import {
    ADD_GIFTCARD_SUCCESS, ADD_GIFTCARD_ERROR,
    REDEEM_GIFTCARD_SUCCESS, REDEEM_GIFTCARD_ERROR,
    IS_GIFT_CARD_LOADING,
    ALL_COUPON_LIST_SUCCESS, ALL_COUPON_LIST_ERROR
} from "../actions/Type";

const initialState = {
    add_gift_card: {},
    redeem_gift_card: {},
    loading: false,
    coupon_list: {}
};

export const GiftCardReducer = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case IS_GIFT_CARD_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ADD_GIFTCARD_SUCCESS:
            return {
                ...state,
                add_gift_card: payload,
                loading: false,
            };
        case ADD_GIFTCARD_ERROR:
            return {
                ...state,
                add_gift_card: payload,
                loading: false,
            };
        case ALL_COUPON_LIST_SUCCESS:
            return {
                ...state,
                coupon_list: payload,
                loading: false,
            };
        case ALL_COUPON_LIST_ERROR:
            return {
                ...state,
                coupon_list: payload,
                loading: false,
            };
        case REDEEM_GIFTCARD_SUCCESS:
            return {
                ...state,
                redeem_gift_card: payload,
                loading: false,
            };
        case REDEEM_GIFTCARD_ERROR:
            return {
                ...state,
                redeem_gift_card: payload,
                loading: false,
            };
        default:
            return state;
    }
};