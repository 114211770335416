import {
    IS_PROFILE_LOADING,
    SUCCESS_COMPLETE_PROFILE_PERSONAL_DETAILS,
    SUCCESS_COMPLETE_PROFILE_QUALIFICATIONS,
    SUCCESS_COMPLETE_PROFILE_TEACHING_DETAILS,
    SUCCESS_COMPLETE_PROFILE_AVAILABILITY,
    SUCCESS_COMPLETE_PROFILE_BANK_DETAILS,
    ERROR_COMPLETE_PROFILE_PERSONAL_DETAILS,
    ERROR_COMPLETE_PROFILE_QUALIFICATIONS,
    ERROR_COMPLETE_PROFILE_TEACHING_DETAILS,
    ERROR_COMPLETE_PROFILE_AVAILABILITY,
    ERROR_COMPLETE_PROFILE_BANK_DETAILS,
    PROFILE_GET_ERROR, PROFILE_GET_SUCCESS,
    PROFILE_CHANGE_PASSWORD_SUCCESS, PROFILE_CHANGE_PASSWORD_ERROR,
    STUDENT_UPDATE_PROFILE_SUCCESS, STUDENT_UPDATE_PROFILE_ERROR,
    PROFILE_PERSONAL_DETAILS_SUCCESS, PROFILE_PERSONAL_DETAILS_ERROR,
    PROFILE_QUALIFICATIONS_SUCCESS, PROFILE_QUALIFICATIONS_ERROR,
    PROFILE_TEACHING_DETAILS_SUCCESS, PROFILE_TEACHING_DETAILS_ERROR,
    PROFILE_AVAILABILITY_SUCCESS, PROFILE_AVAILABILITY_ERROR,
    PROFILE_BANKDETAILS_SUCCESS, PROFILE_BANKDETAILS_ERROR,
    PROFILE_ONLINE_OFLINE_SUCCESS, PROFILE_ONLINE_OFLINE_ERROR,
    SUCCESS_LINKEDIN_CERTIFICATE, ERROR_LINKEDIN_CERTIFICATE,
    SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE, ERROR_UPLOAD_LINKEDIN_CERTIFICATE,
    IS_COMPLETE_PROFILE_CHECK_SUCCESS, IS_COMPLETE_PROFILE_CHECK_ERROR,
    INSTRUCTOR_PROFILE_AVAILABILITY, ERROR_INSTRUCTOR_PROFILE_AVAILABILITY,
    INSTRUCTOR_FETCH_AVAILABILITY_EVENTS, ERROR_INSTRUCTOR_FETCH_AVAILABILITY_EVENTS
} from "../actions/Type";

const initialState = {
    loading: false,
    personal_detail: {},
    qualifications: {},
    teaching_details: {},
    availability: {},
    bank_details: {},
    profile: {},
    change_password: {},
    profile_personal_detail: {},
    profile_qualifications: {},
    profile_teaching_details: {},
    profile_availability: {},
    student_edit_profile: {},
    profile_bank_detail: {},
    profile_offline_online: {},
    certificate: {},
    upload_certificate: {},
    iscomplete_profile: {},
    instructor_profile_availability: [],
    instructor_fetch_availability_events: []
};


export const CompleteProfileReducers = (
    state = initialState,
    { type, payload } = {}
) => {
    switch (type) {
        case IS_PROFILE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case IS_COMPLETE_PROFILE_CHECK_SUCCESS:
            return {
                ...state,
                iscomplete_profile: payload,
                loading: false,
            };
        case IS_COMPLETE_PROFILE_CHECK_ERROR:
            return {
                ...state,
                iscomplete_profile: payload,
                loading: false,
            };
        case SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE:
            return {
                ...state,
                upload_certificate: payload,
                loading: false,
            };
        case ERROR_UPLOAD_LINKEDIN_CERTIFICATE:
            return {
                ...state,
                upload_certificate: payload,
                loading: false,
            };

        case SUCCESS_LINKEDIN_CERTIFICATE:
            return {
                ...state,
                certificate: payload,
                loading: false,
            };
        case ERROR_LINKEDIN_CERTIFICATE:
            return {
                ...state,
                certificate: payload,
                loading: false,
            };
        case PROFILE_ONLINE_OFLINE_SUCCESS:
            return {
                ...state,
                profile_offline_online: payload,
                loading: false,
            };
        case PROFILE_ONLINE_OFLINE_ERROR:
            return {
                ...state,
                profile_offline_online: payload,
                loading: false,
            };
        case PROFILE_BANKDETAILS_SUCCESS:
            return {
                ...state,
                profile_bank_detail: payload,
                loading: false,
            };
        case PROFILE_BANKDETAILS_ERROR:
            return {
                ...state,
                profile_bank_detail: payload,
                loading: false,
            };
        case STUDENT_UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                student_edit_profile: payload,
                loading: false,
            };
        case STUDENT_UPDATE_PROFILE_ERROR:
            return {
                ...state,
                student_edit_profile: payload,
                loading: false,
            };
        case PROFILE_PERSONAL_DETAILS_SUCCESS:
            return {
                ...state,
                profile_personal_detail: payload,
                loading: false,
            };
        case PROFILE_PERSONAL_DETAILS_ERROR:
            return {
                ...state,
                profile_personal_detail: payload,
                loading: false,
            };
        case PROFILE_QUALIFICATIONS_SUCCESS:
            return {
                ...state,
                profile_qualifications: payload,
                loading: false,
            };
        case PROFILE_QUALIFICATIONS_ERROR:
            return {
                ...state,
                profile_qualifications: payload,
                loading: false,
            };
        case PROFILE_TEACHING_DETAILS_SUCCESS:
            return {
                ...state,
                profile_teaching_details: payload,
                loading: false,
            };
        case PROFILE_TEACHING_DETAILS_ERROR:
            return {
                ...state,
                profile_teaching_details: payload,
                loading: false,
            };
        case PROFILE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                profile_availability: payload,
                loading: false,
            };
        case PROFILE_AVAILABILITY_ERROR:
            return {
                ...state,
                profile_availability: payload,
                loading: false,
            };
        case PROFILE_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                change_password: payload,
                loading: false,
            };
        case PROFILE_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                change_password: payload,
                loading: false,
            };
        case PROFILE_GET_SUCCESS:
            return {
                ...state,
                profile: payload,
                loading: false,
            };
        case PROFILE_GET_ERROR:
            return {
                ...state,
                profile: payload,
                loading: false,
            };
        case SUCCESS_COMPLETE_PROFILE_PERSONAL_DETAILS:
            return {
                ...state,
                personal_detail: payload,
                loading: false,
            };
        case ERROR_COMPLETE_PROFILE_PERSONAL_DETAILS:
            return {
                ...state,
                personal_detail: payload,
                loading: false,
            };
        case SUCCESS_COMPLETE_PROFILE_QUALIFICATIONS:
            return {
                ...state,
                qualifications: payload,
                loading: false,
            };
        case ERROR_COMPLETE_PROFILE_QUALIFICATIONS:
            return {
                ...state,
                qualifications: payload,
                loading: false,
            }
        case SUCCESS_COMPLETE_PROFILE_TEACHING_DETAILS:
            return {
                ...state,
                teaching_details: payload,
                loading: false,
            }
        case ERROR_COMPLETE_PROFILE_TEACHING_DETAILS:
            return {
                ...state,
                teaching_details: payload,
                loading: false,
            }
        case SUCCESS_COMPLETE_PROFILE_AVAILABILITY:
            return {
                ...state,
                availability: payload,
                loading: false,
            }
        case ERROR_COMPLETE_PROFILE_AVAILABILITY:
            return {
                ...state,
                availability: payload,
                loading: false,
            }
        case SUCCESS_COMPLETE_PROFILE_BANK_DETAILS:
            return {
                ...state,
                bank_details: payload,
                loading: false,
            }
        case ERROR_COMPLETE_PROFILE_BANK_DETAILS:
            return {
                ...state,
                bank_details: payload,
                loading: false,
            }
        case INSTRUCTOR_PROFILE_AVAILABILITY:
            return {
                ...state,
                instructor_profile_availability: payload,
                loading: false,
            };
        case ERROR_INSTRUCTOR_PROFILE_AVAILABILITY:
            return {
                ...state,
                instructor_profile_availability: payload,
                loading: false,
            };
        case INSTRUCTOR_FETCH_AVAILABILITY_EVENTS:
            return {
                ...state,
                instructor_fetch_availability_events: payload,
                loading: false,
            };
        case ERROR_INSTRUCTOR_FETCH_AVAILABILITY_EVENTS:
            return {
                ...state,
                instructor_fetch_availability_events: payload,
                loading: false,
            };
        default:
            return state;
    }
};