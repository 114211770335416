
import Button from "../../components/Buttons/Buttons";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import { ReferralRedeemAction, ResetReferralRedeemAction } from '../../redux/actions/RefeEarnActions';
import { useEffect } from 'react';
import './../../components/DeleteRecordModal/DeleteRecordModal.scss';
import "./RedeemReferAmountModal.scss";

const RedeemReferAmountModal = (props) => {
    const { amount, onHide, ...rest } = props;
    const dispatch = useDispatch()

    const isloading = useSelector(state => state.refer.loading);
    const referralRedeemSelector = useSelector(state => state.refer.referral_redeem)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const handleOnSubmit = (e) => {
        e.preventDefault();
        dispatch(ReferralRedeemAction({ total_point: amount }))
    }

    useEffect(() => {
        if (Object.keys(referralRedeemSelector).length > 0) {
            if (referralRedeemSelector.status) {
                dispatch(ResetReferralRedeemAction())
                onHide();
            }
        }
    }, [referralRedeemSelector])

    return (
        <Modal
            {...rest}
            centered
            backdrop="static"
            keyboard={false}
            className='delete-modal redeemPoints-modal'
        >
            <Modal.Body>
                <div className="alert-body">
                    <div className="alert-icon green">
                        <svg width="40" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 408.7 293.9">
                            <g>
                                <path d="M408.7,38.4c-2.5,5.3-4,11.2-8.4,15.5c-1,1-1.9,2.2-3,3.2c-75.2,75.3-150.5,150.5-225.7,225.8c-9.9,9.9-21.3,13.5-34.9,9.3
		c-5.3-1.7-9.7-4.8-13.6-8.7c-37.5-37.5-75.1-75-112.6-112.6c-15.1-15.1-13.9-37,2.7-49.8c12-9.3,30.3-9.1,43.7,4.5
		c28.7,29,57.8,57.7,86.5,86.7c2.8,2.8,4.2,3.1,7.2,0.1c66.9-67.1,133.9-134,200.8-201c9.8-9.8,21.2-14,34.8-9.6
		c11.6,3.7,18.5,11.9,21.6,23.5c0.2,0.7-0.2,1.7,0.8,2C408.7,31,408.7,34.7,408.7,38.4z"/>
                            </g>
                        </svg>
                    </div>
                    <h2> {languageSelector?.common?.are_you_sure} </h2>
                    <p> {languageSelector?.common?.redeem_all_the_points_des}</p>
                </div>
                <div className="alert-footer-box">
                    <Button
                        BtnText={languageSelector?.refer_earn?.no}
                        BtnColor="cancel"
                        BtnType="button"
                        onClick={onHide}
                    />
                    <Button
                        BtnText={languageSelector?.refer_earn?.yes}
                        BtnColor="primary"
                        BtnType="button"
                        disabled={isloading}
                        hasSpinner={isloading}
                        onClick={handleOnSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RedeemReferAmountModal
