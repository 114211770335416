import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from "../../components/Buttons/Buttons";
import Modal from "react-bootstrap/Modal";
import { FieldText } from '../../components/InputText/InputText';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AddAmount = (props) => {
    const { onHide, id, ...rest } = props;
    const navigate = useNavigate()
    const isloading = useSelector(state => state.card.loading)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const amountRange = [50, 100, 200, 500, 1000, 2000]

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        <Modal
            {...rest}
            centered
            backdrop="static"
            keyboard={false}
            className="center-modal class-modal problemsolving rejectbooking rejectbookingplace addamount-modal"
        >
            <Modal.Header>
                <Modal.Title> {languageSelector?.payment_details?.add_amount} </Modal.Title>
                <button type="button" className="btn-close" onClick={onHide}></button>
            </Modal.Header>
            <Modal.Body className="blogdetails-modal">
                <Formik
                    initialValues={{
                        amount: ''
                    }}
                    validationSchema={Yup.object().shape({
                        amount: Yup.number().integer().max(10000, languageSelector?.validation?.price_less_equal_10000).positive(languageSelector?.validation?.price_greater_0).integer(languageSelector?.validation?.price_greater_0).required('Required')
                    })}
                    onSubmit={(value) => navigate("/payment/proceed-to-payment", { state: { ...value, page_name: "add_amount" } })}
                >
                    {formik => {
                        return (
                            <Form>
                                <FormikFromFunc />
                                <div className="accountfield-first text-start">
                                    <FieldText
                                        name="amount"
                                        type="number"
                                        className="form-control"
                                        defaultValue={formik.values.amount}
                                        placeholder={languageSelector?.placeholder?.amount}
                                    />
                                </div>

                                <div className="price-radio">
                                    {amountRange.map((value, index) => {
                                        return (
                                            <button className="price-radio-btn" type="button" key={index} onClick={() => formik.setFieldValue("amount", value)}>${value}</button>
                                        )
                                    })}
                                </div>

                                <Modal.Footer className="w-100 p-0 mt-5 mb-4">
                                    <div className="email-send">
                                        <div className="accept">
                                            <Button
                                                BtnText={languageSelector?.payment_details?.add_amount}
                                                name="submit"
                                                BtnColor="primary"
                                                BtnType="submit"
                                                disabled={!formik.isValid || isloading}
                                                hasSpinner={isloading}
                                            />
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default AddAmount
