import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import TextErrorMsg from "../components/TextErrorMsg/TextErrorMsg";
import Button from "../components/Buttons/Buttons";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import { FieldText } from './InputText/InputText';
import { CardCreate } from '../redux/actions/PaymentAction';
import { getUpcomingYearList } from '../Utils/Function';

const AddCardModal = (props) => {
    const { type, onHide, isloading, setClickButton, clickButton, ...rest } = props;
    const dispatch = useDispatch()
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const handleCreatecardsubmit = (value) => {
        dispatch(CardCreate(value, type));
        setClickButton(true)
    }

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        <Modal
            {...rest}
            centered
            backdrop="static"
            keyboard={false}
            className="center-modal class-modal problemsolving rejectbooking paymentmethod"
        >
            <Modal.Header>
                <Modal.Title> {languageSelector?.payment_details?.add_payment_details} </Modal.Title>
                <button type="button" className="btn-close" onClick={onHide}></button>
            </Modal.Header>
            <Modal.Body className="blogdetails-modal">
                <Formik
                    initialValues={{
                        name: "",
                        number: "",
                        exp_month: "",
                        exp_year: "",
                        cvc: "",
                    }}
                    validationSchema={() =>
                        Yup.object().shape({
                            name: Yup.string().required(languageSelector?.validation?.name),
                            number: Yup.string().min(14, languageSelector?.validation?.card_number_invalid).max(20, languageSelector?.validation?.card_number_invalid).required(languageSelector?.validation?.card_number),
                            exp_month: Yup.string().required(languageSelector?.validation?.month),
                            exp_year: Yup.string().required(languageSelector?.validation?.year),
                            cvc: Yup.string().required(languageSelector?.validation?.cvv)
                        })
                    }
                    onSubmit={handleCreatecardsubmit}
                >
                    {formik => {
                        return (
                            <Form>
                                <FormikFromFunc />
                                <div className="classtype-section">
                                    <div className="card-section card-section2">
                                        <div className="card-name">
                                            <FieldText
                                                name="name"
                                                type="text"
                                                placeholder={languageSelector?.placeholder?.name_on_card}
                                            />
                                        </div>
                                        <div className="card-name">
                                            <FieldText
                                                name="number"
                                                type="text"
                                                placeholder={languageSelector?.placeholder?.card_number}
                                                maxLength="16"
                                            />
                                        </div>
                                    </div>
                                    <div className="year-date-cvv card-section2 mb-4 row gy-4 rejectbookingplace">
                                        <div className="date-cvv col-md-4 col-lg-6 col-xl-4 col-sm-12">
                                            <div className="App fix-placeholder">
                                                <Field as="select" name="exp_month" className='w-100'>
                                                    <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value, index) => {
                                                        return (
                                                            <option value={value} key={index}> {value} </option>
                                                        )
                                                    })}
                                                </Field>
                                                <ErrorMessage name="exp_month" component={TextErrorMsg} />
                                                <span className="placeholder-name"> {languageSelector?.placeholder?.month} </span>
                                            </div>
                                        </div>

                                        <div className="date-cvv col-md-4 col-lg-6 col-xl-4 col-sm-12">
                                            <div className="App fix-placeholder">
                                                <Field as="select" name="exp_year" className='w-100'>
                                                    <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                    {getUpcomingYearList(50).map((value, index) => {
                                                        return (
                                                            <option value={value} key={index}> {value} </option>
                                                        );
                                                    })}
                                                </Field>
                                                <ErrorMessage name="exp_year" component={TextErrorMsg} />
                                                <span className="placeholder-name"> {languageSelector?.placeholder?.year} </span>
                                            </div>
                                        </div>

                                        <div className="date-cvv col-md-4 col-lg-6 col-xl-4 col-sm-12">
                                            <FieldText
                                                name="cvc"
                                                type="password"
                                                placeholder={languageSelector?.placeholder?.cvv}
                                                maxLength="4"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal.Footer className="mb-0 mt-5">
                                    <div className="email-send">
                                        <div className="accept">
                                            <Button
                                                BtnText={languageSelector?.payment_details?.cancel}
                                                name="Cancel"
                                                BtnColor="primary cancel"
                                                BtnType="button"
                                                onClick={onHide}
                                            />
                                        </div>

                                        <div className="accept">
                                            <Button
                                                BtnText={languageSelector?.payment_details?.save}
                                                name="Save"
                                                BtnColor="primary"
                                                BtnType="submit"
                                                disabled={!formik.isValid || isloading}
                                                hasSpinner={isloading && clickButton}
                                            />
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default AddCardModal