import React, { useEffect, useState, useRef  } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import '../Instructorprofile/Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ClassesDetailsListAction, ResetClassesDetailsListAction } from '../../redux/actions/ClassesDetailActions';
import 'react-loading-skeleton/dist/skeleton.css'
import { toJpeg } from 'html-to-image';
import jsPDF from 'jspdf';
import moment from "moment";
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

//{ ResetUploadLinkedinCertificateAction, UploadLinkedinStudentCertificateAction } from '../../redux/actions/CompleteProfileAction';
import { studentCertificatesUpload, ResetUploadLinkedinCertificateAction } from '../../redux/actions/CompleteProfileAction';
import { getUserSettings } from '../../Utils/Function';

const Certificatesdetails = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.profile.loading);
    const certificateSelector = useSelector((state) => state.profile.upload_certificate);
    const userDetail = getUserSettings()
    const [submitClick, setSubmitClick] = useState(false)
    const [tabKey, setTabKey] = useState("1")
    const [currentPage, setCurrentPage] = useState(1)
    const [typeBooking, setTypeBooking] = useState("")
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const classesDetailData = useSelector(state => state.classesDetail.class_detail_list)

    const linkedinUploadCertificate = (e) => {
        let certiSection = document.getElementById('certificates-'+e);
        toJpeg(certiSection, { cacheBust: false })
            .then(async (dataUrl) => {
                const base64Image = dataUrl.toString('base64');
                try {
                    let data = {
                        image: base64Image
                    }

                    dispatch(studentCertificatesUpload(data));

                } catch (err) {
                    console.error('Error uploading image:', err);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        //dispatch(UploadLinkedinStudentCertificateAction())
    }

    useEffect(() => {
        if (Object.keys(certificateSelector).length > 0) {
            if (certificateSelector.status) {
                window.open(certificateSelector?.data, "_blank");
                dispatch(ResetUploadLinkedinCertificateAction())
            }
        }
    }, [certificateSelector])


    useEffect(() => {
        dispatch(ClassesDetailsListAction("student", { type_class: "2", type_booking: 4, page: currentPage }))
    }, [dispatch])

    const htmlToImageConvert = (e) => {
        let certiSection = document.getElementById('certificates-'+e);
        toJpeg(certiSection, { cacheBust: false })
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "Certificate.jpeg";
            link.href = dataUrl;
            link.click();
          })
          .catch((err) => {
            console.log(err);
        });
    };

    const handleGeneratePdf = async (e) => {
        let certiSection = document.getElementById('certificates-'+e);
        const canvas = await html2canvas(certiSection, {
            scale: 1, // Adjust scale as needed for better resolution
            useCORS: true, // Enable CORS if needed for external images
        });
          
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgData = canvas.toDataURL('image/jpeg', 1.0); // Use JPEG format for better compression

        // Adjust dimensions based on the canvas size
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        const aspectRatio = canvasWidth / canvasHeight;
        const maxWidth = 210; // A4 width in mm
        const maxHeight = 297; // A4 height in mm

        let pdfWidth = maxWidth;
        let pdfHeight = maxWidth / aspectRatio;

        // Check if the calculated height exceeds the maximum height
        if (pdfHeight > maxHeight) {
        pdfHeight = maxHeight;
        pdfWidth = maxHeight * aspectRatio;
        }

        //pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('Certificate.pdf');
    };
    
    // const certificateStyle = {
    //     backgroundImage: "url(/images/certification.bg.png)",
    //     fontFamily: 'Arial, sans-serif', //'Georgia, serif', // Example font family (Use the actual font from your design)
    //     /*color: '#ffffff',*/ // Main text color for the certificate
    //     //padding: '70px', // Example padding (adjust as needed)
    //     padding: '20px 70px 61px 95px',
    //     //padding: '0px 27px 0px 40px',
    //     backgroundSize: 'contain',
    //     height: '100%',
    //     position: 'relative', // Needed for absolute positioning the seal and corners
    //     width: '100%',
    //     marginTop: '10px',
    //     marginLeft: '-14px',
    // };

    const certificateStyle = {
        backgroundImage: "url(/images/certification.bg.png)",
        fontFamily: 'Arial, sans-serif', //'Georgia, serif', // Example font family (Use the actual font from your design)
        /*color: '#ffffff',*/ // Main text color for the certificate
        //padding: '70px', // Example padding (adjust as needed)
        padding: '20px 70px 62px 95px',
        //padding: '0px 27px 0px 40px',
        backgroundSize: 'cover',
        height: '100%',
        position: 'relative', // Needed for absolute positioning the seal and corners
        width: '100%',
        marginTop: '10px',
        marginLeft: '-14px',
    };

    return (
        <>
        <div id="footer-bottom" className='instructor-profile'>
            <Header />
            <div className="instru-bg-image">
                <h1>{languageSelector?.certificates_details?.certificates_title}</h1>
                <p>Home / <span> {languageSelector?.certificates_details?.certificates_title} </span></p>
            </div>
            <div className='myprofile-section certificatesdetails-page'>
                <div className='container'>
                    <div className='row gx-5'>
                        <div className='col-md-12 col-lg-5 col-xl-4'>
                            <Studentsidebar />
                        </div>
                        <div className='col-md-12 col-lg-7 col-xl-8'>
                            <div className='profile-top-heading'>
                                <h2>{languageSelector?.certificates_details?.certificates_title}</h2>
                            </div>
                            { classesDetailData?.data?.data?.length > 0 ? 
                                <>
                                {classesDetailData?.data?.data?.map((value, index) => {
                                    return (
                                        <div>
                                            <div className='booking-request-section classes-tabs-three' id={`certificates-${index}`}>
                                                <center  style={{ backgroundColor: "#f7f7f7", height: "50%", margin: "0", padding: "0", fontFamily: "Arial, Helvetica, sans-serif", borderCollapse: "collapse", width: "100%", margin: "0 auto",}}>
                                                    <table
                                                        align="center"
                                                        border={0}
                                                        cellPadding={0}
                                                        cellSpacing={0}
                                                        height="100%"
                                                        id="bodyTable"
                                                        style={{
                                                        borderCollapse: "collapse",
                                                        backgroundColor: "#f7f7f7",
                                                        height: "100%",
                                                        margin: 0,
                                                        padding: 0,
                                                        width: "100%"
                                                        }}
                                                        width="100%"
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                align="center"
                                                                id="bodyCell"
                                                                style={{
                                                                    borderTop: 0,
                                                                    height: "100%",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    width: "100%"
                                                                }}
                                                                valign="top"
                                                                >
                                                                    <table
                                                                        border={0}
                                                                        cellPadding={0}
                                                                        cellSpacing={0}
                                                                        className="templateContainer"
                                                                        style={{
                                                                        borderCollapse: "collapse",
                                                                        maxWidth: 842,
                                                                        height: 595,
                                                                        background: "#f9f9f9",
                                                                        border: 0
                                                                        }}
                                                                        width="100%"
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <div className="certificate" >
                                                                                    <div className="seal">
                                                                                    </div>
                                                                                    <div className="certificate-content" style={certificateStyle}>
                                                                                        <div className="content">
                                                                                            <h1 className="certificate-header-title">{languageSelector?.certificates_details?.certificate_of_header_title}</h1>
                                                                                            <div className="sub-lable">This is to certify that:</div>
                                                                                            <h2 className="student-name">{value?.user?.first_name} {value?.user?.last_name}</h2>
                                                                                            <div className="status-lable">successfully completed</div>
                                                                                            <h3 className="group-class-name">{value?.group_class_name?.class_name}</h3>
                                                                                            <div className="date-time">From {moment(value?.start_date, "YYYY/MM/DD").format("MMM/DD/YYYY")} to {moment(value?.end_date, "YYYY/MM/DD").format("MMM/DD/YYYY")}</div>
                                                                                            <div className="hours">{value?.group_class_name?.duration} {languageSelector?.classes_details?.hour}</div>
                                                                                            <div className="certificate-sign">{value?.user?.first_name} {value?.user?.last_name}</div>
                                                                                            <h6>{value?.user_instructor?.first_name} {value?.user_instructor?.last_name}</h6>
                                                                                            {/* <h2>{languageSelector?.certificates_details?.certificate_of_sub_header}</h2> */}
                                                                                            {/*<h6>Date :- {moment(value?.class_date, "YYYY/MM/DD").format("DD MMM'YY")} | {value?.slottime_schudule ? value?.slottime_schudule?.slot_time : "-"}</h6>*/}
                                                                                            <div className="footer">
                                                                                                <div className="left-side">
                                                                                                    <div className="certificate-no">Certificate no.: {index + 1}</div>
                                                                                                </div>
                                                                                                <div className="right-side">
                                                                                                    <div className="logo"><img className="bg-light" src="/images/logo_png_blue1.png"/></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </center>
                                            </div>
                                            <div className='certificates-btn-section'>
                                                <button className="button certificates-button" onClick={() => htmlToImageConvert(index)}>JPEG</button>
                                                <button className="button certificates-button" onClick={() => handleGeneratePdf(index)}>PDF</button>
                                                <button className="button linkedin-button" onClick={() => linkedinUploadCertificate(index)}><FontAwesomeIcon icon={faLinkedin} /></button>
                                            </div>
                                        </div>
                                    )
                                })}
                                </> 
                                : <><h1>You don`t have any Group Class Student Certificates yet</h1></> 
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}

export default Certificatesdetails

// old code
{/* <div id="footer-bottom" className='instructor-profile'>
    <Header />
    <div className="instru-bg-image">
        <h1>{languageSelector?.certificates_details?.certificates_title}</h1>
        <p>Home / <span> {languageSelector?.certificates_details?.certificates_title} </span></p>
    </div>
    <div className='myprofile-section certificatesdetails-page'>
        <div className='container'>
            <div className='row gx-5'>
                <div className='col-md-12 col-lg-5 col-xl-4'>
                    <Studentsidebar />
                </div>

                <div className='col-md-12 col-lg-7 col-xl-8'>
                    <div className='profile-top-heading'>
                        <h2>{languageSelector?.certificates_details?.certificates_title}</h2>
                    </div>

                    <div className='booking-request-section classes-tabs-three' ref={elementRef}>
                        <center  style={{ backgroundColor: "#f7f7f7", height: "50%", margin: "0", padding: "0", fontFamily: "Arial, Helvetica, sans-serif", borderCollapse: "collapse", width: "100%", margin: "0 auto",}}>
                            <table
                                align="center"
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                height="100%"
                                id="bodyTable"
                                style={{
                                borderCollapse: "collapse",
                                backgroundColor: "#f7f7f7",
                                height: "100%",
                                margin: 0,
                                padding: 0,
                                width: "100%"
                                }}
                                width="100%"
                            >
                                <tbody>
                                <tr>
                                    <td
                                    align="center"
                                    id="bodyCell"
                                    style={{
                                        borderTop: 0,
                                        height: "100%",
                                        margin: 0,
                                        padding: 0,
                                        width: "100%"
                                    }}
                                    valign="top"
                                    >
                                    <table
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        className="templateContainer"
                                        style={{
                                        borderCollapse: "collapse",
                                        maxWidth: 842,
                                        height: 595,
                                        background: "#f9f9f9",
                                        border: 0
                                        }}
                                        width="100%"
                                    >
                                        <tbody>
                                        <tr>
                                            <td
                                            id="templatePreheader"
                                            style={{
                                                backgroundImage: "url(/images/certification.bg.png)",
                                                backgroundColor: "#fff",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                                borderTop: 0,
                                                backgroundPosition: "center",
                                                borderBottom: 0,
                                                paddingTop: 10,
                                                paddingBottom: 10
                                            }}
                                            valign="top"
                                            >
                                            <table
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                className="mcnTextBlock"
                                                style={{ borderCollapse: "collapse", minWidth: "100%" }}
                                                width="100%"
                                            >
                                                <tbody className="mcnTextBlockOuter">
                                                <tr>
                                                    <td className="mcnTextBlockInner" valign="top">
                                                    <div
                                                        className="mcnTextContent"
                                                        style={{
                                                        wordBreak: "break-word",
                                                        textAlign: "center",
                                                        padding: "65px 0 0",
                                                        maxWidth: 638,
                                                        marginLeft: "auto"
                                                        }}
                                                        valign="top"
                                                    >
                                                        <img
                                                        style={{ width: 220 }}
                                                        src="/images/Logo_white.svg"
                                                        alt="Logo_white"
                                                        />
                                                    </div>
                                                    <div
                                                        className="mcnTextContent"
                                                        style={{
                                                        wordBreak: "break-word",
                                                        textAlign: "center",
                                                        padding: "20px 0 0",
                                                        fontFamily: "Playfair Display",
                                                        fontWeight: 600,
                                                        fontSize: 40,
                                                        maxWidth: 638,
                                                        color: "#fff",
                                                        marginLeft: "auto"
                                                        }}
                                                        valign="top"
                                                    >
                                                        Certificate of Attendance
                                                    </div>
                                                    <div
                                                        className="mcnTextContent"
                                                        style={{
                                                        wordBreak: "break-word",
                                                        textAlign: "center",
                                                        padding: "10px 0 0",
                                                        fontFamily: "Playfair Display",
                                                        fontWeight: 600,
                                                        fontSize: 35,
                                                        maxWidth: 638,
                                                        color: "#E6C578",
                                                        marginLeft: "auto"
                                                        }}
                                                        valign="top"
                                                    >
                                                        HireInstructor.com
                                                    </div>
                                                    <div
                                                        className="mcnTextContent"
                                                        style={{
                                                        wordBreak: "break-word",
                                                        textAlign: "center",
                                                        padding: "20px 0 0",
                                                        fontFamily: '"Roboto"',
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        maxWidth: 638,
                                                        marginLeft: "auto",
                                                        color: "#fff"
                                                        }}
                                                        valign="top"
                                                    >
                                                        This Is To Certify That
                                                    </div>
                                                    <div
                                                        className="mcnTextContent"
                                                        style={{
                                                        wordBreak: "break-word",
                                                        textAlign: "center",
                                                        padding: "16px 0 0",
                                                        fontFamily: "Playfair Display",
                                                        fontWeight: 700,
                                                        fontSize: 32,
                                                        maxWidth: 638,
                                                        marginLeft: "auto",
                                                        fontStyle: "italic",
                                                        color: "#fff"
                                                        }}
                                                        valign="top"
                                                    >
                                                        Tarun Jain
                                                    </div>
                                                    <div
                                                        className="mcnTextContent"
                                                        style={{
                                                        wordBreak: "break-word",
                                                        textAlign: "center",
                                                        padding: "20px 0 0",
                                                        fontFamily: '"Roboto"',
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        maxWidth: 638,
                                                        marginLeft: "auto",
                                                        color: "#fff",
                                                        lineHeight: 22
                                                        }}
                                                        valign="top"
                                                    >
                                                        for attending Data science by instructor Tarun Jain.
                                                        <br />
                                                        Organized by HireInstructor.com
                                                    </div>
                                                    <div
                                                        className="mcnTextContent"
                                                        style={{
                                                        padding: "45px 0 0",
                                                        maxWidth: 761,
                                                        margin: "auto",
                                                        display: "flex",
                                                        justifyContent: "space-around",
                                                        alignItems: "center"
                                                        }}
                                                        valign="top"
                                                    >
                                                        <div
                                                        style={{
                                                            width: "20%",
                                                            textAlign: "center",
                                                            color: "#fff",
                                                            fontWeight: 400,
                                                            fontFamily: '"Roboto"',
                                                            fontSize: 16
                                                        }}
                                                        >
                                                        04/03/2024
                                                        <div
                                                            style={{
                                                            borderTop: "1px solid #fff",
                                                            paddingTop: 6
                                                            }}
                                                        >
                                                            Date
                                                        </div>
                                                        </div>
                                                        <div
                                                        style={{
                                                            width: "20%",
                                                            textAlign: "center",
                                                            color: "#fff",
                                                            fontWeight: 400,
                                                            fontFamily: '"Roboto"',
                                                            fontSize: 16
                                                        }}
                                                        >
                                                        <div>2</div>
                                                        </div>
                                                        <div
                                                        style={{
                                                            width: "20%",
                                                            textAlign: "center",
                                                            color: "#fff",
                                                            fontWeight: 400,
                                                            fontFamily: '"Roboto"',
                                                            fontSize: 16
                                                        }}
                                                        >
                                                        {" "}
                                                        Tarun Jain
                                                        <div
                                                            style={{
                                                            borderTop: "1px solid #fff",
                                                            paddingTop: 6
                                                            }}
                                                        >
                                                            Signature
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <table
                                                        align="left"
                                                        border={0}
                                                        cellPadding={0}
                                                        cellSpacing={0}
                                                        className="mcnTextContentContainer"
                                                        style={{
                                                        borderCollapse: "collapse",
                                                        minWidth: "100%"
                                                        }}
                                                        width="100%"
                                                    >
                                                        <tbody>
                                                        <tr></tr>
                                                        </tbody>
                                                    </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </center>
                        <button className="button" onClick={htmlToImageConvert}>Download Image</button>
                        <button className="button" onClick={handleGeneratePdf}>Download PDF</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</div> */}
