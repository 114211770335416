import React, { useEffect, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import TextErrorMsg from "../../components/TextErrorMsg/TextErrorMsg";
import * as Yup from 'yup';
import './Instructorprofile.scss';
import Button from '../../components/Buttons/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { ProblemSolvingDetailViewAction, ResetProblemSolvingDetailViewAction, ResetSolutionOfProblemSolvingAction, SolutionOfProblemSolvingAction } from '../../redux/actions/ClassesDetailActions';
import { useNavigate, useParams } from 'react-router-dom';
import { ChooseFile } from '../../components/ChooseFile/ChooseFile';
import { baseUrlPostGres } from '../../redux/constant';
import { GetFileName, isValidHttpUrl } from '../../Utils/Function';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Problemsolving = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { id } = useParams();
    const [submitClick, setSubmitClick] = useState(false)
    const isloading = useSelector(state => state.classesDetail.loading)
    const PSDSelector = useSelector(state => state.classesDetail.problem_solving_detail_view)
    const problemSolvingSelector = useSelector(state => state.classesDetail.solution_of_problem_solving)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    
    const SUPPORTED_FILE_FORMAT = ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"]

    useEffect(() => {
        dispatch(ProblemSolvingDetailViewAction(id))
        return () => dispatch(ResetProblemSolvingDetailViewAction())
    }, [dispatch])

    const handleProblemSolvingSubmit = (value) => {
        let formData = new FormData();
        formData.append("bookingId", id);
        formData.append("message", value?.message);
        formData.append("file", value?.file);
        dispatch(SolutionOfProblemSolvingAction(formData));
        setSubmitClick(true)
    }

    useEffect(() => {
        if (Object.keys(problemSolvingSelector).length > 0 && submitClick) {
            if (problemSolvingSelector.status) {
                setSubmitClick(false)
                navigate("/classesdetails")
                dispatch(ResetSolutionOfProblemSolvingAction())
            }
        }
    }, [problemSolvingSelector, submitClick])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    return (
        <div id="footer-bottom" className='instructor-profile'>
            <Header />
            <div className="instru-bg-image">
                <h1> {languageSelector?.classes_details?.problem_sloving} </h1>
                <p>Home / {languageSelector?.classes_details?.problem_sloving}  / <span> {languageSelector?.classes_details?.problem_sloving} </span></p>
            </div>

            <div className='myprofile-section'>
                <div className='container'>
                    <div className='row g-5'>
                        <div className='col-md-12 col-lg-4'>
                            <Instructorsidebar />
                        </div>

                        <div className='col-md-12 col-lg-8'>
                            <div className='profile-top-heading'>
                                <h2> {languageSelector?.classes_details?.problem_sloving} </h2>
                            </div>
                            <div className='my-profile-details'>
                                {Object.keys(PSDSelector).length > 0 ?
                                    <div className='problem-solving-box'>
                                        <div className="booking-card">
                                            <div className="image-box">
                                                <img
                                                    src={PSDSelector?.user?.profile_image ?
                                                        isValidHttpUrl(PSDSelector?.user?.profile_image) ? PSDSelector?.user?.profile_image
                                                            : `${baseUrlPostGres()}${PSDSelector?.user?.profile_image}`
                                                        : "/images/blank-profile-picture.jpg"}
                                                    referrerPolicy="no-referrer"
                                                    alt={PSDSelector?.user?.first_name}
                                                />
                                            </div>
                                            <div className="booking-details">
                                                <div className="heading-price">
                                                    <div>
                                                        <h2>{PSDSelector?.user?.first_name} {PSDSelector?.user?.last_name} </h2>
                                                        <p> {moment(PSDSelector.created_at).fromNow()} </p>
                                                    </div>
                                                    <div>
                                                        <h3>${PSDSelector?.transaction_user?.txn_amount ? PSDSelector?.transaction_user?.txn_amount : 0}</h3>
                                                    </div>
                                                </div>
                                                <h4> {languageSelector?.classes_details?.subject} </h4>
                                                <span>{PSDSelector?.user_tutor_subject?.tutor_sub_name} | {PSDSelector?.class_level?.class_name}</span>
                                                <h4> {languageSelector?.classes_details?.title} </h4>
                                                <span>{PSDSelector?.tittle}</span>
                                            </div>
                                        </div>
                                        <h4> {languageSelector?.classes_details?.detailed_problem} </h4>
                                        <span> {PSDSelector.description} </span>
                                        {PSDSelector?.file_doc ?
                                            <div className="pdf-dection">
                                                <h4> {languageSelector?.classes_details?.attachment} </h4>
                                                <div className="pdf-img">
                                                    <div className="pdf-img-box">
                                                        <img src="/images/pdf-icon.png" alt="" />
                                                    </div>
                                                    <div className="pdf-content">
                                                        <div>
                                                            <span>{GetFileName(PSDSelector?.file_doc)}</span>
                                                        </div>
                                                        <button className="btn" onClick={() => window.open(`${baseUrlPostGres()}${PSDSelector?.file_doc}`)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="17.5" viewBox="0 0 14.5 17.5"><g id="Group_4802" data-name="Group 4802" transform="translate(-12507.25 -9581.25)"><path id="Path_650" data-name="Path 650" d="M14,3V6.556a.889.889,0,0,0,.889.889h3.556" transform="translate(12502.556 9579)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path><path id="Path_651" data-name="Path 651" d="M16.143,19H6.857A1.819,1.819,0,0,1,5,17.222V4.778A1.819,1.819,0,0,1,6.857,3h6.5L18,7.444v9.778A1.819,1.819,0,0,1,16.143,19Z" transform="translate(12503 9579)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path><line id="Line_178" data-name="Line 178" y2="5" transform="translate(12514.268 9589)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></line><path id="Path_652" data-name="Path 652" d="M9,14l2.667,2.667L14.333,14" transform="translate(12502.834 9577.777)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path></g></svg>
                                                            {languageSelector?.classes_details?.download}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        <Formik
                                            initialValues={{
                                                message: "",
                                                file: ""
                                            }}
                                            validationSchema={() => Yup.object().shape({
                                                message: Yup.string().required(languageSelector?.validation?.describe_reason),
                                                file: Yup.mixed().nullable().notRequired()
                                                    .test(
                                                        "FILE_FORMAT",
                                                        languageSelector?.validation?.file_upload_format,
                                                        (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                                                    ),
                                            })}
                                            onSubmit={handleProblemSolvingSubmit}
                                        >
                                            {formik => {
                                                return (
                                                    <Form>
                                                    <FormikFromFunc />
                                                        <div className='providesolution'>
                                                            <h5> {languageSelector?.classes_details?.provide_solution} </h5>
                                                            <div className="form-group floating-label textarea-limit-block">
                                                                <Field name="message">
                                                                    {({ form, field }) => {
                                                                        return (
                                                                            <>
                                                                                <textarea
                                                                                    id="textarea"
                                                                                    className="form-control"
                                                                                    rows="12"
                                                                                    placeholder=' '
                                                                                    name={field.name}
                                                                                    maxLength="10000"
                                                                                    onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                                                                                ></textarea>
                                                                                <label htmlFor="textarea"> {languageSelector?.placeholder?.describe_reason} </label>
                                                                                <span className="limit">10000 {languageSelector?.placeholder?.characters_limit}</span>
                                                                            </>
                                                                        )
                                                                    }}
                                                                </Field>
                                                            </div>
                                                            <ErrorMessage name="message" component={TextErrorMsg} />
                                                        </div>

                                                        <div className='providesolution'>
                                                            <div className="drag-image-video">
                                                                <Field name="file">
                                                                    {({ form, field }) => {
                                                                        return (
                                                                            <ChooseFile
                                                                                label={languageSelector?.placeholder?.upload_file}
                                                                                name={field.name}
                                                                                handleFileChoose={(e) => form.setFieldValue(field.name, e.file)}
                                                                                error="File should be .jpg .png .jpeg and Size less than 30MB"
                                                                                accept={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
                                                                                acceptInputFile="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx"
                                                                                Size={36000}
                                                                            />
                                                                        )
                                                                    }}
                                                                </Field>
                                                                <ErrorMessage name="file" component={TextErrorMsg} />
                                                            </div>
                                                        </div>

                                                        <div className='send-button'>
                                                            <Button
                                                                BtnText={languageSelector?.classes_details?.send}
                                                                BtnColor="primary"
                                                                BtnType="submit"
                                                                disabled={!formik.isValid || isloading}
                                                                hasSpinner={isloading && submitClick}
                                                            />
                                                        </div>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                    :
                                    <Skeleton height={400} borderRadius="1rem" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Problemsolving
