import axios from "axios";
import { toast } from "react-toastify";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    IS_NOTIFICATION_LOADING,
    NOTIFICATION_LIST_SUCCESS, NOTIFICATION_LIST_ERROR,
    CLEAR_NOTIFICATION_SUCCESS, CLEAR_NOTIFICATION_ERROR,
    DELETE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_ERROR,
    NEW_NOTIFICATION_STATUS_SUCCESS, NEW_NOTIFICATION_STATUS_ERROR
} from "./Type";
import HandleError from "./HandleError";

export const NewNotificationStatusAction = (name) => async (dispatch) => {
    dispatch({ type: IS_NOTIFICATION_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/new-notification-${name}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: NEW_NOTIFICATION_STATUS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: NEW_NOTIFICATION_STATUS_ERROR,
                payload: errors.response.data,
            });
        });
};
export const ResetNewNotificationStatusAction = () => async (dispatch) => {
    dispatch({
        type: NEW_NOTIFICATION_STATUS_SUCCESS,
        payload: ""
    });
}

export const NotificationListAction = (name) => async (dispatch) => {
    dispatch({ type: IS_NOTIFICATION_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/notification-list-${name}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: NOTIFICATION_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: NOTIFICATION_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetNotificationListAction = () => async (dispatch) => {
    dispatch({
        type: NOTIFICATION_LIST_SUCCESS,
        payload: {},
    });
}

export const ClearNotificationAction = (name) => async (dispatch) => {
    dispatch({ type: IS_NOTIFICATION_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/clear-notification-${name}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: CLEAR_NOTIFICATION_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: CLEAR_NOTIFICATION_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetClearNotificationAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_NOTIFICATION_SUCCESS,
        payload: {},
    });
}

export const DeleteNotificationAction = (name, id) => async (dispatch) => {
    dispatch({ type: IS_NOTIFICATION_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/delete-notification-${name}/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: DELETE_NOTIFICATION_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: DELETE_NOTIFICATION_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetDeleteNotificationAction = () => async (dispatch) => {
    dispatch({
        type: DELETE_NOTIFICATION_SUCCESS,
        payload: {},
    });
}