import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Buttons/Buttons";
import { ResetpaymentOnSubscriptionAction, paymentOnSubscriptionAction } from "../../../redux/actions/PaymentAction";
import EditIcon from "../EditIcon";
import PaymentCheckout from "../PaymentCheckout";
import SuccessCheckIcon from "../SuccessCheckIcon";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ProfileGetAction } from "../../../redux/actions/CompleteProfileAction";

const StepThreeSubscription = ({ subscriptionPrice, setActiveStep }) => {
    const { id, name } = useParams();
    const dispatch = useDispatch();
    const location = useLocation()
    const navigate = useNavigate()
    const prevPageData = location?.state
    let amount = prevPageData?.subscription ? prevPageData?.subscription == 1 ? subscriptionPrice?.monthly_subscription_price : subscriptionPrice?.year_subscription_price : 0
    const [showratecard, ratecardShow] = useState(false);
    const [couponId, setCouponId] = useState()
    const [submitClick, setSubmitClick] = useState(false);
    const [SubscriptionData, setSubscriptionData] = useState("");

    const isLoadingSelector = useSelector((state) => state?.instructor?.loading);
    const paymentSelector = useSelector((state) => state?.instructor?.instructor_subscription_payment);
    const SubscriptionStatusSelector = useSelector((state) => state.instructor.subscription_detail);
    const languageSelector = useSelector(state => state.home.dynamic_language);

    useEffect(() => {
        return () => setSubscriptionData("")
    }, [])

    useEffect(() => {
        setActiveStep(name)
    }, [name])

    const handleCloseratecard = () => ratecardShow(false);

    const handlePrevious = () => {
        navigate(`/subscription/payment-method/${id}`, { state: prevPageData });
        setActiveStep('payment-method');
    }
    const handlePayment = () => {
        let data = {
            select_plan: prevPageData.subscription,
            amount: grandTotalAmount(amount, 0),
            // coupon_id: couponId,
            instructor_id: id,
            pay_from: prevPageData.pay_from
        }
        if (prevPageData?.payment_mode !== "wallet") {
            data["card_id"] = prevPageData.card_id
        }
        dispatch(paymentOnSubscriptionAction(data));
        setSubmitClick(true)
    }

    const grandTotalAmount = (amount, discount) => {
        let finalAmount = (Number(amount) - discount)
        return finalAmount.toFixed(2)
    }

    useEffect(() => {
        if (Object.keys(paymentSelector).length > 0 && submitClick) {
            if (paymentSelector.status) {
                ratecardShow(true)
                setSubmitClick(false)
                setSubscriptionData(paymentSelector?.data)
                dispatch(ResetpaymentOnSubscriptionAction())
                dispatch(ProfileGetAction("student"))
            } else {
                setSubmitClick(false)
                setSubscriptionData("")
                dispatch(ResetpaymentOnSubscriptionAction())
            }
        }
    }, [paymentSelector, submitClick])

    var finalPayAmount = Number(amount).toFixed(2)
    return (
        <>
            <div className="personaldetails-main-section on-demand-class-type">
                <h2> {languageSelector?.student_booking?.checkout} </h2>
                <div className="on-demand-class-content">
                    <div className="row g-5">
                        <div className="col-md-8 col-sm-12">

                            <PaymentCheckout setCouponId={setCouponId} amount={amount} grandTotalAmount={grandTotalAmount} isCoupon={false} />

                            <div className="d-flex justify-content-center mt-5">
                                <Button
                                    name="Back"
                                    BtnText={languageSelector?.student_booking?.back}
                                    BtnColor="yellow"
                                    BtnType="button"
                                    onClick={handlePrevious}
                                />

                                {prevPageData?.payment_mode === "paypal" ?
                                    <PayPalScriptProvider
                                        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                                    >
                                        <PayPalButtons
                                            createOrder={(_, actions) => {
                                                return actions.order
                                                    .create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: finalPayAmount,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                    .then((orderId) => {
                                                        return orderId;
                                                    });
                                            }}
                                            forceReRender={[finalPayAmount]}
                                            onApprove={(_, actions) => {
                                                return actions.order.capture().then((details) => {
                                                    let data = {
                                                        select_plan: prevPageData.subscription,
                                                        amount: grandTotalAmount(amount, 0),
                                                        instructor_id: id,
                                                        pay_from: 4,
                                                        order_id: details.id,
                                                        order_status: details.status,
                                                        payer_detail: details.payer
                                                    }
                                                    dispatch(paymentOnSubscriptionAction(data));
                                                    setSubmitClick(true)
                                                });
                                            }}
                                            style={{
                                                layout: 'horizontal',
                                                color: 'blue',
                                                shape: 'rect',
                                                label: 'pay',
                                                height: 55,
                                                tagline: false
                                            }}
                                        />
                                    </PayPalScriptProvider>
                                    :
                                    <Button
                                        BtnText={languageSelector?.student_booking?.proceed_to_pay}
                                        name="pay"
                                        BtnColor="primary"
                                        BtnType="button"
                                        onClick={handlePayment}
                                        disabled={isLoadingSelector}
                                        hasSpinner={isLoadingSelector && submitClick}
                                    />
                                }
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <div className="personaldetails-section payable-amount-card-box">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <span className="amount-text">
                                            {prevPageData?.subscription == "1" ? languageSelector?.student_booking?.monthly_subscription : languageSelector?.student_booking?.yearly_subscription}
                                        </span>
                                    </div>
                                    <div className="col-4 text-end">
                                        <Link className="btn edit-btn" to={`/subscription/selectplan/${id}`}>
                                            <EditIcon />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {!SubscriptionStatusSelector?.data?.status ?
                                <div className="personaldetails-section payable-amount-card-box">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <span className="amount-text">
                                                {prevPageData?.payment_mode === "card" ? languageSelector?.payment_details?.card : prevPageData?.payment_mode === "paypal" ? languageSelector?.payment_details?.paypal : languageSelector?.payment_details?.wallet}
                                            </span>
                                            {prevPageData?.payment_mode === "card" ? <p className="account-number-code">xxxx xxxx xxxx {prevPageData?.last4}</p> : null}
                                        </div>

                                        <div className="col-4 text-end">
                                            <div className="btn edit-btn" onClick={() => navigate(`/subscription/payment-method/${id}`, { state: prevPageData })}>
                                                <EditIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null}
                        </div>
                    </div>
                </div>
            </div>

            <Modal className='center-modal class-modal problemsolving workstation'
                show={showratecard}
                onHide={handleCloseratecard}
                backdrop="static"
                keyboard={false}
            >
                {/* closeButton */}
                <Modal.Header className="p-0">
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='blogdetails-modal'>
                    <div className='classtype-section'>
                        <div className='checkmark'>
                            <SuccessCheckIcon />
                            <p> {languageSelector?.student_booking?.payment_successful} </p>
                        </div>
                        <div className='url-box'>
                            <p>{languageSelector?.student_booking?.enjoy_your} {prevPageData?.subscription == "1" ? languageSelector?.student_booking?.monthly_subscription : languageSelector?.student_booking?.yearly_subscription}</p>
                            <h3>{moment(SubscriptionData?.start_date, "YYYY-MM-DD").format("DD MMMM YYYY")} to {moment(SubscriptionData?.end_date, "YYYY-MM-DD").format("DD MMMM YYYY")}</h3>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='email-send pt-5'>
                        <div className='accept'>
                            <Link className="btn" to="/student/manage-subscription-plan"> {languageSelector?.student_booking?.go_to_manage_subscription} </Link>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default StepThreeSubscription