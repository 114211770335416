import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ChooseFile } from "../../components/ChooseFile/ChooseFile";
import { FieldText } from "../../components/InputText/InputText";
import { GetCountryList, GetLanguageAction } from "../../redux/actions/HomePageActions";
import Button from "./../../components/Buttons/Buttons";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import TextErrorMsg from "../../components/TextErrorMsg/TextErrorMsg";
import moment from "moment/moment";
import { InstructorCompleteProfileStepOne, ResetInstructorCompleteProfileStepOne } from "../../redux/actions/CompleteProfileAction";
import PhoneCountryInput from "../../components/Numberinput/PhoneCountryInput";

export const StepOneCompleteProfile = ({ setActiveStep }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams()
    const [languageName, setLanguageName] = useState([])
    const [submitClick, setSubmitClick] = useState(false)
    const [timeZoneList, setTimeZoneList] = useState()
    const [selectedOption, setSelectedOption] = useState({});
    const [languageList, setLanguageList] = useState([]);

    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        setActiveStep(id)
    }, [id])

    const isLoading = useSelector((state) => state.profile.loading);
    const completeProfileSelector = useSelector((state) => state.profile.personal_detail);
    const user = useSelector((state) => state.auth.user);
    const countryList = useSelector((state) => state?.home?.get_country_list);
    const handleMultiSelect = (data) => {
        const languagesArray = data?.map((elem) => {
            return elem.key;
        });
        setLanguageName([...languagesArray]);
    };

    useEffect(() => {
        dispatch(GetCountryList());
    }, [dispatch]);

    useEffect(() => {
        if (countryList) {
            let countryData = countryList.filter(value => value.id == user?.country_id)
            if (countryData?.length > 0) {
                setTimeZoneList(JSON.parse(countryData[0]?.timezones))
                setSelectedOption(countryData[0])
            }
        }
    }, [countryList])

    const StepOneValidationSchema = Yup.object().shape({
        dob: Yup.string().required(languageSelector?.validation?.dob),
        country_id: Yup.string().required(languageSelector?.validation?.select_country),
        notification_email_lang_id: Yup.string().required(languageSelector?.validation?.select_notification_email),
        country_time: Yup.string().required(languageSelector?.validation?.select_timezone),
        language_name: Yup.string().required(languageSelector?.validation?.language),
        descriptions: Yup.string().required(languageSelector?.validation?.description_required),
        profile_image: Yup.string().required(languageSelector?.validation?.picture_required),
        profile_video: Yup.string().required(languageSelector?.validation?.profile_video_required),
        proofId_image: Yup.string().required(languageSelector?.validation?.proofId_image_required),
        proofaddress_image: Yup.string().required(languageSelector?.validation?.proofaddress_image_required),
    })

    const handleOnChange = (e, data) => {
        if (e.target.name === "phone_number") {
            if (e.target.value) {
                let countryData = countryList.filter(value => (value.phonecode == selectedOption?.phonecode) && (value.name.toLowerCase() == selectedOption?.name.toLowerCase()))
                data.setFieldValue('country_id', countryData[0].id)
                setTimeZoneList(JSON.parse(countryData[0].timezones))
            }
        }
    }

    const handleStepOneSubmit = (value) => {
        let formData = new FormData();
        formData.append("first_name", value?.first_name ? value?.first_name : user?.first_name);
        formData.append("last_name", value?.last_name ? value?.last_name : user?.last_name);
        formData.append("email", value?.email ? value?.email : user?.email);
        formData.append("phone_number", value.phone_number);
        formData.append("phone_code", value.phone_code);
        formData.append("dob", moment(value.dob).format("DD/MM/YY"));
        formData.append("language_name", JSON.stringify(languageName));
        formData.append("country_id", value.country_id);
        formData.append("notification_email_lang_id", value.notification_email_lang_id);
        formData.append("country_time", value.country_time);
        formData.append("descriptions", value.descriptions);
        formData.append("profile_image", value.profile_image);
        formData.append("profile_video", value.profile_video);
        formData.append("proofId_image", value.proofId_image);
        formData.append("proofaddress_image", value.proofaddress_image);
        formData.append("social_type", 0);
        dispatch(InstructorCompleteProfileStepOne(formData));
        setSubmitClick(true)
    }

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    useEffect(() => {
        if (Object.keys(completeProfileSelector).length > 0 && submitClick) {
            if (completeProfileSelector.status) {
                navigate("/completeprofile/qualifications");
                setActiveStep('qualifications');
            } else {
                setSubmitClick(false)
            }
            dispatch(ResetInstructorCompleteProfileStepOne())
        }
    }, [completeProfileSelector, submitClick])

    useEffect(() => {
        GetLanguageAction(response => {
            if (response?.status === true) {
                setLanguageList(response?.data)
            }
        })();
    }, [GetLanguageAction])

    return (
        <div className="personaldetails-main-section" style={{ display: id === 'personalDetails' ? 'block' : 'none' }}>
            <h2> {languageSelector?.complete_profile?.personal_details} </h2>
            <Formik
                initialValues={{
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    email: user?.email,
                    phone_number: user?.phone_number,
                    phone_code: user?.phone_code,
                    dob: moment(user?.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    country_id: user?.country_id,
                    country_time: user?.country_timetemp,
                    language_name: "",
                    descriptions: "",
                    profile_image: "",
                    profile_video: "",
                    proofId_image: "",
                    proofaddress_image: "",
                    notification_email_lang_id: ""
                }}
                enableReinitialize={true}
                validationSchema={StepOneValidationSchema}
                onSubmit={handleStepOneSubmit}
            >
                {formik => {
                    return (
                        <Form onChange={(e) => handleOnChange(e, formik)}>
                            <FormikFromFunc />
                            <div className="personaldetails-section">
                                <div className="personal-details-fields complete-profile2">
                                    <div className="accountfieldmain">
                                        <div className="accountfield">
                                            <div className="accountfield-first">
                                                <FieldText
                                                    name="first_name"
                                                    type="text"
                                                    placeholder={languageSelector?.placeholder?.first_name}
                                                    defaultValue={formik.values.first_name}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="accountfield-first">
                                                <FieldText
                                                    name="last_name"
                                                    type="text"
                                                    placeholder={languageSelector?.placeholder?.last_name}
                                                    defaultValue={formik.values.last_name}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="accountfield-first email-defult-value">
                                                <FieldText
                                                    name="email"
                                                    type="email"
                                                    placeholder={languageSelector?.placeholder?.email}
                                                    disabled={true}
                                                    defaultValue={formik.values.email}
                                                />
                                            </div>

                                            <div className="accountfield-first contact-section dropdown-select d-block fix-placeholder">
                                                <PhoneCountryInput
                                                    selectedOption={selectedOption}
                                                    setSelectedOption={setSelectedOption}
                                                    formik={formik}
                                                />
                                            </div>

                                            <div className="accountfield-first date-picker-sec">
                                                <div className="form-group floating-label">
                                                    <FieldText
                                                        name="dob"
                                                        type="date"
                                                        placeholder={languageSelector?.placeholder?.dob}
                                                        defaultValue={formik.values.dob}
                                                        max={moment().format("YYYY-MM-DD")}
                                                    />
                                                </div>
                                            </div>

                                            <div className="accountfield-first fix-placeholder">
                                                <Field name="language_name">
                                                    {({ form, field }) => {
                                                        return (
                                                            <Multiselect
                                                                hidePlaceholder={true}
                                                                showArrow="true"
                                                                customArrow={<><img src='/images/down-arrow.svg' alt="" /></>}
                                                                disablePreSelectedValues
                                                                name={field.name}
                                                                displayValue="key"
                                                                onRemove={function noRefCheck() { }}
                                                                onSelect={(data) => {
                                                                    handleMultiSelect(data)
                                                                    form.setFieldValue(field.name, JSON.stringify(data))
                                                                }}
                                                                placeholder={languageSelector?.placeholder?.language}
                                                                closeIcon="close"
                                                                avoidHighlightFirstOption
                                                                options={[
                                                                    {
                                                                        cat: "Group 1",
                                                                        key: "English",
                                                                    },
                                                                    {
                                                                        cat: "Group 2",
                                                                        key: "Spanish",
                                                                    },
                                                                    {
                                                                        cat: "Group 3",
                                                                        key: "Physics",
                                                                    },
                                                                    {
                                                                        cat: "Group 4",
                                                                        key: "Hindi",
                                                                    }
                                                                ]}
                                                            />
                                                        )
                                                    }}
                                                </Field>
                                                <span className="placeholder-name"> {languageSelector?.placeholder?.language} </span>
                                                <ErrorMessage name="language_name" component={TextErrorMsg} />
                                            </div>

                                            <div className="number-months-year">
                                                <div className="accountfield-first dropdown-select fix-placeholder">
                                                    <div className="App">
                                                        <Field as="select" name="country_id" disabled value={formik.values.country_id} className='select-control w-100'>
                                                            <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                            {countryList.map((value, index) => {
                                                                return (
                                                                    <option value={value.id} key={index}> {value.name} </option>
                                                                )
                                                            })}
                                                        </Field>
                                                        <span className="placeholder-name"> {languageSelector?.placeholder?.country} </span>
                                                        <ErrorMessage name="country_id" component={TextErrorMsg} />
                                                    </div>
                                                </div>

                                                <div className="accountfield-first dropdown-select fix-placeholder">
                                                    <div className="App">
                                                        <Field as="select" name="country_time" className='select-control w-100'>
                                                            <option value=""> {languageSelector?.placeholder?.select} </option>
                                                            {timeZoneList && timeZoneList.map((value, index) => {
                                                                return (
                                                                    <option value={`${value.zoneName} ${value.tzName} ${value.gmtOffsetName}`} key={index}> {(value.gmtOffsetName)} {value.zoneName}</option>
                                                                )
                                                            })}
                                                        </Field>
                                                        <ErrorMessage name="country_time" component={TextErrorMsg} />
                                                        <span className="placeholder-name"> {languageSelector?.placeholder?.time_zone}  </span>
                                                    </div>
                                                </div>

                                                <div className="accountfield-first dropdown-select fix-placeholder">
                                                    <div className="App">
                                                        <Field as="select" name="notification_email_lang_id" value={formik.values.notification_email_lang_id} className='select-control w-100'>
                                                            <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                            {languageList?.length > 0 &&
                                                                languageList?.map((value, index) => (
                                                                    <option value={value?.id} key={index}>{value?.lang_name} </option>
                                                                ))
                                                            }
                                                        </Field>
                                                        <span className="placeholder-name"> {languageSelector?.placeholder?.notification_email_lang} </span>
                                                        <ErrorMessage name="notification_email_lang_id" component={TextErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accountfield-first text-area-box">
                                                <div className="form-group floating-label textarea-limit-block">
                                                    <Field name="descriptions">
                                                        {({ form, field }) => {
                                                            return (
                                                                <>
                                                                    <textarea
                                                                        id="textarea"
                                                                        className="form-control"
                                                                        rows="3"
                                                                        placeholder=" "
                                                                        name={field.name}
                                                                        maxLength="250"
                                                                        onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                                                                    ></textarea>
                                                                    <label htmlFor="textarea"> {languageSelector?.placeholder?.about_you} </label>
                                                                    <span className="limit">250 {languageSelector?.placeholder?.characters_limit} </span>
                                                                </>
                                                            )
                                                        }}
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="descriptions" component={TextErrorMsg} />
                                            </div>

                                            <div className="accountfield-first">
                                                <div className="drag-image-video">
                                                    <Field name="profile_image">
                                                        {({ form, field }) => {
                                                            return (
                                                                <ChooseFile
                                                                    name={field.name}
                                                                    label={languageSelector?.placeholder?.profile_picture}
                                                                    handleFileChoose={(e) => form.setFieldValue(field.name, e.profile_image)}
                                                                    error="File should be .jpg .png .jpeg and Size < 5MB"
                                                                    accept={["jpg", "jpeg", "png"]}
                                                                    acceptInputFile="image/jpg, image/jpeg, image/png"
                                                                    Size={6000}
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                    <ErrorMessage name="profile_image" component={TextErrorMsg} />
                                                </div>
                                            </div>
                                            <div className="accountfield-first">
                                                <div className="drag-image-video">
                                                    <Field name="profile_video">
                                                        {({ form, field }) => {
                                                            return (
                                                                <ChooseFile
                                                                    label={languageSelector?.placeholder?.profile_video}
                                                                    name={field.name}
                                                                    handleFileChoose={(e) => form.setFieldValue(field.name, e.profile_video)}
                                                                    error="File should be .mp4 .mkv .webp and Size < 5MB"
                                                                    accept={["mp4", "webp", "mkv"]}
                                                                    acceptInputFile="video/mp4, video/*"
                                                                    Size={6000}
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                    <ErrorMessage name="profile_video" component={TextErrorMsg} />
                                                </div>
                                            </div>

                                            <div className="accountfield-first">
                                                <div className="drag-image-video">
                                                    <Field name="proofId_image">
                                                        {({ form, field }) => {
                                                            return (
                                                                <ChooseFile
                                                                    label={languageSelector?.placeholder?.proof_id}
                                                                    name={field.name}
                                                                    handleFileChoose={(e) => form.setFieldValue(field.name, e.proofId_image)}
                                                                    error="File should be .jpg .png .jpeg and Size less than 5MB"
                                                                    accept={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
                                                                    acceptInputFile="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx"
                                                                    Size={6000}
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                    <ErrorMessage name="proofId_image" component={TextErrorMsg} />
                                                </div>
                                            </div>

                                            <div className="accountfield-first">
                                                <div className="drag-image-video">
                                                    <Field name="proofaddress_image">
                                                        {({ form, field }) => {
                                                            return (
                                                                <ChooseFile
                                                                    label={languageSelector?.placeholder?.proof_address}
                                                                    name={field.name}
                                                                    handleFileChoose={(e) => form.setFieldValue(field.name, e.proofaddress_image)}
                                                                    error="File should be .jpg .png .jpeg and Size less than 5MB"
                                                                    accept={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
                                                                    acceptInputFile="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx"
                                                                    Size={6000}
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                    <ErrorMessage name="proofaddress_image" component={TextErrorMsg} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <Button
                                    BtnText={languageSelector?.complete_profile?.next}
                                    name="next"
                                    BtnColor="primary"
                                    BtnType="submit"
                                    disabled={!formik.isValid || isLoading}
                                    hasSpinner={isLoading && submitClick}
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
};
