import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { FieldText } from "../InputText/InputText";
import { GetCountryList } from "../../redux/actions/HomePageActions";
import { useDispatch, useSelector } from "react-redux";

const PhoneCountryInput = ({ formik, selectedOption, setSelectedOption }) => {
    const dispatch = useDispatch();
    const [showCountryOptions, setShowCountryOptions] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const countrySelector = useSelector((state) => state?.home?.get_country_list);

    const handleSelect = (option) => {
        setSelectedOption(option);
        setShowCountryOptions(false);
    };

    useEffect(() => {
        dispatch(GetCountryList());
    }, [dispatch]);

    useEffect(() => {
        if (countrySelector?.length > 0) {
            setCountryList(countrySelector)
            let search_filter_data = countrySelector?.find((data) => data.phonecode == "91");
            setSelectedOption(search_filter_data);
        }
    }, [countrySelector])

    return (
        <div className="phonenuber-block">
            <Field name="phone_code">
                {({ form, field }) => {
                    return (
                        <div className="country-select-wrap">
                            <div className="country-selected-option" onClick={() => setShowCountryOptions(!showCountryOptions)}>
                                {Object.keys(selectedOption)?.length > 0 ?
                                    <>
                                        <input
                                            type="number"
                                            name={field.name}
                                            value={`+${selectedOption?.phonecode}`}
                                            hidden
                                        />
                                        <div className="flag">
                                            <img src={`https://flags.fmcdn.net/data/flags/mini/${selectedOption?.calling_code.toLowerCase()}.png`} className="img-fluid" alt={selectedOption.name} />
                                        </div>
                                        <span className="country-name"> +{selectedOption?.phonecode} </span>
                                    </>
                                    :
                                    null
                                }
                            </div>
                            {showCountryOptions && (
                                <ul className="country-options">
                                    <li className="search">
                                        <input type="search" className="form-control" placeholder="Search here..." onChange={(e) => {
                                            let search_filter_data = countrySelector?.filter((data) => {
                                                let countrys = data.name.toLowerCase();
                                                countrys += data.phonecode.toLowerCase();
                                                return countrys.includes(e.target.value.toLowerCase())
                                            });
                                            setCountryList(search_filter_data);
                                        }} />
                                    </li>
                                    {countryList?.map((value, index) => (
                                        <li key={index} onClick={() => {
                                            form.setFieldValue(field.name, `+${value.phonecode}`)
                                            handleSelect(value)
                                            form.setFieldValue("phone_number", "")
                                        }}>
                                            <div className="flag">
                                                <img src={`https://flags.fmcdn.net/data/flags/mini/${value?.calling_code.toLowerCase()}.png`} className="img-fluid" alt={value.name} />
                                            </div>
                                            <span className="country-name"> {value.name} </span>
                                            <span className="dial-code"> +{value.phonecode} </span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )
                }}
            </Field>
            <div className="form-control-group">
                <FieldText
                    name="phone_number"
                    type="phone_number"
                    maxLength={12}
                    defaultValue={formik.values.phone_number}
                />
            </div>
            <span className="placeholder-name"> Phone Number </span>
        </div>
    );
}
export default PhoneCountryInput;