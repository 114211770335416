import axios from "axios";
import { toast } from "react-toastify";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    IS_CLASSESS_DETAIL_LOADING,
    CLASSES_DETAILS_LIST_SUCCESS,
    CLASSES_DETAILS_LIST_ERROR,
    SOLUTION_OF_PROBLEM_SOLVING_SUCCESS, SOLUTION_OF_PROBLEM_SOLVING_ERROR,
    PROBLEM_SOLVING_DETAIL_VIEW_SUCCESS, PROBLEM_SOLVING_DETAIL_VIEW_ERROR,
    PROBLEM_SOLVING_DETAIL_SUCCESS, PROBLEM_SOLVING_DETAIL_ERROR
} from "./Type";
import HandleError from "./HandleError";

export const ClassesDetailsListAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_CLASSESS_DETAIL_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/class-details-${name}`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: CLASSES_DETAILS_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: CLASSES_DETAILS_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetClassesDetailsListAction = () => async (dispatch) => {
    dispatch({
        type: CLASSES_DETAILS_LIST_SUCCESS,
        payload: {},
    });
}

export const SolutionOfProblemSolvingAction = (data) => async (dispatch) => {
    dispatch({ type: IS_CLASSESS_DETAIL_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/provide-solution`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: SOLUTION_OF_PROBLEM_SOLVING_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: SOLUTION_OF_PROBLEM_SOLVING_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetSolutionOfProblemSolvingAction = () => async (dispatch) => {
    dispatch({
        type: SOLUTION_OF_PROBLEM_SOLVING_SUCCESS,
        payload: {},
    });
}

export const ProblemSolvingDetailViewAction = (id) => async (dispatch) => {
    dispatch({ type: IS_CLASSESS_DETAIL_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/problem-details-instructor/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: PROBLEM_SOLVING_DETAIL_VIEW_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: PROBLEM_SOLVING_DETAIL_VIEW_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetProblemSolvingDetailViewAction = () => async (dispatch) => {
    dispatch({
        type: PROBLEM_SOLVING_DETAIL_VIEW_SUCCESS,
        payload: {},
    });
}


export const ProblemSolvingDetailAction = (name, id) => async (dispatch) => {
    dispatch({ type: IS_CLASSESS_DETAIL_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/problem-details-${name}/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: PROBLEM_SOLVING_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: PROBLEM_SOLVING_DETAIL_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetProblemSolvingDetailAction = () => async (dispatch) => {
    dispatch({
        type: PROBLEM_SOLVING_DETAIL_SUCCESS,
        payload: {},
    });
}