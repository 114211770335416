import moment from "moment";
import { toast } from "react-toastify";

export const getUserSettings = () => {
    let settingsJSON = localStorage.getItem("security_auth");
    let settings = localStorage.security_auth && JSON.parse(settingsJSON)
    return settings || {};
}

export const isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var hDisplay = h > 9 ? h : ("0" + h)
    var mDisplay = m > 9 ? m : ("0" + m)
    var sDisplay = s > 9 ? s : ("0" + s)
    return hDisplay + ":" + mDisplay + ":" + sDisplay;
}

export function copyLinkToClipboard(zoomLink) {
    navigator.clipboard.writeText(zoomLink)
    toast.success("Invite link copied successfully!", { theme: "colored" });
}

export function copyToClipBoard(copyMe){
    navigator.clipboard.writeText(copyMe);
    toast.success("Code Copied Successfully! ", { theme: "colored" });
};

export const GetYearsList = (back) => {
    const year = new Date().getFullYear();
    return Array.from({length: back}, (_, i) => year + 1 - back + i);
    // return Array.from({ length: back }, (v, i) => year - back + i - 1);
}

export const GetFileName = (files) => {
    var n = files?.lastIndexOf('/')
    var result = files?.substring(n + 1);
    return result
}

export const getUpcomingYearList = (year) => {
    const years = []
    const dateStart = moment()
    const dateEnd = moment().add(year, 'y')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
    }
    return years
}