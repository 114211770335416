import {
    CARD_CREATE_ERROR, CARD_CREATE_SUCCESS, CARD_LIST_ERROR, CARD_LIST_SUCCESS, IS_PAYMENT_LOADING, PAY_BY_CARD_ERROR, PAY_BY_CARD_SUCCESS, ADD_AMOUNT_SUCCESS, ADD_AMOUNT_ERROR, CARD_REMOVE_ERROR, CARD_REMOVE_SUCCESS, TRANSACTION_HISTORY_LIST_SUCCESS, TRANSACTION_HISTORY_LIST_ERROR,
    VERIFICATION_BADGE_PURCHASES_SUCCESS, VERIFICATION_BADGE_PURCHASES_ERROR, VERIFICATION_BADGE_STATUS_SUCCESS, VERIFICATION_BADGE_STATUS_ERROR,
    BADGE_BILLING_HISTORY_SUCCESS, BADGE_BILLING_HISTORY_ERROR, PAYMENT_DETAILS_HISTORY_SUCCESS, PAYMENT_DETAILS_HISTORY_ERROR
} from "../actions/Type";

const initialState = {
    card_list: [],
    card_remove: {},
    card_pay: {},
    create_card: {},
    transaction_history: {},
    loading: false,
    add_amount: {},
    verification_badge: {},
    billing_history: {},
    payment_detail_histroy: {}
};


export const CardReducers = (
    state = initialState,
    { type, payload } = {}
) => {
    switch (type) {
        case IS_PAYMENT_LOADING:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_DETAILS_HISTORY_SUCCESS:
            return {
                ...state,
                payment_detail_histroy: payload,
                loading: false,
            };
        case PAYMENT_DETAILS_HISTORY_ERROR:
            return {
                ...state,
                payment_detail_histroy: payload,
                loading: false,
            }
        case BADGE_BILLING_HISTORY_SUCCESS:
            return {
                ...state,
                billing_history: payload,
                loading: false,
            };
        case BADGE_BILLING_HISTORY_ERROR:
            return {
                ...state,
                billing_history: payload,
                loading: false,
            }
        case VERIFICATION_BADGE_STATUS_SUCCESS:
            return {
                ...state,
                verification_status: payload,
                loading: false,
            };
        case VERIFICATION_BADGE_STATUS_ERROR:
            return {
                ...state,
                verification_status: payload,
                loading: false,
            }
        case VERIFICATION_BADGE_PURCHASES_SUCCESS:
            return {
                ...state,
                verification_badge: payload,
                loading: false,
            };
        case VERIFICATION_BADGE_PURCHASES_ERROR:
            return {
                ...state,
                verification_badge: payload,
                loading: false,
            }
        case TRANSACTION_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                transaction_history: payload,
                loading: false,
            };
        case TRANSACTION_HISTORY_LIST_ERROR:
            return {
                ...state,
                transaction_history: payload,
                loading: false,
            }
        case CARD_REMOVE_SUCCESS:
            return {
                ...state,
                card_remove: payload,
                loading: false,
            };
        case CARD_REMOVE_ERROR:
            return {
                ...state,
                card_remove: payload,
                loading: false,
            }
        case CARD_LIST_SUCCESS:
            return {
                ...state,
                card_list: payload,
                loading: false,
            };
        case CARD_LIST_ERROR:
            return {
                ...state,
                card_list: payload,
                loading: false,
            }
        case CARD_CREATE_SUCCESS:
            return {
                ...state,
                create_card: payload,
                loading: false,
            };
        case CARD_CREATE_ERROR:
            return {
                ...state,
                create_card: payload,
                loading: false,
            }
        case PAY_BY_CARD_SUCCESS:
            return {
                ...state,
                card_pay: payload,
                loading: false,
            };
        case PAY_BY_CARD_ERROR:
            return {
                ...state,
                card_pay: payload,
                loading: false,
            }
        case ADD_AMOUNT_SUCCESS:
            return {
                ...state,
                add_amount: payload,
                loading: false
            }
        case ADD_AMOUNT_ERROR:
            return {
                ...state,
                add_amount: payload,
                loading: false
            }
        default:
            return state;
    }
};