import React, { useState, useEffect } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import Table from 'react-bootstrap/Table';
import './Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentDetailHistroyAction } from '../../redux/actions/PaymentAction';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import moment from 'moment';
const BASE_URL = "http://127.0.0.1:8000"; // Change to your actual base URL when deploying

const Paymentdetails = () => {
    const dispatch = useDispatch();
    const paymentDetailSelector = useSelector(state => state.card.payment_detail_histroy);
    const languageSelector = useSelector(state => state.home.dynamic_language);

    useEffect(() => {
        dispatch(PaymentDetailHistroyAction());
    }, [dispatch]);

    //subtoal of EaringTotalAmount 
    const totalSubTotal = paymentDetailSelector?.data ? paymentDetailSelector?.data.reduce((acc, curr) => acc + parseFloat(curr.sub_total), 0) : 0;

    //subtotal of PendingTotalAmount
    const totalPendingAmount = paymentDetailSelector?.data ? 
    paymentDetailSelector.data.reduce((acc, curr) => (curr.payout_status === 0 ? acc + parseFloat(curr.sub_total) : acc), 0) : 0;


    //get student(user) username with profile from db
    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.payment_details?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.payment_details?.page_title} </span></p>
                </div>

                <div className='myprofile-section'>
                    <div className='container'>
                        <div className='row g-5'>
                            <div className='col-md-12 col-lg-4'>
                                <Instructorsidebar />
                            </div>

                            <div className='col-md-12 col-lg-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.payment_details?.payment_details} </h2>
                                </div>

                                <div className='my-profile-details'>
                                    <div className='tab-section-profile tab-section-profile2'>

                                        {Object.keys(paymentDetailSelector).length > 0 ?
                                            <div className='price-two-box'>
                                              <div className='price-first'>
                                                <h2>{languageSelector?.payment_details?.total_earning}</h2>
                                                <p>${totalSubTotal.toFixed(2)}</p>
                                              </div>
                                              <div className='price-first price-first2'>
    <h2>{languageSelector?.payment_details?.pending_amount}</h2>
    <p>${totalPendingAmount.toFixed(2)}</p>
</div>
                                            </div>
                                            :
                                            <div className='row' style={{ marginBottom: "3rem" }}>
                                                <div className='col-md-6'>
                                                    <Skeleton height={75} borderRadius="1rem" />
                                                </div>
                                                <div className='col-md-6'>
                                                    <Skeleton height={75} borderRadius="1rem" />
                                                </div>
                                            </div>
                                        }

                                        <div className='manages-section payment-table-main'>
                                            {Object.keys(paymentDetailSelector).length > 0 ?
                                                <Table striped responsive>
                                                    <tbody>
                                                        {paymentDetailSelector?.data?.length > 0 ?
                                                            paymentDetailSelector?.data.map((value, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className='image-content-box'>
                                                                        <div className='image-payment'>
    <img
        src={value.user?.profile_image ? `${BASE_URL}${value.user.profile_image}` : "/upload/blank-profile-picture.jpg"}
        alt={value.user?.first_name || "User"}
        referrerPolicy="no-referrer"
        onError={(e) => { e.target.onerror = null; e.target.src="/upload/blank-profile-picture.jpg"; }} // Fallback on error
    />
</div>

                                                                            <div className='name-level'>
                                                                            <h3>{value.user?.first_name ?? "Untitled"}</h3>
                                                                            <h3>{value.user?.last_name ?? "Untitled"}</h3>
                                                                            </div>
                                                                            <div className='name-level'>
                                                                                {/* <h3>{value?.username ?? "Untitled"}</h3> */}
                                                                                {/* <h3>{value.user?.last_name ?? "Untitled"}</h3> */}
                                                                                </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{moment(value?.created_at).format("DD MMM'YY")} <br />at {moment(value?.created_at).format("LT")}</td>
                                                                    <td>{value?.payment_method}</td>
                                                                    <td>{languageSelector?.payment_details?.id_number}: <br /> {value?.transaction_id ? `#${value?.transaction_id}` : "-"}</td>
                                                                    <td>
                                                                        <span className={value?.payout_status === 0 ? "" : languageSelector?.payment_details?.paid}>
                                                                            +${value?.sub_total}
                                                                        </span>
                                                                    </td>
                                                                    <td className='text-end'>{value?.payout_status === 0 ? languageSelector?.payment_details?.pending : languageSelector?.payment_details?.paid}</td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr>
                                                                <td colSpan="5"><NoDataFound /></td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                                :
                                                [1, 2, 3, 4, 5, 6].map((_, index) => (
                                                    <div key={index} style={{ marginBottom: "3rem" }}>
                                                        <Skeleton height={50} borderRadius="1rem" />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Paymentdetails;
