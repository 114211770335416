import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { setCurrentUser } from './redux/actions/AuthAction';
import PrivateRoutes from './privateRoutes/PrivateRoutes';
import { toast, ToastContainer } from 'react-toastify';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home/Home';
import Studentcreateaccount from './pages/Auth/Studentcreateaccount';
import Studentlogin from './pages/Auth/Studentlogin';
import Instructorcreateaccount from './pages/Auth/Instructorcreateaccount';
import Instructorlogin from './pages/Auth/Instructorlogin';
import Courses from './pages/Courses/Courses';
import Instructors from './pages/Instructors/Instructors';
import Groupclasses from './pages/Groupclasses/Groupclasses';
import Contactus from './pages/ContactUs/ContactUs';
import Aboutus from './pages/Aboutus/Aboutus';
import FAQs from './pages/FAQs/FAQs';
import Blog from './pages/Bloglisting/Blog';
import Blogdetail from './pages/Bloglisting/Blogdetail';
import Termsconditions from './pages/TermsConditions/Termsconditions';
import Privacypolicy from './pages/PrivacyPolicy/Privacypolicy';
import Instructordetail from './pages/Instructordetail/Instructordetail';
// import Completeprofile from './pages/Instructorcompleteprofile/Completeprofile';
// import Myprofile from './pages/Instructorprofile/Myprofile';
import Bookingrequest from './pages/Instructorprofile/Bookingrequest';
import Classesdetails from './pages/Instructorprofile/Classesdetails';
import Instructorgroupclasses from './pages/Instructorprofile/Instructorgroupclasses';
import Problemsolving from './pages/Instructorprofile/Problemsolving';
// import Completedclassesproblemsolving from './pages/Instructorprofile/Completedclassesproblemsolving';
import Managesubscription from './pages/Instructorprofile/Managesubscription';
import Getverificationbadge from './pages/Instructorprofile/Getverificationbadge';
import Paymentdetails from './pages/Instructorprofile/Paymentdetails';
import Ratingsreviews from './pages/Instructorprofile/Ratingsreviews';
// import Myblogs from './pages/Instructorprofile/Myblogs';
import Chats from './pages/Instructorprofile/Chats';
import Addgroupclass from './pages/Instructorprofile/Addgroupclass';
import Editgroupclass from './pages/Instructorprofile/Editgroupclass';
// import Studentmyprofile from './pages/Studentprofile/Studentmyprofile';
import Studentclassesdetails from './pages/Studentprofile/Studentclassesdetails';
import Studentclassescertificate from './pages/Studentprofile/Studentclassescertificate';
import Studentcompletedclassesproblemsolving from './pages/Studentprofile/Studentcompletedclassesproblemsolving';
import Studentwalletpayment from './pages/Studentprofile/Studentwalletpayment';
import Studentmanagesubscription from './pages/Studentprofile/Studentmanagesubscription';
import Studentgiftcardcoupons from './pages/Studentprofile/Studentgiftcardcoupons';
import Studentpaymentmethod from './pages/Studentprofile/Studentpaymentmethod';
import Studentwishlist from './pages/Studentprofile/Studentwishlist';
import Studentreferearn from './pages/Studentprofile/Studentreferearn';
import Studentratingsreviews from './pages/Studentprofile/Studentratingsreviews';
// import Studentmyblogs from './pages/Studentprofile/Studentmyblogs';
import Ondemandclassconfirmation from './pages/Studentclasstype/OnDemand/Ondemandclassconfirmation';
import Scheduleclass from './pages/Studentclasstype/ScheduledClass/Scheduleclass';
import Problemsolvingclass from './pages/Studentclasstype/ProblemSolving/Problemsolvingclass';
import Subscription from './pages/Studentclasstype/Subscription/Subscription';
import Ondemandclass from './pages/Studentclasstype/OnDemand/Ondemandclass';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Newcompleteprofile from './pages/Instructorcompleteprofile/Completeprofile';
import ProfileWrraper from './pages/Instructorprofile/ProfileWrraper';
import Unauthorized from './components/Unauthorized';
import GroupClassPayment from './components/Groupclassescard/GroupClassPayment/GroupClassPayment';
import CartPage from './pages/Home/CartPage.js';
import Checkout from './pages/Home/Checkout.js';
import GroupClassDetail from './pages/Home/GroupClassDetail.js';
import AddBlog from './pages/Blogs/AddBlog';
import BlogList from './pages/Blogs/BlogList';
import BlogDetailPreview from './pages/Blogs/BlogDetailPreview';
import BlogEdit from './pages/Blogs/BlogEdit';
import './App.scss';
import './responsive.scss';
import { onFirebaseMessageListener } from './pages/Auth/firebase';
import "react-toastify/dist/ReactToastify.css";
import { BsFillChatDotsFill } from "react-icons/bs";
import Support from './pages/Support/Support';
import SupportDetail from './pages/Support/SupportDetail';
import { LogoLoader } from './components/Loaders/LogoLoader';
import { CartProvider } from './pages/Home/CartContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// Replace with your own publishable key
const stripePromise = loadStripe('pk_test_51I6Jf8HIXLdlaW9yaqRbslF5tT53Lv3gt5DWLGvxMa6cM1dhPoqJpSlEBhqFOUbHtRpgYwhRylqS1YGTLosgtWNn00iYAV8VW4');
const ROLES = {
    'student': 2,
    'instructor': 3
}

toast.configure();
function App() {
    const dispatch = useDispatch();
    const [notification, setNotification] = useState()
    const { pathname } = useLocation()
    const userDetail = JSON.parse(localStorage.getItem("security_auth"));
    const [cart , setCart] = useState([]);

    // const { language_loading } = useSelector((state) => state.home)

    async function requestPermission() {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            // Generate Token
        } else if (permission === "denied") {
            //alert("You denied for the notification");
        }
    }

    useEffect(() => {
        // Req user for notification permission
        requestPermission();
    }, []);

    useEffect(() => {
        if (localStorage.security_auth) {
            dispatch(setCurrentUser(userDetail?.user))
        }
    }, [dispatch, userDetail])

    const role = userDetail?.user?.role;

    onFirebaseMessageListener().then(payload => {
        if (pathname.split("/").pop() !== "my-chats") {
            setNotification({ title: payload.notification.title, body: payload.notification.body })
        }
    }).catch(err => console.log('failed: ', err));

    const Msg = () => (
        <div className='chatNotification'>
            <div className='icon'><BsFillChatDotsFill /></div>
            <Link to={`/${role == 2 ? "student" : "instructor"}/my-chats`}>
                <h2>{notification?.title}</h2>
                <p>{notification?.body}</p>
            </Link>
        </div>
    )

    useEffect(() => {
        if (notification) {
            toast.info(<Msg />, {
                icon: false,
                autoClose: 5000
            })
        }
    }, [notification])
    const [cartItems, setCartItems] = useState([]);

    return (
        <>
            {/* {language_loading && <LogoLoader className="fullScreenLoader" size="120px" />} */}
            <ToastContainer style={{ zIndex: 9999999999 }} />
            <CartProvider value={{ cartItems, setCartItems }}>

            <Routes>
                <Route path="/"><Route index element={<Home />} /></Route>
                <Route path="/courses"><Route index element={<Courses />} /></Route>
                <Route path="/instructors" ><Route index element={<Instructors />} /></Route>
                <Route path="/instructors/:search" ><Route index element={<Instructors />} /></Route>
                <Route path="/instructordetail/:id"><Route index element={<Instructordetail />} /></Route>
                <Route path="/group-classes"><Route index element={<Groupclasses />} /></Route>
                <Route path="/group-classes/:id"><Route index element={<Groupclasses />} /></Route>
                <Route path="/blog"><Route index element={<Blog />} /></Route>
                <Route path="/blogdetail/:id"><Route index element={<Blogdetail />} /></Route>
                <Route path="/faqs"><Route index element={<FAQs />} /></Route>
                <Route path="/contactus"><Route index element={<Contactus />} /></Route>
                <Route path="/about-us"><Route index element={<Aboutus />} /></Route>
                <Route path="/terms-and-conditions"><Route index element={<Termsconditions />} /></Route>
                <Route path="/privacy-policy"><Route index element={<Privacypolicy />} /></Route>
                <Route path="/linkedin"><Route index element={<LinkedInCallback />} /></Route>
                <Route path="/unauthorized" element={<Unauthorized />} />
                {/* Student Routes */}
                <Route path="/student-login"><Route index element={<Studentlogin />} /></Route>
                <Route path="/student-signup"><Route index element={<Studentcreateaccount />} /></Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/group-class/:name/:id">
                    <Route index element={<GroupClassPayment />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/scheduleclass/:name/:id">
                    <Route index element={<Scheduleclass />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/problemsolvingclass/:name/:id">
                    <Route index element={<Problemsolvingclass />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/subscription/:name/:id">
                    <Route index element={<Subscription />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/ondemandclass/:name/:id">
                    <Route index element={<Ondemandclass />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/ondemandclass/confirmation/:id">
                    <Route index element={<Ondemandclassconfirmation />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/wallet-and-payment">
                    <Route index element={<Studentwalletpayment />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/student/manage-subscription-plan">
                    <Route index element={<Studentmanagesubscription />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/studentgiftcardcoupons">
                    <Route index element={<Studentgiftcardcoupons />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/student/wishlist">
                    <Route index element={<Studentwishlist />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/student/refer-and-earn">
                    <Route index element={<Studentreferearn />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/student/rating-and-review">
                    <Route index element={<Studentratingsreviews />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/student/classes-details">
                    <Route index element={<Studentclassesdetails />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.student]} />} path="/student/classes-certificate">
                    <Route index element={<Studentclassescertificate />} />
                </Route>
                {/* Instructor Routes */}
                <Route path="/instructor-login"><Route index element={<Instructorlogin />} /></Route>
                <Route path="/instructor-signup"><Route index element={<Instructorcreateaccount />} /></Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/completeprofile/:id">
                    <Route index element={<Newcompleteprofile />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/bookingrequest">
                    <Route index element={<Bookingrequest />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/instructor/group-classes">
                    <Route index element={<Instructorgroupclasses />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/instructor/group-class/edit/:id">
                    <Route index element={<Editgroupclass />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/classes-details/problem-solving/:id">
                    <Route index element={<Problemsolving />} />
                </Route>
                {/* <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/completedclassesproblemsolving">
                    <Route index element={<Completedclassesproblemsolving />} />
                </Route> */}
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/instructor/manage-subscription-plan">
                    <Route index element={<Managesubscription />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/instructor/verification-badge">
                    <Route index element={<Getverificationbadge />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/paymentdetails">
                    <Route index element={<Paymentdetails />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/instructor/rating-and-review">
                    <Route index element={<Ratingsreviews />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/instructor/add-group-class">
                    <Route index element={<Addgroupclass />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor]} />} path="/classesdetails">
                    <Route index element={<Classesdetails />} />
                </Route>
                {/* Common Routes */}
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/myprofile/:role">
                    <Route index element={<ProfileWrraper />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/my-blog/:name/create">
                    <Route index element={<AddBlog role={role} />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/my-blog/:name/list">
                    <Route index element={<BlogList role={role} />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/my-blog/:name/preview/:id">
                    <Route index element={<BlogDetailPreview role={role} />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/my-blog/:name/edit/:id">
                    <Route index element={<BlogEdit role={role} />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/payment/proceed-to-payment">
                    <Route index element={<Studentpaymentmethod />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/:role/my-chats">
                    <Route index element={<Chats role={role} />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/:role/support">
                    <Route index element={<Support role={role} />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/:role/support-detail/:id">
                    <Route index element={<SupportDetail role={role} />} />
                </Route>
                <Route element={<PrivateRoutes allowedRoles={[ROLES.instructor, ROLES.student]} />} path="/:name/classes-details/problemsolving/:id">
                    <Route index element={<Studentcompletedclassesproblemsolving />} />
                </Route>
                {/* <Route element={<PrivateRoutes allowedRoles={[ROLES.User]} />} path="/myprofile"><Route index element={role===3 ? <Myprofile /> :<Studentmyprofile />} /></Route> */}
                <Route path="/CartPage" element={<CartPage />} />
                <Route path="/group-class-detail/:id" element={<GroupClassDetail />} />
                <Route path="/Checkout" element={<Elements stripe={stripePromise}><Checkout /></Elements> } />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </CartProvider>

        </>

    );
}

export default App;
