import React, { useEffect, useState } from "react";
import "./Auth.scss";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { facebookProvider, googleProvider } from "./firebase";
import socialMediaAuth from "./SocialAuth";
import { useDispatch, useSelector } from "react-redux";
import { CompleteProfileAction, ForgotEmail, InstructorLogin, InstuctorSocialLogin, LinkedinSocialLoginAction, ResetPasswordAction } from "../../redux/actions/AuthAction";
import { FieldText } from "../../components/InputText/InputText";
import { STUDENT_REGISTER_SUCCESS } from "../../redux/actions/Type";
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from "./../../components/Buttons/Buttons";
import { Regex } from "../../Utils/Regex";

function Instructorlogin() {
    const navigate = useNavigate();
    const disptach = useDispatch();
    const location = useLocation()
    const [show, setShow] = useState(false);
    const [resetModalshow, setResetModalshow] = useState(false);
    const [loginClick, setLoginClick] = useState(false)
    const [forgotClick, setForgotClick] = useState(false)
    const [resetPassClick, setResetPassClick] = useState(false)
    const [socialEmailModalOpen, setSocialEmailModalOpen] = useState(false)
    const [completeProfileClick, setCompleteProfileClick] = useState(false)

    const isLoading = useSelector((state) => state.auth.loading);
    const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
    const loginSelector = useSelector((state) => state.auth?.user);
    const isCompleteProfile = useSelector((state) => state.auth?.user?.complete_profile);
    const forgotPassSelector = useSelector((state) => state.auth?.forgot_pass);
    const ResetPassSelector = useSelector((state) => state.auth?.reset_pass);
    const SocialCompleteProfileSelector = useSelector(state => state.auth?.social_complete_profile)
    const socialLoginSelector = useSelector(state => state.auth?.social_user_login)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    // Get Id in params
    const params = new URLSearchParams(location.search)
    let resetPassToken = params.get('reset-password')

    useEffect(() => {
        if (resetPassToken) {
            setResetModalshow(true)
        }
    }, [resetPassToken])

    const handleClose = () => {
        setResetModalshow(false)
        setShow(false)
    };

    const handleShow = () => setShow(true);

    useEffect(() => {
        disptach({ type: STUDENT_REGISTER_SUCCESS, payload: null })
    }, [])

    const handleSocialLogin = async (provider, type) => {
        const res = await socialMediaAuth(provider);
        if (!!res) {
            let formdata = new FormData();
            formdata.append("first_name", res?._tokenResponse?.firstName);
            formdata.append("last_name", res?._tokenResponse?.lastName);
            if (res?._tokenResponse?.email) {
                formdata.append("email", res?._tokenResponse?.email);
            }
            formdata.append("profile_image", res?._tokenResponse?.photoUrl);
            formdata.append(type === "facebook" ? "facebook_id" : "google_id", res?.user?.providerData[0]?.uid);
            disptach(InstuctorSocialLogin(formdata));
        }
    }

    const handleForgotSubmit = (values) => {
        let formData = new FormData();
        formData.append("email", values.forgot_email);
        formData.append("device_name", 1);
        formData.append("type_user", 3);
        disptach(ForgotEmail(formData));
        setForgotClick(true)
    };

    const handleLinkedinLogin = async (code) => {
        await disptach(LinkedinSocialLoginAction({ code, redirect_uri: `${window.location.origin}/linkedin` }, "instructor"))
    };

    const handleLoginSubmit = (values) => {
        const formData = new FormData();
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("device_name", 1)
        formData.append("role", 3)
        disptach(InstructorLogin(formData));
        setLoginClick(true)
    }

    const isCompleteProfileChecker = (value) => {
        for (const key in value) {
            if (!value[key]) {
                return key
            }
        }
    }

    useEffect(() => {
        if (isAuthenticated === true && (isCompleteProfile === 1 || isCompleteProfile === null)) {
            var data = isCompleteProfileChecker(loginSelector?.completeprofilevalue)
            if (data) {
                navigate(`/completeprofile/${data}`);
            } else {
                navigate("/completeprofile/personalDetails");
            }
            setLoginClick(false)
        }
        if (isAuthenticated === true && isCompleteProfile === 2) {
            navigate("/");
            setLoginClick(false)
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (forgotPassSelector && Object.keys(forgotPassSelector).length > 0 && show) {
            if (forgotPassSelector.data.status) {
                setShow(false);
                setForgotClick(false)
            }
        }
    }, [forgotPassSelector])

    const handleResetPasswordSubmit = (values) => {
        let data = {
            token: resetPassToken,
            password: values.password,
            confirm_password: values.confirm_password,
            type_device: "1"
        }
        disptach(ResetPasswordAction(data))
        setResetPassClick(true)
    }

    useEffect(() => {
        if (ResetPassSelector && Object.keys(ResetPassSelector).length > 0 && resetModalshow) {
            if (ResetPassSelector.data.status) {
                setResetModalshow(false);
                setResetPassClick(false);
                navigate("/instructor-login");
            }
        }
    }, [ResetPassSelector])

    useEffect(() => {
        if (Object.keys(socialLoginSelector).length > 0) {
            if (socialLoginSelector?.facebookemail) {
                setSocialEmailModalOpen(true)
            }
        }
    }, [socialLoginSelector])


    useEffect(() => {
        if (Object.keys(SocialCompleteProfileSelector).length > 0 && completeProfileClick) {
            if (SocialCompleteProfileSelector.status) {
                setSocialEmailModalOpen(false)
                setCompleteProfileClick(false)
                if (socialLoginSelector?.user?.complete_profile === 1 || socialLoginSelector?.user?.complete_profile === 0 || socialLoginSelector?.user?.complete_profile === null) {
                    if (socialLoginSelector?.user?.role !== 2) {
                        navigate("/completeprofile/personalDetails");
                    } else {
                        navigate("/");
                    }
                } else {
                    navigate("/");
                }
            }
        }
    }, [SocialCompleteProfileSelector])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    return (
        <>
            <div className="login-main-section login-page instructorcreate-sec">
                <div className="login-main-section1">
                    <div className="login-account">
                        <div className="position-relative login-top-content">
                            <h1> {languageSelector?.mobile_signup?.create_account} </h1>
                            <p> {languageSelector?.mobile_signup?.create_account_web_side_des} </p>
                            <div className="view-courses">
                                <Link
                                    className="btn becomaninstructor"
                                    to="/instructor-signup"
                                > {languageSelector?.mobile_signIn?.join_now_button} </Link>
                            </div>
                        </div>
                    </div>
                    <div className="crose-button">
                        <Link className="btn becomaninstructor" to="/">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.484"
                                height="20.479"
                                viewBox="0 0 20.484 20.479"
                            >
                                <path
                                    id="Icon_ionic-ios-close"
                                    data-name="Icon ionic-ios-close"
                                    d="M23.954,21.528l7.316-7.316a1.714,1.714,0,0,0-2.424-2.424L21.53,19.1l-7.316-7.316a1.714,1.714,0,1,0-2.424,2.424l7.316,7.316-7.316,7.316a1.714,1.714,0,0,0,2.424,2.424l7.316-7.316,7.316,7.316a1.714,1.714,0,0,0,2.424-2.424Z"
                                    transform="translate(-11.285 -11.289)"
                                    fill="#fff"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>

                <div className="login-main-section2">
                    <div className="position-relative login-details-sec">
                        <h1> {languageSelector?.mobile_signup?.welcome_back} </h1>
                        <p> {languageSelector?.mobile_signup?.create_text} </p>

                        <div className="accountfieldmain accountfieldmain-login">
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                validationSchema={() =>
                                    Yup.object().shape({
                                        email: Yup.string().email(languageSelector?.validation?.email_invaild).required(languageSelector?.validation?.email_required),
                                        password: Yup.string().required(languageSelector?.validation?.password),
                                    })
                                }
                                onSubmit={handleLoginSubmit}
                            >
                                {formik => {
                                    return (
                                        <Form>
                                            <FormikFromFunc />
                                            <div className="accountfield">
                                                <div className="accountfield-first">
                                                    <FieldText
                                                        name="email"
                                                        type="email"
                                                        placeholder={languageSelector?.placeholder?.email}
                                                    />
                                                </div>
                                                <div className="accountfield-first">
                                                    <FieldText
                                                        name="password"
                                                        type="password"
                                                        showHide={true}
                                                        placeholder={languageSelector?.placeholder?.password}
                                                    />
                                                </div>
                                            </div>

                                            <div className="forget-password">
                                                <div className="box">
                                                    <Link className="button" onClick={handleShow}> {languageSelector?.mobile_signIn?.forgot_password} </Link>
                                                </div>
                                            </div>

                                            <div className="accountfield-heading">
                                                <h2> {languageSelector?.mobile_signIn?.continue} </h2>
                                            </div>

                                            <div className="accountfield-fb-goo-button-sec">
                                                <div className="facebook">
                                                    <Button
                                                        BtnColor="primary becomaninstructor"
                                                        BtnType="button"
                                                        onClick={() => handleSocialLogin(facebookProvider, "facebook")}
                                                        BtnText={<><img src="/images/facebook.png" alt="" /> {languageSelector?.mobile_signIn?.facebook} </>}
                                                    />
                                                </div>

                                                <div className="facebook">
                                                    <Button
                                                        BtnColor="primary becomaninstructor"
                                                        BtnType="button"
                                                        onClick={() => handleSocialLogin(googleProvider, "google")}
                                                        BtnText={<><img src="/images/google.png" alt="" /> {languageSelector?.mobile_signIn?.google}</>}
                                                    />
                                                </div>

                                                <div className="linkedIn">
                                                    <button className="btn becomaninstructor" type="button">
                                                        <LinkedIn
                                                            clientId="78rw4lcdguagjf"
                                                            redirectUri={`${window.location.origin}/linkedin`}
                                                            scope="r_liteprofile,r_emailaddress,w_member_social"
                                                            onSuccess={(code) => handleLinkedinLogin(code)}
                                                            onFailure={(error) => {
                                                                console.log("onFailure", error);
                                                            }}
                                                            onError={(error) => {
                                                                console.log("onError", error);
                                                            }}
                                                        >
                                                            {({ linkedInLogin }) => (
                                                                <img
                                                                    onClick={linkedInLogin}
                                                                    src={linkedin}
                                                                    alt="Sign in with Linked In"
                                                                    style={{ maxWidth: "180px", cursor: "pointer", margin: 0 }}
                                                                />
                                                            )}
                                                        </LinkedIn>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="accountfield-signup">
                                                <div className="signup">
                                                    <Button
                                                        BtnText={languageSelector?.mobile_signIn?.login_button}
                                                        BtnColor="primary becomaninstructor"
                                                        BtnType="submit"
                                                        // disabled={!formik.isValid || isLoading}
                                                        disabled={isLoading}
                                                        hasSpinner={isLoading && loginClick}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="center-modal"
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title> {languageSelector?.for_pass?.forgot_password} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bottom-line"> {languageSelector?.for_pass?.forgot_password_web_des} </Modal.Body>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            forgot_email: ''
                        }}
                        validationSchema={() =>
                            Yup.object().shape({
                                forgot_email: Yup.string().email(languageSelector?.validation?.email_invaild).required(languageSelector?.validation?.email_required)
                            })
                        }
                        onSubmit={handleForgotSubmit}
                    >
                        {formik => {
                            return (
                                <Form>
                                    <FormikFromFunc />
                                    <div className="login-main-section login-main-section-email">
                                        <div className="login-main-section2">
                                            <div className="position-relative w-100">
                                                <div className="accountfieldmain">
                                                    <div className="accountfield">
                                                        <div className="accountfield-first text-start">
                                                            <FieldText
                                                                name="forgot_email"
                                                                type="email"
                                                                placeholder={languageSelector?.placeholder?.email}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accountfield-signup mb-0">
                                        <div className="signup">
                                            <Button
                                                BtnText={languageSelector?.for_pass?.send}
                                                BtnColor="primary becomaninstructor"
                                                BtnType="submit"
                                                // disabled={!formik.isValid || isLoading}
                                                disabled={isLoading}
                                                hasSpinner={isLoading && forgotClick}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                className="center-modal"
                show={resetModalshow}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title> {languageSelector?.reset_password?.reset_password} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bottom-line"> {languageSelector?.reset_password?.enter_new_password} </Modal.Body>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            password: '',
                            confirm_password: ''
                        }}
                        validationSchema={() =>
                            Yup.object().shape({
                                password: Yup.string().required(languageSelector?.validation?.password).min(6, languageSelector?.validation?.password_digits).max(16, languageSelector?.validation?.password_digits).matches(Regex.password, languageSelector?.validation?.password_case),
                                confirm_password: Yup.string().required(languageSelector?.validation?.confirm_password_required).oneOf([Yup.ref('password'), null], languageSelector?.validation?.password_not_match),
                            })
                        }
                        onSubmit={handleResetPasswordSubmit}
                    >
                        {formik => {
                            return (
                                <Form>
                                    <FormikFromFunc />
                                    <div className="login-main-section login-main-section-email">
                                        <div className="login-main-section2">
                                            <div className="position-relative w-100">
                                                <div className="accountfieldmain">
                                                    <div className="accountfield text-start">
                                                        <div className="accountfield-first">
                                                            <FieldText
                                                                name="password"
                                                                type="password"
                                                                showHide={true}
                                                                placeholder={languageSelector?.placeholder?.password}
                                                            />
                                                        </div>
                                                        <div className="accountfield-first mt-5">
                                                            <FieldText
                                                                name="confirm_password"
                                                                type="password"
                                                                showHide={true}
                                                                placeholder={languageSelector?.placeholder?.re_passwrod}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accountfield-signup mb-0">
                                        <div className="signup">
                                            <Button
                                                BtnText={languageSelector?.reset_password?.save}
                                                BtnColor="primary becomaninstructor"
                                                BtnType="submit"
                                                // disabled={!formik.isValid || isLoading}
                                                disabled={isLoading}
                                                hasSpinner={isLoading && resetPassClick}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                className="center-modal"
                show={socialEmailModalOpen}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title> {languageSelector?.complete_profile_model?.complete_profile} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bottom-line"> {languageSelector?.complete_profile_model?.complete_profile_des} </Modal.Body>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        validationSchema={() =>
                            Yup.object().shape({
                                email: Yup.string().email(languageSelector?.validation?.email_invaild).required(languageSelector?.validation?.email_required),
                            })
                        }
                        onSubmit={(value) => {
                            disptach(CompleteProfileAction("instructor", value))
                            setCompleteProfileClick(true)
                        }}
                    >
                        {formik => {
                            return (
                                <Form>
                                    <FormikFromFunc />
                                    <div className="login-main-section login-main-section-email">
                                        <div className="login-main-section2">
                                            <div className="position-relative w-100">
                                                <div className="accountfieldmain">
                                                    <div className="accountfield">
                                                        <div className="accountfield-first text-start">
                                                            <FieldText
                                                                name="email"
                                                                type="email"
                                                                placeholder={languageSelector?.placeholder?.email}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accountfield-signup mb-0">
                                        <div className="signup">
                                            <Button
                                                BtnText={languageSelector?.complete_profile_model?.send}
                                                BtnColor="primary becomaninstructor"
                                                BtnType="submit"
                                                disabled={!formik.isValid || isLoading}
                                                hasSpinner={isLoading && completeProfileClick}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
}

export default Instructorlogin;
