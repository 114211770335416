import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Buttons/Buttons";
import { ResetpaymentOnDemand, paymentOnDemand } from "../../../redux/actions/PaymentAction";
import EditIcon from "../EditIcon";
import PaymentCheckout from "../PaymentCheckout";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ProfileGetAction } from "../../../redux/actions/CompleteProfileAction";

const OndemandClassStepThree = ({ subscriptionValue, amount, setActiveStep }) => {
    const { id, name } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    const prevPageData = location?.state
    const [couponAmount, setCouponAmount] = useState(0)

    const [couponId, setCouponId] = useState()
    const [submitClick, setSubmitClick] = useState(false)

    // const isLoadingSelector = useSelector((state) => state?.instructor?.loading)
    const paymentSelector = useSelector((state) => state?.instructor?.instructor_payment)
    const SubscriptionStatusSelector = useSelector((state) => state.instructor.subscription_detail);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        setActiveStep(name)
    }, [name])

    const handlePrevious = () => {
        if (subscriptionValue) {
            navigate(`/ondemandclass/subject-and-level/${id}`, { state: prevPageData });
            setActiveStep('subject-and-level');
        } else {
            navigate(`/ondemandclass/payment-method/${id}`, { state: prevPageData });
            setActiveStep('payment-method');
        }
    }

    const handleStripePayment = () => {
        let data = {
            subject_id: prevPageData.subject_id,
            level_id: prevPageData.level_id,
            amount: subscriptionValue ? 0 : grandTotalAmount(amount, couponAmount),
            coupon_amount: couponAmount,
            couponId: couponId,
            instructor_id: id,
            pay_from: SubscriptionStatusSelector?.data?.status ? "3" : prevPageData.pay_from
        }
        if (prevPageData.payment_mode !== "wallet") {
            data["card_id"] = prevPageData.card_id
        }
        dispatch(paymentOnDemand(data));
        setSubmitClick(true)
    }

    const grandTotalAmount = (amount, discount) => {
        let finalAmount = (Number(amount) - Number(discount))
        return finalAmount.toFixed(2)
    }

    useEffect(() => {
        if (Object.keys(paymentSelector).length > 0 && submitClick) {
            if (paymentSelector.status) {
                navigate((`/ondemandclass/confirmation/${paymentSelector.data.id}`))
                setSubmitClick(false)
                dispatch(ProfileGetAction("student"))
                dispatch(ResetpaymentOnDemand())
            } else {
                setSubmitClick(false)
            }
        }
    }, [paymentSelector, submitClick])

    var finalPayAmount = (Number(amount) - Number(couponAmount)).toFixed(2)

    return (
        <>
            <div className="personaldetails-main-section on-demand-class-type">
                <h2> {languageSelector?.student_booking?.checkout} </h2>
                <div className="on-demand-class-content">
                    <div className="row g-3 g-md-5">
                        <div className="col-md-8 col-sm-12 order-2 order-md-0">

                            <PaymentCheckout isCoupon={subscriptionValue ? false : true} isSubscription={subscriptionValue} setCouponId={setCouponId} amount={amount} grandTotalAmount={grandTotalAmount} couponAmountFn={setCouponAmount} />

                            <div className="d-flex justify-content-center mt-5 paypalBtnGroup">
                                <Button
                                    name="back"
                                    BtnText={languageSelector?.student_booking?.back}
                                    BtnColor="yellow"
                                    BtnType="button"
                                    onClick={handlePrevious}
                                />
                                {prevPageData.payment_mode === "paypal" ?
                                    <PayPalScriptProvider
                                        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                                    >
                                        <PayPalButtons
                                            createOrder={(_, actions) => {
                                                return actions.order
                                                    .create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: finalPayAmount,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                    .then((orderId) => {
                                                        return orderId;
                                                    });
                                            }}
                                            forceReRender={[finalPayAmount]}
                                            className="heroBtnbutton"
                                            onApprove={(_, actions) => {
                                                return actions.order.capture().then((details) => {
                                                    let data = {
                                                        subject_id: prevPageData.subject_id,
                                                        level_id: prevPageData.level_id,
                                                        amount: grandTotalAmount(amount, couponAmount),
                                                        coupon_amount: couponAmount,
                                                        couponId: couponId,
                                                        instructor_id: id,
                                                        pay_from: 4,
                                                        order_id: details.id,
                                                        order_status: details.status,
                                                        payer_detail: details.payer
                                                    }
                                                    dispatch(paymentOnDemand(data));
                                                    setSubmitClick(true)
                                                });
                                            }}
                                            style={{
                                                layout: 'horizontal',
                                                color: 'blue',
                                                shape: 'rect',
                                                label: 'pay',
                                                height: 55,
                                                tagline: false
                                            }}
                                        />
                                    </PayPalScriptProvider>
                                    :
                                    <Button
                                        BtnText={languageSelector?.student_booking?.proceed_to_pay}
                                        name="pay"
                                        BtnColor="primary"
                                        BtnType="button"
                                        onClick={handleStripePayment}
                                        disabled={submitClick}
                                        hasSpinner={submitClick}
                                    />
                                }
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <div className="personaldetails-section payable-amount-card-box">
                                <div className="row align-items-center">
                                    <div className="col-9">
                                        <span className="amount-text"> {languageSelector?.student_booking?.ondemand_class} </span>
                                    </div>

                                    <div className="col-3 text-end">
                                        <Link className="btn edit-btn" to={`/instructordetail/${id}`}>
                                            <EditIcon />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="personaldetails-section payable-amount-card-box">
                                <div className="row align-items-center">
                                    <div className="col-9">
                                        <span className="amount-text">{prevPageData?.subject_name} | {prevPageData?.level_name}</span>
                                    </div>

                                    <div className="col-3 text-end">
                                        <Link className="btn edit-btn" to={`/ondemandclass/subject-and-level/${id}`}>
                                            <EditIcon />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {!SubscriptionStatusSelector?.data?.status ?
                                <div className="personaldetails-section payable-amount-card-box">
                                    <div className="row align-items-center">
                                        <div className="col-9">
                                            <span className="amount-text">
                                                {prevPageData.payment_mode === "card" ? languageSelector?.payment_details?.card : prevPageData.payment_mode === "paypal" ? languageSelector?.payment_details?.paypal : languageSelector?.payment_details?.wallet}
                                            </span>
                                            {prevPageData.payment_mode === "card" ? <p className="account-number-code">xxxx xxxx xxxx {prevPageData?.last4}</p> : null}
                                        </div>

                                        <div className="col-3 text-end">
                                            <div className="btn edit-btn" onClick={() => navigate(`/ondemandclass/payment-method/${id}`, { state: prevPageData })}>
                                                <EditIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default OndemandClassStepThree