import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux';
const InstructorSideBar = ({ instructorDetails }) => {
    const languageSelector = useSelector(state => state.home.dynamic_language)
    return (
        <div className="col-md-12 col-lg-5 col-xl-4 col-12 sidebar-details">
            {Object.keys(instructorDetails).length > 0 ?
                <>
                    <div className="feescharges-sec availability specialization specialization2">
                        <h2> {languageSelector?.inst_page?.specialization} </h2>
                        <div className="specialization-content">
                            <div className="Specialization-tag">
                                {instructorDetails?.userspecialization?.map(
                                    (ele, index) => (
                                        <div
                                            className="btn becomaninstructor no-cursor"
                                            key={index}
                                        >
                                            {ele?.subject_name}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="feescharges-sec availability specialization">
                        <h2> {languageSelector?.inst_page?.tags} </h2>
                        <div className="specialization-content">
                            <div className="Specialization-tag">
                                {instructorDetails?.usertags?.map(
                                    (ele, index) => (
                                        <div
                                            className="btn becomaninstructor no-cursor"
                                            key={index}
                                        >
                                            {ele?.usertags_name[0]?.tags_name}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <Skeleton height={205} borderRadius="2.6rem" />
                    <Skeleton height={205} borderRadius="2.6rem" style={{marginTop: "3rem"}} />
                </>
            }
        </div>

    )
}

export default InstructorSideBar;