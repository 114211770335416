import React, { useEffect } from "react"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Accordion } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { FaqCardSection } from "../../components/faqCardSection/FaqCardSection";
import { GetFaqs } from "../../redux/actions/HomePageActions";



const FAQs = () => {
    const dispatch = useDispatch();
    const faq = useSelector((state) => state.home?.get_faqs);
    const languageSelector = useSelector(state => state.home.dynamic_language)
    useEffect(() => {
        dispatch(GetFaqs({ page: "faq" }))
    }, [dispatch])


    return (
        <>
            <Header />
            <div className="instructor-profile">
                <div className="instru-bg-image">
                    <h1> {languageSelector?.cms?.faqs} </h1>
                    <p>Home / <span>{languageSelector?.cms?.faqs} </span></p>
                </div>
            </div>

            <section className="questions-section">
                <div className="container">
                    <div className="row">
                        <Accordion defaultActiveKey="0" className="row">
                            {faq && faq?.map((ele) => <FaqCardSection key={ele.id} ele={ele} />)}
                        </Accordion>
                    </div>
                </div>

            </section>

            <Footer />
        </>
    );
}

export default FAQs
