import {
    SUPPORT_LIST_ERROR, SUPPORT_LIST_SUCCESS,
    SUPPORT_DETAIL_ERROR, SUPPORT_DETAIL_SUCCESS,
    IS_SUPPORT_LOADING,
    SUPPORT_MESSAGE_REPLY_SUCCESS, SUPPORT_MESSAGE_REPLY_ERROR,
} from "../actions/Type";

const initialState = {
    support_list: {},
    support_detail: {},
    support_reply: {},
    loading: false,
};

export const SupportReducers = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case IS_SUPPORT_LOADING:
            return {
                ...state,
                loading: true,
            };
        case SUPPORT_LIST_SUCCESS:
            return {
                ...state,
                support_list: payload,
                loading: false,
            };
        case SUPPORT_LIST_ERROR:
            return {
                ...state,
                support_list: payload,
                loading: false,
            };

        case SUPPORT_DETAIL_SUCCESS:
            return {
                ...state,
                support_detail: payload,
                loading: false,
            };
        case SUPPORT_DETAIL_ERROR:
            return {
                ...state,
                support_detail: payload,
                loading: false,
            };

        case SUPPORT_MESSAGE_REPLY_SUCCESS:
            return {
                ...state,
                support_reply: payload,
                loading: false,
            };
        case SUPPORT_MESSAGE_REPLY_ERROR:
            return {
                ...state,
                support_reply: payload,
                loading: false,
            };
        default:
            return state;
    }
};