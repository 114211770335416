import {
    IS_CMS_LOADING,
    CMS_LIST_SUCCESS, CMS_LIST_ERROR,
} from "../actions/Type";

const initialState = {
    cms_data: {},
    loading: false,
};

export const CMSReducer = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case IS_CMS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case CMS_LIST_SUCCESS:
            return {
                ...state,
                cms_data: payload,
                loading: false,
            };
        case CMS_LIST_ERROR:
            return {
                ...state,
                cms_data: payload,
                loading: false,
            };
        default:
            return state;
    }
};