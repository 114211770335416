
import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Buttons/Buttons";
import { toast } from "react-toastify";
import PaymentMethod from "../PaymentMethod";
import EditIcon from "../EditIcon";
import { useSelector } from "react-redux";

const PSClassStepTwo = ({ amount, setActiveStep }) => {
    const { id, name } = useParams();
    const location = useLocation()
    const navigate = useNavigate()
    const prevPageData = location?.state

    const [state, setState] = useState({});
    const [methodPayment, setMethodPayment] = useState();
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        setActiveStep(name)
    }, [name])

    const handleNextSubmitButton = () => {
        if (methodPayment == undefined) {
            toast.error(languageSelector?.validation?.select_any_one_payment_method, { theme: "colored" })
            return <></>
        }
        if (methodPayment === "card" && Object.keys(state).length == 0) {
            toast.error(languageSelector?.validation?.select_any_one_card, { theme: "colored" })
            return <></>
        }
        navigate(`/problemsolvingclass/checkout/${id}`, { state: { ...prevPageData, ...state, payment_mode: methodPayment } });
        setActiveStep('checkout');
    }

    const handlePrevious = () => {
        navigate(`/problemsolvingclass/upload-problem/${id}`, { state: prevPageData });
        setActiveStep('upload-problem');
    }

    return (
        <div className="personaldetails-main-section on-demand-class-type" style={{ display: name === 'payment-method' ? 'block' : 'none' }}>
            <h2> {languageSelector?.student_booking?.payment_method} </h2>
            <div className="on-demand-class-content">
                <div className="row g-3 g-md-5">
                    <div className="col-md-8 col-sm-12 order-2 order-md-0">
                        <PaymentMethod setState={setState} state={state} setMethodPayment={setMethodPayment} methodPayment={methodPayment} />

                        <div className="d-flex justify-content-center mt-5">
                            <Button
                                name={languageSelector?.student_booking?.back}
                                BtnText="Back"
                                BtnColor="yellow"
                                BtnType="button"
                                onClick={handlePrevious}
                            />
                            <Button
                                BtnText={languageSelector?.student_booking?.next}
                                name="next"
                                BtnColor="primary"
                                BtnType="button"
                                onClick={handleNextSubmitButton}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="personaldetails-section payable-amount-card-box">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <span className="amount-text"> {languageSelector?.student_booking?.payable_amount} </span>
                                </div>

                                <div className="col-4 text-end">
                                    <h3 className="amount-price">${amount ? amount : 0}</h3>
                                </div>
                            </div>
                        </div>

                        <div className="personaldetails-section payable-amount-card-box">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <span className="amount-text"> {languageSelector?.student_booking?.problem_solving_class} </span>
                                </div>

                                <div className="col-4 text-end">
                                    <Link className="btn edit-btn" to={`/instructordetail/${id}`}>
                                        <EditIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="personaldetails-section payable-amount-card-box">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <span className="amount-text">{prevPageData?.subject_name} | {prevPageData?.level_name}</span>
                                </div>

                                <div className="col-4 text-end">
                                    <Link className="btn edit-btn" to={`/problemsolvingclass/upload-problem/${id}`}>
                                        <EditIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PSClassStepTwo;