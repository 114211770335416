import Button from "../../components/Buttons/Buttons";
import Modal from "react-bootstrap/Modal";
import './../../components/DeleteRecordModal/DeleteRecordModal.scss';
import { useSelector } from "react-redux";

const ComfirmRequestModal = (props) => {
    const { onHide, onSubmit, loading, ...rest } = props;
    const languageSelector = useSelector(state => state.home.dynamic_language)
    return (
        <Modal
            {...rest}
            centered
            backdrop="static"
            keyboard={false}
            className='delete-modal'
            size="md"
        >
            <Modal.Body>
                <div className="alert-body">
                    <div className="alert-icon green">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="144.987" viewBox="0 0 77.272 144.987">
                            <g id="Group_5573" data-name="Group 5573" transform="translate(0.046)">
                                <path id="Path_1088" data-name="Path 1088" d="M76.873,216.971c1.16,2.611-.709,3.9-3.062,5.06-13.376,6.64-27.3,11.474-42.255,12.957a25.77,25.77,0,0,1-9.283-.677c-5.093-1.386-7.316-4.77-6.64-9.992a24.571,24.571,0,0,1,.58-3Q24,192.459,31.684,163.628c.677-2.482,1.321-4.964.677-7.607a6.646,6.646,0,0,0-4.319-4.8c-4.319-1.708-8.767-1.321-13.183-.516a39.542,39.542,0,0,0-13.311,4.674c-.774.451-1.031.258-1.064-.58a18.875,18.875,0,0,0-.355-3.836c-.645-2.417.548-3.578,2.514-4.609a132.47,132.47,0,0,1,28.718-10.8,64.731,64.731,0,0,1,18.6-2.514,20.513,20.513,0,0,1,5.06.838c4.674,1.386,6.865,4.577,6.414,9.411a19.416,19.416,0,0,1-.612,3.32c-5.415,19.21-10.411,38.548-15.471,57.855-.645,2.482-1.321,5-.677,7.607a6.744,6.744,0,0,0,4.319,4.835,20.141,20.141,0,0,0,10.153.9,42.735,42.735,0,0,0,16.341-5.028c.774-.419,1.031-.29,1.064.58C76.615,214.392,76.744,215.456,76.873,216.971Z" transform="translate(0 -90.141)" fill="#005eb5" />
                                <path id="Path_1089" data-name="Path 1089" d="M144,18.114A18.05,18.05,0,1,1,126.014,0,18.061,18.061,0,0,1,144,18.114Z" transform="translate(-73.154)" fill="#005eb5" />
                            </g>
                        </svg>

                    </div>
                    <h2> {languageSelector?.common?.are_you_sure} </h2>
                    <p> {languageSelector?.common?.booking_request_des} </p>
                </div>
                <div className="alert-footer-box">
                    <Button
                        BtnText="No"
                        BtnColor="cancel"
                        BtnType="button"
                        onClick={onHide}
                    />
                    <Button
                        BtnText="Yes"
                        BtnColor="primary"
                        BtnType="button"
                        disabled={loading}
                        hasSpinner={loading}
                        onClick={onSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ComfirmRequestModal