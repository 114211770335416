import {
    IS_REFER_LOADING,
    REFER_HISTORY_LIST_SUCCESS, REFER_HISTORY_LIST_ERROR,
    REFER_TOTAL_POINTS_SUCCESS, REFER_TOTAL_POINTS_ERROR,
    REFERRAL_REDEEM_SUCCESS, REFERRAL_REDEEM_ERROR
} from "../actions/Type";

const initialState = {
    refer_history: {},
    refer_total_points: {},
    referral_redeem: {},
    loading: false,
};

export const ReferEarnReducers = (
    state = initialState,
    { type, payload } = {}
) => {
    switch (type) {
        case IS_REFER_LOADING:
            return {
                ...state,
                loading: true,
            };
        case REFER_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                refer_history: payload,
                loading: false,
            };
        case REFER_HISTORY_LIST_ERROR:
            return {
                ...state,
                refer_history: payload,
                loading: false,
            }
        case REFER_TOTAL_POINTS_SUCCESS:
            return {
                ...state,
                refer_total_points: payload,
                loading: false,
            };
        case REFER_TOTAL_POINTS_ERROR:
            return {
                ...state,
                refer_total_points: payload,
                loading: false,
            }
        case REFERRAL_REDEEM_SUCCESS:
            return {
                ...state,
                referral_redeem: payload,
                loading: false,
            };
        case REFERRAL_REDEEM_ERROR:
            return {
                ...state,
                referral_redeem: payload,
                loading: false,
            }
        default:
            return state;
    }
};