import React, { useEffect, useState } from "react";
import "./Auth.scss";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FieldText } from "../../components/InputText/InputText";
import { Regex } from "../../Utils/Regex";
import { useDispatch, useSelector } from "react-redux";
import { OtpVerification, ResnedOtp, StudentRegister } from "../../redux/actions/AuthAction";
import OtpInput from "react-otp-input";
import { GetCountryList } from "../../redux/actions/HomePageActions";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from "./../../components/Buttons/Buttons";
import TextErrorMsg from "../../components/TextErrorMsg/TextErrorMsg";
import PhoneCountryInput from "../../components/Numberinput/PhoneCountryInput";

const Createaccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [emailAddress, setEmailAddress] = useState();
    const [otp, setOtp] = useState();
    const [otpError, setOtpError] = useState(false);
    const [counter, setCounter] = useState(60);
    const [signUpClick, setSignUpClick] = useState(false)
    const [otpClick, setOtpClick] = useState(false)
    const [timeZoneList, setTimeZoneList] = useState()
    const [selectedOption, setSelectedOption] = useState({});

    const isLoading = useSelector((state) => state.auth.loading);
    const signupSuccessSelector = useSelector((state) => state.auth.user_register);
    const OTPSuccessSelector = useSelector((state) => state.auth.otp_verification);

    const countryList = useSelector((state) => state?.home?.get_country_list);
    const registerSuccess = useSelector((state) => state?.auth?.user_register?.status_code === 200);
    const resendOtpSuccess = useSelector((state) => state.auth?.resend_otp_success?.data?.status);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const SignupValidationSchema = Yup.object().shape({
        first_name: Yup.string().required(languageSelector?.validation?.first_name),
        last_name: Yup.string().required(languageSelector?.validation?.last_name),
        email: Yup.string().email(languageSelector?.validation?.email_invaild).required(languageSelector?.validation?.email_required),
        phone_number: Yup.string().min(8, languageSelector?.validation?.phone_invaild).max(12, languageSelector?.validation?.phone_invaild).matches(Regex.MOBILE_NUMBER_REGEX, languageSelector?.validation?.phone_invaild).required(languageSelector?.validation?.phone_required),
        password: Yup.string().required(languageSelector?.validation?.password).min(6, languageSelector?.validation?.password_digits).max(16, languageSelector?.validation?.password_digits).matches(Regex.password, languageSelector?.validation?.password_case),
        password_confirmation: Yup.string().required(languageSelector?.validation?.confirm_password_required).oneOf([Yup.ref('password'), null], languageSelector?.validation?.password_not_match),
        // Confirm password should be same !
        country_id: Yup.string().required(languageSelector?.validation?.select_country),
        country_time: Yup.string().required(languageSelector?.validation?.select_timezone),
        term_condition: Yup.bool() // use bool instead of boolean
            .oneOf([true], languageSelector?.validation?.accept_terms_condition)
    })

    useEffect(() => {
        dispatch(GetCountryList());
    }, [dispatch]);

    useEffect(() => {
        if (show) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            setCounter(60);
        }
    }, [counter, show]);

    useEffect(() => {
        if (countryList?.length > 0) {
            let countryArr = countryList.filter(value => value.id == "101")
            setTimeZoneList(JSON.parse(countryArr[0].timezones))
        }
    }, [countryList])


    const handleOnChange = (e, data) => {
        if (e.target.name === "phone_number") {
            if (e.target.value) {
                let countryData = countryList.filter(value => (value.phonecode == selectedOption?.phonecode) && (value.name.toLowerCase() == selectedOption?.name.toLowerCase()))
                data.setFieldValue('country_id', countryData[0].id)
                setTimeZoneList(JSON.parse(countryData[0].timezones))
            }
        }
    }

    const handleSignupSubmit = (value) => {
        let formData = new FormData();
        formData.append("first_name", value.first_name);
        formData.append("last_name", value.last_name);
        formData.append("phone_number", value.phone_number);
        formData.append("phone_code", value.phone_code);
        formData.append("email", value.email);
        formData.append("password", value.password);
        formData.append("password_confirmation", value.password_confirmation);
        if (value.referral_code) {
            formData.append("referral_code", value.referral_code);
        }
        formData.append("country_id", value.country_id);
        formData.append("country_time", value.country_time);
        formData.append("term_condition", value.term_condition === true ? 1 : 0);
        formData.append("device_name", 1);
        dispatch(StudentRegister(formData));
        setEmailAddress(value.email)
        setSignUpClick(true)
    }

    useEffect(() => {
        if (signupSuccessSelector && Object.keys(signupSuccessSelector).length > 0 && signUpClick) {
            if (signupSuccessSelector.status && signupSuccessSelector.data.is_verify) {
                setShow(true);
                setSignUpClick(false)
            }
        }
    }, [signupSuccessSelector])


    const handleClose = () => {
        setShow(false);
        setCounter(60);
    };

    const handleOtp = () => {
        if (otp?.toString().length < 4 || otp == undefined) {
            setOtpError(true);
        }
        if (counter > 0 && registerSuccess) {
            let formData = new FormData();
            formData.append("email", emailAddress);
            formData.append("otp", otp);
            formData.append("type_required", 1);
            setOtpClick(true);
            dispatch(OtpVerification(formData));
        } else if (counter === 0 && resendOtpSuccess) {
            let formData = new FormData();
            formData.append("email", emailAddress);
            formData.append("otp", otp);
            formData.append("type_required", 1);
            setOtpClick(true);
            dispatch(OtpVerification(formData));
        }
    };

    const handleResendOtp = () => {
        if (counter === 0 && emailAddress !== undefined && emailAddress !== "" && emailAddress !== null) {
            dispatch(ResnedOtp({ email: emailAddress }));
            setCounter(60)
            setOtp("")
        }
    };

    useEffect(() => {
        if (Object.keys(OTPSuccessSelector).length > 0 && show) {
            if (OTPSuccessSelector.data.status) {
                setShow(false);
                navigate("/student-login");
                setOtpClick(false)
            } else {
                setOtp("")
            }
        }
    }, [OTPSuccessSelector])

    useEffect(() => {
        if (otp?.toString().length === 4) {
            setOtpError(false)
        }
    }, [otp])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    return (
        <>
            <div className="login-main-section createaccount-page">
                <div className="login-main-section1">
                    <div className="login-account">
                        <div className="position-relative login-top-content">
                            <h1> {languageSelector?.mobile_signup?.welcome_back} </h1>
                            <p> {languageSelector?.mobile_signup?.create_account_web_des} </p>
                            <div className="view-courses">
                                <Link className="btn becomaninstructor" to="/student-login"> {languageSelector?.mobile_signIn?.login_button} </Link>
                            </div>
                        </div>
                    </div>
                    <div className="crose-button">
                        <Link className="btn becomaninstructor" to="/">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.484"
                                height="20.479"
                                viewBox="0 0 20.484 20.479"
                            >
                                <path
                                    id="Icon_ionic-ios-close"
                                    data-name="Icon ionic-ios-close"
                                    d="M23.954,21.528l7.316-7.316a1.714,1.714,0,0,0-2.424-2.424L21.53,19.1l-7.316-7.316a1.714,1.714,0,1,0-2.424,2.424l7.316,7.316-7.316,7.316a1.714,1.714,0,0,0,2.424,2.424l7.316-7.316,7.316,7.316a1.714,1.714,0,0,0,2.424-2.424Z"
                                    transform="translate(-11.285 -11.289)"
                                    fill="#fff"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>

                <div className="login-main-section2">
                    <div className="position-relative login-details-sec">
                        <h1> {languageSelector?.mobile_signup?.create_account} </h1>
                        <p> {languageSelector?.mobile_signup?.create_text} </p>
                        <Formik
                            initialValues={{
                                first_name: "",
                                last_name: "",
                                email: '',
                                phone_number: "",
                                phone_code: "+91",
                                password: '',
                                password_confirmation: "",
                                country_id: "101",
                                country_time: "",
                                term_condition: false
                            }}
                            validationSchema={SignupValidationSchema}
                            onSubmit={handleSignupSubmit}
                        >
                            {formik => {
                                return (
                                    <Form onChange={(e) => handleOnChange(e, formik)}>
                                        <FormikFromFunc />
                                        <div className="accountfieldmain">
                                            <div className="accountfield">
                                                <div className="accountfield-first">
                                                    <FieldText
                                                        name="first_name"
                                                        type="text"
                                                        placeholder={languageSelector?.placeholder?.first_name}
                                                        maxLength="40"
                                                    />
                                                </div>

                                                <div className="accountfield-first">
                                                    <FieldText
                                                        name="last_name"
                                                        type="text"
                                                        placeholder={languageSelector?.placeholder?.last_name}
                                                        maxLength="20"
                                                    />
                                                </div>
                                            </div>

                                            <div className="accountfield">
                                                {/*  */}
                                                <div className="accountfield-first fix-placeholder">
                                                    <PhoneCountryInput
                                                        selectedOption={selectedOption}
                                                        setSelectedOption={setSelectedOption}
                                                        formik={formik}
                                                    />
                                                </div>
                                                <div className="accountfield-first">
                                                    <FieldText
                                                        name="email"
                                                        type="email"
                                                        placeholder={languageSelector?.placeholder?.email}
                                                    />
                                                </div>
                                            </div>

                                            <div className="accountfield">
                                                <div className="accountfield-first">
                                                    <FieldText
                                                        name="password"
                                                        type="password"
                                                        showHide={true}
                                                        placeholder={languageSelector?.placeholder?.password}
                                                    />
                                                </div>
                                                <div className="accountfield-first">
                                                    <FieldText
                                                        name="password_confirmation"
                                                        type="password"
                                                        showHide={true}
                                                        placeholder={languageSelector?.placeholder?.re_passwrod}
                                                    />
                                                </div>
                                            </div>

                                            <div className="accountfield">
                                                <div className="accountfield-first">
                                                    <FieldText name="referral_code" type="text" placeholder={languageSelector?.placeholder?.referral} />
                                                </div>
                                                <div className="accountfield-first dropdown-select fix-placeholder">
                                                    <Field as="select" name="country_id" value={formik.values.country_id} className='select-control w-100' disabled>
                                                        <option value=""> {languageSelector?.placeholder?.select} </option>
                                                        {countryList.map((value, index) => {
                                                            return (
                                                                <option value={value.id} key={index}> {value.name} </option>
                                                            )
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="country_id" component={TextErrorMsg} />
                                                    <span className="placeholder-name"> {languageSelector?.placeholder?.country} </span>
                                                </div>
                                            </div>

                                            <div className="accountfield">
                                                <div className="accountfield-first dropdown-select fix-placeholder">
                                                    <div className="App">
                                                        <Field as="select" name="country_time" className='select-control w-100'>
                                                            <option value=""> {languageSelector?.placeholder?.select} </option>
                                                            {timeZoneList && timeZoneList.map((value, index) => {
                                                                return (
                                                                    <option value={`${value.zoneName} ${value.tzName} ${value.gmtOffsetName}`} key={index}> {(value.gmtOffsetName)} {value.zoneName}</option>
                                                                )
                                                            })}
                                                        </Field>
                                                        <ErrorMessage name="country_time" component={TextErrorMsg} />
                                                        <span className="placeholder-name"> {languageSelector?.placeholder?.time_zone} </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accountfield">
                                                <div className="check-box-create">
                                                    <Field name="term_condition">
                                                        {({ form, field }) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <input
                                                                        name={field.name}
                                                                        type="checkbox"
                                                                        id={field.name}
                                                                        onChange={e => form.setFieldValue(field.name, e.target.checked)}
                                                                    />
                                                                    <label htmlFor={field.name}>
                                                                        {languageSelector?.mobile_signup?.agree_to_the} <a target="_blank" href="/terms-and-conditions"> {languageSelector?.mobile_signup?.terms} </a> {languageSelector?.mobile_signup?.and} <a target="_blank" href="/privacy-policy">{languageSelector?.mobile_signup?.privacy}</a>
                                                                    </label>
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    </Field>
                                                </div>
                                            </div>
                                            <ErrorMessage name="term_condition" component={TextErrorMsg} />
                                            <div className="accountfield-signup">
                                                <div className="signup">
                                                    <Button
                                                        BtnText={languageSelector?.mobile_signup?.signup}
                                                        BtnColor="primary becomaninstructor"
                                                        BtnType="submit"
                                                        disabled={isLoading}
                                                        hasSpinner={isLoading && signUpClick}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>

            <Modal
                className="center-modal verification-modal"
                show={show}
            >
                <Modal.Header closeButton onClick={handleClose}>
                    <Modal.Title> {languageSelector?.mobile_verification?.verification} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bottom-line"> {languageSelector?.mobile_verification?.ver_text} <strong></strong></Modal.Body>
                <Modal.Body className="verification-code-box">
                    <div className="verification-box">
                        <OtpInput
                            value={otp}
                            onChange={(code) => setOtp(code)}
                            numInputs={4}
                            isInputNum={true}
                            shouldAutoFocus={true}
                        />
                    </div>
                    {otpError && (
                        <span className="validation-text"> {languageSelector?.validation?.otp_invalid} </span>
                    )}
                    {counter < 1 ? (
                        <div className="email-send m-auto">
                            <Button
                                BtnText={languageSelector?.mobile_verification?.resend}
                                BtnColor="text"
                                BtnType="button"
                                onClick={handleResendOtp}
                            />
                        </div>
                    ) : (
                        <p>{counter} {languageSelector?.mobile_verification?.sec}</p>
                    )}
                    <div className="email-send m-auto">
                        <Button
                            BtnText={languageSelector?.mobile_verification?.verify}
                            BtnColor="primary becomaninstructor"
                            BtnType="button"
                            onClick={handleOtp}
                            disabled={isLoading}
                            hasSpinner={isLoading && otpClick}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
};

export default Createaccount;
