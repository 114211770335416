import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InstructorsStudent from "../../components/InstructorsStudent/Instructorsstudent";
import Dropdown from "react-bootstrap/Dropdown";
import { GetInstructorList, GetTutorLanguage, GetTutorSubjects, ResetGetInstructorList } from "../../redux/actions/instructorAction";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { toast } from "react-toastify";
import { getUserSettings } from "../../Utils/Function";
import { Pagination } from "../../components/Hooks/Pagination";
import { RangeSlider, InputGroup, InputNumber } from 'rsuite';
import "./RangeSlider.scss"
import "../../style.scss";
import Button from "../../components/Buttons/Buttons";
import { useLocation } from "react-router-dom";

const Instructors = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const userLocalStorageData = getUserSettings()
    let userDetail = userLocalStorageData?.user

    const instructorListSelector = useSelector((state) => state?.instructor?.instructor_list);
    const tutorLanguages = useSelector((state) => state?.instructor?.tutor_language);
    const tutorSubjects = useSelector((state) => state?.instructor?.tutor_subject);
    const wishlistSelector = useSelector(state => state.instructor.add_favorite)
    const isFilterLoading = useSelector(state => state.instructor.loading);
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const [selectClassType, setSelectClassType] = useState()
    const [priceRangeSliderValue, setPriceRangeSliderValue] = useState([0, 1000]);
    const [currentPage, setCurrentPage] = useState(1)
    const [searchString, setSearchString] = useState()
    const [instructors, setInstructors] = useState();
    const [state, setState] = useState({
        userlang: "",
        tutor_name: "",
        subjectTutor: location?.state ? location?.state?.subject_id : "",
        logincheck: "",
        filter: false,
        sort: ""
    });
    useEffect(() => {
        dispatch(GetTutorSubjects());
        dispatch(GetTutorLanguage());
    }, [dispatch]);

    useEffect(() => {
        dispatch(GetInstructorList({ ...state, page: state.filter ? "" : currentPage, user_id: userDetail?.id }));
        return () => dispatch(ResetGetInstructorList());
    }, [state, dispatch, currentPage]);

    useEffect(() => {
        if (instructorListSelector) {
            setInstructors(instructorListSelector?.data?.data);
        }
    }, [instructorListSelector, dispatch]);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setState((preValue) => {
            return {
                ...preValue,
                filter: true,
                [name]: value
            };
        });
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        setState({ ...state, tutor_name: searchString, filter: true })
    };

    const handleRangeSubmit = (e) => {
        e.preventDefault()
        if (selectClassType) {
            let priceRange = {
                min: priceRangeSliderValue[0],
                max: priceRangeSliderValue[1]
            }
            dispatch(GetInstructorList({ ...state, page: currentPage, class_type: selectClassType, ...priceRange }));
            setState({ ...state, class_type: selectClassType, ...priceRange })
        } else {
            toast.error(languageSelector?.inst_page?.please_select_class_type, { theme: "colored" })
        }
    }

    const handleResetFilter = (e) => {
        e.preventDefault()
        setState({
            userlang: "",
            tutor_name: "",
            subjectTutor: "",
            logincheck: "",
            page_no: currentPage,
            sort: ""
        });
        setSelectClassType("")
        setPriceRangeSliderValue([0, 1000])
        setSearchString("")
        setInstructors(instructorListSelector?.data?.data);
    }

    useEffect(() => {
        if (Object.keys(wishlistSelector).length > 0) {
            if (wishlistSelector.status) {
                dispatch(GetInstructorList({ ...state, page: currentPage, user_id: userDetail?.id }));
            }
        }
    }, [wishlistSelector])

    return (
        <>
            <div id="footer-bottom" className="instrutors courses-page">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.inst_page?.page_title}</h1>
                    <p>
                        Home / <span> {languageSelector?.inst_page?.page_title} </span>
                    </p>
                </div>

                <div className="easyfind-box">
                    <div className="easy-box-ab">
                        <div className="container">
                            <div className="easy-box-content">
                                <h1> {languageSelector?.inst_page?.inst_easy} </h1>

                                <div className="input-content-easy instructors-top-four">
                                    <div className="easy-box-1 dropdown-select fix-placeholder instructors-top-four1">
                                        <div className="App">
                                            <select
                                                name="subjectTutor"
                                                onChange={handleOnChange}
                                                value={state.subjectTutor}
                                            >
                                                <option value=""> {languageSelector?.inst_page?.inst_select} </option>
                                                {tutorSubjects &&
                                                    tutorSubjects.sort((a, b) => (a.tutor_sub_name.toLowerCase() > b.tutor_sub_name.toLowerCase()) ? 1 : -1).map((value, index) => {
                                                        return (
                                                            <option value={value.tutor_sub_name} key={index}> {value.tutor_sub_name} </option>
                                                        )
                                                    })}
                                            </select>
                                        </div>
                                        <span className="placeholder-name"> {languageSelector?.inst_page?.inst_tutor} </span>
                                    </div>

                                    <div className="easy-box-1 dropdown-select fix-placeholder instructors-top-four1">
                                        <div className="App">
                                            <select
                                                name="class_type"
                                                onChange={(e) => setSelectClassType(e.target.value)}
                                                value={selectClassType}
                                            >
                                                <option value=""> {languageSelector?.inst_page?.inst_select} </option>
                                                <option value="on_demand"> {languageSelector?.inst_page?.inst_demand} </option>
                                                <option value="problem_solving"> {languageSelector?.inst_page?.inst_solving} </option>
                                                <option value="schedule"> {languageSelector?.inst_page?.inst_schedule} </option>
                                            </select>
                                        </div>
                                        <span className="placeholder-name"> {languageSelector?.inst_page?.inst_type} </span>
                                    </div>

                                    <div className="easy-box-1 range-section instructors-top-four1">
                                        <div className="range-dropdown fix-placeholder counter-code-price">
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    <p> <span>${priceRangeSliderValue[0]}</span> - <span>${priceRangeSliderValue[1]}</span> </p>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <form onSubmit={handleRangeSubmit}>
                                                        <RangeSlider
                                                            progress
                                                            className="mb-4"
                                                            value={priceRangeSliderValue}
                                                            onChange={value => {
                                                                setPriceRangeSliderValue(value);
                                                            }}
                                                            min={0}
                                                            max={1000}
                                                        />

                                                        <div className="min-max-section">
                                                            <div>{languageSelector?.inst_page?.inst_min}</div>
                                                            <div>{languageSelector?.inst_page?.inst_max}</div>
                                                        </div>
                                                        <InputGroup>
                                                            <InputNumber
                                                                min={0}
                                                                max={1000}
                                                                value={priceRangeSliderValue[0]}
                                                                onChange={nextValue => {
                                                                    const [_, end] = priceRangeSliderValue;
                                                                    if (nextValue > end) {
                                                                        return;
                                                                    }
                                                                    setPriceRangeSliderValue([nextValue, end]);
                                                                }}
                                                            />
                                                            <InputGroup.Addon>{languageSelector?.inst_page?.inst_to}</InputGroup.Addon>
                                                            <InputNumber
                                                                min={0}
                                                                max={1000}
                                                                value={priceRangeSliderValue[1]}
                                                                onChange={nextValue => {
                                                                    const [start, _] = priceRangeSliderValue;
                                                                    if (start > nextValue) {
                                                                        return;
                                                                    }
                                                                    setPriceRangeSliderValue([start, nextValue]);
                                                                }}
                                                            />
                                                        </InputGroup>

                                                        <Button
                                                            BtnText={languageSelector?.inst_page?.inst_search}
                                                            name="Search"
                                                            BtnColor="search-find"
                                                            BtnType="submit"
                                                            disabled={isFilterLoading}
                                                            hasSpinner={isFilterLoading}
                                                        />
                                                    </form>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <span className="placeholder-name"> {languageSelector?.inst_page?.inst_price} </span>
                                        </div>
                                    </div>

                                    <div className="easy-box-1 dropdown-select online fix-placeholder instructors-top-four1">
                                        <div className="App">
                                            <select
                                                name="logincheck"
                                                onChange={handleOnChange}
                                                value={state.logincheck}
                                            >
                                                <option value=""> {languageSelector?.inst_page?.inst_select} </option>
                                                <option value="1"> {languageSelector?.inst_page?.inst_online} </option>
                                                <option value="0"> {languageSelector?.inst_page?.inst_offline} </option>
                                            </select>
                                        </div>
                                        <span className="placeholder-name">{languageSelector?.inst_page?.inst_available}</span>
                                    </div>
                                </div>

                                <div className="input-content-easy">
                                    <div className="easy-box-1 search-here">
                                        <form onSubmit={handleSearchSubmit}>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={languageSelector?.inst_page?.inst_search}
                                                    name="tutor_name"
                                                    value={searchString}
                                                    onChange={(e) => setSearchString(e.target.value)}
                                                />
                                                <div className="input-group-prepend">
                                                    <button
                                                        className="btn"
                                                        type="submit"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16.132"
                                                            height="16.132"
                                                            viewBox="0 0 16.132 16.132"
                                                        >
                                                            <g
                                                                id="Icon_feather-search"
                                                                data-name="Icon feather-search"
                                                                transform="translate(-3 -3)"
                                                            >
                                                                <path
                                                                    id="Path_144"
                                                                    data-name="Path 144"
                                                                    d="M10.26,3A7.26,7.26,0,1,1,5.126,5.126,7.212,7.212,0,0,1,10.26,3Zm0,12.906A5.646,5.646,0,1,0,4.613,10.26,5.653,5.653,0,0,0,10.26,15.906Z"
                                                                    fill="#828282"
                                                                />
                                                                <path
                                                                    id="Path_145"
                                                                    data-name="Path 145"
                                                                    d="M27.79,28.6a.8.8,0,0,1-.57-.236l-3.509-3.509a.807.807,0,0,1,1.141-1.141l3.509,3.509a.807.807,0,0,1-.57,1.377Z"
                                                                    transform="translate(-9.465 -9.465)"
                                                                    fill="#828282"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="easy-box-1 dropdown-select fix-placeholder">
                                        <div className="App">
                                            <select
                                                name="userlang"
                                                onChange={handleOnChange}
                                                value={state.userlang}
                                            >
                                                <option value=""> {languageSelector?.inst_page?.inst_select} {languageSelector?.inst_page?.inst_language} </option>
                                                {tutorLanguages &&
                                                    tutorLanguages.sort((a, b) => (a.language_name.toLowerCase() > b.language_name.toLowerCase()) ? 1 : -1).map((value, index) => {
                                                        return (
                                                            <option value={value.language_name} key={index}> {value.language_name} </option>
                                                        )
                                                    })}
                                            </select>
                                            <span className="placeholder-name"> {languageSelector?.inst_page?.inst_language} </span>
                                        </div>
                                    </div>

                                    <div className="easy-box-1 dropdown-select fix-placeholder">
                                        <div className="App">
                                            <select
                                                name="sort"
                                                onChange={handleOnChange}
                                                value={state.sort}
                                            >
                                                <option value=""> {languageSelector?.inst_page?.inst_select} </option>
                                                <option value="1"> {languageSelector?.inst_page?.inst_asc} </option>
                                                <option value="0"> {languageSelector?.inst_page?.inst_desc} </option>
                                            </select>
                                            <span className="placeholder-name"> {languageSelector?.inst_page?.inst_sort} </span>
                                        </div>
                                    </div>
                                </div>
                                {state.userlang || state.tutor_name || state.subjectTutor || state.logincheck || selectClassType ?
                                    <div className="reset-button-filter">
                                        <button type="button" className="btn text-btn reset-btn" onClick={handleResetFilter}> {languageSelector?.inst_page?.reset_filter} </button>
                                    </div>
                                    :
                                    null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="instructors-card-section">
                    <div className="container">
                        <div className="row g-5">
                            {Object.keys(instructorListSelector).length > 0 ?
                                instructorListSelector?.data?.data?.length > 0 ?
                                    <>
                                        {instructors?.map((ele, index) => (
                                            <React.Fragment key={index}>
                                                <InstructorsStudent key={ele.id} isFav={ele.is_instruc_fav} ele={{ ...ele }} />
                                            </React.Fragment>
                                        ))}

                                        <div className="col-md-12">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={instructorListSelector?.data?.total}
                                                pageSize={instructorListSelector?.data?.per_page}
                                                onPageChange={(page) => {
                                                    setCurrentPage(page)
                                                    setState({ ...state, filter: false })
                                                }}
                                            />
                                        </div>
                                    </>
                                    :
                                    <NoDataFound />
                                :
                                [1, 2, 3, 4, 5, 6].map((_, index) => {
                                    return (
                                        <div className='col-md-12 col-lg-6 col-12' key={index}>
                                            <Skeleton height={310} borderRadius="2.6rem" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Instructors;
