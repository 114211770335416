import { RATING_REVIEW_LIST_ERROR, RATING_REVIEW_LIST_SUCCESS, IS_RATING_LOADING, RATING_REVIEW_ADD_ERROR, RATING_REVIEW_ADD_SUCCESS } from "../actions/Type";

const initialState = {
    rating_review_list: {},
    loading: false,
    add_rating_review: {}
};

export const ReviewAndRatingReducers = (
    state = initialState,
    { type, payload } = {}
) => {
    switch (type) {
        case IS_RATING_LOADING:
            return {
                ...state,
                loading: true,
            };
        case RATING_REVIEW_LIST_SUCCESS:
            return {
                ...state,
                rating_review_list: payload,
                loading: false,
            };
        case RATING_REVIEW_LIST_ERROR:
            return {
                ...state,
                rating_review_list: payload,
                loading: false,
            }

        case RATING_REVIEW_ADD_SUCCESS:
            return {
                ...state,
                add_rating_review: payload,
                loading: false,
            };
        case RATING_REVIEW_ADD_ERROR:
            return {
                ...state,
                add_rating_review: payload,
                loading: false,
            }
        default:
            return state;
    }
};