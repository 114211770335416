import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";
import "../components/PageNotFound/PageNotFound.scss"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <section>
            {/* <h1>Unauthorized</h1>
            <br />
            <p>You do not have access to the requested page.</p>
            <div className="flexGrow">
                <button className="btn" onClick={goBack}>Go Back</button>
            </div> */}

            <div className="oopss">
            <div className='error-text'>
                <img src="/images/sad404.svg" alt="404" />
                <span>Unauthorized</span>
                <p className="p-a">.You do not have access to the requested page</p>
                <Link type="button" className="back" onClick={goBack}> Go Back </Link>
            </div>
            </div>
        </section>
        
    )
}

export default Unauthorized
