import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Buttons/Buttons";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { GroupClassBookingAction, ResetGroupClassBookingAction } from "../../../redux/actions/GroupClassActions";
import { ProfileGetAction } from '../../../redux/actions/CompleteProfileAction';
import PaymentCheckout from "../../../pages/Studentclasstype/PaymentCheckout";
import EditIcon from "../../../pages/Studentclasstype/EditIcon";

const StepTwo = ({ subscriptionValue, amount, setActiveStep }) => {
    const { id, name } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    const prevPageData = location?.state
    const [couponAmount, setCouponAmount] = useState(0)

    const [couponId, setCouponId] = useState()
    const [submitClick, setSubmitClick] = useState(false)

    const isLoadingSelector = useSelector((state) => state?.group?.loading)
    const paymentSelector = useSelector((state) => state?.group?.group_class_booking)

    useEffect(() => {
        setActiveStep(name)
    }, [name])

    const handlePrevious = () => {
        if (subscriptionValue) {
            navigate(`/group-classes`);
        } else {
            navigate(`/group-class/payment-method/${id}`, { state: prevPageData });
            setActiveStep('payment-method');
        }
    }

    const handleStripePayment = () => {
        let data = {
            amount: subscriptionValue ? 0 : grandTotalAmount(amount, couponAmount),
            coupon_amount: couponAmount,
            couponId: couponId,
            groupclassId: prevPageData.groupclassId,
            instructor_id: prevPageData.instructor_id,
            pay_from: prevPageData.pay_from,
        }
        if(prevPageData.payment_mode !== "wallet"){
            data["card_id"] = prevPageData.card_id
            data["last4"] = prevPageData.last4
        }
        dispatch(GroupClassBookingAction(data));
        setSubmitClick(true)
    }

    const grandTotalAmount = (amount, discount) => {
        let finalAmount = (Number(amount) - Number(discount))
        return finalAmount.toFixed(2)
    }

    useEffect(() => {
        if (Object.keys(paymentSelector).length > 0 && submitClick) {
            if (paymentSelector.status) {
                navigate("/group-classes")
                setSubmitClick(false)
                dispatch(ResetGroupClassBookingAction())
                dispatch(ProfileGetAction("student"))
            } else {
                setSubmitClick(false)
            }
        }
    }, [paymentSelector, submitClick])

    var finalPayAmount = (Number(amount) - Number(couponAmount)).toFixed(2)
    
    return (
        <>
            <div className="personaldetails-main-section on-demand-class-type">
                <h2>Checkout</h2>
                <div className="on-demand-class-content">
                    <div className="row g-3 g-md-5">
                        <div className="col-md-8 col-sm-12 order-2 order-md-0">

                            <PaymentCheckout pageType="groupclass" isCoupon={subscriptionValue ? false : true} isSubscription={subscriptionValue} setCouponId={setCouponId} amount={amount} grandTotalAmount={grandTotalAmount} couponAmountFn={setCouponAmount} />

                            <div className="d-flex justify-content-center mt-5 paypalBtnGroup">
                                <Button
                                    name="Back"
                                    BtnText="Back"
                                    BtnColor="yellow"
                                    BtnType="button"
                                    onClick={handlePrevious}
                                />
                                {prevPageData.payment_mode === "paypal" ?
                                    <PayPalScriptProvider
                                        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                                    >
                                        <PayPalButtons
                                            createOrder={(_, actions) => {
                                                return actions.order
                                                    .create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: finalPayAmount,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                    .then((orderId) => {
                                                        return orderId;
                                                    });
                                            }}
                                            forceReRender={[finalPayAmount]}
                                            className="heroBtnbutton"
                                            onApprove={(_, actions) => {
                                                return actions.order.capture().then((details) => {
                                                    let data = {
                                                        amount: grandTotalAmount(amount, couponAmount),
                                                        coupon_amount: couponAmount,
                                                        couponId: couponId,
                                                        groupclassId: prevPageData.groupclassId,
                                                        instructor_id: prevPageData.instructor_id,
                                                        pay_from: 4,
                                                        order_id: details.id,
                                                        order_status: details.status,
                                                        payer_detail: details.payer
                                                    }
                                                    dispatch(GroupClassBookingAction(data));
                                                    setSubmitClick(true)
                                                });
                                            }}
                                            style={{
                                                layout: 'horizontal',
                                                color: 'blue',
                                                shape: 'rect',
                                                label: 'pay',
                                                height: 55,
                                                tagline: false
                                            }}
                                        />
                                    </PayPalScriptProvider>
                                    :
                                    <Button
                                        BtnText="Proceed to Pay"
                                        name="pay"
                                        BtnColor="primary"
                                        BtnType="button"
                                        onClick={handleStripePayment}
                                        disabled={isLoadingSelector}
                                        hasSpinner={submitClick && isLoadingSelector}
                                    />
                                }
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <div className="personaldetails-section payable-amount-card-box">
                                <div className="row align-items-center">
                                    <div className="col-7">
                                        <span className="amount-text">Group Class</span>
                                    </div>
                                    <div className="col-5 text-end">
                                        <Link className="btn edit-btn" to={`/group-classes`}>
                                            <EditIcon />
                                        </Link>
                                    </div>

                                </div>
                            </div>

                            <div className="personaldetails-section payable-amount-card-box">
                                <div className="row align-items-center">
                                    <div className="col-7">
                                        <span className="amount-text">
                                            {prevPageData.payment_mode === "card" ? "Card" : prevPageData.payment_mode === "paypal" ? "PayPal" : "Wallet"}
                                        </span>
                                        {prevPageData.payment_mode === "card" ? <p className="account-number-code">xxxx xxxx xxxx {prevPageData?.last4}</p> : null}
                                    </div>

                                    <div className="col-5 text-end">
                                        <div className="btn edit-btn" onClick={() => navigate(`/group-class/payment-method/${id}`, { state: prevPageData })}>
                                            <EditIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default StepTwo