import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import "./Instructorprofile.scss";
import { FieldText } from "../../components/InputText/InputText";
import { ChooseFile } from "../../components/ChooseFile/ChooseFile";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import TextErrorMsg from "../../components/TextErrorMsg/TextErrorMsg";
import * as Yup from "yup";
import { GetCategory } from "../../redux/actions/HomePageActions";
import { GetInstructorClassLevel } from "../../redux/actions/instructorAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { EditGroupClassAction, ResetEditGroupClassAction, ResetViewGroupClassAction, ViewGroupClassAction } from "../../redux/actions/GroupClassActions";
import Button from "../../components/Buttons/Buttons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import ImagePreview from "../../components/ImagePreview";
import { GlobalGroupClassListAction, ResetGlobalGroupClassListAction } from "../../redux/actions/GroupClassActions";
import { getUserSettings, isValidHttpUrl } from "../../Utils/Function";

const Editgroupclass = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams()
    const categoryList = useSelector((state) => state.home?.get_category);
    const classLevel = useSelector((state) => state?.instructor?.instructor_class_level);
    const GroupClassDetailSelector = useSelector((state => state.group.group_class_view))
    const isLoading = useSelector(state => state.group.loading)
    const groupClassEditSelector = useSelector(state => state.group.group_class_edit)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(GetCategory());
        dispatch(GetInstructorClassLevel());
        dispatch(ViewGroupClassAction(id))

        return () => dispatch(ResetViewGroupClassAction())
    }, []);

    const [state, setState] = useState({
        topic: "",
        courseId: location?.state ? location.state.course_id : "",
        date: "",
        page_no: 1
    });

    const handleAddGroupClassSubmit = (values) => {
        if (values.topic_class?.length == values.number_of_class) {
            let formData = new FormData();
            formData.append("groupId", id);
            formData.append("class_name", values.class_name);
            formData.append("courseId", values.courseId);
            formData.append("price", values.price);
            formData.append("level_id", values.level_id);
            formData.append("number_of_class", values.number_of_class);
            formData.append("duration", values.duration);
            formData.append("total_seat", values.total_seat);
            formData.append("full_description", values.full_description);
            formData.append("description", values.description);
            if (typeof values.picture === "object") {
                formData.append("picture", values.picture);
            }
            formData.append("topic_class", JSON.stringify(values.topic_class));
            dispatch(EditGroupClassAction(formData));
        } else {
            toast.error(`Classes in this course must be equal to number of classes`, { theme: "colored" });
        }
    };

    useEffect(() => {
        if (Object.keys(groupClassEditSelector).length > 0) {
            if (groupClassEditSelector.status) {
                navigate("/instructor/group-classes")
            }
            dispatch(ResetEditGroupClassAction())
        }
    }, [groupClassEditSelector])

    // const filteredTime = (time) => {
    //     const currentDate = new Date();
    //     const selectedDate = new Date(time);
    //     return currentDate.getTime() < selectedDate.getTime();
    // };

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    /*scheduled class those dates should be disabled for the instructor dateoiker code */
    const groupClassListSelector = useSelector((state) => state.group.group_class_global_list);

    useEffect(() => {
        dispatch(GlobalGroupClassListAction({ ...state, user_id: getUserSettings()?.user?.id }));
        dispatch(GetCategory());
        return () => dispatch(ResetGlobalGroupClassListAction())
    }, [dispatch]);

    const [disabledDateTimeRanges, setDisabledDateTimeRanges] = useState([]);

      useEffect(() => {
        if (groupClassListSelector?.data?.length > 0) {
          const newDisabledDateTimeRanges = [];
          groupClassListSelector.data.forEach((value) => {
            if (value.group_course_class?.length > 0) {
                value.group_course_class?.forEach((val) => {
                    const date = moment(val.time).format("YYYY-MM-DD");
                    const time = moment(val.time).format("LT");
                    //Check if date already exists in disabledDateTimeRanges
                    const existingDateIndex = newDisabledDateTimeRanges.findIndex(item => item.date === date);
                    if (existingDateIndex !== -1) {
                      // Date exists, add time to existing date's times array
                      newDisabledDateTimeRanges[existingDateIndex].times.push(time);
                    } else {
                      // Date does not exist, create new date object with time array
                      newDisabledDateTimeRanges.push({ date: date, times: [time] });
                    }
                });
            }
          });
          setDisabledDateTimeRanges(newDisabledDateTimeRanges);
        }
      }, [groupClassListSelector.data]);

        const filteredTime = (time) => {
            const selectedDate = moment(time).format("YYYY-MM-DD");
            const disabledDateRange = disabledDateTimeRanges.find(range => range.date === selectedDate);
            if (disabledDateRange) {
                //const selectedTime = moment(time).format("HH:mm");
                const selectedTime = moment(time).format("LT");
                return !disabledDateRange.times.includes(selectedTime);
            }
            return true;
        };

    return (
        <>
            <div id="footer-bottom" className="instructor-profile addnewblog-page">
                <Header />
                <div className="instru-bg-image">
                    <h1>  {languageSelector?.group_class_side?.edit_group_class} </h1>
                    <p>Home / <span>   {languageSelector?.group_class_side?.edit_group_class}  </span></p>
                </div>
                <div className="myprofile-section">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-md-12 col-lg-4 col-xl-4">
                                <Instructorsidebar />
                            </div>

                            <div className="col-md-12 col-lg-8 col-xl-8">
                                <div className="profile-top-heading">
                                    <h2> {languageSelector?.group_class_side?.edit_group_class}</h2>
                                </div>
                                <div className="my-profile-details">
                                    {Object.keys(GroupClassDetailSelector).length > 0 ?
                                        <div className="manages-section-main">
                                            <div className="tab-section-profile">
                                                {categoryList?.data && categoryList?.data?.length > 0 && classLevel?.length ?
                                                    <Formik
                                                        initialValues={{
                                                            class_name: GroupClassDetailSelector.data?.class_name,
                                                            courseId: GroupClassDetailSelector.data?.categoryId,
                                                            price: GroupClassDetailSelector.data?.price,
                                                            level_id: GroupClassDetailSelector.data?.level_id,
                                                            number_of_class: GroupClassDetailSelector.data?.number_of_class,
                                                            duration: GroupClassDetailSelector.data?.duration,
                                                            total_seat: GroupClassDetailSelector.data?.total_seat,
                                                            full_description: GroupClassDetailSelector.data?.full_description,
                                                            description: GroupClassDetailSelector.data?.description,
                                                            picture: GroupClassDetailSelector.data?.picture,
                                                            // topic_class: [{ topic_class: "", date_time: "", time: "" }]
                                                            topic_class: GroupClassDetailSelector.data?.group_course_class?.map(value => (
                                                                {
                                                                    topic_class: value.topic_class,
                                                                    date_time: new Date(value.time),
                                                                    time: moment(value.time).format()
                                                                }
                                                            ))
                                                        }}
                                                        validationSchema={() => Yup.object().shape({
                                                            class_name: Yup.string().required(languageSelector?.validation?.class_name),
                                                            courseId: Yup.string().required(languageSelector?.validation?.select_course),
                                                            price: Yup.string().required(languageSelector?.validation?.price),
                                                            level_id: Yup.string().required(languageSelector?.validation?.subject_level),
                                                            number_of_class: Yup.string().required(languageSelector?.validation?.number_of_classes),
                                                            duration: Yup.string().required(languageSelector?.validation?.duration),
                                                            total_seat: Yup.string().required(languageSelector?.validation?.total_seats),
                                                            full_description: Yup.string().required(languageSelector?.validation?.full_description),
                                                            description: Yup.string().required(languageSelector?.validation?.description),
                                                            picture: Yup.string().required(languageSelector?.validation?.picture_required),
                                                            topic_class: Yup.array().of(
                                                                Yup.object().shape({
                                                                    topic_class: Yup.string().required(languageSelector?.validation?.topic_name),
                                                                    date_time: Yup.string().required(languageSelector?.validation?.time).nullable(),
                                                                })
                                                            ),
                                                        })}
                                                        onSubmit={handleAddGroupClassSubmit}
                                                    >
                                                        {(formik) => (
                                                            <Form>
                                                            <FormikFromFunc />
                                                                <div className="bolg-setion">
                                                                    <div className="blog-title">
                                                                        <FieldText
                                                                            name="class_name"
                                                                            type="text"
                                                                            placeholder={languageSelector?.placeholder?.class_name}
                                                                            defaultValue={formik.values.class_name}
                                                                        />
                                                                    </div>
                                                                    <div className="dropdown-select fix-placeholder">
                                                                        <div className="App">
                                                                            <Field
                                                                                as="select"
                                                                                name="courseId"
                                                                                className="select-control w-100"
                                                                                value={formik.values.courseId}
                                                                            >
                                                                                <option value=""> {languageSelector?.placeholder?.select} </option>
                                                                                {categoryList?.data?.map((value, index) => {
                                                                                    return (
                                                                                        <option value={value.id} key={index}>
                                                                                            {value.category_name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </Field>
                                                                            <ErrorMessage name="courseId" component={TextErrorMsg} />
                                                                            <span className="placeholder-name">  {languageSelector?.placeholder?.categroy}  </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="blog-title">
                                                                        <FieldText
                                                                            name="price"
                                                                            type="number"
                                                                            placeholder={languageSelector?.placeholder?.enter_price}
                                                                            defaultValue={formik.values.price}
                                                                        />
                                                                    </div>
                                                                    <div className="dropdown-select fix-placeholder">
                                                                        <div className="App">
                                                                            <Field
                                                                                as="select"
                                                                                name="level_id"
                                                                                className="select-control w-100"
                                                                                value={formik.values.level_id}
                                                                            >
                                                                                <option value=""> {languageSelector?.placeholder?.select_level} </option>
                                                                                {classLevel.map((value) => (
                                                                                    <option key={value.id} value={value.id}>
                                                                                        {value.class_name}
                                                                                    </option>
                                                                                ))}
                                                                            </Field>
                                                                            <ErrorMessage name="level_id" component={TextErrorMsg} />
                                                                            <span className="placeholder-name"> {languageSelector?.placeholder?.level} </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="blog-title">
                                                                        <FieldText
                                                                            name="number_of_class"
                                                                            type="number"
                                                                            placeholder={languageSelector?.placeholder?.number_class}
                                                                            defaultValue={formik.values.number_of_class}
                                                                        />
                                                                    </div>

                                                                    <div className="blog-title">
                                                                        <FieldText
                                                                            name="duration"
                                                                            type="number"
                                                                            placeholder={languageSelector?.placeholder?.duration}
                                                                            defaultValue={formik.values.duration}
                                                                        />
                                                                    </div>

                                                                    <div className="blog-title">
                                                                        <FieldText
                                                                            name="total_seat"
                                                                            type="number"
                                                                            placeholder={languageSelector?.placeholder?.total_seats}
                                                                            defaultValue={formik.values.total_seat}
                                                                        />
                                                                    </div>

                                                                    <div className="descriptio">
                                                                        <div className="form-group floating-label textarea-limit-block">
                                                                            <Field name="full_description">
                                                                                {({ form, field }) => {
                                                                                    return (
                                                                                        <>
                                                                                            <textarea
                                                                                                id="textarea"
                                                                                                className="form-control text-area-limit"
                                                                                                rows="5"
                                                                                                placeholder=" "
                                                                                                name={field.name}
                                                                                                onChange={(e) =>
                                                                                                    form.setFieldValue(
                                                                                                        field.name,
                                                                                                        e.target.value
                                                                                                    )
                                                                                                }
                                                                                                maxLength="1000"
                                                                                                value={formik.values.full_description}
                                                                                            >
                                                                                            </textarea>
                                                                                            <label htmlFor="textarea"> {languageSelector?.placeholder?.full_des} </label>
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                            <span className="limit">1000 {languageSelector?.placeholder?.characters_limit}</span>
                                                                        </div>
                                                                        <ErrorMessage name="full_description" component={TextErrorMsg} />
                                                                    </div>

                                                                    <FieldArray
                                                                        name="topic_class"
                                                                        render={({ _, remove, push }) => {
                                                                            return (
                                                                                <div className="w-100">
                                                                                    <div className="addMoreBlock-head">
                                                                                        <h3> {languageSelector?.placeholder?.class_course} </h3>
                                                                                        <button
                                                                                            className="btn"
                                                                                            onClick={() => push({ topic_class: "", date_time: "", time: "" })}
                                                                                            type="button"
                                                                                            style={{
                                                                                                backgroundColor: "#fff",
                                                                                                borderColor: "#fff",
                                                                                                color: "#005EB5",
                                                                                                height: "auto",
                                                                                                padding: 0,
                                                                                                minWIdth: "auto"
                                                                                            }}
                                                                                        >
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="17.5"
                                                                                                height="17.5"
                                                                                                viewBox="0 0 17.5 17.5"
                                                                                            >
                                                                                                <circle
                                                                                                    id="Ellipse_101"
                                                                                                    data-name="Ellipse 101"
                                                                                                    cx="8"
                                                                                                    cy="8"
                                                                                                    r="8"
                                                                                                    transform="translate(0.75 0.75)"
                                                                                                    fill="none"
                                                                                                    stroke="#005eb5"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                    strokeWidth="1.5"
                                                                                                />
                                                                                                <line
                                                                                                    id="Line_176"
                                                                                                    data-name="Line 176"
                                                                                                    x2="6"
                                                                                                    transform="translate(5.75 8.75)"
                                                                                                    fill="none"
                                                                                                    stroke="#005eb5"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                    strokeWidth="1.5"
                                                                                                />
                                                                                                <line
                                                                                                    id="Line_177"
                                                                                                    data-name="Line 177"
                                                                                                    y2="6"
                                                                                                    transform="translate(8.75 5.75)"
                                                                                                    fill="none"
                                                                                                    stroke="#005eb5"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                    strokeWidth="1.5"
                                                                                                />
                                                                                            </svg>
                                                                                            {languageSelector?.group_class_side?.more_topics}
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="addMoreRow-container group_class_edit">
                                                                                        {formik.values.topic_class.length > 0 && formik.values.topic_class.map((value, index) => (
                                                                                            <div className="row g-5" key={index}>
                                                                                                <div className="col-md-5">
                                                                                                    <FieldText
                                                                                                        type="text"
                                                                                                        name={`topic_class.${index}.topic_class`}
                                                                                                        placeholder={languageSelector?.placeholder?.more_topics}
                                                                                                        defaultValue={value.topic_class}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-md-5  date-time-calendar date-time-calendar1 fix-placeholder">
                                                                                                    <Field name={`topic_class.${index}.date_time`} >
                                                                                                        {({ form, field, meta }) => (
                                                                                                            <>
                                                                                                                <DatePicker
                                                                                                                    {...field}
                                                                                                                    name={field.name}
                                                                                                                    selected={form.values.topic_class[index].date_time}
                                                                                                                    onChange={(value) => {
                                                                                                                        form.setFieldValue(field.name, value)
                                                                                                                        form.setFieldValue(`topic_class.${index}.time`, moment(value).format())
                                                                                                                    }}
                                                                                                                    showTimeSelect
                                                                                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                                                                                    className="w-100"
                                                                                                                    placeholderText={languageSelector?.placeholder?.select_date}
                                                                                                                    minDate={index > 0 ? moment(form?.values?.topic_class[index - 1]?.date_time).add(1, "day")._d : new Date()}
                                                                                                                    maxDate={form?.values?.topic_class[index] !== form?.values?.topic_class[form?.values?.topic_class.length - 1] ? moment(form?.values?.topic_class[index + 1]?.date_time).subtract(1, "day")._d : ""}
                                                                                                                    autoComplete='off'
                                                                                                                    filterTime={filteredTime}
                                                                                                                />
                                                                                                                {meta.touched && meta.error &&
                                                                                                                    <div className="validation-text">{meta.error}</div>
                                                                                                                }
                                                                                                            </>
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <span className="placeholder-name"> {languageSelector?.placeholder?.select_date} </span>
                                                                                                </div>
                                                                                                <div className="accountfield-first cros-button-sub col-md-2 text-end">
                                                                                                    {index > 0 ?
                                                                                                        <button
                                                                                                            onClick={() => remove(index)}
                                                                                                            value={index}
                                                                                                            type="button"
                                                                                                            className={
                                                                                                                index < 1
                                                                                                                    ? `btn becomaninstructor`
                                                                                                                    : `active btn becomaninstructor`
                                                                                                            }
                                                                                                        >
                                                                                                            <svg
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="20.687"
                                                                                                                height="26.597"
                                                                                                                viewBox="0 0 20.687 26.597"
                                                                                                            >
                                                                                                                <path
                                                                                                                    id="Icon_material-delete-forever"
                                                                                                                    data-name="Icon material-delete-forever"
                                                                                                                    d="M8.978,28.142A2.964,2.964,0,0,0,11.933,31.1H23.754a2.964,2.964,0,0,0,2.955-2.955V10.41H8.978Zm3.635-10.521L14.7,15.538l3.147,3.133,3.133-3.133,2.083,2.083-3.133,3.133,3.133,3.133L20.976,25.97l-3.133-3.133L14.711,25.97l-2.083-2.083,3.133-3.133Zm10.4-11.644L21.537,4.5H14.149L12.672,5.978H7.5V8.933H28.187V5.978Z"
                                                                                                                    transform="translate(-7.5 -4.5)"
                                                                                                                    fill="#fff"
                                                                                                                />
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    />

                                                                    <div className="descriptio">
                                                                        <h3> {languageSelector?.group_class_side?.what_learn} </h3>
                                                                    </div>

                                                                    <div className="descriptio">
                                                                        <div className="form-group floating-label textarea-limit-block">
                                                                            <Field name="description">
                                                                                {({ form, field }) => {
                                                                                    return (
                                                                                        <>
                                                                                            <textarea
                                                                                                rows="5"
                                                                                                className="form-control"
                                                                                                placeholder=" "
                                                                                                name={field.name}
                                                                                                value={formik.values.description}
                                                                                                onChange={(e) =>
                                                                                                    form.setFieldValue(
                                                                                                        field.name,
                                                                                                        e.target.value
                                                                                                    )
                                                                                                }
                                                                                                maxLength="1000"
                                                                                            ></textarea>
                                                                                            <label htmlFor="textarea"> {languageSelector?.placeholder?.descripiton} </label>
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                            <span className="limit">1000 {languageSelector?.placeholder?.characters_limit}</span>
                                                                        </div>
                                                                        <ErrorMessage name="description" component={TextErrorMsg} />
                                                                    </div>

                                                                    <div className="drop-image-left">
                                                                        <div className="drag-image-video">
                                                                            <Field name="picture">
                                                                                {({ form, field }) => {
                                                                                    return (
                                                                                        <ChooseFile
                                                                                            name={field.name}
                                                                                            label={languageSelector?.placeholder?.profile_picture}
                                                                                            handleFileChoose={(e) =>
                                                                                                form.setFieldValue(
                                                                                                    field.name,
                                                                                                    e.picture
                                                                                                )
                                                                                            }
                                                                                            error="File should be .jpg .png .jpeg and Size < 5MB"
                                                                                            accept={["jpg", "jpeg", "png"]}
                                                                                            acceptInputFile="image/jpg, image/jpeg, image/png"
                                                                                            Size={6000}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                            <ErrorMessage name="picture" component={TextErrorMsg} />
                                                                        </div>

                                                                        <div className="myprofilebox1">
                                                                            {typeof formik.values.picture !== "object" ?
                                                                                <div className="uploadImg-block d-block w-100">
                                                                                    <ImagePreview file={formik.values.picture} imageAlt="Image" />
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="post-button-blog">
                                                                        <Button
                                                                            BtnText={languageSelector?.group_class_side?.back}
                                                                            name="prev"
                                                                            BtnColor="cancel cancel"
                                                                            BtnType="button"
                                                                            onClick={() => navigate("/instructor/group-classes")}
                                                                        />

                                                                        <Button
                                                                            BtnText={languageSelector?.group_class_side?.save}
                                                                            name="next"
                                                                            BtnColor="primary post"
                                                                            BtnType="submit"
                                                                            disabled={!formik.isValid || isLoading}
                                                                            hasSpinner={isLoading}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default Editgroupclass;
