import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { StepOneCompleteProfile } from "./StepOneCompleteProfile";
import { StepTwoCompleteProfile } from "./StepTwoCompleteProfile";
import { StepThreeCompleteProfile } from "./StepThreeCompleteProfile";
import { StepFourCompletProfile } from "./StepFourCompletProfile";
import { StepFiveCompleteProfile } from "./StepFiveCompleteProfile";
import "../../style.scss";
import "./CompleteProfile.scss"
import "./../../components/Header/Header.scss";
import { getUserSettings } from "../../Utils/Function";
import { toast } from "react-toastify";
import { StepSixCompleteProfile } from "./StepSixCompleteProfile";
import { Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import GoogleTranslate from "../../components/Header/GoogleTranslate";

const Newcompleteprofile = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(id);
    const completeProfileStatus = getUserSettings().user.complete_profile
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        if (completeProfileStatus == 2) {
            navigate("/");
            toast.warning(languageSelector?.complete_profile?.already_fill_cp, { theme: "colored" })
        }
    }, [])

    const steps = [
        { label: languageSelector?.complete_profile?.personal_details, key: "personalDetails" },
        { label: languageSelector?.complete_profile?.qualifications, key: "qualifications" },
        { label: languageSelector?.complete_profile?.teaching_details, key: "teachingDetails" },
        { label: languageSelector?.complete_profile?.add_availability, key: "addAvailability" },
        { label: languageSelector?.complete_profile?.bank_details, key: "bankDetails" },
        { label: languageSelector?.complete_profile?.confirmation, key: "confirmation" },
    ];
    return (
        <>
            <div id="footer-bottom" className="instructorcompleteprofile">
                {/* <Header /> */}

                <div className="top-navbar" id="header">
                    <div className="container">
                        {["lg"].map((expand, index) => (
                            <Navbar key={index} expand={expand} className="mb-3">
                                <Navbar.Brand>
                                    <img
                                        src="/images/logowhite.png"
                                        alt="Logo"
                                        className="logo-header"
                                    />
                                </Navbar.Brand>
                                <div className="header-button-group">
                                    <div className="multi-language">
                                        <div className="App">
                                            <GoogleTranslate />
                                        </div>
                                    </div>
                                </div>
                            </Navbar>
                        ))}
                    </div>
                </div>

                <div className="instru-bg-image">
                    <h1> {languageSelector?.complete_profile?.page_title} </h1>
                    <p>
                        Home /<span> {languageSelector?.complete_profile?.page_title}</span>
                    </p>
                </div>
                <div className="completeprofile completeProfile-container">
                    <div className="container">
                        <div className="row">
                            <div className="complete-profile-step completeProfile-step-wrapper">
                                <ul className="step-progressbar-ul">
                                    {steps.map((data, idx) =>
                                        <li key={data.key} className={`sp-item ${data.key} ${idx <= steps.findIndex(f => f.key === activeStep) ? 'active' : ""} ${idx < steps.findIndex(f => f.key === activeStep) ? 'saved' : ""}`}>
                                            <span className="sp-count"> 0{idx + 1} </span>
                                            <h5 className="sp-title"> {data.label}</h5>
                                        </li>
                                    )}
                                </ul>
                            </div>

                            <div className="step-content-block">
                                {activeStep === 'personalDetails' && <StepOneCompleteProfile activeStep={activeStep} setActiveStep={setActiveStep} />}
                                {activeStep === 'qualifications' && <StepTwoCompleteProfile activeStep={activeStep} setActiveStep={setActiveStep} />}
                                {activeStep === 'teachingDetails' && <StepThreeCompleteProfile activeStep={activeStep} setActiveStep={setActiveStep} />}
                                {activeStep === 'addAvailability' && <StepFourCompletProfile activeStep={activeStep} setActiveStep={setActiveStep} />}
                                {activeStep === 'bankDetails' && <StepFiveCompleteProfile activeStep={activeStep} setActiveStep={setActiveStep} />}
                                {activeStep === 'confirmation' && <StepSixCompleteProfile activeStep={activeStep} setActiveStep={setActiveStep} />}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copy-righyt">
                    <p>Copyright © {new Date().getFullYear()} Hire Instructor</p>
                </div>
                {/* <Footer /> */}
            </div>
        </>
    );
};
export default Newcompleteprofile;
