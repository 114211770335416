import axios from "axios";
import { toast } from "react-toastify";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    IS_REFER_LOADING,
    REFER_HISTORY_LIST_SUCCESS, REFER_HISTORY_LIST_ERROR,
    REFER_TOTAL_POINTS_SUCCESS, REFER_TOTAL_POINTS_ERROR,
    REFERRAL_REDEEM_SUCCESS, REFERRAL_REDEEM_ERROR
} from "./Type";
import HandleError from "./HandleError";

export const ReferHistoryListAction = () => async (dispatch) => {
    dispatch({ type: IS_REFER_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/referral-history-list`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: REFER_HISTORY_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: REFER_HISTORY_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetReferHistoryListAction = () => async (dispatch) => {
    dispatch({
        type: REFER_HISTORY_LIST_SUCCESS,
        payload: {},
    });
}

export const ReferTotalPointsAction = () => async (dispatch) => {
    dispatch({ type: IS_REFER_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/total-referral`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: REFER_TOTAL_POINTS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: REFER_TOTAL_POINTS_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ReferralRedeemAction = (formdata) => async (dispatch) => {
    dispatch({ type: IS_REFER_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/referral-redeem`, formdata, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: REFERRAL_REDEEM_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: REFERRAL_REDEEM_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetReferralRedeemAction = () => async (dispatch) => {
    dispatch({
        type: REFERRAL_REDEEM_SUCCESS,
        payload: {},
    });
}