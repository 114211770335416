import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardList, ResetCardCreate } from "../../redux/actions/PaymentAction";
import CardListView from "../../components/CardListView";
import AddCardModal from "../../components/AddCardModal";
import { getUserSettings } from "../../Utils/Function";

const PaymentMethod = ({ verficationStatus = false, walletShow = true, type = "student", setState, state, setMethodPayment, methodPayment }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [submitClick, setSubmitClick] = useState(false);
    const cardList = useSelector((state) => state.card?.card_list?.data);
    const cardSuccessfulllyCreated = useSelector((state) => state.card?.create_card);
    const isLoading = useSelector((state) => state?.card.loading);
    const user_detail = getUserSettings();
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(CardList(type));
    }, [dispatch]);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleWalletCheckbox = (e) => {
        const { checked } = e.target
        setState({ ...state, pay_from: checked ? 2 : 0 })
        setMethodPayment(checked ? "wallet" : "")
    }

    const handleRadioInput = (value) => {
        setState({ ...state, card_id: value.id, last4: value.last4, pay_from: 1 });
    };

    useEffect(() => {
        if (Object.keys(cardSuccessfulllyCreated).length > 0 && submitClick) {
            if (cardSuccessfulllyCreated.status) {
                setSubmitClick(false);
                setShow(false);
                dispatch(ResetCardCreate());
                dispatch(CardList(type));
            } else {
                setSubmitClick(false);
                dispatch(ResetCardCreate());
            }
        }
    }, [cardSuccessfulllyCreated, submitClick]);

    return (
        <>
            <div className="personaldetails-section">
                {walletShow ?
                    <div className="select-subject-level-card">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <span className="amount-text2"> {languageSelector?.verification_badge?.wallet_balance} </span>
                                <h3 className="amount-price">${user_detail?.user?.wallet ? Number(user_detail?.user?.wallet).toFixed(0) : 0}</h3>
                            </div>

                            <div className="col-3 text-end">
                                <div className="radio-box radio-box2">
                                    <div className="check-box-create">
                                        <input
                                            type="checkbox"
                                            id="wallet_check_box"
                                            name="wallet_check_box"
                                            onChange={handleWalletCheckbox}
                                            value={user_detail?.user?.wallet ? Number(user_detail?.user?.wallet).toFixed(0) : 0}
                                            checked={methodPayment === "wallet" ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }

                <div className="select-subject-level-card payment-box-main-content">
                    <div className="paymentmethod-box">
                        <div className="tow-type-card-payment pb-2">
                            <form className="payment-type row g-3 g-lg-5">
                                <div className="col-md-12 col-lg-6 col-sm-12">
                                    <div className="stripe">
                                        <div className="form-check">
                                            <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                            >
                                                <img src="/images/credit-card2.png" alt="" /> {languageSelector?.verification_badge?.debit_card}
                                            </label>
                                            <input
                                                checked={methodPayment == "card" ? true : false}
                                                className="form-check-input"
                                                type="radio"
                                                name="payment_type_select"
                                                id="flexRadioDefault1"
                                                value="card"
                                                onChange={(e) => setMethodPayment(e.target.value)}
                                                disabled={verficationStatus}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-sm-12">
                                    <div className="stripe">
                                        <div className="form-check">
                                            <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault3"
                                            >
                                                <img src="/images/paypal2.png" alt="" /> {languageSelector?.verification_badge?.paypal}
                                            </label>
                                            <input
                                                checked={methodPayment == "paypal" ? true : false}
                                                className="form-check-input"
                                                type="radio"
                                                name="payment_type_select"
                                                id="flexRadioDefault3"
                                                value="paypal"
                                                onChange={(e) => setMethodPayment(e.target.value)}
                                                disabled={verficationStatus}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {methodPayment == "card" ? (
                            <CardListView
                                handleShow={handleShow}
                                cardList={cardList}
                                handleRadioInput={handleRadioInput}
                            />
                        ) : null}
                    </div>
                </div>
            </div>

            <AddCardModal
                type={type}
                show={show}
                onHide={handleClose}
                clickButton={submitClick}
                setClickButton={setSubmitClick}
                isloading={isLoading}
            />
            
        </>
    );
};
export default PaymentMethod;
