import { Link, useParams } from "react-router-dom"
import Groupclassescard from "../../components/Groupclassescard/Groupclassescard"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { ResetAddFavoriteAction, instructorGroupClassListAction } from "../../redux/actions/instructorAction"
import { isValidHttpUrl } from "../../Utils/Function"
import { baseUrlPostGres } from "../../redux/constant"
import moment from "moment"

const RelatedGroupClass = ({user_id}) => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const groupClassSelector = useSelector(state => state.instructor.instructor_group_class)
    const wishlistSelector = useSelector(state => state.instructor.add_favorite)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(instructorGroupClassListAction({ instructor_id: id, user_id: user_id }))
    }, [dispatch])

    useEffect(() => {
        if (Object.keys(wishlistSelector).length > 0) {
            if (wishlistSelector.status) {
                dispatch(ResetAddFavoriteAction())
                dispatch(instructorGroupClassListAction({ instructor_id: id, user_id: user_id }))
            }
        }
    }, [wishlistSelector])

    
    return (
        Object.keys(groupClassSelector).length > 0 && groupClassSelector?.data?.length > 0 ?
            <div className="about-description groupclass-card">
                <div className="groupclasses">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <h2> {languageSelector?.group_class_home?.group_classes} </h2>
                        </div>
                        <div className="col-lg-6 viewall">
                            <p>
                                <Link className="btn" to="/group-classes"> {languageSelector?.group_class_home?.view_all} </Link>
                            </p>
                        </div>
                        {[...groupClassSelector?.data].sort((a, b) => (a.created_at > b.created_at) ? -1 : 1).map((value, index) => (
                            <div className="col-md-6 col-lg-4 col-12" key={index}>
                                <Groupclassescard
                                    ImageUrl={value.picture ? value.picture : "/images/Groupclasses1.png"}
                                    CourseName={value.class_name}
                                    id={value.id}
                                    HrPrice={Number(value.price).toFixed(0)}
                                    TotalSeats={value.total_seat}
                                    BookedSeats={value?.total_seat - value?.reaming_seat}
                                    ProfileImage={value?.group_user?.profile_image ?
                                        isValidHttpUrl(value?.group_user?.profile_image) ? value?.group_user?.profile_image
                                            : `${baseUrlPostGres()}${value?.group_user?.profile_image}`
                                        : "/images/blank-profile-picture.jpg"}
                                    TeacherName={`${value.group_user.first_name} ${value.group_user.last_name}`}
                                    isVerified={value.group_user?.verified_Sign}
                                    DateTime={moment(value.created_at).format("llll")}
                                    CourseDescription={value?.descriptions}
                                    isWishlist={value?.is_instruc_fav}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            :
            null
    )
}

export default RelatedGroupClass