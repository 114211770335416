import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import { Form } from "react-bootstrap";
import "./Instructorprofile.scss";
import { InstructorBookingRejectAction, InstructorBookingRequestList, BookingRequestAcceptsAction, ResetInstructorBookingRejectAction, ResetBookingRequestAcceptsAction, ResetInstructorBookingRequestList } from "../../redux/actions/instructorAction";
import { useDispatch, useSelector } from "react-redux";
import { removeSocketAllListeners, socket, socketConnection } from "../../redux/constant/Socket";
import { Pagination } from "../../components/Hooks/Pagination";
import TimeDuration from "../../components/TimeDuration/TimeDuration";
import moment from "moment";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import ComfirmRequestModal from "./ComfirmRequestModal";
import { getUserSettings, isValidHttpUrl } from "../../Utils/Function";
import ProblemSolvingDetailModal from "../../components/ProblemSolvingDetailModal";
import Button from "../../components/Buttons/Buttons";
import { baseUrlPostGres } from "../../redux/constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Bookingrequest = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1)
    const [bookingRequestData, setBookingRequestData] = useState()
    const [item, setItem] = useState({ kindOfStand: "all", another: "another" });
    const [show, setShow] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [bookingId, setBookingId] = useState()
    const [timeStatus, setTimeStatus] = useState(false)
    const [acceptSubmit, setAcceptSubmit] = useState(false)
    const [problemSolvingDetail, setProblemSolvingDetail] = useState()
    const [rejectDataModal, setRejectDataModal] = useState()
    const [isUpdate, setIsUpdate] = useState(false)
    const [typeBooking, setTypeBooking] = useState("")
    const { kindOfStand } = item;

    const bookingRequestList = useSelector((state) => state?.instructor?.instructor_booking_request_list);
    const rejectBookingSelector = useSelector(state => state.instructor.booking_reject)
    const BookingAcceptsSelector = useSelector(state => state.instructor.accept_booking_request)
    const isloading = useSelector(state => state.instructor.loading);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        if (bookingRequestList && bookingRequestList?.data?.data?.length > 0) {
            bookingRequestList?.data?.data.forEach(element => {
                element["isloading"] = false
            });
        }
    }, [bookingRequestList])

    const handleProblemSolvingDetailModal = (data, index) => {
        setShow(true)
        setProblemSolvingDetail({ ...data, index })
    };

    const handleClose = () => setShow(false);

    const handleCloseModal = () => {
        setBookingId("")
        setRejectDataModal("")
        setShowRejectModal(false)
    };

    const handleShowRejectModal = (value) => {
        setBookingId(value.id)
        setRejectDataModal(value)
        setShowRejectModal(true)
        setShow(false)
    };

    useEffect(() => {
        if (bookingRequestList) {
            setBookingRequestData(bookingRequestList.data)
        }
    }, [bookingRequestList])

    useEffect(() => {
        removeSocketAllListeners()
        socketConnection()
    }, [])

    useEffect(() => {
        dispatch(InstructorBookingRequestList({ page: currentPage, type_request: typeBooking }));
        return () => dispatch(ResetInstructorBookingRequestList())
    }, [dispatch, currentPage, typeBooking]);

    useEffect(() => {
        if (timeStatus) {
            setTimeout(() => {
                dispatch(ResetInstructorBookingRequestList())
                dispatch(InstructorBookingRequestList());
            }, 2000);
        }
    }, [timeStatus])

    const handleChange = e => {
        e.persist();
        setItem(prevState => ({
            ...prevState,
            kindOfStand: e.target.value
        }));
        setCurrentPage(1)
        setTypeBooking(e.target.value)
        if (e.target.value !== "all") {
            const filterData = bookingRequestList?.data?.data?.filter(value => value.booking_type == e.target.value)
            setBookingRequestData(filterData)
        } else {
            setBookingRequestData(bookingRequestList?.data)
        }
    };

    const handleAcceptRequest = (value, index) => {
        bookingRequestList.data.data[index].isloading = !bookingRequestList.data.data[index].isloading
        setIsUpdate(!isUpdate)
        setAcceptSubmit(true)
        if (value.booking_type === 1) {
            const token = `Bearer ${JSON.parse(localStorage.getItem("security_auth"))?.token}`;
            let socket_emit_data = {
                bookingId: value.id,
                type: value.booking_type,
                student_user_id: value.user_id,
                instructor_user_id: getUserSettings().user.id,
                token
            }
            socket.emit("instructor_booking_request", socket_emit_data);
            socket.on("instructor_booking_zoom_details", (accept) => {
                if (accept?.zoom_url?.length > 0) {
                    dispatch(InstructorBookingRequestList());
                    window.open(accept?.zoom_url, "_blank");
                } else {
                    toast.error(accept.error, { theme: "colored" })
                }
                bookingRequestList.data.data[index].isloading = !bookingRequestList.data.data[index].isloading
            });
        } else if (value.booking_type === 2 || value.booking_type === 3) {
            dispatch(BookingRequestAcceptsAction({
                "bookingId": value.id,
                "type": value.booking_type
            }))
        }
    };

    const handleRejectBooking = () => {
        if (rejectDataModal.booking_type == 1) {
            const token = `Bearer ${JSON.parse(localStorage.getItem("security_auth"))?.token}`;
            let socket_reject_data = {
                bookingId: bookingId,
                student_user_id: rejectDataModal.user_id,
                instructor_user_id: getUserSettings().user.id,
                token
            }
            socket.emit("onDemand_booking_reject", socket_reject_data);
        }
        dispatch(InstructorBookingRejectAction(bookingId))
    }

    useEffect(() => {
        if (Object.keys(rejectBookingSelector).length > 0) {
            if (rejectBookingSelector?.status) {
                dispatch(ResetInstructorBookingRejectAction())
                setBookingId("")
                setRejectDataModal("")
                setShowRejectModal(false)
                dispatch(InstructorBookingRequestList());
            }
        }
    }, [rejectBookingSelector])

    useEffect(() => {
        if (Object.keys(BookingAcceptsSelector).length > 0) {
            if (BookingAcceptsSelector?.status) {
                setAcceptSubmit(false)
                setShow(false)
                dispatch(ResetBookingRequestAcceptsAction())
                dispatch(InstructorBookingRequestList());
            }
        }
    }, [BookingAcceptsSelector])

    return (
        <>
            <div id="footer-bottom" className="instructor-profile">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.booking_request?.page_title} </h1>
                    <p> Home / <span> {languageSelector?.booking_request?.page_title} </span> </p>
                </div>

                <div className="myprofile-section">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-md-12 col-lg-4 col-xl-4">
                                <Instructorsidebar />
                            </div>

                            <div className="col-md-12 col-lg-8 col-xl-8">
                                <div className="profile-top-heading">
                                    <h2> {languageSelector?.booking_request?.page_title} </h2>
                                </div>
                                <div className="booking-request-section">
                                    <div className="booking-filter">
                                        <div className="btn">
                                            <Form.Group controlId="kindOfStand">
                                                <Form.Check value="all" type="radio" label={languageSelector?.booking_request?.all} onChange={handleChange} checked={kindOfStand === "all"} />
                                            </Form.Group>
                                        </div>

                                        <div className="btn">
                                            <Form.Group controlId="kindOfStand2"> <Form.Check value="1" type="radio" label={languageSelector?.booking_request?.on_demand} onChange={handleChange} checked={kindOfStand === "1"} />
                                            </Form.Group>
                                        </div>

                                        <div className="btn">
                                            <Form.Group controlId="kindOfStand3"> <Form.Check value="2" type="radio" label={languageSelector?.booking_request?.scheduled} onChange={handleChange} checked={kindOfStand === "2"} />
                                            </Form.Group>
                                        </div>

                                        <div className="btn">
                                            <Form.Group controlId="kindOfStand4"> <Form.Check value="3" type="radio" label={languageSelector?.booking_request?.Problem_solving} onChange={handleChange} checked={kindOfStand === "3"} />
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className="blog-section">
                                        <div className="container">
                                            <div className="row g-5">
                                                {Object.keys(bookingRequestList).length > 0 ?
                                                    bookingRequestData?.data?.length > 0 ?
                                                        <>
                                                            {[...bookingRequestData?.data].sort((a, b) => (a.created_at > b.created_at) ? -1 : 1).map((elem, index) => (
                                                                <div className="col-12 col-xxl-6" key={index}>
                                                                    <div className="bookingrequest-card-main">
                                                                        <div className="booking-card">
                                                                            <div className="image-box">
                                                                                <img
                                                                                    src={elem?.user?.profile_image ?
                                                                                        isValidHttpUrl(elem?.user?.profile_image) ? elem?.user?.profile_image
                                                                                            : `${baseUrlPostGres()}${elem?.user?.profile_image}`
                                                                                        : "/images/blank-profile-picture.jpg"}
                                                                                    alt={elem?.user?.first_name}
                                                                                />
                                                                            </div>
                                                                            <div className="booking-details">
                                                                                <div className="heading-price">
                                                                                    <h2>{`${elem?.user?.first_name} ${elem?.user?.last_name}`}</h2>
                                                                                    <h3>${elem?.transaction_user?.txn_amount ? elem?.transaction_user?.txn_amount : 0}</h3>
                                                                                </div>
                                                                                <p>
                                                                                    <TimeDuration timerData={elem?.end_time} isHours={elem?.booking_type === 2 || elem?.booking_type === 3 ? true : false} setTimeStatus={setTimeStatus} /> Left
                                                                                </p>
                                                                                <h4> {languageSelector?.booking_request?.subject_level} </h4>
                                                                                <span>
                                                                                    {elem?.user_tutor_subject?.tutor_sub_name} ({elem?.class_level?.class_name})
                                                                                </span>
                                                                                <h4> {languageSelector?.booking_request?.class_type} </h4>
                                                                                <span>
                                                                                    {elem?.booking_type === 1 ? languageSelector?.booking_request?.ondemand_class
                                                                                        : elem?.booking_type === 2 ? `${languageSelector?.booking_request?.scheduled} - `
                                                                                            : elem?.booking_type === 3 ? languageSelector?.booking_request?.Problem_solving
                                                                                                : ""
                                                                                    }
                                                                                    {elem.booking_type == 2 ?
                                                                                        <>
                                                                                            {moment(elem?.class_date, "YYYY/MM/DD").format("DD MMM'YY")} | {elem?.slottime_schudule ? elem?.slottime_schudule?.slot_time : "-"}
                                                                                        </>
                                                                                        : null}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="eject-accept">
                                                                            <button type="button" className="btn reject" onClick={() => handleShowRejectModal(elem)} > {languageSelector?.booking_request?.reject} </button>
                                                                            {elem?.booking_type === 1 || elem?.booking_type === 2 ?
                                                                                <Button
                                                                                    BtnText={languageSelector?.booking_request?.accept}
                                                                                    BtnColor=" accept"
                                                                                    BtnType="button"
                                                                                    disabled={elem.isloading && acceptSubmit}
                                                                                    hasSpinner={elem.isloading && acceptSubmit}
                                                                                    onClick={() => handleAcceptRequest(elem, index)}
                                                                                />
                                                                                :
                                                                                null
                                                                            }

                                                                            {elem?.booking_type === 3 ?
                                                                                <button className="btn accept" onClick={() => handleProblemSolvingDetailModal(elem, index)}> {languageSelector?.booking_request?.view_detail} </button>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={currentPage}
                                                                totalCount={bookingRequestData?.total}
                                                                pageSize={bookingRequestData?.per_page}
                                                                onPageChange={(page) => setCurrentPage(page)}
                                                            />
                                                        </>
                                                        :
                                                        <NoDataFound />
                                                    :
                                                    [1, 2, 3, 4, 5, 6].map((_, index) => {
                                                        return (
                                                            <div className='col-12 col-xxl-6' key={index}>
                                                                <Skeleton height={258} borderRadius="2.5rem" />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <ComfirmRequestModal
                show={showRejectModal}
                onHide={handleCloseModal}
                loading={isloading}
                onSubmit={handleRejectBooking}
            />
            
            <ProblemSolvingDetailModal
                show={show}
                onHide={handleClose}
                loading={isloading}
                clickStatus={acceptSubmit}
                detail={problemSolvingDetail}
                rejected={handleShowRejectModal}
                AcceptBooking={handleAcceptRequest}
            />
        </>
    );
};

export default Bookingrequest;
