import React, { useState, useEffect, useRef,useContext  } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import moment from "moment";
import "./CartPage.scss";
import { useNavigate } from "react-router-dom";
import { CartContext } from '../../pages/Home/CartContext';
import { baseUrlPostGres } from "../../redux/constant";



const CartPage = () => {
    const navigate = useNavigate();
    const { cartItems, setCartItems  } = useContext(CartContext);
    const [subtotal, setSubtotal] = useState(0);

     useEffect(() => {
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);

    const handlebackeClick = () => {
        navigate('/');
    }
    const removeItem = (index) => {
        const updatedCartItems = cartItems.filter((item, i) => i !== index);
        setCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    }
  
  
   
    // Calculate subtotal when cartItems change
    useEffect(() => {
        const total = cartItems.reduce((acc, item) => {
            if (item.price && !isNaN(item.price)) {
                return acc + parseFloat(item.price);
            }
            return acc;
        }, 0);
        setSubtotal(total);
    }, [cartItems]);
    // const cartItems = [
    //     { class_name: "The Complete 2024 Web Development", price: 43.90, discount: 18.00, image: "https://www.bootdey.com/image/220x180/FF0000/000000" },
    //     { class_name: "Web Development", price: 24.89, discount: 0, image: "https://www.bootdey.com/image/220x180/5F9EA0/000000" },
    //     { class_name: "Frontend Designing", price: 200.00, discount: 0, image: "https://www.bootdey.com/image/220x180/9932CC/000000" }
    // ];

    const handleGroupClassDetailClick = (e, id) => {
        e.preventDefault();
        window.open(`/group-class-detail/${id}`, '_blank');
    }

    return (
        <>
            <Header />
            <div id="footer-bottom">
                <div className="container">
                    {cartItems.length > 0 ?( 
                        <>
                        <div className="table-responsive shopping-cart">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Course Name</th>
                                        <th className="text-center">Price</th>
                                       
                                        <th className="text-center"><a className="btn btn-sm btn-outline-danger" href="#">Clear Cart</a></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {cartItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="product-item">
                                                    <a className="product-thumb" href="#"><img src={baseUrlPostGres() + item?.picture} onClick={(e) => handleGroupClassDetailClick(e, item?.id)}/></a>
                                                    <div className="product-info">
                                                        <h4 className="product-title"><a href="#" onClick={(e) => handleGroupClassDetailClick(e, item?.id)}>{item?.class_name}</a></h4>
                                                        <span>Created {moment(item?.created_at, "YYYY/MM/DD").format("MMM DD YYYY")}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center text-lg text-medium" onClick={(e) => handleGroupClassDetailClick(e, item?.id)}>${item?.price}</td>
                                         
                                            {/* <td className="text-center" ><a onClick={() => removeItem(index)} className="remove-from-cart" href="#" data-toggle="tooltip" title data-original-title="Remove item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
    </svg></a></td> */}
                                <td><button onClick={() => removeItem(index)}>Remove</button></td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                         <div className="shopping-cart-footer">
                         <div className="column text-lg">SubTotal: <span className="text-medium">${Number(subtotal).toFixed(2)}</span></div>
                        </div>
                        <div className="shopping-cart-footer">
                            <div className="column">
                                <a className="btn btn-outline-secondary" style={{ backgroundColor: "orange" }} href="#" onClick={handlebackeClick}>Back to Shopping</a>
                            </div>
                            <div className="column">
                                <a className="btn btn-primary" style={{ backgroundColor: "blue" }} href="#" data-toast data-toast-type="success" data-toast-position="topRight" data-toast-icon="icon-circle-check" data-toast-title="Your cart" data-toast-message="is updated successfully!">Update Cart</a>
                                <a className="btn btn-success" style={{ backgroundColor: "green" }} href='/Checkout'>Checkout</a>
                            </div>
                        </div>
                        </> 
                        ) : (
                            <>
                                <div className="empty-section">
                                    <img src="../images/empty-shopping-cart.jpg" alt="Empty Shopping Cart" />
                                    <p className="empty-massage">Your cart is empty. Keep shopping to find a group class!</p>
                                    <a className="btn btn-outline-secondary" style={{ backgroundColor: "orange" }} href="#" onClick={handlebackeClick}>Back to Shopping</a>
                                </div>
                            </> 
                        )
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CartPage;