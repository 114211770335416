import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChooseFile } from "../../components/ChooseFile/ChooseFile";
import { FieldText } from "../../components/InputText/InputText";
import { GetInstructorGrades, GetInstructorQualificationLevel } from "../../redux/actions/instructorAction";
import Button from "./../../components/Buttons/Buttons";
import { Formik, Form, Field, ErrorMessage, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import TextErrorMsg from "../../components/TextErrorMsg/TextErrorMsg";
import { useNavigate, useParams } from "react-router-dom";
import { InstructorCompleteProfileStepTwo } from "../../redux/actions/CompleteProfileAction";
import { Regex } from "../../Utils/Regex";
import { GetYearsList } from "../../Utils/Function";

export const StepTwoCompleteProfile = ({ setActiveStep }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [submitClick, setSubmitClick] = useState(false);
    const [endYearNumber, setEndYearNumber] = useState()

    useEffect(() => {
        setActiveStep(id);
    }, [id]);

    const isLoading = useSelector((state) => state.profile.loading);
    const completeProfileSelector = useSelector((state) => state.profile.qualifications);
    const instructorLevel = useSelector((state) => state?.instructor?.instructor_qualifiaction);
    const gradeLevel = useSelector((state) => state?.instructor?.instructor_grades);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(GetInstructorQualificationLevel());
        dispatch(GetInstructorGrades());
    }, [dispatch]);

    const handlePrevious = () => {
        navigate("/completeprofile/personalDetails");
        setActiveStep("personalDetails");
    };

    const StepTwoValidationSchema = Yup.object().shape({
        ins_level_id: Yup.string().required(languageSelector?.validation?.education_level),
        school_name: Yup.string().required(languageSelector?.validation?.school_university_name),
        percentage: Yup.string().required(languageSelector?.validation?.grade),
        full_subject_name: Yup.string().required(languageSelector?.validation?.subject),
        clg_start_year: Yup.string().min(4, languageSelector?.validation?.year_digits).max(4, languageSelector?.validation?.year_digits).matches(Regex.ALLOW_NUMBER_ONLY, languageSelector?.validation?.year_invalid).required(languageSelector?.validation?.year_started),
        clg_end_year: Yup.string().min(4, languageSelector?.validation?.year_digits).max(4, languageSelector?.validation?.year_digits).matches(Regex.ALLOW_NUMBER_ONLY, languageSelector?.validation?.year_invalid).required(languageSelector?.validation?.year_completed),
        certificate_image: Yup.string().required(languageSelector?.validation?.certificate),
        specialization: Yup.array().of(
            Yup.object().shape({
                subject_name: Yup.string().required(languageSelector?.validation?.subject_name),
                grade_id: Yup.string().required(languageSelector?.validation?.grade),
            })
        ),
    });

    const handleStepTwoSubmit = (value) => {
        let formData = new FormData();
        formData.append("ins_level_id", value.ins_level_id);
        formData.append("school_name", value.school_name);
        formData.append("percentage", value.percentage);
        formData.append("subject_name", value.full_subject_name);
        formData.append("clg_start_year", value.clg_start_year);
        formData.append("clg_end_year", value.clg_end_year);
        formData.append("certificate_image", value.certificate_image);
        formData.append("specialization", JSON.stringify(value.specialization));
        dispatch(InstructorCompleteProfileStepTwo(formData));
        setSubmitClick(true);
    };

    useEffect(() => {
        if (Object.keys(completeProfileSelector).length > 0 && submitClick) {
            if (completeProfileSelector.status) {
                navigate("/completeprofile/teachingDetails");
                setActiveStep("teachingDetails");
            } else {
                setSubmitClick(false);
            }
        }
    }, [completeProfileSelector, submitClick]);

    const handleFormChagne = (event) => {
        if (event.target.name === "clg_start_year") {
            const diffYear = new Date().getFullYear() - (Number(event.target.value) - 1)
            setEndYearNumber(diffYear)
        }
    }

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    return (
        <div
            className="personaldetails-main-section"
            style={{ display: id === "qualifications" ? "block" : "none" }}
        >
            <h2> {languageSelector?.complete_profile?.qualifications} </h2>
            <Formik
                initialValues={{
                    ins_level_id: "",
                    school_name: "",
                    percentage: "",
                    full_subject_name: "",
                    clg_start_year: "",
                    clg_end_year: "",
                    certificate_image: "",
                    specialization: [{ subject_name: "", grade_id: "" }]
                }}
                validationSchema={StepTwoValidationSchema}
                onSubmit={handleStepTwoSubmit}
            >
                {(formik) => {
                    return (
                        <Form onChange={handleFormChagne}>
                            <FormikFromFunc />
                            <div className="personaldetails-section">
                                <div className="personal-details-fields complete-profile2">
                                    <div className="accountfieldmain">
                                        <div className="accountfield">
                                            <div className="accountfield-first dropdown-select fix-placeholder">
                                                <div className="App">
                                                    <Field
                                                        as="select"
                                                        name="ins_level_id"
                                                        className="select-control w-100"
                                                    >
                                                        <option value=""> {languageSelector?.placeholder?.select} </option>
                                                        {instructorLevel.map((value, index) => {
                                                            return (
                                                                <option value={value.id} key={index}> {value.inst_levels_name} </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="ins_level_id" component={TextErrorMsg} />
                                                    <span className="placeholder-name"> {languageSelector?.placeholder?.education_level} </span>
                                                </div>
                                            </div>

                                            <div className="accountfield-first">
                                                <FieldText
                                                    name="school_name"
                                                    type="text"
                                                    placeholder={languageSelector?.placeholder?.school_university}
                                                />
                                            </div>

                                            <div className="accountfield-first dropdown-select fix-placeholder">
                                                <div className="App">
                                                    <Field
                                                        as="select"
                                                        name="percentage"
                                                        className="select-control w-100"
                                                    >
                                                        <option value=""> {languageSelector?.placeholder?.select} </option>
                                                        {gradeLevel.map((value, index) => {
                                                            return (
                                                                <option value={value.id} key={index}>
                                                                    {value.grades_name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <span className="placeholder-name"> {languageSelector?.placeholder?.grade} </span>
                                                    <ErrorMessage name="percentage" component={TextErrorMsg} />
                                                </div>
                                            </div>

                                            <div className="accountfield-first">
                                                <FieldText
                                                    name="full_subject_name"
                                                    type="text"
                                                    placeholder={languageSelector?.placeholder?.subject}
                                                />
                                            </div>

                                            <div className="accountfield-first dropdown-select fix-placeholder">
                                                <div className="App">
                                                    <Field
                                                        as="select"
                                                        name="clg_start_year"
                                                        className="select-control w-100"
                                                    >
                                                        <option value=""> {languageSelector?.placeholder?.select} </option>
                                                        {GetYearsList(50).map((value, index) => {
                                                            return (
                                                                <option value={value} key={index}> {value} </option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <span className="placeholder-name"> {languageSelector?.placeholder?.year_started} </span>
                                                    <ErrorMessage name="clg_start_year" component={TextErrorMsg} />
                                                </div>
                                            </div>

                                            <div className="accountfield-first dropdown-select fix-placeholder">
                                                <div className="App">
                                                    <Field
                                                        as="select"
                                                        name="clg_end_year"
                                                        className="select-control w-100"
                                                    >
                                                        <option value=""> {languageSelector?.placeholder?.select} </option>
                                                        {GetYearsList(endYearNumber).map((value, index) => {
                                                            return (
                                                                <option value={value} key={index}>{value}</option>
                                                            );
                                                        })}
                                                    </Field>
                                                    <span className="placeholder-name"> {languageSelector?.placeholder?.year_completed} </span>
                                                    <ErrorMessage name="clg_end_year" component={TextErrorMsg} />
                                                </div>
                                            </div>

                                            <div className="accountfield-first text-area-box">
                                                <div className="drag-image-video">
                                                    <Field name="certificate_image">
                                                        {({ form, field }) => {
                                                            return (
                                                                <ChooseFile
                                                                    name={field.name}
                                                                    label={languageSelector?.placeholder?.certificate}
                                                                    handleFileChoose={(e) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            e.certificate_image
                                                                        )
                                                                    }
                                                                    error="File should be .jpg .png .jpeg and Size < 5MB"
                                                                    accept={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
                                                                    acceptInputFile="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx"
                                                                    Size={6000}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                    <ErrorMessage
                                                        name="certificate_image"
                                                        component={TextErrorMsg}
                                                    />
                                                </div>
                                            </div>

                                            <FieldArray
                                                name="specialization"
                                                render={({ _, remove, push }) => {
                                                    return (
                                                        <React.Fragment>
                                                            <div className="accountfield addsubjects-section">
                                                                <h3> {languageSelector?.my_account?.specialization} </h3>
                                                                <div className="addsubjects">
                                                                    <button
                                                                        className="btn becomaninstructor"
                                                                        onClick={() => push({ subject_name: "", grade_id: "" })}
                                                                        type="button"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="17.5"
                                                                            height="17.5"
                                                                            viewBox="0 0 17.5 17.5"
                                                                        >
                                                                            <circle
                                                                                id="Ellipse_101"
                                                                                data-name="Ellipse 101"
                                                                                cx="8"
                                                                                cy="8"
                                                                                r="8"
                                                                                transform="translate(0.75 0.75)"
                                                                                fill="none"
                                                                                stroke="#005eb5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="1.5"
                                                                            />
                                                                            <line
                                                                                id="Line_176"
                                                                                data-name="Line 176"
                                                                                x2="6"
                                                                                transform="translate(5.75 8.75)"
                                                                                fill="none"
                                                                                stroke="#005eb5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="1.5"
                                                                            />
                                                                            <line
                                                                                id="Line_177"
                                                                                data-name="Line 177"
                                                                                y2="6"
                                                                                transform="translate(8.75 5.75)"
                                                                                fill="none"
                                                                                stroke="#005eb5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="1.5"
                                                                            />
                                                                        </svg>
                                                                        {languageSelector?.my_account?.add_more_button}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {formik.values.specialization.length > 0 && formik.values.specialization.map((value, index) => (
                                                                <React.Fragment key={index}>

                                                                    <div className="complete-profile-add-sub">
                                                                        <div className="accountfield-first">
                                                                            <FieldText
                                                                                type="text"
                                                                                name={`specialization.${index}.subject_name`}
                                                                                placeholder={languageSelector?.placeholder?.specialization_subject}
                                                                                defaultValue={value.subject_name}
                                                                            />
                                                                        </div>

                                                                        <div className="accountfield-first dropdown-select fix-placeholder">
                                                                            <div className="App">
                                                                                <Field
                                                                                    as="select"
                                                                                    className="select-control w-100"
                                                                                    name={`specialization.${index}.grade_id`}
                                                                                    value={value.grade_id}
                                                                                >
                                                                                    <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                                                    {gradeLevel.map((value, index) => {
                                                                                        return (
                                                                                            <option value={value.id} key={index}>
                                                                                                {value.grades_name}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </Field>
                                                                                <span className="placeholder-name"> {languageSelector?.placeholder?.grade} </span>
                                                                                <ErrorMessage name={`specialization.${index}.grade_id`} component={TextErrorMsg} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="accountfield-first cros-button-sub">
                                                                            {index > 0 ?
                                                                                <button
                                                                                    onClick={() => remove(index)}
                                                                                    value={index}
                                                                                    type="button"
                                                                                    className={
                                                                                        index < 1
                                                                                            ? `btn becomaninstructor`
                                                                                            : `active btn becomaninstructor`
                                                                                    }
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="20.687"
                                                                                        height="26.597"
                                                                                        viewBox="0 0 20.687 26.597"
                                                                                    >
                                                                                        <path
                                                                                            id="Icon_material-delete-forever"
                                                                                            data-name="Icon material-delete-forever"
                                                                                            d="M8.978,28.142A2.964,2.964,0,0,0,11.933,31.1H23.754a2.964,2.964,0,0,0,2.955-2.955V10.41H8.978Zm3.635-10.521L14.7,15.538l3.147,3.133,3.133-3.133,2.083,2.083-3.133,3.133,3.133,3.133L20.976,25.97l-3.133-3.133L14.711,25.97l-2.083-2.083,3.133-3.133Zm10.4-11.644L21.537,4.5H14.149L12.672,5.978H7.5V8.933H28.187V5.978Z"
                                                                                            transform="translate(-7.5 -4.5)"
                                                                                            fill="#fff"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>

                                                            ))}
                                                        </React.Fragment>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                {/* <Button
                                    name="previous"
                                    BtnText="Back"
                                    BtnColor="yellow"
                                    BtnType="button"
                                    onClick={handlePrevious}
                                /> */}

                                <Button
                                    BtnText={languageSelector?.complete_profile?.next}
                                    name="next"
                                    BtnColor="primary"
                                    BtnType="submit"
                                    disabled={!formik.isValid || isLoading}
                                    hasSpinner={isLoading && submitClick}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
