import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from '../../redux/actions/AuthAction';

export const StepSixCompleteProfile = ({ setActiveStep }) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const languageSelector = useSelector(state => state.home.dynamic_language);
    
    useEffect(() => {
        setActiveStep(id)
    }, [id])

    const handleGoBack = () => {
        localStorage.removeItem("security_auth");
        localStorage.removeItem("linkedin_oauth2_state");
        navigate('/', true)
        dispatch(setCurrentUser({}));
    }

    return (
        <div className="personaldetails-main-section" style={{ display: id === 'confirmation' ? 'block' : 'none' }}>
            <div className='completeProfile-confirm'>
                <p> {languageSelector?.complete_profile?.complete_profile_success} <br /> {languageSelector?.complete_profile?.under_review}</p>
                <button type='button' className="btn btn-primary mt-5" onClick={handleGoBack}> {languageSelector?.complete_profile?.go_to_home} </button>
            </div>
        </div>
    );
}
