import React, { useState, useEffect } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import Table from 'react-bootstrap/Table';
import '../Instructorprofile/Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReferHistoryListAction, ReferTotalPointsAction, ResetReferHistoryListAction } from '../../redux/actions/RefeEarnActions';
import RedeemReferAmountModal from './RedeemReferAmountModal';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { copyToClipBoard, getUserSettings, isValidHttpUrl } from '../../Utils/Function';
import { baseUrlPostGres } from '../../redux/constant';
import moment from 'moment';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { toast } from 'react-toastify';


const Studentreferearn = () => {
    const dispatch = useDispatch();
    const referralHistorySelector = useSelector(state => state.refer.refer_history)
    const referralPointsSelector = useSelector(state => state.refer.refer_total_points)
    const referralRedeemSelector = useSelector(state => state.refer.referral_redeem)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const [showRedeemPointModal, setShowRedeemPointModal] = useState(false)

    const userDetail = getUserSettings();

    useEffect(() => {
        dispatch(ReferHistoryListAction())
        dispatch(ReferTotalPointsAction())
        return () => dispatch(ResetReferHistoryListAction())
    }, [dispatch])

    const handleOpenRedeemModal = () => {
        if (referralPointsSelector?.data?.totalpoint > 0) setShowRedeemPointModal(true)
    }

    useEffect(() => {
        if (Object.keys(referralRedeemSelector).length > 0) {
            if (referralRedeemSelector.status) {
                dispatch(ReferTotalPointsAction())
            }
        }
    }, [referralRedeemSelector])

    const shareInviteLink = () => {
        var inviteUrl = `https://hireinstructor.sandboxdevelopment.in/student-signup?refer=${userDetail?.user?.Referral_code}`
        var shareText = `Use this invite Code: ${userDetail?.user?.Referral_code} or click on this link to get started- ${inviteUrl} Sign Up using this code and get and redeemed ${referralPointsSelector?.data?.Point} Points. Hurry! The invite code expires soon.`
        if (navigator.share) {
            navigator.share({
                title: 'Share Invite Code',
                text: shareText,
            }).then(() => {
                //toast.success("Thanks for sharing!", { theme: "colored" })
            })
        }
    }

    return (
        <>
            <div id="footer-bottom" className='instructor-profile walletpayment'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.refer_earn?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.refer_earn?.page_title} </span></p>
                </div>

                <div className='myprofile-section'>
                    <div className='container'>
                        <div className='row g-5'>
                            <div className='col-md-12 col-lg-5 col-xl-4'>
                                <Studentsidebar />
                            </div>

                            <div className='col-md-12 col-lg-7 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.refer_earn?.page_title} </h2>
                                </div>
                                <div className='my-profile-details'>
                                    <div className='wallet-card-main-section row g-5'>
                                        <div className='col-lg-12 col-xl-5'>
                                            {Object.keys(referralPointsSelector).length > 0 ?
                                                <div className='wallet-card'>
                                                    <h2> {languageSelector?.refer_earn?.my_credit} </h2>
                                                    <div className='balance-card'>
                                                        <p> {languageSelector?.refer_earn?.available_balance} </p>
                                                        <span>{referralPointsSelector?.data?.totalpoint ? referralPointsSelector?.data?.totalpoint : 0}</span>
                                                    </div>
                                                    <div className='balance-amount'>
                                                        <h3>
                                                            {languageSelector?.refer_earn?.you_can_redeem}
                                                            <b>{referralPointsSelector?.data?.Point ? referralPointsSelector?.data?.Point : 0} {languageSelector?.refer_earn?.points}</b>
                                                            {languageSelector?.refer_earn?.to_credit} ${referralPointsSelector?.data?.Amount ? referralPointsSelector?.data?.Amount : 0} {languageSelector?.refer_earn?.to_your_wallet}
                                                        </h3>
                                                        <button type='button'
                                                            disabled={referralPointsSelector?.data?.totalpoint > 0 ? false : true}
                                                            className="btn"
                                                            onClick={handleOpenRedeemModal}
                                                        >
                                                            {languageSelector?.refer_earn?.redeem_points}
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <Skeleton height={365} borderRadius="2.6rem" />
                                            }
                                        </div>

                                        <div className='col-lg-12 col-xl-7'>
                                            {Object.keys(userDetail).length > 0 ?
                                                <div className='wallet-card wallet-card2 refer'>
                                                    <h2> {languageSelector?.refer_earn?.kick_off_friend} <span>Hire Instructor</span> {languageSelector?.refer_earn?.journey}</h2>
                                                    <h3>
                                                        {languageSelector?.refer_earn?.referral_joins}
                                                        {referralPointsSelector?.data?.Point ? referralPointsSelector?.data?.Point : 0}
                                                        {languageSelector?.refer_earn?.points_in_your} <br /> <b>Hire Instructor</b> {languageSelector?.refer_earn?.wallet}
                                                    </h3>
                                                    <div className='copyclipboard-box'>
                                                        <button type='button' className='btn' onClick={() => copyToClipBoard(userDetail?.user?.Referral_code)}>
                                                            {userDetail?.user?.Referral_code}
                                                            <span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                                                    <g id="Group_5086" data-name="Group 5086" transform="translate(-523.25 -1010.25)">
                                                                        <rect id="Rectangle_2042" data-name="Rectangle 2042" width="12" height="12" rx="2" transform="translate(528 1015)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                        <path id="Path_680" data-name="Path 680" d="M16,8V6a2,2,0,0,0-2-2H6A2,2,0,0,0,4,6v8a2,2,0,0,0,2,2H8" transform="translate(520 1007)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <button type='button' className="btn" onClick={shareInviteLink}>
                                                        {languageSelector?.refer_earn?.invite_your_friend}
                                                    </button>
                                                </div>
                                                :
                                                <Skeleton height={365} borderRadius="2.6rem" />
                                            }
                                        </div>

                                        <div className='col-12'>
                                            {Object.keys(referralHistorySelector).length > 0 ?
                                                <div className='wallet-card transactionhistory'>
                                                    <h2> {languageSelector?.refer_earn?.referral_history} </h2>
                                                    <div className='transactionhistory-table transactionhistory-table-main table-responsive'>
                                                        {referralHistorySelector?.data?.length > 0 ?
                                                            <Table striped responsive>
                                                                <tbody>
                                                                    {referralHistorySelector?.data?.map((value, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className='image-content-box'>
                                                                                    <div className='image-payment'>
                                                                                        <img
                                                                                            src={value?.user_name?.profile_image ?
                                                                                                isValidHttpUrl(value?.user_name?.profile_image) ? value?.user_name?.profile_image
                                                                                                    : `${baseUrlPostGres()}${value?.user_name?.profile_image}`
                                                                                                : "/images/blank-profile-picture.jpg"}
                                                                                            alt={value?.user_name?.first_name}
                                                                                            referrerPolicy="no-referrer"
                                                                                        />
                                                                                    </div>
                                                                                    <div className='name-level'>
                                                                                        <h3 className='text-capitalize'>{value?.user_name?.first_name} {value?.user_name?.last_name}</h3>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{moment(value?.created_at).format("DD MMM'YY")} at {moment(value?.created_at).format("LT")}</td>
                                                                            <td className='text-end'><span className='paid'>{value?.admin_setting?.point} {languageSelector?.refer_earn?.points}</span></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                            :
                                                            <NoDataFound />
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <Skeleton height={365} borderRadius="2.6rem" />
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Footer />
            </div>

            <RedeemReferAmountModal
                show={showRedeemPointModal}
                onHide={() => setShowRedeemPointModal(false)}
                amount={referralPointsSelector?.data?.totalpoint}
            />
        </>
    );
}

export default Studentreferearn
