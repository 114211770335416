import { useSelector } from "react-redux"

const LessonFees = ({ instructorDetails }) => {
    const languageSelector = useSelector(state => state.home.dynamic_language)
    return (
        <div className="feescharges-sec feescharges-sec-card">
            <h2> {languageSelector?.inst_page?.lessons} </h2>
            <div className="ondemand-price">
                <div>
                    <h4> {languageSelector?.inst_page?.inst_demand} </h4>
                    <p> {languageSelector?.inst_page?.per_hour} </p>
                </div>
                <div>
                    <h3> ${instructorDetails?.user_extra_info?.on_demand_price}</h3>
                </div>
            </div>

            <div className="ondemand-price">
                <div>
                    <h4> {languageSelector?.inst_page?.inst_schedule} </h4>
                    <p> {languageSelector?.inst_page?.per_hour} </p>
                </div>
                <div>
                    <h3>${instructorDetails?.user_extra_info?.schedule_price}</h3>
                </div>
            </div>

            <div className="ondemand-price">
                <div>
                    <h4> {languageSelector?.inst_page?.inst_solving} </h4>
                    <p> {languageSelector?.inst_page?.solving_description} </p>
                </div>
                <div>
                    <h3> ${instructorDetails?.user_extra_info?.problem_solving_price}</h3>
                </div>
            </div>

            <div className="monthlysubscription-sec">
                <div className="ondemand-price">
                    <div>
                        <h4> {languageSelector?.inst_page?.monthly_subscription} </h4>
                        <p> {languageSelector?.inst_page?.monthly_sub_description} </p>
                    </div>
                    <div>
                        <h3> ${instructorDetails?.user_extra_info?.monthly_subscription_price} </h3>
                    </div>
                </div>

                <div className="ondemand-price">
                    <div>
                        <h4> {languageSelector?.inst_page?.yearly_subscription} </h4>
                        <p> {languageSelector?.inst_page?.yearly_sub_description} </p>
                    </div>
                    <div>
                        <h3>${instructorDetails?.user_extra_info?.year_subscription_price}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonFees