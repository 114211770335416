import { io } from "socket.io-client";
import { getUserSettings } from "../../Utils/Function";

export const SOCKET_URL = "https://socket-hireinstructor.sandboxdevelopment.in"
// export const SOCKET_URL = "http://192.168.2.14:4015"

export const socket = io(SOCKET_URL);

// socket.on("connect", () => {
//     socket.emit("saveSocketId", { user_id: getUserSettings().user?.id, socketId: socket?.id })
// });

// export let socket = io(SOCKET_URL, {
//     autoConnect: false,
//     transports: ['websocket']
// });

export const socketDisconnect = () => {
    socket.disconnect();
};

// export const sendUserIDToBackend = () => {
//     console.log("socketid", socket.id)
// };

export const removeSocketAllListeners = () => {
    socket.removeAllListeners();
};

export const socketConnection = () => {
    socket.connect()
    socket.on("connect", () => {
        console.log("socket", socket.id)
        socket.emit("saveSocketId", { user_id: getUserSettings().user.id, socketId: socket.id })
    });
};

