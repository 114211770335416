import React, { useEffect } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import '../Instructorprofile/Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ProblemSolvingDetailAction, ResetProblemSolvingDetailAction } from '../../redux/actions/ClassesDetailActions';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { GetFileName, isValidHttpUrl } from '../../Utils/Function';
import { baseUrlPostGres } from '../../redux/constant';
import moment from 'moment';
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";


const Studentcompletedclassesproblemsolving = () => {
    const dispatch = useDispatch()
    const { id, name } = useParams()
    const ProblemSolutionViewSelector = useSelector(state => state.classesDetail.problem_solving_detail)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    useEffect(() => {
        dispatch(ProblemSolvingDetailAction(name, id))
        return () => dispatch(ResetProblemSolvingDetailAction())
    }, [dispatch])



    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.classes_details?.problem_sloving} </h1>
                    <p>Home / {languageSelector?.classes_details?.page_title}  / <span> {languageSelector?.classes_details?.problem_sloving}</span></p>
                </div>

                <div className='myprofile-section'>
                    <div className='container'>
                        <div className='row g-5'>
                            <div className='col-md-12 col-lg-5 col-xl-4'>
                                {name == "student" ?
                                    <Studentsidebar />
                                    :
                                    <Instructorsidebar />
                                }
                            </div>

                            <div className='col-md-12 col-lg-7 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.classes_details?.problem_sloving} </h2>
                                </div>
                                <div className='my-profile-details'>
                                    {Object.keys(ProblemSolutionViewSelector).length > 0 ?
                                        Object.keys(ProblemSolutionViewSelector?.data).length > 0 ?
                                            <div className='problem-solving-box completedclassesproblemsolving'>
                                                <div className="booking-card">
                                                    <div className="image-box">
                                                        {name == "student" ?
                                                            <img
                                                                src={ProblemSolutionViewSelector?.data?.user_instructor?.profile_image ?
                                                                    isValidHttpUrl(ProblemSolutionViewSelector?.data?.user_instructor?.profile_image) ? ProblemSolutionViewSelector?.data?.user_instructor?.profile_image
                                                                        : `${baseUrlPostGres()}${ProblemSolutionViewSelector?.data?.user_instructor?.profile_image}`
                                                                    : "/images/blank-profile-picture.jpg"}
                                                                referrerPolicy="no-referrer"
                                                                alt={ProblemSolutionViewSelector?.data?.user_instructor?.first_name}
                                                            />
                                                            :

                                                            <img
                                                                src={ProblemSolutionViewSelector?.data?.user?.profile_image ?
                                                                    isValidHttpUrl(ProblemSolutionViewSelector?.data?.user?.profile_image) ? ProblemSolutionViewSelector?.data?.user?.profile_image
                                                                        : `${baseUrlPostGres()}${ProblemSolutionViewSelector?.data?.user?.profile_image}`
                                                                    : "/images/blank-profile-picture.jpg"}
                                                                referrerPolicy="no-referrer"
                                                                alt={ProblemSolutionViewSelector?.data?.user?.first_name}
                                                            />
                                                        }
                                                    </div>
                                                    <div className="booking-details">
                                                        <div className="heading-price">
                                                            <div>
                                                                {name == "student" ?
                                                                    <h2>{ProblemSolutionViewSelector?.data?.user_instructor?.first_name} {ProblemSolutionViewSelector?.data?.user_instructor?.last_name} </h2>
                                                                    :
                                                                    <h2>{ProblemSolutionViewSelector?.data?.user?.first_name} {ProblemSolutionViewSelector?.data?.user?.last_name} </h2>
                                                                }
                                                                <p> {moment(ProblemSolutionViewSelector?.data.created_at).fromNow()} </p>
                                                            </div>
                                                            <div>
                                                                <h3>${ProblemSolutionViewSelector?.data?.transaction_user?.txn_amount ? ProblemSolutionViewSelector?.data?.transaction_user?.txn_amount : 0}</h3>
                                                            </div>
                                                        </div>
                                                        <h4> {languageSelector?.classes_details?.subject} </h4>
                                                        <span>{ProblemSolutionViewSelector?.data?.user_tutor_subject?.tutor_sub_name} | {ProblemSolutionViewSelector?.data?.class_level?.class_name}</span>
                                                        <h4> {languageSelector?.classes_details?.title} </h4>
                                                        <span>{ProblemSolutionViewSelector?.data?.tittle}</span>
                                                    </div>
                                                </div>
                                                <h4> {languageSelector?.classes_details?.detailed_problem} </h4>
                                                <span> {ProblemSolutionViewSelector?.data?.description} </span>
                                                {ProblemSolutionViewSelector?.data?.file_doc ?
                                                    <div className="pdf-dection">
                                                        <h4> {languageSelector?.classes_details?.attachment} </h4>
                                                        <div className="pdf-img">
                                                            <div className="pdf-img-box">
                                                                <img src="/images/pdf-icon.png" alt="" />
                                                            </div>
                                                            <div className="pdf-content">
                                                                <div>
                                                                    <span>{GetFileName(ProblemSolutionViewSelector?.data?.file_doc)}</span>
                                                                </div>
                                                                <button className="btn" onClick={() => window.open(`${baseUrlPostGres()}${ProblemSolutionViewSelector?.data?.file_doc}`)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="17.5" viewBox="0 0 14.5 17.5"><g id="Group_4802" data-name="Group 4802" transform="translate(-12507.25 -9581.25)"><path id="Path_650" data-name="Path 650" d="M14,3V6.556a.889.889,0,0,0,.889.889h3.556" transform="translate(12502.556 9579)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path><path id="Path_651" data-name="Path 651" d="M16.143,19H6.857A1.819,1.819,0,0,1,5,17.222V4.778A1.819,1.819,0,0,1,6.857,3h6.5L18,7.444v9.778A1.819,1.819,0,0,1,16.143,19Z" transform="translate(12503 9579)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path><line id="Line_178" data-name="Line 178" y2="5" transform="translate(12514.268 9589)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></line><path id="Path_652" data-name="Path 652" d="M9,14l2.667,2.667L14.333,14" transform="translate(12502.834 9577.777)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path></g></svg>
                                                                    {languageSelector?.classes_details?.download}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {ProblemSolutionViewSelector?.data?.solution_provide ?
                                                    <>
                                                        <div className='providesolution'>
                                                            <h5> {languageSelector?.classes_details?.solution} </h5>
                                                            <span> {moment(ProblemSolutionViewSelector?.data?.solution_provide?.updated_at).fromNow()} </span>
                                                        </div>

                                                        <h4> {languageSelector?.classes_details?.describe_reason} </h4>
                                                        <span> {ProblemSolutionViewSelector?.data?.solution_provide?.provide_solution}</span>
                                                        {ProblemSolutionViewSelector?.data?.solution_provide?.file ?
                                                            <div className="pdf-dection">
                                                                <h4> {languageSelector?.classes_details?.attachment} </h4>
                                                                <div className="pdf-img">
                                                                    <div className="pdf-img-box">
                                                                        <img src="/images/pdf-icon.png" alt="" />
                                                                    </div>
                                                                    <div className="pdf-content">
                                                                        <div><span>{GetFileName(ProblemSolutionViewSelector?.data?.solution_provide?.file)}</span></div>
                                                                        <button
                                                                            className="btn"
                                                                            onClick={() => window.open(`${baseUrlPostGres()}${ProblemSolutionViewSelector?.data?.solution_provide?.file}`)}
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="17.5" viewBox="0 0 14.5 17.5"><g id="Group_4802" data-name="Group 4802" transform="translate(-12507.25 -9581.25)"><path id="Path_650" data-name="Path 650" d="M14,3V6.556a.889.889,0,0,0,.889.889h3.556" transform="translate(12502.556 9579)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path><path id="Path_651" data-name="Path 651" d="M16.143,19H6.857A1.819,1.819,0,0,1,5,17.222V4.778A1.819,1.819,0,0,1,6.857,3h6.5L18,7.444v9.778A1.819,1.819,0,0,1,16.143,19Z" transform="translate(12503 9579)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path><line id="Line_178" data-name="Line 178" y2="5" transform="translate(12514.268 9589)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></line><path id="Path_652" data-name="Path 652" d="M9,14l2.667,2.667L14.333,14" transform="translate(12502.834 9577.777)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path></g></svg>
                                                                            {languageSelector?.classes_details?.download}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            <NoDataFound />
                                        :
                                        <Skeleton height={400} borderRadius="1rem" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Studentcompletedclassesproblemsolving
