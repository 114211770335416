import React, { useEffect, useRef, useState } from 'react'
import { Nav, Navbar, Overlay, Tooltip } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
//import { LogoutAction } from '../../redux/actions/AuthAction';
import { baseUrlPostGres } from '../../redux/constant';
import { getUserSettings, isValidHttpUrl } from '../../Utils/Function';
import '../Instructorsidebar/Instructorsidebar';
import { removeSocketAllListeners, socket, socketConnection } from '../../redux/constant/Socket';
import RatingAndReviewModal from '../RatingAndReviewModal/RatingAndReviewModal';
import { ResetAddReviewRatingAction } from '../../redux/actions/ReviewAndRatingAction';

const Sidenavbar = () => {
    const disptach = useDispatch();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tooltip, setTooltip] = useState(false);
    const target = useRef(null);
    const user_detail = getUserSettings();
    const [ratingModalShow, setRatingModalShow] = useState(false);
    const [onSubmitRating, setOnSubmitRating] = useState(false);
    const [instructorId, setInstructorId] = useState()
    const [bookingID, setBookingID] = useState();
    const ratingSubmitSelector = useSelector(state => state.rating.add_rating_review);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        removeSocketAllListeners()
        socketConnection()
    }, [])

    const copyToClipBoard = (copyMe) => {
        setTooltip(!tooltip)
        navigator.clipboard
            .writeText(copyMe)
            .then(() => {
                setTimeout(() => {
                    setTooltip(false)
                }, 1000)
            })
            .catch((error) => {
                alert("something went wrong");
            })
    };

    // const handleLogout = () => {
    //     dispatch(LogoutAction("student"))
    //     navigate("/")
    // };

    socket.on(`rating_student${user_detail?.user?.id}`, (data) => {
        setRatingModalShow(data.rating)
        setBookingID(data.booking_id)
        setInstructorId(data.instructor_id)
    });

    useEffect(() => {
        if (Object.keys(ratingSubmitSelector).length > 0) {
            if (ratingSubmitSelector.status) {
                setRatingModalShow(false)
                setOnSubmitRating(false)
                dispatch(ResetAddReviewRatingAction())
            }
        }
    }, [ratingSubmitSelector])

    return (
        <>
            <div className='sidemenu-profile'>
                {['lg'].map((expand, index) => (
                    <Navbar key={index} expand={expand}>
                        {/* <Navbar.Brand> <Link to="/"></Link> </Navbar.Brand> */}
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='profile-image-box'>
                                    <div className='profile-img'>
                                        <img
                                            src={user_detail?.user?.profile_image ?
                                                isValidHttpUrl(user_detail?.user?.profile_image) ? user_detail?.user?.profile_image
                                                    : `${baseUrlPostGres()}${user_detail?.user?.profile_image}`
                                                : "/images/blank-profile-picture.jpg"}
                                            referrerPolicy="no-referrer"
                                            alt={user_detail?.user?.first_name}
                                        />
                                    </div>
                                    <div className='profile-details'>
                                        <h2><span className='name'>{user_detail?.user?.first_name} {user_detail?.user?.last_name}</span></h2>
                                        <p>{user_detail?.user?.email}</p>
                                        <div className='followers-following-box'>
                                            <div className="btn">{languageSelector?.side_bar?.followers} <span> {user_detail?.user?.user_followers_count ? user_detail?.user?.user_followers_count : 0} </span></div>
                                            <div className="btn">{languageSelector?.side_bar?.following} <span> {user_detail?.user?.user_following_count ? user_detail?.user?.user_following_count : 0}  </span></div>
                                        </div>
                                    </div>
                                </div>
                                <Nav className="sidebar-menu">
                                    <NavLink to={`/myprofile/${user_detail?.user?.role === 3 ? "instructor" : "student"}`}>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24.349" height="24.345" viewBox="0 0 24.349 24.345">
                                                <path d="M12643.612-3153.285c-.054-.013-.1-.029-.158-.038-.547-.08-1.1-.134-1.646-.241a12.025,12.025,0,0,1-9.281-9.624c-.073-.454-.124-.912-.187-1.369v-1.5c.013-.067.031-.134.041-.2.08-.556.134-1.118.248-1.668a12.026,12.026,0,0,1,9.64-9.214c.445-.073.9-.124,1.344-.184h1.5c.061.013.12.029.181.038.616.1,1.242.153,1.852.292a12,12,0,0,1,9.056,9.618c.077.438.124.883.185,1.322v1.5a1.871,1.871,0,0,0-.041.184c-.079.556-.134,1.118-.245,1.667a12.028,12.028,0,0,1-9.643,9.237c-.448.073-.9.124-1.344.184Zm-9.393-12.022a10.162,10.162,0,0,0,10.136,10.145,10.164,10.164,0,0,0,10.152-10.158,10.159,10.159,0,0,0-10.152-10.133A10.162,10.162,0,0,0,12634.22-3165.307Zm13.767,4.717a4.247,4.247,0,0,0-1.1-.934,3.817,3.817,0,0,0-1.912-.524,7.218,7.218,0,0,0-1.937.121,3.946,3.946,0,0,0-2.049,1.293.744.744,0,0,1-.762.3.728.728,0,0,1-.413-1.185,5.316,5.316,0,0,1,2.455-1.706,5.6,5.6,0,0,1,1.843-.3,8.084,8.084,0,0,1,2.21.187,5.412,5.412,0,0,1,2.779,1.795,2.1,2.1,0,0,1,.226.464.833.833,0,0,1-.508.705.778.778,0,0,1-.276.054A.725.725,0,0,1,12647.986-3160.59Zm-3.644-4.186a3.537,3.537,0,0,1-3.522-3.542,3.537,3.537,0,0,1,3.539-3.522,3.537,3.537,0,0,1,3.525,3.538,3.538,3.538,0,0,1-3.535,3.526Zm-2.052-3.535a2.07,2.07,0,0,0,2.059,2.065,2.068,2.068,0,0,0,2.064-2.062,2.069,2.069,0,0,0-2.062-2.061A2.071,2.071,0,0,0,12642.291-3168.312Z" transform="translate(-12632.189 3177.479)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" />
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.profile}
                                    </NavLink>
                                    <NavLink to="/student/classes-details">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.517" height="18.863" viewBox="0 0 19.517 18.863">
                                                <g id="Group_5019" data-name="Group 5019" transform="translate(734.4 1445.869)">
                                                    <path id="Path_171" data-name="Path 171" d="M-734.25-1431.146v-12.611a.472.472,0,0,0,.033-.084,2.244,2.244,0,0,1,2.251-1.87c.982,0,1.964-.005,2.946,0a5.023,5.023,0,0,1,2.067.474,5.3,5.3,0,0,1,1.725,1.269.737.737,0,0,0,1.174,0c.026-.027.05-.056.076-.083a5.146,5.146,0,0,1,3.715-1.659c1.006-.023,2.014-.014,3.021,0a2.185,2.185,0,0,1,1.745.881,2.7,2.7,0,0,1,.464,1.07v12.574a1.055,1.055,0,0,0-.033.1,2.252,2.252,0,0,1-2.319,1.882c-.882,0-1.764.005-2.645,0a3.786,3.786,0,0,0-2.918,1.26,2.221,2.221,0,0,1-3.387,0,3.776,3.776,0,0,0-2.9-1.258c-.919,0-1.839.009-2.758,0a2.135,2.135,0,0,1-1.855-.991A5.523,5.523,0,0,1-734.25-1431.146Zm17.716-6.287q0-2.974,0-5.949a.754.754,0,0,0-.824-.829c-.913,0-1.827,0-2.74,0a4.246,4.246,0,0,0-.708.059,3.73,3.73,0,0,0-2.163,1.219,2.171,2.171,0,0,1-1.676.75,2.256,2.256,0,0,1-1.714-.795,3.7,3.7,0,0,0-2.833-1.236h-2.721a.755.755,0,0,0-.835.836q0,5.911,0,11.822a.768.768,0,0,0,.86.853c.932,0,1.864-.01,2.8,0a5.158,5.158,0,0,1,3.827,1.705.76.76,0,0,0,1.253,0,5.152,5.152,0,0,1,3.81-1.7c.938-.016,1.876,0,2.815,0a.768.768,0,0,0,.853-.86Q-716.534-1434.5-716.534-1437.433Z" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_172" data-name="Path 172" d="M-555.786-1321.128c0,1.231,0,2.463,0,3.694a.749.749,0,0,1-.976.75.748.748,0,0,1-.523-.723c0-.606,0-1.213,0-1.819q0-2.823,0-5.645a.75.75,0,0,1,1.012-.738.756.756,0,0,1,.488.749Q-555.784-1322.994-555.786-1321.128Z" transform="translate(-168.105 -114.053)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_173" data-name="Path 173" d="M-497.448-1355.708c.294,0,.588,0,.881,0a.746.746,0,0,1,.773.746.75.75,0,0,1-.77.753q-.7.009-1.406,0a4.026,4.026,0,0,0-1.29.2.751.751,0,0,1-1-.462.761.761,0,0,1,.554-.97A6.235,6.235,0,0,1-497.448-1355.708Z" transform="translate(-222.242 -85.5)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_174" data-name="Path 174" d="M-497.586-1295.709c.432,0,.726,0,1.02,0a.746.746,0,0,1,.773.746.75.75,0,0,1-.77.753q-.7.008-1.406,0a4.032,4.032,0,0,0-1.29.2.751.751,0,0,1-1-.462.761.761,0,0,1,.554-.97A5.866,5.866,0,0,1-497.586-1295.709Z" transform="translate(-222.242 -142.496)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_175" data-name="Path 175" d="M-497.566-1234.12a4.954,4.954,0,0,0-1.76.22.721.721,0,0,1-.912-.505.736.736,0,0,1,.471-.918,4.784,4.784,0,0,1,1.558-.294c.556-.009,1.112-.006,1.669,0a.744.744,0,0,1,.772.747.752.752,0,0,1-.771.752C-496.833-1234.116-497.126-1234.12-497.566-1234.12Z" transform="translate(-222.268 -199.581)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_176" data-name="Path 176" d="M-669.726-1354.834c0,.495-.429.909-.854.771a9.493,9.493,0,0,0-2.843-.231.759.759,0,0,1-.776-.554.716.716,0,0,1,.6-.93,9.213,9.213,0,0,1,3.306.252.775.775,0,0,1,.549.562A1.273,1.273,0,0,1-669.726-1354.834Z" transform="translate(-57.018 -85.412)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_177" data-name="Path 177" d="M-672.438-1295.718a5.64,5.64,0,0,1,2.173.29.754.754,0,0,1,.513.836.719.719,0,0,1-.9.6,6.845,6.845,0,0,0-2.243-.224c-.194,0-.388,0-.581,0a.753.753,0,0,1-.77-.753.746.746,0,0,1,.772-.747C-673.174-1295.72-672.874-1295.718-672.438-1295.718Z" transform="translate(-57.001 -142.487)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_178" data-name="Path 178" d="M-672.424-1235.732a5.662,5.662,0,0,1,2.137.281.751.751,0,0,1,.45,1.085.713.713,0,0,1-.846.356,5.445,5.445,0,0,0-1.906-.222c-.294.005-.588,0-.881,0a.753.753,0,0,1-.776-.747.747.747,0,0,1,.785-.753C-673.161-1235.733-672.861-1235.732-672.424-1235.732Z" transform="translate(-57.001 -199.471)" fill="#005eb5" fillRule="evenodd" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.classes_details}
                                    </NavLink>
                                    <NavLink to="/student/classes-certificate">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.517" height="18.863" viewBox="0 0 19.517 18.863">
                                                <g id="Group_5019" data-name="Group 5019" transform="translate(734.4 1445.869)">
                                                    <path id="Path_171" data-name="Path 171" d="M-734.25-1431.146v-12.611a.472.472,0,0,0,.033-.084,2.244,2.244,0,0,1,2.251-1.87c.982,0,1.964-.005,2.946,0a5.023,5.023,0,0,1,2.067.474,5.3,5.3,0,0,1,1.725,1.269.737.737,0,0,0,1.174,0c.026-.027.05-.056.076-.083a5.146,5.146,0,0,1,3.715-1.659c1.006-.023,2.014-.014,3.021,0a2.185,2.185,0,0,1,1.745.881,2.7,2.7,0,0,1,.464,1.07v12.574a1.055,1.055,0,0,0-.033.1,2.252,2.252,0,0,1-2.319,1.882c-.882,0-1.764.005-2.645,0a3.786,3.786,0,0,0-2.918,1.26,2.221,2.221,0,0,1-3.387,0,3.776,3.776,0,0,0-2.9-1.258c-.919,0-1.839.009-2.758,0a2.135,2.135,0,0,1-1.855-.991A5.523,5.523,0,0,1-734.25-1431.146Zm17.716-6.287q0-2.974,0-5.949a.754.754,0,0,0-.824-.829c-.913,0-1.827,0-2.74,0a4.246,4.246,0,0,0-.708.059,3.73,3.73,0,0,0-2.163,1.219,2.171,2.171,0,0,1-1.676.75,2.256,2.256,0,0,1-1.714-.795,3.7,3.7,0,0,0-2.833-1.236h-2.721a.755.755,0,0,0-.835.836q0,5.911,0,11.822a.768.768,0,0,0,.86.853c.932,0,1.864-.01,2.8,0a5.158,5.158,0,0,1,3.827,1.705.76.76,0,0,0,1.253,0,5.152,5.152,0,0,1,3.81-1.7c.938-.016,1.876,0,2.815,0a.768.768,0,0,0,.853-.86Q-716.534-1434.5-716.534-1437.433Z" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_172" data-name="Path 172" d="M-555.786-1321.128c0,1.231,0,2.463,0,3.694a.749.749,0,0,1-.976.75.748.748,0,0,1-.523-.723c0-.606,0-1.213,0-1.819q0-2.823,0-5.645a.75.75,0,0,1,1.012-.738.756.756,0,0,1,.488.749Q-555.784-1322.994-555.786-1321.128Z" transform="translate(-168.105 -114.053)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_173" data-name="Path 173" d="M-497.448-1355.708c.294,0,.588,0,.881,0a.746.746,0,0,1,.773.746.75.75,0,0,1-.77.753q-.7.009-1.406,0a4.026,4.026,0,0,0-1.29.2.751.751,0,0,1-1-.462.761.761,0,0,1,.554-.97A6.235,6.235,0,0,1-497.448-1355.708Z" transform="translate(-222.242 -85.5)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_174" data-name="Path 174" d="M-497.586-1295.709c.432,0,.726,0,1.02,0a.746.746,0,0,1,.773.746.75.75,0,0,1-.77.753q-.7.008-1.406,0a4.032,4.032,0,0,0-1.29.2.751.751,0,0,1-1-.462.761.761,0,0,1,.554-.97A5.866,5.866,0,0,1-497.586-1295.709Z" transform="translate(-222.242 -142.496)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_175" data-name="Path 175" d="M-497.566-1234.12a4.954,4.954,0,0,0-1.76.22.721.721,0,0,1-.912-.505.736.736,0,0,1,.471-.918,4.784,4.784,0,0,1,1.558-.294c.556-.009,1.112-.006,1.669,0a.744.744,0,0,1,.772.747.752.752,0,0,1-.771.752C-496.833-1234.116-497.126-1234.12-497.566-1234.12Z" transform="translate(-222.268 -199.581)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_176" data-name="Path 176" d="M-669.726-1354.834c0,.495-.429.909-.854.771a9.493,9.493,0,0,0-2.843-.231.759.759,0,0,1-.776-.554.716.716,0,0,1,.6-.93,9.213,9.213,0,0,1,3.306.252.775.775,0,0,1,.549.562A1.273,1.273,0,0,1-669.726-1354.834Z" transform="translate(-57.018 -85.412)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_177" data-name="Path 177" d="M-672.438-1295.718a5.64,5.64,0,0,1,2.173.29.754.754,0,0,1,.513.836.719.719,0,0,1-.9.6,6.845,6.845,0,0,0-2.243-.224c-.194,0-.388,0-.581,0a.753.753,0,0,1-.77-.753.746.746,0,0,1,.772-.747C-673.174-1295.72-672.874-1295.718-672.438-1295.718Z" transform="translate(-57.001 -142.487)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_178" data-name="Path 178" d="M-672.424-1235.732a5.662,5.662,0,0,1,2.137.281.751.751,0,0,1,.45,1.085.713.713,0,0,1-.846.356,5.445,5.445,0,0,0-1.906-.222c-.294.005-.588,0-.881,0a.753.753,0,0,1-.776-.747.747.747,0,0,1,.785-.753C-673.161-1235.733-672.861-1235.732-672.424-1235.732Z" transform="translate(-57.001 -199.471)" fill="#005eb5" fillRule="evenodd" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.my_certificates}
                                    </NavLink>
                                    <NavLink to="/wallet-and-payment">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.434" height="19.434" viewBox="0 0 19.434 19.434">
                                                <g id="Group_366" data-name="Group 366" transform="translate(-782.6 1488.4)">
                                                    <path id="Path_168" data-name="Path 168" d="M782.75-1473.227v-11.622a1.407,1.407,0,0,0,.034-.142,3.759,3.759,0,0,1,2.861-3.148c.18-.043.362-.074.543-.111h12.332a.636.636,0,0,0,.1.033,3.725,3.725,0,0,1,3.116,2.7c.061.219.1.444.144.667v1.271a.569.569,0,0,0-.034.065.752.752,0,0,1-.84.525.751.751,0,0,1-.621-.774c0-.224,0-.448,0-.672a2.248,2.248,0,0,0-2.323-2.32h-5.9c-1.887,0-3.774-.009-5.661,0a2.241,2.241,0,0,0-1.975,3.316,2.187,2.187,0,0,0,2.049,1.165q5.763,0,11.527,0a3.739,3.739,0,0,1,3.69,2.928c.036.156.061.314.091.471v6.353c-.012.053-.027.106-.036.159a3.693,3.693,0,0,1-2.659,3.088c-.216.071-.445.1-.668.153h-11.7a.585.585,0,0,0-.1-.032,4.484,4.484,0,0,1-3.8-3.259C782.841-1472.676,782.8-1472.954,782.75-1473.227Zm1.5-8.287v.134q0,3.9,0,7.809a2.73,2.73,0,0,0,.055.537,2.983,2.983,0,0,0,2.971,2.423q5.408,0,10.816,0a2.246,2.246,0,0,0,2.3-2.307q0-2.756,0-5.511a2.25,2.25,0,0,0-2.355-2.345q-5.772,0-11.545,0a4.608,4.608,0,0,1-.868-.1A3.585,3.585,0,0,1,784.245-1481.514Z" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_169" data-name="Path 169" d="M849.4-1426.738h-5.77a.739.739,0,0,1-.776-.518.741.741,0,0,1,.666-.974c.05,0,.1,0,.149,0h11.466a.769.769,0,0,1,.785.484.744.744,0,0,1-.694,1.008c-.622.008-1.245,0-1.867,0Z" transform="translate(-57.073 -57.026)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_170" data-name="Path 170" d="M1070.209-1253.13a.921.921,0,0,1-.937-.932.923.923,0,0,1,.952-.934.936.936,0,0,1,.912.941A.936.936,0,0,1,1070.209-1253.13Z" transform="translate(-272.244 -221.63)" fill="#005eb5" fillRule="evenodd" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.wallet_payment}
                                    </NavLink>
                                    <NavLink to="/student/manage-subscription-plan">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.295" height="18.296" viewBox="0 0 18.295 18.296">
                                                <g id="Group_4922" data-name="Group 4922" transform="translate(618.183 -295.802)">
                                                    <path id="Path_656" data-name="Path 656" d="M-614.656,310.55h-.233c-.563,0-1.126,0-1.689,0a1.443,1.443,0,0,1-1.454-1.464q0-5.838,0-11.676a1.427,1.427,0,0,1,1.453-1.458q5.838,0,11.676,0a1.434,1.434,0,0,1,1.469,1.468c0,.556,0,1.112,0,1.668v.25c.092,0,.167.011.241.011.563,0,1.126,0,1.689,0a1.434,1.434,0,0,1,1.465,1.47q0,5.828,0,11.655a1.429,1.429,0,0,1-1.46,1.472q-5.838,0-11.676,0a1.442,1.442,0,0,1-1.461-1.458c-.005-.619,0-1.239,0-1.858A.655.655,0,0,0-614.656,310.55Zm7.328-10h-5.7c-.36,0-.4.043-.4.4v11.4c0,.347.057.405.4.405h11.4c.326,0,.4-.073.4-.393q0-5.712,0-11.424c0-.328-.062-.387-.4-.387Zm2.681-1.2c0-.089.01-.158.01-.226q0-.8,0-1.6c0-.308-.063-.373-.368-.373h-11.464c-.3,0-.373.074-.373.374q0,1.035,0,2.069,0,4.687,0,9.374c0,.318.068.388.379.388h1.6c.068,0,.136-.006.222-.01v-.288q0-4.075,0-8.149a1.448,1.448,0,0,1,1.557-1.552h8.432Z" transform="translate(0)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" />
                                                    <path id="Path_657" data-name="Path 657" d="M-459.037,468.48c.08-.141.143-.257.212-.369a.589.589,0,0,1,.8-.228.587.587,0,0,1,.241.8q-.5.928-1.026,1.842a.571.571,0,0,1-.789.219q-.909-.49-1.807-1a.588.588,0,0,1-.232-.82.6.6,0,0,1,.823-.221c.227.118.45.245.674.368l.05-.043a6.889,6.889,0,0,0-.383-.613,2.581,2.581,0,0,0-2.738-.9,2.6,2.6,0,0,0-1.891,2.178,2.568,2.568,0,0,0,1.246,2.581.626.626,0,0,1,.176,1.006.6.6,0,0,1-.742.055,3.665,3.665,0,0,1-1.881-3.468,3.755,3.755,0,0,1,3.21-3.6,3.805,3.805,0,0,1,3.935,1.992C-459.12,468.316-459.088,468.379-459.037,468.48Z" transform="translate(-145.323 -163.069)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.manage_sub}
                                    </NavLink>
                                    <NavLink to="/studentgiftcardcoupons">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.936" height="14.823" viewBox="0 0 20.936 14.823">
                                                <g id="Group_369" data-name="Group 369" transform="translate(383.454 -1273.562)">
                                                    <path id="Path_192" data-name="Path 192" d="M-362.668,1275.816v2.7a1.27,1.27,0,0,0-.041.13,2.255,2.255,0,0,1-1.09,1.586.807.807,0,0,0,0,1.484,2.2,2.2,0,0,1,1.009,1.276c.046.145.08.294.12.441v2.7a.756.756,0,0,0-.039.109,2.423,2.423,0,0,1-2.4,1.986c-.644,0-1.289.016-1.933,0a2.259,2.259,0,0,1-2-1.151.8.8,0,0,0-1.456,0,2.215,2.215,0,0,1-2,1.146q-4.229.023-8.459,0a2.32,2.32,0,0,1-1.855-.943,2.943,2.943,0,0,1-.5-1.147v-2.7a1.272,1.272,0,0,0,.04-.131,2.25,2.25,0,0,1,1.09-1.585.809.809,0,0,0,0-1.485,2.2,2.2,0,0,1-1.015-1.294c-.043-.139-.077-.281-.115-.422v-2.7a1.207,1.207,0,0,0,.041-.13,2.418,2.418,0,0,1,2.355-1.964q4.189-.008,8.379,0a2.245,2.245,0,0,1,2.049,1.165.8.8,0,0,0,1.436,0,2.277,2.277,0,0,1,2.047-1.169c.651-.015,1.3-.013,1.954,0a2.375,2.375,0,0,1,1.875.945A2.972,2.972,0,0,1-362.668,1275.816Zm-14.029-.484q-2.075,0-4.15,0a.8.8,0,0,0-.842.791c-.008.7-.006,1.4,0,2.1a.725.725,0,0,0,.386.659,2.408,2.408,0,0,1,.015,4.186.753.753,0,0,0-.4.691c0,.685-.006,1.37,0,2.055a.793.793,0,0,0,.805.807q4.171.005,8.341,0a.748.748,0,0,0,.692-.4,2.408,2.408,0,0,1,4.175-.008.766.766,0,0,0,.708.408q.927.005,1.853,0a.8.8,0,0,0,.834-.841c0-.658,0-1.316,0-1.975a.807.807,0,0,0-.432-.757,2.407,2.407,0,0,1,0-4.142.805.805,0,0,0,.43-.758c0-.652,0-1.3,0-1.955a.806.806,0,0,0-.857-.858c-.578,0-1.155.006-1.733,0a.876.876,0,0,0-.841.464,2.4,2.4,0,0,1-4.1,0,.9.9,0,0,0-.859-.469C-374.011,1275.336-375.354,1275.332-376.7,1275.332Z" transform="translate(0)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_193" data-name="Path 193" d="M-292.987,1357.664a.763.763,0,0,1-.667-.42.772.772,0,0,1,.042-.838,1.2,1.2,0,0,1,.158-.182q2.213-2.215,4.426-4.429a.847.847,0,0,1,.756-.289.8.8,0,0,1,.545,1.247,1.428,1.428,0,0,1-.172.2q-2.206,2.208-4.412,4.415A.906.906,0,0,1-292.987,1357.664Z" transform="translate(-84.735 -73.606)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_194" data-name="Path 194" d="M-144.669,1366.29c0,.517,0,1.033,0,1.55a.81.81,0,0,1-.806.868.81.81,0,0,1-.806-.868q0-1.55,0-3.1a.808.808,0,0,1,.805-.868.808.808,0,0,1,.806.868C-144.668,1365.257-144.669,1365.774-144.669,1366.29Z" transform="translate(-224.286 -85.316)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_195" data-name="Path 195" d="M-292.1,1352.264a.794.794,0,0,1-.8.8.8.8,0,0,1-.8-.8.807.807,0,0,1,.807-.8A.8.8,0,0,1-292.1,1352.264Z" transform="translate(-84.779 -73.568)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_196" data-name="Path 196" d="M-208.774,1437.186a.793.793,0,0,1,.808-.8.8.8,0,0,1,.8.807.808.808,0,0,1-.812.8A.8.8,0,0,1-208.774,1437.186Z" transform="translate(-165.152 -153.937)" fill="#005eb5" fillRule="evenodd" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.gift_card_coupons}
                                    </NavLink>
                                    <NavLink to="/student/wishlist">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.485" height="17.756" viewBox="0 0 19.485 17.756">
                                                <g id="Group_368" data-name="Group 368" transform="translate(-876.6 -45.964)">
                                                    <path id="Path_220" data-name="Path 220" d="M876.75,50.345c.057-.288.1-.581.175-.862a4.555,4.555,0,0,1,7.621-1.98c.137.136.264.282.367.392a12.988,12.988,0,0,1,1.1-.937,4.562,4.562,0,0,1,7.106,3.124,6.635,6.635,0,0,1-.1,2.189.746.746,0,0,1-1.472-.189c.017-.312.067-.623.1-.934a3.132,3.132,0,0,0-2.154-3.375,3.206,3.206,0,0,0-3.3,1.026,2.04,2.04,0,0,0-.485,1.256.742.742,0,0,1-.726.722.731.731,0,0,1-.769-.674,2.211,2.211,0,0,0-.966-1.729,3.031,3.031,0,0,0-4.986,2.408,8.294,8.294,0,0,0,1.511,4.69,15.009,15.009,0,0,0,3.578,3.7,3.868,3.868,0,0,0,.891.43.782.782,0,0,1,.528.764.731.731,0,0,1-.575.683.935.935,0,0,1-.406-.013,3.628,3.628,0,0,1-1.3-.619,16.673,16.673,0,0,1-4.065-4.246,10.171,10.171,0,0,1-1.623-4.424c-.016-.142-.035-.283-.052-.425Z" transform="translate(0 0)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_221" data-name="Path 221" d="M1056.7,205.446c-.068.343-.115.692-.206,1.029a7.339,7.339,0,0,1-2.161,3.412,13.712,13.712,0,0,1-1.083.915,2.859,2.859,0,0,1-3.4.079,9.143,9.143,0,0,1-2.822-3.066,5.713,5.713,0,0,1-.741-2.763,2.936,2.936,0,0,1,2.469-2.884,2.883,2.883,0,0,1,2.655.855c.021.021.043.041.064.061.21-.168.405-.351.625-.5a3,3,0,0,1,4.565,2.044,1.054,1.054,0,0,0,.032.122Zm-7.446-1.872a1.522,1.522,0,0,0-1.5,1.737,4.83,4.83,0,0,0,.8,2.216,8.416,8.416,0,0,0,2.1,2.146,1.421,1.421,0,0,0,1.685-.008,8.884,8.884,0,0,0,1.692-1.592,5.074,5.074,0,0,0,1.195-2.724,1.506,1.506,0,0,0-.521-1.42,1.523,1.523,0,0,0-1.628-.213,1.388,1.388,0,0,0-.858,1.306.729.729,0,1,1-1.457-.016A1.471,1.471,0,0,0,1049.253,203.574Z" transform="translate(-160.764 -147.902)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.wishlist}
                                    </NavLink>

                                    <NavLink to="/student/refer-and-earn">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.785" height="20.484" viewBox="0 0 20.785 20.484">
                                                <g id="Group_493" data-name="Group 493" transform="translate(-1308.6 -266.75)">
                                                    <path id="Path_626" data-name="Path 626" d="M1329.234,355.8v7.042a1.172,1.172,0,0,0-.042.128,2.394,2.394,0,0,1-2.319,1.949q-7.881.008-15.762,0a2.317,2.317,0,0,1-1.875-.952,2.94,2.94,0,0,1-.486-1.123V355.8a1.233,1.233,0,0,0,.041-.129,2.4,2.4,0,0,1,2.32-1.949q7.881-.008,15.763,0a2.313,2.313,0,0,1,1.863.937A2.956,2.956,0,0,1,1329.234,355.8Zm-10.263,7.522h7.739a.819.819,0,0,0,.923-.92q0-3.08,0-6.159a.817.817,0,0,0-.922-.922h-15.438a.817.817,0,0,0-.923.92q0,3.08,0,6.159a.818.818,0,0,0,.922.922Z" transform="translate(0 -82.33)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_627" data-name="Path 627" d="M1368.3,266.75c.636.276.683.415.527,1.6h.239q5.091,0,10.181,0a.85.85,0,0,1,.684.265.761.761,0,0,1,.148.837.776.776,0,0,1-.671.5,1.878,1.878,0,0,1-.22,0h-12.5a1.677,1.677,0,0,1-.279-.011.8.8,0,0,1,.089-1.588.8.8,0,0,0,.844-.844.839.839,0,0,1,.638-.758Z" transform="translate(-53.954)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_628" data-name="Path 628" d="M1377.568,593.907c-.637-.277-.684-.419-.527-1.6H1366.6a.8.8,0,1,1-.142-1.6c.053,0,.107,0,.16,0h12.622a.8.8,0,1,1,.13,1.6.8.8,0,0,0-.843.844.842.842,0,0,1-.638.758Z" transform="translate(-53.934 -306.673)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_629" data-name="Path 629" d="M1460.535,416.133a2.4,2.4,0,1,1-2.407-2.4A2.409,2.409,0,0,1,1460.535,416.133Zm-1.6.009a.8.8,0,1,0-.807.792A.8.8,0,0,0,1458.934,416.142Z" transform="translate(-139.142 -139.142)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_630" data-name="Path 630" d="M1370.357,445.277c-.266,0-.532,0-.8,0a.8.8,0,1,1-.008-1.6q.8-.01,1.6,0a.8.8,0,1,1,.008,1.6C1370.89,445.282,1370.624,445.277,1370.357,445.277Z" transform="translate(-56.803 -167.485)" fill="#005eb5" fillRule="evenodd" />
                                                    <path id="Path_631" data-name="Path 631" d="M1574.337,443.707c.26,0,.519,0,.779,0a.8.8,0,1,1,0,1.6q-.789.008-1.578,0a.8.8,0,1,1,0-1.6C1573.8,443.7,1574.071,443.707,1574.337,443.707Z" transform="translate(-249.895 -167.515)" fill="#005eb5" fillRule="evenodd" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.refer_earn}
                                    </NavLink>

                                    <NavLink to="/student/my-chats">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.618" height="19.618" viewBox="0 0 19.618 19.618">
                                                <g id="Group_367" data-name="Group 367" transform="translate(383.403 -2344.468)">
                                                    <path id="Path_188" data-name="Path 188" d="M-382.347,2363.936h-.3a.893.893,0,0,1-.6-.6v-9.659c.054-.4.091-.8.163-1.193a9.662,9.662,0,0,1,7.907-7.709c.376-.067.757-.1,1.136-.153h1.17c.347.048.7.088,1.04.147a9.689,9.689,0,0,1,7.752,7.752c.061.358.1.719.15,1.079v1.17a1.061,1.061,0,0,0-.032.146,9.646,9.646,0,0,1-1.491,4.515,9.325,9.325,0,0,1-6.471,4.359c-.376.067-.758.1-1.137.149h-1.17a.774.774,0,0,0-.126-.03,9.569,9.569,0,0,1-3.558-.966,3.346,3.346,0,0,0-3.912.635A3.909,3.909,0,0,1-382.347,2363.936Zm.616-2.3c.037-.016.06-.023.081-.034a4.818,4.818,0,0,1,4.443,0,7.749,7.749,0,0,0,2.926.792,7.786,7.786,0,0,0,6.8-2.8,8.04,8.04,0,0,0,1.78-7.383,8.1,8.1,0,0,0-9.523-5.909,8.059,8.059,0,0,0-6.5,7.613c-.082,2.556-.018,5.117-.018,7.675A.321.321,0,0,0-381.732,2361.635Z" transform="translate(0 0)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_189" data-name="Path 189" d="M-144.779,2518.794a.943.943,0,0,1,.936-.934.947.947,0,0,1,.948.923.935.935,0,0,1-.947.959A.931.931,0,0,1-144.779,2518.794Z" transform="translate(-226.476 -164.526)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_190" data-name="Path 190" d="M-208.327,2517.862a.945.945,0,0,1,.942.929.935.935,0,0,1-.953.953.931.931,0,0,1-.931-.955A.943.943,0,0,1-208.327,2517.862Z" transform="translate(-165.229 -164.527)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                    <path id="Path_191" data-name="Path 191" d="M-271.822,2518.8a.947.947,0,0,1-.92.951.933.933,0,0,1-.962-.943.931.931,0,0,1,.945-.942A.946.946,0,0,1-271.822,2518.8Z" transform="translate(-104.037 -164.53)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.chat}
                                    </NavLink>

                                    <NavLink to="/student/rating-and-review">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.488" height="18.738" viewBox="0 0 19.488 18.738">
                                                <path id="bookmark-favorite-heart-like-rating-star-svgrepo-com" d="M9.388,2.355a1.492,1.492,0,0,1,2.713,0l2.1,4.56,4.971.591a1.5,1.5,0,0,1,.838,2.588L16.331,13.5l.976,4.925a1.5,1.5,0,0,1-2.195,1.6l-4.368-2.453L6.376,20.028a1.5,1.5,0,0,1-2.195-1.6L5.157,13.5,1.481,10.094A1.5,1.5,0,0,1,2.32,7.506l4.971-.591Zm1.7,1.368a.375.375,0,0,0-.681,0L8.647,7.542a1.494,1.494,0,0,1-1.18.86L3.3,8.9a.375.375,0,0,0-.211.647L6.171,12.4A1.5,1.5,0,0,1,6.622,13.8L5.8,17.923a.375.375,0,0,0,.551.4l3.659-2.054a1.49,1.49,0,0,1,1.459,0l3.659,2.054a.375.375,0,0,0,.551-.4L14.866,13.8a1.5,1.5,0,0,1,.451-1.392L18.4,9.545a.375.375,0,0,0-.211-.647l-4.166-.5a1.494,1.494,0,0,1-1.181-.86Z" transform="translate(-1 -1.484)" fill="#005eb5" fillRule="evenodd" />
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.rating}
                                    </NavLink>

                                    <NavLink to="/student/support">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.724" height="19.725" viewBox="0 0 19.724 19.725">
                                                <path id="Path_80452" data-name="Path 80452" d="M-26.762,22.2a3.787,3.787,0,0,1-2.7-1.117,3.789,3.789,0,0,1-1.117-2.7,3.79,3.79,0,0,1,1.117-2.7l6.494-6.493a4.467,4.467,0,0,1-.025-.684,6.051,6.051,0,0,1,6.036-6.038h.082a5.77,5.77,0,0,1,2.74.7l.882.479-3.979,3.981,1.22,1.219,3.978-3.978.48.883a5.781,5.781,0,0,1,.7,2.8,6.034,6.034,0,0,1-1.794,4.321,6.114,6.114,0,0,1-4.28,1.742,4.633,4.633,0,0,1-.648-.024l-6.492,6.492A3.785,3.785,0,0,1-26.762,22.2Zm9.819-18.225a4.544,4.544,0,0,0-4.547,4.51,3.291,3.291,0,0,0,.059.817l.084.4L-28.4,16.749a2.315,2.315,0,0,0,0,3.271,2.313,2.313,0,0,0,3.271,0l7.049-7.049.4.082a2.868,2.868,0,0,0,.727.061A4.61,4.61,0,0,0-13.7,11.8a4.5,4.5,0,0,0,1.349-3.2,4.522,4.522,0,0,0-.136-1.154l-3.521,3.521-3.341-3.34,3.523-3.525a4.082,4.082,0,0,0-1.089-.133Z" transform="translate(30.576 -2.473)" fill="#005eb5" />
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.contact_ticket}
                                    </NavLink>


                                    <NavLink to="/my-blog/student/list">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.925" height="16.855" viewBox="0 0 18.925 16.855">
                                                <path id="Path_619" data-name="Path 619" d="M768.75,374.3c.042-.194.076-.39.129-.581a3.613,3.613,0,0,1,3.449-2.68c2.043-.02,4.086-.007,6.129,0a.728.728,0,1,1-.014,1.453q-3,0-6,0a2.184,2.184,0,0,0-2.216,1.9,2.257,2.257,0,0,0-.019.344q0,4.283,0,8.566a.742.742,0,0,0,1.29.579c.352-.325.716-.636,1.068-.961a.921.921,0,0,1,.66-.249q3.783,0,7.566,0a2.16,2.16,0,0,0,2.164-1.7,2.3,2.3,0,0,0,.057-.5c.007-.521,0-1.043,0-1.564a.728.728,0,1,1,1.453-.011c-.005.691.022,1.385-.041,2.071a3.6,3.6,0,0,1-3.03,3.117,5.319,5.319,0,0,1-.688.043q-3.529,0-7.057,0a.573.573,0,0,0-.436.164c-.285.272-.578.536-.882.786a2.18,2.18,0,0,1-3.545-1.29.729.729,0,0,0-.034-.1Z" transform="translate(-768.6 -368.895)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                <path id="Path_620" data-name="Path 620" d="M942.551,332.678a2.651,2.651,0,0,1-.16.534,3.224,3.224,0,0,1-.5.75q-2.641,2.67-5.307,5.315a5.551,5.551,0,0,1-3.949,1.664.729.729,0,0,1-.8-.814,5.662,5.662,0,0,1,1.727-4q2.593-2.59,5.181-5.183a2.14,2.14,0,0,1,2.242-.618,2.092,2.092,0,0,1,1.536,1.76c0,.016.018.03.028.044Zm-9.184,6.73c.059-.009.088-.011.116-.018a4.087,4.087,0,0,0,2.033-1.1q2.673-2.661,5.335-5.332a.723.723,0,0,0,.034-1.055.732.732,0,0,0-1.068.034q-2.662,2.66-5.32,5.322a4.107,4.107,0,0,0-1.027,1.732C933.431,339.121,933.4,339.255,933.367,339.408Z" transform="translate(-923.776 -330.075)" fill="#005eb5" stroke="#005eb5" strokeWidth="0.3" fillRule="evenodd" />
                                                <path id="Path_621" data-name="Path 621" d="M830.57,462.5c-.357,0-.714,0-1.071,0a.726.726,0,1,1-.019-1.451q1.089-.014,2.179,0a.726.726,0,1,1-.017,1.452H830.57Z" transform="translate(-825.69 -454.54)" fill="#005eb5" fillRule="evenodd" />
                                                <path id="Path_622" data-name="Path 622" d="M830.564,522.518c-.351,0-.7,0-1.053,0a.727.727,0,1,1-.008-1.452q1.062-.009,2.125,0a.728.728,0,1,1-.01,1.453C831.266,522.52,830.915,522.518,830.564,522.518Z" transform="translate(-825.69 -511.651)" fill="#005eb5" fillRule="evenodd" />
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.blogs}
                                    </NavLink>

                                    {/*<button className='sidebar-menu-item log-out-btn' onClick={handleLogout}>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.992" height="16.993" viewBox="0 0 16.992 16.993">
                                                <g id="Group_494" data-name="Group 494" transform="translate(0.25 0.25)">
                                                    <path id="Path_632" data-name="Path 632" d="M39.873,654.75c-.291.04-.583.072-.873.122a7.99,7.99,0,0,0-3.986,1.929.646.646,0,1,0,.849.968,6.641,6.641,0,0,1,2.011-1.25,6.77,6.77,0,0,1,5.57.24,6.605,6.605,0,0,1,3.715,4.367,7.014,7.014,0,0,1-9.114,8.414,6.5,6.5,0,0,1-2.152-1.292.687.687,0,0,0-.613-.213.645.645,0,0,0-.3,1.13,8.2,8.2,0,0,0,2.7,1.6,8.016,8.016,0,0,0,2.2.457.807.807,0,0,1,.123.027h1.063c.2-.025.393-.046.588-.075a8.02,8.02,0,0,0,4.168-1.94,8.161,8.161,0,0,0,2.758-4.762c.051-.273.081-.55.121-.825v-1.16c-.01-.058-.021-.115-.029-.173-.038-.292-.059-.587-.113-.876a8.244,8.244,0,0,0-6.764-6.554c-.316-.055-.636-.086-.954-.129Z" transform="translate(-32.206 -654.75)" fill="#c1302a" stroke="#c1302a" strokeWidth="0.5" fillRule="evenodd" />
                                                    <path id="Path_633" data-name="Path 633" d="M329.439,766.678a2.327,2.327,0,0,0,.142.472,2.928,2.928,0,0,0,.443.663c.7.713,1.417,1.406,2.13,2.1a.634.634,0,0,0,.738.146.642.642,0,0,0,.377-.621.729.729,0,0,0-.253-.486Q332,767.966,331,766.97A.654.654,0,0,1,331,765.9q1.026-1,2.053-2a.634.634,0,0,0,.153-.75.6.6,0,0,0-.622-.366.809.809,0,0,0-.413.186c-.728.69-1.445,1.393-2.161,2.1a1.851,1.851,0,0,0-.548,1.068.215.215,0,0,1-.026.056Z" transform="translate(-329.439 -758.141)" fill="#c1302a" stroke="#c1302a" strokeWidth="0.5" fillRule="evenodd" />
                                                    <path id="Path_634" data-name="Path 634" d="M131.327,831.7q-2.173,0-4.346,0a.638.638,0,0,0-.63.845.628.628,0,0,0,.561.439,1.1,1.1,0,0,0,.129,0H135.6a.646.646,0,0,0,.7-.453.634.634,0,0,0-.628-.834c-.612,0-1.223,0-1.835,0Z" transform="translate(-123.772 -824.099)" fill="#c1302a" stroke="#c1302a" strokeWidth="0.5" fillRule="evenodd" />
                                                </g>
                                            </svg>
                                        </span>
                                        {languageSelector?.side_bar?.log_out}
                                    </button>*/}
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Navbar>

                ))}
            </div>
            <div className='on-off-section invitecode'>
                <div className='img-on'>
                    <img src='/images/student-copy-code.png' alt='Logo' />
                </div>
                <div className='on-off-content'>
                    <p> {languageSelector?.side_bar?.invite_code_copied} </p>
                    <div className='copyclipboard-box'>
                        <button type='button' className='btn' ref={target} onClick={() => copyToClipBoard(user_detail?.user?.Referral_code)}> {user_detail?.user?.Referral_code}
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                    <g id="Group_5086" data-name="Group 5086" transform="translate(-523.25 -1010.25)">
                                        <rect id="Rectangle_2042" data-name="Rectangle 2042" width="12" height="12" rx="2" transform="translate(528 1015)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Path_680" data-name="Path 680" d="M16,8V6a2,2,0,0,0-2-2H6A2,2,0,0,0,4,6v8a2,2,0,0,0,2,2H8" transform="translate(520 1007)" fill="none" stroke="#005eb5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                </svg>
                            </span>
                        </button>
                        <Overlay target={target.current} show={tooltip} placement="top">
                            {(props) => (
                                <Tooltip id="overlay-example" {...props}> {languageSelector?.side_bar?.copied} </Tooltip>
                            )}
                        </Overlay>
                    </div>
                </div>
            </div>

            <RatingAndReviewModal
                show={ratingModalShow}
                setRatingModalShow={setRatingModalShow}
                setOnSubmitRating={setOnSubmitRating}
                onSubmitRating={onSubmitRating}
                id={instructorId}
                user_type="student"
                bookingID={bookingID}
            />
        </>
    )
}
export default Sidenavbar