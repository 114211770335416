import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import Table from "react-bootstrap/Table";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Instructorprofile/Instructorprofile.scss";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards, Autoplay, Pagination } from "swiper";
import AddAmount from "./AddAmount";
import { useDispatch, useSelector } from "react-redux";
import { ProfileGetAction } from "../../redux/actions/CompleteProfileAction";
import AddCardModal from "../../components/AddCardModal";
import {
    CardList,
    CardRemove,
    ResetCardCreate,
    ResetCardList,
    ResetCardRemove,
    ResetTransactionHistoryAction,
    TransactionHistoryAction,
} from "../../redux/actions/PaymentAction";
import moment from "moment/moment";
import "./SwiperCard.scss";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isValidHttpUrl } from "../../Utils/Function";
import { baseUrlPostGres } from "../../redux/constant";
import DeleteRecordModal from "../../components/DeleteRecordModal/DeleteRecordModal";
import { AiFillGift } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";

const Studentwalletpayment = () => {
    const dispatch = useDispatch();
    const [showAddAmountModal, setShowAddAmountModal] = useState(false);
    const [show, setShow] = useState(false);
    const [submitClick, setSubmitClick] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteRecordID, setDeleteRecordID] = useState();

    const cardList = useSelector((state) => state.card?.card_list?.data);
    const cardSuccessfulllyCreated = useSelector(
        (state) => state.card?.create_card
    );
    const userDetail = useSelector((state) => state?.profile?.profile);
    const cardRemoveSelector = useSelector((state) => state?.card.card_remove);
    const isLoading = useSelector((state) => state?.card.loading);
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const transactionListSelector = useSelector(
        (state) => state?.card.transaction_history
    );

    useEffect(() => {
        dispatch(ProfileGetAction("student"));
    }, []);

    useEffect(() => {
        dispatch(CardList());
        dispatch(TransactionHistoryAction());

        return () => {
            dispatch(ResetTransactionHistoryAction());
            dispatch(ResetCardList());
        };
    }, [dispatch]);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (Object.keys(cardSuccessfulllyCreated).length > 0 && submitClick) {
            if (cardSuccessfulllyCreated.status) {
                setSubmitClick(false);
                setShow(false);
                dispatch(ResetCardCreate());
                dispatch(CardList());
            } else {
                setSubmitClick(false);
                dispatch(ResetCardCreate());
            }
        }
    }, [cardSuccessfulllyCreated, submitClick]);

    const handleRemoveCard = (id) => {
        setDeleteRecordID(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteRecordID("");
        setShowDeleteModal(false);
    };

    const handleDeleteRecord = () => {
        dispatch(CardRemove({ card_id: deleteRecordID }));
    };

    useEffect(() => {
        if (Object.keys(cardRemoveSelector).length > 0) {
            if (cardRemoveSelector.status) {
                dispatch(ResetCardRemove());
                dispatch(CardList());
                setShowDeleteModal(false);
                setDeleteRecordID("");
            } else {
                dispatch(ResetCardRemove());
            }
        }
    }, [cardRemoveSelector]);

    return (
        <>
            <div id="footer-bottom" className="instructor-profile walletpayment">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.wallet_payment?.page_title} </h1>
                    <p> Home / <span> {languageSelector?.wallet_payment?.page_title} </span></p>
                </div>

                <div className="myprofile-section">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-md-12 col-lg-5 col-xl-4">
                                <Studentsidebar />
                            </div>

                            <div className="col-md-12 col-lg-7 col-xl-8">
                                <div className="profile-top-heading">
                                    <h2> {languageSelector?.wallet_payment?.page_title} </h2>
                                </div>
                                <div className="my-profile-details">
                                    <div className="wallet-card-main-section row g-5">
                                        <div className="col-lg-12 col-xl-5">
                                            {Object.keys(userDetail).length > 0 ? (
                                                <div className="wallet-card">
                                                    <h2> {languageSelector?.wallet_payment?.wallet} </h2>
                                                    <div className="balance-card">
                                                        <p> {languageSelector?.wallet_payment?.available_balance} </p>
                                                        <span>
                                                            $
                                                            {userDetail?.wallet
                                                                ? Number(userDetail?.wallet).toFixed(0)
                                                                : 0}
                                                        </span>
                                                    </div>
                                                    <div className="balance-amount">
                                                        <h3> {languageSelector?.wallet_payment?.add_more_balance_wallet} </h3>
                                                        <button
                                                            onClick={() => setShowAddAmountModal(true)}
                                                            className="btn"
                                                        > {languageSelector?.wallet_payment?.add_amount} </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Skeleton height={365} borderRadius="2.6rem" />
                                            )}
                                        </div>

                                        <div className="col-lg-12 col-xl-7">
                                            {cardList ? (
                                                <div className="wallet-card wallet-card2">
                                                    <div className="cards-button">
                                                        <h2> {languageSelector?.wallet_payment?.your_card} </h2>
                                                        <button className="btn" onClick={handleShow}> {languageSelector?.wallet_payment?.add_new} </button>
                                                    </div>
                                                    {cardList?.length > 0 ? (
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="card-swiper-slide-section">
                                                                    <Swiper
                                                                        modules={[
                                                                            Autoplay,
                                                                            EffectCards,
                                                                            Pagination,
                                                                        ]}
                                                                        effect={"cards"}
                                                                        grabCursor={true}
                                                                        centeredSlides={true}
                                                                        loop={true}
                                                                        initialSlide={1}
                                                                        pagination={{
                                                                            clickable: true,
                                                                        }}
                                                                        className="mySwiper"
                                                                        cardsEffect={{
                                                                            perSlideRotate: 0,
                                                                        }}
                                                                    >
                                                                        {cardList?.map((ele, index) => (
                                                                            <SwiperSlide
                                                                                className="swiper-slider-item"
                                                                                key={index}
                                                                            >
                                                                                <div className="slider-card">
                                                                                    <div className="card-img">
                                                                                        {ele.brand === "MasterCard" ? (
                                                                                            <img
                                                                                                src="/images/card/master-card.svg"
                                                                                                className="img-fluid"
                                                                                                width="72"
                                                                                                alt="MasterCard"
                                                                                            />
                                                                                        ) : ele.brand === "Visa" ? (
                                                                                            <img
                                                                                                src="/images/card/visa-card.svg"
                                                                                                className="img-fluid"
                                                                                                width="72"
                                                                                                alt="Visa Card"
                                                                                            />
                                                                                        ) : ele.brand ===
                                                                                            "American Express" ? (
                                                                                            <img
                                                                                                src="/images/card/american-express-card.svg"
                                                                                                className="img-fluid"
                                                                                                width="72"
                                                                                                alt="American Express"
                                                                                            />
                                                                                        ) : ele.brand === "Discover" ? (
                                                                                            <img
                                                                                                src="/images/card/discover-card.svg"
                                                                                                className="img-fluid"
                                                                                                width="72"
                                                                                                alt="Discover"
                                                                                            />
                                                                                        ) : ele.brand === "JCB" ? (
                                                                                            <img
                                                                                                src="/images/card/jcb-card.svg"
                                                                                                className="img-fluid"
                                                                                                width="72"
                                                                                                alt="JCB"
                                                                                            />
                                                                                        ) : ele.brand === "Diners Club" ? (
                                                                                            <img
                                                                                                src="/images/card/diners-club-card.svg"
                                                                                                className="img-fluid"
                                                                                                width="72"
                                                                                                alt="Diners Club"
                                                                                            />
                                                                                        ) : ele.brand === "UnionPay" ? (
                                                                                            <img
                                                                                                src="/images/card/union-pay-card.svg"
                                                                                                className="img-fluid"
                                                                                                width="72"
                                                                                                alt="Union Pay"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src="/images/card/unknown-card.svg"
                                                                                                className="img-fluid"
                                                                                                width="72"
                                                                                                alt="unknown"
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="card-bottom">
                                                                                        <p>xxxx xxxx xxxx {ele.last4}</p>
                                                                                        <div className="card-text">
                                                                                            <div className="left">
                                                                                                <span> {languageSelector?.wallet_payment?.card_holder} </span>
                                                                                                <h3>
                                                                                                    {ele.name ? ele.name : "-"}
                                                                                                </h3>
                                                                                            </div>
                                                                                            <div className="left">
                                                                                                <span> {languageSelector?.wallet_payment?.expires} </span>
                                                                                                <h3>
                                                                                                    {ele.exp_month}/
                                                                                                    {moment(
                                                                                                        ele.exp_year,
                                                                                                        "YYYY"
                                                                                                    ).format("YY")}
                                                                                                </h3>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <button
                                                                                    type="type"
                                                                                    className="edit-delete-button"
                                                                                    onClick={() =>
                                                                                        handleRemoveCard(ele.id)
                                                                                    }
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="12.138"
                                                                                        height="15.606"
                                                                                        viewBox="0 0 12.138 15.606"
                                                                                    >
                                                                                        <path
                                                                                            id="Icon_material-delete-forever"
                                                                                            data-name="Icon material-delete-forever"
                                                                                            d="M8.367,18.372A1.739,1.739,0,0,0,10.1,20.106h6.936a1.739,1.739,0,0,0,1.734-1.734V7.968H8.367ZM10.5,12.2l1.222-1.222,1.847,1.838,1.838-1.838L16.629,12.2l-1.838,1.838,1.838,1.838L15.407,17.1l-1.838-1.838L11.731,17.1l-1.222-1.222,1.838-1.838Zm6.1-6.832L15.736,4.5H11.4l-.867.867H7.5V7.1H19.638V5.367Z"
                                                                                            transform="translate(-7.5 -4.5)"
                                                                                            fill="currentcolor"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                            </SwiperSlide>
                                                                        ))}
                                                                    </Swiper>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <NoDataFound />
                                                    )}
                                                </div>
                                            ) : (
                                                <Skeleton height={365} borderRadius="2.6rem" />
                                            )}
                                        </div>

                                        <div className="col-12">
                                            <div className="wallet-card transactionhistory">
                                                <h2> {languageSelector?.wallet_payment?.transaction_history} </h2>
                                                <div className="transactionhistory-table transactionhistory-table-main table-responsive">
                                                    <Table striped responsive>
                                                        <tbody>
                                                            {Object.keys(transactionListSelector).length >
                                                                0 ? (
                                                                transactionListSelector?.data?.length > 0 ? (
                                                                    transactionListSelector?.data?.map(
                                                                        (value, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div className="image-content-box">
                                                                                        <div className="count-number">{index + 1}</div>
                                                                                        {value?.added_type == 2 ? (
                                                                                            <h3> <div className="wallet-icon"><FaWallet /></div> {languageSelector?.wallet_payment?.amount_added_in_wallet} </h3>
                                                                                        ) : value.gift_card && Object.keys(value.gift_card).length > 0 ? (
                                                                                            <h3> <div className="wallet-icon"><AiFillGift /></div> {value.gift_card.name} ({languageSelector?.wallet_payment?.gift_card}) </h3>
                                                                                        ) : (
                                                                                            <>
                                                                                                <div className="image-payment">
                                                                                                    <img
                                                                                                        src={
                                                                                                            value?.booking_request
                                                                                                                ?.user_instructor
                                                                                                                ?.profile_image
                                                                                                                ? isValidHttpUrl(
                                                                                                                    value
                                                                                                                        ?.booking_request
                                                                                                                        ?.user_instructor
                                                                                                                        ?.profile_image
                                                                                                                )
                                                                                                                    ? value
                                                                                                                        ?.booking_request
                                                                                                                        ?.user_instructor
                                                                                                                        ?.profile_image
                                                                                                                    : `${baseUrlPostGres()}${value
                                                                                                                        ?.booking_request
                                                                                                                        ?.user_instructor
                                                                                                                        ?.profile_image
                                                                                                                    }`
                                                                                                                : "/images/blank-profile-picture.jpg"
                                                                                                        }
                                                                                                        alt={
                                                                                                            value?.booking_request
                                                                                                                ?.user_instructor
                                                                                                                ?.first_name
                                                                                                        }
                                                                                                        referrerPolicy="no-referrer"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="name-level">
                                                                                                    <h3>
                                                                                                        {value?.booking_request
                                                                                                            ?.user_instructor
                                                                                                            ? `${value?.booking_request?.user_instructor?.first_name} ${value?.booking_request?.user_instructor?.last_name}`
                                                                                                            : "Untitled"}
                                                                                                    </h3>
                                                                                                    <p>
                                                                                                        {value?.booking_request
                                                                                                            ?.user_tutor_subject
                                                                                                            ? `${value?.booking_request?.user_tutor_subject?.tutor_sub_name}  (${value?.booking_request?.class_level?.class_name})`
                                                                                                            : null}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {moment(value?.created_at).format("DD MMM'YY")}
                                                                                    <br />
                                                                                    at {moment(value?.created_at).format("LT")}
                                                                                </td>
                                                                                <td>
                                                                                    {value?.pay_from === 1
                                                                                        ? languageSelector?.wallet_payment?.card
                                                                                        : value?.pay_from === 2
                                                                                            ? languageSelector?.wallet_payment?.paypal
                                                                                            : languageSelector?.wallet_payment?.wallet
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {languageSelector?.wallet_payment?.id_number}: <br />
                                                                                    {value?.local_txn_id
                                                                                        ? `#${value?.local_txn_id}`
                                                                                        : "-"}
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    <span
                                                                                        className={
                                                                                            value?.added_type === 1 ||
                                                                                                value?.added_type === 4
                                                                                                ? "not-paid"
                                                                                                : "paid"
                                                                                        }
                                                                                    >
                                                                                        {value?.added_type === 1 ||
                                                                                            value?.added_type === 4
                                                                                            ? "-"
                                                                                            : "+"}
                                                                                        ${value?.txn_amount}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">
                                                                            <NoDataFound />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            ) : (
                                                                [1, 2, 3, 4, 5, 6].map((_, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td colSpan="5">
                                                                                <Skeleton
                                                                                    height={50}
                                                                                    borderRadius="1rem"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <AddAmount
                show={showAddAmountModal}
                onHide={() => setShowAddAmountModal(false)}
            />

            <AddCardModal
                show={show}
                onHide={handleClose}
                clickButton={submitClick}
                setClickButton={setSubmitClick}
                isloading={isLoading}
            />

            <DeleteRecordModal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                loading={isLoading}
                onSubmit={handleDeleteRecord}
            />
        </>
    );
};

export default Studentwalletpayment;
