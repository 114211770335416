import {
    IS_NOTIFICATION_LOADING,
    NOTIFICATION_LIST_SUCCESS, NOTIFICATION_LIST_ERROR,
    CLEAR_NOTIFICATION_SUCCESS, CLEAR_NOTIFICATION_ERROR,
    DELETE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_ERROR,
    NEW_NOTIFICATION_STATUS_SUCCESS, NEW_NOTIFICATION_STATUS_ERROR
} from "../actions/Type";

const initialState = {
    notification_list: {},
    notification_clear: {},
    notification_delete: {},
    new_notification: {},
    loading: false,
};

export const NotificationReducer = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case IS_NOTIFICATION_LOADING:
            return {
                ...state,
                loading: true,
            };
        case NEW_NOTIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                new_notification: payload,
                loading: false,
            };
        case NEW_NOTIFICATION_STATUS_ERROR:
            return {
                ...state,
                new_notification: payload,
                loading: false,
            };
        case DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notification_delete: payload,
                loading: false,
            };
        case DELETE_NOTIFICATION_ERROR:
            return {
                ...state,
                notification_delete: payload,
                loading: false,
            };
        case CLEAR_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notification_clear: payload,
                loading: false,
            };
        case CLEAR_NOTIFICATION_ERROR:
            return {
                ...state,
                notification_clear: payload,
                loading: false,
            };
        case NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                notification_list: payload,
                loading: false,
            };
        case NOTIFICATION_LIST_ERROR:
            return {
                ...state,
                notification_list: payload,
                loading: false,
            };
        default:
            return state;
    }
};