import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { FieldText } from "./../../../components/InputText/InputText";
import { GetInstructorClassLevel, GetInstructorTags } from "./../../../redux/actions/instructorAction";
import Button from "./../../../components/Buttons/Buttons";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import TextErrorMsg from "./../../../components/TextErrorMsg/TextErrorMsg";
import { InstructorProfileTeachingDetailAction } from "../../../redux/actions/CompleteProfileAction";

const TeachingDetails = ({ profileData }) => {
    const dispatch = useDispatch();
    const [submitClick, setSubmitClick] = useState(false)
    const [subjectWantToTutor, setSubjectWantToTutor] = useState([]);
    const [tagName, setTagName] = useState([])

    const tagsList = useSelector((state) => state?.instructor?.instructor_tags);
    const classLevel = useSelector((state) => state?.instructor?.instructor_class_level);
    const isLoading = useSelector((state) => state.profile.loading);
    const ProfileTDSelector = useSelector((state) => state.profile.profile_teaching_details);
    const languageSelector = useSelector(state => state.home.dynamic_language)


    const tagsOptions = tagsList.map((elem) => (
        {
            cat: `Group ${elem.id}`,
            key: elem.tags_name,
            value: elem.id
        }
    ))

    useEffect(() => {
        dispatch(GetInstructorClassLevel());
        dispatch(GetInstructorTags())
    }, [])

    useEffect(() => {
        if (profileData && profileData?.usertags?.length > 0) {
            profileData?.usertags.forEach((element, index) => {
                element.cat = `Group ${index + 1}`
                element.key = element.usertags_name[0].tags_name
                element.value = element.usertags_name[0].id
                return <></>
            });
        }
    }, [profileData])

    useEffect(() => {
        if (profileData && profileData?.user_tutor_subject?.length > 0) {
            profileData?.user_tutor_subject.forEach((element) => {
                setSubjectWantToTutor((s) => {
                    return [...s, { subject_name: element.tutor_sub_name, level_id: element.class_level_id }];
                });
            });
        }
    }, [profileData])

    const handleMultiSelect = (data) => {
        const tags = data.map((elem) => {
            return elem.value;
        });
        setTagName([...tags]);
    };

    const handleAddMoreSubjects = () => {
        setSubjectWantToTutor(s => {
            return [...s, { subject_name: "", level_id: "" }];
        });
    };
    const handleSubjectWantTutorDelete = (id) => {
        setSubjectWantToTutor(oldValues => {
            return oldValues.filter((_, i) => i !== id)
        })
    };

    const TeachingDetailValidationSchema = Yup.object().shape({
        exp_year: Yup.string().required(languageSelector?.validation?.experience_year).min(1, languageSelector?.validation?.experience_digits).max(3, languageSelector?.validation?.experience_digits),
        exp_months: Yup.string().required(languageSelector?.validation?.experience_month),
        on_demand_price: Yup.number().min(1, languageSelector?.validation?.price_greater_0).required(languageSelector?.validation?.ondemand_price),
        schedule_price: Yup.number().min(1, languageSelector?.validation?.price_greater_0).required(languageSelector?.validation?.schedule_price),
        problem_solving_price: Yup.number().min(1, languageSelector?.validation?.price_greater_0).required(languageSelector?.validation?.problem_solving_price),
        monthly_subscription_price: Yup.number().min(1, languageSelector?.validation?.price_greater_0).required(languageSelector?.validation?.monthly_subscription_price),
        year_subscription_price: Yup.number().min(1, languageSelector?.validation?.price_greater_0).required(languageSelector?.validation?.year_subscription_price),
        // tag_id: Yup.string().required('Language is required!'),
    })

    const handleOnChange = (e) => {
        const index = e.target.id;
        if (e.target.name === "subject_name" || e.target.name === "level_id") {
            setSubjectWantToTutor(s => {
                const newArr = s.slice();
                newArr[index][e.target.name] = e.target.value;
                return newArr;
            });
        }
    }

    const handleTeachingDetailSubmit = (value) => {
        let data = {
            exp_year: value.exp_year,
            exp_months: value.exp_months,
            on_demand_price: value.on_demand_price,
            schedule_price: value.schedule_price,
            problem_solving_price: value.problem_solving_price,
            monthly_subscription_price: value.monthly_subscription_price,
            year_subscription_price: value.year_subscription_price,
            tutorsubname: JSON.stringify(subjectWantToTutor)
        }
        if (tagName.length > 0) {
            data["tag_id"] = JSON.stringify(tagName)
        }
        dispatch(InstructorProfileTeachingDetailAction(data));
        setSubmitClick(true)
    }

    useEffect(() => {
        if (Object.keys(ProfileTDSelector).length > 0 && submitClick) {
            setSubmitClick(false)
        }
    }, [ProfileTDSelector, submitClick])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    return (
        Object.keys(profileData).length > 0 ?
            <Formik
                initialValues={{
                    exp_year: profileData?.user_extra_info?.exp_year,
                    exp_months: profileData?.user_extra_info?.exp_months,
                    on_demand_price: profileData?.user_extra_info?.on_demand_price,
                    schedule_price: profileData?.user_extra_info?.schedule_price,
                    problem_solving_price: profileData?.user_extra_info?.problem_solving_price,
                    monthly_subscription_price: profileData?.user_extra_info?.monthly_subscription_price,
                    year_subscription_price: profileData?.user_extra_info?.year_subscription_price,
                    tag_id: profileData?.usertags
                }}
                validationSchema={TeachingDetailValidationSchema}
                onSubmit={handleTeachingDetailSubmit}
            >
                {formik => {
                    return (
                        <Form onChange={handleOnChange}>
                            <FormikFromFunc />
                            <div className="qualifications-section">
                                <div className="tab-content">
                                    <div className="myprofilebox2">
                                        <div className="myprofilebox1">
                                            <h3> {languageSelector?.my_account?.subject_tutor} </h3>
                                        </div>
                                        <div className="myprofilebox1 addsubjects">
                                            <button
                                                className="btn becomaninstructor"
                                                onClick={handleAddMoreSubjects}
                                                type="button"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="17.5"
                                                    height="17.5"
                                                    viewBox="0 0 17.5 17.5"
                                                >
                                                    <circle
                                                        id="Ellipse_101"
                                                        data-name="Ellipse 101"
                                                        cx="8"
                                                        cy="8"
                                                        r="8"
                                                        transform="translate(0.75 0.75)"
                                                        fill="none"
                                                        stroke="#005eb5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    />
                                                    <line
                                                        id="Line_176"
                                                        data-name="Line 176"
                                                        x2="6"
                                                        transform="translate(5.75 8.75)"
                                                        fill="none"
                                                        stroke="#005eb5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    />
                                                    <line
                                                        id="Line_177"
                                                        data-name="Line 177"
                                                        y2="6"
                                                        transform="translate(8.75 5.75)"
                                                        fill="none"
                                                        stroke="#005eb5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                                {languageSelector?.my_account?.add_more_button}
                                            </button>
                                        </div>
                                        {subjectWantToTutor.map((value, index) => (
                                            <div className="myprofilebox1 addmoresubjects-section" key={index}>
                                                <div className="myprofilebox3">
                                                    <FieldText
                                                        type="text"
                                                        name="subject_name"
                                                        placeholder={languageSelector?.placeholder?.subject_tutor}
                                                        id={index}
                                                        defaultValue={value.subject_name}
                                                    />
                                                </div>

                                                <div className="myprofilebox3 dropdown-select fix-placeholder">
                                                    <div className="App">
                                                        <Field as="select" name="level_id" className='select-control w-100' id={index} value={value.level_id}>
                                                            <option value=""> {languageSelector?.placeholder?.select_level} </option>
                                                            {classLevel.map((value, index) => {
                                                                return (
                                                                    <option value={value.id} key={index}> {value.class_name} </option>
                                                                )
                                                            })}
                                                        </Field>
                                                        <span className="placeholder-name"> {languageSelector?.my_account?.level} </span>
                                                    </div>
                                                </div>

                                                <div className="myprofilebox3 cros-button-sub">
                                                    {index > 0 ?
                                                        <button
                                                            onClick={() => handleSubjectWantTutorDelete(index)}
                                                            value={index}
                                                            type="button"
                                                            className={
                                                                index < 1
                                                                    ? `btn becomaninstructor`
                                                                    : `active btn becomaninstructor`
                                                            }
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20.687"
                                                                height="26.597"
                                                                viewBox="0 0 20.687 26.597"
                                                            >
                                                                <path
                                                                    id="Icon_material-delete-forever"
                                                                    data-name="Icon material-delete-forever"
                                                                    d="M8.978,28.142A2.964,2.964,0,0,0,11.933,31.1H23.754a2.964,2.964,0,0,0,2.955-2.955V10.41H8.978Zm3.635-10.521L14.7,15.538l3.147,3.133,3.133-3.133,2.083,2.083-3.133,3.133,3.133,3.133L20.976,25.97l-3.133-3.133L14.711,25.97l-2.083-2.083,3.133-3.133Zm10.4-11.644L21.537,4.5H14.149L12.672,5.978H7.5V8.933H28.187V5.978Z"
                                                                    transform="translate(-7.5 -4.5)"
                                                                    fill="#fff"
                                                                />
                                                            </svg>
                                                        </button>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        ))}

                                        <div className="myprofilebox1 certificate-file-box">
                                            <h3> {languageSelector?.my_account?.experience} </h3>
                                        </div>

                                        <div className="myprofilebox1">
                                            <div className="accountfield-first">
                                                <FieldText
                                                    type="number"
                                                    name="exp_year"
                                                    placeholder={languageSelector?.placeholder?.number_of_year}
                                                    maxLength="100"
                                                    defaultValue={formik.values.exp_year}
                                                />
                                            </div>
                                        </div>

                                        <div className="myprofilebox1 dropdown-select fix-placeholder">
                                            <div className="App">
                                                <Field as="select" name="exp_months" className='select-control w-100' value={formik.values.exp_months}>
                                                    <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value, index) => {
                                                        return (
                                                            <option value={value} key={index}> {value} </option>
                                                        )
                                                    })}
                                                </Field>
                                                <ErrorMessage name="exp_months" component={TextErrorMsg} />
                                                <span className="placeholder-name"> {languageSelector?.my_account?.number_month} </span>
                                            </div>
                                        </div>

                                        <div className="myprofilebox1 certificate-file-box">
                                            <h3> {languageSelector?.my_account?.edit_cost} </h3>
                                        </div>

                                        <div className="myprofilebox1">
                                            <FieldText
                                                name="on_demand_price"
                                                type="number"
                                                placeholder={languageSelector?.placeholder?.demand_class}
                                                defaultValue={formik.values.on_demand_price}
                                            />
                                        </div>

                                        <div className="myprofilebox1">
                                            <FieldText
                                                name="schedule_price"
                                                type="number"
                                                placeholder={languageSelector?.placeholder?.schedule_class}
                                                defaultValue={formik.values.schedule_price}
                                            />
                                        </div>

                                        <div className="myprofilebox1">
                                            <FieldText
                                                name="problem_solving_price"
                                                type="number"
                                                placeholder={languageSelector?.placeholder?.Problem_class}
                                                defaultValue={formik.values.problem_solving_price}
                                            />
                                        </div>

                                        <div className="myprofilebox1">
                                            <FieldText
                                                name="monthly_subscription_price"
                                                type="number"
                                                placeholder={languageSelector?.placeholder?.monthly_class}
                                                defaultValue={formik.values.monthly_subscription_price}
                                            />
                                        </div>

                                        <div className="myprofilebox1">
                                            <FieldText
                                                name="year_subscription_price"
                                                type="number"
                                                placeholder={languageSelector?.placeholder?.yearly_class}
                                                defaultValue={formik.values.year_subscription_price}
                                            />
                                        </div>

                                        <div className="myprofilebox1 certificate-file-box">
                                            <h3>{languageSelector?.placeholder?.tag}</h3>
                                        </div>

                                        <div className="myprofilebox1 certificate-file-box tags">
                                            <Field name="tag_id">
                                                {({ form, field }) => {
                                                    return (
                                                        <Multiselect
                                                            hidePlaceholder={true}
                                                            customCloseIcon={<><img src='/images/cross.svg' style={{ marginLeft: 10 }} alt="" /></>}
                                                            // disablePreSelectedValues
                                                            name={field.name}
                                                            displayValue="key"
                                                            onKeyPressFn={function noRefCheck() { }}
                                                            onRemove={function noRefCheck(data) {
                                                                handleMultiSelect(data)
                                                                form.setFieldValue(field.name, data)
                                                            }}
                                                            onSearch={function noRefCheck() { }}
                                                            onSelect={(data) => {
                                                                handleMultiSelect(data)
                                                                form.setFieldValue(field.name, data)
                                                            }}
                                                            placeholder={languageSelector?.placeholder?.tag}
                                                            options={tagsOptions}
                                                            selectedValues={formik.values.tag_id}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                            <ErrorMessage name="tag_id" component={TextErrorMsg} />
                                        </div>
                                    </div>
                                    <div className="save-button">
                                        <Button
                                            BtnText={languageSelector?.my_account?.save_changes_button}
                                            name="next"
                                            BtnColor="primary"
                                            BtnType="submit"
                                            disabled={!formik.isValid || isLoading}
                                            hasSpinner={isLoading && submitClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            :
            null
    )
}
export default TeachingDetails