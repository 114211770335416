import axios from "axios";
import { toast } from "react-toastify";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    SUPPORT_LIST_ERROR, SUPPORT_LIST_SUCCESS,
    SUPPORT_DETAIL_ERROR, SUPPORT_DETAIL_SUCCESS,
    IS_SUPPORT_LOADING,
    SUPPORT_MESSAGE_REPLY_SUCCESS, SUPPORT_MESSAGE_REPLY_ERROR,
} from "./Type";
import HandleError from "./HandleError";

export const SupportListAction = (user_id) => async (dispatch) => {
    dispatch({ type: IS_SUPPORT_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/contact-list/${user_id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: SUPPORT_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: SUPPORT_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetSupportListAction = () => async (dispatch) => {
    dispatch({
        type: SUPPORT_LIST_SUCCESS,
        payload: {},
    });
}


export const SupportDetailAction = (id) => async (dispatch) => {
    dispatch({ type: IS_SUPPORT_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/contact-details/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: SUPPORT_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: SUPPORT_DETAIL_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetSupportDetailAction = () => async (dispatch) => {
    dispatch({
        type: SUPPORT_DETAIL_SUCCESS,
        payload: {},
    });
}

export const SupportSendMessageAction = (data) => async (dispatch) => {
    dispatch({ type: IS_SUPPORT_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/contact-reply`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: SUPPORT_MESSAGE_REPLY_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: SUPPORT_MESSAGE_REPLY_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetSupportSendMessageAction = () => async (dispatch) => {
    dispatch({
        type: SUPPORT_MESSAGE_REPLY_SUCCESS,
        payload: {},
    });
}