import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GetDynamicLanguageKeysAction, GetLanguageAction, ResetGetDynamicLanguageKeysAction } from '../../redux/actions/HomePageActions';
import { Dropdown } from 'react-bootstrap';
import { baseUrlPostGres } from '../../redux/constant';

const GoogleTranslate = () => {
    const dispatch = useDispatch()
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [languageList, setLanguageList] = useState([]);

    useEffect(() => {
        GetLanguageAction(response => {
            if (response?.status === true) {
                setLanguageList(response?.data)
                if (localStorage.selectedLanguage) {
                    const value = JSON.parse(localStorage.selectedLanguage)
                    const checkLanguageIsActive = response?.data.some((lang) => value?.id === lang.id)

                    if (checkLanguageIsActive) {
                        setSelectedLanguage({ id: value?.id, flag: value?.flag, short_code: value?.short_code })
                    } else {
                        const data = { id: response?.data[0]?.id, flag: response?.data[0]?.flag, short_code: response?.data[0]?.short_code }
                        localStorage.setItem("selectedLanguage", JSON.stringify(data))
                        setSelectedLanguage(data)
                    }
                } else {
                    const data = { id: response?.data[0]?.id, flag: response?.data[0]?.flag, short_code: response?.data[0]?.short_code }
                    localStorage.setItem("selectedLanguage", JSON.stringify(data))
                    setSelectedLanguage(data)
                }
            } else { }
        })();
    }, [GetLanguageAction])

    useEffect(() => {
        if (selectedLanguage?.id) {
            dispatch(GetDynamicLanguageKeysAction(selectedLanguage?.id))
        }
    }, [selectedLanguage])

    const ChangeLanguage = (value) => {
        dispatch(ResetGetDynamicLanguageKeysAction())
        const data = { id: value?.id, flag: value?.flag, short_code: value?.short_code }
        localStorage.setItem("selectedLanguage", JSON.stringify(data))
        setSelectedLanguage(data)
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className='language_btn_header'>
                    <div className='common-img-design_language'>
                        <img className='common-img-design' src={baseUrlPostGres() + selectedLanguage?.flag} alt={selectedLanguage?.short_code} />
                    </div>
                    {/* <span>{selectedLanguage?.short_code ? selectedLanguage?.short_code.toUpperCase() : ""}</span> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {languageList?.length > 0 &&
                        languageList?.map((value, index) => (
                            <Dropdown.Item
                                key={index}
                                className={value?.id === selectedLanguage?.id && "active"}
                                id={value?.id}
                                onClick={() => ChangeLanguage(value)}>
                                {/* {value?.lang_name} */}
                                {value?.lang_name}
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>

        </>
    );
};

export default GoogleTranslate;
