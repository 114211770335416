import React, { useEffect, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import '../Instructorprofile/Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { BlogDeleteAction, BlogListAction, ResetBlogDeleteAction } from '../../redux/actions/BlogActions';
import { baseUrlPostGres } from '../../redux/constant';
import moment from 'moment';
import DeleteRecordModal from '../../components/DeleteRecordModal/DeleteRecordModal';
import { AiFillEye } from "react-icons/ai";
import { Pagination } from '../../components/Hooks/Pagination';
const BlogList = ({ role }) => {
    const dispatch = useDispatch();
    const { name } = useParams()
    const navigate = useNavigate()
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteRecordID, setDeleteRecordID] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const blogListSelector = useSelector((state => state.blogs.blog_list))
    const isloading = useSelector((state => state.blogs.loading))
    const blogDeleteSelector = useSelector((state => state.blogs.blog_delete))
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(BlogListAction(role == 2 ? "list-blog" : "blog-list", { page: currentPage }))
    }, [dispatch, role, currentPage])


    const handleOpenDeleteModal = (id) => {
        setDeleteRecordID(id)
        setShowDeleteModal(true)
    }
    const handleCloseTwo = () => {
        setDeleteRecordID("")
        setShowDeleteModal(false)
    };

    const handleDeleteBlog = () => {
        dispatch(BlogDeleteAction(role == 2 ? "student" : "instructor", deleteRecordID))
    }

    useEffect(() => {
        if (Object.keys(blogDeleteSelector).length > 0) {
            if (blogDeleteSelector.status) {
                setShowDeleteModal(false)
                dispatch(BlogListAction(role == 2 ? "list-blog" : "blog-list", { page: currentPage }))
                dispatch(ResetBlogDeleteAction())
            }
        }
    }, [blogDeleteSelector])
    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.my_blogs?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.my_blogs?.page_title} </span></p>
                </div>

                <div className='myprofile-section classesdetails-page blog-page-add'>
                    <div className='container'>
                        <div className='row gx-5'>
                            <div className='col-md-12 col-lg-5 col-xl-4'>
                                {role == 2 ? <Studentsidebar /> : <Instructorsidebar />}
                            </div>

                            <div className='col-md-12 col-lg-7 col-xl-8'>
                                <div className='profile-top-heading addnewblog-section'>
                                    <div> <h2> {languageSelector?.my_blogs?.page_title} </h2> </div>
                                    <div className='addnewblog'>
                                        <Link className="btn" to={`/my-blog/${name}/create`}> {languageSelector?.my_blogs?.add_blog} </Link>
                                    </div>
                                </div>


                                {blogListSelector?.data?.data?.length > 0 ?
                                    <div className="blog-section">
                                        {blogListSelector?.data?.data?.map((value, index) => {
                                            return (
                                                <div className="bolg-card-main" key={index}>
                                                    <div className='editdelete'>
                                                        <button className="btn edit" onClick={() => navigate(`/my-blog/${name}/edit/${value.id}`)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.742" viewBox="0 0 15.742 15.742">
                                                                <path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,16.959v3.279H7.779l9.671-9.671L14.171,7.288ZM19.986,8.031a.871.871,0,0,0,0-1.233L17.94,4.752a.871.871,0,0,0-1.233,0l-1.6,1.6,3.279,3.279Z" transform="translate(-4.5 -4.496)" fill="#fff" />
                                                            </svg>
                                                        </button>
                                                        <button className="btn" onClick={() => handleOpenDeleteModal(value.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.138" height="15.606" viewBox="0 0 12.138 15.606">
                                                                <path id="Icon_material-delete-forever" data-name="Icon material-delete-forever" d="M8.367,18.372A1.739,1.739,0,0,0,10.1,20.106h6.936a1.739,1.739,0,0,0,1.734-1.734V7.968H8.367ZM10.5,12.2l1.222-1.222,1.847,1.838,1.838-1.838L16.629,12.2l-1.838,1.838,1.838,1.838L15.407,17.1l-1.838-1.838L11.731,17.1l-1.222-1.222,1.838-1.838Zm6.1-6.832L15.736,4.5H11.4l-.867.867H7.5V7.1H19.638V5.367Z" transform="translate(-7.5 -4.5)" fill="#fff" />
                                                            </svg>
                                                        </button>
                                                        <Link className="btn preview" to={value.status == 2 ? `/my-blog/${name}/preview/${value.id}` : `/blogdetail/${value.id}`}>
                                                            <AiFillEye color='#fff' />
                                                        </Link>
                                                    </div>
                                                    <div className="image-box">
                                                        <Link to={value.status == 2 ? `/my-blog/${name}/preview/${value.id}` : `/blogdetail/${value.id}`}>
                                                            <img src={`${baseUrlPostGres()}${value.blog_image}`} alt={value.blog_title} />
                                                        </Link>
                                                    </div>
                                                    <div className="date-section">
                                                        <div className="date">
                                                            <div className="btn"> {moment(value.created_at).format("DD MMM'YY")} </div>
                                                        </div>
                                                        <div className="categ">
                                                            <div className="btn"> {value?.blog_category?.blog_category} </div>
                                                        </div>
                                                    </div>
                                                    <h2> <Link className='h2' to={value.status == 2 ? `/my-blog/${name}/preview/${value.id}` : `/blogdetail/${value.id}`}> {value.blog_title} </Link> </h2>
                                                    <p className="name-comment-section"><span className="name-customer">By <span>{value.blog_user.first_name} {value.blog_user.last_name}</span> </span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.535" height="11.268" viewBox="0 0 12.535 11.268">
                                                            <g id="Group_221" data-name="Group 221" transform="translate(-1022.787 -658)">
                                                                <path id="Path_141" data-name="Path 141" d="M1108.784,661.66h3.244a1.214,1.214,0,0,1,1.269,1.261,3.506,3.506,0,0,1-.338,1.631c-.573,1.237-1.114,2.488-1.668,3.734a1.539,1.539,0,0,1-1.513.98c-1.209,0-2.418,0-3.627,0a1.958,1.958,0,0,1-1.473-.612q-.3-.306-.6-.6a.427.427,0,0,1-.135-.327c0-1.512,0-3.025.006-4.537a.428.428,0,0,1,.106-.288,20.475,20.475,0,0,0,2.776-3.444c.217-.3.438-.6.647-.908a1.075,1.075,0,0,1,1.151-.527,1.182,1.182,0,0,1,.92.908,1.4,1.4,0,0,1-.064.961c-.218.539-.433,1.079-.648,1.619C1108.819,661.555,1108.805,661.6,1108.784,661.66Z" transform="translate(-77.977)" fill="#005eb5" fillRule="evenodd" />
                                                                <path id="Path_142" data-name="Path 142" d="M1022.79,776.058c0-.7-.008-1.4,0-2.1a1.012,1.012,0,0,1,.619-.94,1.04,1.04,0,0,1,1.123.111,1,1,0,0,1,.434.828c.005,1.4.008,2.81,0,4.214a1.088,1.088,0,0,1-2.173.017c-.014-.709,0-1.419,0-2.129Z" transform="translate(0 -110.402)" fill="#005eb5" fillRule="evenodd" />
                                                            </g>
                                                        </svg>
                                                        <span className="like-number"> {value.bloglike_count} </span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.469" height="11.268" viewBox="0 0 11.469 11.268">
                                                            <g id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" transform="translate(-8.177 -3.375)">
                                                                <path id="Path_143" data-name="Path 143" d="M18.558,12.367a.8.8,0,0,1,.109-.4,1.11,1.11,0,0,1,.069-.1,5.345,5.345,0,0,0,.91-2.979,5.611,5.611,0,0,0-5.729-5.508A5.69,5.69,0,0,0,8.3,7.759a5.3,5.3,0,0,0-.122,1.128,5.6,5.6,0,0,0,5.64,5.578,6.793,6.793,0,0,0,1.557-.254c.373-.1.742-.237.838-.274a.872.872,0,0,1,.307-.056.858.858,0,0,1,.333.066l1.87.663a.446.446,0,0,0,.129.033.263.263,0,0,0,.264-.264.424.424,0,0,0-.016-.089Z" fill="#005eb5" />
                                                            </g>
                                                        </svg>
                                                        <span> {value.blog_comment_count} </span>
                                                    </p>

                                                    <p className="desceiption-button">
                                                        {value?.short_blog_description}
                                                        <Link className="btn" to={value.status == 2 ? `/my-blog/${name}/preview/${value.id}` : `/blogdetail/${value.id}`}> {languageSelector?.add_blog?.read_more} </Link>
                                                    </p>
                                                </div>
                                            )
                                        })}

                                        <div className="col-md-12">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={blogListSelector?.data?.total}
                                                pageSize={blogListSelector?.data?.per_page}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className='blog-top-content'>
                                        <p> {languageSelector?.add_blog?.blog_page_empty} </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <DeleteRecordModal
                show={showDeleteModal}
                onHide={handleCloseTwo}
                loading={isloading}
                onSubmit={handleDeleteBlog}
            />
        </>
    );
}

export default BlogList
