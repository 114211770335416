import { combineReducers } from "redux";
import authReducers from "./AuthReducers";
import { CardReducers } from "./CardReducers";
import { GroupClassRedeucer } from "./GroupClassReducers";
import HomePagereducer from "./HomePagereducer";
import { InstructorReducer } from "./instructorReducer";
import { CompleteProfileReducers } from "./CompleteProfileReducers"
import { BlogReducer } from "./BlogReducer";
import { ManagesubscriptionReducers } from "./ManagesubscriptionReducers"
import { ClassesDetailReducers } from "./ClassesDetailReducers"
import { ReviewAndRatingReducers } from "./ReviewAndRatingReducers";
import { GiftCardReducer } from "./GiftCardReducer";
import { ReferEarnReducers } from "./ReferEarnReducers";
import { NotificationReducer } from "./NotificationReducer";
import { CMSReducer } from "./CMSReducer";
import { ChatReducers } from "./ChatReducers";
import { SupportReducers } from "./SupportReducers";

export default combineReducers({
    auth: authReducers,
    instructor: InstructorReducer,
    card: CardReducers,
    home: HomePagereducer,
    blogs: BlogReducer,
    group: GroupClassRedeucer,
    profile: CompleteProfileReducers,
    subscription: ManagesubscriptionReducers,
    classesDetail: ClassesDetailReducers,
    rating: ReviewAndRatingReducers,
    giftCard: GiftCardReducer,
    refer: ReferEarnReducers,
    notification: NotificationReducer,
    cms: CMSReducer,
    chat: ChatReducers,
    support:SupportReducers
})