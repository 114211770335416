import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import Table from "react-bootstrap/Table";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Instructorprofile.scss";
import { useDispatch, useSelector } from "react-redux";
import { InstructorManageSubscription } from "../../redux/actions/ManagesubscriptionAction";
import { baseUrlPostGres } from "../../redux/constant";
import moment from "moment/moment";
import { BsFillChatTextFill } from "react-icons/bs";

const Managesubscription = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const ManagesubscriptionSelector = useSelector(state => state.subscription.manage_subscription)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const [item, setItem] = useState({ kindOfStand: "All", another: "another" });
    const [subscritptionData, setSubscritptionData] = useState();
    const { kindOfStand } = item;
    
    useEffect(() => {
        dispatch(InstructorManageSubscription({ type: "" }))
    }, [dispatch])

    useEffect(() => {
        setSubscritptionData(ManagesubscriptionSelector?.data)
    }, [ManagesubscriptionSelector])

    const handleChange = (e) => {
        e.persist();
        setItem((prevState) => ({
            ...prevState,
            kindOfStand: e.target.value,
        }));
    };

    const handleFilter = (status) => {
        const upadtedItems = ManagesubscriptionSelector?.data?.filter((curElem) => {
            if (status !== "all") {
                return curElem.status === (status == "active" ? 1 : 5);
            } else {
                return curElem
            }
        });
        setSubscritptionData(upadtedItems);
    };

    return (
        <div id="footer-bottom" className="instructor-profile">
            <Header />
            <div className="instru-bg-image">
                <h1> {languageSelector?.manage_subscription?.page_title} </h1>
                <p> Home / <span>  {languageSelector?.manage_subscription?.page_title} </span> </p>
            </div>

            <div className="myprofile-section">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-md-12 col-lg-4">
                            <Instructorsidebar />
                        </div>

                        <div className="col-md-12 col-lg-8">
                            <div className="profile-top-heading">
                                <h2> {languageSelector?.manage_subscription?.page_title} </h2>
                            </div>

                            <div className="booking-request-section">
                                <div className="booking-filter">
                                    <div className="btn">
                                        <Form.Group controlId="kindOfStand">
                                            <Form.Check value="All" type="radio" onClick={() => handleFilter("all")} aria-label="radio 1" label={languageSelector?.manage_subscription?.all} onChange={handleChange} checked={kindOfStand === "All"} />
                                        </Form.Group>
                                    </div>

                                    <div className="btn">
                                        <Form.Group controlId="kindOfStand2">
                                            <Form.Check value="Active" type="radio" onClick={() => handleFilter("active")} aria-label="radio 1" label={languageSelector?.manage_subscription?.active} onChange={handleChange} checked={kindOfStand === "Active"} />
                                        </Form.Group>
                                    </div>

                                    <div className="btn" >
                                        <Form.Group controlId="kindOfStand3">
                                            <Form.Check value="Expired" type="radio" onClick={() => handleFilter("expired")} aria-label="radio 1" label={languageSelector?.manage_subscription?.expired} onChange={handleChange} checked={kindOfStand === "Expired"} />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="my-profile-details">
                                <div className="manages-section-main">
                                    <div className="manages-section">
                                        <Table striped responsive>
                                            <thead>
                                                <tr>
                                                    <th> {languageSelector?.manage_subscription?.student_name} </th>
                                                    <th> {languageSelector?.manage_subscription?.start_date} </th>
                                                    <th> {languageSelector?.manage_subscription?.end_date} </th>
                                                    <th> {languageSelector?.manage_subscription?.subscripiton_id} </th>
                                                    <th> {languageSelector?.manage_subscription?.plan} </th>
                                                    <th> {languageSelector?.manage_subscription?.status} </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subscritptionData &&
                                                    subscritptionData?.length > 0 ?
                                                    subscritptionData?.map((elem, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <img src={elem?.user?.profile_image ? `${baseUrlPostGres()}${elem.user.profile_image}` : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt="" />
                                                                    <span> {elem?.user?.first_name} {elem?.user?.last_name} </span>
                                                                </td>
                                                                <td> {moment(elem.start_date, "YYYY-MM-DD").format("DD MMM'YY")} </td>
                                                                <td> {moment(elem.end_date, "YYYY-MM-DD").format("DD MMM'YY")} </td>
                                                                <td>ID Number: #{elem?.transaction_user?.bookingId}</td>
                                                                <td>{moment(elem.end_date).diff(elem.start_date, 'days') <= 30 ? languageSelector?.manage_subscription?.monthly : languageSelector?.manage_subscription?.yearly}</td>
                                                                <td> {elem?.status === 1 ? languageSelector?.manage_subscription?.active : languageSelector?.manage_subscription?.expired} </td>
                                                                <td>
                                                                    <div className="btn"
                                                                        onClick={() => navigate("/instructor/my-chats", {
                                                                            state: {
                                                                                full_name: `${elem?.user?.first_name} ${elem?.user?.last_name}`,
                                                                                id: elem?.user?.id,
                                                                                profile_image: elem?.user?.profile_image,
                                                                            }
                                                                        }
                                                                        )}
                                                                    >
                                                                        <BsFillChatTextFill color="#005eb5" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan="7" className="text-center"> {languageSelector?.common?.no_data_found} </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Managesubscription;
