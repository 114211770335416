import React, { useEffect, useState } from "react"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Groupclassescard from "../../components/Groupclassescard/Groupclassescard";
import '../../style.scss';
import { useDispatch, useSelector } from "react-redux";
import { GlobalGroupClassListAction, ResetGlobalGroupClassListAction } from "../../redux/actions/GroupClassActions";
import { GetCategory } from "../../redux/actions/HomePageActions";
import moment from "moment";
import { baseUrlPostGres } from "../../redux/constant";
import { getUserSettings, isValidHttpUrl } from "../../Utils/Function";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Groupclasses = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const groupClassListSelector = useSelector((state) => state.group.group_class_global_list);
    const categoryListSelector = useSelector((state) => state.home?.get_category);
    const wishlistSelector = useSelector(state => state.instructor.add_favorite);
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const { id } = useParams(); // Access the ID from the URL
    const [state, setState] = useState({
        topic: "",
        courseId: location?.state ? location.state.course_id : "",
        date: "",
        page_no: 1
    });

    useEffect(() => {
        dispatch(GlobalGroupClassListAction({ ...state, user_id: getUserSettings()?.user?.id }));
        dispatch(GetCategory());
        return () => dispatch(ResetGlobalGroupClassListAction())
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            setState(prevState => ({
                ...prevState,
                courseId: id
            }));
            dispatch(GlobalGroupClassListAction({ ...state, courseId: id, user_id: getUserSettings()?.user?.id }));
        } else if (!id){
            setState(prevState => ({
                ...prevState,
                courseId: ''
            }));
            dispatch(GlobalGroupClassListAction({ ...state, courseId: id, user_id: getUserSettings()?.user?.id }));
        }
    }, [id, dispatch]);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    }

    const handleResetFilter = (e) => {
        e.preventDefault()
        setState({
            topic: "",
            courseId: "",
            date: "",
            page_no: 1
        });
        dispatch(GlobalGroupClassListAction({ page_no: 1 }));
        if (location?.state) {
            navigate(location.pathname, { replace: true });
        }
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault()
        if (state.courseId || state.date || state.topic) {
            dispatch(GlobalGroupClassListAction(state))
        } else {
            toast.error(languageSelector?.group_class_home?.search_click_validation, { theme: "colored" })
        }
    };

    useEffect(() => {
        if (Object.keys(wishlistSelector).length > 0) {
            if (wishlistSelector.status) {
                dispatch(GlobalGroupClassListAction({ ...state, user_id: getUserSettings()?.user?.id }));
            }
        }
    }, [wishlistSelector])

    return (
        <>
            <div id="footer-bottom" className="instrutors">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.group_class_home?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.group_class_home?.page_title} </span></p>
                </div>
                <div className="easyfind-box">
                    <div className="easy-box-ab">
                        <div className="container">
                            <div className="easy-box-content">
                                <h1> {languageSelector?.group_class_home?.group_easy_find} </h1>
                                <form className="input-content-easy groupclass" onSubmit={handleFilterSubmit}>
                                    <div className="easy-box-1">
                                        <label className="custom-field one">
                                            <input
                                                name="topic"
                                                onChange={handleOnChange}
                                                value={state.topic}
                                                type="text"
                                                placeholder=" "
                                            />
                                            <fieldset><legend><span> {languageSelector?.group_class_home?.group_topic} </span></legend></fieldset>
                                            <span className="placeholder"> {languageSelector?.group_class_home?.group_topic} </span>
                                        </label>
                                    </div>
                                    <div className="easy-box-1 date-picker-sec">
                                        <label className="custom-field one">
                                            <input type="date"
                                                name="date"
                                                onChange={handleOnChange}
                                                value={state.date}
                                                className="form-control date-picker-ab"
                                                placeholder=" "
                                                min={moment().format("YYYY-MM-DD")}
                                            />
                                            <fieldset><legend><span> {languageSelector?.group_class_home?.group_date} </span></legend></fieldset>
                                            <span className="placeholder"> {languageSelector?.group_class_home?.group_date} </span>
                                        </label>
                                    </div>
                                    <div className="easy-box-1 dropdown-select">
                                        <div className="App fix-placeholder">
                                            <select
                                                name="courseId"
                                                onChange={handleOnChange}
                                                value={state.courseId}
                                            >
                                                <option value=""> {languageSelector?.group_class_home?.group_select}  </option>
                                                {categoryListSelector?.data?.map((value, index) => {
                                                    return (
                                                        <option value={value.id} key={index}>
                                                            {value.category_name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            <span className="placeholder-name"> {languageSelector?.group_class_home?.group_course} </span>
                                        </div>
                                    </div>
                                    <div className="easy-box-2">
                                        <button className="btn becomaninstructor" type="submit"> {languageSelector?.group_class_home?.group_search} </button>
                                    </div>
                                </form>
                                {state.courseId || state.date || state.topic ?
                                    <div className="reset-button-filter">
                                        <button type="button" className="btn text-btn reset-btn" onClick={handleResetFilter}> {languageSelector?.inst_page?.reset_filter} </button>
                                    </div>
                                    :
                                    null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="groupclasses-card mt-0">
                    <div className="container">
                        <div className="row g-5">
                            {Object.keys(groupClassListSelector).length > 0 ?
                                groupClassListSelector?.data?.length > 0 ?
                                    [...groupClassListSelector?.data].sort((a, b) => (a.created_at > b.created_at) ? -1 : 1).map((value, index) => {
                                        let courseStatus = 'Future';
                                        return (
                                        <div className="col-md-6 col-lg-4 col-12" key={index}>
                                            <div>
                                                {value.group_course_class?.length > 0 &&
                                                    value.group_course_class.map((val, index) => {
                                                        const courseDateTime  = moment(val.time);
                                                        const currentDateTime = moment();
                                                        const isPast     = courseDateTime.isBefore(currentDateTime);
                                                        const isFuture   = courseDateTime.isAfter(currentDateTime);

                                                        if (isPast) {
                                                            courseStatus = 'Passed';
                                                        }

                                                        // if (isPast) {
                                                        //     courseStatus = 'smaller';
                                                        // } else if (isFuture) {
                                                        //     courseStatus = 'larger';
                                                        // } else {
                                                        //     courseStatus = 'equal';
                                                        // }
                                                    })
                                                }
                                            </div>

                                            <Groupclassescard
                                                ImageUrl={value.picture ? value.picture : "/images/Groupclasses1.png"}
                                                CourseName={value.class_name}
                                                id={value.id}
                                                HrPrice={Number(value.price).toFixed(0)}
                                                TotalSeats={value.total_seat}
                                                BookedSeats={value?.total_seat - value?.reaming_seat}
                                                bookedStatus={value?.AlreadyBooked}
                                                ProfileImage={value?.group_user?.profile_image ?
                                                    isValidHttpUrl(value?.group_user?.profile_image) ? value?.group_user?.profile_image
                                                        : `${baseUrlPostGres()}${value?.group_user?.profile_image}`
                                                    : "/images/blank-profile-picture.jpg"}
                                                TeacherName={`${value.group_user.first_name} ${value.group_user.last_name}`}
                                                isVerified={value.group_user?.verified_Sign}
                                                DateTime={moment(value.created_at).format("llll")}
                                                CourseDescription={value?.descriptions}
                                                isWishlist={value?.is_instruc_fav}
                                                isCourseStatus={courseStatus}
                                            />
                                        </div>
                                        );
                                    })
                                    :
                                    <NoDataFound />
                                :
                                [1, 2, 3, 4, 5, 6].map((_, index) => {
                                    return (
                                        <div className='col-md-6 col-lg-4 col-12' key={index}>
                                            <Skeleton height={410} borderRadius="2.6rem" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Groupclasses




