import { useEffect, useState } from "react"
import { baseUrlPostGres } from "../redux/constant";
import { isValidHttpUrl } from "../Utils/Function";

const ImagePreview = ({ file, imageAlt }) => {
    const [preview, setPreview] = useState(null);
    useEffect(() => {
        if (typeof file === "object") {
            const render = new FileReader();
            render.readAsDataURL(file);
            render.onload = () => {
                setPreview(render.result)
            }
        } else {
            setPreview(isValidHttpUrl(file) ? file : `${baseUrlPostGres()}${file}`)            
        }
    }, [file])
    return (
        <img src={preview} className="img-fluid" referrerPolicy = "no-referrer" alt={imageAlt} />
    )
}

export default ImagePreview