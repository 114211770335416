import axios from 'axios';
import { toast } from 'react-toastify';
import {
    CARD_CREATE_ERROR, CARD_CREATE_SUCCESS, CARD_LIST_ERROR, CARD_LIST_SUCCESS, CARD_REMOVE_ERROR, CARD_REMOVE_SUCCESS, APPLY_COUPON_CODE_SUCCESS, APPLY_COUPON_CODE_ERROR, INSTRUCTOR_PAYMENT_ERROR, INSTRUCTOR_PAYMENT_SUCCESS, IS_PAYMENT_LOADING, ONDEMAND_PAYMENT_ZOOMLINK_SUCCESS, ONDEMAND_PAYMENT_ZOOMLINK_ERROR,
    INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_SUCCESS, INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_ERROR, INSTRUCTOR_PAYMENT_SUBSCRIPTION_SUCCESS, INSTRUCTOR_PAYMENT_SUBSCRIPTION_ERROR,
    INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_SUCCESS, INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_ERROR, ADD_AMOUNT_ERROR, ADD_AMOUNT_SUCCESS, TRANSACTION_HISTORY_LIST_SUCCESS, TRANSACTION_HISTORY_LIST_ERROR, VERIFICATION_BADGE_PURCHASES_SUCCESS, VERIFICATION_BADGE_PURCHASES_ERROR,
    VERIFICATION_BADGE_STATUS_SUCCESS, VERIFICATION_BADGE_STATUS_ERROR, BADGE_BILLING_HISTORY_SUCCESS, BADGE_BILLING_HISTORY_ERROR,
    PAYMENT_DETAILS_HISTORY_SUCCESS, PAYMENT_DETAILS_HISTORY_ERROR
} from './Type';
import 'react-toastify/dist/ReactToastify.css';
import { apiHeader } from '../../redux/actions/ApiHeader';
import { baseUrlPostGres } from '../../redux/constant';
import HandleError from './HandleError';
import { getUserSettings } from '../../Utils/Function';

toast.configure();

export const CardList = (type) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .get(`${baseUrlPostGres()}/api/${type == "instructor" ? "list-card-instructor" : "list-card-stripe"}`, {
            headers: apiHeader()
        })
        .then(response => {
            dispatch({
                type: CARD_LIST_SUCCESS,
                payload: response.data.data
            })
        })
        .catch(errors => {
            toast.error(errors?.message, { theme: "colored" })
            dispatch({
                type: CARD_LIST_ERROR,
                payload: errors
            })
        })
}

export const ResetCardList = () => async (dispatch) => {
    dispatch({
        type: CARD_LIST_SUCCESS,
        payload: {}
    })
}

export const CardRemove = (data) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/delete-card-stripe`, data, {
            headers: apiHeader()
        })
        .then(response => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: CARD_REMOVE_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors.response?.data?.message, { theme: "colored" })
            dispatch({
                type: CARD_REMOVE_ERROR,
                payload: errors.response.data
            })
        })
}

export const ResetCardRemove = () => async (dispatch) => {
    dispatch({
        type: CARD_REMOVE_SUCCESS,
        payload: {}
    })
}

export const CardCreate = (body, type) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/${type == "instructor" ? "add-card-instructor" : "add-card-stripe"}`, body, {
            headers: apiHeader()
        })
        .then(response => {
            toast.success(response.data?.message, { theme: "colored" });
            dispatch({
                type: CARD_CREATE_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors.response?.data?.message, { theme: "colored" })
            dispatch({
                type: CARD_CREATE_ERROR,
                payload: errors.response.data
            })
        })
}

export const ResetCardCreate = () => async (dispatch) => {
    dispatch({
        type: CARD_CREATE_SUCCESS,
        payload: {}
    })
}

export const paymentOnDemand = (body) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/request-on-demand`, body, {
            headers: apiHeader()
        })
        .then(response => {
            toast.success(response.data?.message, { theme: "colored" });
            dispatch({
                type: INSTRUCTOR_PAYMENT_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: INSTRUCTOR_PAYMENT_ERROR,
                payload: errors.response.data
            })
        })
}

export const ResetpaymentOnDemand = () => async (dispatch) => {
    dispatch({
        type: INSTRUCTOR_PAYMENT_SUCCESS,
        payload: {}
    })
}

export const paymentZoomLink = (id) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .get(`${baseUrlPostGres()}/api/zoom-link-crate/${id}`, {
            headers: apiHeader()
        })
        .then(response => {
            dispatch({
                type: ONDEMAND_PAYMENT_ZOOMLINK_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ONDEMAND_PAYMENT_ZOOMLINK_ERROR,
                payload: errors.response.data
            })
        })
}

export const CouponCodeApplyAction = (data) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/apply-coupons`, data, {
            headers: apiHeader()
        })
        .then(response => {
            toast.success(response.data?.message, { theme: "colored" });
            dispatch({
                type: APPLY_COUPON_CODE_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: APPLY_COUPON_CODE_ERROR,
                payload: errors.response.data
            })
        })
}

export const RestCouponCodeApplyAction = () => async (dispatch) => {
    dispatch({
        type: APPLY_COUPON_CODE_SUCCESS,
        payload: {}
    })
}

export const paymentOnProblemSolvingAction = (body) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/request-problem-solving`, body, {
            headers: apiHeader()
        })
        .then(response => {
            toast.success(response.data?.message, { theme: "colored" });
            dispatch({
                type: INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_ERROR,
                payload: errors.response.data
            })
        })
}

export const ResetpaymentOnProblemSolvingAction = () => async (dispatch) => {
    dispatch({
        type: INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_SUCCESS,
        payload: {}
    })
}
export const paymentOnSubscriptionAction = (body) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/request-subscription`, body, {
            headers: apiHeader()
        })
        .then(response => {
            toast.success(response.data?.message, { theme: "colored" });
            dispatch({
                type: INSTRUCTOR_PAYMENT_SUBSCRIPTION_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: INSTRUCTOR_PAYMENT_SUBSCRIPTION_ERROR,
                payload: errors.response.data
            })
        })
}

export const ResetpaymentOnSubscriptionAction = (body) => async (dispatch) => {
    dispatch({
        type: INSTRUCTOR_PAYMENT_SUBSCRIPTION_SUCCESS,
        payload: {}
    })
}

export const paymentOnScheduleBookingAction = (body) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/request-on-schedule`, body, {
            headers: apiHeader()
        })
        .then(response => {
            toast.success(response.data?.message, { theme: "colored" });
            dispatch({
                type: INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_ERROR,
                payload: errors.response.data
            })
        })
}

export const ResetPaymentOnScheduleBookingAction = (body) => async (dispatch) => {
    dispatch({
        type: INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_SUCCESS,
        payload: {}
    })
}

export const addPaymentAction = (data) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/add-wallet`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: ADD_AMOUNT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ADD_AMOUNT_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetaddPaymentAction = () => async (dispatch) => {
    dispatch({
        type: ADD_AMOUNT_SUCCESS,
        payload: {},
    });
}

export const TransactionHistoryAction = () => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/transaction-history-student`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: TRANSACTION_HISTORY_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: TRANSACTION_HISTORY_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetTransactionHistoryAction = () => async (dispatch) => {
    dispatch({
        type: TRANSACTION_HISTORY_LIST_SUCCESS,
        payload: {},
    });
}

export const VerificationBadgePurchasesAction = (data) => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/verification-purchase`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: VERIFICATION_BADGE_PURCHASES_SUCCESS,
                payload: response.data,
            });
            const localStorageData = getUserSettings()
            let user_details = localStorageData.user
            user_details["verified_Sign"] = 1
            localStorage.setItem("security_auth", JSON.stringify(localStorageData))
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: VERIFICATION_BADGE_PURCHASES_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetVerificationBadgePurchasesAction = () => async (dispatch) => {
    dispatch({
        type: VERIFICATION_BADGE_PURCHASES_SUCCESS,
        payload: {},
    });
}


export const VerificationBadgeStatusAction = () => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/verification-bage-check`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: VERIFICATION_BADGE_STATUS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: VERIFICATION_BADGE_STATUS_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetVerificationBadgeStatusAction = () => async (dispatch) => {
    dispatch({
        type: VERIFICATION_BADGE_STATUS_SUCCESS,
        payload: {},
    });
}

export const BadgeBillingHistoryAction = () => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/billing-list`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: BADGE_BILLING_HISTORY_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BADGE_BILLING_HISTORY_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBadgeBillingHistoryAction = () => async (dispatch) => {
    dispatch({
        type: BADGE_BILLING_HISTORY_SUCCESS,
        payload: {},
    });
}


export const PaymentDetailHistroyAction = () => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/payment-details`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: PAYMENT_DETAILS_HISTORY_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: PAYMENT_DETAILS_HISTORY_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetPaymentDetailHistroyAction = () => async (dispatch) => {
    dispatch({
        type: PAYMENT_DETAILS_HISTORY_SUCCESS,
        payload: {},
    });
}

//new
export const getPaymentDetails = () => async (dispatch) => {
    dispatch({ type: IS_PAYMENT_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/payment-details`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: PAYMENT_DETAILS_HISTORY_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: PAYMENT_DETAILS_HISTORY_ERROR,
                payload: errors.response.data,
            });
        });
};