import {
    ADD_GROUP_CLASSES_ERROR, ADD_GROUP_CLASSES_SUCCESS, GROUP_CLASSES_LIST_ERROR, GROUP_CLASSES_LIST_SUCCESS, IS_GROUP_LOADING, GLOBAL_GROUP_CLASSES_LIST_SUCCESS, GLOBAL_GROUP_CLASSES_LIST_ERROR, GROUP_CLASSES_DETAIL_SUCCESS, GROUP_CLASSES_DETAIL_ERROR, GROUP_CLASSES_BOOKING_SUCCESS, GROUP_CLASSES_BOOKING_ERROR, MORE_GROUP_CLASS_BY_INSTRUCTOR_SUCCESS, MORE_GROUP_CLASS_BY_INSTRUCTOR_ERROR,
    DELETE_GROUP_CLASSES_SUCCESS, DELETE_GROUP_CLASSES_ERROR, EDIT_GROUP_CLASSES_SUCCESS, EDIT_GROUP_CLASSES_ERROR, VIEW_GROUP_CLASSES_SUCCESS, VIEW_GROUP_CLASSES_ERROR
} from "../actions/Type";

const initialState = {
    group_class_add: {},
    group_class_detail: {},
    group_class_list_success: [],
    group_class_list_error: {},
    group_class_global_list: {},
    loading: false,
    group_class_booking: {},
    group_class_delete: {},
    group_class_edit: {},
    group_class_view: {},
    group_class_instructor: {}
};

export const GroupClassRedeucer = (
    state = initialState,
    { type, payload } = {}
) => {
    switch (type) {
        case IS_GROUP_LOADING:
            return {
                ...state,
                loading: true,
            };
        case VIEW_GROUP_CLASSES_SUCCESS:
            return {
                ...state,
                group_class_view: payload,
                loading: false,
            };
        case VIEW_GROUP_CLASSES_ERROR:
            return {
                ...state,
                group_class_view: payload,
                loading: false,
            }
        case EDIT_GROUP_CLASSES_SUCCESS:
            return {
                ...state,
                group_class_edit: payload,
                loading: false,
            };
        case EDIT_GROUP_CLASSES_ERROR:
            return {
                ...state,
                group_class_edit: payload,
                loading: false,
            }
        case DELETE_GROUP_CLASSES_SUCCESS:
            return {
                ...state,
                group_class_delete: payload,
                loading: false,
            };
        case DELETE_GROUP_CLASSES_ERROR:
            return {
                ...state,
                group_class_delete: payload,
                loading: false,
            }
        case GROUP_CLASSES_BOOKING_SUCCESS:
            return {
                ...state,
                group_class_booking: payload,
                loading: false,
            };
        case GROUP_CLASSES_BOOKING_ERROR:
            return {
                ...state,
                group_class_booking: payload,
                loading: false,
            }
        case GROUP_CLASSES_DETAIL_SUCCESS:
            return {
                ...state,
                group_class_detail: payload,
                loading: false,
            };
        case GROUP_CLASSES_DETAIL_ERROR:
            return {
                ...state,
                group_class_detail: payload,
                loading: false,
            }
        case ADD_GROUP_CLASSES_SUCCESS:
            return {
                ...state,
                group_class_add: payload,
                loading: false,
            };
        case ADD_GROUP_CLASSES_ERROR:
            return {
                ...state,
                group_class_add: payload,
                loading: false,
            }
        case GROUP_CLASSES_LIST_SUCCESS:
            return {
                ...state,
                group_class_list_success: payload,
                loading: false,
            };
        case GROUP_CLASSES_LIST_ERROR:
            return {
                ...state,
                group_class_list_error: payload,
                loading: false,
            }

        case GLOBAL_GROUP_CLASSES_LIST_SUCCESS:
            return {
                ...state,
                group_class_global_list: payload,
                loading: false,
            };
        case GLOBAL_GROUP_CLASSES_LIST_ERROR:
            return {
                ...state,
                group_class_global_list: payload,
                loading: false,
            }

        case MORE_GROUP_CLASS_BY_INSTRUCTOR_SUCCESS:
            return {
                ...state,
                group_class_instructor: payload,
                loading: false,
            };
        case MORE_GROUP_CLASS_BY_INSTRUCTOR_ERROR:
            return {
                ...state,
                group_class_instructor: payload,
                loading: false,
            }

        default:
            return state;
    }
};