import React, { useEffect, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import { Link, useNavigate } from "react-router-dom";
import { FieldText } from '../../components/InputText/InputText';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from "./../../components/Buttons/Buttons";
import '../Instructorprofile/Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CouponAllListAction, RedeemGiftCardAction, ResetCouponAllListAction, ResetRedeemGiftCardAction } from '../../redux/actions/GiftCardAction';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment';
import { copyToClipBoard } from '../../Utils/Function';

const Studentgiftcardcoupons = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const isloading = useSelector(state => state.giftCard.loading)
    const RedeemGiftCardSelector = useSelector(state => state.giftCard.redeem_gift_card)
    const CouponDataSelector = useSelector(state => state.giftCard.coupon_list)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const [isRedeemGiftCardSubmit, setIsRedeemGiftCardSubmit] = useState(false)
    const [couponListing, setCouponListing] = useState()

    useEffect(() => {
        dispatch(CouponAllListAction())
        return () => dispatch(ResetCouponAllListAction())
    }, [dispatch])

    useEffect(() => {
        if (Object.keys(CouponDataSelector).length > 0) {
            setCouponListing(CouponDataSelector?.data)
        }
    }, [CouponDataSelector])

    const handleRedeemGiftCardSubmit = (value, { resetForm }) => {
        dispatch(RedeemGiftCardAction(value))
        setIsRedeemGiftCardSubmit(true)
        resetForm();
    }

    useEffect(() => {
        if (Object.keys(RedeemGiftCardSelector).length > 0) {
            if (RedeemGiftCardSelector.status) {
                setIsRedeemGiftCardSubmit(false)
                dispatch(ResetRedeemGiftCardAction())
            }
        }
    }, [RedeemGiftCardSelector])

    const getExpiresDate = (date) => {
        // let finalDate = moment(date, 'YYYY-MM-DD').diff(moment(moment(), 'YYYY-MM-DD'), 'd') + 1
        const currentDateTime = moment();
        const targetDateTime = moment(date, 'YYYY-MM-DD HH:mm');
        const hasPassed = currentDateTime.isAfter(targetDateTime);
        const daysDiff = hasPassed ? targetDateTime.diff(currentDateTime, 'days') : targetDateTime.diff(currentDateTime, 'days') + 1;
        return daysDiff;
    }

    const handleSelectOnChange = (e) => {
        const { value } = e.target;
        if (value !== "all" && value) {
            const filterValue = CouponDataSelector?.data?.filter(data => data.type_coupons == value)
            setCouponListing(filterValue)
        } else {
            setCouponListing(CouponDataSelector?.data)
        }
    }

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    return (
        <>
            <div id="footer-bottom" className='instructor-profile walletpayment'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.gift_card_coupon?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.gift_card_coupon?.page_title} </span></p>
                </div>

                <div className='myprofile-section'>
                    <div className='container'>
                        <div className='row g-5'>
                            <div className='col-md-12 col-lg-5 col-xl-4'>
                                <Studentsidebar />
                            </div>

                            <div className='col-md-12 col-lg-7 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.gift_card_coupon?.page_title} </h2>
                                </div>
                                <div className='my-profile-details'>
                                    <div className='wallet-card-main-section row g-5'>
                                        <div className='col-lg-12 col-xl-6'>
                                            <Formik
                                                initialValues={{
                                                    name: "",
                                                    email: "",
                                                    gift_amount: "",
                                                }}
                                                validationSchema={() =>
                                                    Yup.object().shape({
                                                        name: Yup.string().required(languageSelector?.validation?.name),
                                                        email: Yup.string().email(languageSelector?.validation?.email_invaild).required(languageSelector?.validation?.email_required),
                                                        gift_amount: Yup.number().positive(languageSelector?.validation?.price_greater_0).integer(languageSelector?.validation?.price_greater_0).required(languageSelector?.validation?.gift_amount)
                                                    })
                                                }
                                                onSubmit={(value) => navigate("/payment/proceed-to-payment", { state: { ...value, page_name: "add_gift_card" } })}
                                            >
                                                {formik => {
                                                    return (
                                                        <Form>
                                                            <FormikFromFunc />
                                                            <div className='wallet-card'>
                                                                <h2> {languageSelector?.gift_card_coupon?.send_gift_card} </h2>
                                                                <div className='gift-input-box'>
                                                                    <div className='gift-input-group'>
                                                                        <FieldText
                                                                            name="name"
                                                                            type="text"
                                                                            placeholder={languageSelector?.placeholder?.student_name}
                                                                        />
                                                                    </div>
                                                                    <div className='gift-input-group'>
                                                                        <FieldText
                                                                            name="email"
                                                                            type="email"
                                                                            placeholder={languageSelector?.placeholder?.student_email}
                                                                        />
                                                                    </div>
                                                                    <div className='gift-input-group'>
                                                                        <FieldText
                                                                            name="gift_amount"
                                                                            type="number"
                                                                            placeholder={languageSelector?.placeholder?.gift_amount}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='send-button-box'>
                                                                    <Button
                                                                        BtnText={languageSelector?.gift_card_coupon?.send}
                                                                        BtnColor="primary"
                                                                        BtnType="submit"
                                                                        disabled={!formik.isValid || isloading}
                                                                    // hasSpinner={isloading}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>
                                        </div>

                                        <div className='col-lg-12 col-xl-6'>
                                            <Formik
                                                initialValues={{ gift_code: "" }}
                                                validationSchema={() => Yup.object().shape({
                                                    gift_code: Yup.string().required(languageSelector?.validation?.gift_code),
                                                })}
                                                onSubmit={handleRedeemGiftCardSubmit}
                                            >
                                                {formik => {
                                                    return (
                                                        <Form className='h-100'>
                                                            <FormikFromFunc />
                                                            <div className='wallet-card'>
                                                                <h2> {languageSelector?.gift_card_coupon?.redeem_gift_card} </h2>
                                                                <div className='gift-input-box'>
                                                                    <div className='gift-input-group'>
                                                                        <FieldText
                                                                            name="gift_code"
                                                                            type="text"
                                                                            placeholder={languageSelector?.placeholder?.enter_gift_code}
                                                                            defaultValue={formik.values.gift_code}
                                                                        />
                                                                    </div>

                                                                    <div className='gift-note'>
                                                                        <span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
                                                                                <g id="Group_5579" data-name="Group 5579" transform="translate(-1796.698 -756.698)">
                                                                                    <circle id="Ellipse_121" data-name="Ellipse 121" cx="7.5" cy="7.5" r="7.5" transform="translate(1797.448 757.448)" fill="none" stroke="currentcolor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                                                    <line id="Line_206" data-name="Line 206" transform="translate(1804.448 761.448)" fill="none" stroke="currentcolor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                                                    <path id="Path_1089" data-name="Path 1089" d="M11,12h.858v3.433h.858" transform="translate(1792.866 752.724)" fill="none" stroke="currentcolor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                                                </g>

                                                                            </svg>
                                                                        </span>

                                                                        <p> {languageSelector?.gift_card_coupon?.gift_card_note} </p>
                                                                    </div>
                                                                </div>
                                                                <div className='send-button-box send-button-box2'>
                                                                    <Button
                                                                        BtnText={languageSelector?.gift_card_coupon?.redeem}
                                                                        BtnColor="primary"
                                                                        BtnType="submit"
                                                                        disabled={!formik.isValid || isloading}
                                                                        hasSpinner={isloading && isRedeemGiftCardSubmit}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>
                                        </div>

                                        <div className='col-12'>
                                            <div className='wallet-card'>
                                                <div className='d-flex align-items-center justify-content-between gift-card-dropdown'>
                                                    <h2> {languageSelector?.gift_card_coupon?.coupons} </h2>
                                                    <div className='App'>
                                                        <select className='' onChange={handleSelectOnChange}>
                                                            <option value="all"> {languageSelector?.gift_card_coupon?.all_coupon} </option>
                                                            <option value="1"> {languageSelector?.gift_card_coupon?.group_class_coupon} </option>
                                                            <option value="2"> {languageSelector?.gift_card_coupon?.subject_class_coupon} </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='coupons-four-card-section'>
                                                    <div className='row g-5'>
                                                        {Object.keys(CouponDataSelector).length > 0 ?
                                                            CouponDataSelector?.data?.length > 0 ?
                                                                couponListing?.map((value, index) => (
                                                                    <div className='col-lg-12 col-md-6 col-xl-6' key={index}>
                                                                        <div className='coupons-card-box-first'>
                                                                            <div className="row g-5">
                                                                                <div className="col-8">
                                                                                    <h3>
                                                                                        <span>
                                                                                            {value.coupon_discount}% {languageSelector?.gift_card_coupon?.off}
                                                                                        </span>
                                                                                        {languageSelector?.gift_card_coupon?.on_all} {value.type_coupons == 1 ? value?.category?.category_name : value.subject.tutor_sub_name} {languageSelector?.gift_card_coupon?.courses}
                                                                                    </h3>
                                                                                </div>
                                                                                <div className="col-4 text-end">
                                                                                    <button typeof='button' className='btn btn-apply2' onClick={() => copyToClipBoard(value.coupon_code)}>
                                                                                        {languageSelector?.gift_card_coupon?.copy}
                                                                                    </button>
                                                                                </div>
                                                                                <div className="col-7 text-start">
                                                                                    <div className="btn text-uppercase"> {value.coupon_code} </div>
                                                                                </div>
                                                                                <div className="col-5 text-end align-self-center">
                                                                                    <h4>
                                                                                        {languageSelector?.gift_card_coupon?.expires} {getExpiresDate(value.expire_date)} {languageSelector?.gift_card_coupon?.days}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                :
                                                                <NoDataFound />
                                                            :
                                                            [1, 2, 3, 4].map((_, index) => {
                                                                return (
                                                                    <div className='col-lg-12 col-md-6 col-xl-6' key={index}>
                                                                        <Skeleton height={178} borderRadius="1rem" />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Studentgiftcardcoupons
