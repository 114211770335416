import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import { baseUrlPostGres } from "../../../redux/constant";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import RatingAndReviewModal from "../../../components/RatingAndReviewModal/RatingAndReviewModal";
import { useDispatch, useSelector } from "react-redux";
import { ResetAddReviewRatingAction } from "../../../redux/actions/ReviewAndRatingAction";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../../components/Hooks/Pagination";
import { ClassesDetailsListAction, ResetClassesDetailsListAction } from "../../../redux/actions/ClassesDetailActions";
import { AiFillEye } from "react-icons/ai";
import { BsFillChatTextFill } from "react-icons/bs";

const Completed = ({ setTypeBooking, currentPage, setCurrentPage, selector, classesDetailData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [item, setItem] = useState({ kindOfStand: "all", another: "another" });
    const [ratingModalShow, setRatingModalShow] = useState(false);
    const [onSubmitRating, setOnSubmitRating] = useState(false);
    const [instructorId, setInstructorId] = useState()
    const [bookingID, setBookingID] = useState();
    const ratingSubmitSelector = useSelector(state => state.rating.add_rating_review)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const { kindOfStand } = item;

    const handleCardRedirection = (value) => {
        if (value.booking_type == 3) {
            navigate(`/student/classes-details/problemsolving/${value.id}`)
        }
    }
    const handleChange = e => {
        e.persist();
        dispatch(ResetClassesDetailsListAction())
        setCurrentPage(1)
        setItem(prevState => ({
            ...prevState,
            kindOfStand: e.target.value
        }));
        setTypeBooking(e.target.value)
        if (e.target.value !== "all") {
            dispatch(ClassesDetailsListAction("student", { type_class: "2", type_booking: e.target.value, page: currentPage }))
        } else {
            dispatch(ClassesDetailsListAction("student", { type_class: "2", type_booking: "", page: currentPage }))
        }
    };

    const handleRatingExperience = (value) => {
        setInstructorId(value?.user_instructor?.id)
        setBookingID(value?.id)
        setRatingModalShow(true)
    }

    useEffect(() => {
        if (Object.keys(ratingSubmitSelector).length > 0) {
            if (ratingSubmitSelector.status) {
                setRatingModalShow(false)
                setOnSubmitRating(false)
                dispatch(ResetAddReviewRatingAction())
            }
        }
    }, [ratingSubmitSelector])
    return (
        <>
            <div className="booking-filter">
                <div className="btn">
                    <Form.Group controlId="kindOfStand18">
                        <Form.Check value="all" type="radio" label={languageSelector?.classes_details?.all} onChange={handleChange} checked={kindOfStand === "all"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand6"> <Form.Check value="1" type="radio" label={languageSelector?.classes_details?.on_demand} onChange={handleChange} checked={kindOfStand === "1"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand7"> <Form.Check value="2" type="radio" label={languageSelector?.classes_details?.scheduled} onChange={handleChange} checked={kindOfStand === "2"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand8"> <Form.Check value="3" type="radio" label={languageSelector?.classes_details?.problem_sloving} onChange={handleChange} checked={kindOfStand === "3"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand9"> <Form.Check value="4" type="radio" label={languageSelector?.classes_details?.group_class} onChange={handleChange} checked={kindOfStand === "4"} />
                    </Form.Group>
                </div>
            </div>

            <div className="blog-section">
                <div className="container">
                    <div className="row g-5">
                        {Object.keys(selector).length > 0 ?
                            classesDetailData?.data?.length > 0 ?
                                <>
                                    {classesDetailData?.data?.map((value, index) => {
                                        return (
                                            <div className="col-12 col-xxl-6" key={index}>
                                                <div className="bookingrequest-card-main">
                                                    <div className='booking-card'>
                                                        <div className="image-box">
                                                            {value.booking_type !== 4 ?
                                                                <img
                                                                    src={
                                                                        value?.user_instructor?.profile_image ?
                                                                            `${baseUrlPostGres()}${value?.user_instructor?.profile_image}`
                                                                            :
                                                                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                                    }
                                                                    alt={value?.user_instructor?.first_name}
                                                                />
                                                                :
                                                                <img
                                                                    src={
                                                                        value?.group_class_name?.picture ?
                                                                            `${baseUrlPostGres()}${value?.group_class_name?.picture}`
                                                                            :
                                                                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                                    }
                                                                    alt={value?.group_class_name?.class_name}
                                                                />
                                                            }
                                                        </div>
                                                        <div className='booking-details'>
                                                            <div className='heading-price'>
                                                                <div className='heading-description'>
                                                                    {value.booking_type !== 4 ?
                                                                        <h2>{value?.user_instructor?.first_name} {value?.user_instructor?.last_name}</h2>
                                                                        :
                                                                        <h2>{value?.group_class_name?.class_name}</h2>
                                                                    }
                                                                    <p> {moment(value?.created_at).format("DD MMM'YY")} {moment(value?.created_at).format("LT")}</p>
                                                                </div>
                                                                <div className='pending-box'>
                                                                    <h3> ${value?.transaction_user?.txn_amount ? value?.transaction_user?.txn_amount : 0}</h3>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                {value?.user_tutor_subject ?
                                                                    <>
                                                                        <h4> {languageSelector?.classes_details?.subject} </h4>
                                                                        <span>{value?.user_tutor_subject.tutor_sub_name} ({value?.class_level?.class_name})</span>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {value.booking_type !== 4 ?
                                                                    <>
                                                                        <h4> {languageSelector?.classes_details?.class_type} </h4>
                                                                        <span>
                                                                            {value.booking_type == 1 ? languageSelector?.classes_details?.ondemand_class :
                                                                                value.booking_type == 2 ? `${languageSelector?.classes_details?.scheduled} - ` :
                                                                                    value.booking_type == 3 ? languageSelector?.classes_details?.problem_sloving :
                                                                                        value.booking_type == 4 ? languageSelector?.classes_details?.group_class
                                                                                            : null
                                                                            }

                                                                            {value.booking_type == 2 ?
                                                                                <>
                                                                                    {moment(value?.class_date, "YYYY/MM/DD").format("DD MMM'YY")} | {value?.slottime_schudule ? value?.slottime_schudule?.slot_time : "-"}
                                                                                </>
                                                                                : null}
                                                                        </span>
                                                                    </>
                                                                    : null
                                                                }
                                                            </div>

                                                            {value.booking_type == 4 ?
                                                                <div className='subjectclasstypelevel'>
                                                                    <div className='subjectclasstypelevel2 subjectclasstypelevel22'>
                                                                        <h4> {languageSelector?.classes_details?.class_type} </h4>
                                                                        <span> {value.booking_type == 4 ? languageSelector?.classes_details?.group_class : "-"} </span>
                                                                    </div>
                                                                    <div className='subjectclasstypelevel2'>
                                                                        <h4> {languageSelector?.classes_details?.level} </h4>
                                                                        <span> {value?.group_class_name?.class_level?.class_name} </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>

                                                    {value.booking_type == 4 ?
                                                        <div>
                                                            <div className='group-classcard-main'>
                                                                <div className='date-time-box date-time-box2'>
                                                                    <h4> {languageSelector?.classes_details?.date_time} </h4>
                                                                    <span>
                                                                        {moment(value?.group_class_name?.time).format("DD MMM'YY")} {moment(value?.group_class_name?.time).format("LT")}
                                                                    </span>
                                                                </div>
                                                                <div className='date-time-box'>
                                                                    <h4> {languageSelector?.classes_details?.duration} </h4>
                                                                    <span>{value?.group_class_name?.duration} {languageSelector?.classes_details?.hour}</span>
                                                                </div>

                                                                <div className='date-time-box date-time-box2'>
                                                                    <h4> {languageSelector?.classes_details?.booked_seats} </h4>
                                                                    <span>{value?.group_class_name?.total_seat - value?.group_class_name?.reaming_seat} {languageSelector?.classes_details?.seats}</span>
                                                                </div>

                                                                <div className='date-time-box'>
                                                                    <h4> {languageSelector?.classes_details?.total_seats} </h4>
                                                                    <span>{value?.group_class_name?.total_seat} {languageSelector?.classes_details?.seats}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    <div className="eject-accept">
                                                        {value.booking_type !== 4 ?
                                                            <>
                                                                {value.ratingavg ?
                                                                    <>
                                                                        <div className="btn reject rating">
                                                                            {value.ratingavg && [...new Array(Math.ceil(Number(value.ratingavg)))].map((_, index) => {
                                                                                return (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.217" viewBox="0 0 16 15.217" key={index}>
                                                                                        <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M18.571,8.5l-5.528-.8L10.571,2.683,8.1,7.692l-5.528.8,4,3.9L5.626,17.9l4.944-2.6,4.944,2.6-.944-5.505,4-3.9Z" transform="translate(-2.571 -2.683)" fill="#fed756" />
                                                                                    </svg>
                                                                                )
                                                                            })}

                                                                            {value.ratingavg && [...new Array((5 - Number(value.ratingavg).toFixed(0)))].map((_, index) => {
                                                                                return (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.217" viewBox="0 0 16 15.217" key={index}>
                                                                                        <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M18.571,8.5l-5.528-.8L10.571,2.683,8.1,7.692l-5.528.8,4,3.9L5.626,17.9l4.944-2.6,4.944,2.6-.944-5.505,4-3.9Z" transform="translate(-2.571 -2.683)" fill="#dcdcdc" />
                                                                                    </svg>
                                                                                )
                                                                            })}
                                                                            <span>({(Number(value.ratingavg)).toFixed(0)})</span>
                                                                        </div>
                                                                        <button className="btn accept workstation" type="button" disabled> {languageSelector?.classes_details?.rating_submitted} </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="btn reject rating">
                                                                            <span>  {languageSelector?.classes_details?.rating_pending} </span>
                                                                        </div>
                                                                        <button className="btn accept workstation" type="button" onClick={() => handleRatingExperience(value)}> {languageSelector?.classes_details?.rate_exp} </button>
                                                                    </>
                                                                }

                                                                <button type="button"
                                                                    onClick={() => handleCardRedirection(value)}
                                                                    className="btn accept comment eye-button-box"
                                                                > <AiFillEye /></button>

                                                                <button type="button"
                                                                    onClick={() => navigate("/student/my-chats", {
                                                                        state: {
                                                                            full_name: `${value?.user_instructor?.first_name} ${value?.user_instructor?.last_name}`,
                                                                            id: value?.user_instructor?.id,
                                                                            profile_image: value?.user_instructor?.profile_image,
                                                                        }
                                                                    }
                                                                    )}
                                                                    className="btn accept comment eye-button-box"
                                                                >
                                                                    <BsFillChatTextFill />
                                                                </button>
                                                            </>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="col-md-12">
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={classesDetailData?.total}
                                            pageSize={classesDetailData?.per_page}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </>
                                :
                                <NoDataFound />
                            :
                            [1, 2, 3, 4, 5, 6].map((_, index) => {
                                return (
                                    <div className='col-12 col-xxl-6' key={index}>
                                        <Skeleton height={260} borderRadius="2.5rem" />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>

            <RatingAndReviewModal
                show={ratingModalShow}
                setRatingModalShow={setRatingModalShow}
                setOnSubmitRating={setOnSubmitRating}
                onSubmitRating={onSubmitRating}
                id={instructorId}
                user_type="student"
                bookingID={bookingID}
            />
        </>
    )
}

export default Completed