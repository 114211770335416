import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "./../../components/Buttons/Buttons"
import './DeleteRecordModal.scss';
import { useSelector } from 'react-redux';

const DeleteRecordModal = (props) => {
    const { onHide, onSubmit, loading, id, ...rest } = props;
    const languageSelector = useSelector(state => state.home.dynamic_language)

    return (
        <Modal
            {...rest}
            className='delete-modal'
            centered
            backdrop="static"
            keyboard={false}
            size="md"
        >
            <Modal.Body>
                <div className="alert-body">
                    <div className="alert-icon danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="114.166" viewBox="0 0 88.796 114.166">
                            <path id="Icon_material-delete-forever" data-name="Icon material-delete-forever" d="M13.843,105.981a12.722,12.722,0,0,0,12.685,12.685H77.268a12.722,12.722,0,0,0,12.685-12.685V29.87H13.843Zm15.6-45.159,8.943-8.943L51.9,65.325,65.344,51.879l8.943,8.943L60.841,74.268,74.287,87.714l-8.943,8.943L51.9,83.211,38.452,96.657l-8.943-8.943L42.955,74.268ZM74.1,10.843,67.754,4.5H36.042L29.7,10.843H7.5V23.528H96.3V10.843Z" transform="translate(-7.5 -4.5)" fill="#e93a3a" />
                        </svg>
                    </div>
                    <h2> {languageSelector?.common?.are_you_sure} </h2>
                    <p> {languageSelector?.common?.delete_records_des} </p>
                </div>
                <div className="alert-footer-box">
                    <Button
                        BtnText={languageSelector?.common?.cancel}
                        BtnColor="cancel"
                        BtnType="button"
                        onClick={onHide}
                    />
                    <Button
                        BtnText={languageSelector?.common?.delete}
                        BtnColor="danger"
                        BtnType="button"
                        disabled={loading}
                        hasSpinner={loading}
                        onClick={onSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteRecordModal;