import {
    CLASSES_DETAILS_LIST_ERROR, CLASSES_DETAILS_LIST_SUCCESS, IS_CLASSESS_DETAIL_LOADING,
    SOLUTION_OF_PROBLEM_SOLVING_SUCCESS, SOLUTION_OF_PROBLEM_SOLVING_ERROR,
    PROBLEM_SOLVING_DETAIL_VIEW_SUCCESS, PROBLEM_SOLVING_DETAIL_VIEW_ERROR,
    PROBLEM_SOLVING_DETAIL_SUCCESS, PROBLEM_SOLVING_DETAIL_ERROR
} from "../actions/Type";

const initialState = {
    class_detail_list: {},
    solution_of_problem_solving: {},
    problem_solving_detail_view: {},
    problem_solving_detail: {},
    loading: false,
};

export const ClassesDetailReducers = (
    state = initialState,
    { type, payload } = {}
) => {
    switch (type) {
        case IS_CLASSESS_DETAIL_LOADING:
            return {
                ...state,
                loading: true,
            };
        case PROBLEM_SOLVING_DETAIL_SUCCESS:
            return {
                ...state,
                problem_solving_detail: payload,
                loading: false,
            };
        case PROBLEM_SOLVING_DETAIL_ERROR:
            return {
                ...state,
                problem_solving_detail: payload,
                loading: false,
            }
        case PROBLEM_SOLVING_DETAIL_VIEW_SUCCESS:
            return {
                ...state,
                problem_solving_detail_view: payload,
                loading: false,
            };
        case PROBLEM_SOLVING_DETAIL_VIEW_ERROR:
            return {
                ...state,
                problem_solving_detail_view: payload,
                loading: false,
            }
        case SOLUTION_OF_PROBLEM_SOLVING_SUCCESS:
            return {
                ...state,
                solution_of_problem_solving: payload,
                loading: false,
            };
        case SOLUTION_OF_PROBLEM_SOLVING_ERROR:
            return {
                ...state,
                solution_of_problem_solving: payload,
                loading: false,
            }
        case CLASSES_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                class_detail_list: payload,
                loading: false,
            };
        case CLASSES_DETAILS_LIST_ERROR:
            return {
                ...state,
                class_detail_list: payload,
                loading: false,
            }
        default:
            return state;
    }
};