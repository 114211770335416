import React, { useEffect, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import { useNavigate, useParams } from "react-router-dom";
import './../Instructorprofile/Instructorprofile.scss';
import { getUserSettings, isValidHttpUrl } from '../../Utils/Function';
import { useDispatch, useSelector } from 'react-redux';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { ResetSupportDetailAction, ResetSupportSendMessageAction, SupportDetailAction, SupportSendMessageAction } from '../../redux/actions/SupportAction';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment';
import TextErrorMsg from '../../components/TextErrorMsg/TextErrorMsg';
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Button from '../../components/Buttons/Buttons';
import { baseUrlPostGres } from '../../redux/constant';

const SupportDetail = ({ role }) => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const navigate = useNavigate();
    const user_detail = getUserSettings()

    const [replySubmit, setReplySubmit] = useState(false)
    const supportDetailSelector = useSelector(state => state.support.support_detail)
    const replySelector = useSelector(state => state.support.support_reply)
    const isloading = useSelector(state => state.support.loading)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(SupportDetailAction(id))
        return () => dispatch(ResetSupportDetailAction())
    }, [dispatch])

    useEffect(() => {
        if (Object.keys(replySelector).length > 0) {
            if (replySelector?.status) {
                setReplySubmit(false)
                dispatch(ResetSupportSendMessageAction())
                dispatch(SupportDetailAction(id))
            }
        }
    }, [replySelector])

    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.contact_ticket?.support} </h1>
                    <p>Home / <span> {languageSelector?.contact_ticket?.support} </span></p>
                </div>

                <div className='myprofile-section classesdetails-page'>
                    <div className='container'>
                        <div className='row gx-5'>
                            <div className='col-md-12 col-lg-4 col-xl-4'>
                                {role == 2 ? <Studentsidebar /> : <Instructorsidebar />}
                            </div>

                            <div className='col-md-12 col-lg-8 col-xl-8'>
                                <div className='blog-page-add'>
                                    <div className='profile-top-heading addnewblog-section'>
                                        <h2> {languageSelector?.contact_ticket?.support} </h2>
                                        <div className='addnewblog'>
                                            <button type='button' className='btn' onClick={() => navigate(`/${role == 2 ? "student" : "instructor"}/support`)}>
                                                {languageSelector?.contact_ticket?.back}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {Object.keys(supportDetailSelector)?.length > 0 ?
                                    Object.keys(supportDetailSelector?.data?.contactUsDetails)?.length > 0 ?
                                        <>
                                            <div className="my-profile-details">
                                                <div className='row g-4'>
                                                    <div className='col-12'>
                                                        <div className='card_support_box card_support_box2'>
                                                            <h3>{supportDetailSelector?.data?.contactUsDetails?.contact_reason?.name}</h3>
                                                            <div className='date_comment_box'>
                                                                <div className='date'>
                                                                    {moment(supportDetailSelector?.data?.contactUsDetails?.created_at, "YYYY-MM-DD").format("DD MMM'YY")}
                                                                </div>
                                                                <div className='comment_icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.25" height="11" viewBox="0 0 12.25 11">
                                                                        <path id="message-square-svgrepo-com" d="M3,6a3.105,3.105,0,0,1,.136-1.317,1.25,1.25,0,0,1,.546-.546A3.105,3.105,0,0,1,5,4h7.25a3.105,3.105,0,0,1,1.318.136,1.25,1.25,0,0,1,.546.546A3.105,3.105,0,0,1,14.25,6v8l-2.078-1.039a2.6,2.6,0,0,0-.319-.146,1.254,1.254,0,0,0-.225-.053,2.609,2.609,0,0,0-.35-.012H5a3.105,3.105,0,0,1-1.317-.136,1.25,1.25,0,0,1-.546-.546A3.105,3.105,0,0,1,3,10.75Z" transform="translate(-2.5 -3.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                                                    </svg>
                                                                    {supportDetailSelector?.data?.contactReplies?.length}
                                                                </div>
                                                            </div>
                                                            <div className='ticket_number'>
                                                                <h4>{languageSelector?.contact_ticket?.ticket}: <span className='text-uppercase'> {supportDetailSelector?.data?.contactUsDetails?.ticked_no} </span> </h4>
                                                                <p
                                                                    className={`active_closed ${supportDetailSelector?.data?.contactUsDetails?.status === 1 ? "active" : "closed"}`}
                                                                >
                                                                    {supportDetailSelector?.data?.contactUsDetails?.status === 1 ?
                                                                        languageSelector?.contact_ticket?.active :
                                                                        supportDetailSelector?.data?.contactUsDetails?.status === 2 ?
                                                                            languageSelector?.contact_ticket?.closed : ""
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 massage_text'>
                                                        <p>{supportDetailSelector?.data?.contactUsDetails?.message}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='user_card_admin_message'>
                                                <div className='massage_admin_user'>
                                                    {supportDetailSelector?.data?.contactReplies?.length > 0 ?
                                                        supportDetailSelector?.data?.contactReplies?.map((value, index) => (
                                                            <div className='card_admin_box' key={index}>
                                                                <div className='img_content_box'>
                                                                    <div className='user_profile'>
                                                                        {value?.bySend == "1" ?
                                                                            <span className='profile-initial'> A </span>
                                                                            :
                                                                            <img
                                                                                src={user_detail?.user?.profile_image ?
                                                                                    isValidHttpUrl(user_detail?.user?.profile_image) ? user_detail?.user?.profile_image
                                                                                        : `${baseUrlPostGres()}${user_detail?.user?.profile_image}`
                                                                                    : "/images/blank-profile-picture.jpg"}
                                                                                referrerPolicy="no-referrer"
                                                                                alt={user_detail?.user?.first_name}
                                                                            />}

                                                                    </div>
                                                                    <div className='content_user_box'>
                                                                        <div className='top_name_date'>
                                                                            <h3> {value?.bySend == "1" ? "Admin" : supportDetailSelector?.data?.contactUsDetails?.name} </h3>
                                                                            <p> {moment(value?.created_at).format("DD MMM'YY")} </p>
                                                                        </div>
                                                                        <p> {value?.message} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className='message_not_found_img'>
                                                            <NoDataFound />
                                                        </div>
                                                    }
                                                </div>

                                                <div className='typing_message_box_main'>
                                                    {supportDetailSelector?.data?.contactUsDetails?.status == 1 ?
                                                        <Formik
                                                            initialValues={{
                                                                message: "",
                                                            }}
                                                            validationSchema={() => Yup.object({
                                                                message: Yup.string().required(languageSelector?.validation?.message),
                                                            })}
                                                            onSubmit={(values, { resetForm }) => {
                                                                setReplySubmit(true)
                                                                dispatch(SupportSendMessageAction({ ...values, contact_id: id }))
                                                                resetForm()
                                                            }}
                                                        >
                                                            {(formik) => (
                                                                <Form className='typing_message_box'>
                                                                    <div className='typing_main_box'>
                                                                        <Field
                                                                            rows="5"
                                                                            placeholder={`${languageSelector?.placeholder?.write_your_message}.....`}
                                                                            name="message"
                                                                            className="typing_input"
                                                                            maxLength="5000"
                                                                        />
                                                                        <ErrorMessage name="message" component={TextErrorMsg} />
                                                                        <Button
                                                                            BtnText={
                                                                                <>
                                                                                    <svg id="Group_49773" data-name="Group 49773" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                        <path id="Path_80030" data-name="Path 80030" d="M0,0H24V24H0Z" fill="none" />
                                                                                        <line id="Line_150" data-name="Line 150" y1="11" x2="11" transform="translate(10 3)" fill="none" stroke="Currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                                                        <path id="Path_80031" data-name="Path 80031" d="M21,3,14.5,21a.55.55,0,0,1-1,0L10,14,3,10.5a.55.55,0,0,1,0-1L21,3" fill="none" stroke="Currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                                                    </svg>
                                                                                </>
                                                                            }
                                                                            BtnColor=" sent_message_icon"
                                                                            BtnType="submit"
                                                                            disabled={!formik.isValid || (isloading && replySubmit)}
                                                                            hasSpinner={isloading && replySubmit}
                                                                        />
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                        :
                                                        <div className='closed_card'>
                                                            {languageSelector?.contact_ticket?.ticket_closed}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <NoDataFound />
                                    :
                                    <div style={{ marginTop: "4rem" }}>
                                        <Skeleton height={230} borderRadius="2.6rem" />
                                        <Skeleton height={300} style={{ marginTop: "20px" }} borderRadius="2.6rem" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default SupportDetail
