import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddReviewRatingAction } from '../../redux/actions/ReviewAndRatingAction';
import Button from '../Buttons/Buttons';

const RatingAndReviewModal = (props) => {
    const { setRatingModalShow, setOnSubmitRating, onSubmitRating, user_type, id, bookingID, ...rest } = props;
    const dispatch = useDispatch()
    const [ratingStar, setRatingStar] = useState()
    const [reviewComment, setReviewComment] = useState()
    const isloading = useSelector(state => state.rating.loading)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const handleModalClose = () => {
        setRatingModalShow(false)
        setReviewComment("")
        setRatingStar("")
    }

    const handleRatingReviewSubmit = (e) => {
        e.preventDefault();
        if (ratingStar && reviewComment) {
            dispatch(AddReviewRatingAction(user_type, {
                user_id: id,
                rating: ratingStar,
                comments: reviewComment,
                bookingId: bookingID
            }))
            setOnSubmitRating(true)
        } else {
            toast.error(languageSelector?.validation?.rating, { theme: "colored" })
        }
    }

    return (
        <Modal
            {...rest}
            centered
            backdrop="static"
            keyboard={false}
            className='center-modal class-modal problemsolving rejectbooking'
        >
            <Modal.Header>
                <Modal.Title> {languageSelector?.classes_details?.rate_exp} </Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}></button>
            </Modal.Header>
            <Modal.Body className='blogdetails-modal mb-0'>
                <form onSubmit={handleRatingReviewSubmit}>
                    <div className='classtype-section'>
                        <div className="star-rating">
                            {[5, 4, 3, 2, 1].map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <input type="radio" id={`star-${value}`} name="rating" value={value} onChange={(e) => setRatingStar(e.target.value)} />
                                        <label htmlFor={`star-${value}`} className="star">&#9733;</label>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        <div className="form-group floating-label">
                            <textarea id="textarea" className="form-control" rows="4" maxLength="300" placeholder=' ' required onChange={(e) => setReviewComment(e.target.value)}></textarea>
                            <label htmlFor="textarea"> {languageSelector?.classes_details?.review_here} </label>
                        </div>
                    </div>
                    <div className='email-send mt-5'>
                        <div className='accept'>
                            <Button
                                BtnText={languageSelector?.classes_details?.submit}
                                BtnColor="primary"
                                BtnType="submit"
                                disabled={isloading}
                                hasSpinner={isloading && onSubmitRating}
                            />
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}
export default RatingAndReviewModal