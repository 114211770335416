import React from "react";
import { Link } from "react-router-dom";
import "./PageNotFound.scss"

const PageNotFound = () => {
  return (
    <div className="oopss">
      <div className='error-text'>
        <img src="/images/sad404.svg" alt="404" />
        <span>404 PAGE</span>
        <p className="p-a">The page you were looking for could not be found</p>
        <Link type="button" to="/" className="back"> Back to Home </Link>
      </div>
    </div>
  );
};

export default PageNotFound