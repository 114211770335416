import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import "../../../style.scss";
import { useNavigate, useParams } from "react-router-dom";
import { socket, removeSocketAllListeners, socketConnection } from "../../../redux/constant/Socket";
import { useDispatch, useSelector } from "react-redux";
import { paymentZoomLink } from "../../../redux/actions/PaymentAction";
import SuccessCheckIcon from "./../SuccessCheckIcon"
import TimeDuration from "../../../components/TimeDuration/TimeDuration";
import { copyLinkToClipboard, getUserSettings } from "../../../Utils/Function";
import { BookingRequestStatusAction, ResetBookingRequestStatusAction } from "./../../../redux/actions/instructorAction"
import RatingAndReviewModal from "../../../components/RatingAndReviewModal/RatingAndReviewModal";
import { ResetAddReviewRatingAction } from "../../../redux/actions/ReviewAndRatingAction";

const Ondemandclassconfirmation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const user_detail = getUserSettings();
    const [zoomLink, setZoomLink] = useState();
    const [timeStatus, setTimeStatus] = useState(false)
    const bookingStatus = useSelector(state => state.instructor.booking_request_status)
    const zoomLinkSelector = useSelector((state) => state?.instructor?.zoom_link)
    const [ratingModalShow, setRatingModalShow] = useState(false);
    const [onSubmitRating, setOnSubmitRating] = useState(false);
    const [instructorId, setInstructorId] = useState()
    const [bookingID, setBookingID] = useState();
    const [rejectBookingMessage, setRejectBookingMessage] = useState("");
    const ratingSubmitSelector = useSelector(state => state.rating.add_rating_review)
    const languageSelector = useSelector(state => state.home.dynamic_language);


    useEffect(() => {
        removeSocketAllListeners()
        socketConnection()
        dispatch(paymentZoomLink(id))
        return () => dispatch(ResetBookingRequestStatusAction())
    }, [dispatch])

    socket.on("student_booking_zoom_details", (accept) => {
        setRejectBookingMessage(accept.message)
        setZoomLink(accept.zoom_url)
    });

    useEffect(() => {
        if (Object.keys(zoomLinkSelector).length > 0) {
            if (zoomLinkSelector.status == 200) {
                setRejectBookingMessage(zoomLinkSelector.data.message)
                setZoomLink(zoomLinkSelector.data.zoom_url)
            }
        }
    }, [zoomLinkSelector])

    useEffect(() => {
        if (timeStatus) {
            dispatch(BookingRequestStatusAction(id))
        }
    }, [timeStatus])

    socket.on(`rating_student${user_detail?.user?.id}`, (data) => {
        setRatingModalShow(data.rating)
        setBookingID(data.booking_id)
        setInstructorId(data.instructor_id)
    });

    useEffect(() => {
        if (Object.keys(ratingSubmitSelector).length > 0) {
            if (ratingSubmitSelector.status) {
                setRatingModalShow(false)
                setOnSubmitRating(false)
                dispatch(ResetAddReviewRatingAction())
            }
        }
    }, [ratingSubmitSelector])

    return (
        <>
            <div id="footer-bottom" className="instructorcompleteprofile">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.student_booking?.instructors} </h1>
                    <p>
                        Home / {languageSelector?.student_booking?.instructors} / {languageSelector?.student_booking?.ondemand_class} / <span> {languageSelector?.student_booking?.confirmation} </span>
                    </p>
                </div>

                <div className="confirmation-section">
                    {zoomLink ?
                        <>
                            <span>
                                <SuccessCheckIcon />
                            </span>
                            <h2> {languageSelector?.student_booking?.zoom_link_is_ready} </h2>
                            <p>
                                <a href={zoomLink} target="_blank"> {zoomLink} </a>
                            </p>
                            <button onClick={() => copyLinkToClipboard(zoomLink)} className="btn"> {languageSelector?.student_booking?.copy_link} </button>
                        </>
                        :
                        <div className="comfirmation-pending-block">
                            {Object.keys(bookingStatus).length > 0 ?
                                <>
                                    <h5> {bookingStatus.message} </h5>
                                    <button onClick={() => navigate("/instructors")} className="btn"> {languageSelector?.student_booking?.go_the_instructor_listing} </button>
                                </>
                                :
                                rejectBookingMessage ?
                                    <>
                                        <h5> {rejectBookingMessage} </h5>
                                        <button onClick={() => navigate("/instructors")} className="btn"> {languageSelector?.student_booking?.go_the_instructor_listing} </button>
                                    </>
                                    :
                                    <>
                                        <span>
                                            <SuccessCheckIcon />
                                        </span>
                                        <h2> {languageSelector?.student_booking?.wait}... </h2>
                                        <h5> {languageSelector?.student_booking?.wait_few_minutes_des} </h5>
                                        <div className="waiting-timer-block">
                                            {zoomLinkSelector?.data?.end_time ?
                                                <TimeDuration timerData={zoomLinkSelector?.data?.end_time} isHours={false} setTimeStatus={setTimeStatus} />
                                                :
                                                "00m:00s"
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    }
                </div>
                <Footer />
            </div>

            <RatingAndReviewModal
                show={ratingModalShow}
                setRatingModalShow={setRatingModalShow}
                setOnSubmitRating={setOnSubmitRating}
                onSubmitRating={onSubmitRating}
                id={instructorId}
                user_type="student"
                bookingID={bookingID}
            />
        </>
    );
};
export default Ondemandclassconfirmation;
