import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Buttons/Buttons";
import { ResetpaymentOnProblemSolvingAction, paymentOnProblemSolvingAction } from "../../../redux/actions/PaymentAction";
import EditIcon from "../EditIcon";
import PaymentCheckout from "../PaymentCheckout";
import SuccessCheckIcon from "../SuccessCheckIcon";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ProfileGetAction } from "../../../redux/actions/CompleteProfileAction";

const PSClassStepThere = ({ subscriptionValue, amount, setActiveStep }) => {
    const { id, name } = useParams();
    const dispatch = useDispatch();
    const location = useLocation()
    const navigate = useNavigate()
    const prevPageData = location?.state

    const [couponAmount, setCouponAmount] = useState(0)
    const [showratecard, ratecardShow] = useState(false);
    const [couponId, setCouponId] = useState()
    const [submitClick, setSubmitClick] = useState(false)

    // const isLoadingSelector = useSelector((state) => state?.instructor?.loading)
    const paymentSelector = useSelector((state) => state?.instructor?.instructor_problem_solving)
    const SubscriptionStatusSelector = useSelector((state) => state.instructor.subscription_detail);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        setActiveStep(name)
        return () => ratecardShow(false)
    }, [name])

    const handleCloseratecard = () => ratecardShow(false);

    const handlePrevious = () => {
        if (subscriptionValue) {
            navigate(`/problemsolvingclass/upload-problem/${id}`, { state: prevPageData });
            setActiveStep('upload-problem');
        } else {
            navigate(`/problemsolvingclass/payment-method/${id}`, { state: prevPageData });
            setActiveStep('payment-method');
        }
    }

    const handlePayment = () => {
        let formdata = new FormData();
        formdata.append("subject_id", prevPageData.subject_id);
        formdata.append("level_id", prevPageData.level_id);
        formdata.append("amount", subscriptionValue ? 0 : grandTotalAmount(amount, couponAmount));
        if (couponId) {
            formdata.append("couponId", couponId);
            formdata.append("coupon_amount", couponAmount);
        }
        formdata.append("instructor_id", id);
        formdata.append("pay_from", SubscriptionStatusSelector?.data?.status ? "3" : prevPageData.pay_from);
        formdata.append("title", prevPageData.title);
        formdata.append("description", prevPageData.description);
        formdata.append("file_doc", prevPageData.file_doc);
        if (prevPageData?.payment_mode !== "wallet") {
            formdata.append("card_id", prevPageData.card_id);
        }
        dispatch(paymentOnProblemSolvingAction(formdata));
        setSubmitClick(true)
    }

    const grandTotalAmount = (amount, discount) => {
        let finalAmount = (Number(amount) - discount)
        return finalAmount.toFixed(2)
    }

    useEffect(() => {
        if (Object.keys(paymentSelector).length > 0 && submitClick) {
            if (paymentSelector.status) {
                ratecardShow(true)
                setSubmitClick(false)
                dispatch(ResetpaymentOnProblemSolvingAction())
                dispatch(ProfileGetAction("student"))
            } else {
                setSubmitClick(false)
            }
        }
    }, [paymentSelector, submitClick])

    var finalPayAmount = (Number(amount) - Number(couponAmount)).toFixed(2)

    return (
        <>
            <div className="personaldetails-main-section on-demand-class-type">
                <h2> {languageSelector?.student_booking?.checkout} </h2>
                <div className="on-demand-class-content">
                    <div className="row g-3 g-md-5">
                        <div className="col-md-8 col-sm-12 order-2 order-md-0">

                            <PaymentCheckout isCoupon={subscriptionValue ? false : true} isSubscription={subscriptionValue} setCouponId={setCouponId} amount={amount} grandTotalAmount={grandTotalAmount} couponAmountFn={setCouponAmount} />

                            <div className="d-flex justify-content-center mt-5">
                                <Button
                                    name="Back"
                                    BtnText={languageSelector?.student_booking?.back}
                                    BtnColor="yellow"
                                    BtnType="button"
                                    onClick={handlePrevious}
                                />

                                {prevPageData?.payment_mode === "paypal" ?
                                    <PayPalScriptProvider
                                        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                                    >
                                        <PayPalButtons
                                            createOrder={(_, actions) => {
                                                return actions.order
                                                    .create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: finalPayAmount,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                    .then((orderId) => {
                                                        return orderId;
                                                    });
                                            }}
                                            forceReRender={[finalPayAmount]}
                                            onApprove={(_, actions) => {
                                                return actions.order.capture().then((details) => {
                                                    let formdata = new FormData();
                                                    formdata.append("subject_id", prevPageData.subject_id);
                                                    formdata.append("level_id", prevPageData.level_id);
                                                    formdata.append("amount", subscriptionValue ? 0 : grandTotalAmount(amount, couponAmount));
                                                    if (couponId) {
                                                        formdata.append("couponId", couponId);
                                                        formdata.append("coupon_amount", couponAmount);
                                                    }
                                                    formdata.append("instructor_id", id);
                                                    formdata.append("pay_from", 4);
                                                    formdata.append("title", prevPageData.title);
                                                    formdata.append("description", prevPageData.description);
                                                    formdata.append("file_doc", prevPageData.file_doc);
                                                    formdata.append("order_id", details.id);
                                                    formdata.append("order_status", details.status);
                                                    formdata.append("payer_detail", details.payer);
                                                    dispatch(paymentOnProblemSolvingAction(formdata));
                                                    setSubmitClick(true)
                                                });
                                            }}
                                            style={{
                                                layout: 'horizontal',
                                                color: 'blue',
                                                shape: 'rect',
                                                label: 'pay',
                                                height: 55,
                                                tagline: false
                                            }}
                                        />
                                    </PayPalScriptProvider>
                                    :
                                    <Button
                                        BtnText={languageSelector?.student_booking?.proceed_to_pay}
                                        name="pay"
                                        BtnColor="primary"
                                        BtnType="button"
                                        onClick={handlePayment}
                                        disabled={submitClick}
                                        hasSpinner={submitClick}
                                    />
                                }
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <div className="personaldetails-section payable-amount-card-box">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <span className="amount-text"> {languageSelector?.student_booking?.problem_solving_class} </span>
                                    </div>

                                    <div className="col-4 text-end">
                                        <Link className="btn edit-btn" to={`/instructordetail/${id}`}>
                                            <EditIcon />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="personaldetails-section payable-amount-card-box">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <span className="amount-text">{prevPageData?.subject_name} | {prevPageData?.level_name}</span>
                                    </div>

                                    <div className="col-4 text-end">
                                        <Link className="btn edit-btn" to={`/problemsolvingclass/upload-problem/${id}`}>
                                            <EditIcon />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {!SubscriptionStatusSelector?.data?.status ?
                                <div className="personaldetails-section payable-amount-card-box">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <span className="amount-text">
                                                {prevPageData?.payment_mode === "card" ? languageSelector?.payment_details?.card : prevPageData?.payment_mode === "paypal" ? languageSelector?.payment_details?.paypal : languageSelector?.payment_details?.wallet}
                                            </span>
                                            {prevPageData?.payment_mode === "card" ? <p className="account-number-code">xxxx xxxx xxxx {prevPageData?.last4}</p> : null}
                                        </div>

                                        <div className="col-4 text-end">
                                            <div className="btn edit-btn" onClick={() => navigate(`/problemsolvingclass/payment-method/${id}`, { state: prevPageData })}>
                                                <EditIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null}

                        </div>
                    </div>
                </div>
            </div>

            <Modal className='center-modal class-modal problemsolving workstation'
                show={showratecard}
                onHide={handleCloseratecard}
                backdrop="static"
                keyboard={false}
            >
                {/* closeButton */}
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='blogdetails-modal'>
                    <div className='classtype-section'>
                        <div className='checkmark'>
                            <SuccessCheckIcon />
                            <p> {languageSelector?.student_booking?.payment_successful} </p>
                        </div>
                        <div className='url-box'>
                            <p> {languageSelector?.student_booking?.payment_successful_des} </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='email-send'>
                        <div className='accept'>
                            <Link className="btn" to="/student/classes-details"> {languageSelector?.student_booking?.go_to_classes_detail} </Link>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default PSClassStepThere