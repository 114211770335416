import React from "react";
import Studentmyprofile from "../Studentprofile/Studentmyprofile";
import InstructorMyProfile from "./InstructorMyProfile";
import { useParams } from "react-router-dom";
const ProfileWrraper = () => {
    const { role } = useParams();
    if (role === "instructor") {
        return <InstructorMyProfile />;
    } else {
        return <Studentmyprofile />;
    }
};
export default ProfileWrraper;
