import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentAction, CardList, ResetaddPaymentAction, ResetCardCreate } from "../../redux/actions/PaymentAction";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Buttons/Buttons";
import '../Instructorprofile/Instructorprofile.scss';
import { getUserSettings } from "../../Utils/Function";
import { toast } from "react-toastify";
import CardListView from "../../components/CardListView";
import AddCardModal from "../../components/AddCardModal";
import { AddGiftCardAction, ResetAddGiftCardAction } from "../../redux/actions/GiftCardAction";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ProfileGetAction } from "../../redux/actions/CompleteProfileAction";

const Studentpaymentmethod = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const locationData = location.state
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [submitClick, setSubmitClick] = useState(false)
    const [submitCardFormClick, setSubmitCardFormClick] = useState(false)
    const [addPaymentDetail, setAddPaymentDetail] = useState()
    const [methodPayment, setMethodPayment] = useState();
    const cardList = useSelector((state) => state.card?.card_list?.data);
    const cardSuccessfulllyCreated = useSelector((state) => state.card?.create_card);
    const AddMoneySuccessfulllySelector = useSelector((state) => state.card?.add_amount);
    const isLoading = useSelector((state) => state?.card.loading);
    const isGiftCardloading = useSelector(state => state.giftCard.loading)
    const GiftCardAddSelector = useSelector(state => state.giftCard.add_gift_card)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const user_detail = getUserSettings();

    useEffect(() => {
        dispatch(CardList());
    }, [dispatch]);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleRadioInput = (value) => {
        setAddPaymentDetail({ card_id: value.id, pay_from: 1 });
        setMethodPayment("card")
    };

    const handleWalletCheckbox = (e) => {
        const { checked } = e.target
        setAddPaymentDetail({ pay_from: checked ? 2 : 0 })
        setMethodPayment(checked ? "wallet" : "")
    }

    useEffect(() => {
        if (Object.keys(cardSuccessfulllyCreated).length > 0 && submitCardFormClick) {
            if (cardSuccessfulllyCreated.status) {
                setSubmitCardFormClick(false)
                setShow(false)
                dispatch(ResetCardCreate())
                dispatch(CardList());
            } else {
                setSubmitCardFormClick(false)
                dispatch(ResetCardCreate())
            }
        }
    }, [cardSuccessfulllyCreated, submitCardFormClick])

    const handleAddPayment = () => {
        if (addPaymentDetail) {
            setSubmitClick(true)
            dispatch(addPaymentAction({ ...addPaymentDetail, amount: locationData.amount }))
        } else {
            toast.error(languageSelector?.validation?.select_any_one_payment_method, { theme: "colored" })
        }
    }

    useEffect(() => {
        if (Object.keys(AddMoneySuccessfulllySelector).length > 0 && submitClick) {
            if (AddMoneySuccessfulllySelector.status) {
                setSubmitClick(false)
                dispatch(ResetaddPaymentAction())
                navigate("/wallet-and-payment")
                dispatch(ProfileGetAction("student"))
            } else {
                setSubmitClick(false)
                dispatch(ResetaddPaymentAction())
            }
        }
    }, [AddMoneySuccessfulllySelector, submitClick])

    const handleAddGiftCardPayment = () => {
        if (methodPayment == undefined) {
            toast.error(languageSelector?.validation?.select_any_one_payment_method, { theme: "colored" })
            return <></>
        }
        if (methodPayment === "card" && !addPaymentDetail) {
            toast.error(languageSelector?.validation?.select_any_one_card, { theme: "colored" })
            return <></>
        }
        if (methodPayment === "wallet" && locationData.gift_amount > user_detail?.user?.wallet) {
            toast.error(languageSelector?.validation?.insufficient_balance, { theme: "colored" })
            return <></>
        }
        setSubmitClick(true)
        dispatch(AddGiftCardAction({ ...addPaymentDetail, email: locationData.email, gift_amount: locationData.gift_amount, name: locationData.name }))
    }

    useEffect(() => {
        if (Object.keys(GiftCardAddSelector).length > 0) {
            if (GiftCardAddSelector.status) {
                setSubmitClick(false)
                dispatch(ResetAddGiftCardAction())
                navigate("/studentgiftcardcoupons")
                dispatch(ProfileGetAction("student"))
            } else {
                setSubmitClick(false)
                dispatch(ResetAddGiftCardAction())
            }
        }
    }, [GiftCardAddSelector])

    var finalPayAmount = locationData.page_name == "add_amount" ? Number(locationData.amount) : locationData.gift_amount

    return (
        <>
            <div id="footer-bottom" className='instructor-profile walletpayment paymentmethode-section-box'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.wallet_payment?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.wallet_payment?.page_title} </span></p>
                </div>

                <div className='myprofile-section'>
                    <div className='container'>
                        <div className='row g-5'>
                            <div className='col-md-12 col-lg-5 col-xl-4'>
                                <Studentsidebar />
                            </div>

                            <div className='col-md-12 col-lg-7 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.wallet_payment?.payment_method} </h2>
                                </div>
                                <div className='my-profile-details'>
                                    <div className='wallet-card-main-section row g-5'>
                                        <div className='col-lg-12'>
                                            <div>
                                                {locationData.page_name !== "add_amount" ?
                                                    <div className='wallet-card mb-5'>
                                                        <div className='payment-details-user'>
                                                            <div className="row gy-4">
                                                                <div className='col-lg-6 col-xl-4 col-md-6'>
                                                                    <h3> {languageSelector?.wallet_payment?.student_full_name} </h3>
                                                                    <p>{locationData?.name}</p>
                                                                </div>
                                                                <div className='col-lg-6 col-xl-4 col-md-6'>
                                                                    <h3> {languageSelector?.wallet_payment?.student_email} </h3>
                                                                    <p>{locationData?.email}</p>
                                                                </div>
                                                                <div className='col-lg-6 col-xl-4 col-md-6'>
                                                                    <h3> {languageSelector?.wallet_payment?.gift_amount} </h3>
                                                                    <p>${locationData?.gift_amount}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>

                                            <div>
                                                <div className="wallet-card my-5 mt-0">
                                                    <div className="select-subject-level-card">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <span className="amount-text2"> {languageSelector?.wallet_payment?.wallet_balance} </span>
                                                                <h3 className="amount-price">${user_detail?.user?.wallet ? Number(user_detail?.user?.wallet).toFixed(0) : 0}</h3>
                                                            </div>
                                                            {locationData.page_name !== "add_amount" &&
                                                                <div className="col-3 text-end">
                                                                    <div className="radio-box radio-box2">
                                                                        <div className="check-box-create">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="wallet_check_box"
                                                                                name="wallet_check_box"
                                                                                onChange={handleWalletCheckbox}
                                                                                value={user_detail?.user?.wallet ? Number(user_detail?.user?.wallet).toFixed(0) : 0}
                                                                                checked={methodPayment === "wallet" ? true : false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='wallet-card'>
                                                    <div className="personaldetails-section">
                                                        <div className="select-subject-level-card payment-box-main-content">
                                                            <div className="paymentmethod-box mt-0">
                                                                <div className="tow-type-card-payment mt-0 pb-5">
                                                                    <form className="payment-type row">
                                                                        <div className="col-md-6 col-sm-12">
                                                                            <div className="stripe">
                                                                                <div className="form-check">
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="flexRadioDefault1"
                                                                                    >
                                                                                        <img src="/images/credit-card2.png" alt="" />
                                                                                        {languageSelector?.wallet_payment?.debit_credit_card}
                                                                                    </label>
                                                                                    <input
                                                                                        checked={methodPayment == "card" ? true : false}
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="payment_type_select"
                                                                                        id="flexRadioDefault1"
                                                                                        value="card"
                                                                                        onChange={(e) => setMethodPayment(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-6">
                                                                            <div className="stripe">
                                                                                <div className="form-check">
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="flexRadioDefault3"
                                                                                    >
                                                                                        <img src="/images/paypal2.png" alt="" />
                                                                                        {languageSelector?.wallet_payment?.paypal}
                                                                                    </label>
                                                                                    <input
                                                                                        checked={methodPayment == "paypal" ? true : false}
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="payment_type_select"
                                                                                        id="flexRadioDefault3"
                                                                                        value="paypal"
                                                                                        onChange={(e) => setMethodPayment(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>

                                                                {methodPayment == "card" ? (
                                                                    <CardListView
                                                                        handleShow={handleShow}
                                                                        cardList={cardList}
                                                                        handleRadioInput={handleRadioInput}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="completeProfile-container btn-proceed-to d-flex justify-content-center mt-5">
                                                        <Button
                                                            name="Back"
                                                            BtnText={languageSelector?.wallet_payment?.back}
                                                            BtnColor="yellow"
                                                            BtnType="button"
                                                            onClick={() => locationData.page_name == "add_amount" ? navigate("/wallet-and-payment", { state: locationData }) : navigate("/studentgiftcardcoupons", { state: locationData })}
                                                        />

                                                        {methodPayment == "paypal" ?
                                                            <PayPalScriptProvider
                                                                options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                                                            >
                                                                <PayPalButtons
                                                                    createOrder={(_, actions) => {
                                                                        return actions.order
                                                                            .create({
                                                                                purchase_units: [
                                                                                    {
                                                                                        amount: {
                                                                                            value: finalPayAmount,
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            })
                                                                            .then((orderId) => {
                                                                                return orderId;
                                                                            });
                                                                    }}
                                                                    forceReRender={[finalPayAmount, methodPayment]}
                                                                    className="heroBtnbutton"
                                                                    onApprove={(_, actions) => {
                                                                        return actions.order.capture().then((details) => {
                                                                            let data = {
                                                                                pay_from: 4,
                                                                                order_id: details.id,
                                                                                order_status: details.status,
                                                                                payer_detail: details.payer
                                                                            }
                                                                            setSubmitClick(true)
                                                                            if (locationData.page_name == "add_amount") {
                                                                                data["amount"] = locationData.amount
                                                                                dispatch(addPaymentAction(data))
                                                                            } else {
                                                                                data["email"] = locationData.email
                                                                                data["gift_amount"] = locationData.gift_amount
                                                                                data["name"] = locationData.name
                                                                                dispatch(AddGiftCardAction({ ...addPaymentDetail, ...data }))
                                                                            }
                                                                        });
                                                                    }}
                                                                    style={{
                                                                        layout: 'horizontal',
                                                                        color: 'blue',
                                                                        shape: 'rect',
                                                                        label: 'pay',
                                                                        height: 55,
                                                                        tagline: false
                                                                    }}
                                                                />
                                                            </PayPalScriptProvider>
                                                            :
                                                            <Button
                                                                BtnText={languageSelector?.wallet_payment?.proceed_to_payment}
                                                                name="pay"
                                                                BtnColor="primary"
                                                                BtnType="button"
                                                                onClick={() => locationData.page_name == "add_amount" ? handleAddPayment() : handleAddGiftCardPayment()}
                                                                disabled={locationData.page_name == "add_amount" ? isLoading : isGiftCardloading}
                                                                hasSpinner={locationData.page_name == "add_amount" ? isLoading : isGiftCardloading && submitClick}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AddCardModal
                    show={show}
                    onHide={handleClose}
                    clickButton={submitCardFormClick}
                    setClickButton={setSubmitCardFormClick}
                    isloading={isLoading}
                />

                <Footer />
            </div>
        </>
    );
}

export default Studentpaymentmethod
