import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import { FieldText } from "../../components/InputText/InputText";
import { GetBlogsCategory } from "../../redux/actions/HomePageActions";
import { ChooseFile } from "../../components/ChooseFile/ChooseFile";
import { BlogViewAction, EditBlogAction, ResetBlogViewAction, ResetEditBlogAction } from "../../redux/actions/BlogActions";
import { ErrorMessage, Form, Field, Formik, useFormikContext } from "formik";
import TextErrorMsg from "../../components/TextErrorMsg/TextErrorMsg";
import * as Yup from "yup";
import Button from "../../components/Buttons/Buttons";
import "../Instructorprofile/Instructorprofile.scss";
import { baseUrlPostGres } from "../../redux/constant";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogEdit = ({ role }) => {
    const dispatch = useDispatch();
    const { name, id } = useParams()
    const navigate = useNavigate()
    const [clickBlogSubmit, setClickBlogSubmit] = useState(false)

    const blogsCategory = useSelector((state) => state.home?.get_blogs_category);
    const BlogDetailSelector = useSelector((state => state.blogs.blog_view))
    const BlogEditSelector = useSelector((state => state.blogs.blog_edit))
    const isloading = useSelector((state => state.blogs.loading))
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(BlogViewAction(role == 2 ? "blog-edit" : "edit-blog", id));
        dispatch(GetBlogsCategory());
        return () => dispatch(ResetBlogViewAction())
    }, [dispatch]);

    const handleBlogPostStudent = (values, { resetForm }) => {
        let formData = new FormData();
        formData.append("blog_category_id", values.blog_category_id);
        formData.append("blog_title", values.blog_title);
        formData.append("blog_description", values.blog_description);
        if (typeof values.blog_image == "object") {
            formData.append("blog_image", values.blog_image);
        }
        dispatch(EditBlogAction(role == 2 ? "update-blog" : "blog-update", id, formData));
        setClickBlogSubmit(true)
        // resetForm();
    };

    useEffect(() => {
        if (Object.keys(BlogEditSelector).length > 0) {
            if (BlogEditSelector.status) {
                navigate(`/my-blog/${name}/list`)
                dispatch(ResetEditBlogAction())
            }
            setClickBlogSubmit(false)
        }
    }, [BlogEditSelector])

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            ['clean']
        ]
    };

    const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        <div id="footer-bottom" className="instructor-profile addnewblog-page">
            <Header />
            <div className="instru-bg-image">
                <h1>  {languageSelector?.my_blogs?.edit_blog}  </h1>
                <p>  Home / {languageSelector?.my_blogs?.page_title} / <span> {languageSelector?.my_blogs?.edit_blog} </span> </p>
            </div>

            <div className="myprofile-section">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-md-12 col-lg-5 col-xl-4">
                            {role == 2 ? <Studentsidebar /> : <Instructorsidebar />}
                        </div>

                        <div className="col-md-12 col-lg-7 col-xl-8">
                            <div className="profile-top-heading">
                                <h2> {languageSelector?.my_blogs?.edit_blog} </h2>
                            </div>
                            {Object.keys(BlogDetailSelector).length > 0 ?
                                <Formik
                                    initialValues={{
                                        blog_category_id: BlogDetailSelector?.data?.blog_category_id,
                                        blog_title: BlogDetailSelector?.data?.blog_title,
                                        blog_description: BlogDetailSelector?.data?.blog_description,
                                        blog_image: BlogDetailSelector?.data?.blog_image,
                                    }}
                                    validationSchema={() =>
                                        Yup.object().shape({
                                            blog_category_id: Yup.string().required(languageSelector?.validation?.category),
                                            blog_title: Yup.string().required(languageSelector?.validation?.title),
                                            blog_description: Yup.string().required(languageSelector?.validation?.description),
                                            blog_image: Yup.string().required(languageSelector?.validation?.picture_required),
                                        })
                                    }
                                    onSubmit={handleBlogPostStudent}
                                >
                                    {(formik) => {
                                        return (
                                            <Form>
                                            <FormikFromFunc />
                                                <div className="my-profile-details">
                                                    <div className="manages-section-main">
                                                        <div className="tab-section-profile">
                                                            <div className="bolg-setion">
                                                                <div className="blog-title">
                                                                    <FieldText
                                                                        name="blog_title"
                                                                        type="text"
                                                                        placeholder={languageSelector?.placeholder?.blog_title}
                                                                        defaultValue={formik.values.blog_title}
                                                                        maxLength="150"
                                                                    />
                                                                </div>

                                                                <div className="dropdown-select fix-placeholder">
                                                                    <div className="App">
                                                                        <Field
                                                                            as="select"
                                                                            name="blog_category_id"
                                                                            className="select-control w-100"
                                                                            value={formik.values.blog_category_id}
                                                                        >
                                                                            <option value=""> {languageSelector?.placeholder?.select} </option>
                                                                            {blogsCategory.map((value, index) => {
                                                                                return (
                                                                                    <option value={value.id} key={index}>
                                                                                        {value.blog_category}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </Field>
                                                                        <ErrorMessage name="blog_category_id" component={TextErrorMsg} />
                                                                        <span className="placeholder-name"> {languageSelector?.placeholder?.categroy} </span>
                                                                    </div>
                                                                </div>

                                                                <div className="descriptio">
                                                                    <div className="form-group floating-label">
                                                                        <Field name="blog_description">
                                                                            {({ form, field }) => {
                                                                                return (
                                                                                    <>
                                                                                        <label> {languageSelector?.placeholder?.description} :</label>
                                                                                        <ReactQuill
                                                                                            className="cms-content-editor"
                                                                                            name={field.name}
                                                                                            modules={modules}
                                                                                            formats={formats}
                                                                                            value={formik.values.blog_description}
                                                                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                        <ErrorMessage name="blog_description" component={TextErrorMsg} />
                                                                    </div>
                                                                </div>

                                                                <div className="drag-image-video">
                                                                    <Field name="blog_image">
                                                                        {({ form, field }) => {
                                                                            return (
                                                                                <ChooseFile
                                                                                    name={field.name}
                                                                                    label={languageSelector?.placeholder?.picture}
                                                                                    handleFileChoose={(e) =>
                                                                                        form.setFieldValue(
                                                                                            field.name,
                                                                                            e.blog_image
                                                                                        )
                                                                                    }
                                                                                    error="File should be .jpg .png .jpeg and Size < 5MB"
                                                                                    accept={["jpg", "jpeg", "png"]}
                                                                                    acceptInputFile="image/jpg, image/jpeg, image/png"
                                                                                    Size={6000}
                                                                                />
                                                                            );
                                                                        }}
                                                                    </Field>
                                                                    <ErrorMessage name="blog_image" component={TextErrorMsg} />
                                                                </div>
                                                                {typeof formik.values.blog_image !== "object" ?
                                                                    <div className="blog-uploadImg-block d-block w-100">
                                                                        <img src={`${baseUrlPostGres()}${formik.values.blog_image}`} style={{ height: "25rem" }} className="img-fluid" alt="Image" />
                                                                    </div>
                                                                    :
                                                                    null
                                                                }

                                                                <div className="post-button-blog">
                                                                    <Button
                                                                        BtnText={languageSelector?.my_blogs?.cancel}
                                                                        BtnColor="primary cancel"
                                                                        BtnType="button"
                                                                        onClick={() => navigate(`/my-blog/${name}/list`)}
                                                                    />

                                                                    <Button
                                                                        BtnText={languageSelector?.my_blogs?.post}
                                                                        BtnColor="primary post"
                                                                        BtnType="submit"
                                                                        disabled={!formik.isValid || isloading}
                                                                        hasSpinner={isloading && clickBlogSubmit}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BlogEdit;
