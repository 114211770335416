import React, { useEffect, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import Groupclassescard from "../../components/Groupclassescard/Groupclassescard";
import { Tab, Tabs } from 'react-bootstrap';
import '../Instructorprofile/Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { GetWishlistAction, ResetGetWishlistAction } from '../../redux/actions/instructorAction';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { baseUrlPostGres } from '../../redux/constant';
import { isValidHttpUrl } from '../../Utils/Function';
import moment from 'moment';
import Instructorsstudent from '../../components/InstructorsStudent/Instructorsstudent';

const Studentwishlist = () => {
    const dispatch = useDispatch();

    const [key, setKey] = useState("1")
    let instructorListSelector = useSelector((state) => state?.instructor?.get_wishlist);
    const wishlistSelector = useSelector(state => state.instructor.add_favorite)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(GetWishlistAction({ type_wish: key }));
        return () => {
            dispatch(ResetGetWishlistAction());
        };
    }, [dispatch]);

    const handleSelect = (value) => {
        setKey(value === "instructors" ? "1" : "2")
        dispatch(ResetGetWishlistAction());
        if (value === "instructors") {
            dispatch(GetWishlistAction({ type_wish: "1" }));
        } else {
            dispatch(GetWishlistAction({ type_wish: "2" }));
        }
    }

    useEffect(() => {
        if (Object.keys(wishlistSelector).length > 0) {
            if (wishlistSelector.status) {
                dispatch(GetWishlistAction({ type_wish: key }));
            }
        }
    }, [wishlistSelector])

    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.wishlist?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.wishlist?.page_title} </span></p>
                </div>

                <div className='myprofile-section classesdetails-page wishlist-section'>
                    <div className='container'>
                        <div className='row g-5'>
                            <div className='col-md-12 col-lg-5 col-xl-4'>
                                <Studentsidebar />
                            </div>

                            <div className='col-md-12 col-lg-7 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.wishlist?.page_title} </h2>
                                </div>
                                <div className='booking-request-section classes-tabs-three ratingsreviews-page-instructor'>
                                    <Tabs defaultActiveKey="instructors" onSelect={handleSelect}>
                                        <Tab eventKey="instructors" title={languageSelector?.wishlist?.instructors}>
                                            <div className='wishlist-card-instructors'>
                                                <div className='row'>
                                                    {Object.keys(instructorListSelector)?.length > 0 ?
                                                        instructorListSelector?.data?.length > 0 ?
                                                            instructorListSelector?.data?.map((ele) => (
                                                                <Instructorsstudent colClass="col-12 wishlist-columns" isFav={ele.is_instruc_fav} key={ele.id} ele={{ ...ele?.instructor_user, ratingavg: ele?.ratingavg, user_rating_count: ele?.user_rating_count }} />
                                                            ))
                                                            :
                                                            <NoDataFound />
                                                        :
                                                        [1, 2, 3, 4, 5, 6].map((_, index) => {
                                                            return (
                                                                <div className='col-12' key={index}>
                                                                    <Skeleton height={300} className="card-main-section" borderRadius="2.6rem" />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="groupclasses" title={languageSelector?.wishlist?.group_classes}>
                                            <div className='wishlist-group-class-card'>
                                                <div className="row g-5">
                                                    {Object.keys(instructorListSelector)?.length > 0 ?
                                                        instructorListSelector?.data?.length > 0 ?
                                                            [...instructorListSelector?.data].sort((a, b) => (a.group_class?.created_at > b.group_class?.created_at) ? -1 : 1).map((value, index) => (
                                                                <div className="col-md-6 col-lg-12 col-xl-6 col-12" key={index}>
                                                                    <Groupclassescard
                                                                        ImageUrl={value?.group_class?.picture ? value?.group_class?.picture : "/images/Groupclasses1.png"}
                                                                        CourseName={value.group_class?.class_name}
                                                                        id={value.group_class?.id}
                                                                        HrPrice={Number(value.group_class?.price).toFixed(0)}
                                                                        TotalSeats={value.group_class?.total_seat}
                                                                        BookedSeats={value?.group_class?.total_seat - value?.group_class?.reaming_seat}
                                                                        ProfileImage={value?.group_class?.group_user?.profile_image ?
                                                                            isValidHttpUrl(value?.group_class?.group_user?.profile_image) ? value?.group_class?.group_user?.profile_image
                                                                                : `${baseUrlPostGres()}${value?.group_class?.group_user?.profile_image}`
                                                                            : "/images/blank-profile-picture.jpg"}
                                                                        isVerified={value.group_user?.verified_Sign}
                                                                        TeacherName={`${value.group_class?.group_user.first_name} ${value.group_class?.group_user.last_name}`}
                                                                        DateTime={moment(value.group_class?.created_at).format("llll")}
                                                                        CourseDescription={value?.group_class?.descriptions}
                                                                        isWishlist={value?.is_instruc_fav}
                                                                    />
                                                                </div>
                                                            ))
                                                            :
                                                            <NoDataFound />
                                                        :
                                                        [1, 2, 3, 4, 5, 6].map((_, index) => {
                                                            return (
                                                                <div className='col-md-6 col-lg-12 col-xl-6 col-12' key={index}>
                                                                    <Skeleton height={410} borderRadius="2.6rem" />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Footer />
            </div>
        </>
    );
}

export default Studentwishlist
