
import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Accordion } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import './Groupclassescard.scss';
import { baseUrlPostGres } from "../../redux/constant";
import { getUserSettings } from "../../Utils/Function";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AddFavoriteAction, ResetAddFavoriteAction } from "../../redux/actions/instructorAction";
import { GroupClassDetailAction, ResetGroupClassDetailAction } from "../../redux/actions/GroupClassActions"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from "moment";

const Groupclassescard = ({ isVerified, isWishlist, isCourseStatus, ImageUrl, CourseName, TeacherName, ProfileImage, DateTime, id, HrPrice, TotalSeats, bookedStatus, BookedSeats, CourseDescription }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [favoriteStatus, setFavoritestatus] = useState(false)

    const wishlistSelector = useSelector(state => state.instructor.add_favorite)
    const classDetailSelector = useSelector(state => state.group.group_class_detail)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const userDetail = getUserSettings()

    useEffect(() => {
        if (location?.state?.groupClassModal_id) {
            setShow(true)
            handleDetailModalOpen(location?.state?.groupClassModal_id)
            navigate("/group-classes", true)
        }
    }, [location.state])
    const handleDetailModalOpen = (idx) => {
        setShow(true)
        dispatch(GroupClassDetailAction({
            id: idx,
            user_id: userDetail?.user?.id
        }))
    };

    const handleClose = () => {
        setShow(false)
        dispatch(ResetGroupClassDetailAction())
    };

    const handleGroupClassBook = () => {
        if (classDetailSelector.AlreadyBooked) {
            toast.error("Class is already booked", { theme: "colored" })
            return <></>
        }
        if (userDetail?.user?.role === 2) {
            let data = {
                groupclassId: classDetailSelector.id,
                instructor_id: classDetailSelector.group_user.id,
                amount: classDetailSelector.price,
                category_id: classDetailSelector.categoryId
            }
            navigate(`/group-class/payment-method/${classDetailSelector.id}`, { state: data })
        } else {
            navigate("/student-login")
            toast.error("Please login as a student", { theme: "colored" })
        }
    }

    const handleWishList = (e) => {
        if (Object.keys(userDetail).length > 0) {
            setFavoritestatus(true)
            dispatch(AddFavoriteAction({
                type_wish: "2",
                groupClassId: e.target.id
            }))
        } else {
            toast.error("Please login as a student", { theme: "colored" })
        }
    };

    useEffect(() => {
        if (Object.keys(wishlistSelector).length > 0) {
            if (wishlistSelector.status) {
                dispatch(ResetAddFavoriteAction())
                setFavoritestatus(false)
            }
        }
    }, [wishlistSelector])
    return (
        <>
            <div className="groupclasses2">
                <div className="three-box-classes">
                    <div className='top-img'>
                        <img src={ImageUrl ? `${baseUrlPostGres()}${ImageUrl}` : "/images/Groupclasses1.png"} alt={CourseName} onClick={() => handleDetailModalOpen(id)} />
                        <div className="seats-section">
                            <div>
                                <p>{languageSelector?.group_class_home?.total_seats} : <span>{TotalSeats}</span></p>
                            </div>
                            <div>
                                <p>{languageSelector?.group_class_home?.booked_seats} : <span>{BookedSeats}</span></p>
                            </div>
                        </div>
                        <div className="heart-icon">
                            <div className={`btn ${favoriteStatus ? "disabled" : ""}`}>
                                <input
                                    type="checkbox"
                                    id={id}
                                    onClick={handleWishList}
                                    disabled={favoriteStatus}
                                    onChange={e => Object.keys(userDetail).length > 0 ? e.target.checked : e.target.checked = false}
                                    checked={isWishlist == 1 ? true : false}
                                />
                                <label htmlFor={id}></label>
                            </div>
                        </div>
                    </div>
                    <h3 onClick={() => handleDetailModalOpen(id)}>{CourseName}</h3>
                    <p>
                        {CourseDescription ?
                            <>{CourseDescription.split("").splice(0, 100)}{CourseDescription?.length >= 100 ? "..." : null}</>
                            :
                            null
                        }
                    </p>
                    <div className="top-to-bottom">
                        <div className="subject subject-one">
                            <div className="subject1 profile-section">
                                <div className="profile-image-box">
                                    <img src={ProfileImage} alt={TeacherName} />
                                    {isVerified == 1 && (
                                        <div className="check-icon">
                                            <img src="/images/checked-mark.png" alt="" />
                                        </div>
                                    )}
                                </div>
                                <h3>{TeacherName}</h3>
                            </div>
                            <div className="subject1 date">
                                <div className="btn">{DateTime}</div>
                            </div>
                        </div>
                        <div className="subject subject-button">
                            {isCourseStatus == 'Future' && (
                                <div className="subject1 subject1-btn">
                                    <button type="button" className="btn becomaninstructor cursor-pointer" disabled={bookedStatus || (TotalSeats == BookedSeats)} onClick={() => handleDetailModalOpen(id)}>
                                        {bookedStatus || (TotalSeats == BookedSeats) ? languageSelector?.group_class_home?.group_booked : languageSelector?.group_class_home?.book_now}
                                    </button>
                                </div>
                            )}

                            <div className="subject1 ms-auto">
                                <h3>${HrPrice}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className='center-modal class-modal group-class-detail-modal'
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> {languageSelector?.group_class_home?.class_detail} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mb-0">
                    {Object.keys(classDetailSelector).length > 0 ?
                        <>
                            <div className='classtype-section'>
                                <div className="group-modal-section">
                                    <div className="group-img">
                                        <img src={classDetailSelector?.picture ? `${baseUrlPostGres()}${classDetailSelector?.picture}` : "/images/Groupclasses2211.png"} alt={classDetailSelector?.class_name} />
                                    </div>
                                    <h2> {classDetailSelector?.class_name} </h2>
                                    <div className="user-profile">
                                        <div className="user-profile-card">
                                            <div className="profile-box">
                                                <div className="profile-img2">
                                                    <img src={classDetailSelector?.group_user?.profile_image ? `${baseUrlPostGres()}${classDetailSelector?.group_user?.profile_image}` : "/images/Groupclasses2211.png"} alt={classDetailSelector?.group_user?.first_name} />
                                                    {classDetailSelector?.group_user?.verified_Sign == 1 ? (
                                                        <div className="check-mark">
                                                            <img src="images/checked-mark.png" alt="" />
                                                        </div>
                                                    ) :
                                                        null}
                                                </div>
                                                <span> {classDetailSelector?.group_user?.first_name} {classDetailSelector?.group_user?.last_name}</span>
                                            </div>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19.978" height="19" viewBox="0 0 19.978 19">
                                                    <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M22.549,9.94l-6.9-1L12.56,2.683,9.473,8.937l-6.9,1,4.994,4.868L6.386,21.683l6.173-3.246,6.173,3.246-1.179-6.874L22.549,9.94Z" transform="translate(-2.571 -2.683)" fill="#fed756" />
                                                </svg>
                                                <span>
                                                    <b>{classDetailSelector?.ratingavg ? Number(classDetailSelector?.ratingavg).toFixed(0) : 0}/5</b>
                                                    ({classDetailSelector?.UserRatingCount ? classDetailSelector?.UserRatingCount : 0})
                                                </span>
                                            </p>
                                        </div>

                                        <div className="seats-booked-section seats-img">
                                            <div className="list-dropdown-section">
                                                {classDetailSelector?.booking_request_group?.map((value, index) => (
                                                    <Dropdown.Item className="user-card" key={index} title={value?.first_name}>{value?.first_name.charAt(0)}</Dropdown.Item>
                                                ))}
                                                {(classDetailSelector?.total_seat - classDetailSelector?.reaming_seat) > 5 ?
                                                    <Dropdown.Item className="user-card" style={{ backgroundColor: "#CCCCCC", fontSize: "12px", color: "#000" }}> +{(classDetailSelector?.total_seat - classDetailSelector?.reaming_seat) - classDetailSelector?.booking_request_group?.length} </Dropdown.Item>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <p>{classDetailSelector?.total_seat - classDetailSelector?.reaming_seat} {`${classDetailSelector?.total_seat - classDetailSelector?.reaming_seat > 1 ? languageSelector?.group_class_home?.group_seats : languageSelector?.group_class_home?.group_seat}`} {languageSelector?.group_class_home?.group_booked}</p>
                                        </div>
                                    </div>

                                    <div className="user-profile date">
                                        <div className="user-profile-card">
                                            <h3>${Number(classDetailSelector?.price).toFixed(0)}</h3>
                                        </div>
                                        <div className="seats-booked-section">
                                            <p> {moment(classDetailSelector.created_at).format("llll")} </p>
                                        </div>
                                    </div>

                                    <div className="aboutgroupclass-section">
                                        <h2> {languageSelector?.group_class_home?.about_class} </h2>
                                        <div className="user-profile">
                                            <div className="user-profile-card">
                                                <p> {languageSelector?.group_class_home?.level} </p>
                                            </div>
                                            <div className="seats-booked-section">
                                                <p> {classDetailSelector?.class_level?.class_name}</p>
                                            </div>

                                            <div className="user-profile-card">
                                                <p> {languageSelector?.group_class_home?.number_class} </p>
                                            </div>
                                            <div className="seats-booked-section">
                                                <p>{classDetailSelector?.number_of_class}</p>
                                            </div>

                                            <div className="user-profile-card">
                                                <p> {languageSelector?.group_class_home?.class_duration} </p>
                                            </div>
                                            <div className="seats-booked-section">
                                                <p>{classDetailSelector?.duration} {languageSelector?.group_class_home?.group_hour}</p>
                                                {/* Minutes */}
                                            </div>

                                            <div className="user-profile-card">
                                                <p> {languageSelector?.group_class_home?.total_seats} </p>
                                            </div>
                                            <div className="seats-booked-section">
                                                <p>{classDetailSelector?.total_seat} {languageSelector?.group_class_home?.group_student}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-section-class">
                                        <div className="questions-section">
                                            <div className="">
                                                <Accordion className="row">
                                                    <div className="col-lg-12">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header> {languageSelector?.group_class_home?.full_description} </Accordion.Header>
                                                            <Accordion.Body> {classDetailSelector?.full_description} </Accordion.Body>
                                                        </Accordion.Item>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header> {languageSelector?.group_class_home?.what_learn} </Accordion.Header>
                                                            <Accordion.Body> {classDetailSelector?.description} </Accordion.Body>
                                                        </Accordion.Item>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header> {languageSelector?.group_class_home?.class_course} </Accordion.Header>
                                                            <Accordion.Body>
                                                                <ol className="course-time-date-drop">
                                                                    {classDetailSelector.group_course_class?.length > 0 &&
                                                                        classDetailSelector.group_course_class.map((value, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-7 col-sm-12">
                                                                                            <h3>{value.topic_class}</h3>
                                                                                        </div>
                                                                                        <div className="col-md-5 col-sm-12">
                                                                                            <p>{moment(value.time).format("llll")} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ol>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </div>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal.Footer className="px-0 pt-4">
                                <div className='email-send'>
                                    <button type="button" className="btn becomaninstructor" onClick={handleGroupClassBook} disabled={classDetailSelector.AlreadyBooked || (classDetailSelector?.total_seat == (classDetailSelector?.total_seat - classDetailSelector?.reaming_seat))}>
                                        {classDetailSelector?.AlreadyBooked || (classDetailSelector?.total_seat == (classDetailSelector?.total_seat - classDetailSelector?.reaming_seat)) ? languageSelector?.group_class_home?.group_booked : languageSelector?.group_class_home?.book_now}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </>
                        :
                        <>
                            <Skeleton height={400} borderRadius="2.6rem" />

                            <Skeleton height={100} className="mt-4" borderRadius="2.6rem" />

                            <Skeleton height={100} className="mt-4 mb-5" borderRadius="2.6rem" />
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Groupclassescard

