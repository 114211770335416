import React, { useEffect } from 'react'
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import ImagePreview from '../../../components/ImagePreview';
import { useDispatch, useSelector } from 'react-redux';
import { ResetUploadLinkedinCertificateAction, UploadLinkedinCertificateAction } from '../../../redux/actions/CompleteProfileAction';
import Button from '../../../components/Buttons/Buttons';

const Certificates = ({ profileData }) => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.profile.loading);
    const certificateSelector = useSelector((state) => state.profile.upload_certificate);
    const languageSelector = useSelector(state => state.home.dynamic_language)
    
    const handleUploadCertificate = () => {
        dispatch(UploadLinkedinCertificateAction())
    }

    useEffect(() => {
        if (Object.keys(certificateSelector).length > 0) {
            if (certificateSelector.status) {
                window.open(certificateSelector?.data, "_blank");
                dispatch(ResetUploadLinkedinCertificateAction())
            }
        }
    }, [certificateSelector])
    
    return (
        profileData?.certificate_img ?
            <div className='text-center'>
                <ImagePreview file={profileData.certificate_img} imageAlt={profileData.first_name} />
                <div className='save-button'>
                    <Button
                        BtnText={languageSelector?.my_account?.upload_linkedin_button}
                        BtnColor="primary becomaninstructor"
                        BtnType="button"
                        onClick={handleUploadCertificate}
                        disabled={isLoading}
                        hasSpinner={isLoading}
                    />
                </div>
            </div>
            :
            <NoDataFound />
    )
}
export default Certificates