import {
    MANAGE_SUBSCRIPTION_LIST_ERROR, MANAGE_SUBSCRIPTION_LIST_SUCCESS, IS_LOADING,
    MANAGE_SUBSCRIPTION_LIST_STUDENT_SUCCESS, MANAGE_SUBSCRIPTION_LIST_STUDENT_ERROR
} from "../actions/Type";

const initialState = {
    manage_subscription: {},
    manage_student_subscription: {},
    loading: false,
};

export const ManagesubscriptionReducers = (
    state = initialState,
    { type, payload } = {}
) => {
    switch (type) {
        case IS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case MANAGE_SUBSCRIPTION_LIST_SUCCESS:
            return {
                ...state,
                manage_subscription: payload,
                loading: false,
            };
        case MANAGE_SUBSCRIPTION_LIST_ERROR:
            return {
                ...state,
                manage_subscription: payload,
                loading: false,
            }
        case MANAGE_SUBSCRIPTION_LIST_STUDENT_SUCCESS:
            return {
                ...state,
                manage_student_subscription: payload,
                loading: false,
            };
        case MANAGE_SUBSCRIPTION_LIST_STUDENT_ERROR:
            return {
                ...state,
                manage_student_subscription: payload,
                loading: false,
            }
        default:
            return state;
    }
};