import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Accordion } from "react-bootstrap";
import ChangePassword from '../ChangePassword/ChangePassword';
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import '../Instructorprofile/Instructorprofile.scss';
import PersonalDetails from './ProfileComponent/PersonalDetails';
import Qualifications from './ProfileComponent/Qualifications';
import TeachingDetails from './ProfileComponent/TeachingDetails';
import Availability from './ProfileComponent/Availability';
import { ProfileGetAction, ResetProfileGetAction } from '../../redux/actions/CompleteProfileAction';
import BankDetails from './ProfileComponent/BankDetails';
import Certificates from './ProfileComponent/Certificates';

const InstructorMyProfile = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.profile?.profile)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const [isAvailabilityOpen, setIsAvailabilityOpen] = useState(false);

    useEffect(() => {
        dispatch(ProfileGetAction("instructor"))
        return () => dispatch(ResetProfileGetAction())
    }, [dispatch]);
    
    const handleAddAvailabilityClick = () => {
      setIsAvailabilityOpen(true);
    };

    return (
        <>
            <div id="footer-bottom" className="instructor-profile">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.my_account?.page_title} </h1>
                    <p>Home / {languageSelector?.my_account?.page_title}  / <span> {languageSelector?.my_account?.breadcrumb_title} </span></p>
                </div>

                <div className="myprofile-section">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-md-12 col-lg-4">
                                <Instructorsidebar />
                            </div>

                            <div className="col-md-12 col-lg-8">
                                <div className="profile-top-heading">
                                    <h2> {languageSelector?.my_account?.page_sidebar_title} </h2>
                                </div>
                                <div className="my-profile-details">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0" className='change-password-box change-password-box-top mt-0 login-main-section d-block'>
                                            <Accordion.Header> {languageSelector?.my_account?.personal_details} </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tab-section-profile'>
                                                    <PersonalDetails profileData={userData} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="1" className='change-password-box change-password-box-top login-main-section d-block'>
                                            <Accordion.Header> {languageSelector?.my_account?.qualifications} </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tab-section-profile'>
                                                    <Qualifications profileData={userData} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2" className='change-password-box change-password-box-top login-main-section d-block'>
                                            <Accordion.Header> {languageSelector?.my_account?.teaching_details} </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tab-section-profile'>
                                                    <TeachingDetails profileData={userData} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3" className='change-password-box change-password-box-top login-main-section d-block'>
                                            <Accordion.Header onClick={handleAddAvailabilityClick}> {languageSelector?.my_account?.add_availability} </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tab-section-profile'>
                                                    <Availability profileData={userData} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4" className='change-password-box change-password-box-top login-main-section d-block'>
                                            <Accordion.Header> {languageSelector?.my_account?.bank_details} </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tab-section-profile'>
                                                    <BankDetails profileData={userData} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="5" className='change-password-box change-password-box-top login-main-section d-block'>
                                            <Accordion.Header> {languageSelector?.my_account?.certificates} </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tab-section-profile'>
                                                    <Certificates profileData={userData} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="6" className='change-password-box change-password-box-top login-main-section d-block'>
                                            <Accordion.Header> {languageSelector?.my_account?.change_password} </Accordion.Header>
                                            <Accordion.Body>
                                                <ChangePassword panelName="instructor" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default InstructorMyProfile;
