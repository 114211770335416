import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

const firebaseConfig = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENTID,
})

// Initialize Firebase
export const firebaseDB = firebase
export const db = firebaseConfig.firestore()
export const facebookProvider = new FacebookAuthProvider();
export const googleProvider = new GoogleAuthProvider();
export const storage = getStorage(firebaseConfig);

export const getFCPToken = async (setTokenFound) => {
    // isSupported()
    //     .then(async () => {
    const messaging = getMessaging(firebaseConfig);
    try {
        const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY });
        if (currentToken) {
            setTokenFound(currentToken);
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
    // })
    // .catch(() => {
    //     console.log("not supported");
    // })
}

export const onFirebaseMessageListener = () =>
    new Promise((resolve) => {
        const messaging = getMessaging(firebaseConfig);
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });