import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
// import Groupclassescard from "../../components/Groupclassescard/Instructorgroupclassescard";
import { Link, useNavigate } from "react-router-dom";
import "./Instructorprofile.scss";
import { useDispatch, useSelector } from "react-redux";
import { DeleteGroupClass, GroupClassDetailAction, GroupClassListMyProfile, ResetDeleteGroupClass, ResetGroupClassDetailAction } from "../../redux/actions/GroupClassActions";
import moment from "moment";
import { baseUrlPostGres } from "../../redux/constant";
import DeleteRecordModal from "../../components/DeleteRecordModal/DeleteRecordModal";
import { getUserSettings } from "../../Utils/Function";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Modal } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';

const Instructorgroupclasses = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const userDetail = getUserSettings()
    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteRecordID, setDeleteRecordID] = useState()
    const groupClassList = useSelector((state) => state.group.group_class_list_success)
    const groupClassDeleteSelector = useSelector((state) => state.group.group_class_delete)
    const isloading = useSelector((state) => state.group.loading)
    const classDetailSelector = useSelector(state => state.group.group_class_detail)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    
    useEffect(() => {
        dispatch(GroupClassListMyProfile());
    }, [dispatch]);

    const handleOpenDeleteModal = (id) => {
        setDeleteRecordID(id)
        setShowDeleteModal(true)
    }
    const handleCloseTwo = () => {
        setDeleteRecordID("")
        setShowDeleteModal(false)
    };

    const handleDeleteClass = () => {
        dispatch(DeleteGroupClass(deleteRecordID))
    }

    useEffect(() => {
        if (Object.keys(groupClassDeleteSelector).length > 0) {
            if (groupClassDeleteSelector.status) {
                setShowDeleteModal(false)
                dispatch(GroupClassListMyProfile());
                dispatch(ResetDeleteGroupClass())
            }
        }
    }, [groupClassDeleteSelector])

    const handleDetailModalOpen = (idx) => {
        setShow(true)
        dispatch(GroupClassDetailAction({
            id: idx,
            user_id: userDetail?.user?.id
        }))
    };

    const handleClose = () => {
        setShow(false)
        dispatch(ResetGroupClassDetailAction())
    };

    return (
        <>
            <div id="footer-bottom" className="instructor-profile">
                <Header />
                <div className="instru-bg-image">
                    <h1>{languageSelector?.group_class_side?.page_title}</h1>
                    <p>
                        Home / <span> {languageSelector?.group_class_side?.page_title} </span>
                    </p>
                </div>

                <div className="myprofile-section classesdetails-page blog-page-add">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-md-12 col-lg-4">
                                <Instructorsidebar />
                            </div>

                            <div className="col-md-12 col-lg-8">
                                <div className="profile-top-heading addnewblog-section">
                                    <div>
                                        <h2>{languageSelector?.group_class_side?.page_title}</h2>
                                    </div>
                                    <div className="addnewblog">
                                        <Link className="btn" to="/instructor/add-group-class"> {languageSelector?.group_class_side?.add_group_class} </Link>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    {groupClassList?.length > 0 ?
                                        <div className="groupclasses-card">
                                            <div className="row g-5">
                                                {[...groupClassList].sort((a, b) => (a.created_at > b.created_at) ? -1 : 1).map((value, index) => (
                                                    <div className="col-md-6 col-lg-12 col-xl-6 col-12" key={index}>
                                                        <div className="groupclasses2">
                                                            <div className="three-box-classes">
                                                                <div className='top-img'>
                                                                    <img src={value.picture ? `${baseUrlPostGres()}${value.picture}` : "/images/Groupclasses1.png"} alt={value?.class_name} onClick={() => handleDetailModalOpen(value.id)} />
                                                                    <div className="seats-section">
                                                                        <div>
                                                                            <p>{languageSelector?.group_class_side?.total_seats} : <span>{value?.total_seat}</span></p>
                                                                        </div>
                                                                        <div>
                                                                            <p>{languageSelector?.group_class_side?.booked_seats} : <span>{value?.total_seat - value?.reaming_seat}</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="editdelete">
                                                                        {(value?.total_seat - value?.reaming_seat) == 0 ?
                                                                            <button className="btn edit" onClick={() => navigate(`/instructor/group-class/edit/${value.id}`)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.742" viewBox="0 0 15.742 15.742">
                                                                                    <path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,16.959v3.279H7.779l9.671-9.671L14.171,7.288ZM19.986,8.031a.871.871,0,0,0,0-1.233L17.94,4.752a.871.871,0,0,0-1.233,0l-1.6,1.6,3.279,3.279Z" transform="translate(-4.5 -4.496)" fill="#fff" />
                                                                                </svg>
                                                                            </button>
                                                                            :
                                                                            null
                                                                        }
                                                                        <button className="btn" onClick={() => handleOpenDeleteModal(value.id)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.138" height="15.606" viewBox="0 0 12.138 15.606">
                                                                                <path id="Icon_material-delete-forever" data-name="Icon material-delete-forever" d="M8.367,18.372A1.739,1.739,0,0,0,10.1,20.106h6.936a1.739,1.739,0,0,0,1.734-1.734V7.968H8.367ZM10.5,12.2l1.222-1.222,1.847,1.838,1.838-1.838L16.629,12.2l-1.838,1.838,1.838,1.838L15.407,17.1l-1.838-1.838L11.731,17.1l-1.222-1.222,1.838-1.838Zm6.1-6.832L15.736,4.5H11.4l-.867.867H7.5V7.1H19.638V5.367Z" transform="translate(-7.5 -4.5)" fill="#fff" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <h3 onClick={() => handleDetailModalOpen(value.id)}>{value?.class_name}</h3>
                                                                <p>{value?.description.split("").splice(0, 100)}{value?.description?.length >= 100 ? "..." : null}</p>
                                                                <div className="subject">
                                                                    <div className="subject1">
                                                                        <h3 className="mt-0">${value?.price}</h3>
                                                                    </div>
                                                                    <div className="subject1 date">
                                                                        <div className="btn"><span>{moment(value.created_at).format("llll")}</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        :
                                        <div className="blog-top-content">
                                            <p> {languageSelector?.group_class_side?.empty_group_class} </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <DeleteRecordModal
                show={showDeleteModal}
                onHide={handleCloseTwo}
                loading={isloading}
                onSubmit={handleDeleteClass}
            />

            <Modal
                className='center-modal class-modal group-class-detail-modal'
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> {languageSelector?.group_class_side?.group_class_detail} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mb-0">
                    {Object.keys(classDetailSelector).length > 0 ?
                        <>
                            <div className='classtype-section'>
                                <div className="group-modal-section">
                                    <div className="group-img">
                                        <img src={classDetailSelector?.picture ? `${baseUrlPostGres()}${classDetailSelector?.picture}` : "/images/Groupclasses2211.png"} alt={classDetailSelector?.class_name} />
                                    </div>
                                    <h2> {classDetailSelector?.class_name} </h2>
                                    <div className="user-profile">
                                        <div className="user-profile-card">
                                            <div className="profile-box">
                                                <div className="profile-img2">
                                                    <img src={classDetailSelector?.group_user?.profile_image ? `${baseUrlPostGres()}${classDetailSelector?.group_user?.profile_image}` : "/images/Groupclasses2211.png"} alt={classDetailSelector?.group_user?.first_name} />
                                                    {classDetailSelector?.group_user?.verified_Sign == 1 ? (
                                                        <div className="check-mark">
                                                            <img src="images/checked-mark.png" alt="" />
                                                        </div>
                                                    ) :
                                                        null}
                                                </div>
                                                <span> {classDetailSelector?.group_user?.first_name} {classDetailSelector?.group_user?.last_name}</span>
                                            </div>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19.978" height="19" viewBox="0 0 19.978 19">
                                                    <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M22.549,9.94l-6.9-1L12.56,2.683,9.473,8.937l-6.9,1,4.994,4.868L6.386,21.683l6.173-3.246,6.173,3.246-1.179-6.874L22.549,9.94Z" transform="translate(-2.571 -2.683)" fill="#fed756" />
                                                </svg>
                                                <span>
                                                    <b>{classDetailSelector?.ratingavg ? Number(classDetailSelector?.ratingavg).toFixed(0) : 0}/5</b>
                                                    ({classDetailSelector?.UserRatingCount ? classDetailSelector?.UserRatingCount : 0})
                                                </span>
                                            </p>
                                        </div>

                                        <div className="seats-booked-section seats-img">
                                            <div className="list-dropdown-section">
                                                {classDetailSelector?.booking_request_group?.map((value, index) => (
                                                    <Dropdown.Item className="user-card" key={index} title={value?.first_name}>{value?.first_name.charAt(0)}</Dropdown.Item>
                                                ))}
                                                {(classDetailSelector?.total_seat - classDetailSelector?.reaming_seat) > 5 ?
                                                    <Dropdown.Item className="user-card" style={{ backgroundColor: "#CCCCCC", fontSize: "12px", color: "#000" }}> +{(classDetailSelector?.total_seat - classDetailSelector?.reaming_seat) - classDetailSelector?.booking_request_group?.length} </Dropdown.Item>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <p>{classDetailSelector?.total_seat - classDetailSelector?.reaming_seat} {`${classDetailSelector?.total_seat - classDetailSelector?.reaming_seat > 1 ? languageSelector?.group_class_side?.seats : languageSelector?.group_class_side?.seat}`} {languageSelector?.group_class_side?.booked}</p>
                                        </div>
                                    </div>

                                    <div className="user-profile date">
                                        <div className="user-profile-card">
                                            <h3>${Number(classDetailSelector?.price).toFixed(0)}</h3>
                                        </div>
                                        <div className="seats-booked-section">
                                            <p> {moment(classDetailSelector.created_at).format("llll")} </p>
                                        </div>
                                    </div>

                                    <div className="aboutgroupclass-section">
                                        <h2> {languageSelector?.group_class_side?.about_group_class} </h2>
                                        <div className="user-profile">
                                            <div className="user-profile-card">
                                                <p> {languageSelector?.group_class_side?.level} </p>
                                            </div>
                                            <div className="seats-booked-section">
                                                <p> {classDetailSelector?.class_level?.class_name}</p>
                                            </div>

                                            <div className="user-profile-card">
                                                <p> {languageSelector?.group_class_side?.number_class} </p>
                                            </div>
                                            <div className="seats-booked-section">
                                                <p>{classDetailSelector?.number_of_class}</p>
                                            </div>

                                            <div className="user-profile-card">
                                                <p> {languageSelector?.group_class_side?.class_duration} </p>
                                            </div>
                                            <div className="seats-booked-section">
                                                <p>{classDetailSelector?.duration} {languageSelector?.group_class_side?.hour}</p>
                                                {/* Minutes */}
                                            </div>

                                            <div className="user-profile-card">
                                                <p> {languageSelector?.group_class_side?.total_seats} </p>
                                            </div>
                                            <div className="seats-booked-section">
                                                <p>{classDetailSelector?.total_seat} {languageSelector?.group_class_side?.students}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-section-class">
                                        <div className="questions-section">
                                            <div className="">
                                                <Accordion className="row">
                                                    <div className="col-lg-12">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header> {languageSelector?.group_class_side?.full_des} </Accordion.Header>
                                                            <Accordion.Body> {classDetailSelector?.full_description} </Accordion.Body>
                                                        </Accordion.Item>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header> {languageSelector?.group_class_side?.what_you_will_learn} </Accordion.Header>
                                                            <Accordion.Body> {classDetailSelector?.description} </Accordion.Body>
                                                        </Accordion.Item>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header> {languageSelector?.group_class_side?.class_course} </Accordion.Header>
                                                            <Accordion.Body>
                                                                <ol className="course-time-date-drop">
                                                                    {classDetailSelector.group_course_class?.length > 0 &&
                                                                        classDetailSelector.group_course_class.map((value, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-7 col-sm-12">
                                                                                            <h3>{value.topic_class}</h3>
                                                                                        </div>
                                                                                        <div className="col-md-5 col-sm-12">
                                                                                            <p> {moment(value.time).format("llll")} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ol>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </div>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <Skeleton height={400} borderRadius="2.6rem" />

                            <Skeleton height={100} className="mt-4" borderRadius="2.6rem" />

                            <Skeleton height={100} className="mt-4 mb-5" borderRadius="2.6rem" />
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Instructorgroupclasses;
