import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { secondsToHms } from '../../Utils/Function';

const TimeDuration = ({ setTimeStatus, timerData, isHours = true }) => {
    const [update, setUpdate] = useState(true);
    var time = (timerData == undefined || timerData == null) ? '00:00:00' : timerData;
    var now = (moment(new Date()))
    if (timerData) {
        var half = timerData.split(" ")[1]
        var hours = half.split(".")[0]
        var date = moment.utc(timerData).format("YYYY-MM-DD")
        var finalDateTime = `${date} ${hours}`
    }

    var endTime = moment(finalDateTime)
    var duration = moment.duration(endTime.diff(now));
    var timeDiff = Math.round(duration.asSeconds())
    if (Math.sign(timeDiff) != -1) {
        time = secondsToHms(timeDiff)
    } else {
        time = secondsToHms(0)
    }

    useEffect(() => {
        if (timeDiff <= 0) {
            setTimeStatus(true)
        }else {
            let myInterval = setInterval(() => {
                setUpdate(!update)
            }, 1000);
            return () => clearInterval(myInterval)
        }
    });
    
    return (
        <>
            {isHours ? <>{time.split(':', 3)[0] + 'h'}:</> : null}
            {time.split(':', 3)[1] + 'm'}:
            {time.split(':', 3)[2] + 's'}
        </>
    );
};

export default TimeDuration;