import React, { useState, useRef, useEffect } from 'react';
import { formatDate } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { INITIAL_EVENTS, createEventId, setEventGuid  } from './../../../Utils/event-utils';
import Button from "./../../../components/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { InstructorProfileAvailabilityAction, InstructorProfileAvailabilityFind, FetchEvents } from "../../../redux/actions/CompleteProfileAction";

export default function DemoApp() {
  const dispatch = useDispatch();
  const [currentEvents, setCurrentEvents] = useState([]);
  const calendarRef = useRef(null);
  const isLoading = useSelector((state) => state.profile.loading);
  const ProfileAvailabilitySelector = useSelector((state) => state.profile.profile_availability);
  const languageSelector = useSelector(state => state.home.dynamic_language)
  const getEventData = useSelector(state => state.profile?.instructor_profile_availability);
  const getLastEvent = useSelector(state => state.profile?.instructor_fetch_availability_events);
  const addedEventIds = useRef(new Set());

  useEffect(() => {
    dispatch(FetchEvents());
  }, [dispatch]);

   useEffect(() => {
    if (getLastEvent && getLastEvent.event) {
      setEventGuid(getLastEvent.event + 1);
    }
  }, [getLastEvent]);

  useEffect(() => {
    dispatch(InstructorProfileAvailabilityFind());
  }, [dispatch]);

  useEffect(() => {
    addEvent();
  }, [getEventData]);

  function addEvent() {
    const calendarApi = calendarRef.current.getApi();

    if (getEventData?.data?.length > 0) {
      getEventData.data.forEach((value) => {

        if (!addedEventIds.current.has(value.event_id)) {
          // Create a new event object
          const newEvent = {
            //id: value.event_id || createEventId(),
            id: value.event_id,
            //title: value.title,
            start: value.start_time, // Set the start time to the current date and time
            end: value.end_time,
            allDay: false // Set to false if it's not an all-day event
          };

          // Add the event to the calendar
          calendarApi.addEvent(newEvent);
          addedEventIds.current.add(value.event_id);
        }
      });
    }
  }

  function handleDateSelect(selectInfo) {
    //let title = prompt('Please enter a new title for your event');
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    let eventId = createEventId();

    //if (title) {
      calendarApi.addEvent({
        id: eventId,
        //title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });

      /*const startDate = formatDate(selectInfo.start);
        const startTime = selectInfo.start;
        const endTime   = selectInfo.end;*/

      // Format start time
      /*const startHours = startTime.getHours();
        const startMinutes = startTime.getMinutes();
        const formattedStartTime = `${startHours % 12 === 0 ? 12 : startHours % 12}.${startMinutes < 10 ? '0' : ''}${startMinutes} ${startHours >= 12 ? 'PM' : 'AM'}`;*/

      // Format end time
      /*const endHours = endTime.getHours();
        const endMinutes = endTime.getMinutes();
        const formattedEndTime = `${endHours % 12 === 0 ? 12 : endHours % 12}.${endMinutes < 10 ? '0' : ''}${endMinutes} ${endHours >= 12 ? 'PM' : 'AM'}`;*/

      // Combine date and time
      /* const timeRange = `${formattedStartTime} to ${formattedEndTime}`;*/

      const eventData = {
        id: eventId,
        //title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
        action: 'add',
        //date: startDate,
        //time: timeRange
      };

      dispatch(InstructorProfileAvailabilityAction(eventData));
    //}
  }

  function handleEventClick(clickInfo) {
    //if (window.confirm(`Are you sure you want to delete this event?`)) { }
    const removeEventData = {
      id: clickInfo.event.id,
      action: 'delete',
    };

    dispatch(InstructorProfileAvailabilityAction(removeEventData));
    clickInfo.event.remove();
  }

  function handleEvents(events) {
    setCurrentEvents(events);
  }

  // function handleSaveAllEvents() {
  //   const calendarApi = calendarRef.current.getApi();
  //   const allEvents = calendarApi.getEvents();
  //   //setCurrentEvents(allEvents);

  //   // Iterate over each event and log the start and end times
  //   allEvents.forEach(event => {
  //       if (event.start && event.end) {
  //           const startTime = event.start;
  //           const endTime = event.end;

  //           // Format start time
  //           const startHours = startTime.getHours();
  //           const startMinutes = startTime.getMinutes();
  //           const formattedStartTime = `${startHours % 12 === 0 ? 12 : startHours % 12}.${startMinutes < 10 ? '0' : ''}${startMinutes} ${startHours >= 12 ? 'PM' : 'AM'}`;

  //           // Format end time
  //           const endHours = endTime.getHours();
  //           const endMinutes = endTime.getMinutes();
  //           const formattedEndTime = `${endHours % 12 === 0 ? 12 : endHours % 12}.${endMinutes < 10 ? '0' : ''}${endMinutes} ${endHours >= 12 ? 'PM' : 'AM'}`;

  //           // Combine start and end times
  //           const timeRange = `${formattedStartTime} To ${formattedEndTime}`;

  //           console.log('Event:', event.title);
  //           console.log('Time:', timeRange);
  //       } else {
  //           console.error('Invalid event:', event);
  //       }
  //   });
  // }

  /*this final*/
  // function handleSaveAllEvents() {
  //   const calendarApi = calendarRef.current.getApi();
  //   const allEvents = calendarApi.getEvents();

  //   // Create an array to store event data
  //   const eventDataArray = [];

  //   // Iterate over each event and log the start and end times
  //   allEvents.forEach(event => {
  //       if (event.start && event.end) {
  //           const startDate = formatDate(event.start);
  //           //const startDate = event.start.toLocaleDateString();
  //           //const endDate = event.end.toLocaleDateString();

  //           const startTime = event.start;
  //           const endTime = event.end;

  //           // Format start time
  //           const startHours = startTime.getHours();
  //           const startMinutes = startTime.getMinutes();
  //           const formattedStartTime = `${startHours % 12 === 0 ? 12 : startHours % 12}.${startMinutes < 10 ? '0' : ''}${startMinutes} ${startHours >= 12 ? 'PM' : 'AM'}`;

  //           // Format end time
  //           const endHours = endTime.getHours();
  //           const endMinutes = endTime.getMinutes();
  //           const formattedEndTime = `${endHours % 12 === 0 ? 12 : endHours % 12}.${endMinutes < 10 ? '0' : ''}${endMinutes} ${endHours >= 12 ? 'PM' : 'AM'}`;

  //           // Combine date and time
  //           const timeRange = `${formattedStartTime} to ${formattedEndTime}`;

  //           // Push event data into the array
  //           eventDataArray.push({
  //               title: event.title,
  //               date: startDate,
  //               time: timeRange
  //           });
  //       } else {
  //           console.error('Invalid event:', event);
  //       }
  //   });

  //   console.log('Event data:', eventDataArray);
  // }

  // Function to format date as YYYY-MM-DD
  // function formatDate(date) {
  //   const year  = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const day   = date.getDate().toString().padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }

  return (
    <div className='demo-app'>
      <div className='demo-app-main'>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev',
            center: 'title',
            //right: 'next today'
            right: 'next'
          }}
          initialView='timeGridWeek'
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          initialEvents={INITIAL_EVENTS}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          eventsSet={handleEvents}
          slotDuration={'01:00:00'}
        />
      </div>
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

/// function Sidebar({ currentEvents, handleSaveAllEvents }) {
//   return (
//     <div className='demo-app-sidebar'>
//       <div className='demo-app-sidebar-section'>
//         <button onClick={handleSaveAllEvents}>Save</button>
//       </div>
//     </div>
//   );
// }

// function SidebarEvent({ event }) {
//   return (
//     <li key={event.id}>
//       <b>{formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
//       <i>{event.title}</i>
//     </li>
//   );
// }
