const server = {
  localBaseUrl: 'http://127.0.0.1:8000',
  liveBaseUrl: 'https://api.hireinstructor.com',
  baseMongoUrl: 'https://admin-hireinstructor.sandboxdevelopment.in',
}
export function baseUrlPostGres() {
  const hostname = window.location.hostname;

  if (hostname === '127.0.0.1' || hostname === 'localhost') {
    return server.localBaseUrl;
  } else {
    return server.liveBaseUrl;
  }

  return server.baseUrl;
}