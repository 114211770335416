import axios from 'axios';
import { toast } from 'react-toastify';
import { MANAGE_SUBSCRIPTION_LIST_ERROR, MANAGE_SUBSCRIPTION_LIST_SUCCESS, IS_LOADING, MANAGE_SUBSCRIPTION_LIST_STUDENT_SUCCESS, MANAGE_SUBSCRIPTION_LIST_STUDENT_ERROR } from './Type';
import 'react-toastify/dist/ReactToastify.css';
import { apiHeader } from '../../redux/actions/ApiHeader';
import { baseUrlPostGres } from '../../redux/constant';

toast.configure();

export const InstructorManageSubscription = (data) => async (dispatch) => {
    dispatch({ type: IS_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/manage-subscription`, data, {
            headers: apiHeader()
        })
        .then(response => {
            dispatch({
                type: MANAGE_SUBSCRIPTION_LIST_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response.data, { theme: "colored" })
            dispatch({
                type: MANAGE_SUBSCRIPTION_LIST_ERROR,
                payload: errors.response.data
            })
        })
}

export const StudentManageSubscription = (data) => async (dispatch) => {
    dispatch({ type: IS_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/manage-subscription-student`, data, {
            headers: apiHeader()
        })
        .then(response => {
            dispatch({
                type: MANAGE_SUBSCRIPTION_LIST_STUDENT_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response.data, { theme: "colored" })
            dispatch({
                type: MANAGE_SUBSCRIPTION_LIST_STUDENT_ERROR,
                payload: errors.response.data
            })
        })
}