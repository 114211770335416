export const days = [
    {
        id: 1,
        name: "monday",
        title: "Monday",
        day_selected: false,
        shifts: [
            {
                shift_id: 1,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Morning",
                id: 1,
                shift_time: "8.00 AM To 12.00 PM",
                slots: [
                    {
                        slot_id: 1,
                        selected: false,
                        slot_name: "8.00 AM To 9.00 AM",
                        slot_title: "8.00 AM To 9.00 AM",
                    },
                    {
                        slot_id: 2,
                        selected: false,
                        slot_name: "9.00 AM To 10.00 AM",
                        slot_title: "9.00 AM To 10.00 AM",
                    },
                    {
                        slot_id: 3,
                        selected: false,
                        slot_name: "10.00 AM To 11.00 AM",
                        slot_title: "10.00 AM To 11.00 AM",
                    },
                    {
                        slot_id: 4,
                        selected: false,
                        slot_name: "11.00 AM To 12.00 AM",
                        slot_title: "11.00 AM To 12.00 AM",
                    },
                ],
            },
            {
                shift_id: 2,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Afternoon",
                shift_time: "12.00 PM To 5.00 PM",
                id: 2,
                slots: [
                    {
                        slot_id: 5,
                        selected: false,
                        slot_name: "12:00 PM To 01:00 PM",
                        slot_title: "12:00 PM To 01:00 PM",
                    },
                    {
                        slot_id: 6,
                        selected: false,
                        slot_name: "1:00 PM To 02:00 PM",
                        slot_title: "1:00 PM To 02:00 PM",
                    },
                    {
                        slot_id: 7,
                        selected: false,
                        slot_name: "2:00 PM To 03:00 PM",
                        slot_title: "2:00 PM To 03:00 PM",
                    },
                    {
                        slot_id: 8,
                        selected: false,
                        slot_name: "3:00 PM To 04:00 PM",
                        slot_title: "3:00 PM To 04:00 PM",
                    },
                    {
                        slot_id: 9,
                        selected: false,
                        slot_name: "4:00 PM To 05:00 PM",
                        slot_title: "4:00 PM To 05:00 PM",
                    },
                ],
            },
            {
                shift_id: 3,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Evening",
                shift_time: "5.00 PM To 9.00 PM",
                id: 3,
                slots: [
                    {
                        slot_id: 10,
                        selected: false,
                        slot_name: "5.00 PM To 6.00 PM",
                        slot_title: "5.00 PM To 6.00 PM",
                    },
                    {
                        slot_id: 11,
                        selected: false,
                        slot_name: "6.00 PM To 7.00 PM",
                        slot_title: "6.00 PM To 7.00 PM",
                    },
                    {
                        slot_id: 12,
                        selected: false,
                        slot_name: "7.00 PM To 8.00 PM",
                        slot_title: "7.00 PM To 8.00 PM",
                    },
                    {
                        slot_id: 13,
                        selected: false,
                        slot_name: "8.00 PM To 9.00 PM",
                        slot_title: "8.00 PM To 9.00 PM",
                    },
                ],
            },
        ],
    },
    {
        id: 2, name: "tuesday", title: "Tuesday",
        day_selected: false,
        shifts: [
            {
                shift_id: 4,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Morning",
                shift_time: "8.00 AM To 12.00 PM",
                id: 1,
                slots: [
                    {
                        slot_id: 14,
                        selected: false,
                        slot_name: "8.00 AM To 9.00 AM",
                        slot_title: "8.00 AM To 9.00 AM",
                    },
                    {
                        slot_id: 15,
                        selected: false,
                        slot_name: "9.00 AM To 10.00 AM",
                        slot_title: "9.00 AM To 10.00 AM",
                    },
                    {
                        slot_id: 16,
                        selected: false,
                        slot_name: "10.00 AM To 11.00 AM",
                        slot_title: "10.00 AM To 11.00 AM",
                    },
                    {
                        slot_id: 17,
                        selected: false,
                        slot_name: "11.00 AM To 12.00 AM",
                        slot_title: "11.00 AM To 12.00 AM",
                    },
                ],
            },
            {
                shift_id: 5,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Afternoon",
                shift_time: "12.00 PM To 5.00 PM",
                id: 2,
                slots: [
                    {
                        slot_id: 18,
                        selected: false,
                        slot_name: "12:00 PM To 01:00 PM",
                        slot_title: "12:00 PM To 01:00 PM",
                    },
                    {
                        slot_id: 19,
                        selected: false,
                        slot_name: "1:00 PM To 02:00 PM",
                        slot_title: "1:00 PM To 02:00 PM",
                    },
                    {
                        slot_id: 20,
                        selected: false,
                        slot_name: "2:00 PM To 03:00 PM",
                        slot_title: "2:00 PM To 03:00 PM",
                    },
                    {
                        slot_id: 21,
                        selected: false,
                        slot_name: "3:00 PM To 04:00 PM",
                        slot_title: "3:00 PM To 04:00 PM",
                    },
                    {
                        slot_id: 22,
                        selected: false,
                        slot_name: "4:00 PM To 05:00 PM",
                        slot_title: "4:00 PM To 05:00 PM",
                    },
                ],
            },
            {
                shift_id: 6,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Evening",
                shift_time: "5.00 PM To 9.00 PM",
                id: 3,
                slots: [
                    {
                        slot_id: 23,
                        selected: false,
                        slot_name: "5.00 PM To 6.00 PM",
                        slot_title: "5.00 PM To 6.00 PM",
                    },
                    {
                        slot_id: 24,
                        selected: false,
                        slot_name: "6.00 PM To 7.00 PM",
                        slot_title: "6.00 PM To 7.00 PM",
                    },
                    {
                        slot_id: 25,
                        selected: false,
                        slot_name: "7.00 PM To 8.00 PM",
                        slot_title: "7.00 PM To 8.00 PM",
                    },
                    {
                        slot_id: 26,
                        selected: false,
                        slot_name: "8.00 PM To 9.00 PM",
                        slot_title: "8.00 PM To 9.00 PM",
                    },
                ],
            },
        ],
    },
    {
        id: 3, name: "wednesday", title: "Wednesday",
        day_selected: false,
        shifts: [
            {
                shift_id: 7,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Morning",
                shift_time: "8.00 AM To 12.00 PM",
                id: 1,
                slots: [
                    {
                        slot_id: 27,
                        selected: false,
                        slot_name: "8.00 AM To 9.00 AM",
                        slot_title: "8.00 AM To 9.00 AM",
                    },
                    {
                        slot_id: 28,
                        selected: false,
                        slot_name: "9.00 AM To 10.00 AM",
                        slot_title: "9.00 AM To 10.00 AM",
                    },
                    {
                        slot_id: 29,
                        selected: false,
                        slot_name: "10.00 AM To 11.00 AM",
                        slot_title: "10.00 AM To 11.00 AM",
                    },
                    {
                        slot_id: 30,
                        selected: false,
                        slot_name: "11.00 AM To 12.00 AM",
                        slot_title: "11.00 AM To 12.00 AM",
                    },
                ],
            },
            {
                shift_id: 8,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Afternoon",
                shift_time: "12.00 PM To 5.00 PM",
                id: 2,
                slots: [
                    {
                        slot_id: 31,
                        selected: false,
                        slot_name: "12:00 PM To 01:00 PM",
                        slot_title: "12:00 PM To 01:00 PM",
                    },
                    {
                        slot_id: 32,
                        selected: false,
                        slot_name: "1:00 PM To 02:00 PM",
                        slot_title: "1:00 PM To 02:00 PM",
                    },
                    {
                        slot_id: 33,
                        selected: false,
                        slot_name: "2:00 PM To 03:00 PM",
                        slot_title: "2:00 PM To 03:00 PM",
                    },
                    {
                        slot_id: 34,
                        selected: false,
                        slot_name: "3:00 PM To 04:00 PM",
                        slot_title: "3:00 PM To 04:00 PM",
                    },
                    {
                        slot_id: 35,
                        selected: false,
                        slot_name: "4:00 PM To 05:00 PM",
                        slot_title: "4:00 PM To 05:00 PM",
                    },
                ],
            },
            {
                shift_id: 9,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Evening",
                shift_time: "5.00 PM To 9.00 PM",
                id: 3,
                slots: [
                    {
                        slot_id: 36,
                        selected: false,
                        slot_name: "5.00 PM To 6.00 PM",
                        slot_title: "5.00 PM To 6.00 PM",
                    },
                    {
                        slot_id: 37,
                        selected: false,
                        slot_name: "6.00 PM To 7.00 PM",
                        slot_title: "6.00 PM To 7.00 PM",
                    },
                    {
                        slot_id: 38,
                        selected: false,
                        slot_name: "7.00 PM To 8.00 PM",
                        slot_title: "7.00 PM To 8.00 PM",
                    },
                    {
                        slot_id: 39,
                        selected: false,
                        slot_name: "8.00 PM To 9.00 PM",
                        slot_title: "8.00 PM To 9.00 PM",
                    },
                ],
            },
        ],
    },
    {
        id: 4, name: "thursday", title: "Thursday",
        day_selected: false,
        shifts: [
            {
                shift_id: 10,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Morning",
                id: 1,
                shift_time: "8.00 AM To 12.00 PM",
                slots: [
                    {
                        slot_id: 40,
                        selected: false,
                        slot_name: "8.00 AM To 9.00 AM",
                        slot_title: "8.00 AM To 9.00 AM",
                    },
                    {
                        slot_id: 41,
                        selected: false,
                        slot_name: "9.00 AM To 10.00 AM",
                        slot_title: "9.00 AM To 10.00 AM",
                    },
                    {
                        slot_id: 42,
                        selected: false,
                        slot_name: "10.00 AM To 11.00 AM",
                        slot_title: "10.00 AM To 11.00 AM",
                    },
                    {
                        slot_id: 4,
                        selected: false,
                        slot_name: "11.00 AM To 12.00 AM",
                        slot_title: "11.00 AM To 12.00 AM",
                    },
                ],
            },
            {
                shift_id: 11,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Afternoon",
                id: 2,
                shift_time: "12.00 PM To 5.00 PM",
                slots: [
                    {
                        slot_id: 43,
                        selected: false,
                        slot_name: "12:00 PM To 01:00 PM",
                        slot_title: "12:00 PM To 01:00 PM",
                    },
                    {
                        slot_id: 44,
                        selected: false,
                        slot_name: "1:00 PM To 02:00 PM",
                        slot_title: "1:00 PM To 02:00 PM",
                    },
                    {
                        slot_id: 45,
                        selected: false,
                        slot_name: "2:00 PM To 03:00 PM",
                        slot_title: "2:00 PM To 03:00 PM",
                    },
                    {
                        slot_id: 46,
                        selected: false,
                        slot_name: "3:00 PM To 04:00 PM",
                        slot_title: "3:00 PM To 04:00 PM",
                    },
                    {
                        slot_id: 47,
                        selected: false,
                        slot_name: "4:00 PM To 05:00 PM",
                        slot_title: "4:00 PM To 05:00 PM",
                    },
                ],
            },
            {
                shift_id: 12,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Evening",
                id: 3,
                shift_time: "5.00 PM To 9.00 PM",
                slots: [
                    {
                        slot_id: 48,
                        selected: false,
                        slot_name: "5.00 PM To 6.00 PM",
                        slot_title: "5.00 PM To 6.00 PM",
                    },
                    {
                        slot_id: 49,
                        selected: false,
                        slot_name: "6.00 PM To 7.00 PM",
                        slot_title: "6.00 PM To 7.00 PM",
                    },
                    {
                        slot_id: 50,
                        selected: false,
                        slot_name: "7.00 PM To 8.00 PM",
                        slot_title: "7.00 PM To 8.00 PM",
                    },
                    {
                        slot_id: 51,
                        selected: false,
                        slot_name: "8.00 PM To 9.00 PM",
                        slot_title: "8.00 PM To 9.00 PM",
                    },
                ],
            },
        ],
    },
    {
        id: 5, name: "friday", title: "Friday",
        day_selected: false,
        shifts: [
            {
                shift_id: 13,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Morning",
                shift_time: "8.00 AM To 12.00 PM",
                id: 1,
                slots: [
                    {
                        slot_id: 52,
                        selected: false,
                        slot_name: "8.00 AM To 9.00 AM",
                        slot_title: "8.00 AM To 9.00 AM",
                    },
                    {
                        slot_id: 53,
                        selected: false,
                        slot_name: "9.00 AM To 10.00 AM",
                        slot_title: "9.00 AM To 10.00 AM",
                    },
                    {
                        slot_id: 54,
                        selected: false,
                        slot_name: "10.00 AM To 11.00 AM",
                        slot_title: "10.00 AM To 11.00 AM",
                    },
                    {
                        slot_id: 55,
                        selected: false,
                        slot_name: "11.00 AM To 12.00 AM",
                        slot_title: "11.00 AM To 12.00 AM",
                    },
                ],
            },
            {
                shift_id: 14,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Afternoon",
                shift_time: "12.00 PM To 5.00 PM",
                id: 2,
                slots: [
                    {
                        slot_id: 56,
                        selected: false,
                        slot_name: "12:00 PM To 01:00 PM",
                        slot_title: "12:00 PM To 01:00 PM",
                    },
                    {
                        slot_id: 57,
                        selected: false,
                        slot_name: "1:00 PM To 02:00 PM",
                        slot_title: "1:00 PM To 02:00 PM",
                    },
                    {
                        slot_id: 58,
                        selected: false,
                        slot_name: "2:00 PM To 03:00 PM",
                        slot_title: "2:00 PM To 03:00 PM",
                    },
                    {
                        slot_id: 59,
                        selected: false,
                        slot_name: "3:00 PM To 04:00 PM",
                        slot_title: "3:00 PM To 04:00 PM",
                    },
                    {
                        slot_id: 60,
                        selected: false,
                        slot_name: "4:00 PM To 05:00 PM",
                        slot_title: "4:00 PM To 05:00 PM",
                    },
                ],
            },
            {
                shift_id: 15,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Evening",
                shift_time: "5.00 PM To 9.00 PM",
                id: 3,
                slots: [
                    {
                        slot_id: 61,
                        selected: false,
                        slot_name: "5.00 PM To 6.00 PM",
                        slot_title: "5.00 PM To 6.00 PM",
                    },
                    {
                        slot_id: 62,
                        selected: false,
                        slot_name: "6.00 PM To 7.00 PM",
                        slot_title: "6.00 PM To 7.00 PM",
                    },
                    {
                        slot_id: 63,
                        selected: false,
                        slot_name: "7.00 PM To 8.00 PM",
                        slot_title: "7.00 PM To 8.00 PM",
                    },
                    {
                        slot_id: 64,
                        selected: false,
                        slot_name: "8.00 PM To 9.00 PM",
                        slot_title: "8.00 PM To 9.00 PM",
                    },
                ],
            },
        ],
    },
    {
        id: 6, name: "saturday", title: "Saturday",
        day_selected: false,
        shifts: [
            {
                shift_id: 16,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Morning",
                shift_time: "8.00 AM To 12.00 PM",
                id: 1,
                slots: [
                    {
                        slot_id: 65,
                        selected: false,
                        slot_name: "8.00 AM To 9.00 AM",
                        slot_title: "8.00 AM To 9.00 AM",
                    },
                    {
                        slot_id: 66,
                        selected: false,
                        slot_name: "9.00 AM To 10.00 AM",
                        slot_title: "9.00 AM To 10.00 AM",
                    },
                    {
                        slot_id: 67,
                        selected: false,
                        slot_name: "10.00 AM To 11.00 AM",
                        slot_title: "10.00 AM To 11.00 AM",
                    },
                    {
                        slot_id: 68,
                        selected: false,
                        slot_name: "11.00 AM To 12.00 AM",
                        slot_title: "11.00 AM To 12.00 AM",
                    },
                ],
            },
            {
                shift_id: 17,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Afternoon",
                shift_time: "12.00 PM To 5.00 PM",
                id: 2,
                slots: [
                    {
                        slot_id: 69,
                        selected: false,
                        slot_name: "12:00 PM To 01:00 PM",
                        slot_title: "12:00 PM To 01:00 PM",
                    },
                    {
                        slot_id: 70,
                        selected: false,
                        slot_name: "1:00 PM To 02:00 PM",
                        slot_title: "1:00 PM To 02:00 PM",
                    },
                    {
                        slot_id: 71,
                        selected: false,
                        slot_name: "2:00 PM To 03:00 PM",
                        slot_title: "2:00 PM To 03:00 PM",
                    },
                    {
                        slot_id: 72,
                        selected: false,
                        slot_name: "3:00 PM To 04:00 PM",
                        slot_title: "3:00 PM To 04:00 PM",
                    },
                    {
                        slot_id: 73,
                        selected: false,
                        slot_name: "4:00 PM To 05:00 PM",
                        slot_title: "4:00 PM To 05:00 PM",
                    },
                ],
            },
            {
                shift_id: 18,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Evening",
                shift_time: "5.00 PM To 9.00 PM",
                id: 3,
                slots: [
                    {
                        slot_id: 74,
                        selected: false,
                        slot_name: "5.00 PM To 6.00 PM",
                        slot_title: "5.00 PM To 6.00 PM",
                    },
                    {
                        slot_id: 75,
                        selected: false,
                        slot_name: "6.00 PM To 7.00 PM",
                        slot_title: "6.00 PM To 7.00 PM",
                    },
                    {
                        slot_id: 76,
                        selected: false,
                        slot_name: "7.00 PM To 8.00 PM",
                        slot_title: "7.00 PM To 8.00 PM",
                    },
                    {
                        slot_id: 77,
                        selected: false,
                        slot_name: "8.00 PM To 9.00 PM",
                        slot_title: "8.00 PM To 9.00 PM",
                    },
                ],
            },
        ],
    },
    {
        id: 7, name: "sunday", title: "Sunday",
        day_selected: false,
        shifts: [
            {
                shift_id: 19,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Morning",
                shift_time: "8.00 AM To 12.00 PM",
                id: 1,
                slots: [
                    {
                        slot_id: 78,
                        selected: false,
                        slot_name: "8.00 AM To 9.00 AM",
                        slot_title: "8.00 AM To 9.00 AM",
                    },
                    {
                        slot_id: 79,
                        selected: false,
                        slot_name: "9.00 AM To 10.00 AM",
                        slot_title: "9.00 AM To 10.00 AM",
                    },
                    {
                        slot_id: 80,
                        selected: false,
                        slot_name: "10.00 AM To 11.00 AM",
                        slot_title: "10.00 AM To 11.00 AM",
                    },
                    {
                        slot_id: 81,
                        selected: false,
                        slot_name: "11.00 AM To 12.00 AM",
                        slot_title: "11.00 AM To 12.00 AM",
                    },
                ],
            },
            {
                shift_id: 20,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Afternoon",
                shift_time: "12.00 PM To 5.00 PM",
                id: 2,
                slots: [
                    {
                        slot_id: 82,
                        selected: false,
                        slot_name: "12:00 PM To 01:00 PM",
                        slot_title: "12:00 PM To 01:00 PM",
                    },
                    {
                        slot_id: 83,
                        selected: false,
                        slot_name: "1:00 PM To 02:00 PM",
                        slot_title: "1:00 PM To 02:00 PM",
                    },
                    {
                        slot_id: 84,
                        selected: false,
                        slot_name: "2:00 PM To 03:00 PM",
                        slot_title: "2:00 PM To 03:00 PM",
                    },
                    {
                        slot_id: 85,
                        selected: false,
                        slot_name: "3:00 PM To 04:00 PM",
                        slot_title: "3:00 PM To 04:00 PM",
                    },
                    {
                        slot_id: 86,
                        selected: false,
                        slot_name: "4:00 PM To 05:00 PM",
                        slot_title: "4:00 PM To 05:00 PM",
                    },
                ],
            },
            {
                shift_id: 21,
                shift_selected: false,
                all_shift_selected: false,
                shift_name: "Evening",
                shift_time: "5.00 PM To 9.00 PM",
                id: 3,
                slots: [
                    {
                        slot_id: 87,
                        selected: false,
                        slot_name: "5.00 PM To 6.00 PM",
                        slot_title: "5.00 PM To 6.00 PM",
                    },
                    {
                        slot_id: 88,
                        selected: false,
                        slot_name: "6.00 PM To 7.00 PM",
                        slot_title: "6.00 PM To 7.00 PM",
                    },
                    {
                        slot_id: 89,
                        selected: false,
                        slot_name: "7.00 PM To 8.00 PM",
                        slot_title: "7.00 PM To 8.00 PM",
                    },
                    {
                        slot_id: 90,
                        selected: false,
                        slot_name: "8.00 PM To 9.00 PM",
                        slot_title: "8.00 PM To 9.00 PM",
                    },
                ],
            },
        ],
    },
];