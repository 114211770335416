import React, { useState, useEffect, useRef, useContext } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import moment from "moment";
import Swiper from 'swiper/bundle';
import CustomerSlider from "../../pages/CustomerSlider/CustomerSlider";
import "./Home.scss";
import { Accordion, NavLink } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { baseUrlPostGres } from "../../redux/constant";
import { FaqCardSection } from "../../components/faqCardSection/FaqCardSection";
import { GetBanner, GetBlogs, GetCategory, GetFaqs, GetHomeSecondSection, GetHomeThirdSection, GetHomeFourthSection, GetRandomGroupClass, GetHomeSixSection, CategoriesWithGroupClass, GetHomeEightSection, GetHomeNineSection, GetHomeTenSection } from "../../redux/actions/HomePageActions";
import { useDispatch, useSelector } from "react-redux";
import { GlobalGroupClassListAction, ResetGlobalGroupClassListAction } from "../../redux/actions/GroupClassActions";
import { getUserSettings, isValidHttpUrl } from "../../Utils/Function";
import { CartContext } from '../../pages/Home/CartContext';
import { FaYenSign } from 'react-icons/fa';

const Home = () => {
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.0.0/dist/tailwind.min.css" rel="stylesheet" />
  // const [setBanner, showFullDescription, setShowFullDescription] = useState(null);
  const [hoveredSliderIndex, setHoveredSliderIndex] = useState(null);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [isNavigating, setIsNavigating] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState('right');
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  const handlePopoverDisplay = (index, totalIndex) => {
    if (index < 2) {
      return 'right-popover';
    } else if (index === 2 && totalIndex === hoveredSliderIndex) {
      return 'left-popover';
    }
    return '';
  };
  const [setBanner] = useState(null);
  const dispatch    = useDispatch();
  const languageSelector = useSelector(state => state.home.dynamic_language)
  const blogs    = useSelector((state) => state.home?.get_blogs)
  const faq      = useSelector((state) => state.home?.get_faqs)
  const banner   = useSelector((state) => state.home?.get_banner)
  const category = useSelector((state) => state.home?.get_category?.data)

  /*section 2*/
  const homeSecondSectionData = useSelector(state => state.home?.home_page_second_section);
  useEffect(() => {
    dispatch(GetHomeSecondSection())
  }, [dispatch]);

  /*section 3*/
  const homeThirdSectionData = useSelector(state => state.home?.home_page_third_section);
  useEffect(() => {
    dispatch(GetHomeThirdSection())
  }, [dispatch]);

  const location = useLocation();
  const [state, setState] = useState({
    topic: "",
    courseId: location?.state ? location.state.course_id : "",
    date: "",
    page_no: 1
  });

  const groupClassListSelector = useSelector((state) => state.group.group_class_global_list);
  useEffect(() => {
    dispatch(GlobalGroupClassListAction({ ...state, user_id: getUserSettings()?.user?.id }));
    return () => dispatch(ResetGlobalGroupClassListAction())
  }, [dispatch]);

  /*section 4*/
  const homeFourthSectionData = useSelector(state => state.home?.home_page_fourth_section);
  useEffect(() => {
    dispatch(GetHomeFourthSection())
  }, [dispatch]);

  /*section 5*/
  const homeFiveSectionData = useSelector(state => state.home?.random_group_class);
  useEffect(() => {
    dispatch(GetRandomGroupClass())
  }, [dispatch]);

  /*section 6*/
  const homeSixSectionData = useSelector(state => state.home?.home_page_six_section);
  useEffect(() => {
    dispatch(GetHomeSixSection())
  }, [dispatch]);

  /*section 7*/
  const homeSevenSectionData = useSelector(state => state.home?.categories_with_group_class);
  useEffect(() => {
    dispatch(CategoriesWithGroupClass())
  }, [dispatch]);

  /*section 8*/
  const homeEightSectionData = useSelector(state => state.home?.home_page_eight_section);
  useEffect(() => {
    dispatch(GetHomeEightSection())
  }, [dispatch]);

  const [eightSectionFullDescription, setEightSectionFullDescription] = useState(false);
  const handleEightSectionLearnMoreClick = () => {
    setEightSectionFullDescription(true);
  };

  // const handleEightSectionReadMoreClick = () => {
  //   setEightSectionFullDescription(true);
  // };
  // const handleEightSectionReadLessClick = () => {
  //   setEightSectionFullDescription(false);
  // };

  /*section 9*/
  const homeNineSectionData = useSelector(state => state.home?.home_page_nine_section);
  useEffect(() => {
    dispatch(GetHomeNineSection())
  }, [dispatch]);

  /*section 10*/
  const homeTenSectionData = useSelector(state => state.home?.home_page_ten_section);
  useEffect(() => {
    dispatch(GetHomeTenSection())
  }, [dispatch]);

  const [tenSectionFullDescription, setTenSectionFullDescription] = useState(false);
  const handleTenSectionLearnMoreClick = () => {
    setTenSectionFullDescription(true);
  };

  useEffect(() => {
    dispatch(GetBlogs({ page_no: "1" }));
    dispatch(GetFaqs({ page: "home" }));
    dispatch(GetBanner({ type_device: 2 }));
    dispatch(GetCategory());
    dispatch(GetHomeThirdSection());
  }, [dispatch]);

  // section 3
  // Updated JS for handling the hover effect
  // const handleMouseEnter = (e) => {
  //   const popover = e.currentTarget.querySelector('.card-popover');
  //   popover.style.display = 'block';
  // };

  // const handleSearchOnChange = (e) => {
  //     const { name, value } = e.target
  //     setSearch(value);
  //     if (value.length > 3) {
  //         dispatch(HomeBannerSearchAction({ [name]: value }))
  //     } else {
  //         dispatch(ResetHomeBannerSearchAction())
  //         setSearchInstructorID("")
  //     }
  // }

  // const handleSearchSubmit = (e) => {
  //     e.preventDefault();
  //     if (searchInstructorID) {
  //         navigate(`/instructordetail/${searchInstructorID}`);
  //     } else {
  //         toast.error("Please Select any one instructor", { theme: "colored" })
  //     }
  // };

  /*slider old code*/
  // const swiper = new Swiper('.swiper-container', {
  //   loop: true,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true,
  //   },
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  // });
  // const swiperRef = useRef(null);

  const handleClickScroll = () => {
    const element = document.getElementById("whychoose-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { addToCart } = useContext(CartContext);
  const handleAddToCart = (item) => {
    const isItemInCart = cartItems.some((cartItem) => cartItem.id === item.id);
    if (!isItemInCart) {
      addToCart(item);
      setCartItems([...cartItems, item]);
      setShowSuccessMessageId(item.id);
      setShowSuccessMessage(true);
      localStorage.setItem('cartItems', JSON.stringify([...cartItems, item]));
    } else {
      navigate('/CartPage');
    }
  };

  useEffect(() => {
    // Fetch cart items from localStorage or API and set the state
    const savedCartItems = localStorage.getItem('cartItems');
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }
  }, []);

  const handleGroupClassDetailClick = (e, id) => {
    e.preventDefault(); // Prevent the default anchor behavior
    navigate(`/group-class-detail/${id}`); // Navigate to the new page with the ID
  }

  // mobile view
  const [isMobileView, setIsMobileView] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Change the breakpoint as needed
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //addtocart button success message
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessMessageId, setShowSuccessMessageId] = useState(null);

  /*slider new code*/
  const bannerSwiperRef = useRef(null);
  const bannerSwiperInstance = useRef(null);
  const secondSwiperRef = useRef(null);
  const secondSwiperInstance = useRef(null);
  const groupClassSwiperRef  = useRef(null);
  const groupClassSwiperInstance = useRef(null);
  const fourSwiperRef = useRef(null);
  const fourSwiperInstance = useRef(null);
  const fiveSwiperRef = useRef(null);
  const fiveSwiperInstance = useRef(null);
  const sevenSwiperRef = useRef(null);
  const sevenSwiperInstance = useRef(null);
  const nineSwiperRef = useRef(null);
  const nineSwiperInstance = useRef(null);

  useEffect(() => {
    if (banner.length > 0 && bannerSwiperRef.current) {
      bannerSwiperInstance.current = new Swiper(bannerSwiperRef.current, {
        loop: true,
        navigation: {
          nextEl: '.banner-swiper-button-next',
          prevEl: '.banner-swiper-button-prev',
        },
        pagination: {
          el: '.banner-swiper-pagination',
          clickable: true,
        },
      });
    }

    return () => {
      if (bannerSwiperInstance.current) {
        bannerSwiperInstance.current.destroy();
      }
    };
  }, [banner]);

  useEffect(() => {
    if (homeSecondSectionData?.data?.home_section_second_logos?.length > 0 && secondSwiperRef.current) {
      secondSwiperInstance.current = new Swiper(secondSwiperRef.current, {
        loop: true,
        navigation: {
          nextEl: '.second-swiper-button-next',
          prevEl: '.second-swiper-button-prev',
        },
        pagination: {
          el: '.second-swiper-pagination',
          clickable: true,
        },
      });
    }

    return () => {
      if (secondSwiperInstance.current) {
        secondSwiperInstance.current.destroy();
      }
    };
  }, [homeSecondSectionData]);

  useEffect(() => {
    if (groupClassListSelector?.data?.length > 0 && groupClassSwiperRef.current) {
      groupClassSwiperInstance.current = new Swiper(groupClassSwiperRef.current, {
        loop: true,
        navigation: {
          nextEl: '.group-class-swiper-button-next',
          prevEl: '.group-class-swiper-button-prev',
        },
        pagination: {
          el: '.group-class-swiper-pagination',
          clickable: true,
        },
      });
    }

    return () => {
      if (groupClassSwiperInstance.current) {
        groupClassSwiperInstance.current.destroy();
      }
    };
  }, [groupClassListSelector]);

  useEffect(() => {
    if (homeFourthSectionData?.data?.home_section_fourths_data?.length > 0 && fourSwiperRef.current) {
      fourSwiperInstance.current = new Swiper(fourSwiperRef.current, {
        loop: true,
        navigation: {
          nextEl: '.four-swiper-button-next',
          prevEl: '.four-swiper-button-prev',
        },
        pagination: {
          el: '.four-swiper-pagination',
          clickable: true,
        },
      });
    }

    return () => {
      if (fourSwiperInstance.current) {
        fourSwiperInstance.current.destroy();
      }
    };
  }, [homeFourthSectionData]);

  useEffect(() => {
    if (homeFiveSectionData?.data?.length > 0 && fiveSwiperRef.current) {
      fiveSwiperInstance.current = new Swiper(fiveSwiperRef.current, {
        loop: true,
        navigation: {
          nextEl: '.five-swiper-button-next',
          prevEl: '.five-swiper-button-prev',
        },
        pagination: {
          el: '.five-swiper-pagination',
          clickable: true,
        },
      });
    }

    return () => {
      if (fiveSwiperInstance.current) {
        fiveSwiperInstance.current.destroy();
      }
    };
  }, [homeFiveSectionData]);

  useEffect(() => {
    if (homeSevenSectionData?.data?.length > 0 && sevenSwiperRef.current) {
      sevenSwiperInstance.current = new Swiper(sevenSwiperRef.current, {
        loop: true,
        navigation: {
          nextEl: '.seven-swiper-button-next',
          prevEl: '.seven-swiper-button-prev',
        },
        pagination: {
          el: '.seven-swiper-pagination',
          clickable: true,
        },
      });
    }

    return () => {
      if (sevenSwiperInstance.current) {
        sevenSwiperInstance.current.destroy();
      }
    };
  }, [homeSevenSectionData]);

  useEffect(() => {
    if (homeNineSectionData?.data?.home_section_nineth_data?.length > 0 && nineSwiperRef.current) {
      nineSwiperInstance.current = new Swiper(nineSwiperRef.current, {
        loop: true,
        navigation: {
          nextEl: '.nine-swiper-button-next',
          prevEl: '.nine-swiper-button-prev',
        },
        pagination: {
          el: '.nine-swiper-pagination',
          clickable: true,
        },
      });
    }

    return () => {
      if (nineSwiperInstance.current) {
        nineSwiperInstance.current.destroy();
      }
    };
  }, [homeNineSectionData]);

  return (
    <>
      <Header/>
      <div id="footer-bottom">
        {/*sectio 1*/}
        {banner.length > 0 && (
          <section className="hero">
            <div className="swiper-container banner-swiper-container" ref={bannerSwiperRef}>
              <div className="swiper-wrapper">
                {banner.map((banner) => (
                  <div className="swiper-slide" key={banner.id} style={{
                    backgroundImage: banner ? `url(${baseUrlPostGres()}${banner.image})` : "",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}>
                    <div className="content-box">
                      <div className="container mx-auto text-center p-4">
                        <h4 className="text-2xl text-dark font-bold">{banner ? banner.name : ""}</h4>
                        <h6>
                          <p className="text-xl mt-4 mb-6" style={{ textAlign: 'left' }}>{banner ? banner.description : ""}</p>
                        </h6>
                        <div style={{ textAlign: 'left' }}>
                          {banner.calltoaction1_txt && <a href={banner.calltoaction1_url} className="btn btn-primary" style={{ backgroundColor: banner?.color_picker }}>{banner.calltoaction1_txt}</a>}
                          {banner.calltoaction2_txt && <a href={banner.calltoaction2_url} className="btn btn-primary" style={{ backgroundColor: banner?.color_picker }}>{banner.calltoaction2_txt}</a>}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {banner.length > 1 && (
                <>
                  // <div className="swiper-button-prev" onClick={handleClickScroll} />
                  <div className="swiper-button-prev banner-swiper-button-prev" />
                  <div className="swiper-button-next banner-swiper-button-next" />
                </>
              )}
            </div>
          </section>
        )}

        <br></br>
        <br></br>
        {/* section 2 */}
        {homeSecondSectionData?.data?.home_section_second_logos?.length > 0 && homeSecondSectionData?.data != null &&
          <section className="logos">
            <h3 className="section-title">{homeSecondSectionData?.data?.description}</h3>
            {/* Swiper */}
            <div className="swiper mySwiper">
              <div className="swiper-container second-swiper-container"  ref={secondSwiperRef}>
                <div className="swiper-wrapper">
                  {homeSecondSectionData?.data?.home_section_second_logos.map((logo, index) => (index % 8 === 0 && (
                    <div className="swiper-slide" key={index}>
                      <div className="logo section_second_image_logo">
                        <div id="banner" style={{ overflow: 'hidden', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}> {homeSecondSectionData?.data?.home_section_second_logos.slice(index, index + 8).map((event, i) => (
                          <div key={i} style={{ maxWidth: '20%', maxHeight: '20%' }}>
                            <img className="logo-img" src={baseUrlPostGres() + event.logo} style={{ maxWidth: '100%', height: 'auto' }} />
                          </div>
                        ))}
                        </div>
                      </div>
                    </div>
                  )))}
                </div>
                {/* Add Pagination */}
                {/* <div className="swiper-pagination" /> */}
                <div className="swiper-button-prev second-swiper-button-prev" style={{ display: homeSecondSectionData?.data?.home_section_second_logos.length > 8 ? 'block' : 'none' }} />
                <div className="swiper-button-next second-swiper-button-next" style={{ display: homeSecondSectionData?.data?.home_section_second_logos.length > 8 ? 'block' : 'none' }} />
              </div>
            </div>
            <br />
          </section>
        }

        {/*section 3*/}
        {groupClassListSelector?.data?.length > 0 && homeThirdSectionData?.data != null &&
          <section className="broad-selection">
            <h3 className="section-title">{homeThirdSectionData?.data?.heading}</h3>
            <p style={{ fontSize: '18px', textAlign: 'center' }}>{homeThirdSectionData?.data?.description}</p><br></br>
            {/* Swiper */}
            <div className="swiper mySwiper">
              <div className="swiper-container group-class-swiper-container" ref={groupClassSwiperRef}>
                <div className="swiper-wrapper">
                  {/* Slides */}
                  {groupClassListSelector?.data?.reduce((acc, curr, index) => { const chunkIndex = Math.floor(index / (isMobileView ? 1 : 4)); if (!acc[chunkIndex]) { acc[chunkIndex] = []; } acc[chunkIndex].push(curr); return acc; }, []).map((chunk, chunkIndex) => (
                    <div className="swiper-slide" key={chunkIndex}>
                      <div className="course-cardsss" >
                        {chunk.map((event, index) => (
                          <article className="card" key={index}   >
                            <div className="image-container" >
                              {/* Card details */}
                              <img src={baseUrlPostGres() + event.picture} onClick={(e) => handleGroupClassDetailClick(e, event.id)} />
                              {/* <div className={`card-popover ${handlePopoverDisplay(index, chunkIndex)}`} onClick={(e) => e.stopPropagation()}>
                                <b>
                                  <p onClick={(e) => handleGroupClassDetailClick(e, event.id)}>{event.class_name} </p>
                                </b>
                                <br />
                                <h6>Updated {moment(event?.updated_at, "YYYY/MM/DD").format("MMM YYYY")}</h6>
                                <h6>{event?.duration} total hours * All Levels</h6>
                                <p className="popover-text">{event?.description}</p>
                                <div className="popover-content">
                                  <svg className="popover-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={30} height={30} viewBox="0 0 24 24">
                                    <path d="M 19.28125 5.28125 L 9 15.5625 L 4.71875 11.28125 L 3.28125 12.71875 L 8.28125 17.71875 L 9 18.40625 L 9.71875 17.71875 L 20.71875 6.71875 Z" />
                                  </svg>
                                  <p className="popover-text">{event?.full_description}</p>
                                </div>

                                {cartItems.some((cartItem) => cartItem.id === event.id) ?
                                  <button className="popover-btn" style={{ fontSize: "15px" }} onClick={() => navigate('/CartPage')}>
                                    Go to Cart
                                  </button> :
                                  <button className="popover-btn" style={{ fontSize: "15px" }} onClick={() => handleAddToCart(event)}>
                                    Add to Cart
                                  </button>
                                }

                                {showSuccessMessage && <p>Item successfully added to cart!</p>}

                                <span className="popover-love">♡</span>
                                <div className="popover-tail" />
                              </div> */}
                              <b>
                                <p onClick={(e) => handleGroupClassDetailClick(e, event.id)}>{event.class_name} </p>
                              </b>
                              <h3 className='iname'>Dr. {event.group_user.first_name} {event.group_user.last_name} </h3>
                              <div className="rating mr-2" >
                                ★★★★☆
                                <span className="text-sm text-gray-600" >Rating</span>
                              </div>
                              <h5 className="price" data-course-price >${event.price} </h5>
                              {cartItems.some((cartItem) => cartItem.id === event.id) ?
                                <button className="popover-btn" style={{ fontSize: "15px" }} onClick={() => navigate('/CartPage')}>
                                  Go to Cart
                                </button> :
                                <button className="popover-btn" style={{ fontSize: "15px" }} onClick={() => handleAddToCart(event)}>
                                  Add to Cart
                                </button>
                              }
                              <span className="popover-love">♡</span>
                              {showSuccessMessage && showSuccessMessageId === event.id && (
                                <p>Item successfully added to cart!</p>
                              )}
                            </div>
                          </article>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                {/* Add Pagination */}
                {/* <div className="swiper-pagination" /> */}
                <div className="swiper-button-prev group-class-swiper-button-prev" style={{ display: groupClassListSelector?.data?.length > 4 ? 'block' : 'none' }} />
                <div className="swiper-button-next group-class-swiper-button-next" style={{ display: groupClassListSelector?.data?.length > 4 ? 'block' : 'none' }} />
              </div>
            </div>
            <br />
          </section>
        }

        {/* section 4 */}
        {/* student says about section */}
        {homeFourthSectionData?.data?.home_section_fourths_data.length > 0 &&
          <section className="instructor-words">
            <h3 className="section-title">{homeFourthSectionData?.data?.heading}</h3>
            {/* Swiper */}
            <div className="swiper mySwiper">
              <div className="swiper-container four-swiper-container" ref={fourSwiperRef}>
                <div className="swiper-wrapper">
                  {/* Slides */}
                  {homeFourthSectionData?.data?.home_section_fourths_data?.reduce((acc, curr, index) => {
                    const chunkIndex = Math.floor(index / (isMobileView ? 1 : 4));
                    if (!acc[chunkIndex]) {
                      acc[chunkIndex] = [];
                    }
                    acc[chunkIndex].push(curr);
                    return acc;
                  }, []).map((chunk, chunkIndex) => (
                    <div className="swiper-slide" key={chunkIndex}>
                      <div className="course-cardss">

                        {chunk.map((event, index) => (
                          <article className="card" key={index}>
                            <div className="image-container">
                              {/* Card details */}
                              <div className="profile"><img src={baseUrlPostGres() + event.image} style={{ height: '100px', width: '100px' }} /></div>
                              <h3 className="instructor-name">{event.name}</h3>
                              <p className="instructor-title">Student</p>
                              {/* <p className="testimonial-quote">
                        {event.description.length > 50 ? (
                          <>
                            {`${event.description.substring(0, 250)}...`}
                            <span className="readmore-text" onClick={() => showFullDescription(event.description)}>Read More</span>
                          </>
                        ) : event.description}
                      </p> */}
                              <div className="card-quotess">
                                <p>{event.description}</p>
                              </div>
                              {/* Star Rating Placeholder */}
                            </div>
                          </article>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                {/* Add Pagination */}
                {/* <div className="swiper-pagination" /> */}
                <div className="swiper-button-prev four-swiper-button-prev" style={{ display: homeFourthSectionData?.data?.home_section_fourths_data.length > 4 ? 'block' : 'none' }} />
                <div className="swiper-button-next four-swiper-button-next" style={{ display: homeFourthSectionData?.data?.home_section_fourths_data.length > 4 ? 'block' : 'none' }} />
              </div>
            </div>
            <br />
          </section>
        }

        {/* section 5 most popular course */}
        {homeFiveSectionData?.data?.length > 0 && homeFiveSectionData?.sectionFiveData != null &&
          <section className="broad-selection">
            <h3 className="section-title">{homeFiveSectionData?.sectionFiveData?.heading}</h3>
            <p style={{ fontSize: '18px', textAlign: 'center' }}>{homeFiveSectionData?.sectionFiveData?.description}</p><br></br>
            {/* Swiper */}
            <div className="swiper mySwiper">
              <div className="swiper-container five-swiper-container" ref={fiveSwiperRef}>
                <div className="swiper-wrapper">
                  {/* Slides */}
                  {homeFiveSectionData?.data?.reduce((acc, curr, index) => { const chunkIndex = Math.floor(index / (isMobileView ? 1 : 4)); if (!acc[chunkIndex]) { acc[chunkIndex] = []; } acc[chunkIndex].push(curr); return acc; }, []).map((chunk, chunkIndex) => (
                    <div className="swiper-slide" key={chunkIndex}>
                      <div className="course-cardsss" >
                        {chunk.map((gclass, index) => (
                          <article className="card" key={index}>
                            <div className="image-container">
                              <img src={baseUrlPostGres() + gclass.picture} onClick={(e) => handleGroupClassDetailClick(e, gclass.id)} />
                              {/* cards details */}
                              <b><p onClick={(e) => handleGroupClassDetailClick(e, gclass.id)}>{gclass?.class_name}</p></b>
                              <h3 className='iname'>Dr. {gclass.group_user.first_name} {gclass.group_user.last_name}</h3>
                              {/* Star Rating Placeholder */}
                              <div className="rating mr-2">
                                ★★★★☆
                                <span className="text-sm text-gray-600">(120)</span>
                              </div>
                              <h5 className="price" data-course-price>${gclass.price}</h5>
                              {cartItems.some((cartItem) => cartItem.id === gclass.id) ?
                                <button className="popover-btn" style={{ fontSize: "15px" }} onClick={() => navigate('/CartPage')}>
                                  Go to Cart
                                </button> :
                                <button className="popover-btn" style={{ fontSize: "15px" }} onClick={() => handleAddToCart(gclass)}>
                                  Add to Cart
                                </button>
                              }
                              <span className="popover-love">♡</span>
                              {showSuccessMessage && showSuccessMessageId === gclass.id && (
                                <p>Item successfully added to cart!</p>
                              )}
                            </div>
                          </article>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                {/* Add Pagination */}
                {/* <div className="swiper-pagination" /> */}
                <div className="swiper-button-prev five-swiper-button-prev" style={{ display: homeFiveSectionData?.data?.length > 4 ? 'block' : 'none' }} />
                <div className="swiper-button-next five-swiper-button-next" style={{ display: homeFiveSectionData?.data?.length > 4 ? 'block' : 'none' }} />
              </div>
            </div>
            <br />
          </section>
        }

        {/* section 6 most popular category */}
        {homeSixSectionData?.data?.home_section_six_data.length > 0 &&
          <section class="top-popular-category">
            <div class="top-categories">
              <div class="container">
                <h1>{homeSixSectionData?.data?.heading}</h1>
                <p>{homeSixSectionData?.data?.description}</p>
                <div class="categories-grid">
                  {homeSixSectionData?.data?.home_section_six_data?.map(event => (
                    <div class="category-card" style={{ backgroundColor: event.color_picker }} data-category="development">
                      <a href={`/group-classes/${event.id}`} style={{ textDecoration: 'none', 'color': 'black' }}><h4>{event.category_name}</h4></a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        }

        {/* section 7 the most viewed category */}
        {homeSevenSectionData?.data?.length > 0 && homeSevenSectionData?.sectionSevenData != null &&
          <section className="broad-selection" style={{ backgroundColor: "#f9f9f9" }}>
            <h3 className="section-title">{homeSevenSectionData?.sectionSevenData?.heading}</h3>
            <p style={{ fontSize: '18px', textAlign: 'center' }}>{homeSevenSectionData?.sectionSevenData?.description}
              <a href="#" class="cts-button">Explore More Topics</a>
            </p>
            {/* Swiper */}
            <div className="swiper mySwiper">
              <div className="swiper-container seven-swiper-container" ref={sevenSwiperRef}>
                <div className="swiper-wrapper">
                  {/* Slides */}
                  {homeSevenSectionData?.data?.reduce((acc, curr, index) => {
                    const hssdIndex = Math.floor(index / (isMobileView ? 1 : 4));
                    if (!acc[hssdIndex]) {
                      acc[hssdIndex] = [];
                    }
                    acc[hssdIndex].push(curr);
                    return acc;
                  }, []).map((seven, hssdIndex) => (
                    <div className="swiper-slide" key={hssdIndex}>
                      <div className="course-cardsss" >
                        {seven.map((event, index) => (
                          <article className="card" key={index}>
                            <div key={index}>
                              <h3>{event.category_name}</h3>
                              <div>
                                {event?.group_class?.map(grpcls => (
                                  <div class="trending-topics--topic--h6Im-">
                                    <div>
                                      <a href="javascript:void(0);" class="trending-topics--link--zoIGq" onClick={(e) => handleGroupClassDetailClick(e, grpcls.id)}>{grpcls.class_name}</a>
                                    </div>
                                    <div class="trending-topics--count--NVrxy" data-purpose="count">00 learners</div>
                                    <br></br>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </article>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                {/* Add Pagination */}
                {/* <div className="swiper-pagination" /> */}
                <div className="swiper-button-prev seven-swiper-button-prev" style={{ display: homeSevenSectionData?.data?.length > 4 ? 'block' : 'none' }} />
                <div className="swiper-button-next seven-swiper-button-next" style={{ display: homeSevenSectionData?.data?.length > 4 ? 'block' : 'none' }} />
              </div>
            </div>
            <br />
          </section>
        }

        {/* section 8 upskill section image with contain */}
        {homeEightSectionData?.data != null &&
          <section class="upskill-team">
            <div class="container">
              <div class="upskill-wrapper">
                <div class="upskill-image">
                  <img src={baseUrlPostGres() + homeEightSectionData?.data?.image} alt="Upskill Your Team" />
                </div>
                <div class="upskill-content">
                  <h2>{homeEightSectionData?.data?.heading}</h2>
                  {eightSectionFullDescription ? (
                    /*<>
                      <p>{homeEightSectionData?.data?.description}</p>
                      <a href="javascript:void(0);" class="cta-button" onClick={handleEightSectionReadLessClick}>Read Less</a>
                    </>*/
                    <p>{homeEightSectionData?.data?.description}</p>
                  ) : (
                    <>
                      <p>{homeEightSectionData?.data?.description.substring(0, 300)}</p>
                      {homeEightSectionData?.data?.description?.length > 300 && (
                        <a href="javascript:void(0);" class="cta-button" onClick={handleEightSectionLearnMoreClick}>Read More</a>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        }

        {/* section 9 */}
        {/* Instructor testimonial cards */}
        {homeNineSectionData?.data?.home_section_nineth_data.length > 0 &&
          <section className="instructor-wordss">
            <h1 className="section-titles">{homeNineSectionData?.data?.heading}</h1>
            {/* Swiper */}
            <div className="swiper mySwiper">
              <div className="swiper-container nine-swiper-container" ref={nineSwiperRef}>
                <div className="swiper-wrapper">
                  {homeNineSectionData?.data?.home_section_nineth_data?.reduce((accumulator, event, index) => {
                    if (index % (isMobileView ? 1 : 4) === 0) {
                      accumulator.push([]);
                    }
                    accumulator[accumulator.length - 1].push(event);
                    return accumulator;
                  }, []).map((group, groupIndex) => (
                    <div className="swiper-slide" key={groupIndex}>
                      <div className='instructorcard'>
                        <div className="testimonial-containers">
                          {group.map((event, index) => (
                            <div className="testimonial-cards" key={index}>
                              <div className="card-tops">
                                <img className="card-images" src={baseUrlPostGres() + event.image} alt="Mike Kane" />
                                <div className="card-titles">{event.name}</div>
                              </div>
                              <div className="card-quotes">
                                <p>{event.description}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Add Pagination */}
              <div className="swiper-button-prev nine-swiper-button-prev"
                style={{
                  display: homeNineSectionData?.data?.home_section_nineth_data.length >
                    (isMobileView ? 1 : 4) ? 'block' : 'none'
                }} />
              <div className="swiper-button-next nine-swiper-button-next"
                style={{ display: homeNineSectionData?.data?.home_section_nineth_data.length > (isMobileView ? 1 : 4) ? 'block' : 'none' }} />
            </div>
            <br />
          </section>
        }

        {/* section 10 rightside image with leftcontent */}
        {homeTenSectionData?.data != null &&
          <section class="upskill-team">
            <div class="container">
              <div class="upskill-wrapper">
                <div class="upskill-content">
                  <h2>{homeTenSectionData?.data?.heading}</h2>
                  {tenSectionFullDescription ? (
                    <p>{homeTenSectionData?.data?.description}</p>
                  ) : (
                    <>
                      <p>{homeTenSectionData?.data?.description.substring(0, 300)}</p>
                      {homeTenSectionData?.data?.description?.length > 300 && (
                        <a href="javascript:void(0);" class="cta-button" onClick={handleTenSectionLearnMoreClick}>Learn More</a>
                      )}
                    </>
                  )}
                </div>
                <div class="upskill-image">
                  <img src={baseUrlPostGres() + homeTenSectionData?.data?.image} alt="Upskill Your Team" />
                </div>
              </div>
            </div>
          </section>
        }
      </div>
      <Footer />
    </>
  );
};

export default Home;
