import Modal from "react-bootstrap/Modal";
import { copyLinkToClipboard } from "../../Utils/Function";
import { useSelector } from "react-redux";

const WorkStationModal = (props) => {
    const { onHide, zoomLink, ...rest } = props;
    const languageSelector = useSelector(state => state.home.dynamic_language)
    return (
        <Modal
            {...rest}
            centered
            backdrop="static"
            keyboard={false}
            className='center-modal class-modal problemsolving workstation'
        >
            <Modal.Header>
                <button type="button" className="btn-close" onClick={onHide}></button>
            </Modal.Header>
            <Modal.Body className='blogdetails-modal'>
                <div className='classtype-section'>
                    <div className='checkmark'>
                        <img src="/images/checkmark.png" alt="Zoom Link" />
                        <p> {languageSelector?.classes_details?.zoom_link} </p>
                    </div>
                    <div className='url-box text-center'>
                        <a href={zoomLink} target="_blank" className="btn"> {zoomLink} </a>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='email-send'>
                    <div className='accept'>
                        <button onClick={() => copyLinkToClipboard(zoomLink)} className="btn"> {languageSelector?.classes_details?.copy_link} </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default WorkStationModal