import axios from "axios";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    IS_CMS_LOADING,
    CMS_LIST_SUCCESS, CMS_LIST_ERROR,
} from "./Type";
import HandleError from "./HandleError";

export const CMSListAction = (slug) => async (dispatch) => {
    dispatch({ type: IS_CMS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/cms-page/${slug}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: CMS_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: CMS_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetCMSListAction = () => async (dispatch) => {
    dispatch({
        type: CMS_LIST_SUCCESS,
        payload: {},
    });
}