import React, { useEffect, useState } from "react";
import "./Instructorsstudent.scss";
import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { baseUrlPostGres } from "../../redux/constant";
import { toast } from "react-toastify";
import { getUserSettings, isValidHttpUrl } from "../../Utils/Function";
import { useDispatch, useSelector } from "react-redux";
import { AddFavoriteAction, ResetAddFavoriteAction } from "../../redux/actions/instructorAction";

const Instructorsstudent = ({ ele, id, colClass, isFav }) => {
    const { descriptions, profile_image, profile_video, is_online, verified_Sign, subject_name, user_lang, userspecialization, instructorlevel } = ele;
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isOpen, setOpen] = useState(false);
    const [modalNavigateURL, setModalNavigateURL] = useState()
    const [show, setShow] = useState(false);
    const [favoriteStatus, setFavoritestatus] = useState(false)

    const userDetail = getUserSettings();
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const wishlistSelector = useSelector(state => state.instructor.add_favorite)

    const handleClose = () => {
        setShow(false)
        setModalNavigateURL("")
    };
    const handleShow = () => {
        setShow(true);
    };

    const handleWishList = (e) => {
        if (Object.keys(userDetail).length > 0) {
            setFavoritestatus(true)
            dispatch(AddFavoriteAction({
                type_wish: "1",
                instructor_id: e.target.id
            }))
        } else {
            toast.error(languageSelector?.common?.please_login_student, { theme: "colored" })
        }
    };

    const handleRedirectClassType = () => {
        if (userDetail?.user?.role === 2) {
            if (is_online === 0 && modalNavigateURL?.id == 1) {
                toast.error(languageSelector?.inst_page?.instrucator_offline, { theme: "colored" })
                return <></>
            }
            modalNavigateURL ? navigate(modalNavigateURL.url) : toast.error(languageSelector?.inst_page?.please_select_class_type, { theme: "colored" })
        } else {
            navigate("/student-login")
            toast.error(languageSelector?.common?.please_login_student, { theme: "colored" })
        }
    }

    useEffect(() => {
        if (Object.keys(wishlistSelector).length > 0) {
            if (wishlistSelector.status) {
                dispatch(ResetAddFavoriteAction())
                setFavoritestatus(false)
            }
        }
    }, [wishlistSelector])
    return (
        <>
            <div className={`${colClass ? colClass : "col-md-12 col-lg-6 col-12"}`}>
                <div className="card-main-section">
                    <div className="card-first">
                        <div className="video-section">
                            <img
                                src={profile_image ?
                                    isValidHttpUrl(profile_image) ? profile_image
                                        : `${baseUrlPostGres()}${profile_image}`
                                    : "/images/blank-profile-picture.jpg"}
                                alt={ele.first_name}
                            />

                            <React.Fragment>
                                <ModalVideo
                                    channel="custom"
                                    url={`${baseUrlPostGres()}${profile_video}`}
                                    autoplay
                                    isOpen={isOpen}
                                    videoId="9xwazD5SyVg"
                                    onClose={() => setOpen(false)}
                                />
                                <button className="btn" onClick={() => setOpen(true)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="43.875"
                                        height="43.875"
                                        viewBox="0 0 43.875 43.875"
                                    >
                                        <path
                                            id="Icon_ionic-ios-play-circle"
                                            data-name="Icon ionic-ios-play-circle"
                                            d="M25.313,3.375A21.938,21.938,0,1,0,47.25,25.313,21.934,21.934,0,0,0,25.313,3.375Zm8.838,22.349L19.68,34.478a.469.469,0,0,1-.707-.411V16.559a.467.467,0,0,1,.707-.411L34.151,24.9A.483.483,0,0,1,34.151,25.724Z"
                                            transform="translate(-3.375 -3.375)"
                                            fill="#fff"
                                        />
                                    </svg>
                                </button>
                            </React.Fragment>
                            <div className="on-off-btn">
                                {is_online === 0 ? (
                                    <span className="unactive">{is_online}</span>
                                ) : (
                                    <span className="active">{is_online}</span>
                                )}
                            </div>

                            <div className="heart-icon">
                                <div className={`btn ${favoriteStatus ? "disabled" : ""}`}>
                                    <input
                                        type="checkbox"
                                        id={ele.id}
                                        onClick={handleWishList}
                                        onChange={e => Object.keys(userDetail).length > 0 ? e.target.checked : e.target.checked = false}
                                        disabled={favoriteStatus}
                                        defaultChecked={isFav == 1 ? true : false}
                                    />
                                    <label htmlFor={ele.id}></label>
                                </div>
                            </div>
                        </div>

                        <div className="details-box">

                            <div className="instructor-box-one">
                                <h1 className="flagcheckmark">
                                    <Link to={`/instructordetail/${ele.id}`} >{`${ele.first_name} ${ele.last_name}`}</Link>
                                    <span><img src={`https://flagcdn.com/32x24/${ele.country?.iso2.toLowerCase()}.png`} /></span>
                                    {/* <span><img src={`https://flagcdn.com/${ele.country?.iso2.toLowerCase()}.svg`} width={30} /></span> */}
                                    {verified_Sign == 1 && (
                                        <span className="Checkmark">
                                            <img src="/images/checked-mark.png" alt="Verified" />
                                        </span>
                                    )}
                                </h1>

                                <div className="rating-price-instructor">
                                    <h1>
                                        $
                                        {Math.min(
                                            ele?.user_extra_info?.on_demand_price,
                                            ele?.user_extra_info?.schedule_price
                                        )}
                                        -
                                        {Math.max(
                                            ele?.user_extra_info?.on_demand_price,
                                            ele?.user_extra_info?.schedule_price
                                        )}
                                        /hr
                                    </h1>
                                    <p>
                                        {ele?.ratingavg ?
                                            <>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25.12"
                                                    height="23.891"
                                                    viewBox="0 0 25.12 23.891"
                                                >
                                                    <path
                                                        id="Icon_metro-star-full"
                                                        data-name="Icon metro-star-full"
                                                        d="M27.691,11.808l-8.679-1.261L15.131,2.683l-3.881,7.864L2.571,11.808l6.28,6.121L7.368,26.573l7.762-4.081,7.762,4.081L21.411,17.93l6.28-6.121Z"
                                                        transform="translate(-2.571 -2.683)"
                                                        fill="#fed756"
                                                    ></path>
                                                </svg>
                                                <span>
                                                    <strong>
                                                        {(Number(ele?.ratingavg) - Math.floor(Number(ele?.ratingavg))) !== 0 ?
                                                            `${Number(ele?.ratingavg).toFixed(1)}/5`
                                                            :
                                                            `${Number(ele?.ratingavg)}/5`
                                                        }

                                                    </strong>
                                                    ({ele?.user_rating_count ? ele?.user_rating_count : 0})
                                                </span>
                                            </>
                                            :
                                            <>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25.12"
                                                    height="23.891"
                                                    viewBox="0 0 25.12 23.891"
                                                >
                                                    <path
                                                        id="Icon_metro-star-full"
                                                        data-name="Icon metro-star-full"
                                                        d="M27.691,11.808l-8.679-1.261L15.131,2.683l-3.881,7.864L2.571,11.808l6.28,6.121L7.368,26.573l7.762-4.081,7.762,4.081L21.411,17.93l6.28-6.121Z"
                                                        transform="translate(-2.571 -2.683)"
                                                        fill="#dcdcdc"
                                                    ></path>
                                                </svg>
                                                <span> {languageSelector?.inst_page?.inst_no_reviews} </span>
                                            </>
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="details-box-first">
                                <div className="wishlist-student2-section">
                                    <div className="wishlist-student2">
                                        <h2> {languageSelector?.inst_page?.speaks} </h2>
                                        <p>
                                            {user_lang?.map((value, index) =>
                                                <span key={index}> {value.language_name} </span>
                                            ).map((item, index) => [index > 0 && ', ', item])}
                                        </p>
                                    </div>
                                    <div className="wishlist-student2">
                                        <h3> {languageSelector?.inst_page?.qualification} </h3>
                                        <p>
                                            {subject_name ? subject_name : null}
                                            {instructorlevel && Object.keys(instructorlevel).length > 0 ? `(${instructorlevel?.inst_levels_name})` : null}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <h4> {languageSelector?.inst_page?.specialization} </h4>
                                    <p>
                                        {userspecialization?.map((ele, index) =>
                                            index > 0
                                                ? `| ${ele.subject_name} `
                                                : ` ${ele.subject_name} `
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="details-box-first2">
                                <button
                                    className="btn becomaninstructor"
                                    onClick={() => handleShow(id)}
                                >  {languageSelector?.inst_page?.inst_book} </button>
                            </div>
                        </div>
                    </div>
                    <div className="read-more-box">
                        <p>
                            {descriptions?.slice(0, 160)}
                            {descriptions?.length > 160 ?
                                <Link
                                    className="btn becomaninstructor"
                                    to={`/instructordetail/${ele.id}`}
                                >
                                    ...{languageSelector?.inst_page?.read_more}
                                </Link>
                                :
                                null}
                        </p>
                    </div>
                </div>
            </div>

            <Modal
                className="center-modal class-modal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{languageSelector?.inst_page?.inst_select} {languageSelector?.inst_page?.inst_type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="classtype-section classtype-section-radio-box">
                        <div className="radio-box">
                            <div className="check-box-create">
                                <input
                                    hidden
                                    type="radio"
                                    id="plan1"
                                    name="month"
                                    onChange={(e) => setModalNavigateURL({ url: e.target.value, id: 1 })}
                                    value={`/ondemandclass/subject-and-level/${ele.id}`}
                                />
                                <label htmlFor="plan1"> {languageSelector?.inst_page?.inst_demand} </label>
                            </div>
                        </div>
                        <div className="radio-box">
                            <div className="check-box-create">
                                <input hidden type="radio" id="plan2" name="month" onChange={(e) => setModalNavigateURL({ url: e.target.value, id: 2 })} value={`/scheduleclass/subject-and-level/${ele.id}`} />
                                <label htmlFor="plan2"> {languageSelector?.inst_page?.inst_schedule} </label>
                            </div>
                        </div>
                        <div className="radio-box">
                            <div className="check-box-create">
                                <input hidden type="radio" id="plan3" name="month" onChange={(e) => setModalNavigateURL({ url: e.target.value, id: 3 })} value={`/problemsolvingclass/upload-problem/${ele.id}`} />
                                <label htmlFor="plan3"> {languageSelector?.inst_page?.inst_solving} </label>
                            </div>
                        </div>
                        <div className="radio-box">
                            <div className="check-box-create">
                                <input hidden type="radio" id="plan4" name="month" onChange={(e) => setModalNavigateURL({ url: e.target.value, id: 4 })} value={`/subscription/selectplan/${ele.id}`} />
                                <label htmlFor="plan4"> {languageSelector?.inst_page?.inst_subscription} </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="email-send continue">
                        <button type="button" className="btn becomaninstructor" onClick={handleRedirectClassType}> {languageSelector?.inst_page?.inst_continue} </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Instructorsstudent;
