import moment from "moment";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { baseUrlPostGres } from "../../../redux/constant";
import Skeleton from 'react-loading-skeleton'
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { ClassesDetailsListAction, ResetClassesDetailsListAction } from "../../../redux/actions/ClassesDetailActions";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../components/Hooks/Pagination";

const Cancelled = ({ setTypeBooking, currentPage, setCurrentPage, selector, classesDetailData }) => {
    const dispatch = useDispatch()
    const [item, setItem] = useState({ kindOfStand: "all", another: "another" });
    const { kindOfStand } = item;
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const handleChange = e => {
        e.persist();
        dispatch(ResetClassesDetailsListAction())
        setCurrentPage(1)
        setItem(prevState => ({
            ...prevState,
            kindOfStand: e.target.value
        }));
        setTypeBooking(e.target.value)
        if (e.target.value !== "all") {
            dispatch(ClassesDetailsListAction("instructor", { type_class: "6", type_booking: e.target.value, page: currentPage }))
        } else {
            dispatch(ClassesDetailsListAction("instructor", { type_class: "6", type_booking: "", page: currentPage }))
        }
    };
    return (
        <>
            <div className="booking-filter">
                <div className="btn">
                    <Form.Group controlId="kindOfStand12">
                        <Form.Check value="all" type="radio" label={languageSelector?.classes_details?.all} onChange={handleChange} checked={kindOfStand === "all"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand10"> <Form.Check value="1" type="radio" label={languageSelector?.classes_details?.on_demand} onChange={handleChange} checked={kindOfStand === "1"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand13"> <Form.Check value="2" type="radio" label={languageSelector?.classes_details?.scheduled} onChange={handleChange} checked={kindOfStand === "2"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand14"> <Form.Check value="3" type="radio" label={languageSelector?.classes_details?.problem_sloving} onChange={handleChange} checked={kindOfStand === "3"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand15"> <Form.Check value="4" type="radio" label={languageSelector?.classes_details?.group_class} onChange={handleChange} checked={kindOfStand === "4"} />
                    </Form.Group>
                </div>
            </div>

            <div className="blog-section">
                <div className="container">
                    <div className="row g-5">
                        {Object.keys(selector).length > 0 ?
                            classesDetailData?.data?.length > 0 ?
                                <>
                                    {[...classesDetailData?.data].sort((a, b) => (a.updated_at > b.updated_at) ? -1 : 1).map((value, index) => {
                                        return (
                                            <div className="col-12 col-xxl-6" key={index}>
                                                <div className="bookingrequest-card-main">
                                                    <div className='booking-card'>
                                                        <div className="image-box">
                                                            <img src={value?.user?.profile_image ? `${baseUrlPostGres()}${value?.user?.profile_image}` : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt={value?.user?.first_name} />
                                                        </div>
                                                        <div className='booking-details'>
                                                            <div className='heading-price'>
                                                                <div className='heading-description'>
                                                                    <h2>{value?.user?.first_name} {value?.user?.last_name}</h2>
                                                                </div>
                                                                {/* <h3> ${value?.transaction_user?.txn_amount ? value?.transaction_user?.txn_amount : 0}</h3> */}
                                                            </div>
                                                            <p> {moment(value.created_at).fromNow()} </p>

                                                            {value?.user_tutor_subject &&
                                                                <>
                                                                    <h4>{languageSelector?.classes_details?.subject}</h4>
                                                                    <span>{value?.user_tutor_subject?.tutor_sub_name} ({value?.class_level?.class_name})</span>
                                                                    <h4> {languageSelector?.classes_details?.class_type} </h4>
                                                                    <span>
                                                                        {value.booking_type == 1 ? languageSelector?.classes_details?.ondemand_class :
                                                                            value.booking_type == 2 ? `${languageSelector?.classes_details?.scheduled} - ` :
                                                                                value.booking_type == 3 ? languageSelector?.classes_details?.problem_sloving :
                                                                                    value.booking_type == 4 ? languageSelector?.classes_details?.group_class
                                                                                        : null
                                                                        }
                                                                    </span>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='group-classcard-main group-classcard-main2'>
                                                        <div className='date-time-box date-time-box2'>
                                                            <h4> {languageSelector?.placeholder?.reason} </h4>
                                                            <span> {value?.reject_message ? value?.reject_message?.reject_message : "-"} </span>
                                                        </div>
                                                        <div className='date-time-box'>
                                                            <h4> {languageSelector?.placeholder?.describe_reason} </h4>
                                                            <span> {value?.reject_description ? value?.reject_description : "-"} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="col-md-12">
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={classesDetailData?.total}
                                            pageSize={classesDetailData?.per_page}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </>
                                :
                                <NoDataFound />
                            :
                            [1, 2, 3, 4, 5, 6].map((_, index) => {
                                return (
                                    <div className='col-12 col-xxl-6' key={index}>
                                        <Skeleton height={260} borderRadius="2.5rem" />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default Cancelled