import axios from "axios";
import { toast } from "react-toastify";
import {
    BOOKING_REJECT_ERROR,
    BOOKING_REJECT_SUCCESS,
    BOOKING_REQUEST_LIST_SUCCESS,
    GET_CLASS_LEVEL_INSTRUCTOR_ERROR,
    GET_CLASS_LEVEL_INSTRUCTOR_SUCCESS,
    GET_GRADE_INSTRUCTOR_ERROR,
    GET_GRADE_INSTRUCTOR_SUCCESS,
    GET_QUALIFICATION_LEVEL_INSTRUCTOR_ERROR,
    GET_QUALIFICATION_LEVEL_INSTRUCTOR_SUCCESS,
    GET_TAGS_INSTRUCTOR_ERROR,
    GET_TAGS_INSTRUCTOR_SUCCESS,
    INSTRUCTOR_DETAIL_ERROR,
    INSTRUCTOR_DETAIL_SUCCESS,
    INSTRUCTOR_LEVEL_LIST_ERROR,
    INSTRUCTOR_LEVEL_LIST_SUCCESS,
    INSTRUCTOR_LIST_DATA_ERROR,
    INSTRUCTOR_LIST_DATA_SUCCESS,
    INSTRUCTOR_LIST_ERROR,
    INSTRUCTOR_LIST_SUCCESS,
    INSTRUCTOR_SUBJECTS_LIST_ERROR,
    INSTRUCTOR_SUBJECTS_LIST_SUCCESS,
    IS_LOADING,
    TUTOR_LANGUAGE_ERROR,
    TUTOR_LANGUAGE_SUCCESS,
    TUTOR_SUBJECT_ERROR,
    TUTOR_SUBJECT_SUCCESS,
    ONDEMAND_COUPON_SUCCESS,
    ONDEMAND_COUPON_ERROR,
    CANCELLATION_REASON_LIST_SUCCESS, CANCELLATION_REASON_LIST_ERROR,
    BOOKING_CANCELLATIONS_SUCCESS, BOOKING_CANCELLATIONS_ERROR,
    ADD_FAVORITE_SUCCESS, ADD_FAVORITE_ERROR,
    WISHLIST_GET_SUCCESS, WISHLIST_GET_ERROR,
    BOOKING_REQUEST_ACCEPT_SUCCESS, BOOKING_REQUEST_ACCEPT_ERROR,
    SUBSCRIPTION_DETAILS_SUCCESS, SUBSCRIPTION_DETAILS_ERROR,
    COUNTRY_FLAG_NAME_SUCCESS, COUNTRY_FLAG_NAME_ERROR,
    INSTRUCTOR_GROUP_CLASS_LIST_SUCCESS, INSTRUCTOR_GROUP_CLASS_LIST_ERROR,
    GROUP_CLASS_COUPON_SUCCESS, GROUP_CLASS_COUPON_ERROR,
    BOOKING_REQUEST_STATUS_SUCCESS, BOOKING_REQUEST_STATUS_ERROR
} from "./Type";
import "react-toastify/dist/ReactToastify.css";
import { apiHeader } from "../../redux/actions/ApiHeader";
import { baseUrlPostGres } from "../../redux/constant";
import HandleError from "./HandleError";

toast.configure();

export const GetInstructorSubjectsList = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/instructor-subject-list/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: INSTRUCTOR_SUBJECTS_LIST_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: INSTRUCTOR_SUBJECTS_LIST_ERROR,
                payload: errors,
            });
        });
};

export const GetInstructorLevelList = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/instructor-level-list/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: INSTRUCTOR_LEVEL_LIST_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: INSTRUCTOR_LEVEL_LIST_ERROR,
                payload: errors,
            });
        });
};

export const GetInstructorList = (body) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(
            `${baseUrlPostGres()}/api/instructor_list`,
            body,
            {
                headers: apiHeader(true),
            }
        )
        .then((response) => {
            dispatch({
                type: INSTRUCTOR_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: INSTRUCTOR_LIST_ERROR,
                payload: errors,
            });
        });
};

export const ResetGetInstructorList = () => async (dispatch) => {
    dispatch({
        type: INSTRUCTOR_LIST_SUCCESS,
        payload: {},
    });
}
export const GetInstructorData = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(
            `${baseUrlPostGres()}/api/instructor_data/${id}`,
            {},
            {
                headers: apiHeader(),
            }
        )
        .then((response) => {
            dispatch({
                type: INSTRUCTOR_LIST_DATA_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: INSTRUCTOR_LIST_DATA_ERROR,
                payload: errors,
            });
        });
};

export const GetInstructorClassLevel = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/class_level`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: GET_CLASS_LEVEL_INSTRUCTOR_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: GET_CLASS_LEVEL_INSTRUCTOR_ERROR,
                payload: errors,
            });
        });
};


export const GetInstructorQualificationLevel = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/qualification_level`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: GET_QUALIFICATION_LEVEL_INSTRUCTOR_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: GET_QUALIFICATION_LEVEL_INSTRUCTOR_ERROR,
                payload: errors,
            });
        });
};
export const GetInstructorGrades = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/grade-list`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: GET_GRADE_INSTRUCTOR_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: GET_GRADE_INSTRUCTOR_ERROR,
                payload: errors,
            });
        });
};
export const GetInstructorTags = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/tags-list`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: GET_TAGS_INSTRUCTOR_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: GET_TAGS_INSTRUCTOR_ERROR,
                payload: errors,
            });
        });
};

export const GetTutorSubjects = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/tutor_subject`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: TUTOR_SUBJECT_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: TUTOR_SUBJECT_ERROR,
                payload: errors,
            });
        });
};

export const GetTutorLanguage = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/tutor_language`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: TUTOR_LANGUAGE_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: TUTOR_LANGUAGE_ERROR,
                payload: errors,
            });
        });
};

export const PostInstructorDetail = (formdata) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/instructor_detail`, formdata, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: INSTRUCTOR_DETAIL_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: INSTRUCTOR_DETAIL_ERROR,
                payload: errors,
            });
        });
};

export const ResetPostInstructorDetail = () => async (dispatch) => {
    dispatch({
        type: INSTRUCTOR_DETAIL_SUCCESS,
        payload: {},
    });
}

export const OnDemandClassCouponAction = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/subjectclass_coupon_list/${id}`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: ONDEMAND_COUPON_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: ONDEMAND_COUPON_ERROR,
                payload: errors,
            });
        });
};

export const ResetOnDemandClassCouponAction = () => async (dispatch) => {
    dispatch({
        type: ONDEMAND_COUPON_SUCCESS,
        payload: {},
    });
}


export const GroupClassCouponAction = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/groupclass_coupon_list`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GROUP_CLASS_COUPON_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: GROUP_CLASS_COUPON_ERROR,
                payload: errors,
            });
        });
};

export const ResetGroupClassCouponAction = () => async (dispatch) => {
    dispatch({
        type: GROUP_CLASS_COUPON_SUCCESS,
        payload: {},
    });
}


export const InstructorBookingRequestList = (body) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/booking-request-list`, body, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: BOOKING_REQUEST_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" });
        });
};

export const ResetInstructorBookingRequestList = () => async (dispatch) => {
    dispatch({
        type: BOOKING_REQUEST_LIST_SUCCESS,
        payload: {},
    });
}

export const CancellationReasonListAction = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/reject-message`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: CANCELLATION_REASON_LIST_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: CANCELLATION_REASON_LIST_ERROR,
                payload: errors,
            });
        });
};


export const InstructorBookingRejectAction = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/reject-booking/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: BOOKING_REJECT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" });
            dispatch({
                type: BOOKING_REJECT_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetInstructorBookingRejectAction = () => async (dispatch) => {
    dispatch({
        type: BOOKING_REJECT_SUCCESS,
        payload: {},
    });
}

export const InstructorBookingCanceltAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/cancel-booking-${name}`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: BOOKING_CANCELLATIONS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" });
            dispatch({
                type: BOOKING_CANCELLATIONS_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetInstructorBookingCanceltAction = () => async (dispatch) => {
    dispatch({
        type: BOOKING_CANCELLATIONS_SUCCESS,
        payload: {},
    });
}

export const AddFavoriteAction = (data) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/add-wishlist`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: ADD_FAVORITE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" });
            dispatch({
                type: ADD_FAVORITE_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetAddFavoriteAction = (data) => async (dispatch) => {
    dispatch({
        type: ADD_FAVORITE_SUCCESS,
        payload: {},
    });
}

export const GetWishlistAction = (body) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/wishlist-list`, body, { headers: apiHeader(), }
        )
        .then((response) => {
            dispatch({
                type: WISHLIST_GET_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: WISHLIST_GET_ERROR,
                payload: errors,
            });
        });
};

export const ResetGetWishlistAction = () => async (dispatch) => {
    dispatch({
        type: WISHLIST_GET_SUCCESS,
        payload: {},
    });
}

export const BookingRequestAcceptsAction = (body) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/booking-request-accept`, body, { headers: apiHeader(), }
        )
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: BOOKING_REQUEST_ACCEPT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" });
            dispatch({
                type: BOOKING_REQUEST_ACCEPT_ERROR,
                payload: errors,
            });
        });
};

export const ResetBookingRequestAcceptsAction = () => async (dispatch) => {
    dispatch({
        type: BOOKING_REQUEST_ACCEPT_SUCCESS,
        payload: {},
    });
}

export const SubscriptionDetailsAction = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/subscription-check/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: SUBSCRIPTION_DETAILS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: SUBSCRIPTION_DETAILS_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetSubscriptionDetailsAction = (id) => async (dispatch) => {
    dispatch({
        type: SUBSCRIPTION_DETAILS_SUCCESS,
        payload: {},
    });
}

export const GetCountryFlagAction = (name) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post("https://countriesnow.space/api/v0.1/countries/flag/images", name, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: COUNTRY_FLAG_NAME_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: COUNTRY_FLAG_NAME_ERROR,
                payload: errors.response.data,
            });
        });
};

export const instructorGroupClassListAction = (data) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/instructor-group-class`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: INSTRUCTOR_GROUP_CLASS_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: INSTRUCTOR_GROUP_CLASS_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetinstructorGroupClassListAction = () => async (dispatch) => {
    dispatch({
        type: INSTRUCTOR_GROUP_CLASS_LIST_SUCCESS,
        payload: {},
    });
}




export const BookingRequestStatusAction = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/request-booking-check/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: BOOKING_REQUEST_STATUS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: BOOKING_REQUEST_STATUS_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBookingRequestStatusAction = () => async (dispatch) => {
    dispatch({
        type: BOOKING_REQUEST_STATUS_SUCCESS,
        payload: {},
    });
}