import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    IS_PROFILE_LOADING,
    SUCCESS_COMPLETE_PROFILE_PERSONAL_DETAILS,
    ERROR_COMPLETE_PROFILE_PERSONAL_DETAILS,
    SUCCESS_COMPLETE_PROFILE_QUALIFICATIONS,
    ERROR_COMPLETE_PROFILE_QUALIFICATIONS,
    SUCCESS_COMPLETE_PROFILE_TEACHING_DETAILS,
    ERROR_COMPLETE_PROFILE_TEACHING_DETAILS,
    SUCCESS_COMPLETE_PROFILE_AVAILABILITY,
    ERROR_COMPLETE_PROFILE_AVAILABILITY,
    SUCCESS_COMPLETE_PROFILE_BANK_DETAILS,
    ERROR_COMPLETE_PROFILE_BANK_DETAILS,
    PROFILE_GET_ERROR, PROFILE_GET_SUCCESS,
    PROFILE_CHANGE_PASSWORD_SUCCESS, PROFILE_CHANGE_PASSWORD_ERROR,
    PROFILE_PERSONAL_DETAILS_SUCCESS, PROFILE_PERSONAL_DETAILS_ERROR,
    PROFILE_QUALIFICATIONS_SUCCESS, PROFILE_QUALIFICATIONS_ERROR,
    PROFILE_TEACHING_DETAILS_SUCCESS, PROFILE_TEACHING_DETAILS_ERROR,
    PROFILE_AVAILABILITY_SUCCESS, PROFILE_AVAILABILITY_ERROR,
    STUDENT_UPDATE_PROFILE_SUCCESS, STUDENT_UPDATE_PROFILE_ERROR,
    PROFILE_BANKDETAILS_SUCCESS, PROFILE_BANKDETAILS_ERROR,
    PROFILE_ONLINE_OFLINE_SUCCESS, PROFILE_ONLINE_OFLINE_ERROR,
    SUCCESS_LINKEDIN_CERTIFICATE, ERROR_LINKEDIN_CERTIFICATE,
    SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE, ERROR_UPLOAD_LINKEDIN_CERTIFICATE,
    IS_COMPLETE_PROFILE_CHECK_SUCCESS, IS_COMPLETE_PROFILE_CHECK_ERROR,
    INSTRUCTOR_PROFILE_AVAILABILITY, ERROR_INSTRUCTOR_PROFILE_AVAILABILITY,
    INSTRUCTOR_FETCH_AVAILABILITY_EVENTS, ERROR_INSTRUCTOR_FETCH_AVAILABILITY_EVENTS
} from "./Type";
import { apiHeader } from "../../redux/actions/ApiHeader";
import { baseUrlPostGres } from "../../redux/constant";
import { getUserSettings } from "../../Utils/Function";
import HandleError from "./HandleError";
import { SOCKET_URL } from "../constant/Socket";

toast.configure();

export const ProfileGetAction = (name) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/my-profile-${name}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: PROFILE_GET_SUCCESS,
                payload: response.data.data,
            });
            const localStorageData = getUserSettings()
            let user_details = localStorageData.user
            user_details["first_name"] = response.data.data.first_name
            user_details["last_name"] = response.data.data.last_name
            user_details["profile_image"] = response.data.data.profile_image
            user_details["complete_profile"] = response.data.data.complete_profile
            user_details["wallet"] = response.data.data.wallet
            user_details["verified_Sign"] = response.data.data.verified_Sign
            user_details["is_online"] = response.data.data.is_online
            user_details["user_following_count"] = response.data.data.user_following_count
            user_details["user_followers_count"] = response.data.data.user_followers_count
            localStorage.setItem("security_auth", JSON.stringify(localStorageData))
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: PROFILE_GET_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetProfileGetAction = () => async (dispatch) => {
    dispatch({
        type: PROFILE_GET_SUCCESS,
        payload: {},
    });
}

export const ChangePasswordAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/change-password-${name}`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: PROFILE_CHANGE_PASSWORD_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: PROFILE_CHANGE_PASSWORD_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetChangePasswordAction = () => async (dispatch) => {
    dispatch({
        type: PROFILE_CHANGE_PASSWORD_SUCCESS,
        payload: {},
    });
}

export const StudentEditProfile = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/profile-update-student`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: STUDENT_UPDATE_PROFILE_SUCCESS,
                payload: response.data,
            });
            const localStorageData = getUserSettings()
            let user_details = localStorageData.user
            user_details["first_name"] = response.data.data.first_name
            user_details["last_name"] = response.data.data.last_name
            user_details["profile_image"] = response.data.data.profile_image
            user_details["complete_profile"] = response.data.data.complete_profile
            user_details["phone_code"] = response.data.data.phone_code
            user_details["wallet"] = response.data.data.wallet
            localStorage.setItem("security_auth", JSON.stringify(localStorageData))
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: STUDENT_UPDATE_PROFILE_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetStudentEditProfile = () => async (dispatch) => {
    dispatch({
        type: STUDENT_UPDATE_PROFILE_SUCCESS,
        payload: {},
    });
}

export const InstructorCompleteProfileStepOne = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/complete-profile-stepone`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_COMPLETE_PROFILE_PERSONAL_DETAILS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_COMPLETE_PROFILE_PERSONAL_DETAILS,
                payload: errors.response.data,
            });
        });
};

export const ResetInstructorCompleteProfileStepOne = () => async (dispatch) => {
    dispatch({
        type: SUCCESS_COMPLETE_PROFILE_PERSONAL_DETAILS,
        payload: {},
    });
}

export const InstructorCompleteProfileStepTwo = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/complete-profile-steptwo`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_COMPLETE_PROFILE_QUALIFICATIONS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_COMPLETE_PROFILE_QUALIFICATIONS,
                payload: errors.response.data,
            });
        });
};

export const InstructorCompleteProfileStepThree = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/complete-profile-stepthree`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_COMPLETE_PROFILE_TEACHING_DETAILS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_COMPLETE_PROFILE_TEACHING_DETAILS,
                payload: errors.response.data,
            });
        });
};

export const InstructorCompleteProfileStepFour = (data) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/complete-profile-stepfour`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_COMPLETE_PROFILE_AVAILABILITY,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_COMPLETE_PROFILE_AVAILABILITY,
                payload: errors.response.data,
            });
        });
};

export const InstructorCompleteProfileStepFive = (data) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/complete-profile-stepfive`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_COMPLETE_PROFILE_BANK_DETAILS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_COMPLETE_PROFILE_BANK_DETAILS,
                payload: errors.response.data,
            });
        });
};

export const studentCertificatesUpload = (data) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/student-certificates-upload`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_UPLOAD_LINKEDIN_CERTIFICATE,
                payload: errors.response.data,
            });
        });
};

export const isCompleteProfileCompletedAction = () => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/complete-profile-check`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: IS_COMPLETE_PROFILE_CHECK_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: IS_COMPLETE_PROFILE_CHECK_ERROR,
                payload: errors.response.data,
            });
        });
};

export const InstructorLinkedinCertificateAction = (data) => async (dispatch) => {
    const userDetail = localStorage.getItem("security_auth");
    const token = JSON.parse(userDetail)?.token;
    let authToken = `Bearer ${token}`;
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${SOCKET_URL}/create-certificate`, data, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: authToken,
            },
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_LINKEDIN_CERTIFICATE,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_LINKEDIN_CERTIFICATE,
                payload: errors.response.data,
            });
        });
};

export const UploadLinkedinCertificateAction = () => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/linkedin-certificate-generate`, {
            headers: apiHeader(),
        })
        .then((response) => {
            // toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            // toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_UPLOAD_LINKEDIN_CERTIFICATE,
                payload: errors.response.data,
            });
        });
};

export const UploadLinkedinStudentCertificateAction = () => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/linkedin-student-certificate-generate`, {
            headers: apiHeader(),
        })
        .then((response) => {
            // toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            // toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: ERROR_UPLOAD_LINKEDIN_CERTIFICATE,
                payload: errors.response.data,
            });
        });
};

export const ResetUploadLinkedinCertificateAction = () => async (dispatch) => {
    dispatch({
        type: SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE,
        payload: {},
    });
}


export const InstructorProfilePersonalDetailAction = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/profile-update-stepone`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: PROFILE_PERSONAL_DETAILS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: PROFILE_PERSONAL_DETAILS_ERROR,
                payload: errors.response.data,
            });
        });
};

export const InstructorProfileQualificationAction = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/profile-update-steptwo`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: PROFILE_QUALIFICATIONS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: PROFILE_QUALIFICATIONS_ERROR,
                payload: errors.response.data,
            });
        });
};


export const InstructorProfileTeachingDetailAction = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/profile-update-stepthree`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: PROFILE_TEACHING_DETAILS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: PROFILE_TEACHING_DETAILS_ERROR,
                payload: errors.response.data,
            });
        });
};

export const InstructorProfileAvailabilityAction = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/profile-update-stepfour`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            //toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: PROFILE_AVAILABILITY_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: PROFILE_AVAILABILITY_ERROR,
                payload: errors.response.data,
            });
        });
};

export const InstructorProfileAvailabilityFind = () => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/instructor-profile-availability-find`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: INSTRUCTOR_PROFILE_AVAILABILITY,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_INSTRUCTOR_PROFILE_AVAILABILITY,
                payload: errors.response.data,
            });
        });
};

export const FetchEvents = () => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/fetch-events`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: INSTRUCTOR_FETCH_AVAILABILITY_EVENTS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_INSTRUCTOR_FETCH_AVAILABILITY_EVENTS,
                payload: errors.response.data,
            });
        });
};

export const InstructorProfileBankDetailsAction = (formData) => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/profile-update-stepfive`, formData, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: PROFILE_BANKDETAILS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: PROFILE_BANKDETAILS_ERROR,
                payload: errors.response.data,
            });
        });
};


export const IPOnlineOflineAction = () => async (dispatch) => {
    dispatch({ type: IS_PROFILE_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/check-offline`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({
                type: PROFILE_ONLINE_OFLINE_SUCCESS,
                payload: response.data,
            });
            const localStorageData = getUserSettings()
            let user_details = localStorageData.user
            user_details["is_online"] = response.data.data.is_online
            localStorage.setItem("security_auth", JSON.stringify(localStorageData))
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors?.response?.data?.message, { theme: "colored" })
            dispatch({
                type: PROFILE_ONLINE_OFLINE_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetIPOnlineOflineAction = () => async (dispatch) => {
    dispatch({
        type: PROFILE_ONLINE_OFLINE_SUCCESS,
        payload: {},
    });
}