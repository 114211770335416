import axios from "axios";
import { toast } from "react-toastify";
import {
    CONTACT_US_ERROR,
    CONTACT_US_SUCCESS,
    GET_BANNER_ERROR,
    GET_BANNER_SUCCESS,
    GET_BLOGS_CATEGORY_ERROR,
    GET_BLOGS_CATEGORY_SUCCESS,
    GET_BLOGS_ERROR,
    GET_BLOGS_SUCCESS,
    GET_BLOG_DETAIL_ERROR,
    GET_BLOG_DETAIL_SUCCESS,
    GET_CATEGORY_ERROR,
    GET_CATEGORY_SUCCESS,
    GET_COUNTRY_LIST_ERROR,
    GET_COUNTRY_LIST_SUCCESS,
    GET_COURSES_ERROR,
    GET_COURSES_SUCCESS,
    GET_FAQ_ERROR,
    GET_FAQ_SUCCESS,
    GET_TESTIMONIALSS_ERROR,
    GET_TESTIMONIALSS_SUCCESS,
    IS_LOADING,
    IS_LANGUAGE_LOADING,
    POST_CITIES_LIST_ERROR,
    POST_CITIES_LIST_SUCCESS,
    POST_STATES_LIST_ERROR,
    POST_STATES_LIST_SUCCESS,
    GLOBAL_SEARCH_SUCCESS, GLOBAL_SEARCH_ERROR,
    SEARCH_SUGGESTION_SUCCESS, SEARCH_SUGGESTION_ERROR,
    GET_RECENT_BLOG_SUCCESS, GET_RECENT_BLOG_ERROR,
    GET_DYNAMIC_LANGUAGE_SUCCESS, GET_DYNAMIC_LANGUAGE_ERROR,
    HOME_PAGE_SECOND_SECTION_LIST, ERROR_HOME_PAGE_SECOND_SECTION_LIST,
    HOME_PAGE_THIRD_SECTION_LIST, ERROR_HOME_PAGE_THIRD_SECTION_LIST,
    RANDOM_GROUP_CLASS, ERROR_RANDOM_GROUP_CLASS,
    HOME_PAGE_SIX_SECTION_LIST, ERROR_HOME_PAGE_SIX_SECTION_LIST,
    HOME_PAGE_EIGHT_SECTION_LIST, ERROR_HOME_PAGE_EIGHT_SECTION_LIST,
    HOME_PAGE_TEN_SECTION_LIST, ERROR_HOME_PAGE_TEN_SECTION_LIST,
    HOME_PAGE_FOURTH_SECTION_LIST, ERROR_HOME_PAGE_FOURTH_SECTION_LIST,
    HOME_PAGE_NINE_SECTION_LIST, ERROR_HOME_PAGE_NINE_SECTION_LIST,
    CATEGORIES_WITH_GROUP_CLASS, ERROR_CATEGORIES_WITH_GROUP_CLASS
} from "./Type";
import "react-toastify/dist/ReactToastify.css";
import { apiHeader } from "../../redux/actions/ApiHeader";
import { baseUrlPostGres } from "../../redux/constant";
import HandleError from "./HandleError";

toast.configure();

export const GetLanguageAction = (callBack) => async () => {
    try {
        const response = await axios.get(`${baseUrlPostGres()}/api/language-list`, {
            headers: apiHeader(),
        })
        callBack(response.data)
    } catch (error) {
        callBack(error?.response?.data)
    }
};

export const GetDynamicLanguageKeysAction = (id) => async (dispatch) => {
    dispatch({ type: IS_LANGUAGE_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-language/${id}`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_DYNAMIC_LANGUAGE_SUCCESS,
                payload: response?.data?.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.data.message, { theme: "colored" });
            dispatch({
                type: GET_DYNAMIC_LANGUAGE_ERROR,
                payload: errors,
            });
        });
};

export const ResetGetDynamicLanguageKeysAction = (id) => async (dispatch) => {
    dispatch({
        type: GET_DYNAMIC_LANGUAGE_SUCCESS,
        payload: {},
    });
}

export const GetCourses = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get_courses`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_COURSES_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GET_COURSES_ERROR,
                payload: errors,
            });
        });
};

export const GetBlogs = (body) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/list-blogs`, body, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_BLOGS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.data.message, { theme: "colored" });
            dispatch({
                type: GET_BLOGS_ERROR,
                payload: errors,
            });
        });
};

export const GetBlogDetails = (id) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/blog_details/${id}`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_BLOG_DETAIL_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.data.message, { theme: "colored" });
            dispatch({
                type: GET_BLOG_DETAIL_ERROR,
                payload: errors,
            });
        });
};

export const ResetGetBlogDetails = () => async (dispatch) => {
    dispatch({
        type: GET_BLOG_DETAIL_SUCCESS,
        payload: {},
    });
}

export const GetRecentBlogAction = (data) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/recent-blog`, data, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_RECENT_BLOG_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.data.message, { theme: "colored" });
            dispatch({
                type: GET_RECENT_BLOG_ERROR,
                payload: errors,
            });
        });
};

export const ResetGetRecentBlogAction = () => async (dispatch) => {
    dispatch({
        type: GET_RECENT_BLOG_SUCCESS,
        payload: {},
    });
}

export const GetBlogsCategory = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/blog-category-list`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_BLOGS_CATEGORY_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.data.message, { theme: "colored" });
            dispatch({
                type: GET_BLOGS_CATEGORY_ERROR,
                payload: errors,
            });
        });
};

export const GetFaqs = (data) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/get_faq`, data, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_FAQ_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GET_FAQ_ERROR,
                payload: errors,
            });
        });
};

export const GetBanner = (data) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/get_banner`, data, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_BANNER_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GET_BANNER_ERROR,
                payload: errors,
            });
        });
};


export const GetCategory = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/category_list`, {
            "category_name": "",
            "sorting": ""
        }, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_CATEGORY_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GET_CATEGORY_ERROR,
                payload: errors,
            });
        });
};

export const GetTestimonials = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/testimonial_list`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_TESTIMONIALSS_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GET_TESTIMONIALSS_ERROR,
                payload: errors,
            });
        });
};

export const GetCountryList = () => async (dispatch) => {
    // dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/countries`, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GET_COUNTRY_LIST_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response?.data?.message, { theme: "colored" });
            dispatch({
                type: GET_COUNTRY_LIST_ERROR,
                payload: [errors],
            });
        });
};

export const StatesList = (formdata) => async (dispatch) => {
    // dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/states`, formdata, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: POST_STATES_LIST_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: POST_STATES_LIST_ERROR,
                payload: [errors],
            });
        });
};

export const CitiesList = (formdata) => async (dispatch) => {
    // dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/cities`, formdata, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: POST_CITIES_LIST_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: POST_CITIES_LIST_ERROR,
                payload: [errors],
            });
        });
};

export const ContactReasonListAction = (callback) => {
    // dispatch({ type: IS_LOADING });
    axios.get(`${baseUrlPostGres()}/api/contact-reason-list`, {
        headers: apiHeader(),
    }).then((response) => {
        callback(response?.data);
    }).catch((errors) => {
        HandleError(errors.response.data)
        toast.error(errors.response.data.message, { theme: "colored" });
        callback(errors)
    });
};

export const ContactUsAction = (body) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/contact-us`, body, {
            headers: apiHeader(true),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: CONTACT_US_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: CONTACT_US_ERROR,
                payload: [errors],
            });
        });
};


export const HomeBannerSearchAction = (body) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/instructor-suggestion`, body, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: SEARCH_SUGGESTION_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: SEARCH_SUGGESTION_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetHomeBannerSearchAction = () => async (dispatch) => {
    dispatch({
        type: SEARCH_SUGGESTION_SUCCESS,
        payload: {},
    });
}

export const GlobalSearchAction = (body) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/top-suggestion-search`, body, {
            headers: apiHeader(true),
        })
        .then((response) => {
            dispatch({
                type: GLOBAL_SEARCH_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: GLOBAL_SEARCH_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetGlobalSearchAction = () => async (dispatch) => {
    dispatch({
        type: GLOBAL_SEARCH_SUCCESS,
        payload: {},
    });
}

/*home page section 2*/
export const GetHomeSecondSection = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-home-second-section`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: HOME_PAGE_SECOND_SECTION_LIST,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_HOME_PAGE_SECOND_SECTION_LIST,
                payload: errors.response.data,
            });
        });
};


/*home page section 4*/
export const GetHomeFourthSection = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-home-fourth-section`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: HOME_PAGE_FOURTH_SECTION_LIST,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_HOME_PAGE_FOURTH_SECTION_LIST,
                payload: errors.response.data,
            });
        });
};

/*home page section 5*/
export const GetRandomGroupClass = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-random-group-class`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: RANDOM_GROUP_CLASS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_RANDOM_GROUP_CLASS,
                payload: errors.response.data,
            });
        });
};

/*home page section 6*/
export const GetHomeSixSection = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-home-six-section`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: HOME_PAGE_SIX_SECTION_LIST,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_HOME_PAGE_SIX_SECTION_LIST,
                payload: errors.response.data,
            });
        });
};

/*home page section 7*/
export const CategoriesWithGroupClass = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-categories-with-group-class`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: CATEGORIES_WITH_GROUP_CLASS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_CATEGORIES_WITH_GROUP_CLASS,
                payload: errors.response.data,
            });
        });
};

/*home page section 8*/
export const GetHomeEightSection = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-home-eight-section`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: HOME_PAGE_EIGHT_SECTION_LIST,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_HOME_PAGE_EIGHT_SECTION_LIST,
                payload: errors.response.data,
            });
        });
};

/*home page section 9*/
export const GetHomeNineSection = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-home-nine-section`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: HOME_PAGE_NINE_SECTION_LIST,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_HOME_PAGE_NINE_SECTION_LIST,
                payload: errors.response.data,
            });
        });
};

/*home page section 10*/
export const GetHomeTenSection = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get-home-ten-section`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: HOME_PAGE_TEN_SECTION_LIST,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_HOME_PAGE_TEN_SECTION_LIST,
                payload: errors.response.data,
            });
        });
};


/*home page section 3*/
export const GetHomeThirdSection = () => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/get_home_third_section`, {
            headers: apiHeader(),
        })
        .then((response) => {
            // console.log(response.data.data)
            dispatch({
                type: HOME_PAGE_THIRD_SECTION_LIST,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ERROR_HOME_PAGE_THIRD_SECTION_LIST,
                payload: errors.response.data,
            });
        });
};
