import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FieldText } from './../../../components/InputText/InputText';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import TextErrorMsg from "../../../components/TextErrorMsg/TextErrorMsg";
import * as Yup from 'yup';
import Button from "./../../../components/Buttons/Buttons";
import { Regex } from './../../../Utils/Regex';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import { ChooseFile } from '../../../components/ChooseFile/ChooseFile';
import ImagePreview from '../../../components/ImagePreview';
import { GetCountryList, GetLanguageAction } from '../../../redux/actions/HomePageActions';
import { InstructorProfilePersonalDetailAction, ProfileGetAction } from '../../../redux/actions/CompleteProfileAction';
import { baseUrlPostGres } from '../../../redux/constant';
import PhoneCountryInput from '../../../components/Numberinput/PhoneCountryInput';

const PersonalDetails = ({ profileData }) => {
    const dispatch = useDispatch();
    const [languageName, setLanguageName] = useState([])
    const [submitClick, setSubmitClick] = useState(false)
    const [timeZoneList, setTimeZoneList] = useState();
    const [selectedOption, setSelectedOption] = useState({});
    const [languageList, setLanguageList] = useState([]);

    const countryList = useSelector((state) => state?.home?.get_country_list);
    const isLoading = useSelector((state) => state.profile.loading);
    const ProfilePDSelector = useSelector((state) => state.profile.profile_personal_detail);
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const SUPPORTED_FILE_FORMAT = ["image/jpg", "image/png", "image/jpeg"]

    useEffect(() => {
        dispatch(GetCountryList());
    }, [dispatch]);

    useEffect(() => {
        GetLanguageAction(response => {
            if (response?.status === true) {
                setLanguageList(response?.data)
            }
        })();
    }, [GetLanguageAction])

    useEffect(() => {
        if (countryList && profileData) {
            let countryData = countryList.filter(value => value.id == profileData?.country_id)
            if (countryData?.length > 0) {
                setTimeZoneList(JSON.parse(countryData[0]?.timezones))
                setSelectedOption(countryData[0])
            }
        }
    }, [countryList, profileData])

    useEffect(() => {
        if (profileData && profileData?.user_lang?.length > 0) {
            profileData?.user_lang.forEach((element, index) => {
                element.cat = `Group ${index + 1}`
                element.key = element.language_name
                return <></>
            });
        }
    }, [profileData])

    const handleMultiSelect = (data) => {
        const languagesArray = data?.map((elem) => {
            return elem.key;
        });
        setLanguageName([...languagesArray]);
    };

    const handleOnChange = (e, data) => {
        if (e.target.name === "phone_number") {
            if (e.target.value) {
                let countryData = countryList.filter(value => (value.phonecode == selectedOption?.phonecode) && (value.name.toLowerCase() == selectedOption?.name.toLowerCase()))
                data.setFieldValue('country_id', countryData[0].id)
                setTimeZoneList(JSON.parse(countryData[0].timezones))
            }
        }
    }

    const handlePersonalDetailSubmit = (value, actions) => {
        let formData = new FormData();
        formData.append("first_name", value?.first_name);
        formData.append("last_name", value?.last_name);
        formData.append("phone_number", value.phone_number);
        formData.append("phone_code", value.phone_code);
        formData.append("country_id", value.country_id);
        formData.append("descriptions", value.descriptions);
        formData.append("country_time", value.country_time);
        formData.append("notification_email_lang_id", value.notification_email_lang_id);
        if (languageName.length > 0) {
            formData.append("language_name", JSON.stringify(languageName));
        } else {
            let language = profileData?.user_lang?.map(value => value.key)
            formData.append("language_name", JSON.stringify(language));
        }
        formData.append("dob", moment(value.dob).format("DD/MM/YYYY"));
        if (typeof value.profile_image === "object") {
            formData.append("profile_image", value.profile_image);
        }
        if (typeof value.proofId_image === "object") {
            formData.append("proofId_image", value.proofId_image);
        }
        if (typeof value.proofaddress_image === "object") {
            formData.append("proofaddress_image", value.proofaddress_image);
        }
        if (typeof value.profile_video === "object") {
            formData.append("profile_video", value.profile_video);
        }
        dispatch(InstructorProfilePersonalDetailAction(formData));
        setSubmitClick(true)
    }

    useEffect(() => {
        if (Object.keys(ProfilePDSelector).length > 0 && submitClick) {
            setSubmitClick(false)
            dispatch(ProfileGetAction("instructor"))
        }
    }, [ProfilePDSelector, submitClick])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    return (
        Object.keys(profileData).length > 0 ?
            <Formik
                initialValues={{
                    first_name: profileData?.first_name,
                    last_name: profileData?.last_name,
                    email: profileData?.email,
                    phone_number: profileData?.phone_number,
                    phone_code: profileData?.phone_code,
                    dob: profileData?.dob ? moment(profileData?.dob, "DD/MM/YYYY").format("YYYY-MM-DD") : "",
                    country_id: profileData?.country_id,
                    country_time: profileData?.country_timetemp,
                    notification_email_lang_id: profileData?.notify_email_lang_id,
                    profile_image: profileData?.profile_image ? profileData?.profile_image : "",
                    proofId_image: profileData?.proofId_image ? profileData?.proofId_image : "",
                    proofaddress_image: profileData?.proofaddress_image ? profileData?.proofaddress_image : "",
                    profile_video: profileData?.profile_video ? profileData?.profile_video : "",
                    descriptions: profileData?.descriptions,
                    language_name: profileData?.user_lang,
                }}

                validationSchema={() => Yup.object().shape({
                    first_name: Yup.string().required(languageSelector?.validation?.first_name),
                    last_name: Yup.string().required(languageSelector?.validation?.last_name),
                    dob: Yup.string().required(languageSelector?.validation?.dob),
                    country_id: Yup.string().required(languageSelector?.validation?.select_country),
                    notification_email_lang_id: Yup.string().required(languageSelector?.validation?.select_notification_email),
                    country_time: Yup.string().required(languageSelector?.validation?.select_timezone),
                    email: Yup.string().email(languageSelector?.validation?.email_invaild).required(languageSelector?.validation?.email_required),
                    phone_number: Yup.string().min(8, languageSelector?.validation?.phone_invaild).max(12, languageSelector?.validation?.phone_invaild).matches(Regex.MOBILE_NUMBER_REGEX, languageSelector?.validation?.phone_invaild).required(languageSelector?.validation?.phone_required),
                    descriptions: Yup.string().required(languageSelector?.validation?.description_required),
                    profile_video: Yup.string().required(languageSelector?.validation?.profile_video_required),
                    profile_image: Yup.mixed().nullable().required(languageSelector?.validation?.picture_required)
                        .test(
                            "FILE_FORMAT",
                            languageSelector?.validation?.file_upload_format,
                            (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                        ),
                    proofId_image: Yup.string().required(languageSelector?.validation?.proofId_image_required),
                    proofaddress_image: Yup.string().required(languageSelector?.validation?.proofaddress_image_required),
                })}

                onSubmit={handlePersonalDetailSubmit}
            >
                {formik => {
                    return (
                        <Form onChange={(e) => handleOnChange(e, formik)}>
                            <FormikFromFunc />
                            <div className="tab-content">
                                <div className='camera-box'>
                                    <Field name="profile_image">
                                        {({ form, field }) => {
                                            return (
                                                <label className='d-block'>
                                                    <input type="file"
                                                        hidden
                                                        name={field.name}
                                                        onChange={(e) => form.setFieldValue(field.name, e.target.files[0])}
                                                    />
                                                    <div className='icon'>
                                                        <img src='/images/camera-icon.png' className='img-fluid' alt="Camera" />
                                                    </div>
                                                </label>
                                            )
                                        }}
                                    </Field>

                                    <div className='img-container'>
                                        {formik.values.profile_image ?
                                            <ImagePreview file={formik.values.profile_image} imageAlt={formik.values.first_name} />
                                            :
                                            <ImagePreview file="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" imageAlt={formik.values.first_name} />
                                        }
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <ErrorMessage name="profile_image" component={TextErrorMsg} />
                                </div>

                                <div className="myprofilebox2">
                                    <div className="myprofilebox1">
                                        <FieldText
                                            name="first_name"
                                            type="text"
                                            placeholder={languageSelector?.placeholder?.first_name}
                                            defaultValue={formik.values.first_name}
                                            maxLength="40"
                                        />
                                    </div>

                                    <div className="myprofilebox1">
                                        <FieldText
                                            name="last_name"
                                            type="text"
                                            placeholder={languageSelector?.placeholder?.last_name}
                                            defaultValue={formik.values.last_name}
                                            maxLength="40"
                                        />
                                    </div>

                                    <div className="myprofilebox1">
                                        <div className="email-box">
                                            <FieldText
                                                name="email"
                                                type="email"
                                                placeholder={languageSelector?.placeholder?.email}
                                                defaultValue={formik.values.email}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="myprofilebox1 fix-placeholder">
                                        <PhoneCountryInput
                                            selectedOption={selectedOption}
                                            setSelectedOption={setSelectedOption}
                                            formik={formik}
                                        />
                                    </div>

                                    <div className="myprofilebox1 date-picker-sec">
                                        <div className="form-group floating-label">
                                            <FieldText
                                                name="dob"
                                                type="date"
                                                placeholder={languageSelector?.placeholder?.dob}
                                                defaultValue={formik.values.dob}
                                                max={moment().format("YYYY-MM-DD")}
                                            />
                                        </div>
                                    </div>

                                    <div className="myprofilebox1 fix-placeholder">
                                        <Field name="language_name">
                                            {({ form, field }) => {
                                                return (
                                                    <Multiselect
                                                        hidePlaceholder={true}
                                                        showArrow="true"
                                                        customArrow={<><img src='/images/down-arrow.svg' alt="" /></>}
                                                        name={field.name}
                                                        displayValue="key"
                                                        onRemove={function noRefCheck(data) {
                                                            handleMultiSelect(data)
                                                            form.setFieldValue(field.name, data)
                                                        }}
                                                        onSelect={function noRefCheck(data) {
                                                            handleMultiSelect(data)
                                                            form.setFieldValue(field.name, data)
                                                        }}
                                                        // onKeyPressFn={function noRefCheck() { }}
                                                        onSearch={function noRefCheck() { }}
                                                        placeholder={languageSelector?.placeholder?.language}
                                                        closeIcon="close"
                                                        avoidHighlightFirstOption
                                                        options={[
                                                            {
                                                                cat: "Group 1",
                                                                key: "English",
                                                            },
                                                            {
                                                                cat: "Group 2",
                                                                key: "Spanish",
                                                            },
                                                            {
                                                                cat: "Group 3",
                                                                key: "Physics",
                                                            },
                                                            {
                                                                cat: "Group 4",
                                                                key: "Hindi",
                                                            },
                                                        ]}
                                                        selectedValues={formik.values.language_name}
                                                    />
                                                )
                                            }}
                                        </Field>
                                        <span className="placeholder-name"> {languageSelector?.placeholder?.language} </span>
                                        <ErrorMessage name="language_name" component={TextErrorMsg} />
                                    </div>

                                    <div className="myprofilebox1 dropdown-select fix-placeholder">
                                        <div className="App">
                                            <Field as="select" name="country_id" disabled className='select-control w-100' value={formik.values.country_id}>
                                                <option value=""> {languageSelector?.placeholder?.select} </option>
                                                {countryList.map((value, index) => {
                                                    return (
                                                        <option value={value.id} key={index}> {value.name} </option>
                                                    )
                                                })}
                                            </Field>
                                            <ErrorMessage name="country_id" component={TextErrorMsg} />
                                            <span className="placeholder-name"> {languageSelector?.placeholder?.country} </span>
                                        </div>
                                    </div>

                                    <div className="myprofilebox1 dropdown-select fix-placeholder">
                                        <div className="App">
                                            <Field as="select" name="country_time" className='select-control w-100' value={formik.values.country_time}>
                                                <option value=""> {languageSelector?.placeholder?.select} </option>
                                                {timeZoneList && timeZoneList.map((value, index) => {
                                                    return (
                                                        <option value={`${value.zoneName} ${value.tzName} ${value.gmtOffsetName}`} key={index}> {(value.gmtOffsetName)} {value.zoneName}</option>
                                                    )
                                                })}
                                            </Field>
                                            <ErrorMessage name="country_time" component={TextErrorMsg} />
                                            <span className="placeholder-name"> {languageSelector?.placeholder?.time_zone} </span>
                                        </div>
                                    </div>

                                    <div className="myprofilebox1 dropdown-select fix-placeholder">
                                        <div className="App">
                                            <Field as="select" name="notification_email_lang_id" className='select-control w-100' value={formik.values.notification_email_lang_id}>
                                                <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                {languageList?.length > 0 &&
                                                    languageList?.map((value, index) => (
                                                        <option value={value?.id} key={index}>{value?.lang_name} </option>
                                                    ))
                                                }
                                            </Field>
                                            <ErrorMessage name="notification_email_lang_id" component={TextErrorMsg} />
                                            <span className="placeholder-name"> {languageSelector?.placeholder?.notification_email_lang} </span>
                                        </div>
                                    </div>


                                    <div className="myprofilebox1 profile-textarea">
                                        <div className="form-group floating-label textarea-limit-block">
                                            <Field name="descriptions">
                                                {({ form, field }) => {
                                                    return (
                                                        <>
                                                            <textarea
                                                                id="textarea"
                                                                className="form-control"
                                                                rows="8"
                                                                placeholder=' '
                                                                name={field.name}
                                                                value={formik.values.descriptions}
                                                                maxLength="250"
                                                                onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                                                            ></textarea>
                                                            <label htmlFor="textarea"> {languageSelector?.placeholder?.about_you} </label>
                                                            <span className="limit">250 {languageSelector?.placeholder?.characters_limit} </span>
                                                        </>
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                        <ErrorMessage name="descriptions" component={TextErrorMsg} />
                                    </div>

                                    <div className="myprofilebox1">
                                        <div className="drag-image-video">
                                            <Field name="profile_video">
                                                {({ form, field }) => {
                                                    return (
                                                        <ChooseFile
                                                            label={languageSelector?.placeholder?.profile_video}
                                                            name={field.name}
                                                            handleFileChoose={(e) => form.setFieldValue(field.name, e.profile_video)}
                                                            error="File should be .mp4 .mkv .webp and Size < 5MB"
                                                            accept={["mp4", "webp", "mkv"]}
                                                            acceptInputFile="video/mp4, video/*"
                                                            Size={6000}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                            <ErrorMessage name="profile_video" component={TextErrorMsg} />
                                        </div>
                                    </div>

                                    <div className="myprofilebox1">
                                        {typeof formik.values.profile_video !== "object" ?
                                            <div className="uploadImg-block d-block w-100">
                                                {/* <ImagePreview file={formik.values.profile_video} imageAlt="Image" /> */}
                                                <video width="300" height="200" controls>
                                                    <source src={`${baseUrlPostGres()}${formik.values.profile_video}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>

                                    <div className="myprofilebox1">
                                        <div className="drag-image-video">
                                            <Field name="proofId_image">
                                                {({ form, field }) => {
                                                    return (
                                                        <ChooseFile
                                                            label={languageSelector?.placeholder?.proof_id}
                                                            name={field.name}
                                                            handleFileChoose={(e) => form.setFieldValue(field.name, e.proofId_image)}
                                                            error="File should be .jpg .png .jpeg and Size less than 5MB"
                                                            accept={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
                                                            acceptInputFile="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx"
                                                            Size={6000}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                            <ErrorMessage name="proofId_image" component={TextErrorMsg} />
                                        </div>
                                    </div>

                                    <div className="myprofilebox1">
                                        {typeof formik.values.proofId_image !== "object" ?
                                            <div className="uploadImg-block d-block w-100">
                                                <ImagePreview file={formik.values.proofId_image} imageAlt="Image" />
                                            </div>
                                            :
                                            null
                                        }
                                    </div>

                                    <div className="myprofilebox1">
                                        <div className="drag-image-video">
                                            <Field name="proofaddress_image">
                                                {({ form, field }) => {
                                                    return (
                                                        <ChooseFile
                                                            label={languageSelector?.placeholder?.proof_address}
                                                            name={field.name}
                                                            handleFileChoose={(e) => form.setFieldValue(field.name, e.proofaddress_image)}
                                                            error="File should be .jpg .png .jpeg and Size less than 5MB"
                                                            accept={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
                                                            acceptInputFile="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx"
                                                            Size={6000}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                            <ErrorMessage name="proofaddress_image" component={TextErrorMsg} />
                                        </div>
                                    </div>

                                    <div className="myprofilebox1">
                                        {typeof formik.values.proofaddress_image !== "object" ?
                                            <div className="uploadImg-block d-block w-100">
                                                <ImagePreview file={formik.values.proofaddress_image} imageAlt="Image" />
                                            </div>
                                            :
                                            null
                                        }
                                    </div>

                                </div>
                                <div className="save-button">
                                    <Button
                                        BtnText={languageSelector?.my_account?.save_changes_button}
                                        BtnColor="primary becomaninstructor"
                                        BtnType="submit"
                                        disabled={!formik.isValid || isLoading}
                                        hasSpinner={isLoading && submitClick}
                                    />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            :
            null
    )
}
export default PersonalDetails