import {
    BLOG_LIST_ERROR, BLOG_LIST_SUCCESS,
    BLOG_EDIT_ERROR, BLOG_EDIT_SUCCESS,
    BLOG_VIEW_ERROR, BLOG_VIEW_SUCCESS,
    BLOG_ADD_ERROR, BLOG_ADD_SUCCESS,
    IS_BLOG_LOADING,
    BLOG_LIKE_SUCCESS, BLOG_LIKE_ERROR,
    ADD_BLOG_COMMENT_SUCCESS, ADD_BLOG_COMMENT_ERROR, BLOG_COMMENT_LIST_SUCCESS, BLOG_COMMENT_LIST_ERROR,
    BLOG_IS_LIKE_SUCCESS, BLOG_IS_LIKE_ERROR,
    BLOG_IS_FOLLOW_SUCCESS, BLOG_IS_FOLLOW_ERROR,
    BLOG_DELETE_SUCCESS, BLOG_DELETE_ERROR,
    BLOG_PREVIEW_DETAIL_SUCCESS, BLOG_PREVIEW_DETAIL_ERROR,
    BLOG_REPORT_SUCCESS, BLOG_REPORT_ERROR,
    BLOG_REPORT_FLAG_SUCCESS, BLOG_REPORT_FLAG_ERROR
} from "../actions/Type";

const initialState = {
    blog_add: {},
    blog_list: {},
    blog_edit: {},
    blog_view: {},
    blog_like: {},
    add_blog_comment: {},
    blog_comment_list: {},
    loading: false,
    blog_is_like: {},
    blog_follow: {},
    blog_delete: {},
    blog_preview: {},
    blog_report: {},
    blog_flag_report: {}
};

export const BlogReducer = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case IS_BLOG_LOADING:
            return {
                ...state,
                loading: true,
            };

        case BLOG_REPORT_FLAG_SUCCESS:
            return {
                ...state,
                blog_flag_report: payload,
                loading: false,
            };
        case BLOG_REPORT_FLAG_ERROR:
            return {
                ...state,
                blog_flag_report: payload,
                loading: false,
            };

        case BLOG_REPORT_SUCCESS:
            return {
                ...state,
                blog_report: payload,
                loading: false,
            };
        case BLOG_REPORT_ERROR:
            return {
                ...state,
                blog_report: payload,
                loading: false,
            };
        case BLOG_PREVIEW_DETAIL_SUCCESS:
            return {
                ...state,
                blog_preview: payload,
                loading: false,
            };
        case BLOG_PREVIEW_DETAIL_ERROR:
            return {
                ...state,
                blog_preview: payload,
                loading: false,
            };
        case BLOG_IS_FOLLOW_SUCCESS:
            return {
                ...state,
                blog_follow: payload,
                loading: false,
            };
        case BLOG_IS_FOLLOW_ERROR:
            return {
                ...state,
                blog_follow: payload,
                loading: false,
            };
        case BLOG_DELETE_SUCCESS:
            return {
                ...state,
                blog_delete: payload,
                loading: false,
            };
        case BLOG_DELETE_ERROR:
            return {
                ...state,
                blog_delete: payload,
                loading: false,
            };
        case BLOG_IS_LIKE_SUCCESS:
            return {
                ...state,
                blog_is_like: payload,
                loading: false,
            };
        case BLOG_IS_LIKE_ERROR:
            return {
                ...state,
                blog_is_like: payload,
                loading: false,
            };

        case BLOG_VIEW_SUCCESS:
            return {
                ...state,
                blog_view: payload,
                loading: false,
            };
        case BLOG_VIEW_ERROR:
            return {
                ...state,
                blog_view: payload,
                loading: false,
            };
        case BLOG_EDIT_SUCCESS:
            return {
                ...state,
                blog_edit: payload,
                loading: false,
            };
        case BLOG_EDIT_ERROR:
            return {
                ...state,
                blog_edit: payload,
                loading: false,
            };
        case BLOG_LIST_SUCCESS:
            return {
                ...state,
                blog_list: payload,
                loading: false,
            };
        case BLOG_LIST_ERROR:
            return {
                ...state,
                blog_list: payload,
                loading: false,
            };
        case BLOG_ADD_SUCCESS:
            return {
                ...state,
                blog_add: payload,
                loading: false,
            };
        case BLOG_ADD_ERROR:
            return {
                ...state,
                blog_add: payload,
                loading: false,
            };
        case BLOG_LIKE_SUCCESS:
            return {
                ...state,
                blog_like: payload,
                loading: false,
            };
        case BLOG_LIKE_ERROR:
            return {
                ...state,
                blog_like: payload,
                loading: false,
            }
        case ADD_BLOG_COMMENT_SUCCESS:
            return {
                ...state,
                add_blog_comment: payload,
                loading: false,
            };
        case ADD_BLOG_COMMENT_ERROR:
            return {
                ...state,
                add_blog_comment: payload,
                loading: false,
            }
        case BLOG_COMMENT_LIST_SUCCESS:
            return {
                ...state,
                blog_comment_list: payload,
                loading: false,
            };
        case BLOG_COMMENT_LIST_ERROR:
            return {
                ...state,
                blog_comment_list: payload,
                loading: false,
            }
        default:
            return state;
    }
};