import { FieldText } from '../../components/InputText/InputText';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from "./../../components/Buttons/Buttons";
import { Regex } from '../../Utils/Regex';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ChangePasswordAction, ResetChangePasswordAction } from '../../redux/actions/CompleteProfileAction';

const ChangePassword = ({ panelName }) => {
    const dispatch = useDispatch();
    const [isClick, setIsClick] = useState(false)

    const passwordSelector = useSelector(state => state.profile.change_password);
    const isloading = useSelector(state => state.profile.loading);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const handlePasswordSubmit = (values, { resetForm }) => {
        setIsClick(true)
        dispatch(ChangePasswordAction(panelName, values))
        resetForm();
    }

    useEffect(() => {
        if (Object.keys(passwordSelector).length > 0 && isClick) {
            if (passwordSelector.status) {
                setIsClick(false)
            } else {
                setIsClick(false)
            }
            dispatch(ResetChangePasswordAction())
        }
    }, [passwordSelector, isClick])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        <Formik
            initialValues={{
                old_password: '',
                password: '',
                confirm_password: "",
            }}
            validationSchema={() => Yup.object().shape({
                password: Yup.string().required(languageSelector?.validation?.new_password_required).min(6, languageSelector?.validation?.password_digits).max(16, languageSelector?.validation?.password_digits).matches(Regex.password, languageSelector?.validation?.password_case),
                confirm_password: Yup.string().required(languageSelector?.validation?.confirm_password_required).oneOf([Yup.ref('password'), null], languageSelector?.validation?.password_not_match),
                old_password: Yup.string().required(languageSelector?.validation?.current_password_required)
            })}
            onSubmit={handlePasswordSubmit}
        >
            {formik => {
                return (
                    <Form>
                        <FormikFromFunc />
                        <div className="accountfield row">
                            <div className="accountfield-first col-md-4">
                                <FieldText
                                    name="old_password"
                                    type="password"
                                    showHide={true}
                                    placeholder={languageSelector?.placeholder?.current_password}
                                    defaultValue={formik.values.old_password}
                                />
                            </div>

                            <div className="accountfield-first col-md-4">
                                <FieldText
                                    name="password"
                                    type="password"
                                    showHide={true}
                                    placeholder={languageSelector?.placeholder?.new_passwrod}
                                    defaultValue={formik.values.password}
                                />
                            </div>

                            <div className="accountfield-first col-md-4">
                                <FieldText
                                    name="confirm_password"
                                    type="password"
                                    showHide={true}
                                    placeholder={languageSelector?.placeholder?.re_passwrod}
                                    defaultValue={formik.values.confirm_password}
                                />
                            </div>
                        </div>
                        <div className="update-button">
                            <Button
                                BtnText={languageSelector?.my_account?.update}
                                BtnColor="primary becomaninstructor"
                                BtnType="submit"
                                disabled={!formik.isValid || isloading}
                                hasSpinner={isloading && isClick}
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}
export default ChangePassword;