import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import "../../../../src/style.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StepOneSubscription from "./StepOneSubscription";
import StepTwoSubscription from "./StepTwoSubscription";
import StepThreeSubscription from "./StepThreeSubscription";
import StepHead from "../../../components/MultiStepForm/StepHead";
import { PostInstructorDetail, ResetSubscriptionDetailsAction, SubscriptionDetailsAction } from "../../../redux/actions/instructorAction";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Subscription = () => {
    const dispatch = useDispatch();
    const { id, name } = useParams();
    const [activeStep, setActiveStep] = useState(name);

    const instructorDetailsState = useSelector((state) => state.instructor.instructor_detail);
    const subscriptionDetail = instructorDetailsState?.user_extra_info;
    const SubscriptionStatusSelector = useSelector((state) => state.instructor.subscription_detail)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        let formData = new FormData();
        formData.append("id", id);
        dispatch(PostInstructorDetail(formData));
        dispatch(SubscriptionDetailsAction(id))

        return () => dispatch(ResetSubscriptionDetailsAction())
    }, [id, dispatch]);

    const steps = [
        { label: "Select Plan", key: "selectplan" },
        { label: "Payment Method", key: "payment-method" },
        { label: "Checkout", key: "checkout" },
    ];

    return (
        <>
            <div id="footer-bottom" className="instructorcompleteprofile">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.student_booking?.instructors} </h1>
                    <p> Home / {languageSelector?.student_booking?.instructors} / {languageSelector?.student_booking?.subscription} / <span> {languageSelector?.student_booking?.booking}</span></p>
                </div>

                <div className="completeprofile completeProfile-container">
                    <div className="container">
                        <div className="row">
                            {Object.keys(SubscriptionStatusSelector).length > 0 ?
                                <>
                                    <StepHead data={steps} activeStep={activeStep} />

                                    <div className="step-content-block">
                                        {activeStep === "selectplan" && (<StepOneSubscription currentPlan={SubscriptionStatusSelector?.data?.subscription} setActiveStep={setActiveStep} subscriptionPrice={subscriptionDetail} />)}
                                        {activeStep === "payment-method" && (<StepTwoSubscription setActiveStep={setActiveStep} subscriptionPrice={subscriptionDetail} />)}
                                        {activeStep === "checkout" && (<StepThreeSubscription setActiveStep={setActiveStep} subscriptionPrice={subscriptionDetail} />)}
                                    </div>
                                </>
                                :
                                <>
                                    <div className="complete-profile-step">
                                        <ul className="step-progressbar-ul">
                                            {steps?.map((_, idx) =>
                                                <li key={idx} className="sp-item">
                                                    <Skeleton height={60} width={60} circle />
                                                </li>
                                            )}
                                        </ul>
                                    </div>

                                    <div className="col-md-8 col-sm-12 order-2 order-md-0">
                                        <Skeleton height={310} borderRadius="2.6rem" />
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <Skeleton height={310} borderRadius="2.6rem" />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default Subscription;
