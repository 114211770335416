import React, { useEffect, useRef, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import Dropdown from 'react-bootstrap/Dropdown';
import { useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './Instructorprofile.scss';
import { db, firebaseDB } from '../Auth/firebase';
import { getUserSettings, isValidHttpUrl } from '../../Utils/Function';
import moment from 'moment';
import classNames from 'classnames';
import { baseUrlPostGres } from '../../redux/constant';
import TextErrorMsg from '../../components/TextErrorMsg/TextErrorMsg';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from '../../components/Buttons/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { ChatReportAction, ResetChatReportAction } from '../../redux/actions/ChatAction';
import NoDataFound from '../../components/NoDataFound/NoDataFound';

const Chats = ({ role }) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const containerRef = useRef(null);
    const currentUserDetail = getUserSettings().user
    const [chatMessagesList, setChatMessagesList] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [firebaseChatList, setFirebaseChatList] = useState([])
    const [recieverCounter, setRecieveerCounter] = useState(0);
    const [sendClick, setSendClick] = useState(0);
    const [message, setMessage] = useState('');
    const [searchString, setSearchString] = useState()
    const [reciverIdx, setReciverIdx] = useState(location.state ? location.state.id : "");
    const [reciverName, setReciverName] = useState(location.state ? location.state.full_name : "");
    const [reciverImage, setReciverImage] = useState(location.state ? location.state.profile_image : "");
    const [isBlockUser, setIsBlockUser] = useState(location.state ? location.state?.isBlocked : "");
    const [blockUserID, setBlockUserID] = useState(0);
    const [chatWindowDown, setChatWindowDown] = useState(0)
    const [FCMToken, setFCMToken] = useState();
    const [showTwo, rejectShow] = useState(false);
    const chatReportSelector = useSelector(state => state.chat.report_data)
    const isloading = useSelector(state => state.chat.loading)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const handleShowTwo = () => rejectShow(true);

    useEffect(() => {
        if (Object.keys(chatReportSelector).length > 0) {
            if (chatReportSelector.status) {
                dispatch(ResetChatReportAction())
                rejectShow(false)
            }
        }
    }, [chatReportSelector])

    useEffect(() => {
        if (containerRef && containerRef.current) {
            const element = containerRef.current;
            element.scroll({
                top: element.scrollHeight,
                left: 0,
                behavior: "smooth"
            })
        }
    }, [containerRef, chatList, reciverIdx, chatWindowDown])

    useEffect(() => {
        if (chatMessagesList.length == 0) {
            if (chatList.length > 0) {
                if (!location.state) {
                    setReciverIdx(chatList[0]?.reciverId);
                    setReciverName(chatList[0]?.recieverName);
                    setReciverImage(chatList[0]?.profileImage);
                    setIsBlockUser(chatList[0]?.isBlocked)
                    setTimeout(() => {
                        setChatWindowDown(chatWindowDown + 1)
                    }, 1000);
                }
            }
        }
    }, [chatList])

    //Get Chat List Data
    useEffect(() => {
        if (reciverIdx) {
            db.collection('List')
                .doc(reciverIdx?.toString())
                .get()
                .then((snapshot) => {
                    const token = snapshot.get("fcm_token")
                    setFCMToken(token)
                });
        }

        let messageChatData = [];
        db.collection('List')
            .doc(currentUserDetail.id.toString())
            .collection('userDetails')
            .orderBy("date", "desc")
            .onSnapshot(function (snapshot) {
                snapshot.docChanges().forEach(function (change) {
                    if (change.type === 'modified') {
                        const newData = change.doc.data();
                        const newMap = messageChatData.map((data) =>
                            data.reciverId == newData.reciverId ? newData : data,
                        );
                        messageChatData = newMap;
                    } else {
                        messageChatData.push(change.doc.data());
                    }
                    const newList = messageChatData.sort(function (x, y) {
                        return y.date - x.date;
                    });
                    setChatList(newList);
                    setFirebaseChatList(newList)
                });
            });

        let msgData = [];
        db.collection('Chat')
            .doc(combine2UserId(currentUserDetail.id).toString())
            .collection('messages')
            .orderBy('date')
            // .orderBy('date', 'desc')
            // .limit(50)
            .onSnapshot(function (snapshot) {
                snapshot.docChanges().forEach(function (change) {
                    msgData.push(change.doc.data());
                });
                setChatMessagesList(msgData);
                db.collection('Chat')
                    .doc(combine2UserId(currentUserDetail.id).toString())
                    .get()
                    .then((snapshot) => {
                        const blockuser = snapshot.get("isBlock")
                        const blockuserid = snapshot.get("IsBlockUserId")
                        setBlockUserID(blockuserid)
                        setIsBlockUser(typeof blockuser != "undefined" ? blockuser : false)
                    });
            });


        //set current user count 0
        db.collection('List')
            .doc(currentUserDetail.id.toString())
            .collection('userDetails')
            .get()
            .then((documentSnapshot) => {
                documentSnapshot.docs.forEach(function (change) {
                    if (change.id === reciverIdx.toString()) {
                        db.collection('List')
                            .doc(currentUserDetail.id.toString())
                            .collection('userDetails')
                            .doc(reciverIdx.toString())
                            .update({
                                count: 0,
                            });
                    }
                });
            });
    }, [reciverIdx])

    useEffect(() => {
        // get List data from reciever id
        if (reciverIdx && reciverName) {
            db.collection('List')
                .doc(reciverIdx.toString())
                .collection('userDetails')
                .onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.doc.data().reciverId.toString() === currentUserDetail.id.toString()) {
                            setRecieveerCounter(change.doc.data().count);
                        }
                    });
                });

            // set current user count 0
            db.collection('List')
                .doc(currentUserDetail.id.toString())
                .collection('userDetails')
                .get()
                .then(documentSnapshot => {
                    documentSnapshot.docs.forEach(change => {
                        if (change.id.toString() === reciverIdx.toString()) {
                            db.collection('List')
                                .doc(currentUserDetail.id.toString())
                                .collection('userDetails')
                                .doc(reciverIdx.toString())
                                .update({
                                    count: 0,
                                });
                        }
                    });
                });
        }
    });

    const combine2UserId = (id) => {
        var currentUser = id;
        var userReciever = reciverIdx;
        var chatIDpre = [];
        chatIDpre.push(currentUser);
        chatIDpre.push(userReciever);
        chatIDpre.sort(function (a, b) {
            return a - b;
        });
        return chatIDpre.join('_');
    }


    const sendChatMessage = async (e) => {
        e.preventDefault()
        if (message.trim()) {
            try {
                await db.collection('Chat')
                    .doc(combine2UserId(currentUserDetail.id).toString())
                    .collection('messages')
                    .doc(Date.now().toString())
                    .set({
                        message: message.trim(),
                        date: Date.now(),
                        senderId: currentUserDetail.id,
                        reciverId: Number(reciverIdx),
                        senderName: `${currentUserDetail?.first_name} ${currentUserDetail?.last_name}`,
                        recieverName: reciverName,
                    });

                // chat list of reciever id
                await db.collection('List')
                    .doc(reciverIdx.toString())
                    .collection('userDetails')
                    .doc(currentUserDetail.id.toString())
                    .set({
                        reciverId: Number(currentUserDetail.id),
                        recieverName: `${currentUserDetail?.first_name} ${currentUserDetail?.last_name}`,
                        message: message,
                        date: Date.now(),
                        profileImage: currentUserDetail.profile_image === null ? null : currentUserDetail.profile_image,
                        count: parseInt(recieverCounter) + 1,
                    });

                // chat list db for sender id
                await db.collection('List')
                    .doc(currentUserDetail.id.toString())
                    .collection('userDetails')
                    .doc(reciverIdx.toString())
                    .set({
                        reciverId: reciverIdx.toString(),
                        recieverName: reciverName,
                        message: message,
                        date: Date.now(),
                        profileImage: reciverImage === null ? null : reciverImage,
                        count: 0,
                    });

                sendNotification({ message, sender_name: `${currentUserDetail?.first_name} ${currentUserDetail?.last_name}` });
                setRecieveerCounter((prevCounter) => prevCounter + 1);
                // setSendClick((prevCounter) => prevCounter + 1);
                setMessage('');
            } catch (error) {
                // Handle any errors that might occur during Firestore operations
                console.error('Error sending chat message:', error);
            }
        }
    }

    const setDateValue = (item, index) => {
        var timestemp = new Date(item.date);
        if (index === 0) {
            var preDate = item.date;
            let currentDate = moment(new Date()).format('DD/MM/YYYY');
            let msgDate = moment(preDate).format('DD/MM/YYYY');
            if (currentDate === msgDate) {
                return (
                    <div className="date-split text-center"> Today </div>
                );
            } else {
                return (
                    <div className="date-split text-center"> {moment(timestemp).format('DD MMM YYYY')} </div>
                );
            }
        } else {
            if (moment(preDate).format('DD MMM YYYY') !== moment(item.date).format('DD MMM YYYY')) {
                preDate = item.date;
                let currentDate = moment(new Date()).format('DD/MM/YYYY');
                let msgDate = moment(preDate).format('DD/MM/YYYY');
                if (currentDate === msgDate) {
                    return (
                        <div className="date-split text-center"> Today </div>
                    );
                } else {
                    return (
                        <div className="date-split text-center"> {moment(new Date(item.date)).format('DD MMM YYYY')} </div>
                    );
                }
            }
        }
    }

    //set Chat list time
    const setChatListTime = (item) => {
        var timestemp = new Date(item.date);
        if (moment(timestemp).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) {
            return 'Today';
        } else {
            return moment(timestemp).format('DD/MM/YYYY');
        }
    }

    const handleselectChatOpen = (data) => {
        setReciverIdx(data.reciverId);
        setReciverName(data.recieverName);
        setReciverImage(data.profileImage);
        setTimeout(() => {
            setChatWindowDown(chatWindowDown + 1)
        }, 1000);
        setRecieveerCounter(0)
    };

    const handleSearchFilter = (e) => {
        const { value } = e.target;
        let search_filter_data = firebaseChatList?.filter((data) => {
            let userName = data.recieverName.toLowerCase();
            return userName.includes(value.toLowerCase());
        });
        setSearchString(value)
        setChatList(search_filter_data);
    };

    const sendNotification = (data) => {
        const notification = {
            title: `New message from ${data?.sender_name}`,
            body: data?.message,
            icon: '/images/placeholder-img.jpg',
            click_action: 'https://hireinstructor.sandboxdevelopment.in/',
        };
        const payload = {
            notification: notification,
            to: FCMToken,
        };
        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Authorization: `key=${process.env.REACT_APP_FCM_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                console.log('Notification sent');
            })
            .catch((error) => {
                console.error("Notification sent error", error);
            });
    };

    const handleUserBlocked = () => {
        db.collection('List')
            .doc(currentUserDetail.id.toString()).update({
                blockedUsers: firebaseDB.firestore.FieldValue.arrayUnion(reciverIdx)
            });

        db.collection('Chat')
            .doc(combine2UserId(currentUserDetail.id).toString())
            .set({
                isBlock: true,
                IsBlockUserId: reciverIdx
            });
        setIsBlockUser(true)

        db.collection('Chat')
            .doc(combine2UserId(currentUserDetail.id).toString())
            .collection('messages')
            .doc(Date.now().toString())
            .set({
                label: "blocked",
                message: "",
                date: Date.now(),
                senderId: currentUserDetail.id,
                reciverId: Number(reciverIdx)
            });
    }

    const handleUserUnblocked = () => {
        db.collection('List')
            .doc(currentUserDetail.id.toString()).update({
                blockedUsers: firebaseDB.firestore.FieldValue.arrayRemove(reciverIdx)
            });

        db.collection('Chat')
            .doc(combine2UserId(currentUserDetail.id).toString())
            .set({
                isBlock: false,
                IsBlockUserId: 0
            });
        setIsBlockUser(false)

        db.collection('Chat')
            .doc(combine2UserId(currentUserDetail.id).toString())
            .collection('messages')
            .doc(Date.now().toString())
            .set({
                label: "Unblocked",
                message: "",
                date: Date.now(),
                senderId: currentUserDetail.id,
                reciverId: Number(reciverIdx)
            });

        setTimeout(() => {
            setChatWindowDown(chatWindowDown + 1)
        }, 1000);
    }

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }

    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.chats?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.chats?.page_title} </span></p>
                </div>

                <div className='myprofile-section classesdetails-page'>
                    <div className='container'>
                        <div className='row gx-5'>
                            <div className='col-md-12 col-lg-4 col-xl-4'>
                                {role == 2 ? <Studentsidebar /> : <Instructorsidebar />}
                            </div>

                            <div className='col-md-12 col-lg-8 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.chats?.page_title} </h2>
                                </div>

                                <div className='chat-box-section'>
                                    <div className='row gx-5'>
                                        {chatList.length > 0 ?
                                            <div className='col-lg-5'>
                                                <div className='left-card-box'>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.132" height="16.132" viewBox="0 0 16.132 16.132">
                                                                    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3 -3)">
                                                                        <path id="Path_144" data-name="Path 144" d="M10.26,3A7.26,7.26,0,1,1,5.126,5.126,7.212,7.212,0,0,1,10.26,3Zm0,12.906A5.646,5.646,0,1,0,4.613,10.26,5.653,5.653,0,0,0,10.26,15.906Z" fill="#828282" />
                                                                        <path id="Path_145" data-name="Path 145" d="M27.79,28.6a.8.8,0,0,1-.57-.236l-3.509-3.509a.807.807,0,0,1,1.141-1.141l3.509,3.509a.807.807,0,0,1-.57,1.377Z" transform="translate(-9.465 -9.465)" fill="#828282" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={languageSelector?.chats?.search_here}
                                                            value={searchString}
                                                            onChange={handleSearchFilter}
                                                        />
                                                    </div>
                                                    <div className='user-online-box'>
                                                        <ul>
                                                            {chatList.map((item, index) => {
                                                                return (
                                                                    <li
                                                                        className={classNames(
                                                                            "user-card",
                                                                            { "active": reciverIdx.toString() === item.reciverId.toString() }
                                                                        )}
                                                                        key={index}
                                                                        onClick={() => handleselectChatOpen(item)}
                                                                    >
                                                                        <div className='user-box-main'>
                                                                            <div className='user-images'>
                                                                                <img
                                                                                    src={item.profileImage ?
                                                                                        isValidHttpUrl(item.profileImage) ? item.profileImage
                                                                                            : `${baseUrlPostGres()}${item.profileImage}`
                                                                                        : "/images/blank-profile-picture.jpg"}
                                                                                    referrerPolicy="no-referrer"
                                                                                    alt={item.recieverName}
                                                                                />
                                                                            </div>
                                                                            <div className='user-name-box'>
                                                                                <h2>{item.recieverName}</h2>
                                                                                <p>{item.message}</p>
                                                                            </div>
                                                                            <div
                                                                                className={classNames(
                                                                                    "time-box last",
                                                                                    { "new": item.count > 0 }
                                                                                )}
                                                                            >
                                                                                <h3> {setChatListTime(item)}  </h3>
                                                                                {item.count > 0 ? <div className='massage-number'>{item.count}</div> : null}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            reciverName ?
                                                <div className='col-lg-5'>
                                                    {languageSelector?.chats?.no_chat_list_found}
                                                </div>
                                                :
                                                <NoDataFound />
                                        }

                                        {reciverName &&
                                            <div className='col-lg-7'>
                                                <div className='user-profile-chats'>
                                                    <div className='profile-topbar-section'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='profile-box-top'>
                                                                    <div className='profile-image2'>
                                                                        <img
                                                                            src={reciverImage ?
                                                                                isValidHttpUrl(reciverImage) ? reciverImage
                                                                                    : `${baseUrlPostGres()}${reciverImage}`
                                                                                : "/images/blank-profile-picture.jpg"}
                                                                            referrerPolicy="no-referrer"
                                                                            alt={reciverName}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h4>{reciverName}</h4>
                                                                        {/* <span>Online</span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className="dropdown-report-section">
                                                                    {blockUserID?.toString() !== currentUserDetail.id.toString() ?
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="5.625" height="22.5" viewBox="0 0 5.625 22.5">
                                                                                        <path id="Icon_ionic-md-more" data-name="Icon ionic-md-more" d="M20.813,9.563A2.813,2.813,0,1,0,18,12.375,2.821,2.821,0,0,0,20.813,9.563Zm0,16.875A2.813,2.813,0,1,0,18,29.25,2.821,2.821,0,0,0,20.813,26.438Zm0-8.437A2.813,2.813,0,1,0,18,20.813,2.821,2.821,0,0,0,20.813,18Z" transform="translate(-15.188 -6.75)" fill="#828282" />
                                                                                    </svg>
                                                                                </span>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item className="user-card" onClick={handleShowTwo} >{languageSelector?.chats?.report}</Dropdown.Item>
                                                                                {!isBlockUser ?
                                                                                    <Dropdown.Item className="user-card" onClick={handleUserBlocked}>{languageSelector?.chats?.block}</Dropdown.Item>
                                                                                    :
                                                                                    <Dropdown.Item className="user-card" onClick={handleUserUnblocked}> {languageSelector?.chats?.unblock} </Dropdown.Item>
                                                                                }
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        !isBlockUser ?
                                                            <>
                                                                <div className={`conversation-part ${chatMessagesList?.length == 0 ? "no-chats" : ""}`} ref={containerRef}>
                                                                    {chatMessagesList?.length > 0 ?
                                                                        <ul>
                                                                            {chatMessagesList.map((item, index) => (
                                                                                <li key={index} className={`message ${item.reciverId.toString() !== currentUserDetail.id.toString() ? 'right-side' : 'left-side'}`}>
                                                                                    {setDateValue(item, index)}
                                                                                    {item?.label ?
                                                                                        <small className='text-center d-block'>{languageSelector?.chats?.you_are} {item?.label} </small>
                                                                                        :
                                                                                        <>
                                                                                            <div className='conversation-list'>
                                                                                                <h5>{item.message}</h5>
                                                                                            </div>
                                                                                            <div className='time-list'>
                                                                                                <p>{moment(new Date(item.date)).format('hh:mm a')}</p>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                        :
                                                                        <div className='nochat-found'>
                                                                            <h2> {languageSelector?.chats?.no_messages_yet} </h2>
                                                                            <p> {languageSelector?.chats?.havent_initiated_conversation} </p>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                {reciverName &&
                                                                    <form onSubmit={sendChatMessage} className="type-bottom-section">
                                                                        <input value={message} onChange={(e) => setMessage(e.target.value)} className="msg-input" placeholder={`${languageSelector?.chats?.write}.....`} />
                                                                        <button type='submit'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.229" height="20.227" viewBox="0 0 20.229 20.227">
                                                                                <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M24.094,4.548,4.754,12.978a.443.443,0,0,0,.016.806L10,16.74a.844.844,0,0,0,.964-.095L21.281,7.751c.068-.058.232-.169.3-.105s-.037.227-.095.3L12.556,17.994A.841.841,0,0,0,12.472,19l3.419,5.485a.445.445,0,0,0,.8-.011l8-19.341A.443.443,0,0,0,24.094,4.548Z" transform="translate(-4.503 -4.503)" fill="#fff" />
                                                                            </svg>
                                                                        </button>
                                                                    </form>
                                                                }
                                                            </>
                                                            :
                                                            <div className='conversation-part'>
                                                                <div className='text-center blocked-text'>
                                                                    {languageSelector?.chats?.user_is_blocked}
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <Modal className="center-modal class-modal problemsolving rejectbooking rejectbookingplace" show={showTwo} onHide={() => rejectShow(false)}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> {languageSelector?.chats?.report} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="blogdetails-modal">
                    <Formik
                        initialValues={{
                            chat_reason: "",
                            comment: "",
                        }}
                        validationSchema={() =>
                            Yup.object().shape({
                                chat_reason: Yup.string().required(languageSelector?.validation?.reason_report),
                                comment: Yup.string().required(languageSelector?.validation?.describe_reason)
                            })
                        }
                        onSubmit={(value) => {
                            dispatch(ChatReportAction({ ...value, report_userId: currentUserDetail.id, against_userId: reciverIdx }))
                        }}
                    >
                        {formik => {
                            return (
                                <Form>
                                <FormikFromFunc />
                                    <div className="classtype-section dropdown-select fix-placeholder">
                                        <div className="App">
                                            <Field as="select" name="chat_reason" className='select-control w-100'>
                                                <option value=""> {languageSelector?.chats?.select_reason} </option>
                                                <option value="1"> {languageSelector?.chats?.inappropriate_offensive_behavior} </option>
                                                <option value="2"> {languageSelector?.chats?.violation_of_policies_guidelines} </option>
                                                <option value="3"> {languageSelector?.chats?.harassment_bullying} </option>
                                                <option value="4"> {languageSelector?.chats?.security_concerns} </option>
                                                <option value="5"> {languageSelector?.chats?.scams_fraud} </option>
                                                <option value="6"> {languageSelector?.chats?.other_concerns} </option>
                                            </Field>
                                            <ErrorMessage name="chat_reason" component={TextErrorMsg} />
                                            <span className="placeholder-name"> {languageSelector?.chats?.reason_report} </span>
                                        </div>
                                    </div>
                                    <div className="form-group floating-label textarea-limit-block">
                                        <Field name="comment">
                                            {({ form, field }) => {
                                                return (
                                                    <>
                                                        <textarea
                                                            className="form-control"
                                                            rows="5"
                                                            placeholder=" "
                                                            name={field.name}
                                                            maxLength="250"
                                                            onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                                                        ></textarea>
                                                        <label htmlFor="textarea"> {languageSelector?.placeholder?.describe_reason} </label>
                                                        <span className="limit">250 {languageSelector?.placeholder?.characters_limit}</span>
                                                    </>
                                                )
                                            }}
                                        </Field>
                                    </div>
                                    <div className='text-start'> <ErrorMessage name="comment" component={TextErrorMsg} /></div>

                                    <Modal.Footer className="w-100 p-0 mt-5 mb-4">
                                        <div className="email-send">
                                            <div className="accept">
                                                <Button
                                                    BtnText={languageSelector?.chats?.submit}
                                                    name="submit"
                                                    BtnColor="primary"
                                                    BtnType="submit"
                                                    disabled={!formik.isValid || isloading}
                                                    hasSpinner={isloading}
                                                />
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Chats
