import moment from "moment"
import { baseUrlPostGres } from "../../redux/constant"
import { useSelector } from "react-redux"

const RatingsReviews = ({ instructorDetails }) => {
    const languageSelector = useSelector(state => state.home.dynamic_language)
    return (
        <div className="about-description">
            <div className="groupclasses ratingsreviews">
                <div className="row gx-5">
                    <div className="col-lg-6">
                        <h2> {languageSelector?.rating?.page_title} </h2>
                        <p className="rating-p">
                            {instructorDetails?.ratingavg ?
                                <>
                                    {instructorDetails?.ratingavg && [...new Array(Math.ceil(Number(instructorDetails?.ratingavg)))].map((_, index) => {
                                        return (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25"
                                                height="24"
                                                viewBox="0 0 25 24"
                                                key={index}
                                            >
                                                <path
                                                    id="Icon_metro-star-full"
                                                    data-name="Icon metro-star-full"
                                                    d="M27.571,11.684l-9-1-3-8-4,8-9,1,6,6-1,9,8-4,7,4-1-9Z"
                                                    transform="translate(-2.571 -2.684)"
                                                    fill="#fed756"
                                                ></path>
                                            </svg>
                                        )
                                    })}

                                    {instructorDetails?.ratingavg && [...new Array((5 - Number(instructorDetails?.ratingavg).toFixed(0)))].map((_, index) => {
                                        return (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25.12"
                                                height="23.891"
                                                viewBox="0 0 25.12 23.891"
                                                key={index}
                                            >
                                                <path
                                                    id="Icon_metro-star-full"
                                                    data-name="Icon metro-star-full"
                                                    d="M27.691,11.808l-8.679-1.261L15.131,2.683l-3.881,7.864L2.571,11.808l6.28,6.121L7.368,26.573l7.762-4.081,7.762,4.081L21.411,17.93l6.28-6.121Z"
                                                    transform="translate(-2.571 -2.683)"
                                                    fill="#dcdcdc"
                                                ></path>
                                            </svg>
                                        )
                                    })}
                                </>
                                :
                                languageSelector?.rating?.no_rating
                            }
                            <span>({instructorDetails?.UserRatingCount ? instructorDetails?.UserRatingCount : 0})</span>
                        </p>
                    </div>
                </div>

                {instructorDetails.instructorreview && instructorDetails.instructorreview.map((value, index) => {
                    return (
                        <div className="reviews-person-main-section" key={index}>
                            <div className="reviews-person">
                                <div className="ratings-box-card">
                                    <div className="reviews-image">
                                        <img src={value?.user_rating?.profile_image ? `${baseUrlPostGres()}${value?.user_rating?.profile_image}` : "/images/AnnaLisy.png"} alt="" />
                                    </div>
                                    <div className="name-star">
                                        <h4> {value?.user_rating?.first_name} {value?.user_rating?.last_name} </h4>
                                        <p className="rating-p">
                                            {value?.rating ?
                                                <>
                                                    {value?.rating && [...new Array(Number(value?.rating))].map((_, index) => {
                                                        return (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="15.217"
                                                                viewBox="0 0 16 15.217"
                                                                key={index}
                                                            >
                                                                <path
                                                                    id="Icon_metro-star-full"
                                                                    data-name="Icon metro-star-full"
                                                                    d="M18.571,8.5l-5.528-.8L10.571,2.683,8.1,7.692l-5.528.8,4,3.9L5.626,17.9l4.944-2.6,4.944,2.6-.944-5.505,4-3.9Z"
                                                                    transform="translate(-2.571 -2.683)"
                                                                    fill="#fed756"
                                                                />
                                                            </svg>
                                                        )
                                                    })}

                                                    {value?.rating && [...new Array((5 - Number(value?.rating)))].map((_, index) => {
                                                        return (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="15.217"
                                                                viewBox="0 0 16 15.217"
                                                                key={index}
                                                            >
                                                                <path
                                                                    id="Icon_metro-star-full"
                                                                    data-name="Icon metro-star-full"
                                                                    d="M18.571,8.5l-5.528-.8L10.571,2.683,8.1,7.692l-5.528.8,4,3.9L5.626,17.9l4.944-2.6,4.944,2.6-.944-5.505,4-3.9Z"
                                                                    transform="translate(-2.571 -2.683)"
                                                                    fill="#dcdcdc"
                                                                />
                                                            </svg>
                                                        )
                                                    })}
                                                </>
                                                :
                                                languageSelector?.rating?.no_rating
                                            }
                                            <span>({value?.rating})</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="date-botton">
                                    <div className="btn becomaninstructor"> {moment(value.created_at).format("DD MMM'YY")} </div>
                                </div>
                            </div>
                            <p> {value?.comments} </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RatingsReviews