import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

// const PrivateRoutes = ({ role }) => {
//   const isAuthenticated = JSON.parse(localStorage.getItem("security_auth"));
//   return isAuthenticated ? (
//     <Outlet />
//   ) : role === 2 ? (
//     <Navigate to="/student-login" />
//   ) : (
//     <Navigate to="/instructor-login" />
//   );
// };

const PrivateRoutes = ({ allowedRoles }) => {
    const userData = JSON.parse(localStorage.getItem("security_auth"));
    const auth = userData?.user?.role
    const location = useLocation();
    return (
        allowedRoles?.includes(auth)
            ? <Outlet />
            : userData
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    );
}

export default PrivateRoutes;
