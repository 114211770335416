import React, { useEffect, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import { Tab, Tabs } from 'react-bootstrap';
import './Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ClassesDetailsListAction, ResetClassesDetailsListAction } from '../../redux/actions/ClassesDetailActions';
import Upcoming from './ClassDetailsComponent/Upcoming';
import Completed from './ClassDetailsComponent/Completed';
import Cancelled from './ClassDetailsComponent/Cancelled';
import 'react-loading-skeleton/dist/skeleton.css'
import { ResetInstructorBookingCanceltAction } from '../../redux/actions/instructorAction';

const Classesdetails = () => {
    
    const dispatch = useDispatch()
    const [classesDetailData, setClassesDetailData] = useState();
    const [tabKey, setTabKey] = useState("1")
    const classDetailSelector = useSelector(state => state.classesDetail.class_detail_list)
    const cancelRequestSelector = useSelector(state => state.instructor.reason_cancellation)
    const ratingSubmitSelector = useSelector(state => state.rating.add_rating_review)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const [currentPage, setCurrentPage] = useState(1)
    const [typeBooking, setTypeBooking] = useState("all")

    useEffect(() => {
        dispatch(ClassesDetailsListAction("instructor", { type_class: tabKey, type_booking: typeBooking === "all" ? "" : typeBooking, page: currentPage }))
        return () => dispatch(ResetClassesDetailsListAction())
    }, [dispatch, tabKey, currentPage])

    useEffect(() => {
        if (Object.keys(classDetailSelector).length > 0) {
            setClassesDetailData(classDetailSelector.data)
        }
    }, [classDetailSelector])

    useEffect(() => {
        if (Object.keys(cancelRequestSelector).length > 0) {
            if (cancelRequestSelector.status) {
                dispatch(ResetInstructorBookingCanceltAction())
                dispatch(ClassesDetailsListAction("instructor", { type_class: tabKey, type_booking: typeBooking === "all" ? "" : typeBooking }))
            }
        }
    }, [cancelRequestSelector])

    const handleSelect = (e) => {
        setTabKey(e)
        setTypeBooking("all")
    }
    
    useEffect(() => {
        if (Object.keys(ratingSubmitSelector).length > 0) {
            if (ratingSubmitSelector.status) {
                dispatch(ClassesDetailsListAction("instructor", { type_class: tabKey, type_booking: typeBooking === "all" ? "" : typeBooking, page: currentPage }))
            }
        }
    }, [ratingSubmitSelector])

    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.classes_details?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.classes_details?.page_title} </span></p>
                </div>

                <div className='myprofile-section classesdetails-page'>
                    <div className='container'>
                        <div className='row gx-5'>
                            <div className='col-md-12 col-lg-4 col-xl-4'>
                                <Instructorsidebar />
                            </div>

                            <div className='col-md-12 col-lg-8 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.classes_details?.page_title} </h2>
                                </div>

                                <div className='booking-request-section classes-tabs-three'>
                                    <Tabs
                                        defaultActiveKey="1"
                                        onSelect={handleSelect}
                                    >
                                        <Tab eventKey="1" title={languageSelector?.classes_details?.upcoming_class}>
                                            <Upcoming setTypeBooking={setTypeBooking} typeBooking={typeBooking} currentPage={currentPage} setCurrentPage={setCurrentPage} selector={classDetailSelector} classesDetailData={classesDetailData} />
                                        </Tab>

                                        <Tab eventKey="4" title={languageSelector?.classes_details?.completed_class}>
                                            <Completed setTypeBooking={setTypeBooking} typeBooking={typeBooking} currentPage={currentPage} setCurrentPage={setCurrentPage} selector={classDetailSelector} classesDetailData={classesDetailData} />
                                        </Tab>

                                        <Tab eventKey="6" title={languageSelector?.classes_details?.cancelled_class}>
                                            <Cancelled setTypeBooking={setTypeBooking} typeBooking={typeBooking} currentPage={currentPage} setCurrentPage={setCurrentPage} selector={classDetailSelector} classesDetailData={classesDetailData} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Classesdetails
