import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import TextErrorMsg from "../../components/TextErrorMsg/TextErrorMsg";
import Button from "../../components/Buttons/Buttons";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CancellationReasonListAction, InstructorBookingCanceltAction } from '../../redux/actions/instructorAction';
import { useState } from 'react';

const CancelRequestModal = (props) => {
    const { onHide, name, id, ...rest } = props;
    const dispatch = useDispatch()
    const [submitClick, setSubmitClick] = useState(false)
    const reasonList = useSelector(state => state.instructor.reason_list)
    const isloading = useSelector(state => state.instructor.loading)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(CancellationReasonListAction())
        return () => setSubmitClick(false)
    }, [dispatch])

    const handleRejectSubmit = (value) => {
        setSubmitClick(true)
        dispatch(InstructorBookingCanceltAction(name, { ...value, bookingId: id }))
    }

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        <Modal
            {...rest}
            centered
            backdrop="static"
            keyboard={false}
            className="center-modal class-modal problemsolving rejectbooking rejectbookingplace"
        >
            <Modal.Header>
                <Modal.Title> {languageSelector?.classes_details?.cancel_booking} </Modal.Title>
                <button type="button" className="btn-close" onClick={onHide}></button>
            </Modal.Header>
            <Modal.Body className="blogdetails-modal">
                <Formik
                    initialValues={{
                        rejectId: "",
                        reject_description: "",
                    }}
                    validationSchema={() =>
                        Yup.object().shape({
                            rejectId: Yup.string().required(languageSelector?.validation?.reason_cancellation),
                            reject_description: Yup.string().required(languageSelector?.validation?.describe_reason)
                        })
                    }
                    onSubmit={handleRejectSubmit}
                >
                    {formik => {
                        return (
                            <Form>
                            <FormikFromFunc />
                                <div className="classtype-section dropdown-select fix-placeholder">
                                    <div className="App">
                                        <Field as="select" name="rejectId" className='select-control w-100'>
                                            <option value=""> {languageSelector?.placeholder?.select_reason} </option>
                                            {reasonList && reasonList.map((value, index) => {
                                                return (
                                                    <option value={value.id} key={index}> {value.reject_message} </option>
                                                )
                                            })}
                                        </Field>
                                        <ErrorMessage name="rejectId" component={TextErrorMsg} />
                                        <span className="placeholder-name"> {languageSelector?.placeholder?.reason} </span>
                                    </div>
                                </div>
                                <div className="form-group floating-label textarea-limit-block">
                                    <Field name="reject_description">
                                        {({ form, field }) => {
                                            return (
                                                <>
                                                    <textarea
                                                        className="form-control"
                                                        rows="5"
                                                        placeholder=" "
                                                        name={field.name}
                                                        maxLength="250"
                                                        onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                                                    ></textarea>
                                                    <label htmlFor="textarea"> {languageSelector?.placeholder?.describe_reason} </label>
                                                    <span className="limit">250 {languageSelector?.placeholder?.characters_limit}</span>
                                                </>
                                            )
                                        }}
                                    </Field>
                                </div>
                                <div className='text-start'> <ErrorMessage name="reject_description" component={TextErrorMsg} /></div>

                                <Modal.Footer className="w-100 p-0 mt-5 mb-4">
                                    <div className="email-send">
                                        <div className="accept">
                                            <Button
                                                BtnText={languageSelector?.classes_details?.submit}
                                                name="submit"
                                                BtnColor="primary"
                                                BtnType="submit"
                                                disabled={(!formik.isValid || isloading) && submitClick}
                                                hasSpinner={isloading && submitClick}
                                            />
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default CancelRequestModal