import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import TextErrorMsg from "../../../components/TextErrorMsg/TextErrorMsg";
import Button from "../../../components/Buttons/Buttons";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const StepOneSubscription = ({ currentPlan, setActiveStep, subscriptionPrice }) => {
    const { id, name } = useParams();
    const navigate = useNavigate()
    const [sideBarDetails, setSideBarDetails] = useState();

    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        setActiveStep(name)
    }, [name])

    const handleOnChange = (event) => {
        setSideBarDetails(event.target.value)
    }

    const handleStepOneSubmit = (value) => {
        if (currentPlan) {
            toast.error(`You are already subscribed the ${currentPlan == 1 ? "monthly" : "yearly"} plan`, { theme: "colored" })
        } else {
            navigate(`/subscription/payment-method/${id}`, { state: { ...value, "subscription": sideBarDetails } });
            setActiveStep('payment-method');
        }
    }
    
    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        <>
            <div className="personaldetails-main-section on-demand-class-type" style={{ display: name === 'selectplan' ? 'block' : 'none' }}>
                <h2> {languageSelector?.student_booking?.select_plan} </h2>
                <div className="on-demand-class-content">
                    <Formik
                        initialValues={{
                            select_plan: currentPlan ? currentPlan : ""
                        }}
                        validationSchema={() =>
                            Yup.object().shape({
                                select_plan: Yup.string().required(languageSelector?.validation?.select_plan)
                            })
                        }
                        onSubmit={handleStepOneSubmit}
                    >
                        {formik => {
                            return (
                                <Form onChange={handleOnChange}>
                                    <FormikFromFunc />
                                    <div className="row g-5">
                                        <div className="col-md-8 col-sm-12">
                                            <div className="personaldetails-section">
                                                <div className="select-subject-level-card payment-box-main-content selectplan">
                                                    <div className="paymentmethod-box">
                                                        <div className="payment-three-card row">
                                                            <div className="col-md-6">
                                                                <div className="cardnumber-box">
                                                                    <div className="radio-box selectdatetime-section">
                                                                        <div className="check-box-create">
                                                                            {currentPlan == 1 ? <div className="plan_label"> {languageSelector?.student_booking?.current_plan} </div> : null}
                                                                            <Field name="select_plan">
                                                                                {({ form, field }) => {
                                                                                    return (
                                                                                        <>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="card1"
                                                                                                name={field.name}
                                                                                                // defaultChecked={formik.values.select_plan == "1" ? true : false}
                                                                                                //1 is Monthly Subscription
                                                                                                value="1"
                                                                                                onChange={e => form.setFieldValue(field.name, e.target.checked)}
                                                                                            />
                                                                                            <label htmlFor="card1">
                                                                                                <h3> {languageSelector?.student_booking?.monthly_subscription} </h3>
                                                                                                <p> {languageSelector?.student_booking?.monthly_subscription_des} </p>
                                                                                                <span>${subscriptionPrice?.monthly_subscription_price ? subscriptionPrice?.monthly_subscription_price : 0}</span>
                                                                                            </label>
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="cardnumber-box">
                                                                    <div className="radio-box selectdatetime-section">
                                                                        <div className="check-box-create">
                                                                            {currentPlan == 2 ? <div className="plan_label"> {languageSelector?.student_booking?.current_plan} </div> : null}
                                                                            <Field name="select_plan">
                                                                                {({ form, field }) => {
                                                                                    return (
                                                                                        <>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="card2"
                                                                                                name={field.name}
                                                                                                //1 is Yearly Subscription
                                                                                                // defaultChecked={formik.values.select_plan == "2" ? true : false}
                                                                                                value="2"
                                                                                                onChange={e => form.setFieldValue(field.name, e.target.checked)}
                                                                                            />
                                                                                            <label htmlFor="card2">
                                                                                                <h3> {languageSelector?.student_booking?.yearly_subscription} </h3>
                                                                                                <p> {languageSelector?.student_booking?.yearly_subscription_des} </p>
                                                                                                <span>${subscriptionPrice?.year_subscription_price ? subscriptionPrice?.year_subscription_price : 0}</span>
                                                                                            </label>
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="select_plan" component={TextErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-center pt-5">
                                                <Button
                                                    BtnText={languageSelector?.student_booking?.next}
                                                    name="next"
                                                    BtnColor="primary"
                                                    BtnType="submit"
                                                    disabled={!formik.isValid}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <div className="personaldetails-section payable-amount-card-box">
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        <span className="amount-text"> {languageSelector?.student_booking?.payable_amount} </span>
                                                    </div>

                                                    <div className="col-4 text-end">
                                                        <h3 className="amount-price">${sideBarDetails ? sideBarDetails == 1 ? subscriptionPrice.monthly_subscription_price : subscriptionPrice.year_subscription_price : 0}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default StepOneSubscription;