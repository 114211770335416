import Modal from "react-bootstrap/Modal";
import { baseUrlPostGres } from "../redux/constant";
import Button from "./Buttons/Buttons";
import { useSelector } from "react-redux";

const ProblemSolvingDetailModal = (props) => {
    const { AcceptBooking, detail, rejected, onHide, onSubmit, clickStatus, loading, ...rest } = props;
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const getFileName = (files) => {
        var n = files?.lastIndexOf('/')
        var result = files?.substring(n + 1);
        return result
    }
    return (
        <Modal
            {...rest}
            centered
            backdrop="static"
            keyboard={false}
            className="center-modal class-modal problemsolving"
        >
            <Modal.Header>
                <Modal.Title> {languageSelector?.booking_request?.Problem_solving} </Modal.Title>
                <button type="button" className="btn-close" onClick={onHide}></button>
            </Modal.Header>
            <Modal.Body className="blogdetails-modal">
                <div className="classtype-section">
                    <h2> {languageSelector?.booking_request?.subject_level} </h2>
                    <p>{detail?.user_tutor_subject?.tutor_sub_name} ({detail?.class_level?.class_name})</p>
                    <h2> {languageSelector?.booking_request?.title} </h2>
                    <p>{detail?.tittle}</p>
                    <h2> {languageSelector?.booking_request?.detailed_problem} </h2>
                    <p> {detail?.description} </p>

                    {detail?.file_doc ?
                        <div className="pdf-dection">
                            <h2> {languageSelector?.booking_request?.attachment} </h2>

                            <div className="pdf-img">
                                <div className="pdf-img-box">
                                    <img src="/images/pdf-icon.png" alt="" />
                                </div>
                                <div className="pdf-content">
                                    <p> {getFileName(detail?.file_doc)} </p>
                                    <button className="btn" onClick={() => window.open(`${baseUrlPostGres()}${detail?.file_doc}`)}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.5"
                                            height="17.5"
                                            viewBox="0 0 14.5 17.5"
                                        >
                                            <g
                                                id="Group_4802"
                                                data-name="Group 4802"
                                                transform="translate(-12507.25 -9581.25)"
                                            >
                                                <path
                                                    id="Path_650"
                                                    data-name="Path 650"
                                                    d="M14,3V6.556a.889.889,0,0,0,.889.889h3.556"
                                                    transform="translate(12502.556 9579)"
                                                    fill="none"
                                                    stroke="#005eb5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1.5"
                                                />
                                                <path
                                                    id="Path_651"
                                                    data-name="Path 651"
                                                    d="M16.143,19H6.857A1.819,1.819,0,0,1,5,17.222V4.778A1.819,1.819,0,0,1,6.857,3h6.5L18,7.444v9.778A1.819,1.819,0,0,1,16.143,19Z"
                                                    transform="translate(12503 9579)"
                                                    fill="none"
                                                    stroke="#005eb5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1.5"
                                                />
                                                <line
                                                    id="Line_178"
                                                    data-name="Line 178"
                                                    y2="5"
                                                    transform="translate(12514.268 9589)"
                                                    fill="none"
                                                    stroke="#005eb5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1.5"
                                                />
                                                <path
                                                    id="Path_652"
                                                    data-name="Path 652"
                                                    d="M9,14l2.667,2.667L14.333,14"
                                                    transform="translate(12502.834 9577.777)"
                                                    fill="none"
                                                    stroke="#005eb5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1.5"
                                                />
                                            </g>
                                        </svg>
                                        {languageSelector?.booking_request?.download}
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="email-send">
                    <div className="reject">
                        <Button
                            BtnText={languageSelector?.booking_request?.reject}
                            BtnColor=""
                            BtnType="button"
                            onClick={() => rejected(detail)}
                        />
                    </div>
                    <div className="accept">
                        <Button
                            BtnText={languageSelector?.booking_request?.accept}
                            BtnColor=""
                            BtnType="button"
                            disabled={loading && clickStatus}
                            hasSpinner={loading && clickStatus}
                            onClick={() => AcceptBooking(detail, detail.index)}
                        />
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ProblemSolvingDetailModal