import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (item) => {
    setCartItems([...cartItems, item]);
  };

  const removeItem = (index) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
  };

  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(savedCartItems);
}, []);

useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);
  
const updateCartItems = (items) => {
    setCartItems(items);
    localStorage.setItem("cartItems", JSON.stringify(items));
};

  return (
    <CartContext.Provider value={{ cartItems, addToCart, setCartItems, removeItem, updateCartItems }}>
      {children}
    </CartContext.Provider>
  );
};
