import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CMSListAction, ResetCMSListAction } from "../../redux/actions/CMSAction";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const TermsConditions = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation()
    const cmsDataSelector = useSelector(state => state.cms.cms_data)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(CMSListAction(pathname.split("/").pop()))
        return () => dispatch(ResetCMSListAction())
    }, [dispatch])

    return (
        <div id="footer-bottom" className="Instructordetail">
            <Header />
            <div className="instru-bg-image">
                <h1>{languageSelector?.cms?.privacy_policy} </h1>
                <p> Home /<span> {languageSelector?.cms?.privacy_policy} </span> </p>
            </div>

            <div className="termsconditions">
                <div className="container">
                    <div className="row">
                        {Object.keys(cmsDataSelector).length > 0 ?
                            cmsDataSelector?.data && Object.keys(cmsDataSelector?.data).length > 0 ?
                                <div className="col-md-12 privacy-table-policy" dangerouslySetInnerHTML={{ __html: cmsDataSelector?.data?.descriptions }} />
                                :
                                <NoDataFound />
                            :
                            <div className="col-md-12">
                                <Skeleton height={500} borderRadius="2.6rem" />
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default TermsConditions;

