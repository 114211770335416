import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../style.scss";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    GetBlogs,
    GetBlogsCategory,
} from "../../redux/actions/HomePageActions";
import { baseUrlPostGres } from "../../redux/constant";
import moment from "moment";
import { Pagination } from "../../components/Hooks/Pagination";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

let PageSize = 9;

const Blog = () => {
    const dispatch = useDispatch();
    const { state } = useLocation()
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [currentTab, setCurrentTab] = useState("All")

    const blogs = useSelector((state) => state.home?.get_blogs);
    const blogsCategory = useSelector((state) => state.home?.get_blogs_category);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        if (blogs) {
            setItems(blogs?.data?.slice(firstPageIndex, lastPageIndex));
        }
    }, [currentPage]);

    useEffect(() => {
        if (Object.keys(blogs).length > 0) {
            if (state) {
                setCurrentTab(state.cat_id)
                const upadtedItems = blogs?.data.filter((curElem) => {
                    return curElem.blog_category?.blog_category === state.cat_id;
                });
                setItems(upadtedItems);
            }
            else {
                setCurrentTab("All")
                setItems(blogs?.data);
            }
        }
    }, [state, blogs]);

    const filterItem = (categItem) => {
        setCurrentTab(categItem)
        const upadtedItems = blogs?.data.filter((curElem) => {
            return curElem.blog_category?.blog_category === categItem;
        });
        setItems(upadtedItems);
    };

    useEffect(() => {
        dispatch(GetBlogs({ page_no: currentPage }));
        dispatch(GetBlogsCategory());
    }, [dispatch, currentPage]);

    
    return (
        <>
            <div id="footer-bottom" className="blog">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.blogs_page?.page_title} </h1>
                    <p>
                        Home / <span>{languageSelector?.blogs_page?.page_title}</span>
                    </p>
                </div>
                <div className="easyfind-box">
                    <div className="easy-box-ab">
                        <div className="container">
                            <div className="easy-box-content">
                                <div className="menu-blog">
                                    <div
                                        className={`btn becomaninstructor ${currentTab == "All" ? "active" : ""}`}
                                        onClick={() => {
                                            setItems(blogs?.data)
                                            setCurrentTab("All")
                                        }}
                                    > All </div>
                                    {blogsCategory?.map(elem => (
                                        <div
                                            key={elem?.id}
                                            className={`btn becomaninstructor ${currentTab == elem.blog_category ? "active" : ""}`}
                                            onClick={() => filterItem(elem.blog_category)}
                                        >
                                            {elem.blog_category}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="blog-page-section">
                    <div className="container">
                        <div className="row g-4">
                            {Object.keys(blogs).length > 0 ?
                                items?.length > 0 ?
                                    items?.map((elem) => (
                                        <div
                                            key={elem?.id}
                                            className="col-lg-4 col-md-6 col-sm-6 col-12"
                                        >
                                            <div className="bolg-card-main">
                                                <div className="image-box">
                                                    <Link to={`/blogdetail/${elem.id}`}>
                                                        <img src={`${baseUrlPostGres()}${elem?.blog_image}`} alt={elem?.blog_title} />
                                                    </Link>
                                                </div>
                                                <div className="date-section">
                                                    <div className="date">
                                                        <div className="btn becomaninstructor no-cursor">
                                                            {moment(elem.created_at).format("DD MMM'YY")}
                                                        </div>
                                                    </div>
                                                    <div className="categ">
                                                        <div className="btn becomaninstructor no-cursor">
                                                            <span>{elem?.blog_category?.blog_category}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h1><Link className="h1" to={`/blogdetail/${elem.id}`}>{elem?.blog_title}</Link></h1>

                                                <p className="name-comment-section">
                                                    <span className="name-customer">
                                                        By <span> {elem.blog_user?.first_name} {elem.blog_user?.last_name}</span>
                                                    </span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="12.535"
                                                        height="11.268"
                                                        viewBox="0 0 12.535 11.268"
                                                    >
                                                        <g
                                                            id="Group_221"
                                                            data-name="Group 221"
                                                            transform="translate(-1022.787 -658)"
                                                        >
                                                            <path
                                                                id="Path_141"
                                                                data-name="Path 141"
                                                                d="M1108.784,661.66h3.244a1.214,1.214,0,0,1,1.269,1.261,3.506,3.506,0,0,1-.338,1.631c-.573,1.237-1.114,2.488-1.668,3.734a1.539,1.539,0,0,1-1.513.98c-1.209,0-2.418,0-3.627,0a1.958,1.958,0,0,1-1.473-.612q-.3-.306-.6-.6a.427.427,0,0,1-.135-.327c0-1.512,0-3.025.006-4.537a.428.428,0,0,1,.106-.288,20.475,20.475,0,0,0,2.776-3.444c.217-.3.438-.6.647-.908a1.075,1.075,0,0,1,1.151-.527,1.182,1.182,0,0,1,.92.908,1.4,1.4,0,0,1-.064.961c-.218.539-.433,1.079-.648,1.619C1108.819,661.555,1108.805,661.6,1108.784,661.66Z"
                                                                transform="translate(-77.977)"
                                                                fill="#005eb5"
                                                                fillRule="evenodd"
                                                            />
                                                            <path
                                                                id="Path_142"
                                                                data-name="Path 142"
                                                                d="M1022.79,776.058c0-.7-.008-1.4,0-2.1a1.012,1.012,0,0,1,.619-.94,1.04,1.04,0,0,1,1.123.111,1,1,0,0,1,.434.828c.005,1.4.008,2.81,0,4.214a1.088,1.088,0,0,1-2.173.017c-.014-.709,0-1.419,0-2.129Z"
                                                                transform="translate(0 -110.402)"
                                                                fill="#005eb5"
                                                                fillRule="evenodd"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <span className="like-number"> {elem?.bloglike_count} </span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="11.469"
                                                        height="11.268"
                                                        viewBox="0 0 11.469 11.268"
                                                    >
                                                        <g
                                                            id="Icon_ionic-ios-chatbubbles"
                                                            data-name="Icon ionic-ios-chatbubbles"
                                                            transform="translate(-8.177 -3.375)"
                                                        >
                                                            <path
                                                                id="Path_143"
                                                                data-name="Path 143"
                                                                d="M18.558,12.367a.8.8,0,0,1,.109-.4,1.11,1.11,0,0,1,.069-.1,5.345,5.345,0,0,0,.91-2.979,5.611,5.611,0,0,0-5.729-5.508A5.69,5.69,0,0,0,8.3,7.759a5.3,5.3,0,0,0-.122,1.128,5.6,5.6,0,0,0,5.64,5.578,6.793,6.793,0,0,0,1.557-.254c.373-.1.742-.237.838-.274a.872.872,0,0,1,.307-.056.858.858,0,0,1,.333.066l1.87.663a.446.446,0,0,0,.129.033.263.263,0,0,0,.264-.264.424.424,0,0,0-.016-.089Z"
                                                                fill="#005eb5"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <span>{elem?.blog_comment_count}</span>
                                                </p>

                                                <p className="desceiption-button">
                                                    {elem?.short_blog_description}
                                                    <Link className="btn" to={`/blogdetail/${elem?.id}`}>
                                                        {languageSelector?.blogs_page?.read_more}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <NoDataFound />
                                :
                                [1, 2, 3, 4, 5, 6].map((_, index) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                                            <Skeleton height={400} borderRadius="1rem" />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={"0"}
                                    pageSize={PageSize}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Blog;
