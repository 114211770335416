import {
    IS_CHAT_LOADING,
    CHAT_REPORT_SUCCESS, CHAT_REPORT_ERROR,
} from "../actions/Type";

const initialState = {
    report_data: {},
    loading: false,
};

export const ChatReducers = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case IS_CHAT_LOADING:
            return {
                ...state,
                loading: true,
            };
        case CHAT_REPORT_SUCCESS:
            return {
                ...state,
                report_data: payload,
                loading: false,
            };
        case CHAT_REPORT_ERROR:
            return {
                ...state,
                report_data: payload,
                loading: false,
            };
        default:
            return state;
    }
};