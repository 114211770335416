import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import "../../style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    GetBlogDetails,
    GetBlogsCategory,
    GetRecentBlogAction,
    ResetGetBlogDetails,
    ResetGetRecentBlogAction,
} from "../../redux/actions/HomePageActions";
import moment from "moment";
import { baseUrlPostGres } from "../../redux/constant";
import { getUserSettings } from "./../../Utils/Function"
import { AddBlogCommentAction, BlogCommentListAction, BlogIsFollowAction, BlogIsLikeAction, BlogLikeAction, GetReportFlagAction, ReportBlogAction, ResetAddBlogCommentAction, ResetBlogCommentListAction, ResetBlogIsFollowAction, ResetBlogIsLikeAction, ResetBlogLikeAction, ResetReportFlagAction } from "../../redux/actions/BlogActions";
import { toast } from "react-toastify";
import "./Blog.scss"
import { GoPlus, GoCheck } from "react-icons/go";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const Blogdetail = () => {
    const userLocalStorageData = getUserSettings()
    let userDetail = userLocalStorageData?.user
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [writeCommentText, setWriteCommentText] = useState()
    const [writeReplyCommentText, setWriteReplyCommentText] = useState()
    const [isUpdate, setIsUpdate] = useState(false)

    const blogsCategory = useSelector((state) => state.home?.get_blogs_category);
    const filteredBlog = useSelector((state) => state.home.get_blog_detail);
    const blogLikeSelector = useSelector((state) => state.blogs.blog_like);
    const blogCommentSelector = useSelector((state) => state.blogs.add_blog_comment);
    const blogCommentListSelector = useSelector((state) => state.blogs.blog_comment_list);
    const blogIsLikeSelector = useSelector((state) => state.blogs.blog_is_like);
    const blogIsReportedSelector = useSelector((state) => state.blogs.blog_report);
    const blogIsReportedClickSelector = useSelector((state) => state.blogs.blog_flag_report);
    const blogFollowSelector = useSelector((state) => state.blogs.blog_follow);
    const blogRecentSelector = useSelector((state) => state.home.get_recent_blog);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(GetBlogDetails(id));
        dispatch(GetBlogsCategory());
        dispatch(BlogCommentListAction(id))
        return () => {
            dispatch(ResetBlogCommentListAction())
            dispatch(ResetGetBlogDetails())
            dispatch(ResetBlogIsLikeAction())
            dispatch(ResetGetRecentBlogAction())
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (filteredBlog && Object.keys(filteredBlog).length > 0) {
            if (userDetail) {
                dispatch(BlogIsLikeAction(userDetail.role == 2 ? "student" : "instructor", { blogId: id, user_id: filteredBlog.user_id }))
                dispatch(ReportBlogAction(userDetail.role == 2 ? "student" : "instructor", { blogId: id }))
            }
            dispatch(GetRecentBlogAction({ blog_category: filteredBlog.blog_category_id, blog_id: id }))
        }
    }, [filteredBlog])

    const handleBlogLike = () => {
        if (Object.keys(userLocalStorageData).length > 0) {
            // role 2 is student and 3 is Instructor
            dispatch(BlogLikeAction(userDetail.role == 2 ? "blog-like" : "like-blog", id))
        } else {
            toast.error(languageSelector?.common?.please_login, { theme: "colored" });
            navigate("/")
        }
    }

    const handleBlogReport = () => {
        if (!blogIsReportedSelector?.data?.flag_status) {
            if (Object.keys(userLocalStorageData).length > 0) {
                dispatch(GetReportFlagAction(userDetail.role == 2 ? "student" : "instructor", id))
            } else {
                toast.error(languageSelector?.common?.please_login, { theme: "colored" });
                navigate("/")
            }
        }
    }

    useEffect(() => {
        if (Object.keys(blogLikeSelector).length > 0) {
            if (blogLikeSelector.status) {
                dispatch(GetBlogDetails(id));
                dispatch(ResetBlogLikeAction());
                dispatch(BlogIsLikeAction(userDetail.role == 2 ? "student" : "instructor", { blogId: id, user_id: filteredBlog.user_id }))
            }
        }
    }, [blogLikeSelector])

    useEffect(() => {
        if (Object.keys(blogIsReportedClickSelector).length > 0) {
            if (blogIsReportedClickSelector.status) {
                dispatch(ResetReportFlagAction())
                dispatch(ReportBlogAction(userDetail.role == 2 ? "student" : "instructor", { blogId: id }))
            }
        }
    }, [blogIsReportedClickSelector])

    useEffect(() => {
        if (blogCommentListSelector && blogCommentListSelector?.data?.length > 0) {
            blogCommentListSelector.data.forEach(element => {
                element["visible"] = false
                element["textShow"] = false

                if (element.reply_comment?.length > 0) {
                    element.reply_comment.forEach(reply => {
                        reply["textShow"] = false
                    });
                }
            });
        }
    }, [blogCommentListSelector])

    const handleReplyCommentOpen = (index) => {
        blogCommentListSelector.data[index].visible = !blogCommentListSelector.data[index].visible
        setIsUpdate(!isUpdate)
        setWriteReplyCommentText("")
    }

    const handleReadMore = (index) => {
        blogCommentListSelector.data[index].textShow = !blogCommentListSelector.data[index].textShow
        setIsUpdate(!isUpdate)
    }

    const handleReadMoreReply = (index, index2) => {
        blogCommentListSelector.data[index].reply_comment[index2].textShow = !blogCommentListSelector.data[index].reply_comment[index2].textShow
        setIsUpdate(!isUpdate)
    }

    const handlePostComment = (type, replyID) => {
        if (blogIsReportedSelector?.data?.flag_status) {
            toast.error(languageSelector?.my_blogs?.not_eligible_comment_blog, { theme: "colored" });
            return <></>
        }
        if (writeCommentText || writeReplyCommentText) {
            let data = {
                blogId: id
            }
            if (Object.keys(userLocalStorageData).length > 0) {
                if (type === "comment") {
                    data["type"] = 1
                    data["comment_message"] = writeCommentText
                } else if (type === "reply") {
                    data["type"] = 2
                    data["replyId"] = replyID
                    data["comment_message"] = writeReplyCommentText
                }
                dispatch(AddBlogCommentAction(userDetail.role == 2 ? "student" : "instructor", data))
                setWriteCommentText("")
                setWriteReplyCommentText("")
            } else {
                toast.error(languageSelector?.common?.please_login, { theme: "colored" });
                navigate("/")
            }
        } else {
            toast.error(languageSelector?.validation?.comment_field, { theme: "colored" });
        }
    }

    useEffect(() => {
        if (Object.keys(blogCommentSelector).length > 0) {
            if (blogCommentSelector.status) {
                dispatch(BlogCommentListAction(id))
                dispatch(ResetAddBlogCommentAction())
                dispatch(GetBlogDetails(id));
            }
        }
    }, [blogCommentSelector])

    const handleFollow = () => {
        if (Object.keys(userLocalStorageData).length > 0) {
            // role 2 is student and 3 is Instructor
            dispatch(BlogIsFollowAction(userDetail.role == 2 ? "student" : "instructor", { user_id: filteredBlog.user_id }))
        } else {
            toast.error(languageSelector?.common?.please_login, { theme: "colored" });
            navigate("/")
        }
    }

    useEffect(() => {
        if (Object.keys(blogFollowSelector).length > 0) {
            if (blogFollowSelector.status) {
                dispatch(GetBlogDetails(id));
                dispatch(ResetBlogIsFollowAction());
                dispatch(BlogIsLikeAction(userDetail.role == 2 ? "student" : "instructor", { blogId: id, user_id: filteredBlog.user_id }))
            }
        }
    }, [blogFollowSelector])

    return (
        <>
            <div id="footer-bottom" className="blogdetail">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.my_blogs?.page_title} </h1>
                    <p> Home /  {languageSelector?.my_blogs?.page_title}  / <span> {filteredBlog?.blog_category?.blog_category}</span> </p>
                </div>
                <div className="teachers-description">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-md-12 col-lg-7 col-xxl-8 col-12">
                                {filteredBlog && Object.keys(filteredBlog).length > 0 ?
                                    <div className="blogdetails-section">
                                        <h1>{filteredBlog?.blog_title}</h1>
                                        <h3>
                                            By {filteredBlog?.blog_user?.first_name} {filteredBlog?.blog_user?.last_name} |
                                            <div className="btn follow-btn" onClick={handleFollow}>
                                                {blogIsLikeSelector?.data?.blog_status ?
                                                    <>
                                                        <GoCheck />
                                                        {languageSelector?.blogs_page?.blog_follow}
                                                    </>
                                                    :
                                                    <>
                                                        <GoPlus />
                                                        {languageSelector?.blogs_page?.blog_followers}
                                                    </>
                                                }
                                            </div>
                                        </h3>

                                        <div className="comment-like-button-section">
                                            <div className="comment">
                                                <p>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18.634"
                                                        height="16.75"
                                                        viewBox="0 0 18.634 16.75"
                                                    >
                                                        <g
                                                            id="Group_184"
                                                            data-name="Group 184"
                                                            transform="translate(-1022.787 -658)"
                                                        >
                                                            <path
                                                                id="Path_141"
                                                                data-name="Path 141"
                                                                d="M1111.139,663.442h.261q2.281,0,4.562,0a1.8,1.8,0,0,1,1.886,1.874,5.214,5.214,0,0,1-.5,2.424c-.851,1.839-1.656,3.7-2.48,5.551a2.288,2.288,0,0,1-2.249,1.457c-1.8,0-3.594,0-5.391,0a2.911,2.911,0,0,1-2.19-.909q-.44-.454-.893-.9a.635.635,0,0,1-.2-.485c0-2.248,0-4.5.009-6.744a.637.637,0,0,1,.157-.429,30.432,30.432,0,0,0,4.127-5.119c.322-.449.651-.894.962-1.35a1.6,1.6,0,0,1,1.71-.783,1.757,1.757,0,0,1,1.368,1.351,2.078,2.078,0,0,1-.095,1.429q-.486,1.2-.964,2.407C1111.192,663.285,1111.171,663.352,1111.139,663.442Z"
                                                                transform="translate(-76.43)"
                                                                fill="#005eb5"
                                                                fillRule="evenodd"
                                                            />
                                                            <path
                                                                id="Path_142"
                                                                data-name="Path 142"
                                                                d="M1022.792,777.593c0-1.041-.012-2.081,0-3.122a1.5,1.5,0,0,1,.92-1.4,1.546,1.546,0,0,1,1.669.165,1.488,1.488,0,0,1,.645,1.23c.008,2.088.012,4.177,0,6.265a1.617,1.617,0,0,1-3.231.025c-.021-1.055-.005-2.11-.005-3.165Z"
                                                                transform="translate(0 -108.212)"
                                                                fill="#005eb5"
                                                                fillRule="evenodd"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <span> {filteredBlog?.bloglike_count} </span>
                                                    <svg
                                                        className="margin-l"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="17.684"
                                                        viewBox="0 0 18 17.684"
                                                    >
                                                        <g
                                                            id="Icon_ionic-ios-chatbubbles"
                                                            data-name="Icon ionic-ios-chatbubbles"
                                                            transform="translate(-8.177 -3.375)"
                                                        >
                                                            <path
                                                                id="Path_143"
                                                                data-name="Path 143"
                                                                d="M24.469,17.487a1.254,1.254,0,0,1,.171-.632,1.744,1.744,0,0,1,.109-.16,8.388,8.388,0,0,0,1.429-4.675,8.806,8.806,0,0,0-8.992-8.645,8.93,8.93,0,0,0-8.816,6.88,8.319,8.319,0,0,0-.192,1.77A8.782,8.782,0,0,0,17.03,20.78a10.662,10.662,0,0,0,2.443-.4c.585-.16,1.165-.373,1.315-.43a1.369,1.369,0,0,1,.481-.088,1.346,1.346,0,0,1,.523.1l2.935,1.041a.7.7,0,0,0,.2.052.412.412,0,0,0,.414-.414.665.665,0,0,0-.026-.14Z"
                                                                fill="#005eb5"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <span> {filteredBlog?.blog_comment_count} </span>
                                                </p>
                                            </div>

                                            <div className="button-setion2">
                                                <Link className="btn becomaninstructor date no-cursor">
                                                    <span>{moment(filteredBlog?.created_at).format("DD MMM'YY")}</span>
                                                </Link>
                                                <button className="btn becomaninstructor no-cursor">
                                                    <span> {filteredBlog?.blog_category?.blog_category} </span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="thumblil-image">
                                            <img src={`${baseUrlPostGres()}${filteredBlog?.blog_image}`} alt={filteredBlog?.blog_title} />
                                        </div>

                                        <div className="description-text">
                                            <div dangerouslySetInnerHTML={{ __html: filteredBlog?.blog_description }} />
                                        </div>

                                        <div className="flag-section">
                                            <button
                                                className={`btn becomaninstructor ${blogIsReportedSelector?.data?.flag_status ? "liked" : ""}`}
                                                type="button"
                                                onClick={handleBlogReport}
                                                disabled={blogIsReportedSelector?.data?.flag_status}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16.489"
                                                    height="16.75"
                                                    viewBox="0 0 16.489 16.75"
                                                >
                                                    <path
                                                        id="Icon_awesome-flag"
                                                        data-name="Icon awesome-flag"
                                                        d="M11.737,3.232c-1.753,0-3.2-1.138-5.405-1.138a6.359,6.359,0,0,0-2.226.393,1.832,1.832,0,1,0-2.758.848v12.63a.785.785,0,0,0,.785.785h.523a.785.785,0,0,0,.785-.785V12.877a9.141,9.141,0,0,1,3.744-.724c1.753,0,3.2,1.138,5.405,1.138A6.849,6.849,0,0,0,16.6,11.954a1.045,1.045,0,0,0,.453-.862V3.139a1.047,1.047,0,0,0-1.488-.949,9.525,9.525,0,0,1-3.826,1.042Z"
                                                        transform="translate(-0.563)"
                                                        fill="#828282"
                                                    />
                                                </svg>
                                            </button>

                                            <button className={`btn becomaninstructor ${blogIsLikeSelector?.data?.like_status ? "liked" : "disliked"}`}
                                                disabled={blogIsReportedSelector?.data?.flag_status}
                                                type="button"
                                                onClick={handleBlogLike}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18.634"
                                                    height="16.75"
                                                    viewBox="0 0 18.634 16.75"
                                                >
                                                    <g
                                                        id="Group_219"
                                                        data-name="Group 219"
                                                        transform="translate(-1022.787 -658)"
                                                    >
                                                        <path
                                                            id="Path_141"
                                                            data-name="Path 141"
                                                            d="M1111.139,663.442h.261q2.281,0,4.562,0a1.8,1.8,0,0,1,1.886,1.874,5.214,5.214,0,0,1-.5,2.424c-.851,1.839-1.656,3.7-2.48,5.551a2.288,2.288,0,0,1-2.249,1.457c-1.8,0-3.594,0-5.391,0a2.911,2.911,0,0,1-2.19-.909q-.44-.454-.893-.9a.635.635,0,0,1-.2-.485c0-2.248,0-4.5.009-6.744a.637.637,0,0,1,.157-.429,30.432,30.432,0,0,0,4.127-5.119c.322-.449.651-.894.962-1.35a1.6,1.6,0,0,1,1.71-.783,1.757,1.757,0,0,1,1.368,1.351,2.078,2.078,0,0,1-.095,1.429q-.486,1.2-.964,2.407C1111.192,663.285,1111.171,663.352,1111.139,663.442Z"
                                                            transform="translate(-76.43)"
                                                            fill="#828282"
                                                            fillRule="evenodd"
                                                        />
                                                        <path
                                                            id="Path_142"
                                                            data-name="Path 142"
                                                            d="M1022.792,777.593c0-1.041-.012-2.081,0-3.122a1.5,1.5,0,0,1,.92-1.4,1.546,1.546,0,0,1,1.669.165,1.488,1.488,0,0,1,.645,1.23c.008,2.088.012,4.177,0,6.265a1.617,1.617,0,0,1-3.231.025c-.021-1.055-.005-2.11-.005-3.165Z"
                                                            transform="translate(0 -108.212)"
                                                            fill="#828282"
                                                            fillRule="evenodd"
                                                        />
                                                    </g>
                                                </svg>
                                            </button>
                                            <p>
                                                {filteredBlog?.bloglike_count}
                                                {filteredBlog?.bloglike_count > 1 ? languageSelector?.blogs_page?.likes : languageSelector?.blogs_page?.like}
                                            </p>
                                        </div>

                                        <div className="comments-section">
                                            <h2> {languageSelector?.blogs_page?.comments} <span>({filteredBlog?.blog_comment_count ? filteredBlog?.blog_comment_count : 0})</span> </h2>
                                            <div className="post-input">
                                                <input
                                                    required
                                                    type="text"
                                                    placeholder={`${languageSelector?.blogs_page?.write_here}...`}
                                                    onChange={(e) => setWriteCommentText(e.target.value)}
                                                    value={writeCommentText}
                                                />
                                                <button
                                                    className="btn"
                                                    type="button"
                                                    onClick={() => handlePostComment("comment")}
                                                    disabled={blogIsReportedSelector?.data?.flag_status}
                                                > {languageSelector?.blogs_page?.post_button} </button>
                                            </div>
                                            <div className="comments-main-section">
                                                {blogCommentListSelector && blogCommentListSelector?.data?.map((value, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="comments-card-box">
                                                                <div className="profile-image">
                                                                    <img
                                                                        src={
                                                                            value?.user_name[0]?.profile_image ?
                                                                                `${baseUrlPostGres()}${value?.user_name[0]?.profile_image}`
                                                                                :
                                                                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                                        }
                                                                        alt={value?.user_name[0]?.first_name}
                                                                        className="img"
                                                                    />
                                                                    {value?.user_name[0]?.verified_Sign == 1 && (
                                                                        <span className="Checkmark">
                                                                            <img src="/images/checked-mark.png" alt="Verified" />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="profile-content">
                                                                    <h1> {value?.user_name[0]?.first_name} {value?.user_name[0]?.last_name}
                                                                        <div className="btn">{moment(value.created_at).format("DD MMM YYYY")}</div>
                                                                    </h1>
                                                                    <p>
                                                                        {!value.textShow ? value.comment_message.slice(0, 150) : value.comment_message}
                                                                        {value.comment_message.length > 150 ? <button className="btn readMore-btn" onClick={() => handleReadMore(index)}> {value.textShow ? `...${languageSelector?.blogs_page?.read_less}` : `...${languageSelector?.blogs_page?.read_more}`} </button> : null}
                                                                    </p>
                                                                    <button className="btn" onClick={() => handleReplyCommentOpen(index)}> {languageSelector?.blogs_page?.post_button} </button>
                                                                </div>
                                                            </div>
                                                            <div className="sub-comments">
                                                                {value.visible ?
                                                                    <div className="subComment-reply">
                                                                        <input
                                                                            required
                                                                            type="text"
                                                                            placeholder={languageSelector?.placeholder?.add_reply}
                                                                            onChange={(e) => setWriteReplyCommentText(e.target.value)}
                                                                            value={writeReplyCommentText}
                                                                        />
                                                                        <div className="subComment-action">
                                                                            <button className="btn btn-cancel" type="button" onClick={() => handleReplyCommentOpen(index)}> {languageSelector?.blogs_page?.cancel} </button>
                                                                            <button className="btn btn-reply" type="button" onClick={() => handlePostComment("reply", value.id)}> {languageSelector?.blogs_page?.reply} </button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                {value.reply_comment.length > 0 && value.reply_comment.map((data, idx) => {
                                                                    return (
                                                                        <div className="comments-card-box" key={idx}>
                                                                            <div className="profile-image">
                                                                                <img
                                                                                    src={
                                                                                        data?.user_name[0]?.profile_image ?
                                                                                            `${baseUrlPostGres()}${data?.user_name[0]?.profile_image}`
                                                                                            :
                                                                                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                                                    }
                                                                                    className="img"
                                                                                    alt={data?.user_name[0]?.first_name}
                                                                                />
                                                                                {data?.user_name[0]?.verified_Sign == 1 && (
                                                                                    <span className="Checkmark">
                                                                                        <img src="/images/checked-mark.png" alt="Verified" />
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                            <div className="profile-content">
                                                                                <h1> {data?.user_name[0]?.first_name} {data?.user_name[0]?.last_name}
                                                                                    <div className="btn"> {moment(data.created_at).format("DD MMM YYYY")} </div>
                                                                                </h1>
                                                                                <p>
                                                                                    {!data.textShow ? data.comment_message.slice(0, 150) : data.comment_message}
                                                                                    {data.comment_message.length > 150 ?
                                                                                        <button className="btn  readMore-btn" onClick={() => handleReadMoreReply(index, idx)}> {data.textShow ? `...${languageSelector?.blogs_page?.read_less}` : `...${languageSelector?.blogs_page?.read_more}`} </button>
                                                                                        : null
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <Skeleton height={30} />
                                        <Skeleton height={24} style={{ marginTop: "1rem" }} />
                                        <Skeleton height={30} style={{ marginTop: "2rem", marginBottom: "2.3rem" }} />
                                        <Skeleton height={425} borderRadius="1.5rem" />
                                    </>
                                }
                            </div>

                            <div className="col-md-12 col-lg-5 col-xxl-4 col-12 sidebar-details">
                                {Object.keys(blogRecentSelector).length > 0 ?
                                    <div className="feescharges-sec recent-blog-section">
                                        <h2> {languageSelector?.blogs_page?.recent_blog} </h2>
                                        {blogRecentSelector?.data?.length > 0 ?
                                            blogRecentSelector?.data?.map((value, index) => (
                                                <div className="charge-list" key={index}>
                                                    <Link className="recent-blog-img" to={`/blogdetail/${value.id}`}>
                                                        <img src={`${baseUrlPostGres()}${value?.blog_image}`} alt={value?.blog_title} />
                                                    </Link>
                                                    <div className="recent-blog-content">
                                                        <Link to={`/blogdetail/${value.id}`} className="text-decoration-none text-dark text-capitalize"><h1> {value?.blog_title} </h1></Link>
                                                        <p> {value?.short_blog_description} <Link className="btn" to={`/blogdetail/${value.id}`}> {languageSelector?.blogs_page?.read_more} </Link> </p>
                                                        <Link className="btn becomaninstructor date">
                                                            <span> {moment(value?.created_at).format("DD MMM'YY")}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <NoDataFound />
                                        }
                                    </div>
                                    :
                                    <Skeleton height={280} borderRadius="2.5rem" />
                                }

                                {blogsCategory?.length > 0 ?
                                    <div className="feescharges-sec availability specialization">
                                        <h2> {languageSelector?.blogs_page?.categroy} </h2>
                                        <div className="specialization-content">
                                            <div className="Specialization-tag">
                                                {blogsCategory?.map((elem, index) => (
                                                    <button className="btn becomaninstructor" key={index}
                                                        onClick={() => navigate("/blog", {
                                                            state: {
                                                                cat_id: elem?.blog_category,
                                                            }
                                                        }
                                                        )}
                                                    >{elem.blog_category}</button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Skeleton height={280} borderRadius="2.5rem" style={{ marginTop: "3rem" }} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default Blogdetail;
