import axios from "axios";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    IS_CHAT_LOADING,
    CHAT_REPORT_SUCCESS, CHAT_REPORT_ERROR,
} from "./Type";
import HandleError from "./HandleError";
import { toast } from "react-toastify";

export const ChatReportAction = (data) => async (dispatch) => {
    dispatch({ type: IS_CHAT_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/chat-report`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: CHAT_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: CHAT_REPORT_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetChatReportAction = () => async (dispatch) => {
    dispatch({
        type: CHAT_REPORT_SUCCESS,
        payload: {},
    });
}