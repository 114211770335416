import axios from "axios";
import { toast } from "react-toastify";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    IS_GIFT_CARD_LOADING,
    ADD_GIFTCARD_ERROR, ADD_GIFTCARD_SUCCESS,
    REDEEM_GIFTCARD_SUCCESS, REDEEM_GIFTCARD_ERROR,
    ALL_COUPON_LIST_SUCCESS, ALL_COUPON_LIST_ERROR
} from "./Type";
import HandleError from "./HandleError";

export const AddGiftCardAction = (data) => async (dispatch) => {
    dispatch({ type: IS_GIFT_CARD_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/add-gift-card`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: ADD_GIFTCARD_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ADD_GIFTCARD_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetAddGiftCardAction = () => async (dispatch) => {
    dispatch({
        type: ADD_GIFTCARD_SUCCESS,
        payload: {},
    });
}


export const RedeemGiftCardAction = (data) => async (dispatch) => {
    dispatch({ type: IS_GIFT_CARD_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/redeem-gift-card`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: REDEEM_GIFTCARD_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: REDEEM_GIFTCARD_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetRedeemGiftCardAction = () => async (dispatch) => {
    dispatch({
        type: REDEEM_GIFTCARD_SUCCESS,
        payload: {},
    });
}


export const CouponAllListAction = () => async (dispatch) => {
    dispatch({ type: IS_GIFT_CARD_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/coupon-all-list`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: ALL_COUPON_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ALL_COUPON_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetCouponAllListAction = () => async (dispatch) => {
    dispatch({
        type: ALL_COUPON_LIST_SUCCESS,
        payload: {},
    });
}