import {
    IS_LOADING,
    IS_LANGUAGE_LOADING,
    GET_BANNER_ERROR,
    GET_BANNER_SUCCESS,
    GET_BLOGS_ERROR,
    GET_BLOGS_SUCCESS,
    GET_CATEGORY_ERROR,
    GET_CATEGORY_SUCCESS,
    GET_COURSES_ERROR,
    GET_COURSES_SUCCESS,
    GET_FAQ_ERROR,
    GET_FAQ_SUCCESS,
    GET_TESTIMONIALSS_ERROR,
    GET_TESTIMONIALSS_SUCCESS,
    GET_COUNTRY_LIST_ERROR,
    GET_COUNTRY_LIST_SUCCESS,
    POST_STATES_LIST_SUCCESS,
    POST_STATES_LIST_ERROR,
    POST_CITIES_LIST_SUCCESS,
    POST_CITIES_LIST_ERROR,
    GET_BLOGS_CATEGORY_SUCCESS,
    GET_BLOGS_CATEGORY_ERROR,
    CONTACT_US_SUCCESS,
    CONTACT_US_ERROR,
    GET_BLOG_DETAIL_SUCCESS,
    GET_BLOG_DETAIL_ERROR,
    GLOBAL_SEARCH_SUCCESS, GLOBAL_SEARCH_ERROR,
    SEARCH_SUGGESTION_SUCCESS, SEARCH_SUGGESTION_ERROR,
    GET_RECENT_BLOG_SUCCESS, GET_RECENT_BLOG_ERROR,
    GET_DYNAMIC_LANGUAGE_SUCCESS, GET_DYNAMIC_LANGUAGE_ERROR,
    HOME_PAGE_SECOND_SECTION_LIST, ERROR_HOME_PAGE_SECOND_SECTION_LIST,
    HOME_PAGE_SIX_SECTION_LIST, ERROR_HOME_PAGE_SIX_SECTION_LIST,
    CATEGORIES_WITH_GROUP_CLASS, ERROR_CATEGORIES_WITH_GROUP_CLASS,
    HOME_PAGE_EIGHT_SECTION_LIST, ERROR_HOME_PAGE_EIGHT_SECTION_LIST,
    HOME_PAGE_THIRD_SECTION_LIST, ERROR_HOME_PAGE_THIRD_SECTION_LIST,
    HOME_PAGE_TEN_SECTION_LIST, ERROR_HOME_PAGE_TEN_SECTION_LIST,
    HOME_PAGE_FOURTH_SECTION_LIST, ERROR_HOME_PAGE_FOURTH_SECTION_LIST,
    HOME_PAGE_NINE_SECTION_LIST, ERROR_HOME_PAGE_NINE_SECTION_LIST,
    RANDOM_GROUP_CLASS, ERROR_RANDOM_GROUP_CLASS
} from "../actions/Type";

const initialState = {
    get_testimonial: [],
    get_course: [],
    get_blogs: {},
    get_blog_detail: {},
    get_blogs_category: [],
    get_faqs: [],
    get_banner: [],
    get_category: [],
    get_country_list: [],
    post_states_list: [],
    post_cities_list: [],
    contact_us_success: {},
    contact_us_error: {},
    search_suggestion: {},
    loading: false,
    language_loading: false,
    global_search: {},
    get_recent_blog: {},
    dynamic_language: {},
    home_page_second_section: {},
    home_page_third_section: {},
    random_group_class: {},
    home_page_six_section: {},
    categories_with_group_class: {},
    home_page_eight_section: {},
    home_page_ten_section: {},
    home_page_fourth_section: {},
    home_page_nine_section: {},
};

const HomePagereducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case IS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case IS_LANGUAGE_LOADING:
            return {
                ...state,
                language_loading: true,
            };
        case GLOBAL_SEARCH_SUCCESS:
            return {
                ...state,
                global_search: payload,
                loading: false,
            };
        case GLOBAL_SEARCH_ERROR:
            return {
                ...state,
                global_search: payload,
                loading: false,
            };

        case GET_DYNAMIC_LANGUAGE_SUCCESS:
            return {
                ...state,
                dynamic_language: payload,
                language_loading: false,
                loading: false,
            };
        case GET_DYNAMIC_LANGUAGE_ERROR:
            return {
                ...state,
                dynamic_language: payload,
                language_loading: false,
                loading: false,
            };

        case SEARCH_SUGGESTION_SUCCESS:
            return {
                ...state,
                search_suggestion: payload,
                loading: false,
            };
        case SEARCH_SUGGESTION_ERROR:
            return {
                ...state,
                search_suggestion: payload,
                loading: false,
            };
        case GET_COURSES_SUCCESS:
            return {
                ...state,
                get_course: payload,
                loading: false,
            };
        case GET_COURSES_ERROR:
            return {
                ...state,
                get_course: payload,
                loading: false,
            };
        case GET_BLOGS_SUCCESS:
            return {
                ...state,
                get_blogs: payload,
                loading: false,
            };
        case GET_BLOGS_ERROR:
            return {
                ...state,
                get_blogs: payload,
                loading: false,
            };
        case GET_BLOG_DETAIL_SUCCESS:
            return {
                ...state,
                get_blog_detail: payload,
                loading: false,
            };
        case GET_BLOG_DETAIL_ERROR:
            return {
                ...state,
                get_blog_detail: payload,
                loading: false,
            };
        case GET_RECENT_BLOG_SUCCESS:
            return {
                ...state,
                get_recent_blog: payload,
                loading: false,
            };
        case GET_RECENT_BLOG_ERROR:
            return {
                ...state,
                get_recent_blog: payload,
                loading: false,
            };
        case GET_BLOGS_CATEGORY_SUCCESS:
            return {
                ...state,
                get_blogs_category: payload,
                loading: false,
            };
        case GET_BLOGS_CATEGORY_ERROR:
            return {
                ...state,
                get_blogs_category: payload,
                loading: false,
            };
        case GET_FAQ_SUCCESS:
            return {
                ...state,
                get_faqs: payload,
                loading: false,
            };
        case GET_FAQ_ERROR:
            return {
                ...state,
                get_faqs: payload,
                loading: false,
            };
        case GET_BANNER_SUCCESS:
            return {
                ...state,
                get_banner: payload,
                loading: false,
            };
        case GET_BANNER_ERROR:
            return {
                ...state,
                get_banner: payload,
                loading: false,
            };
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                get_category: payload,
                loading: false,
            };
        case GET_CATEGORY_ERROR:
            return {
                ...state,
                get_category: payload,
                loading: false,
            };
        case GET_TESTIMONIALSS_SUCCESS:
            return {
                ...state,
                get_testimonial: payload,
                loading: false,
            };
        case GET_TESTIMONIALSS_ERROR:
            return {
                ...state,
                get_testimonial: payload,
                loading: false,
            };
        case GET_COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                get_country_list: payload,
                loading: false,
            };
        case GET_COUNTRY_LIST_ERROR:
            return {
                ...state,
                get_country_list: payload,
                loading: false,
            };
        case POST_STATES_LIST_SUCCESS:
            return {
                ...state,
                post_states_list: payload,
                loading: false,
            };
        case POST_STATES_LIST_ERROR:
            return {
                ...state,
                post_states_list: payload,
                loading: false,
            };
        case POST_CITIES_LIST_SUCCESS:
            return {
                ...state,
                post_cities_list: payload,
                loading: false,
            };
        case POST_CITIES_LIST_ERROR:
            return {
                ...state,
                post_cities_list: payload,
                loading: false,
            };
        case CONTACT_US_SUCCESS:
            return {
                ...state,
                contact_us_success: payload,
                loading: false,
            };
        case CONTACT_US_ERROR:
            return {
                ...state,
                CONTACT_US_ERROR: payload,
                loading: false,
            };
        case HOME_PAGE_SECOND_SECTION_LIST:
            return {
                ...state,
                home_page_second_section: payload,
                loading: false,
            };
        case ERROR_HOME_PAGE_SECOND_SECTION_LIST:
            return {
                ...state,
                home_page_second_section: payload,
                loading: false,
            };    
        case HOME_PAGE_THIRD_SECTION_LIST:
            return {
                ...state,
                home_page_third_section: payload,
                loading: false,
            };
        case ERROR_HOME_PAGE_THIRD_SECTION_LIST:
            return {
                ...state,
                home_page_third_section: payload,
                loading: false,
            };
        case HOME_PAGE_SIX_SECTION_LIST:
            return {
                ...state,
                home_page_six_section: payload,
                loading: false,
            };
        case ERROR_HOME_PAGE_SIX_SECTION_LIST:
            return {
                ...state,
                home_page_six_section: payload,
                loading: false,
            };
        case HOME_PAGE_EIGHT_SECTION_LIST:
            return {
                ...state,
                home_page_eight_section: payload,
                loading: false,
            };
        case ERROR_HOME_PAGE_EIGHT_SECTION_LIST:
            return {
                ...state,
                home_page_eight_section: payload,
                loading: false,
            };
        case HOME_PAGE_TEN_SECTION_LIST:
            return {
                ...state,
                home_page_ten_section: payload,
                loading: false,
            };
        case ERROR_HOME_PAGE_TEN_SECTION_LIST:
            return {
                ...state,
                home_page_ten_section: payload,
                loading: false,
            };
        case HOME_PAGE_FOURTH_SECTION_LIST:
            return {
                ...state,
                home_page_fourth_section: payload,
                loading: false,
            };
        case ERROR_HOME_PAGE_FOURTH_SECTION_LIST:
            return {
                ...state,
                home_page_fourth_section: payload,
                loading: false,
            };
        case RANDOM_GROUP_CLASS:
            return {
                ...state,
                random_group_class: payload,
                loading: false,
            };
        case ERROR_RANDOM_GROUP_CLASS:
            return {
                ...state,
                random_group_class: payload,
                loading: false,
            };
        case HOME_PAGE_NINE_SECTION_LIST:
            return {
                ...state,
                home_page_nine_section: payload,
                loading: false,
            };
        case ERROR_HOME_PAGE_NINE_SECTION_LIST:
            return {
                ...state,
                home_page_nine_section: payload,
                loading: false,
            };
        case CATEGORIES_WITH_GROUP_CLASS:
            return {
                ...state,
                categories_with_group_class: payload,
                loading: false,
            };
        case ERROR_CATEGORIES_WITH_GROUP_CLASS:
            return {
                ...state,
                categories_with_group_class: payload,
                loading: false,
            };

        default:
            return state;
    }
};
export default HomePagereducer;
