import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import "../../style.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetBlogsCategory } from "../../redux/actions/HomePageActions";
import moment from "moment";
import { baseUrlPostGres } from "../../redux/constant";
import { BlogPreviewAction, ResetBlogPreviewAction } from "../../redux/actions/BlogActions";
import "./../Bloglisting/Blog.scss"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const BlogDetailPreview = ({ role }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const blogsCategory = useSelector((state) => state.home?.get_blogs_category);
    const blogPreviewSelector = useSelector((state) => state.blogs.blog_preview);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(BlogPreviewAction(role == 2 ? "student" : "instructor", id));
        dispatch(GetBlogsCategory());
        return () => dispatch(ResetBlogPreviewAction())
    }, [id, dispatch]);

    return (
        <>
            <div id="footer-bottom" className="blogdetail">
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.my_blogs?.blog_preview} </h1>
                    <p> Home / {languageSelector?.my_blogs?.blog_preview} / <span> {blogPreviewSelector?.blog_category?.blog_category}</span> </p>
                </div>
                <div className="teachers-description">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-md-12 col-lg-7 col-xxl-8 col-12">
                                {Object.keys(blogPreviewSelector).length > 0 ?
                                    <div className="blogdetails-section">
                                        <h1>{blogPreviewSelector?.blog_title}</h1>
                                        <h3>
                                            By {blogPreviewSelector?.blog_user?.first_name} {blogPreviewSelector?.blog_user?.last_name}
                                        </h3>

                                        <div className="comment-like-button-section">
                                            <div className="comment">
                                                <p>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18.634"
                                                        height="16.75"
                                                        viewBox="0 0 18.634 16.75"
                                                    >
                                                        <g
                                                            id="Group_184"
                                                            data-name="Group 184"
                                                            transform="translate(-1022.787 -658)"
                                                        >
                                                            <path
                                                                id="Path_141"
                                                                data-name="Path 141"
                                                                d="M1111.139,663.442h.261q2.281,0,4.562,0a1.8,1.8,0,0,1,1.886,1.874,5.214,5.214,0,0,1-.5,2.424c-.851,1.839-1.656,3.7-2.48,5.551a2.288,2.288,0,0,1-2.249,1.457c-1.8,0-3.594,0-5.391,0a2.911,2.911,0,0,1-2.19-.909q-.44-.454-.893-.9a.635.635,0,0,1-.2-.485c0-2.248,0-4.5.009-6.744a.637.637,0,0,1,.157-.429,30.432,30.432,0,0,0,4.127-5.119c.322-.449.651-.894.962-1.35a1.6,1.6,0,0,1,1.71-.783,1.757,1.757,0,0,1,1.368,1.351,2.078,2.078,0,0,1-.095,1.429q-.486,1.2-.964,2.407C1111.192,663.285,1111.171,663.352,1111.139,663.442Z"
                                                                transform="translate(-76.43)"
                                                                fill="#005eb5"
                                                                fillRule="evenodd"
                                                            />
                                                            <path
                                                                id="Path_142"
                                                                data-name="Path 142"
                                                                d="M1022.792,777.593c0-1.041-.012-2.081,0-3.122a1.5,1.5,0,0,1,.92-1.4,1.546,1.546,0,0,1,1.669.165,1.488,1.488,0,0,1,.645,1.23c.008,2.088.012,4.177,0,6.265a1.617,1.617,0,0,1-3.231.025c-.021-1.055-.005-2.11-.005-3.165Z"
                                                                transform="translate(0 -108.212)"
                                                                fill="#005eb5"
                                                                fillRule="evenodd"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <span> {blogPreviewSelector?.bloglike_count} </span>
                                                    <svg
                                                        className="margin-l"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="17.684"
                                                        viewBox="0 0 18 17.684"
                                                    >
                                                        <g
                                                            id="Icon_ionic-ios-chatbubbles"
                                                            data-name="Icon ionic-ios-chatbubbles"
                                                            transform="translate(-8.177 -3.375)"
                                                        >
                                                            <path
                                                                id="Path_143"
                                                                data-name="Path 143"
                                                                d="M24.469,17.487a1.254,1.254,0,0,1,.171-.632,1.744,1.744,0,0,1,.109-.16,8.388,8.388,0,0,0,1.429-4.675,8.806,8.806,0,0,0-8.992-8.645,8.93,8.93,0,0,0-8.816,6.88,8.319,8.319,0,0,0-.192,1.77A8.782,8.782,0,0,0,17.03,20.78a10.662,10.662,0,0,0,2.443-.4c.585-.16,1.165-.373,1.315-.43a1.369,1.369,0,0,1,.481-.088,1.346,1.346,0,0,1,.523.1l2.935,1.041a.7.7,0,0,0,.2.052.412.412,0,0,0,.414-.414.665.665,0,0,0-.026-.14Z"
                                                                fill="#005eb5"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <span> {blogPreviewSelector?.blog_comment_count} </span>
                                                </p>
                                            </div>

                                            <div className="button-setion2">
                                                <Link className="btn becomaninstructor date">
                                                    <span>{moment(blogPreviewSelector?.created_at).format("DD MMM'YY")}</span>
                                                </Link>
                                                <button className="btn becomaninstructor">
                                                    <span> {blogPreviewSelector?.blog_category?.blog_category} </span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="thumblil-image">
                                            <img src={`${baseUrlPostGres()}${blogPreviewSelector?.blog_image}`} alt={blogPreviewSelector?.blog_title} />
                                        </div>

                                        <div className="description-text">
                                            <div dangerouslySetInnerHTML={{ __html: blogPreviewSelector?.blog_description }} />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <Skeleton height={30} />
                                        <Skeleton height={24} style={{ marginTop: "1rem" }} />
                                        <Skeleton height={30} style={{ marginTop: "2rem", marginBottom: "2.3rem" }} />
                                        <Skeleton height={425} borderRadius="1.5rem" />
                                    </>
                                }
                            </div>

                            <div className="col-md-12 col-lg-5 col-xxl-4 col-12 sidebar-details">
                                {blogsCategory?.length > 0 ?
                                    <div className="feescharges-sec availability specialization mt-0">
                                        <h2> {languageSelector?.my_blogs?.categroy} </h2>
                                        <div className="specialization-content">
                                            <div className="Specialization-tag">
                                                {blogsCategory?.map((elem, index) => (
                                                    <button className="btn becomaninstructor" key={index}>{elem.blog_category}</button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Skeleton height={280} borderRadius="2.5rem" />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default BlogDetailPreview;
