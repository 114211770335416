// import React, { useState } from "react";
// import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
// import "react-phone-number-input/style.css";
// const Numberinput = ({ GetValue, name, formErrors, defaultValue, disabled, defaultCountry, selectedCountry="IN" }) => {
//     let [value, setValue] = useState(defaultValue);
//     const [handleError, setHandleError] = useState();

//     const onChange = (data) => {
//         setValue(data);
//         GetValue({ [name]: data });
//         if (value === undefined) {
//             setHandleError(true);
//         } else {
//             setHandleError(false);
//         }
//     };

//     const onCountryChange = (data) => {
//         selectedCountry(data)
//     }

//     const HandleOnBlur = () => {
//         if (value === undefined) {
//             setHandleError(true);
//         } else {
//             setHandleError(false);
//         }
//     };
//     return (
//         <div className="phone-input-flag">
//             <PhoneInput
//                 placeholder="Phone number"
//                 international
//                 defaultCountry={defaultCountry}
//                 value={defaultValue}
//                 onChange={onChange}
//                 onBlur={HandleOnBlur}
//                 onCountryChange={onCountryChange}
//                 disabled={disabled}
//                 countryCallingCodeEditable={false}
//                 error={defaultValue ? (isValidPhoneNumber(defaultValue) ? undefined : 'Invalid phone number') : ''}
//                 name={name}
//             />
//             {handleError && <span className="validation-text">{formErrors}</span>}
//         </div>
//     );
// };

// export default Numberinput;


import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
const Numberinput = ({ GetValue, name, defaultValue, disabled, defaultCountry, selectedCountry = "in" }) => {
    const handleOnChange = (value, data) => {
        selectedCountry(data.countryCode)
        GetValue(value)
    }
    return (
        <PhoneInput
            country={defaultCountry}
            countryCodeEditable={false}
            enableSearch={true}
            value={defaultValue}
            //onlyCountries={["af", "al", "de", "ad", "ao", "ai", "aq", "ag", "sa", "dz", "ar", "am", "aw", "au", "at", "az", "bs", "bd", "bb", "bh", "be", "bz", "bj", "bm", "by", "bo", "ba", "bw", "br", "bn", "bg", "bf", "bi", "bt", "cv", "kh", "cm", "ca", "ic", "bq", "qa", "ea", "td", "cz", "cl", "cn", "cy", "va", "co", "km", "cg", "kp", "kr", "cr", "ci", "hr", "cu", "cw", "dg", "dk", "dm", "ec", "eg", "sv", "ae", "er", "sk", "si", "es", "ee", "sz", "et", "ph", "fi", "fj", "fr", "ga", "gm", "ge", "gh", "gi", "gd", "gr", "gl", "gp", "gu", "gt", "gf", "gg", "gn", "gq", "gw", "gy", "ht", "hn", "hu", "in", "id", "iq", "ir", "ie", "ac", "im", "cx", "nf", "is", "ax", "ky", "cc", "ck", "fo", "gs", "fk", "mp", "mh", "um", , "pn", "sb", "tc", "vg", "vi", , "il", "it", "jm", "jp", "je", "jo", "kz", "ke", "kg", "ki", "xk", "kw", "la", "ls", "lv", "lb", "lr", "ly", "li", "lt", "lu", "mk", "mg", "my", "mw", "mv", "ml", "mt", "ma", "mq", "mu", "mr", "yt", "mx", "fm", "md", "mc", "mn", "me", "ms", "mz", "mm", "na", "nr", "np", "ni", "ne", "ng", "nu", "no", "nc", "nz", "om", "nl", "pk", "pw", "pa", "pg", "py", "pe", "pf", "pl", "pt", "pr", "hk", "mo", "gb", "cf", "cd", "do", "re", "rw", "ro", "ru", "eh", "ws", "as", "bl", "kn", "sm", "mf", "pm", "vc", "sh", "lc", "st", "sn", "rs", "sc", "sl", "sg", "sx", "sy", "so", "lk", "za", "sd", "ss", "se", "ch", "sr", "sj", "th", "tw", "tz", "tj", "io", "tf", "ps", "tl", "tg", "tk", "to", "tt", "ta", "tn", "tm", "tr", "tv", "ua", "ug", "uy", "uz", "vu", "ve", "vn", "wf", "ye", "dj", "zm", "zw"]}
            //areaCodes={{ do: ['809', '829', '849'], ca: ['204', '226', '236', '249', '250', '289', '306', '343', '365', '387', '403', '416', '418', '431', '437', '438', '450', '506', '514', '519', '548', '579', '581', '587', '604', '613', '639', '647', '672', '705', '709', '742', '778', '780', '782', '807', '819', '825', '867', '873', '902', '905', '980'], us: ['307', '202', '304', '239', '209', '206', '212', '213', '208', '316', '319', '219', '313', '203', '210', '401', '334', '309', '267'] }}
            enableAreaCodes={false}
            enableTerritories={false}
            onChange={handleOnChange}
            disabled={disabled}
            inputProps={{
                name: name,
                required: true
            }}
        />
    );
}
export default Numberinput;