import axios from "axios";
import { toast } from "react-toastify";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    BLOG_LIST_ERROR, BLOG_LIST_SUCCESS,
    BLOG_EDIT_ERROR, BLOG_EDIT_SUCCESS,
    BLOG_VIEW_ERROR, BLOG_VIEW_SUCCESS,
    BLOG_ADD_ERROR, BLOG_ADD_SUCCESS, IS_BLOG_LOADING, BLOG_LIKE_SUCCESS, BLOG_LIKE_ERROR,
    ADD_BLOG_COMMENT_SUCCESS, ADD_BLOG_COMMENT_ERROR, BLOG_COMMENT_LIST_SUCCESS, BLOG_COMMENT_LIST_ERROR,
    BLOG_IS_LIKE_SUCCESS, BLOG_IS_LIKE_ERROR,
    BLOG_IS_FOLLOW_SUCCESS, BLOG_IS_FOLLOW_ERROR,
    BLOG_DELETE_SUCCESS, BLOG_DELETE_ERROR,
    BLOG_PREVIEW_DETAIL_SUCCESS, BLOG_PREVIEW_DETAIL_ERROR,
    BLOG_REPORT_SUCCESS, BLOG_REPORT_ERROR,
    BLOG_REPORT_FLAG_SUCCESS, BLOG_REPORT_FLAG_ERROR
} from "./Type";
import HandleError from "./HandleError";

export const BlogListAction = (slug, data) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/${slug}`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: BLOG_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const AddBlogAction = (slug, formdata) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/${slug}`, formdata, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_ADD_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_ADD_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetAddBlogAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_ADD_SUCCESS,
        payload: {},
    });
}

export const BlogViewAction = (slug, id) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/${slug}/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            // toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_VIEW_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_VIEW_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBlogViewAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_VIEW_SUCCESS,
        payload: {},
    });
}

export const EditBlogAction = (slug, id, data) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/${slug}/${id}`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_EDIT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_EDIT_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetEditBlogAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_EDIT_SUCCESS,
        payload: {},
    });
}

export const BlogLikeAction = (name, id) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/${name}/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_LIKE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_LIKE_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBlogLikeAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_LIKE_SUCCESS,
        payload: {},
    });
}

export const AddBlogCommentAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/${name}-comment-blog`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: ADD_BLOG_COMMENT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ADD_BLOG_COMMENT_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetAddBlogCommentAction = () => async (dispatch) => {
    dispatch({
        type: ADD_BLOG_COMMENT_SUCCESS,
        payload: {},
    });
}

export const BlogCommentListAction = (id) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/list-blog-comment/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: BLOG_COMMENT_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: BLOG_COMMENT_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBlogCommentListAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_COMMENT_LIST_SUCCESS,
        payload: {},
    });
}

export const BlogIsLikeAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/blog-like-${name}`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: BLOG_IS_LIKE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            dispatch({
                type: BLOG_IS_LIKE_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBlogIsLikeAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_IS_LIKE_SUCCESS,
        payload: {},
    });
}

export const BlogIsFollowAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/blog-follow-${name}`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_IS_FOLLOW_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_IS_FOLLOW_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBlogIsFollowAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_IS_FOLLOW_SUCCESS,
        payload: {},
    });
}

export const BlogDeleteAction = (name, id) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/blog-delete-${name}/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_DELETE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_DELETE_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBlogDeleteAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_DELETE_SUCCESS,
        payload: {},
    });
}

export const BlogPreviewAction = (name, id) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/blog-details-${name}/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: BLOG_PREVIEW_DETAIL_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_PREVIEW_DETAIL_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetBlogPreviewAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_PREVIEW_DETAIL_SUCCESS,
        payload: {},
    });
}

export const GetReportFlagAction = (name, id) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/blog-flag-${name}/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_REPORT_FLAG_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_REPORT_FLAG_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetReportFlagAction = () => async (dispatch) => {
    dispatch({
        type: BLOG_REPORT_FLAG_SUCCESS,
        payload: {},
    });
}

export const ReportBlogAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_BLOG_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/blog-flag-${name}`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: BLOG_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: BLOG_REPORT_ERROR,
                payload: errors.response.data,
            });
        });
};