import React, { useState, useEffect, useRef, useContext } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import moment from "moment";
import Swiper from 'swiper/bundle';
//import 'swiper/swiper-bundle.min.css'; // Ensure the Swiper styles are imported
import "./GroupClassDetail.scss";
import { Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserSettings, isValidHttpUrl } from "../../Utils/Function";
import { useParams } from 'react-router-dom';
import { baseUrlPostGres } from "../../redux/constant";
import { CartContext } from '../../pages/Home/CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GroupClassDetailAction, MoreGroupClassByInstructorAction, GetGroupClassById } from "../../redux/actions/GroupClassActions";
import { faTrophy, faPlay, faCirclePlay, faUserFriends, faCertificate, faStar, faGlobe, faNewspaper, faClock } from '@fortawesome/free-solid-svg-icons';

const GroupClassDetail = () => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');
  const { id } = useParams(); // Access the ID from the URL
  const classDetailSelector = useSelector(state => state.group.group_class_detail);

  useEffect(() => {
    dispatch(GroupClassDetailAction({
      id: id,
    }))
  }, []);

  useEffect(() => {
    if (Object.keys(classDetailSelector).length > 0) {
      setUserId(classDetailSelector?.user_id);
    }
  }, [classDetailSelector]);

  /*instructorGroupClass*/
  const instructorGroupClassSelector = useSelector(state => state.group.group_class_instructor);

  useEffect(() => {
    if (userId) {
      dispatch(MoreGroupClassByInstructorAction({
        id: id,
        user_id: userId,
      }));
    }
  }, [userId, dispatch]);

  /*slider scroll code*/
  /*new code*/
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = new Swiper(swiperRef.current, {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
  }, [instructorGroupClassSelector]);

  /*old code*/
  // const swiper = new Swiper('.swiper-container', {
  //   loop: true,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true,
  //   },
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  // });
  // const swiperRef = useRef(null);

  // const handlePrevClick = () => {
  //   swiperRef.current?.slidePrev();
  // };

  // cart button
  const [cartItems, setCartItems] = useState([]);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // Check if the current item is already in the cart
  const isItemInCart = cartItems.some(item => item.id === classDetailSelector.id);

  const handleAddToCart = (item) => {
  const isItemInCart = cartItems.some((cartItem) => cartItem.id === item.id);
    if (!isItemInCart) {
      addToCart(item);
      setCartItems([...cartItems, item]);
      setShowSuccessMessage(true);
      localStorage.setItem('cartItems', JSON.stringify([...cartItems, item]));
      // Store cart items in localStorage
    } else {
      navigate('/CartPage');
    }
  };

  //1 cards visible in mobile view per slider 
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Change the breakpoint as needed
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // slider
  const [hoveredSliderIndex, setHoveredSliderIndex] = useState(null);

  // localstore cart
  useEffect(() => {
    // Fetch cart items from localStorage or API and set the state
    const savedCartItems = localStorage.getItem('cartItems');
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }
  }, []);

  /*Group Class description show more & show less*/
  const [groupClassFullDescription, setGroupClassFullDescription] = useState(false);
  const handleGroupClassShowMoreClick = () => {
    setGroupClassFullDescription(true);
  };
  const handleGroupClassShowLessClick = () => {
    setGroupClassFullDescription(false);
  };

  /*Instructor description show more & show less*/
  const [instructorFullDescription, setInstructorFullDescription] = useState(false);
  const handleInstructorShowMoreClick = () => {
    setInstructorFullDescription(true);
  };
  const handleInstructorShowLessClick = () => {
    setInstructorFullDescription(false);
  };

  const handleGroupClassDetailClick = (e, id) => {
    window.location.href = '/group-class-detail/'+id;
  }

  return (
    <>
      <Header />
        <div id="footer-bottom">
          <div>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Web Development Bootcamp</title>
            <link rel="stylesheet" href="styles.css" />
            {Object.keys(classDetailSelector).length > 0 &&
              <>
                <div className="top-containers">
                  <div className="left-panel">
                    <h1>{classDetailSelector?.class_name}</h1>
                    <p>{classDetailSelector?.description}</p>
                    <div className="course-details">
                      {/* <span className="bestseller-tag">Bestseller</span> */}
                      <span className="stars">★★★★★</span>
                      <span className="rating">{classDetailSelector?.ratingavg ? Number(classDetailSelector?.ratingavg).toFixed(0) : 0}/5</span>
                      <span className="rating-count">({classDetailSelector?.UserRatingCount ? classDetailSelector?.UserRatingCount : 0} ratings)</span>
                      <span className="student-count">00 students</span>
                    </div>
                    <div className="level">Level : {classDetailSelector?.class_level?.class_name}</div>
                    <div className="number-of-classes">Number of Classes : {classDetailSelector?.number_of_class}</div>
                    <div className="class-duration">Class Duration : {classDetailSelector?.duration} Hour</div>
                    <div className="total-seats">Total Seats : {classDetailSelector?.total_seat} Students</div>
                    <div className="author">Created by <a href={`/myprofile/instructor`} style={{ color: '#c0c4fc' }} >Dr. {classDetailSelector?.group_user?.first_name} {classDetailSelector?.group_user?.last_name}</a></div>
                    <div className="updated-info">
                      <FontAwesomeIcon icon={faClock} style={{ paddingRight: '7px' }}/>
                      <span>Last updated {moment(classDetailSelector?.updated_at, "YYYY/MM/DD").format("MM/YYYY")}</span> <FontAwesomeIcon icon={faGlobe} className="world-icon" style={{ paddingLeft: '7px', paddingRight: '9px' }} /> 
                      <span>English <FontAwesomeIcon icon={faNewspaper} className="world-icon" style={{ paddingLeft: '10px', paddingRight: '7px' }}/> English, Arabic [Auto],</span>
                      <span className="languages">13 more</span>
                    </div>
                  </div>
                  <div className="right-panel">
                    <section className="broad-selections">
                      <div className="container" style={{ marginTop: '-40px', marginBottom: '-40px' }}>
                        <div className="group-class-course-cards">
                          <article className="card group-class-card">
                            <div className="image-container">
                              <img src={baseUrlPostGres() + classDetailSelector?.picture} />
                              <h3>Subscribe to Hire Instructor’s top courses</h3>
                              <div class="purchase-section-container">
                                Get this course, plus 11,000+ of our top-rated courses, with Personal Plan. 
                                <a class="purchase-section-container" href="/personal-plan/">Learn more</a>
                              </div>
                              <div class="subscription">
                                <a href="" className="subscription-cta">
                                  <div class="subscription-cta--text">Start subscription</div>
                                </a>
                                <div class="per-month-rate">Starting at ₹850 per month</div>
                                <div class="cancel-plan">Cancel anytime</div>
                              </div>
                              <div class="separator">
                                <span className="left"><hr /></span><span className="midel">or</span><span  className="right"><hr /></span>
                              </div>
                              <span className="group-class-price">
                                {classDetailSelector?.price ? (
                                  <span>₹{classDetailSelector?.price}</span>
                                ) : (
                                  <>
                                    <span>₹00.00</span>
                                  </>
                                )}
                              </span>
                              <div class="add-to-cart-button">
                                {cartItems.some((cartItem) => cartItem.id === classDetailSelector.id) ? (
                                  <button type="button" className="add-to-cart" onClick={() => navigate('/CartPage')}>Go to cart</button>
                                ) : (
                                  <button type="button" className="add-to-cart" onClick={() => handleAddToCart(classDetailSelector)}>Add to Cart</button>
                                )}
                                {showSuccessMessage && <p className="added-cart-msg">Item successfully added to cart!</p>}
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="mid-container">
                  <div class="course-includes-comp">
                    <section className="broad-selection">
                      <div className="container" style={{ marginTop: '-40px', marginBottom: '-40px' }}>
                        <h2>This course includes</h2>
                        <div className="incentive-certificate">
                          <span><FontAwesomeIcon icon={faTrophy} style={{ paddingRight: '10px' }} />  Certificate of completion</span>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="course-component">
                    <section className="broad-selection">
                      <div className="container" style={{ marginTop: '-40px', marginBottom: '-40px' }}>
                        <h2>Course content</h2>
                        {classDetailSelector?.group_course_class?.length > 0 ? 
                          <div className="row">
                            {classDetailSelector.group_course_class.map((course, index) => (
                              <div className="col-lg-12">
                                <div className="course-title">{course.topic_class}</div>
                              </div>
                            ))}
                          </div>
                          :
                          <>
                            <span>Course not found</span>
                          </>
                        }
                      </div>
                    </section>
                  </div>
                  <div class="full-des-component">
                    <section className="broad-selection">
                      <div className="container" style={{ marginTop: '-40px', marginBottom: '-40px' }}>
                        <h2>Description</h2>
                        <div class="description">
                          {groupClassFullDescription ? (
                            <>
                            {classDetailSelector?.full_description}
                            <span onClick={handleGroupClassShowLessClick}>Show less</span>
                            </>
                          ) : (
                            <>
                              {classDetailSelector?.full_description.substring(0, 300)}
                              {classDetailSelector?.full_description?.length > 300 && (
                                <span onClick={handleGroupClassShowMoreClick}>Show more</span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="instructor-component">
                    <section className="broad-selection">
                      <div className="container" style={{ marginTop: '-40px', marginBottom: '-40px' }}>
                        <h2>Instructor</h2>
                        <div class="ins-heading">
                          <a href={`/myprofile/instructor`}>Dr. {classDetailSelector?.group_user?.first_name} {classDetailSelector?.group_user?.last_name}</a>
                        </div>
                        <br />
                        <div class="ins-short-des">{classDetailSelector?.group_user?.short_descriptions}</div>
                        <div class="ins-img">
                          <img src={baseUrlPostGres() + classDetailSelector?.group_user?.profile_image} />
                        </div>
                        <div class="ins-detail">
                          <div class="ins-rating"><FontAwesomeIcon icon={faStar} style={{ fontSize: '12px', paddingRight: '7px' }} />0.0 Instructor Rating</div>
                          <div class="ins-reviews"><FontAwesomeIcon icon={faCertificate} style={{ fontSize: '12px', paddingRight: '9px' }} />0 Reviews</div>
                          <div class="ins-students"><FontAwesomeIcon icon={faUserFriends} style={{ fontSize: '12px', paddingRight: '7px' }} />2,697,077 Students</div>
                          <div class="ins-courses"><FontAwesomeIcon icon={faCirclePlay} style={{ fontSize: '12px', paddingRight: '10px' }} />
                            {classDetailSelector?.group_course_class?.length > 0 
                              ? classDetailSelector?.group_course_class?.length
                              : '0' 
                            } Courses
                          </div>
                        </div>
                        <br /><br /><br /><br /><br /><br />
                        <div class="ins-full-des">
                          {instructorFullDescription ? (
                            <>
                            {classDetailSelector?.group_user?.descriptions}
                            <span onClick={handleInstructorShowLessClick}>Show less</span>
                            </>
                          ) : (
                            <>
                              {classDetailSelector?.group_user?.descriptions.substring(0, 300)}
                              {classDetailSelector?.group_user?.descriptions?.length > 300 && (
                                <span onClick={handleInstructorShowMoreClick}>Show more</span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                  {instructorGroupClassSelector?.length > 0 && (
                    <div className="instructor-more-group-classes">
                      <section className="broad-selection">
                        <div className="container" style={{ marginTop: '-40px', marginBottom: '-40px' }}>
                          <h2>More Group Classes by Dr. <a href={`/myprofile/instructor`}>{classDetailSelector?.group_user?.first_name} {classDetailSelector?.group_user?.last_name}</a></h2>
                          <br />
                          <div className="swiper mySwiper">
                            <div className="swiper-container" ref={swiperRef}>
                              <div className="swiper-wrapper">
                                {instructorGroupClassSelector?.reduce((acc, curr, index) => {
                                  const chunkSize = window.innerWidth < 530 ? 1 : 4; // 1 card per slider on mobile, 4 cards per slider on web
                                  const chunkIndex = Math.floor(index / chunkSize);
                                  if (!acc[chunkIndex]) { acc[chunkIndex] = []; }
                                  acc[chunkIndex].push(curr);
                                  return acc;
                                }, []).map((chunk, chunkIndex) => (
                                  <div className="swiper-slide" key={chunkIndex}>
                                    <div className="course-cards">
                                      {chunk.map((event, index) => (
                                        <article className="card" key={index}>
                                          <div className="image-container">
                                            <img src={baseUrlPostGres() + event.picture}  onClick={(e) => handleGroupClassDetailClick(e, event.id)}/>
                                            <h3 onClick={(e) => handleGroupClassDetailClick(e, event.id)}>{event.class_name}</h3>
                                            <p className="instructor-name">Dr. {event.group_user.first_name} {event.group_user.last_name}</p>
                                            {/* Star Rating Placeholder */}
                                            <div className="rating" style={{ marginLeft: '0px' }}>
                                              ★★★★☆
                                              <span className="text-sm text-gray-600">(120)</span>
                                            </div>
                                            <h5 className="price" data-course-price style={{ marginLeft: '0px' }}>${event.price}</h5>
                                            {/* Add to Cart or Go to Cart button based on cart items */}
                                          </div>
                                        </article>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="swiper-button-prev" style={{ left: '0px', marginTop: '-160px', display: instructorGroupClassSelector?.length > 1 ? 'block' : 'none' }} />
                              <div className="swiper-button-next" style={{ right: '23px', marginTop: '-160px', display: instructorGroupClassSelector?.length > 1 ? 'block' : 'none' }} />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  )}
                </div>
              </>
            }
          </div>
        </div>
      <Footer />
    </>
  );
};
export default GroupClassDetail;