import { getAuth, signInWithPopup } from "firebase/auth";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const auth = getAuth();
const socialMediaAuth = async (provider) => {
    return await signInWithPopup(auth, provider).then((response) => {
        return response
    }).catch((error) => {
        // toast.error(error, { theme: "colored" })
        return error
    })
}

export default socialMediaAuth