export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const IS_LOADING = "IS_LOADING";
export const IS_LANGUAGE_LOADING = "IS_LANGUAGE_LOADING";
export const IS_RATING_LOADING = "IS_RATING_LOADING";
export const IS_CLASSESS_DETAIL_LOADING = "IS_CLASSESS_DETAIL_LOADING";
export const IS_GROUP_LOADING = "IS_GROUP_LOADING"
export const IS_REFER_LOADING = "IS_REFER_LOADING";
export const AUTH_LOADING = "AUTH_LOADING";
export const IS_NOTIFICATION_LOADING = "IS_NOTIFICATION_LOADING";
export const IS_CHAT_LOADING = "IS_CHAT_LOADING";
export const IS_BLOG_LOADING = "IS_BLOG_LOADING"
export const IS_CMS_LOADING = "IS_CMS_LOADING"
export const IS_GIFT_CARD_LOADING = "IS_GIFT_CARD_LOADING";
export const IS_PROFILE_LOADING = "IS_PROFILE_LOADING";
export const IS_PAYMENT_LOADING = "IS_PAYMENT_LOADING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USERLIST_SUCCESS = "USERLIST_SUCCESS";
export const IS_SUPPORT_LOADING = "IS_SUPPORT_LOADING";
export const PAGE_LIST_SUCCESS = "PAGE_LIST_SUCCESS";

//------------------------------------------------common-API's---------------------------------------------------------------

export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_ERROR = "RESEND_OTP_ERROR"


//------------------------------------------------instructor-auth---------------------------------------------------------------

export const INSTRUCTOR_LOGIN_SUCCESS = "INSTRUCTOR_LOGIN_SUCCESS";
export const INSTRUCTOR_LOGIN_ERROR = "INSTRUCTOR_LOGIN_ERROR";

export const INSTRUCTOR_REGISTER_SUCCESS = "INSTRUCTOR_REGISTER_SUCCESS";
export const INSTRUCTOR_REGISTER_ERROR = "INSTRUCTOR_REGISTER_ERROR";

export const INSTRUCTOR_SOCIAL_LOGIN_SUCCESSFULL = "INSTRUCTOR_SOCIAL_LOGIN_SUCCESSFULL";
export const INSTRUCTOR_SOCIAL_LOGIN_ERROR = "INSTRUCTOR_SOCIAL_LOGIN_ERROR";

export const LINKEDIN_SOCIAL_LOGIN_SUCCESS = "LINKEDIN_SOCIAL_LOGIN_SUCCESS";
export const LINKEDIN_SOCIAL_LOGIN_ERROR = "LINKEDIN_SOCIAL_LOGIN_ERROR";

export const COMPLETE_PROFILE_SOCIAL_SUCCESS = "COMPLETE_PROFILE_SOCIAL_SUCCESS";
export const COMPLETE_PROFILE_SOCIAL_ERROR = "COMPLETE_PROFILE_SOCIAL_ERROR";

//------------------------------------------------instructor-completeProfile---------------------------------------------------------------

export const GET_CLASS_LEVEL_INSTRUCTOR_SUCCESS = "GET_CLASS_LEVEL_INSTRUCTOR_SUCCESS";
export const GET_CLASS_LEVEL_INSTRUCTOR_ERROR = "GET_CLASS_LEVEL_INSTRUCTOR_ERROR";

export const GET_QUALIFICATION_LEVEL_INSTRUCTOR_SUCCESS = "GET_QUALIFICATION_LEVEL_INSTRUCTOR_SUCCESS";
export const GET_QUALIFICATION_LEVEL_INSTRUCTOR_ERROR = "GET_QUALIFICATION_LEVEL_INSTRUCTOR_ERROR";

export const GET_GRADE_INSTRUCTOR_SUCCESS = "GET_GRADE_INSTRUCTOR_SUCCESS";
export const GET_GRADE_INSTRUCTOR_ERROR = "GET_GRADE_INSTRUCTOR_ERROR";

export const GET_TAGS_INSTRUCTOR_SUCCESS = "GET_TAGS_INSTRUCTOR_SUCCESS";
export const GET_TAGS_INSTRUCTOR_ERROR = "GET_TAGS_INSTRUCTOR_ERROR";

//------------------------------------------------ instructor Complete Profile ---------------------------------------------------------------
export const SUCCESS_COMPLETE_PROFILE_PERSONAL_DETAILS = "SUCCESS_COMPLETE_PROFILE_PERSONAL_DETAILS";
export const ERROR_COMPLETE_PROFILE_PERSONAL_DETAILS = "ERROR_COMPLETE_PROFILE_PERSONAL_DETAILS";

export const SUCCESS_COMPLETE_PROFILE_QUALIFICATIONS = "SUCCESS_COMPLETE_PROFILE_QUALIFICATIONS";
export const ERROR_COMPLETE_PROFILE_QUALIFICATIONS = "ERROR_COMPLETE_PROFILE_QUALIFICATIONS";

export const SUCCESS_COMPLETE_PROFILE_TEACHING_DETAILS = "SUCCESS_COMPLETE_PROFILE_TEACHING_DETAILS";
export const ERROR_COMPLETE_PROFILE_TEACHING_DETAILS = "ERROR_COMPLETE_PROFILE_TEACHING_DETAILS";

export const SUCCESS_COMPLETE_PROFILE_AVAILABILITY = "SUCCESS_COMPLETE_PROFILE_AVAILABILITY";
export const ERROR_COMPLETE_PROFILE_AVAILABILITY = "ERROR_COMPLETE_PROFILE_AVAILABILITY";

export const SUCCESS_COMPLETE_PROFILE_BANK_DETAILS = "SUCCESS_COMPLETE_PROFILE_BANK_DETAILS";
export const ERROR_COMPLETE_PROFILE_BANK_DETAILS = "ERROR_COMPLETE_PROFILE_BANK_DETAILS";

export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_ERROR = "PROFILE_GET_ERROR";

export const PROFILE_CHANGE_PASSWORD_SUCCESS = "PROFILE_CHANGE_PASSWORD_SUCCESS";
export const PROFILE_CHANGE_PASSWORD_ERROR = "PROFILE_CHANGE_PASSWORD_ERROR";

export const PROFILE_PERSONAL_DETAILS_SUCCESS = "PROFILE_PERSONAL_DETAILS_SUCCESS";
export const PROFILE_PERSONAL_DETAILS_ERROR = "PROFILE_PERSONAL_DETAILS_ERROR";

export const PROFILE_QUALIFICATIONS_SUCCESS = "PROFILE_QUALIFICATIONS_SUCCESS";
export const PROFILE_QUALIFICATIONS_ERROR = "PROFILE_QUALIFICATIONS_ERROR";

export const PROFILE_TEACHING_DETAILS_SUCCESS = "PROFILE_TEACHING_DETAILS_SUCCESS";
export const PROFILE_TEACHING_DETAILS_ERROR = "PROFILE_TEACHING_DETAILS_ERROR";

export const PROFILE_AVAILABILITY_SUCCESS = "PROFILE_AVAILABILITY_SUCCESS";
export const PROFILE_AVAILABILITY_ERROR = "PROFILE_AVAILABILITY_ERROR";

export const STUDENT_UPDATE_PROFILE_SUCCESS = "STUDENT_UPDATE_PROFILE_SUCCESS";
export const STUDENT_UPDATE_PROFILE_ERROR = "STUDENT_UPDATE_PROFILE_ERROR";

export const PROFILE_BANKDETAILS_SUCCESS = "PROFILE_BANKDETAILS_SUCCESS";
export const PROFILE_BANKDETAILS_ERROR = "PROFILE_BANKDETAILS_ERROR";

//------------------------------------------------instructor-myprofile-section---------------------------------------------------------------

export const BOOKING_REQUEST_LIST_SUCCESS = "BOOKING_REQUEST_LIST_SUCCESS";
export const BOOKING_REQUEST_LIST_ERROR = "BOOKING_REQUEST_LIST_ERROR";


export const BOOKING_REJECT_SUCCESS = "BOOKING_REJECT_SUCCESS";
export const BOOKING_REJECT_ERROR = "BOOKING_REJECT_ERROR";

export const CANCELLATION_REASON_LIST_SUCCESS = "CANCELLATION_REASON_LIST_SUCCESS";
export const CANCELLATION_REASON_LIST_ERROR = "CANCELLATION_REASON_LIST_ERROR";

export const BOOKING_CANCELLATIONS_SUCCESS = "BOOKING_CANCELLATIONS_SUCCESS";
export const BOOKING_CANCELLATIONS_ERROR = "BOOKING_CANCELLATIONS_ERROR";

//-------------------------------------------------instructor-list-api----------------------------------------------------------------------------------------

export const INSTRUCTOR_LIST_SUCCESS = "INSTRUCTOR_LIST_SUCCESS";
export const INSTRUCTOR_LIST_ERROR = "INSTRUCTOR_LIST_ERROR";

export const INSTRUCTOR_DETAIL_SUCCESS = "INSTRUCTOR_DETAIL_SUCCESS";
export const INSTRUCTOR_DETAIL_ERROR = "INSTRUCTOR_DETAIL_ERROR";

export const INSTRUCTOR_LIST_DATA_SUCCESS = "INSTRUCTOR_LIST_DATA_SUCCESS";
export const INSTRUCTOR_LIST_DATA_ERROR = "INSTRUCTOR_LIST_DATA_ERROR";

export const INSTRUCTOR_LEVEL_SUCCESS = "INSTRUCTOR_LEVEL_SUCCESS";
export const INSTRUCTOR_LEVEL_ERROR = "INSTRUCTOR_LEVEL_ERROR";

export const INSTRUCTOR_SUBJECTS_LIST_SUCCESS = "INSTRUCTOR_SUBJECTS_LIST_SUCCESS";
export const INSTRUCTOR_SUBJECTS_LIST_ERROR = "INSTRUCTOR_SUBJECTS_LIST_ERROR";


export const INSTRUCTOR_LEVEL_LIST_SUCCESS = "INSTRUCTOR_LEVEL_LIST_SUCCESS";
export const INSTRUCTOR_LEVEL_LIST_ERROR = "INSTRUCTOR_LEVEL_LIST_ERROR";


export const INSTRUCTOR_PAYMENT_SUCCESS = "INSTRUCTOR_PAYMENT_SUCCESS";
export const INSTRUCTOR_PAYMENT_ERROR = "INSTRUCTOR_PAYMENT_ERROR";

export const INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_SUCCESS = "INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_SUCCESS";
export const INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_ERROR = "INSTRUCTOR_PAYMENT_PROBLEM_SOLVING_ERROR";

export const INSTRUCTOR_PAYMENT_SUBSCRIPTION_SUCCESS = "INSTRUCTOR_PAYMENT_SUBSCRIPTION_SUCCESS";
export const INSTRUCTOR_PAYMENT_SUBSCRIPTION_ERROR = "INSTRUCTOR_PAYMENT_SUBSCRIPTION_ERROR";

export const INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_SUCCESS = "INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_SUCCESS";
export const INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_ERROR = "INSTRUCTOR_PAYMENT_SCHEDULE_BOOKING_ERROR";

export const TUTOR_SUBJECT_SUCCESS = "TUTOR_SUBJECT_SUCCESS";
export const TUTOR_SUBJECT_ERROR = "TUTOR_SUBJECT_ERROR";

export const TUTOR_LANGUAGE_SUCCESS = "TUTOR_LANGUAGE_SUCCESS";
export const TUTOR_LANGUAGE_ERROR = "TUTOR_LANGUAGE_ERROR";

//------------------------------------------------student-auth---------------------------------------------------------------
export const STUDENT_LOGIN_SUCCESS = "STUDENT_LOGIN_SUCCESS";
export const STUDENT_LOGIN_ERROR = "STUDENT_LOGIN_ERROR";

export const FORGOT_EMAIL_SUCCESS = "FORGOT_EMAIL_SUCCESS";
export const FORGOT_EMAIL_ERROR = "FORGOT_EMAIL_ERROR";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const STUDENT_REGISTER_SUCCESS = "STUDENT_REGISTER_SUCCESS";
export const STUDENT_REGISTER_ERROR = "STUDENT_REGISTER_ERROR";

export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_ERROR = "OTP_VERIFICATION_ERROR";

export const STUDENT_SOCIAL_LOGIN_SUCCESSFULL = "STUDENT_SOCIAL_LOGIN_SUCCESSFULL";
export const STUDENT_SOCIAL_LOGIN_ERROR = "STUDENT_SOCIAL_LOGIN_ERROR";


export const INSTRUCTOR_PERSONAL_DETAIL_SUCCESS = "INSTRUCTOR_PERSONAL_DETAIL_SUCCESS";
export const INSTRUCTOR_PERSONAL_DETAIL_ERROR = "INSTRUCTOR_PERSONAL_DETAIL_ERROR";

export const STUDENT_TO_INSTRUCTOR_SWITCH_SUCCESS = "STUDENT_TO_INSTRUCTOR_SWITCH_SUCCESS";
export const STUDENT_TO_INSTRUCTOR_SWITCH_ERROR = "STUDENT_TO_INSTRUCTOR_SWITCH_ERROR";




//----------------------------------------------card-type-------------------------------------------------------

export const CARD_CREATE_SUCCESS = "CARD_CREATE_SUCCESS";
export const CARD_CREATE_ERROR = "CARD_CREATE_ERROR";

export const CARD_LIST_SUCCESS = "CARD_LIST_SUCCESS";
export const CARD_LIST_ERROR = "CARD_LIST_ERROR";

export const CARD_REMOVE_SUCCESS = "CARD_REMOVE_SUCCESS";
export const CARD_REMOVE_ERROR = "CARD_REMOVE_ERROR";

export const PAY_BY_CARD_SUCCESS = "PAY_BY_CARD_SUCCESS";
export const PAY_BY_CARD_ERROR = "PAY_BY_CARD_ERROR";

//------------------------------------------------Group-page----------------------------------------------------------------------------------

export const ADD_GROUP_CLASSES_SUCCESS = "ADD_GROUP_CLASSES_SUCCESS";
export const ADD_GROUP_CLASSES_ERROR = "ADD_GROUP_CLASSES_ERROR";

export const GROUP_CLASSES_LIST_SUCCESS = "GROUP_CLASSES_LIST_SUCCESS";
export const GROUP_CLASSES_LIST_ERROR = "GROUP_CLASSES_LIST_ERROR";

export const GLOBAL_GROUP_CLASSES_LIST_ERROR = "GLOBAL_GROUP_CLASSES_LIST_ERROR";
export const GLOBAL_GROUP_CLASSES_LIST_SUCCESS = "GLOBAL_GROUP_CLASSES_LIST_SUCCESS";

export const GROUP_CLASSES_DETAIL_SUCCESS = "GROUP_CLASSES_DETAIL_SUCCESS";
export const GROUP_CLASSES_DETAIL_ERROR = "GROUP_CLASSES_DETAIL_ERROR";

//------------------------------------------------home-page----------------------------------------------------------------------------------

export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_ERROR = "GET_COURSES_ERROR";

export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_ERROR = "GET_BLOGS_ERROR";

export const GET_DYNAMIC_LANGUAGE_SUCCESS = "GET_DYNAMIC_LANGUAGE_SUCCESS";
export const GET_DYNAMIC_LANGUAGE_ERROR = "GET_DYNAMIC_LANGUAGE_ERROR";

export const GET_BLOG_DETAIL_SUCCESS = "GET_BLOG_DETAIL_SUCCESS";
export const GET_BLOG_DETAIL_ERROR = "GET_BLOG_DETAIL_ERROR";

export const BLOG_LIST_SUCCESS = "BLOG_LIST_SUCCESS";
export const BLOG_LIST_ERROR = "BLOG_LIST_ERROR";

export const BLOG_DELETE_SUCCESS = "BLOG_DELETE_SUCCESS";
export const BLOG_DELETE_ERROR = "BLOG_DELETE_ERROR";

export const BLOG_EDIT_SUCCESS = "BLOG_EDIT_SUCCESS";
export const BLOG_EDIT_ERROR = "BLOG_EDIT_ERROR";

export const BLOG_VIEW_SUCCESS = "BLOG_VIEW_SUCCESS";
export const BLOG_VIEW_ERROR = "BLOG_VIEW_ERROR";

export const BLOG_PREVIEW_DETAIL_SUCCESS = "BLOG_PREVIEW_DETAIL_SUCCESS";
export const BLOG_PREVIEW_DETAIL_ERROR = "BLOG_PREVIEW_DETAIL_ERROR";

export const GET_BLOGS_CATEGORY_SUCCESS = "GET_BLOGS_CATEGORY_SUCCESS";
export const GET_BLOGS_CATEGORY_ERROR = "GET_BLOGS_CATEGORY_ERROR";

export const BLOG_IS_LIKE_SUCCESS = "BLOG_IS_LIKE_SUCCESS";
export const BLOG_IS_LIKE_ERROR = "BLOG_IS_LIKE_ERROR";

export const BLOG_IS_FOLLOW_SUCCESS = "BLOG_IS_FOLLOW_SUCCESS";
export const BLOG_IS_FOLLOW_ERROR = "BLOG_IS_FOLLOW_ERROR";

export const GET_RECENT_BLOG_SUCCESS = "GET_RECENT_BLOG_SUCCESS";
export const GET_RECENT_BLOG_ERROR = "GET_RECENT_BLOG_ERROR";

export const BLOG_REPORT_SUCCESS = "BLOG_REPORT_SUCCESS";
export const BLOG_REPORT_ERROR = "BLOG_REPORT_ERROR";

export const BLOG_REPORT_FLAG_SUCCESS = "BLOG_REPORT_FLAG_SUCCESS";
export const BLOG_REPORT_FLAG_ERROR = "BLOG_REPORT_FLAG_ERROR";

export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_ERROR = "GET_FAQ_ERROR";

export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_ERROR = "GET_BANNER_ERROR";

export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";

export const GET_TESTIMONIALSS_SUCCESS = "GET_TESTIMONIALSS_SUCCESS";
export const GET_TESTIMONIALSS_ERROR = "GET_TESTIMONIALSS_ERROR";

export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_ERROR = "GET_COUNTRY_LIST_ERROR";

export const POST_STATES_LIST_SUCCESS = "POST_STATES_LIST_SUCCESS";
export const POST_STATES_LIST_ERROR = "POST_STATES_LIST_ERROR";

export const POST_CITIES_LIST_SUCCESS = "POST_CITIES_LIST_SUCCESS";
export const POST_CITIES_LIST_ERROR = "POST_CITIES_LIST_ERROR";

export const SEARCH_SUGGESTION_SUCCESS = "SEARCH_SUGGESTION_SUCCESS";
export const SEARCH_SUGGESTION_ERROR = "SEARCH_SUGGESTION_ERROR";

export const GLOBAL_SEARCH_SUCCESS = "GLOBAL_SEARCH_SUCCESS";
export const GLOBAL_SEARCH_ERROR = "GLOBAL_SEARCH_ERROR";

export const HOME_PAGE_SECOND_SECTION_LIST = "HOME_PAGE_SECOND_SECTION_LIST";
export const ERROR_HOME_PAGE_SECOND_SECTION_LIST = "ERROR_HOME_PAGE_SECOND_SECTION_LIST";

export const HOME_PAGE_THIRD_SECTION_LIST = "HOME_PAGE_THIRD_SECTION_LIST";
export const ERROR_HOME_PAGE_THIRD_SECTION_LIST = "ERROR_HOME_PAGE_THIRD_SECTION_LIST";

export const RANDOM_GROUP_CLASS = "RANDOM_GROUP_CLASS";
export const ERROR_RANDOM_GROUP_CLASS = "ERROR_RANDOM_GROUP_CLASS";

export const HOME_PAGE_SIX_SECTION_LIST = "HOME_PAGE_SIX_SECTION_LIST";
export const ERROR_HOME_PAGE_SIX_SECTION_LIST = "ERROR_HOME_PAGE_SIX_SECTION_LIST";

export const CATEGORIES_WITH_GROUP_CLASS = "CATEGORIES_WITH_GROUP_CLASS";
export const ERROR_CATEGORIES_WITH_GROUP_CLASS = "ERROR_CATEGORIES_WITH_GROUP_CLASS";

export const HOME_PAGE_EIGHT_SECTION_LIST = "HOME_PAGE_EIGHT_SECTION_LIST";
export const ERROR_HOME_PAGE_EIGHT_SECTION_LIST = "ERROR_HOME_PAGE_EIGHT_SECTION_LIST";

export const HOME_PAGE_TEN_SECTION_LIST = "HOME_PAGE_TEN_SECTION_LIST";
export const ERROR_HOME_PAGE_TEN_SECTION_LIST = "ERROR_HOME_PAGE_TEN_SECTION_LIST";

export const HOME_PAGE_FOURTH_SECTION_LIST = "HOME_PAGE_FOURTH_SECTION_LIST";
export const ERROR_HOME_PAGE_FOURTH_SECTION_LIST = "ERROR_HOME_PAGE_FOURTH_SECTION_LIST";

export const HOME_PAGE_NINE_SECTION_LIST = "HOME_PAGE_NINE_SECTION_LIST";
export const ERROR_HOME_PAGE_NINE_SECTION_LIST = "ERROR_HOME_PAGE_NINE_SECTION_LIST";

//------------------------------------------------cms-page----------------------------------------------------------------------------------

export const ONDEMAND_COUPON_SUCCESS = "ONDEMAND_COUPON_SUCCESS";
export const ONDEMAND_COUPON_ERROR = "ONDEMAND_COUPON_ERROR";

export const APPLY_COUPON_CODE_SUCCESS = "APPLY_COUPON_CODE_SUCCESS";
export const APPLY_COUPON_CODE_ERROR = "APPLY_COUPON_CODE_ERROR";

export const ALL_COUPON_LIST_SUCCESS = "ALL_COUPON_LIST_SUCCESS";
export const ALL_COUPON_LIST_ERROR = "ALL_COUPON_LIST_ERROR";

export const GROUP_CLASS_COUPON_SUCCESS = "GROUP_CLASS_COUPON_SUCCESS";
export const GROUP_CLASS_COUPON_ERROR = "GROUP_CLASS_COUPON_ERROR";

export const ONDEMAND_PAYMENT_ZOOMLINK_SUCCESS = "ONDEMAND_PAYMENT_ZOOMLINK_SUCCESS";
export const ONDEMAND_PAYMENT_ZOOMLINK_ERROR = "ONDEMAND_PAYMENT_ZOOMLINK_ERROR";

export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_ERROR = "CONTACT_US_ERROR";

export const SUPPORT_LIST_SUCCESS = "SUPPORT_LIST_SUCCESS";
export const SUPPORT_LIST_ERROR = "SUPPORT_LIST_ERROR";

export const SUPPORT_DETAIL_SUCCESS = "SUPPORT_DETAIL_SUCCESS";
export const SUPPORT_DETAIL_ERROR = "SUPPORT_DETAIL_ERROR";

export const SUPPORT_MESSAGE_REPLY_SUCCESS = "SUPPORT_MESSAGE_REPLY_SUCCESS";
export const SUPPORT_MESSAGE_REPLY_ERROR = "SUPPORT_MESSAGE_REPLY_ERROR";

//------------------------------------------------blog-page----------------------------------------------------------------------------------

export const BLOG_ADD_SUCCESS = "BLOG_ADD_SUCCESS";
export const BLOG_ADD_ERROR = "BLOG_ADD_ERROR";

export const BLOG_LIKE_SUCCESS = "BLOG_LIKE_SUCCESS";
export const BLOG_LIKE_ERROR = "BLOG_LIKE_ERROR";

export const ADD_BLOG_COMMENT_SUCCESS = "ADD_BLOG_COMMENT_SUCCESS";
export const ADD_BLOG_COMMENT_ERROR = "ADD_BLOG_COMMENT_ERROR";

export const BLOG_COMMENT_LIST_SUCCESS = "BLOG_COMMENT_LIST_SUCCESS";
export const BLOG_COMMENT_LIST_ERROR = "BLOG_COMMENT_LIST_ERROR";

export const MANAGE_SUBSCRIPTION_LIST_SUCCESS = "MANAGE_SUBSCRIPTION_LIST_SUCCESS";
export const MANAGE_SUBSCRIPTION_LIST_ERROR = "MANAGE_SUBSCRIPTION_LIST_ERROR";

export const MANAGE_SUBSCRIPTION_LIST_STUDENT_SUCCESS = "MANAGE_SUBSCRIPTION_LIST_STUDENT_SUCCESS";
export const MANAGE_SUBSCRIPTION_LIST_STUDENT_ERROR = "MANAGE_SUBSCRIPTION_LIST_STUDENT_ERROR";

export const CLASSES_DETAILS_LIST_SUCCESS = "CLASSES_DETAILS_LIST_SUCCESS";
export const CLASSES_DETAILS_LIST_ERROR = "CLASSES_DETAILS_LIST_ERROR";

export const RATING_REVIEW_LIST_SUCCESS = "RATING_REVIEW_LIST_SUCCESS";
export const RATING_REVIEW_LIST_ERROR = "RATING_REVIEW_LIST_ERROR";

export const RATING_REVIEW_ADD_SUCCESS = "RATING_REVIEW_ADD_SUCCESS";
export const RATING_REVIEW_ADD_ERROR = "RATING_REVIEW_ADD_ERROR";


export const ADD_AMOUNT_SUCCESS = "ADD_AMOUNT_SUCCESS"
export const ADD_AMOUNT_ERROR = "ADD_AMOUNT_ERROR"

export const ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS"
export const ADD_FAVORITE_ERROR = "ADD_FAVORITE_ERROR";

export const ADD_GIFTCARD_SUCCESS = "ADD_GIFTCARD_SUCCESS"
export const ADD_GIFTCARD_ERROR = "ADD_GIFTCARD_ERROR";

export const REDEEM_GIFTCARD_SUCCESS = "REDEEM_GIFTCARD_SUCCESS"
export const REDEEM_GIFTCARD_ERROR = "REDEEM_GIFTCARD_ERROR";

export const WISHLIST_GET_SUCCESS = "WISHLIST_GET_SUCCESS"
export const WISHLIST_GET_ERROR = "WISHLIST_GET_ERROR";

export const TRANSACTION_HISTORY_LIST_SUCCESS = "TRANSACTION_HISTORY_LIST_SUCCESS"
export const TRANSACTION_HISTORY_LIST_ERROR = "TRANSACTION_HISTORY_LIST_ERROR";

export const BOOKING_REQUEST_ACCEPT_SUCCESS = "BOOKING_REQUEST_ACCEPT_SUCCESS"
export const BOOKING_REQUEST_ACCEPT_ERROR = "BOOKING_REQUEST_ACCEPT_ERROR";

export const SOLUTION_OF_PROBLEM_SOLVING_SUCCESS = "SOLUTION_OF_PROBLEM_SOLVING_SUCCESS"
export const SOLUTION_OF_PROBLEM_SOLVING_ERROR = "SOLUTION_OF_PROBLEM_SOLVING_ERROR";

export const PROBLEM_SOLVING_DETAIL_VIEW_SUCCESS = "PROBLEM_SOLVING_DETAIL_VIEW_SUCCESS"
export const PROBLEM_SOLVING_DETAIL_VIEW_ERROR = "PROBLEM_SOLVING_DETAIL_VIEW_ERROR";

export const SUBSCRIPTION_DETAILS_SUCCESS = "SUBSCRIPTION_DETAILS_SUCCESS"
export const SUBSCRIPTION_DETAILS_ERROR = "SUBSCRIPTION_DETAILS_ERROR";

export const PROBLEM_SOLVING_DETAIL_SUCCESS = "PROBLEM_SOLVING_DETAIL_SUCCESS"
export const PROBLEM_SOLVING_DETAIL_ERROR = "PROBLEM_SOLVING_DETAIL_ERROR";


export const REFER_HISTORY_LIST_SUCCESS = "REFER_HISTORY_LIST_SUCCESS"
export const REFER_HISTORY_LIST_ERROR = "REFER_HISTORY_LIST_ERROR";

export const REFER_TOTAL_POINTS_SUCCESS = "REFER_TOTAL_POINTS_SUCCESS"
export const REFER_TOTAL_POINTS_ERROR = "REFER_TOTAL_POINTS_ERROR";

export const REFERRAL_REDEEM_SUCCESS = "REFERRAL_REDEEM_SUCCESS"
export const REFERRAL_REDEEM_ERROR = "REFERRAL_REDEEM_ERROR";

export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS"
export const NOTIFICATION_LIST_ERROR = "NOTIFICATION_LIST_ERROR";

export const CLEAR_NOTIFICATION_SUCCESS = "CLEAR_NOTIFICATION_SUCCESS"
export const CLEAR_NOTIFICATION_ERROR = "CLEAR_NOTIFICATION_ERROR";

export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR";

export const NEW_NOTIFICATION_STATUS_SUCCESS = "NEW_NOTIFICATION_STATUS_SUCCESS"
export const NEW_NOTIFICATION_STATUS_ERROR = "NEW_NOTIFICATION_STATUS_ERROR";

export const CMS_LIST_SUCCESS = "CMS_LIST_SUCCESS"
export const CMS_LIST_ERROR = "CMS_LIST_ERROR";

export const COUNTRY_FLAG_NAME_SUCCESS = "COUNTRY_FLAG_NAME_SUCCESS"
export const COUNTRY_FLAG_NAME_ERROR = "COUNTRY_FLAG_NAME_ERROR";

export const GROUP_CLASSES_BOOKING_SUCCESS = "GROUP_CLASSES_BOOKING_SUCCESS"
export const GROUP_CLASSES_BOOKING_ERROR = "GROUP_CLASSES_BOOKING_ERROR";

export const INSTRUCTOR_GROUP_CLASS_LIST_SUCCESS = "INSTRUCTOR_GROUP_CLASS_LIST_SUCCESS"
export const INSTRUCTOR_GROUP_CLASS_LIST_ERROR = "INSTRUCTOR_GROUP_CLASS_LIST_ERROR";

export const PROFILE_ONLINE_OFLINE_SUCCESS = "PROFILE_ONLINE_OFLINE_SUCCESS"
export const PROFILE_ONLINE_OFLINE_ERROR = "PROFILE_ONLINE_OFLINE_ERROR";

export const CHAT_REPORT_SUCCESS = "CHAT_REPORT_SUCCESS"
export const CHAT_REPORT_ERROR = "CHAT_REPORT_ERROR";

export const VERIFICATION_BADGE_PURCHASES_SUCCESS = "VERIFICATION_BADGE_PURCHASES_SUCCESS"
export const VERIFICATION_BADGE_PURCHASES_ERROR = "VERIFICATION_BADGE_PURCHASES_ERROR";

export const VERIFICATION_BADGE_STATUS_SUCCESS = "VERIFICATION_BADGE_STATUS_SUCCESS"
export const VERIFICATION_BADGE_STATUS_ERROR = "VERIFICATION_BADGE_STATUS_ERROR";

export const PAYMENT_DETAILS_HISTORY_SUCCESS = "PAYMENT_DETAILS_HISTORY_SUCCESS"
export const PAYMENT_DETAILS_HISTORY_ERROR = "PAYMENT_DETAILS_HISTORY_ERROR";

export const BADGE_BILLING_HISTORY_SUCCESS = "BADGE_BILLING_HISTORY_SUCCESS"
export const BADGE_BILLING_HISTORY_ERROR = "BADGE_BILLING_HISTORY_ERROR";

export const DELETE_GROUP_CLASSES_SUCCESS = "DELETE_GROUP_CLASSES_SUCCESS"
export const DELETE_GROUP_CLASSES_ERROR = "DELETE_GROUP_CLASSES_ERROR";

export const EDIT_GROUP_CLASSES_SUCCESS = "EDIT_GROUP_CLASSES_SUCCESS"
export const EDIT_GROUP_CLASSES_ERROR = "EDIT_GROUP_CLASSES_ERROR";

export const VIEW_GROUP_CLASSES_SUCCESS = "VIEW_GROUP_CLASSES_SUCCESS"
export const VIEW_GROUP_CLASSES_ERROR = "VIEW_GROUP_CLASSES_ERROR";


export const SUCCESS_LINKEDIN_CERTIFICATE = "SUCCESS_LINKEDIN_CERTIFICATE"
export const ERROR_LINKEDIN_CERTIFICATE = "ERROR_LINKEDIN_CERTIFICATE"

export const SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE = "SUCCESS_UPLOAD_LINKEDIN_CERTIFICATE"
export const ERROR_UPLOAD_LINKEDIN_CERTIFICATE = "ERROR_UPLOAD_LINKEDIN_CERTIFICATE"

export const IS_COMPLETE_PROFILE_CHECK_SUCCESS = "IS_COMPLETE_PROFILE_CHECK_SUCCESS"
export const IS_COMPLETE_PROFILE_CHECK_ERROR = "IS_COMPLETE_PROFILE_CHECK_ERROR"

export const BOOKING_REQUEST_STATUS_SUCCESS = "BOOKING_REQUEST_STATUS_SUCCESS"
export const BOOKING_REQUEST_STATUS_ERROR = "BOOKING_REQUEST_STATUS_ERROR"

export const INSTRUCTOR_PROFILE_AVAILABILITY = "INSTRUCTOR_PROFILE_AVAILABILITY"
export const ERROR_INSTRUCTOR_PROFILE_AVAILABILITY = "ERROR_INSTRUCTOR_PROFILE_AVAILABILITY"

export const INSTRUCTOR_FETCH_AVAILABILITY_EVENTS = "INSTRUCTOR_FETCH_AVAILABILITY_EVENTS"
export const ERROR_INSTRUCTOR_FETCH_AVAILABILITY_EVENTS = "ERROR_INSTRUCTOR_FETCH_AVAILABILITY_EVENTS"

export const MORE_GROUP_CLASS_BY_INSTRUCTOR_SUCCESS = "MORE_GROUP_CLASS_BY_INSTRUCTOR_SUCCESS"
export const MORE_GROUP_CLASS_BY_INSTRUCTOR_ERROR = "MORE_GROUP_CLASS_BY_INSTRUCTOR_ERROR"

