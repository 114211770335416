import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoDataFound from './NoDataFound/NoDataFound'
import { useSelector } from 'react-redux'

const CardListView = ({ handleShow, cardList, handleRadioInput }) => {
    const languageSelector = useSelector(state => state.home.dynamic_language)
    return (
        <div className="payment-three-card row g-3">
            {cardList ?
                cardList?.length > 0 ?
                    <>
                        {cardList?.map((ele, index) =>
                            <div className="col-md-12 col-lg-6 col-xl-4" key={index}>
                                <div className="cardnumber-box">
                                    <div className="radio-box">
                                        <div className="check-box-create">
                                            <label htmlFor={`card${index}`}>
                                                <div>
                                                    {ele.brand === "MasterCard" ? (
                                                        <img src='/images/card/master-card.svg' className='img-fluid' width="72" alt='MasterCard' />
                                                    ) : ele.brand === "Visa" ? (
                                                        <img src='/images/card/visa-card.svg' className='img-fluid' width="72" alt='Visa Card' />
                                                    ) : ele.brand === "American Express" ? (
                                                        <img src='/images/card/american-express-card.svg' className='img-fluid' width="72" alt="American Express" />
                                                    ) : ele.brand === "Discover" ? (
                                                        <img src='/images/card/discover-card.svg' className='img-fluid' width="72" alt="Discover" />
                                                    ) : ele.brand === "JCB" ? (
                                                        <img src='/images/card/jcb-card.svg' className='img-fluid' width="72" alt="JCB" />
                                                    ) : ele.brand === "Diners Club" ? (
                                                        <img src='/images/card/diners-club-card.svg' className='img-fluid' width="72" alt="Diners Club" />
                                                    ) : ele.brand === "UnionPay" ? (
                                                        <img src='/images/card/union-pay-card.svg' className='img-fluid' width="72" alt="Union Pay" />
                                                    ) : <img src='/images/card/unknown-card.svg' className='img-fluid' width="72" alt="unknown" />
                                                    }
                                                </div>
                                                <div>
                                                    <h3>{ele.funding} {languageSelector?.payment_details?.card}</h3>
                                                </div>
                                                <div>
                                                    <p>xxxx xxxx xxxx {ele.last4}</p>
                                                </div>
                                            </label>
                                            <input
                                                type="radio"
                                                id={`card${index}`}
                                                name="card"
                                                onClick={() => handleRadioInput(ele)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="col-md-12 col-lg-6 col-xl-4">
                            <div className="cardnumber-box">
                                <button type="button" className="btn btn-payment-button" onClick={handleShow}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                    >
                                        <rect
                                            id="Rectangle_1951"
                                            data-name="Rectangle 1951"
                                            width="40"
                                            height="40"
                                            rx="5"
                                            fill="#fed756"
                                        />
                                        <g
                                            id="Group_4989"
                                            data-name="Group 4989"
                                            transform="translate(8 8)"
                                        >
                                            <path
                                                id="Path_672"
                                                data-name="Path 672"
                                                d="M0,0H24V24H0Z"
                                                fill="none"
                                            />
                                            <line
                                                id="Line_204"
                                                data-name="Line 204"
                                                y2="14"
                                                transform="translate(12 5)"
                                                fill="none"
                                                stroke="#2d2d2d"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                            />
                                            <line
                                                id="Line_205"
                                                data-name="Line 205"
                                                x2="14"
                                                transform="translate(5 12)"
                                                fill="none"
                                                stroke="#2d2d2d"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                            />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='d-flex align-items-center justify-content-between blank-card-block'>
                            <h6> {languageSelector?.payment_details?.cards} </h6>
                            <button className="btn small-btn" type="button" onClick={handleShow}> {languageSelector?.payment_details?.add_new_card} </button>
                        </div>
                        <NoDataFound />
                    </>
                :
                [1, 2, 3, 4, 5, 6].map((_, index) => {
                    return (
                        <div className='col-md-4 col-lg-6 col-xl-4 col-sm-12' key={index}>
                            <Skeleton height={152} borderRadius="2.6rem" />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CardListView