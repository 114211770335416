import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FieldText } from './../../../components/InputText/InputText';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import TextErrorMsg from "../../../components/TextErrorMsg/TextErrorMsg";
import * as Yup from 'yup';
import Button from "./../../../components/Buttons/Buttons";
import { Regex } from './../../../Utils/Regex';
import { ChooseFile } from '../../../components/ChooseFile/ChooseFile';
import ImagePreview from '../../../components/ImagePreview';
import { GetInstructorGrades, GetInstructorQualificationLevel } from '../../../redux/actions/instructorAction';
import { InstructorProfileQualificationAction } from '../../../redux/actions/CompleteProfileAction';
import { GetYearsList } from '../../../Utils/Function';

const Qualifications = ({ profileData }) => {
    const dispatch = useDispatch();

    const instructorLevel = useSelector((state) => state?.instructor?.instructor_qualifiaction);
    const gradeLevel = useSelector((state) => state?.instructor?.instructor_grades);
    const isLoading = useSelector((state) => state.profile.loading);
    const ProfileQSelector = useSelector((state) => state.profile.profile_qualifications);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const [specialization, setSpecialization] = useState([]);
    const [submitClick, setSubmitClick] = useState(false);
    const [endYearNumber, setEndYearNumber] = useState()


    useEffect(() => {
        dispatch(GetInstructorQualificationLevel());
        dispatch(GetInstructorGrades());
    }, [dispatch]);

    useEffect(() => {
        if (profileData && profileData?.userspecialization?.length > 0) {
            profileData?.userspecialization.forEach((element) => {
                setSpecialization((s) => {
                    return [...s, { subject_name: element.subject_name, grade_id: element.grade_id }];
                });
            });
        }
    }, [profileData])

    const handleAddMoreSubjects = () => {
        setSpecialization((s) => {
            return [...s, { subject_name: "", grade_id: "" }];
        });
    };
    const handleSpecializationDelete = (id) => {
        setSpecialization((oldValues) => {
            return oldValues.filter((_, i) => i !== id);
        });
    };

    const handleOnChange = (e) => {
        const index = e.target.id;
        if (e.target.name === "subject_name" || e.target.name === "grade_id") {
            setSpecialization((s) => {
                const newArr = s.slice();
                newArr[index][e.target.name] = e.target.value;
                return newArr;
            });
        } else if (e.target.name === "clg_start_year") {
            const diffYear = new Date().getFullYear() - (Number(e.target.value) - 1)
            setEndYearNumber(diffYear)
        }
    };

    const handleQualificationSubmit = (value) => {
        let formData = new FormData();
        formData.append("ins_level_id", value.ins_level_id);
        formData.append("school_name", value.school_name);
        formData.append("percentage", value.percentage);
        formData.append("subject_name", value.full_subject_name);
        formData.append("clg_start_year", value.clg_start_year);
        formData.append("clg_end_year", value.clg_end_year);
        if (typeof value.certificate_image === "object") {
            formData.append("certificate_image", value.certificate_image);
        }
        formData.append("specialization", JSON.stringify(specialization));
        dispatch(InstructorProfileQualificationAction(formData));
        setSubmitClick(true);
    };

    useEffect(() => {
        if (Object.keys(ProfileQSelector).length > 0 && submitClick) {
            setSubmitClick(false);
        }
    }, [ProfileQSelector, submitClick]);

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        Object.keys(profileData).length > 0 ?
            <Formik
                initialValues={{
                    ins_level_id: profileData?.ins_level_id,
                    school_name: profileData?.school_name,
                    percentage: profileData?.percentage,
                    full_subject_name: profileData?.subject_name,
                    clg_start_year: profileData?.clg_start_year,
                    clg_end_year: profileData?.clg_end_year,
                    certificate_image: profileData?.certificate_image ? profileData?.certificate_image : "",
                }}
                validationSchema={() => Yup.object().shape({
                    ins_level_id: Yup.string().required(languageSelector?.validation?.education_level),
                    school_name: Yup.string().required(languageSelector?.validation?.school_university_name),
                    percentage: Yup.string().required(languageSelector?.validation?.grade),
                    full_subject_name: Yup.string().required(languageSelector?.validation?.subject),
                    clg_start_year: Yup.string().min(4, languageSelector?.validation?.year_digits).max(4, languageSelector?.validation?.year_digits).matches(Regex.ALLOW_NUMBER_ONLY, languageSelector?.validation?.year_invalid).required(languageSelector?.validation?.year_started),
                    clg_end_year: Yup.string().min(4, languageSelector?.validation?.year_digits).max(4, languageSelector?.validation?.year_digits).matches(Regex.ALLOW_NUMBER_ONLY, languageSelector?.validation?.year_invalid).required(languageSelector?.validation?.year_completed),
                    certificate_image: Yup.string().required(languageSelector?.validation?.certificate),
                })}
                onSubmit={handleQualificationSubmit}
            >
                {formik => {
                    return (
                        <Form onChange={handleOnChange}>
                        <FormikFromFunc />
                            <div className="qualifications-section">
                                <div className="tab-content">
                                    <div className="myprofilebox2">
                                        <div className="myprofilebox1 dropdown-select fix-placeholder">
                                            <div className="App">
                                                <Field
                                                    as="select"
                                                    name="ins_level_id"
                                                    className="select-control w-100"
                                                    value={formik.values.ins_level_id}
                                                >
                                                    <option value=""> {languageSelector?.placeholder?.select} </option>
                                                    {instructorLevel.map((value, index) => {
                                                        return (
                                                            <option value={value.id} key={index}> {value.inst_levels_name} </option>
                                                        );
                                                    })}
                                                </Field>
                                                <ErrorMessage name="ins_level_id" component={TextErrorMsg} />
                                                <span className="placeholder-name"> {languageSelector?.placeholder?.education_level} </span>
                                            </div>
                                        </div>

                                        <div className="myprofilebox1">
                                            <FieldText
                                                name="school_name"
                                                type="text"
                                                placeholder={languageSelector?.placeholder?.school_university}
                                                defaultValue={formik.values.school_name}
                                            />
                                        </div>

                                        <div className="myprofilebox1 dropdown-select fix-placeholder">
                                            <div className="App">
                                                <Field
                                                    as="select"
                                                    name="ins_level_id"
                                                    className="select-control w-100"
                                                    value={formik.values.percentage}
                                                >
                                                    <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                    {gradeLevel.map((value, index) => {
                                                        return (
                                                            <option value={value.id} key={index}> {value.grades_name} </option>
                                                        );
                                                    })}
                                                </Field>
                                                <ErrorMessage name="ins_level_id" component={TextErrorMsg} />
                                                <span className="placeholder-name"> {languageSelector?.placeholder?.grade} </span>
                                            </div>
                                        </div>

                                        <div className="myprofilebox1">
                                            <FieldText
                                                name="full_subject_name"
                                                type="text"
                                                placeholder={languageSelector?.placeholder?.subject}
                                                defaultValue={formik.values.full_subject_name}
                                            />
                                        </div>

                                        <div className="myprofilebox1 dropdown-select fix-placeholder">
                                            <div className="App">
                                                <Field
                                                    as="select"
                                                    name="clg_start_year"
                                                    className="select-control w-100"
                                                    value={formik.values.clg_start_year}
                                                >
                                                    <option value=""> {languageSelector?.placeholder?.select} </option>
                                                    {GetYearsList(50).map((value, index) => {
                                                        return (
                                                            <option value={value} key={index}> {value} </option>
                                                        );
                                                    })}
                                                </Field>
                                                <span className="placeholder-name"> {languageSelector?.placeholder?.year_started} </span>
                                                <ErrorMessage name="clg_start_year" component={TextErrorMsg} />
                                            </div>
                                        </div>
                                        <div className="myprofilebox1 dropdown-select fix-placeholder">
                                            <div className="App">
                                                <Field
                                                    as="select"
                                                    name="clg_end_year"
                                                    className="select-control w-100"
                                                    value={formik.values.clg_end_year}
                                                >
                                                    <option value=""> {languageSelector?.placeholder?.select} </option>
                                                    {GetYearsList(endYearNumber ? endYearNumber : new Date().getFullYear() - (Number(formik.values.clg_end_year) - 1)).map((value, index) => {
                                                        return (
                                                            <option value={value} key={index}> {value} </option>
                                                        );
                                                    })}
                                                </Field>
                                                <span className="placeholder-name"> {languageSelector?.placeholder?.year_completed} </span>
                                                <ErrorMessage name="clg_end_year" component={TextErrorMsg} />
                                            </div>
                                        </div>

                                        <div className="myprofilebox1 certificate-file-box">
                                            <div className="drag-image-video">
                                                <Field name="certificate_image">
                                                    {({ form, field }) => {
                                                        return (
                                                            <ChooseFile
                                                                name={field.name}
                                                                label={languageSelector?.placeholder?.certificate}
                                                                handleFileChoose={(e) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        e.certificate_image
                                                                    )
                                                                }
                                                                error="File should be .jpg .png .jpeg and Size < 5MB"
                                                                accept={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
                                                                acceptInputFile="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx"
                                                                Size={6000}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                                <ErrorMessage
                                                    name="certificate_image"
                                                    component={TextErrorMsg}
                                                />
                                            </div>
                                        </div>

                                        <div className="myprofilebox1">
                                            {typeof formik.values.certificate_image !== "object" ?
                                                <div className="uploadImg-block d-block w-100">
                                                    <ImagePreview file={formik.values.certificate_image} imageAlt="Image" />
                                                </div>
                                                :
                                                null
                                            }
                                        </div>

                                        <div className="myprofilebox1 w-100 d-flex align-items-center justify-content-between">
                                            <h3> {languageSelector?.my_account?.specialization} </h3>
                                            <div className="addsubjects">
                                                <button
                                                    className="btn becomaninstructor"
                                                    onClick={handleAddMoreSubjects}
                                                    type="button"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="17.5"
                                                        height="17.5"
                                                        viewBox="0 0 17.5 17.5"
                                                    >
                                                        <circle
                                                            id="Ellipse_101"
                                                            data-name="Ellipse 101"
                                                            cx="8"
                                                            cy="8"
                                                            r="8"
                                                            transform="translate(0.75 0.75)"
                                                            fill="none"
                                                            stroke="#005eb5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                        />
                                                        <line
                                                            id="Line_176"
                                                            data-name="Line 176"
                                                            x2="6"
                                                            transform="translate(5.75 8.75)"
                                                            fill="none"
                                                            stroke="#005eb5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                        />
                                                        <line
                                                            id="Line_177"
                                                            data-name="Line 177"
                                                            y2="6"
                                                            transform="translate(8.75 5.75)"
                                                            fill="none"
                                                            stroke="#005eb5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                        />
                                                    </svg>
                                                    {languageSelector?.my_account?.add_more_button}
                                                </button>
                                            </div>
                                        </div>
                                        {specialization.map((value, index) => {
                                            return (
                                                <div className="myprofilebox1 addmoresubjects-section" key={index}>
                                                    <div className="myprofilebox3">
                                                        <FieldText
                                                            type="text"
                                                            name="subject_name"
                                                            placeholder={languageSelector?.placeholder?.specialization_subject}
                                                            id={index}
                                                            defaultValue={value.subject_name}
                                                        />
                                                    </div>

                                                    <div className="myprofilebox3 dropdown-select fix-placeholder">
                                                        <div className="App">
                                                            <Field
                                                                as="select"
                                                                name="grade_id"
                                                                className="select-control w-100"
                                                                id={index}
                                                                value={value.grade_id}
                                                            >
                                                                <option value=""> {languageSelector?.placeholder?.select} </option>
                                                                {gradeLevel.map((value, index) => {
                                                                    return (
                                                                        <option value={value.id} key={index}> {value.grades_name} </option>
                                                                    );
                                                                })}
                                                            </Field>
                                                            <span className="placeholder-name"> {languageSelector?.placeholder?.grade} </span>
                                                        </div>
                                                    </div>

                                                    <div className="myprofilebox3 cros-button-sub">
                                                        {index > 0 ?
                                                            <button
                                                                onClick={() => handleSpecializationDelete(index)}
                                                                value={index}
                                                                type="button"
                                                                className={
                                                                    index < 1
                                                                        ? `btn becomaninstructor`
                                                                        : `active btn becomaninstructor`
                                                                }
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20.687"
                                                                    height="26.597"
                                                                    viewBox="0 0 20.687 26.597"
                                                                >
                                                                    <path
                                                                        id="Icon_material-delete-forever"
                                                                        data-name="Icon material-delete-forever"
                                                                        d="M8.978,28.142A2.964,2.964,0,0,0,11.933,31.1H23.754a2.964,2.964,0,0,0,2.955-2.955V10.41H8.978Zm3.635-10.521L14.7,15.538l3.147,3.133,3.133-3.133,2.083,2.083-3.133,3.133,3.133,3.133L20.976,25.97l-3.133-3.133L14.711,25.97l-2.083-2.083,3.133-3.133Zm10.4-11.644L21.537,4.5H14.149L12.672,5.978H7.5V8.933H28.187V5.978Z"
                                                                        transform="translate(-7.5 -4.5)"
                                                                        fill="#fff"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            :
                                                            null}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="save-button">
                                        <Button
                                            BtnText={languageSelector?.my_account?.save_changes_button}
                                            name="next"
                                            BtnColor="primary"
                                            BtnType="submit"
                                            disabled={!formik.isValid || isLoading}
                                            hasSpinner={isLoading && submitClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            :
            null
    )
}
export default Qualifications