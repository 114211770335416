import React, { useEffect, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import { Accordion } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import './Instructorprofile.scss';
import PaymentMethod from '../Studentclasstype/PaymentMethod';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Buttons/Buttons';
import { BadgeBillingHistoryAction, ResetVerificationBadgePurchasesAction, VerificationBadgePurchasesAction, VerificationBadgeStatusAction } from '../../redux/actions/PaymentAction';
import { toast } from 'react-toastify';
import { ProfileGetAction } from '../../redux/actions/CompleteProfileAction';
import moment from 'moment';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { baseUrlPostGres } from '../../redux/constant';


const Getverificationbadge = () => {
    const dispatch = useDispatch()
    const [selectPlan, setSelectPlan] = useState("1")
    const [submitClick, setSubmitClick] = useState(false)
    const [state, setState] = useState({});
    const [methodPayment, setMethodPayment] = useState();
    const paymentSelector = useSelector((state) => state?.card?.verification_badge)
    const verificationBadgeStatus = useSelector((state) => state?.card?.verification_status)
    const badgeBillingHistorySelector = useSelector((state) => state?.card?.billing_history)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const isloading = useSelector((state) => state?.card?.loading)

    let amount = (selectPlan == "1" ? 2 : 20);

    const handleOnChange = (e) => {
        setSelectPlan(e.target.value)
    }

    useEffect(() => {
        dispatch(VerificationBadgeStatusAction())
        dispatch(BadgeBillingHistoryAction())
    }, [dispatch])

    const handlePayment = () => {
        if (methodPayment == undefined) {
            toast.error(languageSelector?.validation?.select_any_one_payment_method, { theme: "colored" })
            return <></>
        }
        if (methodPayment === "card" && Object.keys(state).length == 0) {
            toast.error(languageSelector?.validation?.select_any_one_card, { theme: "colored" })
            return <></>
        }
        let data = {
            amount: amount,
            select_plan: selectPlan,
            pay_from: 1,
            card_id: state.card_id,
            last4: state.last4
        }
        dispatch(VerificationBadgePurchasesAction(data));
        setSubmitClick(true)
    }

    useEffect(() => {
        if (Object.keys(paymentSelector).length > 0 && submitClick) {
            if (paymentSelector.status) {
                setSubmitClick(false)
                dispatch(ResetVerificationBadgePurchasesAction())
                dispatch(ProfileGetAction("instructor"))
                dispatch(VerificationBadgeStatusAction())
            } else {
                setSubmitClick(false)
            }
        }
    }, [paymentSelector, submitClick])

    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.verification_badge?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.verification_badge?.page_title} </span></p>
                </div>

                <div className='myprofile-section'>
                    <div className='container'>
                        <div className='row g-5'>
                            <div className='col-md-12 col-lg-4'>
                                <Instructorsidebar />
                            </div>

                            <div className='col-md-12 col-lg-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.verification_badge?.page_title} </h2>
                                </div>
                                <div className='my-profile-details'>
                                    <div className='tab-section-profile'>

                                        <div className='getverificationbadge-section'>
                                            <div className='plan-section'>
                                                <div className='plan-box'>
                                                    <div className="radio-box">
                                                        <div className="check-box-create">
                                                            <input type="radio" id="plan1" name="month" value="1" checked={selectPlan == "1"} onChange={handleOnChange} />
                                                            <label htmlFor="plan1"><div><h3> {languageSelector?.verification_badge?.monthly} </h3><p>30 {languageSelector?.verification_badge?.days}</p></div> <div><h2>${verificationBadgeStatus?.plan?.month ? verificationBadgeStatus?.plan?.month : 0} <span>/ {languageSelector?.verification_badge?.month} </span></h2></div></label>
                                                        </div>
                                                        {verificationBadgeStatus?.data?.verification_plan == 1 ? <div className="badge_plan_label"> {languageSelector?.verification_badge?.current_plan} </div> : null}
                                                    </div>
                                                </div>

                                                <div className='plan-box'>
                                                    <div className="radio-box">
                                                        <div className="check-box-create">
                                                            <input required type="radio" id="plan2" name="month" value="2" checked={selectPlan == "2"} onChange={handleOnChange} />
                                                            <label htmlFor="plan2"><div><h3> {languageSelector?.verification_badge?.yearly} </h3><p>365 {languageSelector?.verification_badge?.days}</p></div> <div><h2>${verificationBadgeStatus?.plan?.month ? verificationBadgeStatus?.plan?.year : 0}<span>/ {languageSelector?.verification_badge?.year}</span></h2></div></label>
                                                        </div>
                                                        {verificationBadgeStatus?.data?.verification_plan == 2 ? <div className="badge_plan_label"> {languageSelector?.verification_badge?.current_plan} </div> : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='paymentmethod-box'>
                                                <h2> {languageSelector?.verification_badge?.select_payment} </h2>
                                                <div className="payment-three-card">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <PaymentMethod verficationStatus={verificationBadgeStatus?.data?.verification_plan} walletShow={false} type="instructor" setState={setState} state={state} setMethodPayment={setMethodPayment} methodPayment={methodPayment} amount={amount} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {verificationBadgeStatus?.data?.verficiation_status ?
                                                <div className='buynow'>
                                                    <Button
                                                        BtnText={languageSelector?.verification_badge?.already_buy}
                                                        BtnColor="primary"
                                                        BtnType="button"
                                                        disabled={true}
                                                    />
                                                </div>
                                                :
                                                <div className='buynow'>
                                                    {methodPayment === "paypal" ?
                                                        <PayPalScriptProvider
                                                            options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                                                        >
                                                            <PayPalButtons
                                                                createOrder={(_, actions) => {
                                                                    return actions.order
                                                                        .create({
                                                                            purchase_units: [
                                                                                {
                                                                                    amount: {
                                                                                        value: amount,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        })
                                                                        .then((orderId) => {
                                                                            return orderId;
                                                                        });
                                                                }}
                                                                forceReRender={[amount, methodPayment]}
                                                                className="heroBtnbutton m-auto"
                                                                onApprove={(_, actions) => {
                                                                    return actions.order.capture().then((details) => {
                                                                        let data = {
                                                                            amount: amount,
                                                                            select_plan: selectPlan,
                                                                            pay_from: 4,
                                                                            order_id: details.id,
                                                                            order_status: details.status,
                                                                            payer_detail: details.payer
                                                                        }
                                                                        dispatch(VerificationBadgePurchasesAction(data));
                                                                        setSubmitClick(true)
                                                                    });
                                                                }}
                                                                style={{
                                                                    layout: 'horizontal',
                                                                    color: 'blue',
                                                                    shape: 'rect',
                                                                    label: 'pay',
                                                                    height: 55,
                                                                    tagline: false
                                                                }}
                                                            />
                                                        </PayPalScriptProvider>
                                                        :
                                                        <Button
                                                            BtnText={languageSelector?.verification_badge?.buy_now}
                                                            name="pay"
                                                            BtnColor="primary"
                                                            BtnType="button"
                                                            onClick={handlePayment}
                                                            disabled={isloading}
                                                            hasSpinner={submitClick && isloading}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='change-password-box billinghistory'>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header> {languageSelector?.verification_badge?.billing_histroy} </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='manages-section'>
                                                        <Table striped responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th> {languageSelector?.verification_badge?.date} </th>
                                                                    <th> {languageSelector?.verification_badge?.details} </th>
                                                                    <th> {languageSelector?.verification_badge?.amount} </th>
                                                                    <th> {languageSelector?.verification_badge?.download} </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(badgeBillingHistorySelector).length > 0 ?
                                                                    badgeBillingHistorySelector?.data?.length > 0 ?
                                                                        badgeBillingHistorySelector?.data?.map((value, index) => (
                                                                            <tr key={index}>
                                                                                <td>{moment(value?.created_at).format("DD MMM'YY")} <br /> at {moment(value?.created_at).format("LT")}</td>
                                                                                <td>{value?.plan == "1" ? languageSelector?.verification_badge?.monthly : languageSelector?.verification_badge?.yearly} </td>
                                                                                <td><span>${value.txn_amount ? value.txn_amount : 0}</span></td>
                                                                                <td>
                                                                                    <a
                                                                                        href={`${baseUrlPostGres()}${value.billing_pdf}`}
                                                                                        target='_blank'
                                                                                    >
                                                                                        {languageSelector?.verification_badge?.invoice} {moment(value?.created_at).format("DD MMM'YY")} </a></td>
                                                                            </tr>
                                                                        ))
                                                                        :
                                                                        <tr>
                                                                            <td colSpan="4"><NoDataFound /></td>
                                                                        </tr>
                                                                    :
                                                                    [1, 2, 3, 4, 5, 6].map((_, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td colSpan="4"><Skeleton height={50} borderRadius="1rem" /></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Getverificationbadge


