import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import "../../style.scss";
import { useParams } from "react-router-dom";
import { baseUrlPostGres } from "../../redux/constant";
import { useDispatch, useSelector } from "react-redux";
import { AddFavoriteAction, PostInstructorDetail, ResetAddFavoriteAction, ResetPostInstructorDetail } from "../../redux/actions/instructorAction";
import { toast } from "react-toastify";
import Introduction from "./Introduction";
import LessonFees from "./LessonFees";
import RatingsReviews from "./RatingsReviews";
import InstructorSideBar from "./InstructorSideBar";
import RelatedGroupClass from "./RelatedGroupClass";
import { getUserSettings, isValidHttpUrl } from "../../Utils/Function";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Instructordetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [isOpen, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [instructorDetails, setInstructorDetails] = useState({});
    const [modalNavigateURL, setModalNavigateURL] = useState();
    const [favoriteStatus, setFavoritestatus] = useState(false)
    const instructorDetailsState = useSelector((state) => state.instructor.instructor_detail);
    const wishlistSelector = useSelector(state => state.instructor.add_favorite)
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const userDetail = getUserSettings()

    useEffect(() => {
        let formData = new FormData();
        formData.append("id", id);
        if (userDetail?.user?.id) {
            formData.append("user_id", userDetail?.user?.id);
        }
        dispatch(PostInstructorDetail(formData));
        return () => dispatch(ResetPostInstructorDetail())
    }, [id, dispatch]);

    useEffect(() => {
        setInstructorDetails(instructorDetailsState);
    }, [instructorDetailsState]);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setModalNavigateURL("");
    };

    const handleRedirectClassType = () => {
        if (userDetail?.user?.role === 2) {
            if (instructorDetails?.is_online === 0 && modalNavigateURL?.id == 1) {
                toast.error(languageSelector?.inst_page?.instrucator_offline, { theme: "colored" })
                return <></>
            }
            modalNavigateURL ? navigate(modalNavigateURL.url) : toast.error(languageSelector?.inst_page?.please_select_class_type, { theme: "colored" })
        } else {
            navigate("/student-login")
            toast.error(languageSelector?.common?.please_login_student, { theme: "colored" })
        }
    };

    const handleWishList = (e) => {
        if (Object.keys(userDetail).length > 0) {
            setFavoritestatus(true)
            dispatch(AddFavoriteAction({
                type_wish: "1",
                instructor_id: e.target.id
            }))
        } else {
            toast.error(languageSelector?.common?.please_login_student, { theme: "colored" })
        }
    };

    useEffect(() => {
        if (Object.keys(wishlistSelector).length > 0) {
            if (wishlistSelector.status) {
                dispatch(ResetAddFavoriteAction())
                setFavoritestatus(false)
                let formData = new FormData();
                formData.append("id", id);
                formData.append("user_id", userDetail?.user?.id);
                dispatch(PostInstructorDetail(formData));
            }
        }
    }, [wishlistSelector])
    return (
        <>
            <div id="footer-bottom" className="Instructordetail">
                <Header />
                {Object.keys(instructorDetails).length > 0 ?
                    <div className="teacher-details-box">
                        <div className="teacher-content-card-top">
                            <div className="container">
                                <div className="teacher-main-section">
                                    <div className="teacher-video">
                                        <img
                                            src={instructorDetails?.profile_image ?
                                                isValidHttpUrl(instructorDetails?.profile_image) ? instructorDetails?.profile_image
                                                    : `${baseUrlPostGres()}${instructorDetails?.profile_image}`
                                                : "/images/blank-profile-picture.jpg"}
                                            alt={instructorDetails.first_name}
                                        />

                                        <div className="on-off-btn">
                                            {instructorDetails?.is_online === 0 ? (
                                                <span className="unactive">{instructorDetails?.is_online}</span>
                                            ) : (
                                                <span className="active">{instructorDetails?.is_online}</span>
                                            )}
                                        </div>
                                        <React.Fragment>
                                            <ModalVideo
                                                channel="custom"
                                                url={`${baseUrlPostGres()}${instructorDetails?.profile_video}`}
                                                autoplay
                                                isOpen={isOpen}
                                                videoId="9xwazD5SyVg"
                                                onClose={() => setOpen(false)}
                                            />
                                            <button className="btn" onClick={() => setOpen(true)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="43.875"
                                                    height="43.875"
                                                    viewBox="0 0 43.875 43.875"
                                                >
                                                    <path
                                                        id="Icon_ionic-ios-play-circle"
                                                        data-name="Icon ionic-ios-play-circle"
                                                        d="M25.313,3.375A21.938,21.938,0,1,0,47.25,25.313,21.934,21.934,0,0,0,25.313,3.375Zm8.838,22.349L19.68,34.478a.469.469,0,0,1-.707-.411V16.559a.467.467,0,0,1,.707-.411L34.151,24.9A.483.483,0,0,1,34.151,25.724Z"
                                                        transform="translate(-3.375 -3.375)"
                                                        fill="#fff"
                                                    />
                                                </svg>
                                            </button>
                                        </React.Fragment>
                                    </div>
                                    <div className="teacher-details">
                                        <div className="main-deatils1">
                                            <div className="detai1">
                                                <h1>
                                                    <span className="detail-profile-name"
                                                        title={`${instructorDetails?.first_name === undefined ||
                                                            instructorDetails?.first_name === null
                                                            ? ""
                                                            : instructorDetails?.first_name
                                                            } ${instructorDetails?.last_name === undefined ||
                                                                instructorDetails?.first_name === null
                                                                ? ""
                                                                : instructorDetails?.last_name
                                                            }`}
                                                    >
                                                        {`${instructorDetails?.first_name === undefined ||
                                                            instructorDetails?.first_name === null
                                                            ? ""
                                                            : instructorDetails?.first_name
                                                            } ${instructorDetails?.last_name === undefined ||
                                                                instructorDetails?.first_name === null
                                                                ? ""
                                                                : instructorDetails?.last_name
                                                            }`}
                                                    </span>
                                                    <span className="flag">
                                                        <img src={`https://flagcdn.com/32x24/${instructorDetails.country?.iso2.toLowerCase()}.png`} />
                                                    </span>
                                                    {/* <span className="flag"><img src={`https://flagcdn.com/${instructorDetails.country?.iso2.toLowerCase()}.svg`} width={30} /></span> */}
                                                    <span className="checked-mark">
                                                        {instructorDetails?.verified_Sign === 1 && (
                                                            <img src={"/images/checked-mark.png"} alt="" />
                                                        )}
                                                    </span>
                                                </h1>
                                                <p>
                                                    <span>
                                                        <b>
                                                            {instructorDetails?.ratingavg ?
                                                                <>
                                                                    {instructorDetails?.ratingavg && [...new Array(Math.ceil(Number(instructorDetails?.ratingavg)))].map((_, index) => {
                                                                        return (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="25"
                                                                                height="24"
                                                                                viewBox="0 0 25 24"
                                                                                key={index}
                                                                            >
                                                                                <path
                                                                                    id="Icon_metro-star-full"
                                                                                    data-name="Icon metro-star-full"
                                                                                    d="M27.571,11.684l-9-1-3-8-4,8-9,1,6,6-1,9,8-4,7,4-1-9Z"
                                                                                    transform="translate(-2.571 -2.684)"
                                                                                    fill="#fed756"
                                                                                ></path>
                                                                            </svg>
                                                                        )
                                                                    })}

                                                                    {instructorDetails?.ratingavg && [...new Array((5 - Number(instructorDetails?.ratingavg).toFixed(0)))].map((_, index) => {
                                                                        return (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="25.12"
                                                                                height="23.891"
                                                                                viewBox="0 0 25.12 23.891"
                                                                                key={index}
                                                                            >
                                                                                <path
                                                                                    id="Icon_metro-star-full"
                                                                                    data-name="Icon metro-star-full"
                                                                                    d="M27.691,11.808l-8.679-1.261L15.131,2.683l-3.881,7.864L2.571,11.808l6.28,6.121L7.368,26.573l7.762-4.081,7.762,4.081L21.411,17.93l6.28-6.121Z"
                                                                                    transform="translate(-2.571 -2.683)"
                                                                                    fill="#dcdcdc"
                                                                                ></path>
                                                                            </svg>
                                                                        )
                                                                    })}
                                                                    <span>{`${instructorDetails?.ratingavg ? Number(instructorDetails?.ratingavg).toFixed(0) : 0}/5`} ({instructorDetails?.UserRatingCount ? instructorDetails?.UserRatingCount : 0})</span>
                                                                </>
                                                                :
                                                                `${languageSelector?.inst_page?.no_rating} (0)`
                                                            }
                                                        </b>
                                                        {instructorDetails?.user_review?.ratingCount ===
                                                            undefined ||
                                                            instructorDetails?.user_review?.ratingCount === null
                                                            ? ""
                                                            : (instructorDetails?.user_review?.ratingCount)
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="detai1 price-details-card">
                                                <h1 className="price-heading">
                                                    $
                                                    {Math.min(
                                                        instructorDetails?.user_extra_info
                                                            ?.on_demand_price,
                                                        instructorDetails?.user_extra_info
                                                            ?.schedule_price
                                                    )}
                                                    -
                                                    {Math.max(
                                                        instructorDetails?.user_extra_info
                                                            ?.on_demand_price,
                                                        instructorDetails?.user_extra_info
                                                            ?.schedule_price
                                                    )}
                                                    /hr
                                                </h1>
                                            </div>

                                            <div className="subject-button-heart-icon">
                                                <div className="subject-level-instructor-details">
                                                    <h3> {languageSelector?.inst_page?.subject_level} </h3>
                                                    <div className="sub-level">
                                                        {
                                                            instructorDetails?.user_tutor_subject?.length > 0 ?
                                                                <>
                                                                    {instructorDetails?.user_tutor_subject?.map((value, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {index === 0 && ` ${value.tutor_sub_name} (${value.user_subject[0]?.class_name}) `}
                                                                        </React.Fragment>
                                                                    ))}
                                                                    {instructorDetails?.user_tutor_subject?.length > 1 ?
                                                                        <a href="#subLevel" className="btn btn-primary">
                                                                            +{instructorDetails?.user_tutor_subject?.length - 1}
                                                                        </a>
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                                :
                                                                "-"
                                                        }
                                                    </div>
                                                </div>
                                                <div className="book-heart-button">
                                                    <button className="btn" onClick={handleShow}> {languageSelector?.inst_page?.inst_book} </button>

                                                    <div className={`btn heart ${favoriteStatus ? "disabled" : ""}`}>
                                                        <input
                                                            type="checkbox"
                                                            id={id}
                                                            onClick={handleWishList}
                                                            onChange={e => Object.keys(userDetail).length > 0 ? e.target.checked : e.target.checked = false}
                                                            disabled={favoriteStatus}
                                                            checked={instructorDetails?.is_instruc_fav == 1 ? true : false}
                                                        />
                                                        <label htmlFor={id} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-details2">
                                            <div className="detai1">
                                                <h3> {languageSelector?.inst_page?.speaks} </h3>
                                                <h4>
                                                    {instructorDetails?.user_lang?.map((ele, index) =>
                                                        index > 0 ? `| ${ele.language_name} ` : ` ${ele.language_name} `
                                                    )}
                                                </h4>

                                                <h3 className="highest"> {languageSelector?.inst_page?.qualification} </h3>
                                                <h4>
                                                    {instructorDetails?.instructorlevel ?
                                                        instructorDetails?.instructorlevel?.inst_levels_name
                                                        :
                                                        "-"
                                                    }
                                                </h4>
                                            </div>

                                            <div className="detai1 price-details-card">
                                                <h3> {languageSelector?.inst_page?.experience} </h3>
                                                <h4>
                                                    {instructorDetails?.user_extra_info?.exp_year === 0
                                                        ? languageSelector?.inst_page?.fresher
                                                        : `${instructorDetails?.user_extra_info?.exp_year}+`}
                                                </h4>
                                                <h3 className="highest"> {languageSelector?.inst_page?.completed_less} </h3>
                                                <h4> {instructorDetails?.completelession ? instructorDetails?.completelession : 0} </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Skeleton height={440} style={{ marginBottom: "10rem" }} />
                }

                <div className="teachers-description">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-md-12 col-lg-7 col-xl-8 col-12">
                                {Object.keys(instructorDetails).length > 0 ?
                                    <div className="tab-section-profile">
                                        <Tabs defaultActiveKey="introduction">
                                            <Tab eventKey="introduction" title={languageSelector?.inst_page?.introduction}>
                                                <Introduction instructorDetails={instructorDetails} />
                                            </Tab>
                                            <Tab eventKey="LessonFees" title={languageSelector?.inst_page?.lesson_fees}>
                                                <LessonFees instructorDetails={instructorDetails} />
                                            </Tab>
                                            <Tab eventKey="RatingsReviews" title={languageSelector?.inst_page?.ratings_reviews}>
                                                <RatingsReviews instructorDetails={instructorDetails} />
                                            </Tab>
                                        </Tabs>
                                    </div>
                                    :
                                    <Skeleton height={500} borderRadius="2.6rem" />
                                }
                            </div>

                            <InstructorSideBar instructorDetails={instructorDetails} />
                        </div>

                        <RelatedGroupClass user_id={userDetail?.user?.id} />
                    </div>
                </div>

                <Footer />
            </div>

            <Modal
                className="center-modal class-modal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> {languageSelector?.inst_page?.select_class_type} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="classtype-section classtype-section-radio-box">
                        <div className="radio-box">
                            <div className="check-box-create">
                                <input
                                    hidden
                                    type="radio"
                                    id="plan1"
                                    name="month"
                                    onChange={(e) => setModalNavigateURL({ url: e.target.value, id: 1 })}
                                    value={`/ondemandclass/subject-and-level/${id}`}
                                />
                                <label htmlFor="plan1"> {languageSelector?.inst_page?.inst_demand} </label>
                            </div>
                        </div>
                        <div className="radio-box">
                            <div className="check-box-create">
                                <input
                                    hidden
                                    type="radio"
                                    id="plan2"
                                    name="month"
                                    onChange={(e) => setModalNavigateURL({ url: e.target.value, id: 2 })}
                                    value={`/scheduleclass/subject-and-level/${id}`}
                                />
                                <label htmlFor="plan2"> {languageSelector?.inst_page?.inst_schedule} </label>
                            </div>
                        </div>
                        <div className="radio-box">
                            <div className="check-box-create">
                                <input
                                    hidden
                                    type="radio"
                                    id="plan3"
                                    name="month"
                                    onChange={(e) => setModalNavigateURL({ url: e.target.value, id: 3 })}
                                    value={`/problemsolvingclass/upload-problem/${id}`}
                                />
                                <label htmlFor="plan3"> {languageSelector?.inst_page?.inst_solving} </label>
                            </div>
                        </div>
                        <div className="radio-box">
                            <div className="check-box-create">
                                <input
                                    hidden
                                    type="radio"
                                    id="plan4"
                                    name="month"
                                    onChange={(e) => setModalNavigateURL({ url: e.target.value, id: 4 })}
                                    value={`/subscription/selectplan/${id}`}
                                />
                                <label htmlFor="plan4"> {languageSelector?.inst_page?.inst_subscription} </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="email-send continue">
                        <button
                            type="button"
                            className="btn becomaninstructor"
                            onClick={handleRedirectClassType}
                        >
                            {languageSelector?.inst_page?.inst_continue}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Instructordetail;
