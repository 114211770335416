import React, { useEffect, useState } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import { Tab, Tabs } from 'react-bootstrap';
import '../Instructorprofile/Instructorprofile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ResetReviewRatingListAction, ReviewRatingListAction } from '../../redux/actions/ReviewAndRatingAction';
import { isValidHttpUrl } from '../../Utils/Function';
import { baseUrlPostGres } from '../../redux/constant';
import moment from 'moment';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const Studentratingsreviews = () => {
    const dispatch = useDispatch();
    const [key, setKey] = useState("1")
    const ratingReviewSelector = useSelector(state => state.rating.rating_review_list)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(ReviewRatingListAction("student", { type_review: key }))
    }, [dispatch, key])

    const handleSelect = (value) => {
        setKey(value)
        dispatch(ResetReviewRatingListAction())
    }

    return (
        <div id="footer-bottom" className='instructor-profile'>
            <Header />
            <div className="instru-bg-image">
                <h1> {languageSelector?.rating?.page_title} </h1>
                <p>Home / <span>  {languageSelector?.rating?.page_title}  </span></p>
            </div>

            <div className='myprofile-section classesdetails-page'>
                <div className='container'>
                    <div className='row gx-5'>
                        <div className='col-md-12 col-lg-4'>
                            <Studentsidebar />
                        </div>

                        <div className='col-md-12 col-lg-8'>
                            <div className='profile-top-heading'>
                                <h2> {languageSelector?.rating?.page_title} </h2>
                            </div>

                            <div className='booking-request-section classes-tabs-three ratingsreviews-page-instructor'>
                                <Tabs defaultActiveKey="1" onSelect={handleSelect}>
                                    <Tab eventKey="1" title={languageSelector?.rating?.received}>
                                        <div className='ratingsreviews-tab'>
                                            <div className='received-tab'>
                                                {Object.keys(ratingReviewSelector).length > 0 ?
                                                    ratingReviewSelector?.data?.length > 0 ?
                                                        <>
                                                            <div className='total-reviews'>
                                                                <div className='rating-card'>
                                                                    {key == "1" ?
                                                                        <>
                                                                            {ratingReviewSelector?.average?.average_rating && [...new Array(Math.ceil(Number(ratingReviewSelector?.average?.average_rating)))].map((_, index) => {
                                                                                return (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" key={index}>
                                                                                        <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M23.571,10.322,16.315,9.267,13.071,2.683,9.826,9.267,2.571,10.322l5.25,5.125L6.581,22.683l6.489-3.416,6.489,3.416-1.239-7.236,5.25-5.125Z" transform="translate(-2.571 -2.683)" fill="#fed756" />
                                                                                    </svg>
                                                                                )
                                                                            })}

                                                                            {ratingReviewSelector?.average?.average_rating && [...new Array((5 - Number(ratingReviewSelector?.average?.average_rating).toFixed(0)))].map((_, index) => {
                                                                                return (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" key={index}>
                                                                                        <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M23.571,10.322,16.315,9.267,13.071,2.683,9.826,9.267,2.571,10.322l5.25,5.125L6.581,22.683l6.489-3.416,6.489,3.416-1.239-7.236,5.25-5.125Z" transform="translate(-2.571 -2.683)" fill="#dcdcdc" />
                                                                                    </svg>
                                                                                )
                                                                            })}
                                                                            <span>({Number(ratingReviewSelector?.average?.average_rating).toFixed(0)})</span>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <p> {languageSelector?.rating?.total_reviews} : <strong> {ratingReviewSelector?.data?.length}</strong> </p>
                                                                </div>
                                                            </div>
                                                            {ratingReviewSelector?.data?.map((value, index) => {
                                                                return (
                                                                    <div className='all-reviews-section' key={index}>
                                                                        <div className='reviews-box-main'>
                                                                            <div className='reviews-img-box'>
                                                                                <div className='image-inst'>
                                                                                    <img
                                                                                        src={value?.user_name?.profile_image ?
                                                                                            isValidHttpUrl(value?.user_name?.profile_image) ? value?.user_name?.profile_image
                                                                                                : `${baseUrlPostGres()}${value?.user_name?.profile_image}`
                                                                                            : "/images/blank-profile-picture.jpg"}
                                                                                        alt={value?.user_name?.first_name}
                                                                                        referrerPolicy="no-referrer"
                                                                                        className='img'
                                                                                    />
                                                                                    {value?.user_name?.verified_Sign == 1 && (
                                                                                        <span className="Checkmark">
                                                                                            <img src="/images/checked-mark.png" alt="Verified" />
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                                <div>
                                                                                    <h3> {value?.user_name?.first_name} {value?.user_name?.last_name}</h3>
                                                                                    <div className='rating-card'>
                                                                                        {value?.rating && [...new Array(Number(value?.rating))].map((_, index) => {
                                                                                            return (
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" key={index}>
                                                                                                    <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M23.571,10.322,16.315,9.267,13.071,2.683,9.826,9.267,2.571,10.322l5.25,5.125L6.581,22.683l6.489-3.416,6.489,3.416-1.239-7.236,5.25-5.125Z" transform="translate(-2.571 -2.683)" fill="#fed756" />
                                                                                                </svg>
                                                                                            )
                                                                                        })}

                                                                                        {value?.rating && [...new Array((5 - Number(value?.rating)))].map((_, index) => {
                                                                                            return (
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" key={index}>
                                                                                                    <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M23.571,10.322,16.315,9.267,13.071,2.683,9.826,9.267,2.571,10.322l5.25,5.125L6.581,22.683l6.489-3.416,6.489,3.416-1.239-7.236,5.25-5.125Z" transform="translate(-2.571 -2.683)" fill="#dcdcdc" />
                                                                                                </svg>
                                                                                            )
                                                                                        })}
                                                                                        <span>({Number(value?.rating).toFixed(0)})</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='date-section'>
                                                                                <div className="btn"> {moment(value.created_at).format("DD MMM'YY")} </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='description'>
                                                                            <p> {value.comments} </p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <NoDataFound />
                                                    :
                                                    [1, 2, 3].map((_, index) => {
                                                        return (
                                                            <div className="w-100 mb-3" key={index}>
                                                                <Skeleton height={110} borderRadius="1rem" />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="2" title={languageSelector?.rating?.submitted}>
                                        <div className='ratingsreviews-tab submitted'>
                                            <div className='received-tab'>
                                                {Object.keys(ratingReviewSelector).length > 0 ?
                                                    ratingReviewSelector?.data?.length > 0 ?
                                                        ratingReviewSelector?.data?.map((value, index) => {
                                                            return (
                                                                <div className={`all-reviews-section ${index == 0 ? "all-reviews-section-top-box" : ""}`} key={index}>
                                                                    <div className='reviews-box-main'>
                                                                        <div className='reviews-img-box'>
                                                                            <div className='image-inst'>
                                                                                <img
                                                                                    src={value?.user_name?.profile_image ?
                                                                                        isValidHttpUrl(value?.user_name?.profile_image) ? value?.user_name?.profile_image
                                                                                            : `${baseUrlPostGres()}${value?.user_name?.profile_image}`
                                                                                        : "/images/blank-profile-picture.jpg"}
                                                                                    alt={value?.user_name?.first_name}
                                                                                    referrerPolicy="no-referrer"
                                                                                    className='img'
                                                                                />
                                                                                {value?.user_name?.verified_Sign == 1 && (
                                                                                    <span className="Checkmark">
                                                                                        <img src="/images/checked-mark.png" alt="Verified" />
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                <h3> {value?.user_name?.first_name} {value?.user_name?.last_name}</h3>
                                                                                <div className='rating-card'>
                                                                                    {value?.rating && [...new Array(Number(value?.rating))].map((_, index) => {
                                                                                        return (
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" key={index}>
                                                                                                <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M23.571,10.322,16.315,9.267,13.071,2.683,9.826,9.267,2.571,10.322l5.25,5.125L6.581,22.683l6.489-3.416,6.489,3.416-1.239-7.236,5.25-5.125Z" transform="translate(-2.571 -2.683)" fill="#fed756" />
                                                                                            </svg>
                                                                                        )
                                                                                    })}

                                                                                    {value?.rating && [...new Array((5 - Number(value?.rating)))].map((_, index) => {
                                                                                        return (
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" key={index}>
                                                                                                <path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M23.571,10.322,16.315,9.267,13.071,2.683,9.826,9.267,2.571,10.322l5.25,5.125L6.581,22.683l6.489-3.416,6.489,3.416-1.239-7.236,5.25-5.125Z" transform="translate(-2.571 -2.683)" fill="#dcdcdc" />
                                                                                            </svg>
                                                                                        )
                                                                                    })}
                                                                                    <span>({Number(value?.rating).toFixed(0)})</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='date-section'>
                                                                            <div className="btn"> {moment(value.created_at).format("DD MMM'YY")} </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='description'>
                                                                        <p> {value.comments} </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <NoDataFound />
                                                    :
                                                    [1, 2, 3].map((_, index) => {
                                                        return (
                                                            <div className="w-100 mb-3" key={index}>
                                                                <Skeleton height={110} borderRadius="1rem" />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    );
}

export default Studentratingsreviews
