import axios from "axios";
import { toast } from "react-toastify";
import { baseUrlPostGres } from "../constant";
import { apiHeader } from "./ApiHeader";
import {
    IS_GROUP_LOADING,
    ADD_GROUP_CLASSES_ERROR, ADD_GROUP_CLASSES_SUCCESS,
    GROUP_CLASSES_LIST_ERROR, GROUP_CLASSES_LIST_SUCCESS,
    GLOBAL_GROUP_CLASSES_LIST_ERROR, GLOBAL_GROUP_CLASSES_LIST_SUCCESS,
    GROUP_CLASSES_DETAIL_SUCCESS, GROUP_CLASSES_DETAIL_ERROR,
    GROUP_CLASSES_BOOKING_SUCCESS, GROUP_CLASSES_BOOKING_ERROR,
    DELETE_GROUP_CLASSES_SUCCESS, DELETE_GROUP_CLASSES_ERROR,
    EDIT_GROUP_CLASSES_SUCCESS, EDIT_GROUP_CLASSES_ERROR,
    VIEW_GROUP_CLASSES_SUCCESS, VIEW_GROUP_CLASSES_ERROR,
    MORE_GROUP_CLASS_BY_INSTRUCTOR_SUCCESS, MORE_GROUP_CLASS_BY_INSTRUCTOR_ERROR
} from "./Type";
import HandleError from "./HandleError";

export const AddGroupClass = (formdata) => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/add-group-class`, formdata, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: ADD_GROUP_CLASSES_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: ADD_GROUP_CLASSES_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetAddGroupClass = () => async (dispatch) => {
    dispatch({
        type: ADD_GROUP_CLASSES_SUCCESS,
        payload: {},
    });
}

export const DeleteGroupClass = (id) => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .delete(`${baseUrlPostGres()}/api/group-class-delete/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: DELETE_GROUP_CLASSES_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: DELETE_GROUP_CLASSES_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetDeleteGroupClass = () => async (dispatch) => {
    dispatch({
        type: DELETE_GROUP_CLASSES_SUCCESS,
        payload: {},
    });
}

export const EditGroupClassAction = (data) => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/group-class-update`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: EDIT_GROUP_CLASSES_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: EDIT_GROUP_CLASSES_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetEditGroupClassAction = () => async (dispatch) => {
    dispatch({
        type: EDIT_GROUP_CLASSES_SUCCESS,
        payload: {},
    });
}

export const ViewGroupClassAction = (id) => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/group-class-edit/${id}`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: VIEW_GROUP_CLASSES_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: VIEW_GROUP_CLASSES_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetViewGroupClassAction = () => async (dispatch) => {
    dispatch({
        type: VIEW_GROUP_CLASSES_SUCCESS,
        payload: {},
    });
}

export const GroupClassListMyProfile = () => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .get(`${baseUrlPostGres()}/api/group-list`, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: GROUP_CLASSES_LIST_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GROUP_CLASSES_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};


export const GlobalGroupClassListAction = (data) => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/list-group`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: GLOBAL_GROUP_CLASSES_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GLOBAL_GROUP_CLASSES_LIST_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetGlobalGroupClassListAction = () => async (dispatch) => {
    dispatch({
        type: GLOBAL_GROUP_CLASSES_LIST_SUCCESS,
        payload: {}
    });
};


export const GroupClassDetailAction = (data) => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/group-class-details`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: GROUP_CLASSES_DETAIL_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GROUP_CLASSES_DETAIL_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetGroupClassDetailAction = () => async (dispatch) => {
    dispatch({
        type: GROUP_CLASSES_DETAIL_SUCCESS,
        payload: {}
    });
};


export const GroupClassBookingAction = (data) => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/request-group-class`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({
                type: GROUP_CLASSES_BOOKING_SUCCESS,
                payload: response.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: GROUP_CLASSES_BOOKING_ERROR,
                payload: errors.response.data,
            });
        });
};

export const ResetGroupClassBookingAction = () => async (dispatch) => {
    dispatch({
        type: GROUP_CLASSES_BOOKING_SUCCESS,
        payload: {}
    });
};

export const MoreGroupClassByInstructorAction = (data) => async (dispatch) => {
    dispatch({ type: IS_GROUP_LOADING });
    await axios
        .post(`${baseUrlPostGres()}/api/more-group-class-by-instructor`, data, {
            headers: apiHeader(),
        })
        .then((response) => {
            dispatch({
                type: MORE_GROUP_CLASS_BY_INSTRUCTOR_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((errors) => {
            HandleError(errors.response.data)
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: MORE_GROUP_CLASS_BY_INSTRUCTOR_ERROR,
                payload: errors.response.data,
            });
        });
};