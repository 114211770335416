import React, { useEffect } from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Instructorsidebar from "../../components/Instructorsidebar/Instructorsidebar";
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import { useNavigate } from "react-router-dom";
import './../Instructorprofile/Instructorprofile.scss';
import { getUserSettings } from '../../Utils/Function';
import { useDispatch, useSelector } from 'react-redux';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { ResetSupportListAction, SupportListAction } from '../../redux/actions/SupportAction';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment';

const Support = ({ role }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentUserDetail = getUserSettings().user
    const supportListSelector = useSelector(state => state.support.support_list)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(SupportListAction(currentUserDetail?.id))
        return () => dispatch(ResetSupportListAction())
    }, [dispatch])

    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.contact_ticket?.page_title} </h1>
                    <p>Home / <span> {languageSelector?.contact_ticket?.page_title} </span></p>
                </div>

                <div className='myprofile-section classesdetails-page'>
                    <div className='container'>
                        <div className='row gx-5'>
                            <div className='col-md-12 col-lg-4 col-xl-4'>
                                {role == 2 ? <Studentsidebar /> : <Instructorsidebar />}
                            </div>

                            <div className='col-md-12 col-lg-8 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.contact_ticket?.page_title} </h2>
                                </div>

                                <div className="my-profile-details">
                                    <div className='row g-4'>
                                        {Object.keys(supportListSelector)?.length > 0 ?
                                            supportListSelector?.data?.length > 0 ?
                                                supportListSelector?.data?.map((elem, index) => (
                                                    <div className='col-md-6' key={index}>
                                                        <div className='card_support_box' onClick={() => navigate(`/${role == 2 ? "student" : "instructor"}/support-detail/${elem?.id}`, { state: elem })}>
                                                            <h3> {elem?.contact_reason?.name} </h3>
                                                            <p> {elem?.message.length > 20 ? `${elem?.message.slice(0, 20)}...` : elem?.message} </p>
                                                            <div className='date_comment_box'>
                                                                <div className='date'> {moment(elem.created_at, "YYYY-MM-DD").format("DD MMM'YY")}  </div>
                                                                <div className='comment_icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.25" height="11" viewBox="0 0 12.25 11">
                                                                        <path id="message-square-svgrepo-com" d="M3,6a3.105,3.105,0,0,1,.136-1.317,1.25,1.25,0,0,1,.546-.546A3.105,3.105,0,0,1,5,4h7.25a3.105,3.105,0,0,1,1.318.136,1.25,1.25,0,0,1,.546.546A3.105,3.105,0,0,1,14.25,6v8l-2.078-1.039a2.6,2.6,0,0,0-.319-.146,1.254,1.254,0,0,0-.225-.053,2.609,2.609,0,0,0-.35-.012H5a3.105,3.105,0,0,1-1.317-.136,1.25,1.25,0,0,1-.546-.546A3.105,3.105,0,0,1,3,10.75Z" transform="translate(-2.5 -3.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                                                    </svg>
                                                                    {elem?.contact_reply_count}
                                                                </div>
                                                            </div>
                                                            <div className='ticket_number'>
                                                                <h4>{languageSelector?.contact_ticket?.ticket}: <span className='text-uppercase'>{elem?.ticked_no} </span> </h4>
                                                                <button type='btn' className={`active_closed ${elem?.status === 1 ? "active" : "closed"}`}>
                                                                    {elem?.status === 1 ? languageSelector?.contact_ticket?.active :
                                                                        elem?.status === 2 ? languageSelector?.contact_ticket?.closed :
                                                                            ""}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <div className='col-12'>
                                                    <NoDataFound />
                                                </div>
                                            :
                                            [1, 2, 3, 4, 5, 6].map((_, index) => (
                                                <div className='col-md-6' key={index}>
                                                    <Skeleton height={166} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Support
