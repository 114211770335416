import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import Skeleton from 'react-loading-skeleton'
import { baseUrlPostGres } from "../../../redux/constant";
import WorkStationModal from "../../Instructorprofile/WorkStationModal";
import { useDispatch, useSelector } from "react-redux";
import CancelRequestModal from "../../Instructorprofile/CancelRequestModal";
import Button from "../../../components/Buttons/Buttons";
import { Pagination } from "../../../components/Hooks/Pagination";
import { ClassesDetailsListAction, ResetClassesDetailsListAction } from "../../../redux/actions/ClassesDetailActions";

const Upcoming = ({ setTypeBooking, currentPage, setCurrentPage, selector, classesDetailData }) => {
    const dispatch = useDispatch();
    const [item, setItem] = useState({ kindOfStand: "all", another: "another" });
    const [show, setShow] = useState(false);
    const [zoomLink, setZoomLink] = useState()
    const [bookingId, setBookingId] = useState();
    const [shwoCancelModal, setShowCancelModal] = useState(false);
    const { kindOfStand } = item;

    const isloading = useSelector(state => state.instructor.loading)
    const cancelRequestSelector = useSelector(state => state.instructor.reason_cancellation)
    const languageSelector = useSelector(state => state.home.dynamic_language)

    const handleChange = e => {
        e.persist();
        dispatch(ResetClassesDetailsListAction())
        setCurrentPage(1)
        setItem(prevState => ({
            ...prevState,
            kindOfStand: e.target.value
        }));
        setTypeBooking(e.target.value)
        if (e.target.value !== "all") {
            dispatch(ClassesDetailsListAction("student", { type_class: "1", type_booking: e.target.value, page: currentPage }))
        } else {
            dispatch(ClassesDetailsListAction("student", { type_class: "1", type_booking: "", page: currentPage }))
        }
    };

    const handleWorkStationLink = (value) => {
        if (value.zoom_link) {
            setZoomLink(value.zoom_link)
        }
        setShow(true)
    }
    const handleClose = () => {
        setShow(false);
        setZoomLink("")
    };

    const handleCloseTwo = () => {
        setBookingId("")
        setShowCancelModal(false)
    };

    const handleCancelRequest = (value) => {
        setBookingId(value.id)
        setShowCancelModal(true)
    };

    useEffect(() => {
        if (Object.keys(cancelRequestSelector).length > 0) {
            if (cancelRequestSelector?.status) {
                setBookingId("")
                setShowCancelModal(false)
            }
        }
    }, [cancelRequestSelector])

    const Date2datecompare = (dateString) => {
        var enteredDate = new Date(dateString);
        var currentDate = new Date();
        if (enteredDate >= currentDate) {
            return true;
        }
        return false;
    }

    const getUpcomingClass = (data) => {
        if (data?.length > 0) {
            let findObj = data.find(value => Date2datecompare(value?.time))
            if (findObj && Object.keys(findObj)?.length > 0) {
                let finalData = `${moment(findObj?.time).format("DD MMM'YY")} ${moment(findObj?.time).format("LT")}`
                return finalData
            }
        }
    }

    return (
        <>
            <div className="booking-filter">
                <div className="btn">
                    <Form.Group controlId="kindOfStand">
                        <Form.Check value="all" type="radio" label={languageSelector?.classes_details?.all} onChange={handleChange} checked={kindOfStand === "all"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand2"> <Form.Check value="1" type="radio" label={languageSelector?.classes_details?.on_demand} onChange={handleChange} checked={kindOfStand === "1"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand3"> <Form.Check value="2" type="radio" label={languageSelector?.classes_details?.scheduled} onChange={handleChange} checked={kindOfStand === "2"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand4"> <Form.Check value="3" type="radio" label={languageSelector?.classes_details?.problem_sloving} onChange={handleChange} checked={kindOfStand === "3"} />
                    </Form.Group>
                </div>

                <div className="btn">
                    <Form.Group controlId="kindOfStand5"> <Form.Check value="4" type="radio" label={languageSelector?.classes_details?.group_class} onChange={handleChange} checked={kindOfStand === "4"} />
                    </Form.Group>
                </div>
            </div>

            <div className="blog-section">
                <div className="container">
                    <div className="row g-5">
                        {Object.keys(selector).length > 0 ?
                            classesDetailData?.data?.length > 0 ?
                                <>
                                    {[...classesDetailData?.data].sort((a, b) => (a.created_at > b.created_at) ? -1 : 1).map((value, index) => {
                                        return (
                                            <div className="col-12 col-xxl-6" key={index}>
                                                <div className="bookingrequest-card-main">
                                                    <div className='booking-card'>
                                                        <div className="image-box">
                                                            {value.booking_type !== 4 ?
                                                                <img
                                                                    src={
                                                                        value?.user_instructor?.profile_image ?
                                                                            `${baseUrlPostGres()}${value?.user_instructor?.profile_image}`
                                                                            :
                                                                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                                    }
                                                                    alt={value?.user_instructor?.first_name}
                                                                />
                                                                :
                                                                <img
                                                                    src={
                                                                        value?.group_class_name?.picture ?
                                                                            `${baseUrlPostGres()}${value?.group_class_name?.picture}`
                                                                            :
                                                                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                                    }
                                                                    alt={value?.group_class_name?.class_name}
                                                                />
                                                            }
                                                        </div>
                                                        <div className='booking-details'>
                                                            <div className='heading-price'>
                                                                <div className='heading-description'>
                                                                    {value.booking_type !== 4 ?
                                                                        <h2>{value?.user_instructor?.first_name} {value?.user_instructor?.last_name}</h2>
                                                                        :
                                                                        <h2>{value?.group_class_name?.class_name}</h2>
                                                                    }
                                                                    <p> {moment(value.created_at).fromNow()} </p>
                                                                </div>
                                                                <div className='pending-box'>
                                                                    <h3> ${value?.transaction_user?.txn_amount ? value?.transaction_user?.txn_amount : 0}</h3>
                                                                    {value.status == 0 ? <Link className="btn pending"> {languageSelector?.classes_details?.pending} </Link> :
                                                                        value.status == 1 ? <Link className="btn pending accepted"> {languageSelector?.classes_details?.accepted} </Link> :
                                                                            value.status == 2 ? <Link className="btn pending rejected"> {languageSelector?.classes_details?.rejected} </Link> :
                                                                                null
                                                                    }
                                                                </div>
                                                            </div>
                                                            {value.booking_type !== 4 ?
                                                                <div>
                                                                    {value?.user_tutor_subject ?
                                                                        <>
                                                                            <h4> {languageSelector?.classes_details?.subject} </h4>
                                                                            <span>{value?.user_tutor_subject.tutor_sub_name} ({value?.class_level.class_name})</span>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }

                                                                    {value.booking_type !== 4 ?
                                                                        <>
                                                                            <h4> {languageSelector?.classes_details?.class_type} </h4>
                                                                            <span>
                                                                                {value.booking_type == 1 ? languageSelector?.classes_details?.ondemand_class :
                                                                                    value.booking_type == 2 ? `${languageSelector?.classes_details?.scheduled} - ` :
                                                                                        value.booking_type == 3 ? languageSelector?.classes_details?.problem_sloving :
                                                                                            value.booking_type == 4 ? languageSelector?.classes_details?.group_class
                                                                                                : null
                                                                                }

                                                                                {value.booking_type == 2 ?
                                                                                    <>
                                                                                        {moment(value?.class_date, "YYYY/MM/DD").format("DD MMM'YY")} | {value?.slottime_schudule ? value?.slottime_schudule?.slot_time : "-"}
                                                                                    </>
                                                                                    : null
                                                                                }
                                                                            </span>
                                                                        </>
                                                                        : null
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }

                                                            {value.booking_type == 4 ?
                                                                <div className='subjectclasstypelevel'>
                                                                    <div className='subjectclasstypelevel2 subjectclasstypelevel22'>
                                                                        <h4> {languageSelector?.classes_details?.class_type} </h4>
                                                                        <span> {value.booking_type == 4 ? languageSelector?.classes_details?.group_class : "-"} </span>
                                                                    </div>
                                                                    <div className='subjectclasstypelevel2'>
                                                                        <h4> {languageSelector?.classes_details?.level} </h4>
                                                                        <span> {value?.group_class_name?.class_level?.class_name} </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>

                                                    {value.booking_type == 4 ?
                                                        <div>
                                                            <div className='group-classcard-main'>
                                                                <div className='date-time-box date-time-box2'>
                                                                    <h4> {languageSelector?.classes_details?.date_time} </h4>
                                                                    <span> {getUpcomingClass(value?.group_class_name?.group_course_class)} </span>
                                                                </div>
                                                                <div className='date-time-box'>
                                                                    <h4> {languageSelector?.classes_details?.duration} </h4>
                                                                    <span>{value?.group_class_name?.duration} hrs</span>
                                                                </div>

                                                                <div className='date-time-box date-time-box2'>
                                                                    <h4> {languageSelector?.classes_details?.booked_seats} </h4>
                                                                    <span>{value?.group_class_name?.total_seat - value?.group_class_name?.reaming_seat} {languageSelector?.classes_details?.seats}</span>
                                                                </div>

                                                                <div className='date-time-box'>
                                                                    <h4> {languageSelector?.classes_details?.total_seats} </h4>
                                                                    <span>{value?.group_class_name?.total_seat} {languageSelector?.classes_details?.seats}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    <div className="eject-accept">
                                                        {value.booking_type !== 4 ?
                                                            <Button
                                                                BtnText={languageSelector?.classes_details?.cancel}
                                                                BtnColor=" reject"
                                                                BtnType="button"
                                                                disabled={isloading}
                                                                onClick={() => handleCancelRequest(value)}
                                                            />
                                                            :
                                                            null
                                                        }
                                                        {value.booking_type == 1 || value.booking_type == 2 || value.booking_type == 4 ?
                                                            <button className="btn accept workstation" type="button" disabled={value.zoom_link ? false : true} onClick={() => handleWorkStationLink(value)}> {languageSelector?.classes_details?.workstation} </button>
                                                            :
                                                            null
                                                        }
                                                        {value.booking_type == 3 ? <Link to={`/student/classes-details/problemsolving/${value.id}`} className="btn accept viewdetail"> {languageSelector?.classes_details?.view_detail} </Link> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <div className="col-md-12">
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={classesDetailData?.total}
                                            pageSize={classesDetailData?.per_page}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </>
                                :
                                <NoDataFound />
                            :
                            [1, 2, 3, 4, 5, 6].map((_, index) => {
                                return (
                                    <div className='col-12 col-xxl-6' key={index}>
                                        <Skeleton height={260} borderRadius="2.5rem" />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>

            <CancelRequestModal
                show={shwoCancelModal}
                onHide={handleCloseTwo}
                id={bookingId}
                name="student"
            />

            <WorkStationModal
                show={show}
                onHide={handleClose}
                zoomLink={zoomLink}
            />
        </>
    )
}

export default Upcoming