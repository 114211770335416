import axios from "axios";
import { toast } from "react-toastify";
import {
    STUDENT_LOGIN_SUCCESS,
    STUDENT_LOGIN_ERROR,
    AUTH_LOADING,
    SET_CURRENT_USER,
    LOGOUT_SUCCESS,
    STUDENT_REGISTER_ERROR,
    STUDENT_REGISTER_SUCCESS,
    OTP_VERIFICATION_SUCCESS,
    OTP_VERIFICATION_ERROR,
    FORGOT_EMAIL_SUCCESS,
    FORGOT_EMAIL_ERROR,
    INSTRUCTOR_LOGIN_SUCCESS,
    INSTRUCTOR_LOGIN_ERROR,
    INSTRUCTOR_REGISTER_SUCCESS,
    INSTRUCTOR_REGISTER_ERROR,
    STUDENT_SOCIAL_LOGIN_SUCCESSFULL,
    STUDENT_SOCIAL_LOGIN_ERROR,
    INSTRUCTOR_SOCIAL_LOGIN_SUCCESSFULL,
    INSTRUCTOR_SOCIAL_LOGIN_ERROR,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESEND_OTP_SUCCESS,
    RESEND_OTP_ERROR,
    IS_LOADING,
    LINKEDIN_SOCIAL_LOGIN_SUCCESS, LINKEDIN_SOCIAL_LOGIN_ERROR,
    COMPLETE_PROFILE_SOCIAL_SUCCESS, COMPLETE_PROFILE_SOCIAL_ERROR,
    STUDENT_TO_INSTRUCTOR_SWITCH_SUCCESS, STUDENT_TO_INSTRUCTOR_SWITCH_ERROR
} from "./Type";
import store from "../store";
import "react-toastify/dist/ReactToastify.css";
import { apiHeader } from "../../redux/actions/ApiHeader";
import { baseUrlPostGres } from "../../redux/constant";

toast.configure();

export const setCurrentUser = (userDetail) => {
    return store.dispatch({
        type: SET_CURRENT_USER,
        payload: userDetail,
    });
};

export const StudentLogin = (formData) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/login`, formData, {
            headers: apiHeader(true),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            const res = response.data.data;
            const payload = {
                token: res.access_token,
                user: res,
            };
            dispatch({ type: STUDENT_LOGIN_SUCCESS, payload: res });
            dispatch(setCurrentUser(res));
            localStorage.setItem("security_auth", JSON.stringify(payload));
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: STUDENT_LOGIN_ERROR,
                payload: errors,
            });
        });
};

export const StudentRegister = (formData) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/register`, formData, {
            headers: apiHeader(true),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({ type: STUDENT_REGISTER_SUCCESS, payload: response.data });
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: STUDENT_REGISTER_ERROR,
                payload: errors,
            });
        });
};

export const InstructorLogin = (formData) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/login`, formData, {
            headers: apiHeader(true),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            const res = response.data.data;
            const payload = {
                token: res.access_token,
                user: res,
            };
            dispatch({ type: INSTRUCTOR_LOGIN_SUCCESS, payload: res });
            dispatch(setCurrentUser(res));
            localStorage.setItem("security_auth", JSON.stringify(payload));
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: INSTRUCTOR_LOGIN_ERROR,
                payload: errors,
            });
        });
};

export const InstructorRegister = (formData) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/instructor-register`, formData, {
            headers: apiHeader(true),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({ type: INSTRUCTOR_REGISTER_SUCCESS, payload: response.data });
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: INSTRUCTOR_REGISTER_ERROR,
                payload: errors,
            });
        });
};

export const StudentSocialLogin = (formData) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/social-register`, formData, {
            headers: apiHeader(true),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            const payload = {
                token: response.data.data.access_token,
                user: response.data.data,
                facebookemail: response.data.facebookemail
            };
            dispatch({ type: STUDENT_SOCIAL_LOGIN_SUCCESSFULL, payload: payload });
            if (!response.data.facebookemail) {
                dispatch(setCurrentUser(payload.user));
            }
            localStorage.setItem("security_auth", JSON.stringify(payload));
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: STUDENT_SOCIAL_LOGIN_ERROR,
                payload: errors,
            });
        });
};

export const ResetStudentSocialLogin = () => (dispatch) => {
    dispatch({ type: STUDENT_SOCIAL_LOGIN_SUCCESSFULL, payload: {} });
}

export const InstuctorSocialLogin = (formData) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/instructor-social-register`, formData, {
            headers: apiHeader(true),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            const payload = {
                token: response.data.data.access_token,
                user: response.data.data,
                facebookemail: response.data.facebookemail
            };
            dispatch({ type: INSTRUCTOR_SOCIAL_LOGIN_SUCCESSFULL, payload: payload });
            if (!response.data.facebookemail) {
                dispatch(setCurrentUser(payload.user));
            }
            localStorage.setItem("security_auth", JSON.stringify(payload));
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({
                type: INSTRUCTOR_SOCIAL_LOGIN_ERROR,
                payload: errors,
            });
        });
};

export const LinkedinSocialLoginAction = (data, name) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios.post(`${baseUrlPostGres()}/api/linkedin-register-${name}`, data, {
        headers: apiHeader(true),
    }).then((response) => {
        toast.success(response?.data?.message, { theme: "colored" });
        const payload = {
            token: response.data.data.access_token,
            user: response.data.data,
            facebookemail: response.data.facebookemail
        };
        dispatch({ type: LINKEDIN_SOCIAL_LOGIN_SUCCESS, payload: payload });
        if (!response.data.facebookemail) {
            dispatch(setCurrentUser(payload.user));
        }
        localStorage.setItem("security_auth", JSON.stringify(payload));
    }).catch((errors) => {
        toast.error(errors.response.data.message, { theme: "colored" });
        dispatch({
            type: LINKEDIN_SOCIAL_LOGIN_ERROR,
            payload: errors,
        });
    });
};

export const LogoutAction = (name) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .get(`${baseUrlPostGres()}/api/${name}-logout`, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response.data.message, { theme: "colored" });
            dispatch({ type: LOGOUT_SUCCESS, payload: response });
            dispatch(setCurrentUser({}));
            localStorage.removeItem("security_auth");
            localStorage.removeItem("linkedin_oauth2_state");
            // window.location.reload()
            window.location.href = "/";
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
        });
};

export const OtpVerification = (formdata) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/account/verify`, formdata, {
            headers: apiHeader(),
        })
        .then((response) => {
            const res = response.data.data;
            if (res.role == 3) {
                const payload = {
                    token: res.access_token,
                    user: res,
                };
                dispatch({ type: INSTRUCTOR_LOGIN_SUCCESS, payload: res });
                dispatch(setCurrentUser(res));
                localStorage.setItem("security_auth", JSON.stringify(payload));
            }
            dispatch({ type: OTP_VERIFICATION_SUCCESS, payload: response });
            toast.success(response?.data?.message, { theme: "colored" });
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({ type: OTP_VERIFICATION_ERROR, payload: errors.response });
        });
};

export const ResnedOtp = (body) => (dispatch) => {
    dispatch({ type: IS_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/resend-otp`, body, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({ type: RESEND_OTP_SUCCESS, payload: response });
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({ type: RESEND_OTP_ERROR, payload: errors });
        });
};

export const ForgotEmail = (formdata) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/forgot-password`, formdata, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({ type: FORGOT_EMAIL_SUCCESS, payload: response });
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({ type: FORGOT_EMAIL_ERROR, payload: errors });
        });
};

export const ResetPasswordAction = (formdata) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/reset-password`, formdata, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response });
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({ type: RESET_PASSWORD_ERROR, payload: errors });
        });
};

export const CompleteProfileAction = (name, formdata) => (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    axios
        .post(`${baseUrlPostGres()}/api/facebook-email-${name}`, formdata, {
            headers: apiHeader(),
        })
        .then((response) => {
            toast.success(response?.data?.message, { theme: "colored" });
            dispatch({ type: COMPLETE_PROFILE_SOCIAL_SUCCESS, payload: response.data });
        })
        .catch((errors) => {
            toast.error(errors.response.data.message, { theme: "colored" });
            dispatch({ type: COMPLETE_PROFILE_SOCIAL_ERROR, payload: errors });
        });
};

const newapiHeader = (authRequired) => { 
    let headers = { 'Content-Type': 'application/json' };
    if (authRequired) {
        const token = JSON.parse(localStorage.getItem('security_auth'))?.token;
        headers.Authorization = `Bearer ${token}`;
    }
    return headers;
};

export const SwitchToInstructor = () => async (dispatch, getState) => {
    dispatch({ type: AUTH_LOADING });

    try {
        const { user } = getState().auth;
        const email    = user?.email;

        const response = await axios.post(`${baseUrlPostGres()}/api/switch-to-instructor`, { email }, {
            headers: newapiHeader(true),
        });

        toast.success(response?.data?.message, { theme: "colored" });

        const res = response.data.data;
        const payload = {
            token: res.access_token,
            user: res,
        };

        dispatch({ type: STUDENT_TO_INSTRUCTOR_SWITCH_SUCCESS, payload: res });
        dispatch(setCurrentUser(res));
        localStorage.setItem("security_auth", JSON.stringify(payload));
        window.location.href = "/myprofile/instructor";
    } catch (errors) {
        toast.error(errors.response.data.message, { theme: "colored" });
        dispatch({
            type: STUDENT_TO_INSTRUCTOR_SWITCH_ERROR,
            payload: errors,
        });
    }
};

export const SwitchToStudent = () => async (dispatch, getState) => {
    dispatch({ type: AUTH_LOADING });

    try {
        const { user } = getState().auth;
        const email    = user?.email;

        const response = await axios.post(`${baseUrlPostGres()}/api/switch-to-student`, { email }, {
            headers: newapiHeader(true),
        });

        toast.success(response?.data?.message, { theme: "colored" });

        const res = response.data.data;
        const payload = {
            token: res.access_token,
            user: res,
        };

        dispatch({ type: STUDENT_TO_INSTRUCTOR_SWITCH_SUCCESS, payload: res });
        dispatch(setCurrentUser(res));
        localStorage.setItem("security_auth", JSON.stringify(payload));
        window.location.href = "/myprofile/student";
    } catch (errors) {
        toast.error(errors.response.data.message, { theme: "colored" });
        dispatch({
            type: STUDENT_TO_INSTRUCTOR_SWITCH_ERROR,
            payload: errors,
        });
    }
};