import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Accordion } from "react-bootstrap";
import ChangePassword from '../ChangePassword/ChangePassword';
import Studentsidebar from "../../components/Studentsidebar/Studentsidebar";
import { GetCountryList, GetLanguageAction } from '../../redux/actions/HomePageActions';
import { FieldText } from '../../components/InputText/InputText';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import TextErrorMsg from "../../components/TextErrorMsg/TextErrorMsg";
import * as Yup from 'yup';
import Button from "./../../components/Buttons/Buttons";
import '../Instructorprofile/Instructorprofile.scss';
import ImagePreview from "./../../components/ImagePreview"
import { ProfileGetAction, ResetProfileGetAction, ResetStudentEditProfile, StudentEditProfile } from '../../redux/actions/CompleteProfileAction';
import PhoneCountryInput from '../../components/Numberinput/PhoneCountryInput';

const options2 = [
    { value: "Female", label: "Female" },
    { value: "Male", label: "Male" },
];

const Studentmyprofile = () => {
    const dispatch = useDispatch();
    const [timeZoneList, setTimeZoneList] = useState()
    const [submitClick, setSubmitClick] = useState(false)
    const [selectedOption, setSelectedOption] = useState({});
    const [languageList, setLanguageList] = useState([]);
    const EditProfileSelector = useSelector((state) => state.profile.student_edit_profile);
    const userData = useSelector((state) => state?.profile?.profile)
    const countryList = useSelector((state) => state?.home?.get_country_list);
    const isloading = useSelector((state) => state.profile.loading);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(ProfileGetAction("student"))
        return () => dispatch(ResetProfileGetAction())
    }, [dispatch]);

    useEffect(() => {
        GetLanguageAction(response => {
            if (response?.status === true) {
                setLanguageList(response?.data)
            }
        })();
    }, [GetLanguageAction])

    useEffect(() => {
        if (countryList && userData) {
            let countryData = countryList.filter(value => value.id == userData?.country_id)
            if (countryData?.length > 0) {
                setTimeZoneList(JSON.parse(countryData[0]?.timezones))
                setSelectedOption(countryData[0])
            }
        }
    }, [countryList, userData])

    useEffect(() => {
        dispatch(GetCountryList());
    }, [dispatch]);

    const handleOnChange = (e, data) => {
        if (e.target.name === "phone_number") {
            if (e.target.value) {
                let countryData = countryList.filter(value => (value.phonecode == selectedOption?.phonecode) && (value.name.toLowerCase() == selectedOption?.name.toLowerCase()))
                data.setFieldValue('country_id', countryData[0].id)
                setTimeZoneList(JSON.parse(countryData[0].timezones))
            }
        }
    }

    const handleProfileUpdateSubmit = (value, actions) => {
        let formData = new FormData();
        formData.append("first_name", value?.first_name);
        formData.append("last_name", value?.last_name);
        formData.append("phone_number", value.phone_number);
        formData.append("phone_code", value.phone_code);
        formData.append("dob", moment(value.dob).format("DD/MM/YYYY"));
        formData.append("gender", value?.gender);
        formData.append("country_id", value.country_id);
        formData.append("notification_email_lang_id", value.notification_email_lang_id);
        formData.append("country_time", value.country_time);
        if (typeof value.profile_image === "object") {
            formData.append("profile_image", value.profile_image);
        }
        dispatch(StudentEditProfile(formData));
        setSubmitClick(true)
    }

    useEffect(() => {
        if (Object.keys(EditProfileSelector).length > 0 && submitClick) {
            if (EditProfileSelector.status) {
                setSubmitClick(false)
                dispatch(ResetStudentEditProfile())
            }
        }
    }, [EditProfileSelector, submitClick])

    const SUPPORTED_FILE_FORMAT = ["image/jpg", "image/png", "image/jpeg"]

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        <>
            <div id="footer-bottom" className='instructor-profile'>
                <Header />
                <div className="instru-bg-image">
                    <h1> {languageSelector?.my_account?.page_title} </h1>
                    <p>Home / {languageSelector?.my_account?.page_title}  / <span> {languageSelector?.my_account?.breadcrumb_title} </span></p>
                </div>

                <div className='myprofile-section'>
                    <div className='container'>
                        <div className='row g-5'>
                            <div className='col-md-12 col-lg-5 col-xl-4'>
                                <Studentsidebar />
                            </div>

                            <div className='col-md-12 col-lg-7 col-xl-8'>
                                <div className='profile-top-heading'>
                                    <h2> {languageSelector?.my_account?.page_sidebar_title} </h2>
                                </div>
                                <div className='my-profile-details'>
                                    <div className='tab-section-profile'>
                                        <div className='tab-content'>
                                            {Object.keys(userData).length > 0 ?
                                                <Formik
                                                    initialValues={{
                                                        first_name: userData?.first_name,
                                                        last_name: userData?.last_name,
                                                        email: userData?.email,
                                                        phone_number: userData?.phone_number,
                                                        phone_code: userData?.phone_code,
                                                        dob: userData?.dob ? moment(userData?.dob, "DD/MM/YYYY").format("YYYY-MM-DD") : "",
                                                        gender: userData?.gender ? userData?.gender : "",
                                                        country_id: userData?.country_id ? userData?.country_id : "",
                                                        country_time: userData?.country_timetemp ? userData?.country_timetemp : "",
                                                        notification_email_lang_id: userData?.notify_email_lang_id ? userData?.notify_email_lang_id : "",
                                                        profile_image: userData?.profile_image ? userData?.profile_image : ""
                                                    }}
                                                    validationSchema={() => Yup.object().shape({
                                                        first_name: Yup.string().required(languageSelector?.validation?.first_name),
                                                        last_name: Yup.string().required(languageSelector?.validation?.last_name),
                                                        dob: Yup.string().required(languageSelector?.validation?.dob),
                                                        gender: Yup.string().required(languageSelector?.validation?.select_gender),
                                                        country_id: Yup.string().required(languageSelector?.validation?.select_country),
                                                        country_time: Yup.string().required(languageSelector?.validation?.select_timezone),
                                                        notification_email_lang_id: Yup.string().required(languageSelector?.validation?.select_notification_email),
                                                        profile_image: Yup.mixed().nullable().required(languageSelector?.validation?.picture_required)
                                                            .test(
                                                                "FILE_FORMAT",
                                                                languageSelector?.validation?.file_upload_format,
                                                                (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                                                            ),
                                                    })}
                                                    onSubmit={handleProfileUpdateSubmit}
                                                >
                                                    {formik => {
                                                        return (
                                                            <Form onChange={(e) => handleOnChange(e, formik)}>
                                                                <FormikFromFunc />
                                                                <div className='camera-box'>
                                                                    <Field name="profile_image">
                                                                        {({ form, field }) => {
                                                                            return (
                                                                                <label className='d-block'>
                                                                                    <input type="file"
                                                                                        hidden
                                                                                        name={field.name}
                                                                                        onChange={(e) => form.setFieldValue(field.name, e.target.files[0])}
                                                                                    />
                                                                                    <div className='icon'>
                                                                                        <img src='/images/camera-icon.png' className='img-fluid' alt="Camera" />
                                                                                    </div>
                                                                                </label>
                                                                            )
                                                                        }}
                                                                    </Field>

                                                                    <div className='img-container'>
                                                                        {formik.values.profile_image ?
                                                                            <ImagePreview file={formik.values.profile_image} imageAlt={formik.values.first_name} />
                                                                            :
                                                                            <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" className="img-fluid" alt={formik.values.first_name} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='text-center'>
                                                                    <ErrorMessage name="profile_image" component={TextErrorMsg} />
                                                                </div>

                                                                <div className='myprofilebox2'>
                                                                    <div className='myprofilebox1'>
                                                                        <FieldText
                                                                            name="first_name"
                                                                            type="text"
                                                                            placeholder={languageSelector?.placeholder?.first_name}
                                                                            defaultValue={formik.values.first_name}
                                                                        />
                                                                    </div>
                                                                    <div className='myprofilebox1'>
                                                                        <FieldText
                                                                            name="last_name"
                                                                            type="text"
                                                                            placeholder={languageSelector?.placeholder?.last_name}
                                                                            defaultValue={formik.values.last_name}
                                                                        />
                                                                    </div>

                                                                    <div className='myprofilebox1'>
                                                                        <div className="email-box">
                                                                            <FieldText
                                                                                name="email"
                                                                                type="email"
                                                                                placeholder={languageSelector?.placeholder?.email}
                                                                                defaultValue={formik.values.email}
                                                                                disabled={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* disabled */}
                                                                    <div className='myprofilebox1 fix-placeholder'>
                                                                        <PhoneCountryInput
                                                                            selectedOption={selectedOption}
                                                                            setSelectedOption={setSelectedOption}
                                                                            formik={formik}
                                                                        />
                                                                    </div>

                                                                    <div className='myprofilebox1 date-picker-sec'>
                                                                        <div className="form-group floating-label">
                                                                            <FieldText
                                                                                name="dob"
                                                                                type="date"
                                                                                placeholder={languageSelector?.placeholder?.dob}
                                                                                defaultValue={formik.values.dob}
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className='myprofilebox1 dropdown-select fix-placeholder'>
                                                                        <div className="App">
                                                                            <Field as="select" name="gender" className='select-control w-100' value={formik.values.gender}>
                                                                                <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                                                {options2.map((value, index) => {
                                                                                    return (
                                                                                        <option value={value.value} key={index}> {value.label} </option>
                                                                                    )
                                                                                })}
                                                                            </Field>
                                                                            <ErrorMessage name="gender" component={TextErrorMsg} />
                                                                            <span className="placeholder-name"> {languageSelector?.placeholder?.gender} </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='myprofilebox1 dropdown-select fix-placeholder'>
                                                                        <div className="App">
                                                                            <Field as="select" name="country_id" disabled className='select-control w-100' value={formik.values.country_id}>
                                                                                <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                                                {countryList.map((value, index) => {
                                                                                    return (
                                                                                        <option value={value.id} key={index}> {value.name} </option>
                                                                                    )
                                                                                })}
                                                                            </Field>
                                                                            <ErrorMessage name="country_id" component={TextErrorMsg} />
                                                                            <span className="placeholder-name"> {languageSelector?.placeholder?.country} </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='myprofilebox1 dropdown-select fix-placeholder'>
                                                                        <div className="App">
                                                                            <Field as="select" name="country_time" className='select-control w-100' value={formik.values.country_time}>
                                                                                <option value=""> {languageSelector?.placeholder?.select} </option>
                                                                                {timeZoneList && timeZoneList.map((value, index) => {
                                                                                    return (
                                                                                        <option value={`${value.zoneName} ${value.tzName} ${value.gmtOffsetName}`} key={index}> {(value.gmtOffsetName)} {value.zoneName}</option>
                                                                                    )
                                                                                })}
                                                                            </Field>
                                                                            <ErrorMessage name="country_time" component={TextErrorMsg} />
                                                                            <span className="placeholder-name"> {languageSelector?.placeholder?.time_zone} </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='myprofilebox1 dropdown-select fix-placeholder'>
                                                                        <div className="App">
                                                                            <Field as="select" name="notification_email_lang_id" className='select-control w-100' value={formik.values.notification_email_lang_id}>
                                                                                <option value=""> {languageSelector?.placeholder?.select}  </option>
                                                                                {languageList?.length > 0 &&
                                                                                    languageList?.map((value, index) => (
                                                                                        <option value={value?.id} key={index}>{value?.lang_name} </option>
                                                                                    ))
                                                                                }
                                                                            </Field>
                                                                            <ErrorMessage name="notification_email_lang_id" component={TextErrorMsg} />
                                                                            <span className="placeholder-name"> {languageSelector?.placeholder?.notification_email_lang} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="save-button">
                                                                    <Button
                                                                        BtnText={languageSelector?.my_account?.save_changes_button}
                                                                        BtnColor="primary becomaninstructor"
                                                                        BtnType="submit"
                                                                        disabled={!formik.isValid || isloading}
                                                                        hasSpinner={isloading && submitClick}
                                                                    />
                                                                </div>
                                                            </Form>
                                                        )
                                                    }}
                                                </Formik>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                    <div className='change-password-box login-main-section d-block'>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header> {languageSelector?.my_account?.change_password} </Accordion.Header>
                                                <Accordion.Body>
                                                    <ChangePassword panelName="student" />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
}

export default Studentmyprofile
