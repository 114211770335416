import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FieldText } from './../../../components/InputText/InputText';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from "./../../../components/Buttons/Buttons";
import { Regex } from './../../../Utils/Regex';
import { GetCountryList } from '../../../redux/actions/HomePageActions';
import { InstructorProfileBankDetailsAction } from '../../../redux/actions/CompleteProfileAction';

const BankDetails = ({ profileData }) => {
    const dispatch = useDispatch();
    const [submitClick, setSubmitClick] = useState(false)

    const isLoading = useSelector((state) => state.profile.loading);
    const ProfilePDSelector = useSelector((state) => state.profile.profile_bank_detail);
    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        dispatch(GetCountryList());
    }, [dispatch]);

    const handlePersonalDetailSubmit = (value) => {
        let data = {
            routing_number: value.routing_number,
            holder_name: value.holder_name,
            account_number: value.account_number,
            branch: value.branch,
            country: value.country,
            beneficiary_name: value.beneficiary_name,
            address: value.address,
            city: value.city,
            bank_name: value.bank_name,
            bank_address: value.bank_address,
            bank_city: value.bank_city,
            account_type: value.account_type,
            bank_swift_bic: value.bank_swift_bic,
            email: value.email,
        }
        dispatch(InstructorProfileBankDetailsAction(data))
        setSubmitClick(true)
    }

    useEffect(() => {
        if (Object.keys(ProfilePDSelector).length > 0 && submitClick) {
            setSubmitClick(false)
        }
    }, [ProfilePDSelector, submitClick])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        setTimeout(() => {
            formikFrom.validateForm();
        }, 300);
    }
    return (
        Object.keys(profileData).length > 0 ?
            <Formik
                initialValues={{
                    routing_number: profileData?.bank_details?.routing_number,
                    holder_name: profileData?.bank_details?.holder_name,
                    account_number: profileData?.bank_details?.account_number,
                    branch: profileData?.bank_details?.branch,
                    country: profileData?.bank_details?.country,
                    beneficiary_name: profileData?.bank_details?.beneficiary_name,
                    address: profileData?.bank_details?.address,
                    city: profileData?.bank_details?.city,
                    bank_name: profileData?.bank_details?.bank_name,
                    bank_address: profileData?.bank_details?.bank_address,
                    bank_city: profileData?.bank_details?.bank_city,
                    bank_swift_bic: profileData?.bank_details?.bank_swift_bic,
                    account_type: profileData?.bank_details?.account_type,
                    email: profileData?.bank_details?.email,
                }}

                validationSchema={() => Yup.object().shape({
                    routing_number: Yup.string().matches(Regex.ROUTING_NUMBER_REGEX, languageSelector?.validation?.routing_number_digits).required(languageSelector?.validation?.routing_number),
                    holder_name: Yup.string().matches(Regex.ALLOW_LETTERS_ONLY, languageSelector?.validation?.account_holder_name_characters).required(languageSelector?.validation?.account_holder_name),
                    account_number: Yup.string().matches(Regex.BANK_ACCOUNT_REGEX, languageSelector?.validation?.account_number_digits).required(languageSelector?.validation?.account_number),
                    branch: Yup.string().matches(Regex.ALLOW_LETTERS_ONLY, languageSelector?.validation?.branch_name_characters).required(languageSelector?.validation?.branch_name),
                    country: Yup.string().required(languageSelector?.validation?.country),
                    beneficiary_name: Yup.string().matches(Regex.ALLOW_LETTERS_ONLY, languageSelector?.validation?.beneficiary_name_characters).required(languageSelector?.validation?.beneficiary_name),
                    address: Yup.string().required(languageSelector?.validation?.address),
                    city: Yup.string().matches(Regex.ALLOW_LETTERS_ONLY, languageSelector?.validation?.city_characters).required(languageSelector?.validation?.city),
                    bank_name: Yup.string().required(languageSelector?.validation?.bank_name),
                    bank_address: Yup.string().required(languageSelector?.validation?.bank_address),
                    bank_city: Yup.string().matches(Regex.ALLOW_LETTERS_ONLY, languageSelector?.validation?.bank_city_characters).required(languageSelector?.validation?.bank_city),
                    bank_swift_bic: Yup.string().matches(Regex.BANK_SWIFT_BIC_REGEX, languageSelector?.validation?.bank_swift_bic_characters).required(languageSelector?.validation?.bank_swift_bic),
                    account_type: Yup.string().required(languageSelector?.validation?.account_type),
                    email: Yup.string().email(languageSelector?.validation?.email_valid).required(languageSelector?.validation?.email),
                })}

                onSubmit={handlePersonalDetailSubmit}
            >
                {formik => {
                    return (
                        <Form>
                        <FormikFromFunc />
                            <div className="tab-content">
                                <div className="myprofilebox2">
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="country"
                                            placeholder={languageSelector?.placeholder?.country}
                                            defaultValue={formik.values.country}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="beneficiary_name"
                                            placeholder="Beneficiary Name"
                                            defaultValue={formik.values.beneficiary_name}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="address"
                                            placeholder="Address"
                                            defaultValue={formik.values.address}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="city"
                                            placeholder="City"
                                            defaultValue={formik.values.city}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="bank_name"
                                            placeholder="Bank Name"
                                            defaultValue={formik.values.bank_name}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="bank_address"
                                            placeholder="Bank Address"
                                            defaultValue={formik.values.bank_address}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="bank_city"
                                            placeholder="Bank City"
                                            defaultValue={formik.values.bank_city}
                                        />
                                    </div>

                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="number"
                                            name="account_number"
                                            placeholder={languageSelector?.placeholder?.account_number}
                                            defaultValue={formik.values.account_number}
                                        />
                                    </div>

                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="account_type"
                                            placeholder="Account Type"
                                            defaultValue={formik.values.account_type}
                                        />
                                    </div>

                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="number"
                                            name="routing_number"
                                            placeholder={languageSelector?.placeholder?.routing_number}
                                            defaultValue={formik.values.routing_number}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="bank_swift_bic"
                                            placeholder="Bank’s SWIFT or BIC code"
                                            defaultValue={formik.values.bank_swift_bic}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="email"
                                            name="email"
                                            placeholder={languageSelector?.placeholder?.email}
                                            defaultValue={formik.values.email}
                                        />
                                    </div>
                                    <div className="myprofilebox1">
                                        <FieldText
                                            type="text"
                                            name="branch"
                                            placeholder={languageSelector?.placeholder?.branch_name}
                                            defaultValue={formik.values.branch}
                                        />
                                    </div>



                                </div>
                                <div className="save-button">
                                    <Button
                                        BtnText={languageSelector?.my_account?.save_changes_button}
                                        BtnColor="primary becomaninstructor"
                                        BtnType="submit"
                                        disabled={!formik.isValid || isLoading}
                                        hasSpinner={isLoading && submitClick}
                                    />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            :
            null
    )
}
export default BankDetails