import axios from 'axios';
import { toast } from 'react-toastify';
import { RATING_REVIEW_LIST_ERROR, RATING_REVIEW_LIST_SUCCESS, IS_RATING_LOADING, RATING_REVIEW_ADD_ERROR, RATING_REVIEW_ADD_SUCCESS } from './Type';
import 'react-toastify/dist/ReactToastify.css';
import { apiHeader } from '../../redux/actions/ApiHeader';
import { baseUrlPostGres } from '../../redux/constant';

toast.configure();

export const ReviewRatingListAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_RATING_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/list-review-${name}`, data, {
            headers: apiHeader()
        })
        .then(response => {
            dispatch({
                type: RATING_REVIEW_LIST_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response.data, { theme: "colored" })
            dispatch({
                type: RATING_REVIEW_LIST_ERROR,
                payload: errors.response.data
            })
        })
}

export const ResetReviewRatingListAction = () => async (dispatch) => {
    dispatch({
        type: RATING_REVIEW_LIST_SUCCESS,
        payload: {}
    })
}

export const AddReviewRatingAction = (name, data) => async (dispatch) => {
    dispatch({ type: IS_RATING_LOADING })
    await axios
        .post(`${baseUrlPostGres()}/api/rating-review-${name}`, data, {
            headers: apiHeader()
        })
        .then(response => {
            toast.success(response.data.message, { theme: "colored" })
            dispatch({
                type: RATING_REVIEW_ADD_SUCCESS,
                payload: response.data
            })
        })
        .catch(errors => {
            toast.error(errors?.response.data.message, { theme: "colored" })
            dispatch({
                type: RATING_REVIEW_ADD_ERROR,
                payload: errors.response.data
            })
        })
}

export const ResetAddReviewRatingAction = () => async (dispatch) => {
    dispatch({
        type: RATING_REVIEW_ADD_SUCCESS,
        payload: {}
    })
}