import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Buttons/Buttons";
import EditIcon from "../EditIcon";
import { Tab, Tabs } from "react-bootstrap";
import "./../../Instructorcompleteprofile/CompleteProfile.scss"
import { socket } from "../../../redux/constant/Socket";
import moment from "moment/moment";
import "./ScheduleClass.scss"
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ScheduleClassStepTwo = ({ subscriptionValue, amount, setActiveStep }) => {
    const { id, name } = useParams();
    const location = useLocation()
    const navigate = useNavigate()
    const prevPageData = location?.state
    const [scheduleDays, setScheduleDays] = useState()
    const [availability, setAvailability] = useState()
    const [scheduleCalendarDays, setScheduleCalendarDays] = useState()
    const [scheduleCalendarDayRange, setScheduleCalendarDayRange] = useState()
    const [selectedDate, setSelectedDate] = useState(moment().format("DD/MM/YYYY"))
    const [key, setKey] = useState()
    const [count, setCount] = useState(1)
    const [selectedSlotTiming, setSelectedSlotTiming] = useState()

    const languageSelector = useSelector(state => state.home.dynamic_language)

    useEffect(() => {
        socket.emit("instructor_schedule_data_params", { user_id: id, type: 1, week_name: "monday", week_date: moment().format("DD/MM/YYYY") })
    }, [])

    socket.on("instructor_schedule_slots_data", (value) => {
        setScheduleDays(value)
    });

    useEffect(() => {
        setActiveStep(name)
    }, [name])

    const handleDayTab = (data) => {
        setKey(data)
    };

    const handleChange = (e, value, array) => {
        let data_value = {
            slot_time: value.slot_time,
            slot_id: value.id,
            shift_id: array.id,
            shift_name: array.shift_name,
        }
        setAvailability(data_value)
        setSelectedSlotTiming(e.target.value)
        setCount(count + 1)
    }

    const getUpcomingSevanDays = (data, type) => {
        let upcoming7Day = []
        for (var w = 0; w < 7; w++) {
            if (type === "add") {
                let dateFrom = moment(data).add(w, 'days').startOf('day').format('DD/MM/YYYY')
                let weekName = moment(data).add(w, 'days').startOf('day').format('dddd')
                upcoming7Day.push({ date: dateFrom, week: weekName });
            } else {
                let dateFrom = moment(data).subtract(w, 'days').startOf('day').format('DD/MM/YYYY')
                let weekName = moment(data).subtract(w, 'days').startOf('day').format('dddd')
                upcoming7Day.push({ date: dateFrom, week: weekName });
            }
        }
        setScheduleCalendarDays(type === "add" ? upcoming7Day : upcoming7Day.reverse())
        if (upcoming7Day.length > 6) {
            let monthName = moment(upcoming7Day[0].date, "DD/MM/YYYY").format("MMM")
            let startDate = moment(upcoming7Day[0].date, "DD/MM/YYYY").format("DD")
            let Year = moment(upcoming7Day[0].date, "DD/MM/YYYY").format("YYYY")
            let endDate = moment(upcoming7Day[upcoming7Day.length - 1].date, "DD/MM/YYYY").format("DD")
            setScheduleCalendarDayRange(`${monthName} ${startDate}-${endDate}, ${Year}`)
        }
        return upcoming7Day
    }

    const handleCalendarArrow = (type) => {
        let startDate = moment(scheduleCalendarDays[0].date, "DD/MM/YYYY").format("YYYY-MM-DD")
        let endDate = moment(scheduleCalendarDays[scheduleCalendarDays.length - 1].date, "DD/MM/YYYY").format("YYYY-MM-DD")
        if (type === "prev") {
            getUpcomingSevanDays(startDate, "minus")
        } else {
            getUpcomingSevanDays(endDate, "add")
        }
    }

    useEffect(() => {
        getUpcomingSevanDays(moment().format("YYYY-MM-DD"), "add")
    }, [])

    const handleStepThereSubmit = (e) => {
        e.preventDefault()
        if (availability) {
            if (subscriptionValue) {
                navigate(`/scheduleclass/checkout/${id}`, { state: { ...prevPageData, ...availability, date_class: selectedDate } });
            } else {
                navigate(`/scheduleclass/payment-method/${id}`, { state: { ...prevPageData, ...availability, date_class: selectedDate } });
            }
            setActiveStep('date-and-time');
        } else {
            toast.error(languageSelector?.validation?.please_select_any_one_availability, { theme: "colored" });
        }
    }

    const handlePrevious = () => {
        navigate(`/scheduleclass/subject-and-level/${id}`, { state: { ...prevPageData, ...availability, date_class: selectedDate } });
        setActiveStep('subject-and-level');
    }

    const handleCalendarDate = (data) => {
        setSelectedSlotTiming("");
        setAvailability("")
        setSelectedDate(data.date)
        socket.emit("instructor_schedule_data_params", { user_id: id, type: 1, week_name: data.week, week_date: data.date })
    }

    return (
        <div className="personaldetails-main-section on-demand-class-type">
            <h2> {languageSelector?.student_booking?.select_date_time} </h2>
            <div className="on-demand-class-content">
                <div className="row g-3 g-md-5">
                    <div className="col-md-8 col-sm-12 order-2 order-md-0">
                        <form autoComplete="off" onSubmit={handleStepThereSubmit}>
                            <div className="personaldetails-section">
                                <div className="select-subject-level-card">
                                    <div className="schedule-calendar-wrap">
                                        <div className="schedule-up-range">
                                            <div className={`pre-arrow arrow-block ${scheduleCalendarDays && scheduleCalendarDays[0]?.date == moment().format("DD/MM/YYYY") ? "disabled" : ""}`} onClick={() => handleCalendarArrow("prev")}>
                                                <img src="/images/arrow-left.svg" className="img-fluid" alt="Arrow" />
                                            </div>
                                            <div className="sur-data"> {scheduleCalendarDayRange} </div>
                                            <div className="next-arrow arrow-block" onClick={() => handleCalendarArrow("next")}>
                                                <img src="/images/arrow-right.svg" className="img-fluid" alt="Arrow" />
                                            </div>
                                        </div>
                                        <div className="schedule-up-date">
                                            {scheduleCalendarDays && scheduleCalendarDays.map((value, index) => {
                                                return (
                                                    <div className={`sud-item ${value.date == selectedDate ? "current" : ""}`} key={index} onClick={() => handleCalendarDate(value)}>
                                                        <span className="week_name">{moment(value.week, "dddd").format("ddd")}</span>
                                                        <span className="day_name">{moment(value.date, "DD/MM/YYYY").format("DD")}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="select-subject-level-card payment-box-main-content">
                                    <div className="availability-box-main">
                                        <div className="classes-tabs-three">
                                            <div className="morning-slot-box">
                                                {scheduleDays && scheduleDays.length > 0 ?
                                                    <Tabs
                                                        defaultActiveKey={1}
                                                        activeKey={key}
                                                        onSelect={handleDayTab}
                                                        className="slot-tabs-block"
                                                    >
                                                        {scheduleDays.map((ele) => (
                                                            <Tab key={ele.fix_id} eventKey={ele.fix_id}
                                                                title={
                                                                    <>
                                                                        <p>
                                                                            {ele.fix_id == 1 ? languageSelector?.student_booking?.morning :
                                                                                ele.fix_id === 2 ? languageSelector?.student_booking?.afternoon :
                                                                                    languageSelector?.student_booking?.evening
                                                                            }
                                                                        </p>
                                                                        <span>
                                                                            {ele.fix_id == 1 ? "8.00 AM To 12.00 PM" : ele.fix_id === 2 ? "12.00 PM To 5.00 PM" : "5.00 PM To 9.00 PM"}
                                                                        </span>
                                                                    </>
                                                                }
                                                            >
                                                                <div className="time-slots-box-section row">
                                                                    {ele?.slotData ?
                                                                        ele?.slotData.map((value, index) => {
                                                                            return (
                                                                                <div className="time-slots-box-first col-md-6 col-lg-4 mb-3" key={index}>
                                                                                    <div className="radio-box">
                                                                                        <div className="check-box-create">
                                                                                            <input
                                                                                                // required
                                                                                                type="radio"
                                                                                                name="slots_booking_availability"
                                                                                                id={`${value.id}_${ele.shift_name}`}
                                                                                                value={value.slot_time}
                                                                                                onChange={(e) => handleChange(e, value, ele)}
                                                                                                checked={selectedSlotTiming == value.slot_time}
                                                                                            />
                                                                                            <label
                                                                                                htmlFor={`${value.id}_${ele.shift_name}`}
                                                                                            > {value.slot_time} </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <div className="slot-not-found text-center py-4">
                                                                            <h3> {languageSelector?.student_booking?.slots_are_not_available} </h3>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Tab>
                                                        ))}
                                                    </Tabs>
                                                    :
                                                    <div className="slot-not-found text-center pb-5">
                                                        <h3> {languageSelector?.student_booking?.no_slot_available}  </h3>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pt-5">
                                <Button
                                    name="previous"
                                    BtnText={languageSelector?.student_booking?.back}
                                    BtnColor="yellow"
                                    BtnType="button"
                                    onClick={handlePrevious}
                                />

                                <Button
                                    BtnText={languageSelector?.student_booking?.next}
                                    name="next"
                                    BtnColor="primary"
                                    BtnType="submit"
                                />
                            </div>
                        </form>
                    </div>


                    <div className="col-md-4 col-sm-12">
                        <div className="personaldetails-section payable-amount-card-box">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <span className="amount-text"> {languageSelector?.student_booking?.payable_amount} </span>
                                </div>

                                <div className="col-4 text-end">
                                    <h3 className="amount-price">${amount ? amount : 0}</h3>
                                </div>
                            </div>
                        </div>

                        <div className="personaldetails-section payable-amount-card-box">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <span className="amount-text"> {languageSelector?.student_booking?.schedule_class} </span>
                                </div>
                                <div className="col-3 text-end">
                                    <Link className="btn edit-btn" to={`/instructordetail/${id}`}>
                                        <EditIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="personaldetails-section payable-amount-card-box">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <span className="amount-text">{prevPageData?.subject_name} | {prevPageData?.level_name}</span>
                                </div>
                                <div className="col-3 text-end">
                                    <Link className="btn edit-btn" to={`/scheduleclass/subject-and-level/${id}`}>
                                        <EditIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ScheduleClassStepTwo