import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
const Introduction = ({ instructorDetails }) => {
    const languageSelector = useSelector(state => state.home.dynamic_language)
    const [availabilityDays, setAvailabilityDays] = useState()
    const days = [
        { id: 1, name: "Mon", value: "Monday" },
        { id: 2, name: "Tue", value: "Tuesday" },
        { id: 3, name: "Wed", value: "Wednesday" },
        { id: 4, name: "Thu", value: "Thursday" },
        { id: 5, name: "Fri", value: "Friday" },
        { id: 6, name: "Sat", value: "Saturday" },
        { id: 7, name: "Sun", value: "Sunday" },
    ];

    const availability = [
        {
            name: "Morning (8.00 AM To 12.00 PM)",
            shift: 1,
            slots: [
                { name: "Monday", slot: false },
                { name: "Tuesday", slot: false },
                { name: "Wednesday", slot: false },
                { name: "Thursday", slot: false },
                { name: "Friday", slot: false },
                { name: "Saturday", slot: false },
                { name: "Sunday", slot: false }
            ]
        },
        {
            name: "Afternoon (12.00 PM To 5.00 PM)",
            shift: 2,
            slots: [
                { name: "Monday", slot: false },
                { name: "Tuesday", slot: false },
                { name: "Wednesday", slot: false },
                { name: "Thursday", slot: false },
                { name: "Friday", slot: false },
                { name: "Saturday", slot: false },
                { name: "Sunday", slot: false }
            ]
        },
        {
            name: "Evening (5.00 PM To 9.00 PM)",
            shift: 3,
            slots: [
                { name: "Monday", slot: false },
                { name: "Tuesday", slot: false },
                { name: "Wednesday", slot: false },
                { name: "Thursday", slot: false },
                { name: "Friday", slot: false },
                { name: "Saturday", slot: false },
                { name: "Sunday", slot: false }
            ]
        }
    ]

    useEffect(() => {
        if (instructorDetails && Object.keys(instructorDetails).length > 0) {
            instructorDetails.userweek.forEach(userweek => {
                userweek.user_shift.forEach(usershift => {
                    availability.forEach(ava => {
                        if (ava.shift == usershift.shift) {
                            ava.slots.forEach(slots => {
                                if (userweek.week_name == slots.name) {
                                    slots.slot = true
                                }
                            });
                        }
                    });
                })
            });
        }
        setAvailabilityDays(availability)
    }, [instructorDetails])

    return (
        <div className="about-description" id="subLevel">
            <h2> {languageSelector?.inst_page?.about} </h2>
            <div
                dangerouslySetInnerHTML={{
                    __html: instructorDetails?.descriptions,
                }}
            />

            {instructorDetails?.user_tutor_subject?.length > 0 ?
                <div className="qualification-sec subLevel-block">
                    <h2> {languageSelector?.inst_page?.subject_level} </h2>
                    <ul>
                        {instructorDetails?.user_tutor_subject?.map((value, index) => (
                            <li key={index}>
                                {`${value.tutor_sub_name} (${value.user_subject[0]?.class_name})`}
                            </li>
                        ))}
                    </ul>
                </div>
                :
                null
            }

            <div className="qualification-sec">
                <h2> {languageSelector?.inst_page?.qualification} </h2>
                <div className="qualification-card">
                    <h3>
                        {instructorDetails?.instructorlevel?.inst_levels_name} in {instructorDetails?.subject_name}
                    </h3>
                    <div className="qualification-card-main">
                        <p>{instructorDetails?.school_name}</p>
                        <p>{instructorDetails?.percentage} {languageSelector?.inst_page?.grade} </p>
                        <p> {`${instructorDetails?.clg_start_year} to ${instructorDetails?.clg_end_year}`} </p>
                    </div>
                </div>
            </div>

            <div className="qualification-sec">
                <h2> {languageSelector?.inst_page?.availability} </h2>
                <div className="availability-calendar">
                    <div className="feescharges-sec availability">
                        <div className="table-responsive">
                            <table className="customers">
                                <thead>
                                    <tr>
                                        <th> {languageSelector?.inst_page?.time_slots} </th>
                                        {days.map((ele, index) => (
                                            <th key={index}>{ele.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {availabilityDays && availabilityDays.map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><span>{value.name}</span></td>
                                                {value.slots.map((data, idx) => (
                                                    <React.Fragment key={idx}>
                                                        {data.slot ?
                                                            <td className="active">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="19.728"
                                                                    height="15.107"
                                                                    viewBox="0 0 19.728 15.107"
                                                                >
                                                                    <path
                                                                        id="Path_126"
                                                                        data-name="Path 126"
                                                                        d="M30.128,13.974l-1.612-1.658a.347.347,0,0,0-.257-.11h0a.333.333,0,0,0-.257.11L16.826,23.576l-4.068-4.068a.354.354,0,0,0-.513,0l-1.631,1.631a.365.365,0,0,0,0,.522l5.13,5.13a1.622,1.622,0,0,0,1.072.522,1.7,1.7,0,0,0,1.063-.5h.009L30.138,14.5A.392.392,0,0,0,30.128,13.974Z"
                                                                        transform="translate(-10.505 -12.206)"
                                                                        fill="Currentcolor"
                                                                    />
                                                                </svg>
                                                            </td>
                                                            :
                                                            <td className="deactive"></td>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction